import React, { useState, forwardRef } from 'react'
import './CommonInput.css'

const CommonInput = forwardRef(({
  type = 'text',
  name,
  placeholder,
  value,
  onChange,
  className = '',
  style = {},
  isTextArea = false,
  maxLength,
  accept,
  pattern,
  onKeyPress,
  onPaste,
  allowTextOnly = false,
  allowNumberOnly = false,
  autocomplete = 'off',
  onBlur,
  ...rest
}, ref) => {
  const [counter, setCounter] = useState(0)

  const handleChange = (e) => {
    const { value } = e.target

    setCounter(value?.length || 0)
    if (allowTextOnly && !/^[A-Za-z\s]*$/.test(value)) return
    if (allowNumberOnly && !/^\d*$/.test(value)) return

    onChange(e)
  }

  const renderCharCount = () => {
    if (maxLength) {
      return (
        <div className="description_count">
          {counter}
          /
          {maxLength}
        </div>
      )
    }
    return null
  }

  if (isTextArea) {
    return (
      <div style={{ position: 'relative' }}>
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className={`common-input ${className}`}
          style={{ ...style, paddingBottom: '20px' }}
          maxLength={maxLength}
          ref={ref}
          {...rest}
        />
        {renderCharCount()}
      </div>
    )
  }

  return (
    <input
      type={type}
      name={name}
      ref={ref}
      accept={accept}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      className={`common-input ${className}`}
      style={style}
      pattern={pattern}
      onBlur={onBlur}
      autoComplete={autocomplete}
      onKeyPress={(e) => {
        const charCode = e.which || e.keyCode
        const charStr = String.fromCharCode(charCode)

        // Prevent invalid characters based on props
        if (allowTextOnly && !/^[A-Za-z\s]*$/.test(charStr)) {
          e.preventDefault()
        }
        if (allowNumberOnly && !/^\d*$/.test(charStr)) {
          e.preventDefault()
        }

        if (onKeyPress) {
          onKeyPress(e)
        }
      }}
      onPaste={onPaste}
      {...rest}
    />
  )
})

export default CommonInput
