/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import CommonInput from '../InputComponet/CommonInput'
import { debounce } from '../../helpers/Utility'
import {
  urlImageProductPathStore
} from '../../services/Service'
import Dropdown from '../Dropdown/Dropdown'
import CommonButton from '../ButtonComponent/ButtonCommon'
import Loader from '../Loader/Loader'
import { emitter, EventType } from '../../helpers/Emitter'
import AddEditProductLibaryModal from './AddEditProductLibaryModal'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const ProductLibaryModal = ({
  ShowProductsModel,
  setShowProductsModel,
  generalProduct,
  onSubmited,
  productType
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const modelheaderRef = useRef(null)
  const modelFooterRef = useRef(null)
  const componentRef = useRef()
  const [modalHeight, setModalHeight] = useState(0)
  const [ShowProduct, setShowProduct] = useState(false)
  const [editData, setEditData] = useState(null)
  const [category, setCategory] = useState([])
  const [isLibraryLoading, setIsLibraryLoading] = useState(false)
  const [EditProduct, setEditProduct] = useState(false)
  const [product, setProduct] = useState([])

  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [proDeleteIndex, setProDeleteIndex] = useState(null)

  const [inputFields, setInputFields] = useState({
    name: '',
    regular_price: '',
    discount_price: 0,
    category_id: '',
    sub_category_id: '',
    product_link: '',
    description: '',
    main_image: '',
    image_1: '',
    image_2: '',
    image_3: '',
    search: '',
    id: ''
  })
  const productLibraryInitialized = useRef(false)

  const updateModalHeight = () => {
    if (componentRef.current) {
      setModalHeight(
        componentRef.current.offsetHeight -
          (modelheaderRef.current.offsetHeight +
            modelFooterRef.current.offsetHeight)
      )
    }
  }

  useEffect(() => {
    updateModalHeight()
    window.addEventListener('resize', updateModalHeight)
    return () => {
      window.removeEventListener('resize', updateModalHeight)
    }
  }, [ShowProductsModel])

  const ProductLibrary = (search) => {
    setIsLibraryLoading(true)
    const data = {
      search
    }
    APICall(
      'post',
      data,
      `${EndPoints.getProductLibrary}?search=${search}`,
      'store'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setProduct(res?.decryptedData)
          setIsLibraryLoading(false)
        } else {
          setIsLibraryLoading(false)
           toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLibraryLoading(false)
      })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category?.find((cat) => cat?.id === categoryId)
    return categoryObj ? categoryObj?.name : English.G124
  }

  const ProductLibFilterData = (category_id) => {
    setIsLibraryLoading(true)
    const data = {
      category_id
    }
    APICall('post', data, `${EndPoints.getProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setProduct(res?.decryptedData)
          setIsLibraryLoading(false)
        } else {
          setIsLibraryLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLibraryLoading(false)
      })
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => ProductLibrary(inputVal), 500),
    []
  )

  const MainCategory = () => {
    APICall('post', {}, EndPoints.category, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const mainCategoryInitialized = useRef(false)

  useEffect(() => {
    if (!mainCategoryInitialized.current) {
      mainCategoryInitialized.current = true
      MainCategory()
    }
  }, [])

  const AddLibrary = () => {
    setShowProduct(true)
    setEditData(false)
    setEditProduct(false)
    ProductLibrary()
  }

  const EditLibrary = (data) => {
    setEditProduct(true)
    setShowProduct(true)
    setEditData(data)
  }

  const HandleCancelConfirm = () => {
    setShowLibraryConfirmModel(false)
  }

  const HandleCancel = () => {
    setShowProductsModel(false)
  }

  const deleteLibraryConfirm = () => {
    setShowLibraryConfirmModel(true)
  }

  const deleteProductLib = (data) => {
    const platFormData = {
      product_id: data?.id
    }
    setLoading(true)
    APICall('post', platFormData, `${EndPoints.deleteProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setLoading(false)
            emitter.emit(EventType.ProductLibraryDelete, {
              product_id: data.id,
              action: 'update'
            })
            ProductLibrary()
            onSubmited()
            setShowLibraryConfirmModel(false)
            toast.success(res?.data?.message)
          }, 1500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const GeneralSubmit = (data) => {
    if (productType === 'trending') {
      AddTrending(data)
    } else {
      AddGeneral(data)
    }
    setShowProductsModel(false)
  }

  const AddGeneral = (data) => {
    const platFormData = {
      app_id: app_id?.id,
      store_general_products: [data.id]
    }
    setLoading(true)
    APICall('post', platFormData, `${EndPoints.addGeneralProduct}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onSubmited()
            setLoading(false)
            toast.success(res?.data?.message)
            emitter.emit(EventType.StoreUpdate)
          }, 1500)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const AddTrending = (data) => {
    const platFormData = {
      app_id: app_id?.id,
      store_trending_products: [data.id]
    }
    APICall('post', platFormData, `${EndPoints.addTrendingProduct}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onSubmited()
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!productLibraryInitialized.current && ShowProductsModel) {
      productLibraryInitialized.current = true
      ProductLibrary()
    }
  }, [ShowProductsModel])

  return (
    <>
      <div className="add-item-model">
        <div
          className="add-item-content add-item-content-store d-flex flex-column justify-content-between store_right_padding"
          style={{ overflow: 'hidden' }}
          ref={componentRef}
        >
          <div className="">
            <div ref={modelheaderRef} className="store_header_sticky">
              <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
                <h2>{English.G140}</h2>
                <div className="items-filter">
                  <div className="position-relative input-position">
                    <CommonInput
                      placeholder="Search Products"
                      onChange={(e) => {
                        handleSearch2(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="drop-row mb-2">
                <div className="drop-sub-row">
                  <Dropdown
                    title={getCategoryName(inputFields.category_id)}
                    onChanged={(d) => {
                      if (d.id === 'all') {
                        ProductLibrary()
                        setInputFields({
                          ...inputFields,
                          category_id: null
                        })
                      } else {
                        ProductLibFilterData(d.id)
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }
                    }}
                    Data={[
                      ...(inputFields.category_id
                        ? [{ title: 'All Product', id: 'all' }]
                        : []),
                      ...(category || []).map((d) => ({
                        title: d.name,
                        id: d.id
                      }))
                    ]}
                    customClass="store_dropdown"
                  />
                  <Dropdown
                    title="Ratings"
                    Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                    customClass="disabledDropdown"
                  />
                  <Dropdown
                    title="Duration"
                    Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                    customClass="disabledDropdown"
                  />
                </div>
                <div className="add-video-rows">
                  <CommonButton
                    label={English.G137}
                    imgSrc={Images.AddIco}
                    onClick={AddLibrary}
                    isPrimary
                    className="add_new_content add_new_store"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ maxHeight: `${modalHeight}px` }}
              className="awards-row store-container store_scroll_bar"
            >
              {product?.length === 0 && !isLibraryLoading ? (
                <div className="awards-item">
                  <div className="add-profile-video-cover add-product-video-cover">
                    <button type="button" onClick={AddLibrary}>
                      <img src={Images.Plus} alt="add library" />
                    </button>
                  </div>
                  <div className="product-footer">
                    <p className="add-value" />
                    <span className="add-value" />
                  </div>
                </div>
              ) : isLibraryLoading ? (
                <div className="awards-item">
                  <div className="add-profile-video-cover add-product-video-cover">
                    <button type="button">
                      <img src={Images.Plus} alt="add library" />
                    </button>
                  </div>
                  <div className="product-footer">
                    <p className="add-value">
                      <Skeleton className="add-value" width={202} height={35} />
                    </p>
                    <span className="add-value">
                      <Skeleton className="add-value" width={67} height={35} />
                    </span>
                  </div>
                </div>
              ) : (
                product?.map((data, i) => (
                  <div
                    key={i}
                    className="product-grid-item position-relative"
                    style={
                      generalProduct?.filter((item) => item.id === data.id)
                        .length > 0
                        ? { cursor: 'not-allowed' }
                        : {}
                    }
                  >
                    <div className="image-buttons z-1">
                      <label>
                        <button
                          type="button"
                          className="d-none"
                          onClick={() => EditLibrary(data)}
                        />
                      </label>
                      <button
                        type="button"
                        className="p-0"
                        onClick={deleteLibraryConfirm}
                      />
                      {ShowLibraryConfirmModel && (
                        <div className="add-item-model">
                          <div className="add-item-contents h-auto w-auto">
                            <div className="item-details-input pt-0">
                              <h3 className="mb-0 text-center">
                                {English.G119}
                              </h3>
                              <div className="button-row justify-content-center">
                                <button
                                  type="button"
                                  className="secondary-btn-modelConfirm"
                                  onClick={HandleCancelConfirm}
                                >
                                  <span>{English.G116}</span>
                                </button>
                                <button
                                  type="button"
                                  className="primary-btn-modelConfirm"
                                  onClick={() => {
                                    setProDeleteIndex(i)
                                    deleteProductLib(data)
                                  }}
                                >
                                  {English.G120}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {loading && proDeleteIndex === i && <Loader />}
                    <div
                      onClick={() => GeneralSubmit(data)}
                      style={
                        generalProduct?.filter((item) => item.id === data.id)
                          .length > 0
                          ? {
                              pointerEvents: 'none',
                              cursor: 'progress',
                              opacity: 0.6
                            }
                          : {}
                      }
                    >
                      <div
                        className="product-item-cover"
                        style={{
                          backgroundImage: `url(${urlImageProductPathStore + data.image})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                      >
                        <div className="rating">
                          <img src={Images.RatingStar} alt="star" />
                          <span>4.9</span>
                        </div>
                      </div>
                      <div className="product-item-details d-flex  justify-content-between">
                        <span>{data.name}</span>
                        <div>
                          {data.discount_price > 0 ? (
                            <>
                              <h4>{data.discount_price}</h4>
                              <h4 className="actual-price">
                                {data.regular_price}
                              </h4>
                            </>
                          ) : (
                            <h4>{data.regular_price}</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div ref={modelFooterRef} className="item-details-input pt-0">
            <div className="button-row store_sticky_btn pt-4 pb-4">
              <CommonButton
                label={English.G116}
                type="button"
                onClick={HandleCancel}
                isPrimary={false}
                context={English.G232}
              />
            </div>
          </div>
        </div>
      </div>
      {ShowProduct && (
        <AddEditProductLibaryModal
          isProductEdit={EditProduct}
          setIsProductEdit={setEditProduct}
          setShowProduct={setShowProduct}
          onComplete={() => {
            if (EditProduct) {
              onSubmited()
            }
            ProductLibrary()
          }}
          editData={editData}
        />
      )}
    </>
  )
}

export default ProductLibaryModal
