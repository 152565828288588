import React from 'react'
import { Images } from '../../../../helpers'

const MemberPlanComponent = () => {
  return (
    <div className="grid grid-cols-4 gap-7 max-[1440px]:!gap-4">
      <div className="bg-white shadow-lg !p-5 rounded-[24px] max-[1440px]:!p-3">
        <div>
          <img className="w-full rounded-[24px]" src={Images.LiteMember} alt="" />
        </div>
        <div className="text-center my-7">
          <button type="button" className="w-[285px] text-xl text-white font-bold text-center rounded-[50px] bg-lightGradient h-14 max-[1600px]:w-auto px-6 max-[1600px]:text-base">ab 6,99 € / Monat</button>
        </div>
        <ul className="flex flex-col gap-2 max-[1440px]:!ps-0">
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1 Pro App deiner Wahl</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Pro Verifizierung der Member ID.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1,2 Point Multiplikator.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Inklusive 750 Points pro Monat.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Early Bird Angebote.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Werbereduziert in allen Apps</li>
        </ul>
      </div>
      <div className="bg-white shadow-lg !p-5 rounded-[24px] max-[1440px]:!p-3">
        <div>
          <img className="w-full rounded-[24px]" src={Images.BlueMember} alt="" />
        </div>
        <div className="text-center my-7">
          <button type="button" className="w-[285px] text-xl text-white font-bold text-center rounded-[50px] bg-BlueGred h-14 max-[1600px]:w-auto px-6 max-[1600px]:text-base">ab 6,99 € / Monat</button>
        </div>
        <ul className="flex flex-col gap-2 max-[1440px]:!ps-0">
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1 Pro App deiner Wahl</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Pro Verifizierung der Member ID.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1,2 Point Multiplikator.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Inklusive 750 Points pro Monat.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Early Bird Angebote.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Werbereduziert in allen Apps</li>
        </ul>
      </div>
      <div className="bg-white shadow-lg !p-5 rounded-[24px] max-[1440px]:!p-3">
        <div>
          <img className="w-full rounded-[24px]" src={Images.PremiumMember} alt="" />
        </div>
        <div className="text-center my-7">
          <button type="button" className="w-[285px] text-xl text-white font-bold text-center rounded-[50px] bg-electricPink h-14 max-[1600px]:w-auto px-6 max-[1600px]:text-base">ab 12,99 € / Monat</button>
        </div>
        <ul className="flex flex-col gap-2 max-[1440px]:!ps-0">
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1 Pro App deiner Wahl</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Pro Verifizierung der Member ID.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1,2 Point Multiplikator.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Inklusive 750 Points pro Monat.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Early Bird Angebote.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Werbereduziert in allen Apps</li>
        </ul>
      </div>
      <div className="bg-white shadow-lg !p-5 rounded-[24px] max-[1440px]:!p-3">
        <div>
          <img className="w-full rounded-[24px]" src={Images.PlatinumMember} alt="" />
        </div>
        <div className="text-center my-7">
          <button type="button" className="w-[285px] text-xl text-white font-bold text-center rounded-[50px] bg-primaryGradient h-14 max-[1600px]:w-auto px-6 max-[1600px]:text-base">ab 19,99 € / Monat</button>
        </div>
        <ul className="flex flex-col gap-2 max-[1440px]:!ps-0">
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1 Pro App deiner Wahl</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Pro Verifizierung der Member ID.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">1,2 Point Multiplikator.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">Inklusive 750 Points pro Monat.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="available text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1600px]:text-base max-[1440px]:text-sm">2,5% Discount auf Points.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Early Bird Angebote.</li>
          <li className="unavailable text-2xl bg-clip-text text-transparent bg-DarkRed max-[1600px]:text-base max-[1440px]:text-sm">Werbereduziert in allen Apps</li>
        </ul>
      </div>
    </div>
  )
}

export default MemberPlanComponent
