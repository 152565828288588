import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from '../sidebar/SidebarBuilder'
import ProfileHeadCreator from '../ProfileBuilder/ProfileHeadCreator'
import ProfileHeadStore from '../ProfileBuilder/ProfileHeadStore'
import ProfileHeadLocal from '../ProfileBuilder/ProfileHeadLocal'

const LayoutBuilder = () => {
  const location = useLocation()
  const [activePlatform, setActivePlatform] = useState({})

  return (
    <>
      <div className="sidebar-wrapper">
        <Sidebar onPlatformChange={setActivePlatform} />
      </div>
      <div key={activePlatform?.id || 1} className="creator-profile-main creator-profile-create pb-0">
        {(location.pathname.includes('profile-builder') || location.pathname.includes('community-builder')) && (
        <ProfileHeadCreator />
        )}
        {location.pathname.includes('store-builder') && (
          <ProfileHeadStore />
        )}
        {location.pathname.includes('local-builder') && (
          <ProfileHeadLocal />
        )}
      </div>
      <Outlet />
    </>
  )
}
export default LayoutBuilder
