/* eslint-disable no-nested-ternary */
import React from 'react'

const TitleComponent = ({ title, subTitle, description, userType = 1 }) => {
  return (
    <div>
      <h2 className={`bg-clip-text text-transparent ${userType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} leading-none font-black italic text-[80px] text-center max-[1600px]:text-[62px] max-[1440px]:text-[48px]`}>{title}</h2>
      {subTitle && <p className={`bg-clip-text text-transparent ${userType === 2 ? 'bg-textDodgerBlue' : userType === 3 ? 'bg-electricWhite' : 'bg-electricPink'} leading-none font-black italic text-[56px] text-center max-[1600px]:text-[42px] max-[1440px]:text-[32px]`}>{subTitle}</p>}
      {description && <p className="bg-clip-text text-transparent bg-primaryGradient text-[24px] text-center mt-7 max-w-[1100px] mx-auto max-[1600px]:text-[20px] max-[1600px]:!mt-5 max-[1440px]:text-[18px]">{description}</p>}
    </div>
  )
}

export default TitleComponent
