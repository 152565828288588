import React from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'

// userType == 1 or 2 same or 3 -> business (dark blue)
const AccordionComponent = ({ data, userType }) => {
  console.log(userType)
  return (
    <Accordion>
      {data.map((item, index) => {
        return (
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>{item.description}</p>
            </AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default AccordionComponent
