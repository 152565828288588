/* eslint-disable no-dupe-else-if */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Utility from '../helpers/Utility'

const RouteGuards = ({ component }) => {
  const [status, setStatus] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const token = Utility.getToken()
  const UserData = useSelector((state) => state.user?.userData)
  const user_type = useSelector((state) => state.user?.user_type)
  const onBoardPage = useSelector((state) => state.user?.onBoardPage)
  const businessInfo = useSelector((state) => state.user?.businessInfo)

  useEffect(() => {
    if (token) {
      // UserData = JSON.parse(UserData)
      if (UserData.email_verified === 0) {
        navigate('/otp-verification')
      } else if (!user_type) {
        navigate('/user-type-select')
      } else if (user_type === 'creator') {
        if (!onBoardPage && UserData?.creator_data === null) {
          navigate('/user-information')
        } else if (UserData?.creator_data && UserData?.creator_data?.user_type_id === 0) {
          navigate('/creator-slide')
        } else if (UserData?.creator_data?.user_type_id === 0) {
          navigate('/creator-create-profile')
        } else if (location.pathname === '/creator-slide' && UserData?.creator_data?.user_type_id !== 0) {
          navigate('/creator-slide')
        } else {
          navigate('/dashboard')
        }
      } else if (user_type === 'business') {
        if (!onBoardPage && UserData?.business_data === null && businessInfo === null) {
          navigate('/business-setup')
        } else if (
          !onBoardPage &&
          (UserData?.business_data === null || UserData?.business_data === undefined) &&
          businessInfo === true
        ) {
          navigate('/business-legal')
        } else if (UserData?.business_data && UserData?.business_data?.user_type_id === 0) {
          navigate('/business-slide')
        } else if (UserData?.business_data?.user_type_id === 0) {
          navigate('/create-profile')
        } else {
          navigate('/dashboard')
        }
      } else if (user_type === 'member') {
        if (!onBoardPage && UserData?.member_data === null) {
          navigate('/member-slide')
        } else {
          navigate('/dashboard')
        }
      } else {
        navigate('/dashboard')
      }
    }
    setStatus(true)
  }, [component])

  return status ? component : null
}

export default RouteGuards
