import React from 'react'

const SecondaryButton = ({ text, onClick, bgClass, txtClass, txtStyle, bgStyle }) => {
  return (
    <button
      onClick={onClick}
      className={`${bgClass} bg-primaryGradient rounded-3xl gradient-border`}
      style={bgStyle}
      type="button"
    >
      <div className="bg-electricWhite rounded-3xl pl-5 pr-5 pt-2 pb-2">
        <div
          style={txtStyle}
          className={`${txtClass} bg-primaryGradient font-black pr-1 italic bg-clip-text text-transparent uppercase text-base`}
        >
          {text}
        </div>
      </div>
    </button>)
}

export default SecondaryButton
