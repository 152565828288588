import React, { useState, useEffect, useRef } from 'react'
import './profile.css'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import {
  s3ImageProfile,
  urlSystemIcon
} from '../../services/Service'
import { setUserSliceData } from '../../services/http-services'
import 'react-loading-skeleton/dist/skeleton.css'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const Profiles = () => {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visibleCount, setVisibleCount] = useState(6)
  const user_type = useSelector((state) => state.user?.user_type)

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 6)
  }

  const navigate = useNavigate()
  const profilesInitialized = useRef(false)

  const getUserProfiles = async () => {
    setLoading(true)
    APICall('get', '', `${EndPoints.userProfile}?type=${user_type}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setUserData(res?.decryptedData)
          setLoading(false)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const onEditClick = (userData) => {
    setUserSliceData(userData?.application_data, 'appId')
    if (userData.add_ons_data[0].name === 'Store') {
      navigate(`/store-builder/${userData.user_id}`)
    } else if (userData.add_ons_data[0].name === 'Entertainment') {
      navigate(`/profile-builder/${userData.user_id}`)
    } else if (userData.add_ons_data[0].name === 'Community') {
      navigate(`/community-builder/${userData.user_id}`)
    } else if (userData.add_ons_data[0].name === 'Local') {
      navigate(`/local-builder/${userData.user_id}`)
    }
  }

  useEffect(() => {
    if (!profilesInitialized.current) {
      profilesInitialized.current = true
      getUserProfiles()
    }
  }, [])

  return (
    <div className="profiles-section">
      <div className="profiles-section-sub">
        <div className="d-flex align-items-center justify-content-between profiles-btn">
          <h1>{English.G99}</h1>
          <div className="profile-button-group">
            <CommonButton
              label={English.G103}
              imgSrc={Images.PlusIco}
              onClick={() => navigate('/create-profile')}
              isPrimary
              className="add_new_content primary-btn disabledDropdown"
            />
            <Dropdown
              title="Profile type"
              customClass="profile-type-dropdown disabledDropdown"
            />
          </div>
        </div>
        <p className="sub-text">{English.G100}</p>
        <div className="step-4">
          {loading ? (
            <div className="add-ons">
              <div className="add-ons-main-ing">
                <Skeleton height={150} width={200} />
              </div>
              <div className="footer-row">
                <div className="images-block profile_addons">
                  <Skeleton height={50} width={50} />
                </div>
                <div className="add-ons-detail">
                  <h4>
                    <Skeleton width={100} />
                  </h4>
                  <p>
                    <Skeleton width={150} />
                  </p>
                  <p className="addons_name">
                    <Skeleton width={120} />
                  </p>
                  <button
                    type="button"
                    disabled
                    className="secondary-btn-model border border-0 mt-3"
                  >
                    <Skeleton width={80} height={25} />
                  </button>
                </div>
              </div>

              <div className="custome-badge shadow-none">
                <Skeleton className="rounded-3" width={80} height={30} />
              </div>

              <button
                type="button"
                className="btn-white border border-0 shadow-none"
              >
                <Skeleton className="rounded-3" width={100} height={30} />
              </button>
            </div>
          ) : (
            <>
              {userData.slice(0, visibleCount).map((i, index) => (
                <div key={index} className="add-ons">
                  <div className="add-ons-main-ing">
                    <img
                      className="object-fit-contain"
                      alt=""
                      src={s3ImageProfile + i.icon}
                    />
                  </div>
                  <div className="footer-row">
                    <div className="images-block profile_addons">
                      <img
                        className="app_icon_img"
                        alt=""
                        src={urlSystemIcon + i.application_data.icon}
                      />
                    </div>
                    <div className="add-ons-detail">
                      <h4>{i.user_name}</h4>
                      <p>{i.full_name}</p>
                      {/* <p className="addons_name">{i?.add_ons_data?.name}</p> */}
                      {/* <CommonButton
                      label={English.G127}
                      type="button"
                      isPrimary={false}
                      className="mt-2"
                      disabled
                    /> */}
                    </div>
                  </div>
                  <div className="custome-badge badge-pending">
                    {English.G101}
                  </div>
                  <button
                    type="button"
                    onClick={() => onEditClick(i)}
                    className="btn-white"
                  >
                    <img className="" src={Images.EditIco} alt="" />
                    <span className="edit_profile_text font-weight-bold">
                      {English.G102}
                    </span>
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
        {visibleCount < userData.length && (
          <div className="collapse-button text-center mt-4">
            <button type="button" onClick={handleShowMore}>
              <img className="downIcon" alt="Show more" src={Images.DownIco} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Profiles
