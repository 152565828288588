import React from 'react'
import './ServiceCenter.css'
import { Link } from 'react-router-dom'
import SupportCetegory from './SupportCetegory'
import Tutorial from './Tutorial'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const ServiceCenter = () => {
  return (
    <div className="creator-profile-main">
      <div className="service-center-main-container">
        <img alt="" src={Images.AuthLogo} />
        <h1>How Can We Help You?</h1>
        <div className="position-input">
          <input placeholder="Describe your issue" />
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra
          luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque
          nullam sit amet, lorem.
        </p>
        <Link>Learn more</Link>
      </div>

      <div className="support-cetegory mt-40">
        <SupportCetegory />
      </div>

      <div className="frequenty-asked-questions mt-40">
        <h3>Frequently Asked Questions</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc habitant
          sit luctus diam, tincidunt. Sed.
        </p>
        <button type="button" className="light-button">
          <span>{English.G423}</span>
        </button>
      </div>

      <div className="service-tutorial mt-40">
        <Tutorial />
      </div>

      <div>
        <button type="button">
          <span>Hire Expert</span>
        </button>
      </div>
    </div>
  )
}
export default ServiceCenter
