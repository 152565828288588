import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import { Navigation } from 'swiper/modules'
import { English, Images } from '../../../helpers'
import { setUserSliceData } from '../../../services/http-services'

const BusinessSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = useNavigate()

  const slides = [
    {
      Image: Images.creator_slider_Image1,
      title: English.A84,
      description: English.A85
    },
    {
      Image: Images.Business_slider_Image1,
      title: English.A86,
      description: English.A87
    }
  ]
  const slideRef = useRef(null)
  const goToSlide = (index) => {
    setCurrentSlide(index)
    if (slideRef.current && slideRef.current.swiper) {
      slideRef.current.swiper.slideTo(index)
    }
  }

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-white">
      <img
        src={Images.Business_slider_bg}
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      <div className="w-[60%] h-1/2 bg-electricWhite rounded-3xl overflow-hidden shadow-lg z-10 bg_business_slider">
        <Swiper
          ref={slideRef}
          navigation
          modules={[Navigation]}
          pagination={{ clickable: false, type: 'custom' }}
          spaceBetween={50}
          mousewheel
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          className="bg-opacity-90 m-[50px]"
          onSnapIndexChange={({ activeIndex }) => setCurrentSlide(activeIndex)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              className="d-flex items-center justify-center"
              key={index}
            >
              <div className="w-[600px] flex-col items-center justify-center">
                <img
                  src={slide.Image}
                  alt="Slide"
                  className="w-[600px] h-[250px] mb-4"
                />
                <h2 className="text-3xl italic text-center text-[#ffff] font-black business_slide_h2">
                  {slide.title}
                </h2>
                <p className="mt-2 text-[#ffff] text-center text-lg font-medium business_slide_p">
                  {slide.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="z-20 mt-[5%] w-[60%] relative">
        <div className="flex space-x-4 items-center justify-center">
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              onClick={() => goToSlide(index)}
              className={`transition ${
                currentSlide === index
                  ? 'bg-[#ffff] w-6 h-3 rounded-2xl'
                  : 'bg-[#C7D5E0] shadow-md w-3 h-3 rounded-full'
              }`}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>
        {currentSlide === 1 && (
          <p className="z-20 mt-[2%] absolute right-0 top-[50%] -translate-y-[50%]">
            {/* <CommonButton
              label={English.A69}
              loading={false}
              onClick={() => {
                navigate('/create-profile')
                setUserSliceData('true', 'onBoardPage')
              }}
              className="primary-button m-auto login_button"
              context="primary"
              isPrimary
            /> */}
            <img
              className="pb-5 cursor-pointer"
              src={Images.arrow_left_white}
              alt="arrow"
              loading={false}
              onClick={() => {
                navigate('/create-profile')
                setUserSliceData('true', 'onBoardPage')
              }}
            />
          </p>
        )}
      </div>
      {/* <div className="relative flex flex-col items-center
      w-[914px] h-[500px] p-6 bg_business_slider
       bg-opacity-90 rounded-[20px] shadow-lg z-10 justify-center">
        <img
          src={slides[currentSlide].Image}
          alt="Slide"
          className="w-[600px] h-[250px] mb-4"
        />
        <h2 className="text-3xl italic text-center text-[#ffff] font-black business_slide_h2">
          {slides[currentSlide].title}
        </h2>
        <p className="mt-2 text-[#ffff] text-center text-lg font-medium business_slide_p">
          {slides[currentSlide].description}
        </p>
      </div> */}
    </div>
  )
}

export default BusinessSlider
