const English = {
  G1: 'Please enter valid email',
  G2: 'Please enter username',
  G3: 'Please enter password',
  G4: 'WELCOME BACK!',
  G5: 'APP WORLD ONE ID *',
  G6: 'ID Username',
  G7: 'Password *',
  G8: 'ID Password',
  G08: 'Re-write ID Password',
  G9: 'Forgotten Password?',
  G10: "Don't have a business account?",
  G11: 'Sign up',
  G12: 'Log in',
  G13: 'WELCOME TO APP WORLD ONE BUSINESS!',
  G14: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst sagittis vitae lobortis a ultrices porttitor. Hendrerit massa, erat bibendum elementum tempor. Consequat egestas massa rhoncus odio scelerisque amet.',
  G15: 'APP WORLD ONE Username *',
  G16: 'Email *',
  G17: 'ID E-mail',
  G18: 'Phone Number *',
  G19: 'Phone Number',
  G20: 'Re-enter Password *',
  G21: 'CREATE ID',
  G22: 'Already have an APP WORLD ONE ID ?',
  G23: 'User name must only alphabet allowed and characters.',
  G24: 'Invalid phone number',
  G25: 'Your password must contain, letters, numbers & Special Characters.',
  G26: 'Password did not match!',
  G27: 'An error occurred. Please try again.',
  G28: 'Please enter email',
  G29: 'Find Your Account',
  G30: 'Find your app world one account',
  G31: 'Please enter your email address to receive a new OTP',
  G32: 'Submit',
  G33: 'Please enter 6 digit otp',
  G34: 'Otp Send Successfully.',
  G35: 'SET NEW APP WORLD ONE PASSWORD',
  G36: 'One Time Password *',
  G37: 'Please enter otp',
  G38: 'New Password *',
  G39: 'Please Enter Otp *',
  G40: 'Resend Otp',
  G41: 'Set Password',
  G42: 'HELLO,',
  G43: 'Switch Profile',
  G44: 'VERIFY EMAIL',
  G45: 'Login',
  G46: 'Want to upgrade your existing APP WORLD ONE Account to an APP WORLD ONE Business Account?',
  G47: 'Click here',
  G48: 'Please enter valid name',
  G49: 'Please enter valid date format',
  G50: 'Please enter valid zip code',
  G51: 'Please enter valid Vat ID',
  G52: 'App World requires everyone to be at least 15 years old before they can create an account ',
  G53: 'BUSINESS USER INFORMATION',
  G54: 'Full Name *',
  G55: 'Enter full name',
  G56: 'Date of Birth *',
  G57: 'Please choose date',
  G58: 'Company Name (optional)',
  G59: 'Enter company name',
  G60: 'Company Address',
  G61: 'Address *',
  G62: 'Enter address',
  G63: 'VAT ID *',
  G64: 'Enter vat id',
  G65: 'City *',
  G66: 'Enter city',
  G67: 'Zip Code *',
  G68: 'Enter zip code',
  G69: 'NEXT',
  G70: 'Registration Completed',
  G71: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In massaut imperdiet gravida amet, feugiat sit.',
  G72: 'OK',
  G73: 'URL format is not valid',
  G74: 'Image upload failed.',
  G75: 'Error occurred during image upload.',
  G76: 'Create Profile',
  G77: 'What kind of a profile you want to create:',
  G78: 'Back',
  G79: 'How I use my profile?',
  G80: 'User Type',
  G81: 'Edit',
  G82: 'Add Social Media Link',
  G83: 'Type here',
  G84: 'Paste here',
  G85: 'Add another',
  G86: 'Add Website Link',
  G87: 'Add Certificate',
  G88: 'Enter image URL or click upload...',
  G89: 'Upload',
  G90: 'Add Local Address',
  G91: 'Enter here',
  G92: 'Add Images for Verification',
  G93: 'Add your store link',
  G94: 'Organization Type',
  G95: 'Add Title',
  G96: 'Join',
  G97: 'Choose Add ons:',
  G98: 'Create',
  G99: 'Profiles',
  G100: 'Here is an overview of your Profiles so far. Click on which you want to see a preview of and customize it to your needs!',
  G101: 'Pending',
  G102: 'Edit Profile',
  G103: 'Create New Profile',
  G104: 'Posts',
  G105: 'Followers',
  G106: 'Likes',
  G107: 'Follow +',
  G108: 'Contact',
  G109: 'Edit Store Profile',
  G110: 'Edit Creator Profile',
  G111: 'Edit Local Profile',
  G112: 'Description',
  G113: 'Local Link',
  G114: 'Store Link',
  G115: 'Social Media Link',
  G116: 'Cancel',
  G117: 'Save',
  G118: 'Gallery',
  G119: 'Do you want to delete this?',
  G120: 'Confirm',
  G121: 'Product Name',
  G122: 'Regular Price',
  G123: 'Discount Price',
  G124: 'Category',
  G125: 'Subcategory',
  G126: 'Product-Link',
  G127: 'Preview',
  G128: 'Publish',
  G129: 'Add Product',
  G130: 'Name',
  G131: 'Price',
  G132: 'Discount price',
  G133: 'Product link',
  G134: 'Select category',
  G135: 'Select subcategory',
  G136: 'Trending Products',
  G137: 'Add New Product',
  G138: 'Edit Product',
  G139: 'All Products',
  G140: 'Product Library',
  G141: 'Video',
  G142: 'Add New Video',
  G143: 'Upload Files',
  G144: 'See all',
  G145: 'see less',
  G146: 'see more',
  G147: 'Video Comment Section',
  G148: 'No Comments Yet',
  G149: 'Reply...',
  G150: 'Reply',
  G151: 'Add a comment...',
  G152: 'Add',
  G153: 'Upload Video',
  G154: 'Upload Cover',
  G155: 'Upload a New Video',
  G156: 'Drag and drop your file or click the button below to upload',
  G157: 'Podcast',
  G158: 'Add New Podcast',
  G159: 'Upload a New Podcast',
  G160: 'Upload Podcast',
  G161: 'Title of the Podcast',
  G162: 'Title',
  G163: 'Select Host',
  G164: 'Shots',
  G165: 'Add New Shot',
  G166: 'Shot Comment Section',
  G167: 'Add Text',
  G168: 'Enter a valid email',
  G169: 'Number should be 11 or 12 digits',
  G170: 'Select type',
  G171: 'Select brand',
  G172: 'Giveaway',
  G173: 'Add New Giveaway',
  G174: 'Enter to Win',
  G175: 'Giveaway Participate List',
  G176: 'Prev',
  G177: 'Giveaway Comment Section',
  G178: 'Enter Giveaway Details',
  G179: 'Title of the Giveaway',
  G180: 'Time Left',
  G181: 'Please select',
  G182: 'Brand',
  G183: 'Type',
  G184: 'User Email',
  G185: 'Enter email',
  G186: 'Phone Number',
  G187: 'Address',
  G188: 'Tag Friends',
  G189: 'Add tag',
  G190: 'Upload Images',
  G191: 'Giveaway Details',
  G192: 'Detail',
  G193: 'See less',
  G194: 'See more',
  G195: 'Polls',
  G196: 'New Poll',
  G197: 'Voting List',
  G198: 'votes',
  G199: 'Poll Comment Section',
  G200: 'Enter Poll Details',
  G201: 'Topic Statement',
  G202: 'Select Category',
  G203: 'Select Sub-category',
  G204: 'Expiry Date',
  G205: 'Option',
  G206: 'Add Poll Option',
  G207: 'Discussions',
  G208: 'New Discussions',
  G209: 'started',
  G210: 'ago',
  G211: 'Discussions Replays',
  G212: 'Enter Discussions Detail',
  G213: 'Title of the Video',
  G214: 'Upload a Shot',
  G215: 'Upload Video Cover',
  G216: 'Upload Photo',
  G217: 'UPLOAD PICTURE(S)',
  G218: 'Upload Podcast Cover',
  G219: 'Add Logo',
  G220: 'Add Photo',
  G221: 'Install',
  G222: 'Home',
  G223: 'Feed',
  G224: 'Store',
  G225: 'Videos',
  G226: 'Podcasts',
  G227: 'Hotel',
  G228: 'Restaurant',
  G229: 'Organizer',
  G230: 'Giveaways',
  G231: 'New Giveaways',
  G232: 'Profile',
  G233: 'Workspace',
  G234: 'Content Manager',
  G235: 'My Store',
  G236: 'Local',
  G237: 'Cloud Store',
  G238: 'ADS',
  G239: 'Affiliate',
  G240: 'Service Center',
  G241: 'Settings',
  G242: 'Logout',
  G243: 'Select Platform',
  G244: 'Already Selected',
  G245: 'General Settings',
  G246: 'Install ads On',
  G247: 'Add Page',
  G248: 'View All',
  G249: 'Activity',
  G250: 'Campaigns',
  G251: 'Latest Content',
  G252: 'Insights',
  G253: 'Business Profile',
  G254: 'LOCAL MANAGER',
  G255: 'Add Location',
  G256: 'Location',
  G257: 'Add location here',
  G258: 'Add description here',
  G259: 'LOCATIONS',
  G260: 'Search Location here...',
  G261: 'Add New Location',
  G262: 'Select local type',
  G263: 'HOTEL',
  G264: 'RESTAURANT & BAR',
  G265: 'ORGANIZER',
  G266: 'STORE',
  G267: 'SERVICE PROVIDER',
  G268: 'EXCURSIONS',
  G269: 'Lorem ipsum dolor sit ame consectetur adipiscing elit.',
  G270: 'ALL HOTEL',
  G271: 'ADD NEW HOTEL',
  G272: 'Select city',
  G273: '/ Night',
  G274: 'Published',
  G275: 'Un Publish',
  G276: 'EDIT',
  G277: 'PUBLISH',
  G278: 'No link',
  G279: 'Contact Persons',
  G280: 'Add Contact Person',
  G281: 'CheckIn/CheckOut',
  G282: 'Price Range',
  G283: '€',
  G284: 'Room Types',
  G285: 'No images available',
  G286: 'Overview',
  G287: 'Rooms',
  G288: 'Discounts',
  G289: 'Contact Person',
  G290: 'local_photo',
  G291: 'Designation',
  G292: '+4915234213935',
  G293: 'ALL RESTAURANT',
  G294: 'ADD NEW RESTAURANT',
  G295: 'Timing',
  G296: 'Meals',
  G297: 'Cuisines',
  G298: 'Menu',
  G299: 'VISITORS',
  G300: 'CLICKS',
  G301: 'CONVERSION',
  G302: 'REVENUE',
  G303: 'ALL ORGANIZER',
  G304: 'ADD NEW ORGANIZER',
  G305: 'Interested Industries',
  G306: 'Events',
  G307: 'Hotel Name',
  G308: 'Enter name',
  G309: 'Country',
  G310: 'HOTEL DETAILS',
  G311: 'Province List',
  G312: 'City List',
  G313: 'Zip Code',
  G314: 'Website Link',
  G315: 'Enter website link',
  G316: 'Please Select Location In Map',
  G317: 'At least 400x400 px recommended.',
  G318: 'JPEG or PNG is allowed',
  G319: 'Minimum Price',
  G320: 'Min. price',
  G321: 'Maximum Price',
  G322: 'Max. price',
  G323: 'Opening Time',
  G324: 'Select from options',
  G325: 'Closing time cannot be earlier than opening time',
  G326: 'Briefly describe about your hotel',
  G327: 'Briefly describe about your restaurant',
  G328: 'Briefly describe about your organizer',
  G329: 'RESTAURANT PHOTOS',
  G330: 'EVENT ORGANIZER DETAILS',
  G331: 'UPLOAD FILE',
  G332: 'Organizer Name',
  G333: 'Skip',
  G334: 'ROOMS NOT ADDED',
  G335: 'You have not added any rooms yet',
  G336: 'Add Rooms',
  G337: 'Room Name',
  G338: 'Enter room name / number',
  G339: 'Person',
  G340: 'Person to allow',
  G341: 'Bathroom',
  G342: 'Sofa',
  G343: 'Number of bathroom',
  G344: 'Number of sofa',
  G345: 'TV',
  G346: 'TV inch',
  G347: '00.00 / Night',
  G348: 'Room Type',
  G349: 'Inch',
  G350: 'CREATE YOU FIRST DISCOUNT',
  G351: 'You have not created any discount codes yet',
  G352: 'Create Discount',
  G353: 'Discount Name',
  G354: 'Coupon Code',
  G355: 'Enter code',
  G356: 'Discount',
  G357: 'Enter percentage',
  G358: 'Coupon Valid Till',
  G359: 'CANCEL',
  G360: 'MENU NOT ADDED',
  G361: 'You have not added any menu yet',
  G362: 'Add Items',
  G363: 'MENU',
  G364: 'Item Name',
  G365: 'Enter item name',
  G366: 'Meal',
  G367: 'Cuisine',
  G368: '€ 00.00',
  G369: 'Briefly describe about your menu',
  G370: 'Deal',
  G371: 'CREATE YOUR FIRST DEAL',
  G372: 'You have not created any deal yet',
  G373: 'Create Deal',
  G374: 'DISCOUNTS',
  G375: 'Minimum Amount',
  G376: 'Min. Amount',
  G377: 'CREATE YOUR FIRST EVENT',
  G378: 'You have not created any event yet',
  G379: 'Create Event',
  G380: 'Event Name',
  G381: 'Enter event name',
  G382: 'Industry',
  G383: 'Date',
  G384: 'Ticket Qty.',
  G385: 'Tickets qty',
  G386: 'Select start time',
  G387: 'Closing Time',
  G388: 'Booking/Guestlist Link',
  G389: 'Booking link',
  G390: 'Briefly describe about your event',
  G391: 'Speaker',
  G392: 'Remove',
  G393: 'Performer',
  G394: 'Add Performer',
  G395: 'Dates',
  G396: 'Timings',
  G397: '(expected)',
  G398: 'Performer Person',
  G399: 'Performer Type',
  G400: 'Unlike',
  G401: 'Like',
  G402: 'Comment',
  G403: 'Share',
  G404: 'UnSave',
  G405: 'Share List',
  G406: 'Report Feed',
  G407: 'Feed Comment Section',
  G408: 'Select country',
  G409: 'Select province',
  G410: 'Max price must be greater than Min price.',
  G411: 'Amenities',
  G412: 'HOTEL PHOTOS',
  G413: 'EVENT PHOTOS',
  G414: 'RESTAURANT DETAILS',
  G415: 'Food',
  G416: 'Relaxation',
  G417: 'Movement',
  G418: 'Sustainability',
  G419: 'Personality Test',
  G420: 'Add New Question',
  G421: 'OR',
  G422: 'Add City',
  G423: 'See All',
  G424: 'Services',
  G425: 'Portfolio',
  G426: 'Jobs',
  G427: 'Reviews/Rating',
  G428: 'About',
  G429: 'Clients',
  G430: 'Remove Selected',
  G431: 'Add Client',
  G432: 'Cities',
  G433: 'Advertisements Summary',
  G434: 'Statistics',
  G435: 'Videos Statistics',
  G436: 'Views',
  G437: 'Watch Time',
  G438: 'Subscribers',
  G439: 'Community',
  G440: 'Courses',
  G441: 'Recipies',
  G442: 'Megazines',
  G443: 'Entertainment',
  G444: 'Appointment',
  G445: 'Magazines',
  G446: 'Recipes',
  G447: 'See More',
  G448: 'Donations',
  G449: 'Service/Appointment',
  G450: 'Tickets/Booking',
  G451: 'Book a Table',
  G452: 'Reviews',
  G453: 'Deals',
  G454: 'Booking',
  G455: 'Offers',
  G456: 'Team',
  G457: 'Ads',
  G458: 'Upload a Photo',
  G459: 'Upload File',
  G460: 'Upload a File',
  G461: 'Title of Award',
  G462: 'technical error Please try again!',

  // new Design 4.0
  A1: 'App World One',
  A2: 'Profile Builder',
  A3: 'Apps',
  A4: 'Wallet',
  A5: 'Affiliate',
  A6: 'Produktion',
  A7: 'APP WORLD ONE ID',
  A8: 'SERVE IDENTITY FOR UP TO 16 APPS',
  A9: 'We want to tailor your experience so you’ll feel right at home. Choose your user type. Mit der App World One ID können bis zu 3 verschiedene Nutzerarten erstellt werden.Mit der App World One ID können bis zu 3 verschiedene',
  A10: 'CREATE YOUR MEMBER ID HERE',
  A11: 'MORE ABOUT THE MEMBER ID',
  A12: 'MEMBER',
  A13: 'CREATOR',
  A14: 'BUSINESS',
  A15: 'SERVE ALL IN ONE ENTERTAINMENT PLATFORM',
  A16: 'SERVE ALL IN ONE PLATFORM FOR CRETOR',
  A17: 'THE PUBLISHING SOLUTION FOR YOUR COMPANY',

  B18: 'Priese',
  B19: 'Ads',
  B20: 'APP WORLD ONE BUSINESS',
  B21: 'APP WORLD ONE',
  B22: 'Present your company with business profiles on App World One apps. Present your company with business profiles on App World One apps. Present your company with business profiles on App World One apps. Present your company with business profiles on App World One apps.',
  B23: 'Your company with business profiles on the App World One apps',
  B24: 'APP WORLD ONE ID',
  B25: 'CREATE',
  B26: 'Want to use same username across all platforms',
  B27: 'GET ACCESS FOR FREE TO ALL APPS WITH JUST ONE APP WORLD ONE ID',
  B28: 'TYPES OF USERS',
  B29: 'THE APP WORLD ONE',
  B30: 'The World One ID app can be used to manage up to 3 different types of users...',
  B31: 'USE ALL APPS PRIVATELY',
  B32: 'BUILD YOUR COMMUNITIES',
  B33: 'PRESENT YOUR COMPANY',
  B34: 'Share content privately with friends and consume content from creators and business users.',
  B35: 'Unlock features, create your public creator profiles and build your communities.',
  B36: 'Present your company with business profiles on App World One apps.',
  B37: 'CONTINUE AS A MEMBER',
  B38: 'CONTINUE AS A CREATOR',
  B39: 'CONTINUE AS A BUSINESS',
  B40: 'POINTS',
  B41: 'Member info',
  B42: 'We want to tailor your experience so you’ll feel right at home. Choose your user type. Mit der App World One ID können bis zu 3 verschiedene Nutzerarten.',
  B43: 'My Apps',
  B44: 'APP WORLD ONE ID ALS MEMBER',
  B45: 'YOUR IDENTITY FOR UP TO 16 APPS',
  B46: 'Create your App World One ID for the App World One apps with a username and password and benefit from a quick registration process.',
  B47: 'USERNAME',
  B48: 'Set a unique username for all apps and display it in your profiles.',
  B49: 'PASSWORD',
  B50: 'Use a strong password and log in on multiple devices.',
  B51: 'VERIFICATION',
  B52: 'Prove your identity with uncomplicated verification and ensure your trustworthiness.',
  B53: 'ABO',
  B54: 'Unlock premium functions with a subscription and use the app(s) of your choice in the Pro version.',
  B55: 'HOW IT WORKS',
  B56: 'BECOME A FREE MEMBER OF APP WORLD ONE',
  B57: 'LITE MEMBER',
  B58: 'Next',
  B59: 'Select the Platform(s) you want to be active on',
  B60: 'CREATE CREATOR PROFILES',
  B61: 'Add Social Media Links',

  A18: 'SIGN IN TO APP WORLD ONE',
  A19: 'Business ID is your username that you will use in Business Manager. You use this ID to represent yourself',
  A20: 'APP WORLD ONE ID',
  A21: 'Password',
  A22: 'LOGIN',
  A23: 'Forgot password',
  A24: 'You don’t have an App World One ID yet?',
  A25: 'CREATE APP WORLD ONE ID',
  A26: 'Stay logged in',
  A27: 'APP WORLD ONE Email',
  A28: 'BACK TO LOGIN',
  A29: 'RESET PASSWORD ',
  A30: 'OF YOUR APP WORLD ONE BUSINESS ID',
  A31: 'Please enter your App World One Business ID send request, Follow the link to update your password',
  A32: 'REQUEST PASSWORD RESEND LINK',
  A33: 'SET YOUR',
  A34: 'BUSINESS ID PASSWORD',
  A35: 'Please enter your new Password for your Business ID',
  A36: 'CHANGE PASSWORD',
  A37: 'New Password',
  A38: 'Re-Enter Your New Password',
  A39: 'RESEND OTP',
  A40: 'ENTER YOUR PASSWORD',
  A41: 'TO CONTINUE',
  A42: 'This is not You?',
  A43: 'Login As:',
  A44: 'MEMBER',
  A45: 'CREATOR',
  A46: 'BUSINESS',
  A47: 'Business ID is your username that you will use in Business Manager. You use this ID to represent yourself to other users.',
  A48: 'FORGOT YOUR PASSWORD?',
  A49: "NO PROBLEM, WE'RE HAPPY TO HELP!",
  A50: 'PROTECT YOUR ID!',
  A51: 'CHOOSE A STRONG PASSWORD',
  A52: 'SIGN UP AGAIN',
  A53: 'APP WORLD ONE',
  A54: '© 2024',
  A55: 'TO ACCESS THE BUSINESS MANAGER YOU NEED TO SETUP YOUR APP WORLD ONE ID',
  A56: 'WELCOME!',
  A57: 'APP WORLD ONE',
  A58: 'PART OF',
  A59: 'SETUP YOUR',
  A60: 'LEGAL INFORMATION',
  A61: 'BUSINESS INFORMATION',
  A62: 'Contact person*',
  A63: 'App World One ID*',
  A64: 'Telephone number',
  A65: 'Country*',
  A66: 'City*',
  A67: 'Street and House Number*',
  A68: 'Postal code*',
  A69: 'NEXT',
  A70: 'COMPANY INFORMATION*',
  A71: 'Enter',
  A72: 'rosa.tr@abc',
  A73: 'Company name*',
  A74: 'Company type*',
  A75: 'Managing Director*',
  A76: 'Contact email*',
  A77: 'HRB*',
  A78: 'Commercial Register Extract (optional)',
  A79: 'Tax Number (optional)',
  A80: 'Tax ID (optional)',
  A81: 'CREATOR INFORMATION',
  A82: 'Street*',
  A83: 'We want to tailor your experience so you’ll feel right at home. Choose your user type. Mit der App World One ID können bis zu 3 verschiedene Nutzerarten erstellt werden.Mit der App World One ID können bis zu 3 verschiedene.',
  A84: 'YOU HAVE COMPLETED THE APP WORLD ONE BUSINESS REQUEST!.',
  A85: 'We have already received your request, follow the next steps for faster verification!',
  A86: 'SET UP YOUR COMPANY PRESENCE ON APP WORLD ONE!',
  A87: 'Choose your company type, the apps you want to be active on and the add-ons for your profiles.',
  A88: 'YOU HAVE SUCCESSFULLY REGISTERED AS AN APP WORLD ONE MEMBER!',
  A89: 'We want to tailor your experience so you’ll feel right at home. Choose your user type. With the World One ID app, up to',
  A90: 'YOU CAN NOW USE ALL APP WORLD ONE APPS AS A MEMBER.',
  A91: 'BENEFIT FROM ADVANTAGES OF FREE VERIFICATION OF YOUR IDENTITY.',
  A92: 'WHAT IS VAT ID?',
  A93: 'VAT ID stands for Value-Added Tax Identification Number. It is a unique identifier assigned to businesses and individuals registered for VAT purposes in countries that implement a Value-Added Tax (VAT) system.',
  A94: 'CHOOSE THE TYPE OF YOUR BUSINESS',
  A95: 'Choose the platforms on which you want to present your company.',
  A96: 'CREATE YOUR BUSINESS PROFILES',
  A97: 'VIEW DETAILS',
  A98: 'COMPLETE REGISTRATION OF YOUR COMPANY PRESENCE',
  A99: 'App World One ID',
  A100: 'Describe your company...',
  A101: 'Verification Help (optional)',
  A102: 'ID card',
  A103: 'WEB PRESENCE',
  A104: 'COMMERCIAL REGISTER',
  A105: 'CHOOSE OTHER ADD ONS',
  A106: 'Select Add-Ons to Set Up your Profile',
  A107: 'HOW TO USE',
  A108: 'Videos',
  A109: 'Podcast',
  A110: 'Shots',
  A111: 'Live Streams',
  A112: 'UPGRADE OPTION',
  A113: 'UPGRADE NOW',
  A114: 'MAY BE LATER',
  A115: 'UPGRADE OPTION +',
  A116: 'BACK',
  A117: 'PHONE NUMBER',
  A118: 'SOCIAL MEDIA',
  A119: 'Send us a DM from your Instagram account.',
  A120: 'HOW I USE MY PROFILE',
  A121: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue tincidunt massa viverra non dignissim sed leo.',
  A122: 'VERIFICATION',
  A123: 'OPEN APP',
  A124: 'ALL APPS',
  A125: 'ACTIVE APPS',
  A126: 'INACTIVE APPS',
  A127: 'ACTIVE',
  A128: 'INACTIVE',
  A129: 'RECOMMENDATIONS',
  A130: 'Friends Recommendations:',
  A131: 'Make a presentation about the marketing strategy for Q4.',
  A132: 'Creators Recommendations:',
  A133: 'About Me',
  A134: 'ABOUT INFO',
  A135: 'YOUR NAME',
  A136: 'YOUR ID',
  A137: 'ABOUT ME',
  A138: 'LINKED APPS',
  A139: 'Friends',
  A140: 'MY FRIENDS',
  A141: 'VIEW ALL',
  A142: 'MY PROFILES',
  A143: 'While your profiles are being verified, you can upload content via the Profile Builder.',
  A144: 'YOUR INFO',
  A145: 'OPEN WALLET',
  A146: 'RUNNING JOBS',
  A147: 'OPEN JOBS',
  A148: 'NEW JOBS',
  A149: 'CLOSE JOBS',
  A150: 'inspirations',
  A151: 'Weekly Appointments',
  A152: 'Daily To Do',
  A153: 'Workspace',
  A154: 'MONTH',
  A155: 'WEEK',
  A156: 'DAILY',
  A157: 'DUE TODAY',
  A158: 'UPCOMING',
  A159: 'COMPLETED',
  A160: 'ALL',
  A161: 'WAITING',
  A162: 'INPROGRESS',
  A163: 'COMPLETE',
  A164: 'Smart Hub',
  A165: 'Your Profiles',
  A167: 'https://',
  A168: 'Please enter 11 digit company tax id',
  A169: 'Please enter 11 digit company tax number',
  A170: 'Media',
  A171: 'Contacts',
  A172: 'Recruiting',
  A173: 'CRM',
  A174: 'UPLOAD',
  A175: 'REACH',
  A176: 'HELP'

}
export default English
