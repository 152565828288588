/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadScriptNext } from '@react-google-maps/api'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  ForMobileNumber
} from '../../../helpers/Utility'
import {
  GOOGLE_MAP_LIBRARIES,
  locationSuggestion,
  s3ImageOrganizer
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Dropdown from '../../Dropdown/Dropdown'
import MapComponent from '../../MapComponent/MapComponent'
import Loader from '../../Loader/Loader'
import AutocompleteInput from '../../MapComponent/AutoComplete'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const eventPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowEventList,
  setIsShow,
  // particularEventApiCall = () => {},
  editEvent,
  isFromOrganizerComponent,
  platformData
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [inputFields, setInputFields] = useState({
    ...defaultData,
    name: '',
    description: '',
    address: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    logo: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    contact_phone1: '',
    contact_phone2: '',
    contact_profile_pic: [],
    organizer_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: ''
  })
  const [markerPosition, setMarkerPosition] = useState(null)

  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [errors, setErrors] = useState('')
  const [isStep1Modified, setIsStep1Modified] = useState(false)
  const [isStep2Modified, setIsStep2Modified] = useState(false)
  const [isStep3Modified, setIsStep3Modified] = useState(false)
  const [imagesCleared, setImagesCleared] = useState(false)
  const [initialImages, setInitialImages] = useState(defaultData.images || [])

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const clearValue = () => {
    setInputFields({
      name: '',
      description: '',
      address: '',
      city_id: '',
      zip_code: '',
      longitude: '',
      latitude: '',
      website: '',
      logo: '',
      images: [],
      contact_name1: '',
      contact_name2: '',
      contact_designation1: '',
      contact_designation2: '',
      contact_phone1: '',
      contact_phone2: '',
      contact_profile_pic: [],
      organizer_id: '',
      country_id: '',
      province_id: '',
      country: '',
      city_name: '',
      province_name: ''
    })
  }

  const handleCancelStep2 = () => {
    onCancel()
  }

  const handleCancels = () => {
    setCurrentStep(0)
    onCancel()
    clearValue()
  }

  const onChange = (e) => {
    const { name, value } = e.target

    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))

    if (name === 'website' && urlRegex.test(value)) {
      setErrors('')
    }
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const imageFile = await compressImage(file)
          const coverFormData = new FormData()
          coverFormData.append('file', imageFile)

          if (fieldName === 'contact_profile_pic') {
            coverFormData.append('type', 'organizer_contact')
          } else if (fieldName === 'logo') {
            coverFormData.append('type', 'organizer_logo')
          } else {
            coverFormData.append('type', 'organizer')
          }

          try {
            const res = await APICall(
              'post',
              coverFormData,
              '',
              'imageUploadLocal',
              true
            )
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedImageData = dataDecrypt(res.data.data)
              setInputFields((prev) => {
                if (fieldName === 'logo') {
                  return {
                    ...prev,
                    [fieldName]: decryptedImageData
                  }
                }

                const prevData = prev[fieldName] || []
                const updatedData = [...prevData]

                if (
                  index !== undefined &&
                  index !== null &&
                  Array.isArray(prevData)
                ) {
                  updatedData[index] = decryptedImageData
                } else {
                  updatedData.push(decryptedImageData)
                }

                return {
                  ...prev,
                  [fieldName]: updatedData
                }
              })

              setIsStep3Modified(true)
            } else {
              toast.error(res?.data?.message || 'Error uploading image')
            }
          } catch (err) {
            toast.error(err?.response?.data?.message || 'Upload failed')
          }
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  const handleClick = () => {
    document.getElementById('upload_button').click()
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
    setIsStep3Modified(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageOrganizer + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  // api call

  const countryListData = () => {
    APICall('get', '', `${EndPoints.countryList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCountryList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const provinceListData = (data) => {
    APICall('post', data, `${EndPoints.provinceList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsProvinceList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cityListData = (data) => {
    APICall('post', data, `${EndPoints.cityList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCityListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const organizerAddApiCall = () => {
    let payload = {
      name: inputFields.name,
      description: inputFields.description,
      address: inputFields.address,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      logo: inputFields.logo,
      images: inputFields.images,
      contact_name: [
        inputFields.contact_name1,
        inputFields.contact_name2
      ].filter((item) => item !== '' && item !== null),
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ].filter((item) => item !== '' && item !== null),
      contact_phone: [
        inputFields.contact_phone1,
        inputFields.contact_phone2
      ].filter((item) => item !== '' && item !== null),
      contact_profile_pic: inputFields.contact_profile_pic,
      organizer_id: inputFields.organizer_id,
      ...(isFromOrganizerComponent && { app_id: [platformData[0]] })
    }
    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) => !(
            value === '' ||
            (Array.isArray(value) &&
              (value.length === 0 || value.every((item) => item === '')))
          )
      )
    )

    if (Object.keys(defaultData).length > 0) {
       APICall('post', payload, `${EndPoints.editOrganizer}`, 'local')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  toast.success(res?.data?.message)
                  setTimeout(() => {
                    apiSuccess()
                  }, 3000)
                  setCurrentStep(0)
                  // particularEventApiCall(inputFields.organizer_id)
                  setIsShowEventList(true)
                  setIsShow(false)
                } else {
                  toast.error(res?.data?.message)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
              })
    } else {
      APICall('post', payload, `${EndPoints.addOrganizer}`, 'local')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  toast.success(res?.data?.message)
                  setTimeout(() => {
                    apiSuccess()
                    if (isFromOrganizerComponent) {
                      navigate(`/local-builder/${id}`)
                    } else {
                      navigate('/event')
                    }
                  }, 3000)
                  setCurrentStep(0)
                } else {
                  toast.error(res?.data?.message)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
              })
    }
  }

  const getCountryName = (country_id) => {
    const categoryObj = isCountryList.find(
      (cat) => cat.country_id === country_id
    )
    return categoryObj ? categoryObj.country_name : English.G408
  }
  const getProvinceName = (province_id) => {
    const categoryObj = isProvinceList.find(
      (cat) => cat.province_id === province_id
    )
    return categoryObj ? categoryObj.province_name : English.G409
  }
  const getCityName = (city_id) => {
    const categoryObj = isCityListData.find((cat) => cat.city_id === city_id)
    return categoryObj ? categoryObj.city_name : English.G272
  }

  useEffect(() => {
    countryListData()
  }, [])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      provinceListData({
        country_id: defaultData.country_id
      })
      cityListData({
        province_id: defaultData.province_id
      })
    }
  }, [defaultData])

  const [currentStep, setCurrentStep] = useState(1)

  const handleStartStep2 = () => {
    setErrors('')
    if (inputFields.website && !urlRegex.test(inputFields.website)) {
      setErrors(English.G73)
      return
    }
    if (currentStep === 1 && editEvent) {
      setCurrentStep(3)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleNextClick = () => {
    if (currentStep < 3) {
      if (currentStep === 1 && editEvent) {
        setCurrentStep(3)
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
      organizerAddApiCall()
    }
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      if (currentStep === 3 && editEvent) {
        setCurrentStep(1)
      } else {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  useEffect(() => {
    setIsStep1Modified(
      inputFields.logo !== defaultData.logo ||
        inputFields.name !== defaultData.name ||
        inputFields.country_id !== defaultData.country_id ||
        inputFields.province_id !== defaultData.province_id ||
        inputFields.city_id !== defaultData.city_id ||
        inputFields.zip_code !== defaultData.zip_code ||
        inputFields.website !== defaultData.website ||
        inputFields.description !== defaultData.description
    )
    setIsStep2Modified(
      inputFields.contact_profile_pic !== defaultData.contact_profile_pic ||
        inputFields.contact_name1 !== defaultData.contact_name1 ||
        inputFields.contact_name2 !== defaultData.contact_name2 ||
        inputFields.contact_designation1 !== defaultData.contact_designation1 ||
        inputFields.contact_designation2 !== defaultData.contact_designation2 ||
        inputFields.contact_phone1 !== defaultData.contact_phone1 ||
        inputFields.contact_phone2 !== defaultData.contact_phone2
    )
    // setIsStep3Modified(
    //   inputFields?.images?.length !== defaultData?.images?.length
    // )
  }, [inputFields])

  const isStep1Valid = () => {
    return (
      isStep1Modified &&
      inputFields.logo &&
      inputFields.name &&
      inputFields.country_id &&
      inputFields.province_id &&
      inputFields.city_id &&
      inputFields.zip_code.length > 4 &&
      inputFields.website &&
      inputFields.description &&
      markerPosition
    )
  }

  const isStep2Valid = () => {
    return (
      isStep2Modified &&
      inputFields.contact_profile_pic.length === 2 &&
      inputFields.contact_name1 &&
      inputFields.contact_name2 &&
      inputFields.contact_designation1 &&
      inputFields.contact_designation2 &&
      inputFields.contact_phone1.length > 10 &&
      inputFields.contact_phone2.length > 10
    )
  }
  // const isStep3Valid = () => {
  //   return isStep3Modified && inputFields.images.length > 0
  // }

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  const clearCurrentStepData = (step) => {
    switch (step) {
      case 2:
        setInputFields({
          ...inputFields,
          contact_name1: '',
          contact_designation1: '',
          contact_phone1: '',
          contact_name2: '',
          contact_designation2: '',
          contact_phone2: '',
          contact_profile_pic: ['', '']
        })
        break
      case 3:
        const hasImages = inputFields.images?.length > 0
        setInputFields({
          ...inputFields,
          images: []
        })
        if (hasImages) {
          setImagesCleared(true)
        } else {
          organizerAddApiCall()
        }
        break
      default:
        break
    }
  }

  const handleSkipClick = () => {
    if (currentStep >= 2 && currentStep <= 3) {
      clearCurrentStepData(currentStep)
      if (currentStep === 1 && editEvent) {
        setCurrentStep(3)
      } else if (currentStep < 3) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  useEffect(() => {
    if (imagesCleared) {
      organizerAddApiCall()
      setImagesCleared(false)
    }
  }, [imagesCleared])

  const isStep3Valid = () => {
    const hasImagesChanged =
      JSON.stringify(inputFields.images) !== JSON.stringify(initialImages)

    return hasImagesChanged && inputFields.images.length > 0
  }
  useEffect(() => {
    setInitialImages(defaultData.images || [])
  }, [])

  return (
    <div className="content-manager">
      {currentStep === 1 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal contact_padding">
            <div className="type_close mb-2 local_sticky_header">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Images.BackLocal}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancelStep2}
                />
                {English.G330}
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0 local_scroll_bars">
              <div className="logo_btn mb-2">
                <div className="add_logo">
                  <CommonImageUpload
                    id="upload_button"
                    name="local_upload_logo"
                    handleImageChange={(file) => onUploadImage(file, 'logo')}
                    index={0}
                    forImage="local_logo"
                    className="profile-upload"
                    selectedImg={
                      inputFields.logo
                        ? `${s3ImageOrganizer}${inputFields.logo}`
                        : ''
                    }
                    defaultImg={Images.local_add_img}
                  />
                </div>
                <div className="upload_btn_logo">
                  <CommonButton
                    label={English.G331}
                    context="local"
                    className="local-secondary-button mb-2"
                    onClick={handleClick}
                  />
                  <p className="image_validation mb-0">
                    {English.G317}
                    <br />
                    {English.G318}
                  </p>
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G332}</label>
                  <CommonInput
                    placeholder={English.G308}
                    name="name"
                    onChange={onChange}
                    value={capitalizeFirstLetter(inputFields.name)}
                  />
                </div>
                <div>
                  <label>{English.G309}</label>
                  <Dropdown
                    title={getCountryName(inputFields.country_id)}
                    name="type_id"
                    onChanged={(d) => {
                      provinceListData({
                        country_id: d.id
                      })
                      setInputFields((prev) => ({
                        ...prev,
                        country_id: d.id,
                        country: d.title
                      }))
                    }}
                    Data={isCountryList.map((d) => ({
                      title: d.country_name,
                      id: d.country_id
                    }))}
                    customClass={
                      inputFields.country_id
                        ? 'selected-class'
                        : 'local-dropdown'
                    }
                    // customClass="local-dropdowns"
                  />
                </div>
              </div>
              <div className="input-grid mb-3">
                <div>
                  <label>{English.G311}</label>
                  <Dropdown
                    title={getProvinceName(inputFields.province_id)}
                    name="type_id"
                    onChanged={(d) => {
                      cityListData({
                        province_id: d.id
                      })
                      setInputFields((prev) => ({
                        ...prev,
                        province_id: d.id,
                        province_name: d.title,
                        city: '',
                        city_name: ''
                      }))
                    }}
                    Data={isProvinceList.map((d) => ({
                      title: d.province_name,
                      id: d.province_id
                    }))}
                    customClass={
                      inputFields.province_id
                        ? 'selected-class'
                        : 'local-dropdown'
                    }
                  />
                </div>
                <div>
                  <label>{English.G312}</label>
                  <Dropdown
                    title={getCityName(inputFields.city_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        city_id: d.id,
                        city_name: d.title
                      })
                    }}
                    Data={isCityListData.map((d) => ({
                      title: d.city_name,
                      id: d.city_id
                    }))}
                    customClass={
                      inputFields.city_id ? 'selected-class' : 'local-dropdown'
                    }
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G313}</label>
                  <CommonInput
                    placeholder={English.G91}
                    name="zip_code"
                    type="number"
                    className="mb-0"
                    onChange={onChange}
                    pattern="^\d{0,5}$"
                    maxLength={5}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault()
                      }
                      if (e.target.value.length === 5) {
                        e.preventDefault()
                      }
                    }}
                    value={inputFields.zip_code}
                  />
                </div>
                <div>
                  <label>{English.G314}</label>
                  <CommonInput
                    placeholder={English.G315}
                    name="website"
                    onChange={onChange}
                    value={inputFields.website}
                  />
                  {errors && <div className="error-message">{errors}</div>}
                </div>
              </div>
              <div className="">
                <div className="local_event_description">
                  <label>{English.G112}</label>
                  <CommonInput
                    placeholder={English.G328}
                    name="description"
                    onChange={onChange}
                    isTextArea
                    value={capitalizeFirstLetter(inputFields.description)}
                    maxLength={350}
                    className="mb-4"
                  />
                </div>
              </div>
              <LoadScriptNext
                googleMapsApiKey={locationSuggestion}
                libraries={GOOGLE_MAP_LIBRARIES}
              >
                <div className="form-group">
                  <label>{English.G187}</label>
                  <AutocompleteInput
                    setInputFields={setInputFields}
                    inputFields={inputFields}
                  />
                </div>
                <div className="form-group">
                  <label>{English.G316}</label>
                  <MapComponent
                    inputFields={inputFields}
                    setInputFields={setInputFields}
                    onMapPositionChange={handleMapPositionChange}
                    markerPosition={markerPosition}
                  />
                </div>
              </LoadScriptNext>
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label={English.G78}
                  onClick={handleCancelStep2}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={handleStartStep2}
                  context={English.G232}
                  disabled={!isStep1Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal contact_padding">
            <div className="type_close mb-2 local_sticky_header">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Images.BackLocal}
                  className="back_btn"
                  alt="back"
                  onClick={handleBackClick}
                />
                {English.G289}
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0 local_contact_scroll_bars">
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                        index={0}
                        forImage={English.G290}
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[0]
                            ? `${s3ImageOrganizer}${inputFields.contact_profile_pic[0]}`
                            : ''
                        }
                        defaultImg={Images.local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>{English.G130}</label>
                    <CommonInput
                      placeholder={English.G130}
                      onChange={onChange}
                      name="contact_name1"
                      maxLength={20}
                      allowTextOnly
                      value={capitalizeFirstLetter(inputFields.contact_name1)}
                    />
                  </div>
                  <div>
                    <label>{English.G291}</label>
                    <CommonInput
                      placeholder={English.G291}
                      onChange={onChange}
                      name="contact_designation1"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation1
                      )}
                    />
                  </div>
                  <div>
                    <label>{English.G186}</label>
                    <CommonInput
                      placeholder={English.G292}
                      name="contact_phone1"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress={ForMobileNumber}
                      type="text"
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9+]/g,
                          ''
                        )
                        const maxLength = sanitizedValue.startsWith('+')
                          ? 13
                          : 12
                        if (sanitizedValue.length <= maxLength) {
                          onChange(e)
                        }
                      }}
                      value={inputFields.contact_phone1}
                    />
                  </div>
                </div>
              </div>
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 1)}
                        index={1}
                        forImage={English.G290}
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[1]
                            ? `${s3ImageOrganizer}${inputFields.contact_profile_pic[1]}`
                            : ''
                        }
                        defaultImg={Images.local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>{English.G130}</label>
                    <CommonInput
                      placeholder={English.G130}
                      onChange={onChange}
                      name="contact_name2"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(inputFields.contact_name2)}
                    />
                  </div>
                  <div>
                    <label>{English.G291}</label>
                    <CommonInput
                      placeholder={English.G291}
                      onChange={onChange}
                      name="contact_designation2"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation2
                      )}
                    />
                  </div>
                  <div>
                    <label>{English.G186}</label>
                    <CommonInput
                      placeholder={English.G292}
                      name="contact_phone2"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress={ForMobileNumber}
                      type="text"
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9+]/g,
                          ''
                        )
                        const maxLength = sanitizedValue.startsWith('+')
                          ? 13
                          : 12
                        if (sanitizedValue.length <= maxLength) {
                          onChange(e)
                        }
                      }}
                      value={inputFields.contact_phone2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label={English.G333}
                  onClick={handleSkipClick}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={handleNextClick}
                  context={English.G232}
                  disabled={!isStep2Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Images.BackLocal}
                  className="back_btn"
                  alt="back"
                  onClick={handleBackClick}
                />
                {English.G413}
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="hotel_image_upload_section">
              <div className="input-image-show image_local_last_popup">
                {renderImg}
                {inputFields.images.length < 4 && (
                  <div className="input-img-label">
                    <CommonImageUpload
                      handleImageChange={(file) => onUploadImage(file, 'images', inputFields.images.length)}
                      buttonText="Add Image"
                      selectedImg=""
                      defaultImg={Images.plusGiveaway}
                      className="plsimg"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="local_button_row">
              <CommonButton
                label={English.G333}
                onClick={handleSkipClick}
                isPrimary={false}
                context={English.G232}
              />
              <CommonButton
                label={English.G69}
                type="button"
                isPrimary
                onClick={handleNextClick}
                context={English.G232}
                disabled={!isStep3Valid()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default eventPopup
