/* eslint-disable no-undef */
/* eslint space-infix-ops: "off" */
import React, { useEffect, useState } from 'react'
import './photos.css'
import { toast } from 'react-toastify'
import 'cropperjs/dist/cropper.css'
import { useSelector } from 'react-redux'
import {
  s3ImagePostFeed
} from '../../services/Service'
import Loader from '../Loader/Loader'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import { emitter, EventType } from '../../helpers/Emitter'
import AddPhotoModal from './AddPhotoModal'
import English from '../../helpers/English'
import { Images } from '../../helpers'
import { APICall, EndPoints } from '../../services'

const AddPhotos = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [images, setImages] = useState()
  const [getImage, setGetImage] = useState([])
  const [loading, setLoading] = useState(true)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)

  const [editDate, setEditData] = useState({})
  const [ShowVideoModel, setShowVideoModel] = useState({
    show: false,
    isEditing: false
  })
  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  const AddVideo = () => {
    setShowVideoModel({ ...ShowVideoModel.isEditing, show: true })
  }

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setUpdatingIndex(null)
  }

  const fetchPhotos = (delay = 4000) => {
    setTimeout(() => {
      getPostData()
      emitter.emit(EventType.ProfileUpdate)
    }, delay)
  }

  const getPostData = () => {
    const data = {
      app_id: app_id.id
      // creator_user_id: params.id
    }
    APICall('post', data, `${EndPoints.getPost}`, 'creator')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setGetImage(res?.decryptedData.data)
      } else {
        toast.error(res?.data?.message)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
    })
  }

  const deletePhoto = (postId) => {
    const data = {
      app_id: app_id.id,
      post_id: postId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deletePost}`, 'creator')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        toast.success(res?.data?.message)
        setShowConfirmModel(false)
        fetchPhotos()
        setLoading(false)
      } else {
        setLoading(false)
        toast.error(res?.data?.message)
      }
    })
    .catch((error) => {
      setLoading(false)
      toast.error(error?.data?.message)
    })
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id|| null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPostData()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (ShowVideoModel.show || ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => unlockScroll()
  }, [ShowVideoModel.show, ShowConfirmModel])

  return (
    <div className="stories-component add-photos common-card-body">
      <div className="gallery_add_btn">
        <h3>{English.G118}</h3>
        {getImage?.length < 3 && (
          <div className="add-video-rows main_btn">
            <CommonButton
              label="Add New Image"
              imgSrc={Images.AddIco}
              onClick={AddVideo}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
      </div>

      <div className="photos-grid">
        {getImage?.length === 0 ? (
          <div className="photos-grid-item">
            <div className="image_add">
              <button type="button" onClick={AddVideo}>
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
          </div>
        ) : (
          getImage?.map((image, index) => (
            <div
              key={image.post_id}
              className="photos-grid-item photos-grid-item-added"
            >
              <img src={s3ImagePostFeed + image.attachment} alt="Uploaded" />
              <div className="image-buttons">
                <label
                  onClick={() => {
                    setEditData({
                      description: image.description,
                      app_id: app_id.id,
                      location: image.location,
                      post_id: image.post_id
                    })
                    setImages(s3ImagePostFeed + image.attachment)
                    setShowVideoModel({ show: true, isEditing: true })
                  }}
                >
                  {/* <input
                  className="d-none"
                  type="file"
                  accept="image/*"
                  // onChange={(event) => handleReplaceImage(image.key, event)}
                /> */}
                </label>
                {/* <button type="button" onClick={() => handleDeleteImage(image.key)}></button> */}
                <button type="button" onClick={ShowConfirm} />
                {ShowConfirmModel && (
                  <div className="add-item-model">
                    <div className="add-item-contents h-auto w-auto">
                      <div className="item-details-input pt-0">
                        <h3 className="mb-0 text-center">
                          {English.G119}
                        </h3>
                        <div className="button-row justify-content-center">
                          <button
                            type="button"
                            className="secondary-btn-modelConfirm"
                            onClick={HandleCancelConfirm}
                          >
                            <span>{English.G116}</span>
                          </button>
                          <button
                            type="button"
                            className="primary-btn-modelConfirm"
                            onClick={() => {
                              setUpdatingIndex(index)
                              deletePhoto(image.post_id)
                            }}
                          >
                            {English.G120}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {loading && updatingIndex === index && <Loader />}
            </div>
          ))
        )}
      </div>
      {ShowVideoModel.show && (
        <AddPhotoModal
          key="1"
          images={images}
          editDate={editDate}
          setImages={setImages}
          ShowVideoModel={ShowVideoModel}
          setShowVideoModel={setShowVideoModel}
          onCompletd={() => fetchPhotos()}
        />
      )}
    </div>
  )
}
export default AddPhotos
