import React from 'react'
import './RecentPodcast.css'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RecentPodcast = () => {
  const items = [
    {
      cover: './images/podcast4.png',
      profile: './images/task-profile2.png',
      text: 'Podcast Title Best Podcast Ever Ever Made',
      name: 'Courtney Henry',
      Rating: '4.9'
    },
    {
      cover: './images/podcast3.png',
      profile: './images/task-profile2.png',
      text: 'Podcast Title Best Podcast Ever Ever Made',
      name: 'Courtney Henry',
      Rating: '4.9'
    },
    {
      cover: './images/podcast2.png',
      profile: './images/task-profile2.png',
      text: 'Podcast Title Best Podcast Ever Ever Made',
      name: 'Courtney Henry',
      Rating: '4.9'
    }
  ]
  return (
    <div className="recent-video-component recent-podcast common-card-body">
      <h3>
        Podcasts
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="recent-podcast-content">
        {items.map((item) => (
          <div
            className="recent-podcast-item"
            key={item.name}
            style={{
              background: `url(${item.cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="rating">
              <img alt="" src={Images.RatingStar} />
              <span>{item.Rating}</span>
            </div>
            <div className="podcast-item-detail">
              <p>{item.text}</p>
              <div className="podcast-item-profile">
                <img alt="" src={item.profile} />
                {item.name}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RecentPodcast
