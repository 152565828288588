/* eslint-disable no-control-regex */
/* eslint-disable no-return-assign */
import React, { useRef } from 'react'
import { Autocomplete } from '@react-google-maps/api'
import { normalizeAddress } from '../../helpers/Utility'
import English from '../../helpers/English'

const AutocompleteInput = ({ setInputFields, inputFields }) => {
  const autocompleteRef = useRef(null)

  const handlePlaceSelected = () => {
    const place = autocompleteRef.current.getPlace()

    if (place && place.geometry) {
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      const formattedAddress = place.formatted_address

      setInputFields((prev) => ({
        ...prev,
        address: normalizeAddress(formattedAddress),
        latitude: lat,
        longitude: lng
      }))
    } else {
      console.error('No geometry data available for the selected place.')
    }
  }

  return (
    <Autocomplete
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceSelected}
      options={{
        componentRestrictions: { country: 'DE' }
      }}
    >
      <input
        type="text"
        placeholder={English.G257}
        value={inputFields.address}
        onChange={(e) => setInputFields({
          ...inputFields,
          address: normalizeAddress(e.target.value)
        })}
        className="form-control"
      />
    </Autocomplete>
  )
}

export default AutocompleteInput
