import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const RecentEvent = () => {
  const items = [
    {
      cover: './images/event/1.jpg',
      name: 'Ballet Festival',
      date: '23 may, 2022',
      location: 'London, United Kingdom',
      rating: '4.9'
    },
    {
      cover: './images/event/2.jpg',
      name: 'World Festival',
      date: '23 may, 2022',
      location: 'London, United Kingdom',
      rating: '4.9'
    },
    {
      cover: './images/event/2.jpg',
      name: 'Ballet Festival',
      date: '23 may, 2022',
      location: 'London, United Kingdom',
      rating: '4.9'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component ">
      <h3 className="d-flex align-items-center justify-content-between">
        Recent Events
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="recent-event-grid">
        {items.map((item) => (
          <div key={item.name} className="recent-event-item">
            <div
              className="recent-event-cover"
              style={{
                background: `url(${item.cover})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>{item.rating}</span>
              </div>
            </div>
            <div className="recent-event-detail">
              <div className="event--name--date">
                <div className="event--name">{item.name}</div>
                <div className="event-date">{item.date}</div>
              </div>
              <p className="event-location">
                <img alt="" src={Images.Location} />
                <span>{item.location}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RecentEvent
