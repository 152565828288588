import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RecipiesDetails = () => {
  const items = [
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    }
  ]

  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }

  return (
    <div className="podcast-details-manage recent-video-component expert-recipes">
      <div className="megazines-slider position-relative">
        <Swiper
          slidesPerView={1}
          navigation
          modules={[Navigation]}
          className="mySwiper-story position-static"
        >
          <SwiperSlide>
            <div className="recipes-slider-item">
              <div className="recipes-slier-item-detail">
                <h4>Sto pizza with roasted potatos</h4>
                <div className="recipies-profile">
                  <img src="./images/profile.png" alt="" />
                  <p>Jens Peter</p>
                  <span>|</span>
                  <div className="linear-rating">
                    <img src={Images.RatingStar} alt="" />
                    4.9
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Cursus pellentesque dui mi habitasse mi ultrices. Velit, urna,
                  nunc, tortor tellus amet aenean faucibus lobortis. Vehicula
                  cras lobortis.
                </p>
                <div className="cooking-info">
                  <h5>Cooking Info</h5>
                  <div>
                    <p>
                      <img src={Images.time} alt="" />
                      Cooking Time
                    </p>
                    <p>
                      <img src={Images.service} alt="" />
                      4 Servings
                    </p>
                    <p>
                      <img src={Images.vegitarian} alt="" />
                      Vegitarian
                    </p>
                  </div>
                </div>
                <button type="button" className="primary-btn">Full Recipe</button>
              </div>
              <div
                className="recipes-slier-item-detail"
                style={{
                  background: 'url(./images/recipesslider.jpg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '0 15px 15px 0',
                  padding: '0'
                }}
              >
                <div className="like">
                  <img src={Images.UnLikes} alt="" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="common-card-body">
        <div className="add-video-row">
          <h3 className="m-0">{English.G446}</h3>
          <div className="items-filter">
            <div className="position-relative input-position">
              <input placeholder="Search Project" />
            </div>
            <Dropdown
              title="Filter"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
          </div>
        </div>
        <div className="recipies-item-add">
          {items.slice(0, visibleItems).map((item) => (
            <div className="recipies-item-detail" key={item.name}>
              <div className="video-item">
                <div
                  className="video-cover"
                  style={{
                    background: `url(${item.CoverImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  <div className="like">
                    <img src={Images.UnLikes} alt="" />
                  </div>
                </div>
                <div className="video-details">
                  <div className="d-flex justify-content-between">
                    <h6>
                      <img src={Images.Chief} alt="" />
                      <span>{item.Title}</span>
                    </h6>
                    <div className="rating">
                      <img src={Images.RatingStar} alt="" />
                      <span>{item.Rating}</span>
                    </div>
                  </div>
                  <p>{item.text}</p>
                  <div className="other-info">
                    <span>
                      <img src={Images.ClockFillIco} alt="" />
                      {item.days}
                    </span>
                    <span>{item.other}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img src={Images.DownIco} alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default RecipiesDetails
