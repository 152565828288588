/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import './Discount.css'
import './RoomAdd.css'
import { Button, Input, Table } from 'antd'
import { toast } from 'react-toastify'
import _ from 'lodash'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import { s3ImageHotel } from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination/Pagination'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import DiscountSkeleton from '../Skeleton/DiscountSkeleton'
import CommonDatePicker from '../../CommonDatePicker/CommonDatePicker'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const Discount = ({ isHotelId }) => {
  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    discount_name: '',
    coupan_code: '',
    expiry_date: '',
    discount: '',
    hotel_id: isHotelId,
    discount_id: '',
    image: ''
  })

  const [discountListData, setDiscountListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [delDiscountId, setDelDiscountId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [particularDiscount, setParticularDiscount] = useState([])
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [particularDiscountLoading, setParticularDiscountLoading] =
    useState(false)

  const clearValue = () => {
    setInputFields({
      discount_name: '',
      coupan_code: '',
      expiry_date: '',
      discount: '',
      discount_id: '',
      image: ''
    })
    setSelectedFilePath('')
    setSelectedFileObj('')
    setIsEdit(false)
    setIsImageUploaded(false)
  }

  const ShowConfirm = (record) => {
    setDelDiscountId(record.discount_id)
    setShowConfirmModel(true)
  }

  const addDiscount = () => {
    setIsAddDiscount(true)
  }
  const handleCancels = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    clearValue()
  }

  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const showDiscount = async (record) => {
    setParticularDiscountLoading(true)
    await particularDiscountData(record.discount_id)
    setIsRoomFeature(true)
  }

  const EditDiscount = (record) => {
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      setInputFields({
        hotel_id: record.hotel_id,
        coupan_code: record.coupan_code,
        discount: record.discount.replace(/%/g, ''),
        discount_id: record.discount_id,
        discount_name: record.discount_name,
        expiry_date: formatDateToISO(record.expiry_date),
        image: record.image
      })
      setSelectedFilePath(s3ImageHotel + record.image)
    }
    setInitialValues({
      hotel_id: record.hotel_id,
      coupan_code: record.coupan_code,
      discount: record.discount.replace(/%/g, ''),
      discount_id: record.discount_id,
      discount_name: record.discount_name,
      expiry_date: formatDateToISO(record.expiry_date),
      image: record.image
    })
    setIsAddDiscount(true)
  }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
      setIsImageUploaded(true)
    }
    reader.readAsDataURL(file)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const updatedValue = name === 'coupan_code' ? value.toUpperCase() : value
    setInputFields({ ...inputFields, [name]: updatedValue })
  }

  const columns = [
    {
      title: 'DISCOUNT NAME',
      dataIndex: 'discount_name',
      key: 'discount_name',
      render: (text, record) => (
        <div className="image_name">
          <span>
            {record.discount_name.length > 15
              ? `${record.discount_name.substring(0, 15)}...`
              : record.discount_name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.discount_name.localeCompare(b.discount_name),
      onFilter: (value, record) => record.discount_name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'CODE',
      dataIndex: 'coupan_code',
      key: 'coupan_code',
      sorter: (a, b) => a.coupan_code.localeCompare(b.coupan_code),
      className: 'custom-column'
    },
    {
      title: 'EXPIRY',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date),
      className: 'custom-column'
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discount',
      key: 'discount',
      sorter: (a, b) => a.discount.localeCompare(b.discount),
      className: 'custom-column'
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Images.Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              EditDiscount(record)
            }}
          />
          <Button
            icon={<img src={Images.DeleteDiscount} alt="Delete" />}
            onClick={(e) => {
              // setUpdatingIndex(record)
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      ),
      className: 'custom-action-column'
    }
  ]

  const filteredData = discountListData.filter((item) => item.discount_name.toLowerCase().includes(searchText))

  const allDiscountData = (currentPage) => {
    const payload = {
      hotel_id: isHotelId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getDiscount}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscountListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discountAddApiCall = async () => {
    if (selectedFileObj) {
      const imageFile = await compressImage(selectedFileObj)
      const coverFormData = new FormData()
      coverFormData.append('file', imageFile)
      coverFormData.append('type', 'hotel_discount')

      try {
        const res = await APICall(
          'post',
          coverFormData,
          '',
          'imageUploadLocal',
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedCoverData = dataDecrypt(res.data.data)
          inputFields.image = decryptedCoverData
          setIsImageUploaded(true)
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    }

    const payload = {
      discount_name: inputFields.discount_name,
      coupan_code: inputFields.coupan_code,
      expiry_date: inputFields.expiry_date,
      discount: inputFields.discount,
      hotel_id: isHotelId,
      discount_id: inputFields.discount_id,
      image: inputFields.image
    }
    if (isEdit) {
      APICall('post', payload, `${EndPoints.editDiscount}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddDiscount(false)
            clearValue()
            setIsEdit(false)
            setTimeout(() => {
              allDiscountData(currentPage)
              toast.success(res?.data?.message)
            }, 2500)
          } else {
            setIsEdit(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.addDiscount}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            clearValue()
            setIsAddDiscount(false)
            setTimeout(() => {
              allDiscountData(currentPage)
              toast.success(res?.data?.message)
            }, 2500)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const deleteDiscount = () => {
    const data = {
      discount_id: delDiscountId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteDiscount}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            allDiscountData(currentPage)
            toast.success(res?.data?.message)
          }, 2500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const particularDiscountData = (discount_id) => {
    const payload = {
      hotel_id: isHotelId,
      discount_id
    }
    APICall('post', payload, `${EndPoints.particularDiscount}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularDiscount(res?.decryptedData)
          setParticularDiscountLoading(false)
        } else {
          setParticularDiscountLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setParticularDiscountLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const formatDateToISO = (dateString) => {
    if (!dateString) return null

    const date = new Date(dateString)
    if (isNaN(date)) return null

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const validateForm = () => {
    const { discount_name, coupan_code, expiry_date, discount } = inputFields

    if (discount_name && coupan_code && expiry_date && discount) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const handlePageChange = (pageNumber) => {
    allDiscountData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    allDiscountData(currentPage)
  }, [])

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(
        !_.isEqual(inputFields, initialValues) || isImageUploaded
      )
    }
  }, [inputFields, initialValues, isEdit, isImageUploaded])

  useEffect(() => {
    if (isAddDiscount || isRoomFeature || ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddDiscount, isRoomFeature, ShowConfirmModel])

  return (
    <div className="content-manager-Statistics">
      <div className="statistic-chart-container position-relative">
        {discountListData?.length === 0 ? (
          <div className="add_room_text">
            <img src={Images.tagsDiscount} alt="bedroom" />
            <h3>{English.G350}</h3>
            <p>{English.G351}</p>
            <div className="add-video-rows" onClick={addDiscount}>
              <div className="add_new_content">
                <img src={Images.PlusIco} alt="" />
                {English.G352}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="local-room-main">
              <h3 className="d-flex align-items-center justify-content-between">
                {English.G288}
                <button
                  type="button"
                  className="primary-btn"
                  onClick={addDiscount}
                >
                  <img src={Images.PlusIco} alt="" />
                  {English.G352}
                </button>
              </h3>
            </div>
            <div className="discount_list">
              <Input
                placeholder="Search by Discount Name"
                onChange={handleSearch}
                style={{ width: 200, marginBottom: '1rem' }}
              />
              <div className="discount_list">
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  // pagination={{ pageSize: 5 }}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        showDiscount(record)
                      }
                    }
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel}
                onConfirm={deleteDiscount}
                onCancel={handleDeleteCancel}
                isLoading={loading}
              />
              {loading && <Loader />}
            </div>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}

        {isAddDiscount && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  {isEdit ? 'EDIT DISCOUNT' : 'CREATE DISCOUNT'}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <div className="input-grid">
                  <div>
                    <label>{English.G353}</label>
                    <CommonInput
                      placeholder={English.G308}
                      name="discount_name"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.discount_name)}
                    />
                  </div>
                  <div>
                    <label>{English.G354}</label>
                    <CommonInput
                      placeholder={English.G355}
                      name="coupan_code"
                      onChange={handleChange}
                      value={inputFields.coupan_code}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G204}</label>
                    <div className="calendar">
                      <CommonDatePicker
                        inputFields={inputFields}
                        setInputFields={setInputFields}
                        fieldName="expiry_date"
                        placeholder={English.G57}
                        minDate={new Date()}
                        maxDate={
                          new Date(new Date().getFullYear() + 10, 11, 31)
                        }
                        popperPlacement="bottom"
                      />
                    </div>
                  </div>
                  <div>
                    <label>{English.G356}</label>
                    <CommonInput
                      placeholder={English.G357}
                      name="discount"
                      type="number"
                      onChange={handleChange}
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={2}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 2) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.discount}
                      allowNumberOnly
                    />
                  </div>
                </div>
                <div className="upload-input upload-input-with-border mb-0 upload_height_local">
                  <label htmlFor="upload-file">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      buttonText={English.G154}
                      className="discount_image_size m-0"
                      selectedImg={selectedFilePath}
                      defaultImg=""
                      forImage="image"
                      forName="video"
                    />
                  </label>
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label={English.G116}
                    onClick={handleCancels}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G117}
                    type="button"
                    isPrimary
                    disabled={!isImageUploaded && !isFormComplete}
                    onClick={discountAddApiCall}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            {particularDiscountLoading ? (
              <DiscountSkeleton />
            ) : (
              particularDiscount.map((data, index) => (
                <div
                  className="add-item-content add_room_modal px-4"
                  key={index}
                >
                  <div className="type_close mb-2">
                    <div className="d-flex align-items-center col-gap-10">
                      {data.discount_name}
                    </div>
                    <button
                      className="cancel_icon"
                      type="button"
                      onClick={handleCancels}
                    >
                      <img src={Images.RemoveLocal} alt="" />
                    </button>
                  </div>
                  <div className="item-details-input ">
                    <div className="room_image">
                      <img src={s3ImageHotel + data.image} alt="roomImage" />
                    </div>
                  </div>
                  <div className="item-details-input pt-0 mt-3">
                    <div className="input-grid detail-grid">
                      <div className="deal-label">
                        <label>{English.G354}</label>
                        <p className="">{data.coupan_code}</p>
                      </div>
                      <div>
                        <label>
                          {English.G353}
                          {' '}
                        </label>
                        <p>{data.discount_name}</p>
                      </div>
                    </div>
                    <div className="input-grid detail-grid">
                      <div>
                        <label>{English.G358}</label>
                        <p>{data.expiry_date}</p>
                      </div>
                      <div>
                        <label>{English.G356}</label>
                        <p>{data.discount}</p>
                      </div>
                    </div>
                    <div className="local_button_row">
                      <CommonButton
                        label={English.G359}
                        onClick={handleCancels}
                        isPrimary={false}
                        context={English.G232}
                        className="common-button"
                      />
                      {/* <CommonButton
                      label="EDIT"
                      type="button"
                      isPrimary
                      onClick={nextClick}
                      context={English.G232}
                    /> */}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default Discount
