import React from 'react'
import Images from '../../helpers/Images'

const Portfolio = () => {
  const items = [
    {
      image: './images/job/cat1.svg',
      name: 'Design & Creative Artist',
      position:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    },
    {
      image: './images/job/cat2.svg',
      name: 'Content Writer',
      position:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    },
    {
      image: './images/job/cat3.svg',
      name: 'Construction',
      position:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    },
    {
      image: './images/job/cat4.svg',
      name: 'Web Developer',
      position:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit. Arcu aliquet turpis tum condimentum sit ornare ty amet neque odio.'
    }
  ]
  const Projectitems = [
    {
      img: './images/project/1.jpg'
    },
    {
      img: './images/project/2.jpg'
    },
    {
      img: './images/project/3.jpg'
    },
    {
      img: './images/project/4.jpg'
    },
    {
      img: './images/project/5.jpg'
    },
    {
      img: './images/project/6.jpg'
    }
  ]
  return (
    <div className="portfolio-wrapper">
      <div className="porfolio-head d-flex">
        <div className="potfolio-head-detail w-50">
          <h1>Hello, I’m Ankit Muller</h1>
          <h2>UI/UX DESIGNER & WEB DEVELOPER</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisi,
            euismod viverra facilisis vestibulum enim faucibus. Tristique at
            enim quis convallis. Est arcu, ultricies nec velit sollicitudin.
            Consequat netus magna mattis sem risus, nulla pellentesque. Nec, ut
            turpis et, leo suspendisse scelerisque sollicitudin sem facilisis.
            Odio eu faucibus egestas facilisi gravida Sed luctus.
          </p>
          <button type="button" className="light-button">
            <span>Hire Me</span>
          </button>
        </div>
        <div className="portfolio-detail-img w-50">
          <img alt="" src={Images.Portfolioimg} className="w-100 h-100 object-fit-cover" />
        </div>
      </div>
      <div className="common-card-body mt-30 recent-video-component">
        <h3>My Services</h3>
        <div className="job-categorie">
          <div className="job-categories-grid">
            {items.map((item) => (
              <div className="job-categories-item" key={item.name}>
                <img alt="" src={item.image} />
                <p>{item.name}</p>
                <span className="porfolio-service-text">{item.position}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="common-card-body mt-30 recent-video-component">
        <h3>My Projects</h3>
        <div className="project-gellery">
          {Projectitems.map((item) => (
            <div className="project-item" key={item.img}>
              <img alt="" src={item.img} />
            </div>
          ))}
        </div>
      </div>
      <div className="common-card-body mt-30 recent-video-component">
        <h3>Contact</h3>
        <div className="bg-linier-blue">
          <img alt="" src={Images.HandIco} />
          <div className="d-flex align-items-center justify-content-between">
            <div className="bg-linier-blue-left">
              <h4>Say Hello!</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor,
                diam dignissim malesuada eget laoreet libero feugiat eu.
                Pharetra ornare risus scelerisque sed mauris.
              </p>
            </div>
            <button type="button" className="light-button">
              <span>Say Hello</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Portfolio()
