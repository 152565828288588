
import React, { useState } from 'react'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const TagView = ({ tags, setTags, updateInputFieldsTags, className = '' }) => {
  const [tag, setTag] = useState('')

  const handleRemoveTag = (tagIndex) => {
    const updatedTags = [...tags]
    updatedTags.splice(tagIndex, 1)
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  return (
    <div className={`add-tag ${className}`}>
      <span>{English.G189}</span>
      <div className="d-flex flex-wrap column-gap-20">
        <ul className="tags_select_margin">
          {tags.map((tag, index) => (
            <li
              key={index}
              className="tag-item"
            >
              {tag}
              <button
                type="button"
                className="remove-tag"
                onClick={() => handleRemoveTag(index)}
              >
                <img src={Images.Remove} alt="Remove" />
              </button>
            </li>
          ))}
          <button
            type="button"
            className="add-tag-btns"
          >
            <img src={Images.AddIcoAdmin} alt="Add Tag" />
            <div className="tag-edit-container">
              <CommonInput
                value={tag}
                placeholder={English.G189}
                onChange={(e) => setTag(e.target.value)}
                onBlur={() => {
                  if (tag.trim().length > 0) {
                    setTags([...tags, tag])
                    setTag('')
                    updateInputFieldsTags([...tags, tag])
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && tag.trim().length > 0) {
                    setTags([...tags, tag])
                    setTag('')
                    updateInputFieldsTags([...tags, tag])
                  }
                }}
                ref={(input) => input}
                className="edit-input"
              />
            </div>
          </button>
        </ul>
      </div>
    </div>
  )
}

export default TagView
