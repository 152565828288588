import React from 'react'
import { English, Images } from '../../helpers'

const SideBarPageBusiness = () => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="bg-primary-gredient-signup d-flex flex-column justify-content-between h-100 py-[60px] ml-4">
        <div className="text-center">
          <h1 className="sidebar_text">
            <p className="forgot_text text-white-gradient">{English.A56}</p>
            <p className="forgot_text_under text-white-gradient">{English.A55}</p>
          </h1>
        </div>

        <div className="relative">
          <div className="relative">
            <img className="w-100" src={Images.App_icons} alt="app icons" />
          </div>
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
            <img src={Images.blue_bg_logo} alt="white bg logo" />
          </div>
        </div>

        <footer className="text-[#FFFFFF] text-sm mt-0 d-flex gap-4 items-center justify-center">
          <img src={Images.Withe_bg_logo} alt="white bg logo" className="w-10 h-10" />
          {' '}
          <span className="text-[#FFFFFF] d-flex justify-center items-center gap-1">
            {English.A58}
            {' '}
            <span className="font-bold italic">{English.A57}</span>
          </span>
        </footer>
      </div>
    </div>
  )
}
export default SideBarPageBusiness
