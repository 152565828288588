import React, { useEffect, useState } from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import PropTypes from 'prop-types'
import English from '../../helpers/English'

const slide = [
  {
    cover: './images/slider/1.jpg',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim morbi eu elementum pellentesque arcu. Suscipit id arcu scelerisque non egestas leo risus viverra. Pretium, dictum eget nulla volutpat.',
    heading: 'Cheese Pizza',
    offer: 'OFF For First 3 Offers',
    percentage: '30%',
    button: 'Detail',
    background: '#F4434F',
    subhead: ''
  },
  {
    cover: './images/slider/1.jpg',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim morbi eu elementum pellentesque arcu. Suscipit id arcu scelerisque non egestas leo risus viverra. Pretium, dictum eget nulla volutpat.',
    heading: 'Cheese Pizza',
    offer: 'OFF For First 3 Offers',
    percentage: '30%',
    button: 'Detail',
    background: '#F4434F',
    subhead: ''
  }
]
const SliderLocal = ({ propsitems }) => {
  const [items, setItems] = useState(slide)
  useEffect(() => {
    if (propsitems && propsitems.length > 0) {
      setItems(propsitems)
    }
  }, [propsitems])

  return (
    <div className="slider-for-reviews slider-local-- position-relative">
      <Swiper
        slidesPerView={1}
        navigation
        pagination={{
          clickable: true
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper-story review-slider position-static"
      >
        {items.map((item) => (
          <SwiperSlide key={item}>
            <div
              className="slider--local"
              style={{ background: `${item.background}` }}
            >
              <div className="position-relative slider--local-detail">
                <span className="main-heading">{item.subhead}</span>
                <h4>{item.heading}</h4>
                <div className="position-relative">
                  <p>{item.text}</p>
                  <div className="offer-ellips">
                    <p>
                      <span>{item.percentage}</span>
                      {item.offer}
                    </p>
                  </div>
                </div>
                <button type="button" className="light-button">
                  <span>{English.G192}</span>
                </button>
              </div>
              <div className="slider--local-image position-relative border-50">
                <div
                  className="slider--local--img"
                  style={{
                    background: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
SliderLocal.propTypes = {
  // propsitems: PropTypes.array
  propsitems: PropTypes.func.isRequired
}
export default SliderLocal

