/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { setUserSliceData } from '../../services/http-services'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { removeToken } from '../../redux/UserSlice/UserSlice'
import { APICall, EndPoints } from '../../services'

const pathMapping = {
  Store: '/store-builder/',
  Community: '/community-builder/',
  Entertainment: '/profile-builder/',
  Local: '/local-builder/'
}

const SidebarBuilder = ({ onPlatformChange = () => {} }) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const userType = useSelector((state) => state.user?.user_type)
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stayLogin = useSelector((state) => state.user.stay_loginData)

  const [inputFields, setInputFields] = useState({
    id: '',
    name: ''
  })

  const LogoutClick = () => {
    dispatch(removeToken())
    toast.success('User logged out successfully')
    if (stayLogin) {
      navigate('/re-login')
    } else {
      navigate('/login')
    }
  }

  const [userTypesData, setUserTypesData] = useState([])
  const [addOns, setAddOns] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getPlatformData = async () => {
    setIsLoading(true)
    await APICall('get', '', `${EndPoints.applicationsAddons}?type=${userType}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const DecodedData = res?.decryptedData
          setUserTypesData(DecodedData)
          const FinalData = DecodedData.find((d) => d.id === app_id.id)
          setUserSliceData(FinalData, 'appId')
          setAddOns(FinalData.add_ons_data)
          onPlatformChange(FinalData)
          setInputFields({
            ...inputFields,
            id: FinalData.id,
            name: FinalData.name
          })
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const changeAddOns = (id) => {
    setAddOns(userTypesData.filter((item) => item.id === id)[0].add_ons_data)
    const AppData = userTypesData.filter((item) => item.id === id)[0]
    setUserSliceData(AppData, 'appId')
    window.dispatchEvent(new Event('storage'))
  }

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event?.key !== 'bis_data' && app_id) {
        setLocalStorageValue(app_id?.id || null)
      }
      if (event.key === 'addOns') {
        getPlatformData()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [localStorageValue, app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPlatformData()
    }
  }, [localStorageValue])

  const location = useLocation()

  const matchingPath = Object.entries(pathMapping).find(
    ([key, path]) => location.pathname.includes(path) && addOns.some((i) => i.name === key)
  )

  const checkAddOnPath = Object.entries(pathMapping).find(([key, path]) => {
    return key && location.pathname.includes(path)
  })

  useEffect(() => {
    if (addOns && addOns.length > 0) {
      if (matchingPath || !checkAddOnPath) {
      } else {
        navigate(
          (addOns[0].name === 'Store' && `${pathMapping.Store}${params.id}`) ||
          (addOns[0].name === 'Community' && `${pathMapping.Community}${params.id}`) ||
          (addOns[0].name === 'Local' && `${pathMapping.Local}${params.id}`) ||
          (addOns[0].name === 'Entertainment' && `${pathMapping.Entertainment}${params.id}`)
        )
      }
    }
  }, [addOns, navigate])

  return (
    <div className="custom-sidebar custom-sidebar-builder p-0">
      <div className="sidebar-collapse">
        <div className="logo">
          <img src={Images.GbnLogo} alt="" />
        </div>
        <ul className="main-navigation">
          <li>
            <NavLink to="/dashboard">
              <div>
                <div className="image-block">
                  <img src={Images.Home} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G222}</span>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/profiles">
              <div>
                <div className="image-block">
                  <img src={Images.Profile} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G232}</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/workspace">
              <div>
                <div className="image-block">
                  <img src={Images.Workspace} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G233}</span>
              </div>
            </NavLink>
          </li>
          <div className="mt-40" />
          <li>
            <NavLink to="/content-manager">
              <div>
                <div className="image-block">
                  <img src={Images.ContentManager} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G234}</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/store">
              <div>
                <div className="image-block">
                  <img src={Images.MyStore} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G235}</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <div className="d-flex align-items-center justify-content-between">
              <NavLink to="/local" className="w-100">
                <div className="position-relative">
                  <div className="image-block">
                    <img src={Images.Local} alt="" />
                  </div>
                  <span className="sidebar-link-text">{English.G236}</span>
                  {/* <div
                  onClick={toggleMenu}
                  className="cursor-pointer image-block position-absolute"
                  type="button"
                >
                  <img src={Images.RightIco} alt=""/>
                </div> */}
                </div>
              </NavLink>
            </div>
            {/* {isOpen && (
            <ul className="custom-menu-options ms-4">
              <li>
                <NavLink to="/hotels">
                  <div>Hotels</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/restaurant">
                  <div>Restaurant</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/event">
                  <div>Events</div>
                </NavLink>
              </li>
            </ul>
          )} */}
          </li>
          <li className="disableSideMenu">
            <NavLink to="cloud-store">
              <div>
                <div className="image-block">
                  <img src={Images.CloudStore} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G237}</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/ads">
              <div>
                <div className="image-block">
                  <img src={Images.ADS} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G238}</span>
              </div>
            </NavLink>
          </li>
          <div className="mt-40" />
          <li className="disableSideMenu">
            <NavLink to="/affiliate">
              <div>
                <div className="image-block">
                  <img src={Images.Affiliate} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G239}</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/service-center">
              <div>
                <div className="image-block">
                  <img src={Images.ServiceCenter} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G240}</span>
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/settings">
              <div>
                <div className="image-block">
                  <img src={Images.Settings} alt="" />
                </div>
                <span className="sidebar-link-text">{English.G241}</span>
              </div>
            </NavLink>
          </li>
          <div className="mt-40" />
          <li>
            <NavLink
              to="#"
              onClick={(e) => {
                  e.preventDefault()
                  LogoutClick()
                }}
            >
              <div>
                <div className="image-block">
                  <img src={Images.Logout} alt="" />
                </div>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="sidebar-right-part">
        <label>{English.G243}</label>
        <Dropdown
          title={inputFields.name}
          activeItem={inputFields}
          onChanged={(d) => {
            changeAddOns(d.id)
            setInputFields({
              ...inputFields,
              id: d.id,
              name: d.title
            })
          }}
          Data={userTypesData.map((d) => {
            return {
              title: d.name,
              id: d.id
            }
          })}
        />

        <div className="links-end">
          <ul>
            {isLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="rounded-3 mb-1"
                  height={48}
                  width={181}
                />
                ))
              : addOns.map((i, index) => {
                  return (
                    <div key={index}>
                      <li>
                        <NavLink
                          to={
                            (i.name === 'Store' &&
                              `${pathMapping.Store}${params.id}`) ||
                            (i.name === 'Community' &&
                              `${pathMapping.Community}${params.id}`) ||
                            (i.name === 'Entertainment' &&
                              `${pathMapping.Entertainment}${params.id}`) ||
                            (i.name === 'Local' &&
                              `${pathMapping.Local}${params.id}`)
                          }
                          replace
                        >
                          <div>
                            {i.name}
                            <div className="cursor-pointer image-block position-absolute">
                              <img src={Images.RightAerrow} alt="" />
                            </div>
                          </div>
                        </NavLink>
                      </li>
                    </div>
                  )
                })}
            {/* {(
            <>
              <li>
                <NavLink to="/local-gastronomy">
                  <div>
                    Local Gastronomy
                    <div className="cursor-pointer image-block position-absolute">
                      <img src={RightAerrow} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/local-hotels">
                  <div>
                    Local Hotels
                    <div className="cursor-pointer image-block position-absolute">
                      <img src={RightAerrow} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/local-events">
                  <div>
                    Local Event
                    <div className="cursor-pointer image-block position-absolute">
                      <img src={RightAerrow} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </li>
            </>
            )} */}
          </ul>
          <ul>
            <li>
              <NavLink to="">
                <div>
                  {English.G245}
                  <div className="cursor-pointer image-block position-absolute">
                    <img src={Images.RightAerrow} alt="" />
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={`install-addons/${params.id}`}>
                <div>
                  {English.G246}
                  <div className="cursor-pointer image-block position-absolute">
                    <img src={Images.RightAerrow} alt="" />
                  </div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="">
                <div>
                  {English.G247}
                  <div className="cursor-pointer image-block position-absolute">
                    <img src={Images.RightAerrow} alt="" />
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default SidebarBuilder
