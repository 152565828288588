import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import English from '../../helpers/English'

const MapLocal = () => {
  const [ShowMapModel, setShowMapModel] = useState(false)
  const AddMap = () => {
    setShowMapModel(true)
  }
  const HandleCancel = () => {
    setShowMapModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowMapModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>{English.G256}</h2>
          <button type="button" className="primary-btn" onClick={AddMap}>
            Add Manually
          </button>
        </div>
        <div className="contact-info-item map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
            width="100%"
            height="452"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Location"
          />
        </div>
      </div>
      {ShowMapModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div>
                <label>{English.G187}</label>
                <input placeholder={English.G62} />
              </div>
              <div className="input-grid">
                <div>
                  <label>City</label>
                  <input placeholder="Enter city" />
                </div>
                <div>
                  <label>State</label>
                  <input placeholder="Enter state" />
                </div>
                <div>
                  <label>Zip code</label>
                  <input placeholder="Enter zip code" />
                </div>
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button type="button" className="secondary-btn-model">{English.G152}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default MapLocal
