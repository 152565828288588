import React, { useState, useEffect } from 'react'
import './CreatorProfile.css'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { dataDecrypt } from '../../services/http-services'
import { s3ImageProfile, urlSystemIcon } from '../../services/Service'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import { APICall, EndPoints } from '../../services'

const InstallAddons = () => {
  const app_ids = useSelector((state) => state.user?.app_id)
  const userType = useSelector((state) => state.user?.user_type)

  const params = useParams()
  const navigate = useNavigate()
  const [addOnsData, setAddOnsData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [initialAddOnsData, setInitialAddOnsData] = useState([])

  useEffect(() => {
    setIsLoading(true)
    APICall('get', '', `${EndPoints.addons}?type=${userType}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const Arr = dataDecrypt(res.data.data)
          Arr.forEach((item1, index) => {
            app_ids.add_ons_data.forEach((item2) => {
              if (item2.id === item1.id) {
                Arr[index].checked = true
              }
            })
          })
          setAddOnsData(Arr)
          setInitialAddOnsData(JSON.parse(JSON.stringify(Arr)))
          setIsLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }, [])

  const installClick = () => {
    const add_ons = addOnsData
      .filter(({ checked }) => checked)
    const data = {
      app_id: app_ids.id,
      add_ons: add_ons.map((item) => item.id),
      type: userType
    }
    APICall('post', data, EndPoints.editAddons, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          window.dispatchEvent(new StorageEvent('storage', {
            key: 'addOns',
            oldValue: null,
            newValue: 'newValue',
            url: window.location.href,
            storageArea: localStorage
          }))
          // navigate(`/profile-builder/${params.id}`)
          if (add_ons[0].name === 'Store') {
            navigate(`/store-builder/${params.id}`)
          } else if (add_ons[0].name === 'Entertainment') {
            navigate(`/profile-builder/${params.id}`)
          } else if (add_ons[0].name === 'Community') {
            navigate(`/community-builder/${params.id}`)
          } else if (add_ons[0].name === 'Local') {
            navigate(`/local-builder/${params.id}`)
          }
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const isInstallButtonDisabled = () => {
    return addOnsData.every(
      (addon, index) => addon.checked === initialAddOnsData[index].checked
    )
  }

  return (
    <div className="creator-profile-main creator-profile-create install_addOnd_padding py-0 pl-2">
      <div>
        <div className="sticky_header_install_addOns">
          <div className="d-flex align-items-center justify-content-between">
            <p className="sub-text">{English.G97}</p>
          </div>
        </div>
        <div className="step-4 scroll_bars_install_addOns !h-[calc(100vh-183px)] grid-rows-[max-content]">
          {isLoading ? (
            <div className="add-ons">
              <div className="add-ons-main-ing">
                <Skeleton width={100} height={100} />
              </div>
              <div className="footer-row justify-center">
                <div className="images-block">
                  <Skeleton width={100} height={100} />
                </div>
                <div className="add-ons-detail ml-3">
                  <h4>
                    <Skeleton max-width={175} />
                  </h4>
                  <p>
                    <Skeleton max-width={175} height={50} />
                  </p>
                </div>
              </div>
              <div className="custome-checkbox">
                <Skeleton type="checkbox" width={20} height={20} />
              </div>
            </div>
          ) : (
            addOnsData.map((i, index) => (
              <div key={index} className="add-ons">
                <div className="add-ons-main-ing">
                  <img
                    className="install_addOns_img"
                    alt=""
                    src={s3ImageProfile + i.image}
                  />
                </div>
                <div className="footer-row">
                  <div className="images-block">
                    <img
                      className="app_icon_img"
                      alt=""
                      src={urlSystemIcon + i.icon}
                    />
                  </div>
                  <div className="add-ons-detail">
                    <h4>{i.name}</h4>
                    <p>{i.description}</p>
                  </div>
                </div>
                <div className="custome-checkbox">
                  <input
                    type="checkbox"
                    value={i.id}
                    checked={i.checked}
                    disabled={i.disabled}
                    onChange={(e) => {
                      const newCheckboxes = [...addOnsData]
                      newCheckboxes[index].checked = e.target.checked
                      setAddOnsData(newCheckboxes)
                    }}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <div className="steps-button sticky-footer mt-0 px-3">
          {/* <button
            disabled={isInstallButtonDisabled()}
            className="primary-button btn-next"
            type="button"
            onClick={installClick}
          >
            <span>Install</span>
          </button> */}
          <CommonButton
            label={English.G221}
            type="button"
            onClick={installClick}
            isPrimary
            className="primary-button rounded-pill"
            disabled={isInstallButtonDisabled()}
            context={English.G232}
          />
        </div>
      </div>
    </div>
  )
}
export default InstallAddons
