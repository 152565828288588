import React from 'react'
import './Dashboard.css'
import English from '../../helpers/English'
// import { Link } from 'react-router-dom'
// import Podcast from '../../images/podcast.png'
// import Images from '../../helpers/Images'

const LetestContent = () => {
  // const VideoStyle = {
  //   background: 'url("./images/video-banner.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const PodacastStyle1 = {
  //   background: 'url("./images/podcast1.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const PodacastStyle2 = {
  //   background: 'url("./images/podcast2.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const PodacastStyle3 = {
  //   background: 'url("./images/podcast3.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const courses1 = {
  //   background: 'url("./images/courses1.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const courses2 = {
  //   background: 'url("./images/courses2.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const courses3 = {
  //   background: 'url("./images/courses3.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }
  // const Recipes = {
  //   background: 'url("./images/recipies.png")',
  //   backgroundrepeat: 'no-repeat',
  //   backgroundSize: 'cover'
  // }

  return (
    <div className="letest-content">
      <div className="video letest-content-item">
        <div className="d-flex align-items-center justify-content-between">
          <h5>{English.G225}</h5>
          {/* <Link>View All</Link> */}
        </div>
        {/* <div className="video-items-row">
          <div className="video-item">
            <div className="video-cover" style={VideoStyle}>
              <div className="like">
                <img src={Images.Likes} alt="" />
              </div>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.HealthIco} alt="" />
                  <span>Gesundheit// Sub category</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Video title for a video which will be here </p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
                <span>3 Km Fun Run</span>
              </div>
            </div>
          </div>
          <div className="video-item">
            <div className="video-cover" style={VideoStyle}>
              <div className="like">
                <img src={Images.Likes} alt="" />
              </div>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.HealthIco} alt="" />
                  <span>Gesundheit// Sub category</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Video title for a video which will be here </p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
                <span>3 Km Fun Run</span>
              </div>
            </div>
          </div>
          <div className="video-item">
            <div className="video-cover" style={VideoStyle}>
              <div className="like">
                <img src={Images.Likes} alt="" />
              </div>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.HealthIco} alt="" />
                  <span>Gesundheit// Sub category</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Video title for a video which will be here </p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
                <span>3 Km Fun Run</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="podcast letest-content-item">
        <div className="d-flex align-items-center justify-content-between">
          <h5>{English.G226}</h5>
          {/* <Link>View All</Link> */}
        </div>
        {/* <div className="podcast-items-row">
          <div className="podacast-item">
            <div className="podcast-cover" style={PodacastStyle1}>
              <div className="play">
                <img src={Images.Play} alt="" />
              </div>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
            </div>
            <div className="video-details podcast-detail">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Podcast} alt="" />
                  <span>Ankit Muller</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Podcast Title Best Podcast Ever Ever Made </p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
              </div>
            </div>
          </div>
          <div className="podacast-item">
            <div className="podcast-cover" style={PodacastStyle2}>
              <div className="play">
                <img src={Images.Play} alt="" />
              </div>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
            </div>
            <div className="video-details podcast-detail">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Podcast} alt="" />
                  <span>Ankit Muller</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Podcast Title Best Podcast Ever Ever Made</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
              </div>
            </div>
          </div>
          <div className="podacast-item">
            <div className="podcast-cover" style={PodacastStyle3}>
              <div className="play">
                <img src={Images.Play} alt="" />
              </div>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
            </div>
            <div className="video-details podcast-detail">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Podcast} alt="" />
                  <span>Ankit Muller</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Podcast Title Best Podcast Ever Ever Made</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="courses letest-content-item">
        <div className="d-flex align-items-center justify-content-between">
          <h5>{English.G440}</h5>
          {/* <Link>View All</Link> */}
        </div>
        {/* <div className="video-items-row">
          <div className="video-item">
            <div className="video-cover" style={courses1}>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.HealthIco} alt="" />
                  <span>Health</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
              </div>
            </div>
          </div>
          <div className="video-item">
            <div className="video-cover" style={courses2}>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.HealthIco} alt="" />
                  <span>Health</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
              </div>
            </div>
          </div>
          <div className="video-item">
            <div className="video-cover" style={courses3}>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.HealthIco} alt="" />
                  <span>Health</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="Recipes letest-content-item">
        <div className="d-flex align-items-center justify-content-between">
          <h5>{English.G446}</h5>
          {/* <Link>View All</Link> */}
        </div>
        {/* <div className="video-items-row">
          <div className="video-item">
            <div className="video-cover" style={Recipes}>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
              <div className="like">
                <img src={Images.Likes} alt="" />
              </div>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.Chief} alt="" />
                  <span>{English.G446} // Sub category</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
                <span>by Jens Peter</span>
              </div>
            </div>
          </div>
          <div className="video-item">
            <div className="video-cover" style={Recipes}>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
              <div className="like">
                <img src={Images.Likes} alt="" />
              </div>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.Chief} alt="" />
                  <span>{English.G446} // Sub category</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
                <span>by Jens Peter</span>
              </div>
            </div>
          </div>
          <div className="video-item">
            <div className="video-cover" style={Recipes}>
              <button type="button" className="drop-button">
                <img src={Images.MoreHorizontal} alt="" />
              </button>
              <div className="like">
                <img src={Images.Likes} alt="" />
              </div>
              <p className="time-duration">10:20</p>
            </div>
            <div className="video-details">
              <div className="d-flex justify-content-between">
                <h6>
                  <img src={Images.Chief} alt="" />
                  <span>{English.G446} // Sub category</span>
                </h6>
                <div className="rating">
                  <img src={Images.RatingStar} alt="" />
                  <span>4.9</span>
                </div>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div className="other-info">
                <span>
                  <img src={Images.ClockIco} alt="" />
                  10 days
                </span>
                <span>by Jens Peter</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default LetestContent
