import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'

const CurruntJobs = () => {
  const items = [
    {
      cover: './images/job/1.png',
      name: 'Dyson',
      city: 'Germany',
      role: 'UI Designer',
      discription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium, egestas amet, eu. Malesuada tristique vitae praesent non sed duis. Viverra et commodo, duis congue nisi, proin lacus. Proin leo.'
    },
    {
      cover: './images/job/2.png',
      name: 'Facebook',
      city: 'Germany',
      role: 'Marketing Manager',
      discription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium, egestas amet, eu. Malesuada tristique vitae praesent non sed duis. Viverra et commodo, duis congue nisi, proin lacus. Proin leo.'
    },
    {
      cover: './images/job/1.png',
      name: 'Pixelz Studio',
      city: 'Germany',
      role: 'UI Designer',
      discription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium, egestas amet, eu. Malesuada tristique vitae praesent non sed duis. Viverra et commodo, duis congue nisi, proin lacus. Proin leo.'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component ">
      <h3 className="d-flex align-items-center justify-content-between">
        {English.G226}
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="recent-job-grid">
        {items.map((item) => (
          <div key={item.name} className="job--grid-item">
            <div className="d-flex flex-wrap col-gap-20 align-items-center job--details">
              <div>
                <img src={item.cover} alt="logo" />
              </div>
              <div>
                <h3 className="m-0">{item.role}</h3>
                <p>
                  <span>{item.name}</span>
                  {' '}
                  |
                  <span>{item.city}</span>
                </p>
              </div>
            </div>
            <h5>{English.G112}</h5>
            <p>{item.discription}</p>
            <button type="button" className="light-button">
              <span>Apply Now</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
export default CurruntJobs
