import React, { useState } from 'react'
import './ExpertProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import RecentPodcast from '../RecentPodcast/RecentPodcast'
import Feeds from '../Feeds/Feeds'
import OurTeamProfile from '../OurTeam/OurTeam'
import ProfileServices from '../ProfileServices/ProfileServices'
import FitnessExpertSlider from '../FitnessExpertSlider/slider'
import RatingContainer from '../RatingContainer/Rating'
import ContactInfo from '../ContactInfo/ContactInfo'
import ServicesProfile from '../Services/Services'
import Appointment from '../Appointment/Appointment'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import ExpertCourses from './courses'
import ExpertMegazines from './megazines'
import ExpertRecipes from './recipes'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const ExpertProfile = () => {
  const items = [
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    },
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    },
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    }
  ]
  const Badgeitems = [
    {
      badge: './images/badge/1.svg'
    },
    {
      badge: './images/badge/2.svg'
    },
    {
      badge: './images/badge/3.svg'
    },
    {
      badge: './images/badge/4.svg'
    },
    {
      badge: './images/badge/5.svg'
    },
    {
      badge: './images/badge/6.svg'
    },
    {
      badge: './images/badge/7.svg'
    },
    {
      badge: './images/badge/8.svg'
    },
    {
      badge: './images/badge/9.svg'
    }
  ]
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div
        className={`feed-container mt-30 ${tabIndex === 1 ? '' : 'd-block'}`}
      >
        <div
          className={`details-tab-view common-card-body ${
            tabIndex === 1 ? 'feed-left' : ''
          }`}
        >
          <div className="tabs-for-details-view">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab>
                  <img alt="" src={Images.HomeIco} />
                  {English.G222}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedIco} />
                  {English.G223}
                </Tab>
                <Tab>
                  <img alt="" src={Images.ServiceIco} />
                  {English.G224}
                </Tab>
                <Tab>
                  <img alt="" src={Images.BookingIco} />
                  {English.G444}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedbackIco} />
                  {English.G427}
                </Tab>
                <Tab>
                  <img alt="" src={Images.AboutIco} />
                  {English.G428}
                </Tab>
                <Tab>
                  <img alt="" src={Images.CoursnsIcon} />
                  {English.G440}
                </Tab>
                <Tab>
                  <img alt="" src={Images.MegazinesIco} />
                  {English.G445}
                </Tab>
                <Tab>
                  <img alt="" src={Images.RecipiesIco} />
                  {English.G446}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="mt-40">
                  <OurTeamProfile />
                </div>
                <div className="mt-40">
                  <ProfileServices />
                </div>
                <div className="mt-40">
                  <RecentPodcast />
                </div>
                <div className="free-courses-starter-pack mt-40">
                  <div className="details">
                    <h5>Free Courses Starter Pack </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pulvinar consequat urna pharetra.
                    </p>
                  </div>
                  <button type="button" className="light-button">
                    <span>{English.G447}</span>
                  </button>
                </div>
                <div className="mt-40">
                  <FitnessExpertSlider />
                </div>
                <div className="about common-card-body recent-video-component mt-40">
                  <h3>{English.G428}</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                    pellentes nullam sit amet, lorem. Semper vel egestas
                    convallis nisl duis mattis ut molestie sed. Mauris feugiat
                    tincidunt nunc, quam mauris, massa diam faucibus.
                    {' '}
                    <span>See more</span>
                  </p>
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <ContactInfo />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div>
                  <Feeds />
                </div>
                <div className="free-courses-starter-pack mt-30">
                  <div className="details">
                    <h5>Free Courses Starter Pack </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pulvinar consequat urna pharetra.
                    </p>
                  </div>
                  <button type="button" className="light-button">
                    <span>{English.G447}</span>
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <ServicesProfile />
              </TabPanel>
              <TabPanel>
                <Appointment />
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
              <TabPanel>
                <ExpertCourses />
              </TabPanel>
              <TabPanel>
                <ExpertMegazines />
              </TabPanel>
              <TabPanel>
                <ExpertRecipes />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {tabIndex === 1 && (
          <div className="common-card-body feed-right p-20">
            <h3>completed challenges</h3>
            <div className="feed-challanges-container">
              {items.map((item) => (
                <div
                  key={item.name}
                  className="feed-challanges-item mt-20"
                  style={{
                    background: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  <div className="about-challanges">
                    <p>{item.name}</p>
                    <div className="d-flex justify-content-between">
                      <span>{item.date}</span>
                      <span>{item.time}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button type="button">{English.G423}</button>
            <h3>Badges</h3>
            <div className="badge-container">
              {Badgeitems.map((item) => (
                <div className="badge-item" key={item.badge}>
                  <img alt="" src={item.badge} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ExpertProfile
