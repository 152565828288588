/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react'
import { English, Images } from '../../../helpers'
import PrimaryButton from './PrimaryButton'
import 'react-accessible-accordion/dist/fancy-example.css'
import MarqueeComponent from './SubComponent/MarqueeComponent'
import TitleComponent from './SubComponent/TitleComponent'
import FlowComponent from './SubComponent/FlowComponent'
import ListVerticalComponent from './SubComponent/ListVerticalComponent'
import MemberPlanComponent from './SubComponent/MemberPlanComponent'
import OtherUserTypeComponent from './SubComponent/UserTypeSubComponent'
import AccordionComponent from './SubComponent/AccordionComponent'
import CompanyDetailComponent from './SubComponent/CompanyDetailComponent'
import SecondaryButton from './SecondaryButton'

const MemberLandingView = () => {
  const videoRef = useRef()
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }
  return (
    <div>
      <div className="flex items-center justify-center relative">
        <div className="bg-electricWhite w-1/2 h-96 text-center items-center rounded-xl overflow-hidden relative">
          <video
            ref={videoRef}
            className="rounded-lg overflow-hidden h-full w-full object-cover"
            src="https://www.w3schools.com/html/mov_bbb.mp4"
            controls={false}
          />
          <button
            type="button"
            onClick={togglePlayPause}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-primaryGradient w-12 h-12 self-center rounded-3xl"
          >
            <span className="text-white text-2xl pl-1">
              {isPlaying ? '❚❚' : '▶'}
            </span>
          </button>
        </div>
      </div>
      <div className="py-28">
        <MarqueeComponent />
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          title={English.B44}
          subTitle={English.B45}
          description={English.B46}
        />
        <FlowComponent />
      </div>
      <div className="py-28">
        <MarqueeComponent />
      </div>
      <div className="pt-16 pb-28 px-16">
        <TitleComponent
          title={English.B55}
          subTitle={English.B56}
        />
        <div className="grid grid-cols-2 gap-20 mt-10 max-[1440px]:gap-6">
          <div className="flex flex-col gap-20">
            <ListVerticalComponent
              data={[
                {
                  title: 'ERSTELLE DEINE APP WORLD ONE ID',
                  subTitle: 'TRETE DER COMMUNITY KOSTENLOS BEI',
                  description: 'Verwende deine Member-ID als Zugang für alle Apps von App World One.',
                  buttonLabel: 'APP WORLD ONE ID ERSTELLEN',
                  image: null
                },
                {
                  title: 'NUTZE BIS ZU 16 APPS',
                  subTitle: 'ENTFALTE DAS MAXIMALE POTENTIAL DEINER APPS',
                  description: 'Nutze dauerhaft alle Apps in der kostenlosen Lite Version oder schalte mit einem Upgrade die Pro Funktionen für deine Lieblings-App(s) frei.',
                  buttonLabel: 'APPS ENTDECKEN',
                  image: Images.DeinerApps
                }
              ]}
            />
          </div>
          <div className="flex flex-col gap-20 mt-36">
            <ListVerticalComponent
              data={[
                {
                  title: 'WÄHLE DEINE NUTZERART',
                  subTitle: 'MEMBER - CREATOR - BUSINESS',
                  description: 'Alle Apps bestehen aus einer Kombination von Social Media und Premium Funktionen mit exklusiven Content.',
                  buttonLabel: 'NUTZERARTEN VERGLEICHEN',
                  image: Images.MemberCreatorBussiness
                },
                {
                  title: 'APP WORLD ONE WALLET',
                  subTitle: 'ALLE AKTIVITÄTEN ABRUFBAR IN JEDER APP',
                  description: 'Die Wallet beinhaltet deine App World One ID mit dem ausgewählten Abo, verfügbare Points, Transaktionen und vieles mehr.',
                  buttonLabel: 'WALLET ERKUNDEN',
                  image: Images.JederApp
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          title="FINDE DEIN PASSENDES ABO"
          subTitle="ENTDECKE PREISE & VORTEILE"
          description="Entscheide dich für dein ideales Abo Modell, schalte die Pro Version der ausgewählten App(s) frei, reduziere Werbung und genieße viele weitere Vorteile."
        />
        <div className="capitalize flex items-center !gap-5 justify-center">
          <span className="bg-clip-text text-transparent bg-primaryGradient text-2xl font-semibold">monthly</span>
          <div className="custum-toggle priceing">
            <label className="switch !w-[101px] !h-[50px]" htmlFor="title">
              <input id="title" type="checkbox" checked />
              <div className="slider round !rounded-[50px]" />
            </label>
          </div>
          <span className="bg-clip-text text-transparent bg-primaryGradient text-2xl font-semibold">YEARLY</span>
        </div>
        <label className="w-full text-center bg-clip-text text-transparent bg-primaryGradient text-xl mb-10 !mt-5 opacity-70">( Save up to 22% )</label>
        <MemberPlanComponent />
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          title="POINTS"
          subTitle="SCHALTE PREMIUM FEATURES FREI"
          description="Sei auf unseren Apps aktiv, nimm an Aufgaben teil und werde mit Points belohnt. Verwalte diese in deiner App World One Wallet und löse sie in jeder App für Premium Features ein."
        />
        <div className="mt-16">
          <img className="mw-100 mx-auto" src={Images.PointsLayout} alt="" />
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          title="APP WORLD ONE WALLET ALS MEMBER"
          subTitle="ALLES NOTWENDIGE ABRUFBAR IN JEDER APP"
          description="eine individuelle App World One Wallet beinhaltet deine App World One ID mit deinem ausgewählten Abo, deine verfügbaren Points, Transaktionen und vieles mehr."
        />
        <div className="mt-16">
          <img className="mw-100 mx-auto" src={Images.WalletAs} alt="" />
        </div>
        <div className="flex items-center !gap-5 justify-center mt-16">
          <PrimaryButton
            txtClass="uppercase text-lg"
            text="APP WORLD ONE ID ERSTELLEN"
          />
          <SecondaryButton
            text="MEHR ÜBER DIE APP WORLD ONE Wallet"
          />
          {/* <button className="bg-primaryGradient rounded-3xl gradient-border" type="button">
            <div className="bg-electricWhite rounded-3xl pl-5 pr-5 pt-2 pb-2">
              <div className="bg-primaryGradient font-black pr-1 italic bg-clip-text text-transparent uppercase text-base">
                MEHR ÜBER DIE APP WORLD ONE Wallet
              </div>
            </div>
          </button> */}
        </div>
      </div>
      <div className="py-28">
        <MarqueeComponent />
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          title="WEITERE NUTZERARTEN ENTDECKEN"
          subTitle="CREATOR I BUSINESS"
        />
        <div className="mt-16 grid grid-cols-2 gap-14 max-[1440px]:gap-6">
          <OtherUserTypeComponent
            image={Images.CreatorBlock}
            icon={Images.creator}
            userTypeTitle={English.A45}
            userTypeSubTitle="BUILD YOUR COMMUNITIES"
          />
          <OtherUserTypeComponent
            image={Images.BussinessBlock}
            icon={Images.BussinessIco}
            userTypeTitle={English.A46}
            userTypeSubTitle="PRESENT YOUR COMPANY UNITS"
          />
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          title="HÄUFIG GESTELLTE FRAGEN"
          subTitle="FINDE ANTWORTEN UND ERKLÄRUNGEN"
        />
        <div className="lending-faq mt-14">
          <AccordionComponent
            data={[
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              },
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              },
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              },
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              }
            ]}
          />
        </div>
      </div>
      <footer className="p-[60px] pb-0 bg-lightGradient">
        <CompanyDetailComponent />
      </footer>
      <div className="w-full h-[10px] bg-BgRainbow" />
    </div>
  )
}

export default MemberLandingView
