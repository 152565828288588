/* eslint-disable no-nested-ternary */
import React from 'react'
import { Images } from '../../../../helpers'

// userType == 1 or 2 same or 3 -> business (dark blue)
const ListVerticalComponent = ({ data, userType }) => {
  return (
    data.map((item) => {
      return (
        <div key={item.title} className={`${userType === 3 ? 'bg-[#ffffff14]' : 'bg-lightGradient'} rounded-[32px] p-4`}>
          <div className="bg-white rounded-[32px] aspect-[4/3] flex items-center justify-center">
            {item?.image && <img className={`${userType === 3 ? 'w-full h-full object-cover rounded-[32px]' : 'mw-100'}`} src={item?.image} alt="" />}
          </div>
          <div className="p-10 max-[1440px]:p-6 max-[1440px]:px-0">
            <h3 className={`font-black italic text-[35px] uppercase bg-clip-text text-transparent ${userType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} leading-none max-[1440px]:text-[28px]`}>{item?.title}</h3>
            <h4 className={`font-bold text-[27px] italic uppercase bg-clip-text text-transparent ${userType === 2 ? 'bg-textDodgerBlue' : userType === 3 ? 'bg-electricWhite' : 'bg-electricPink'} leading-none max-[1440px]:text-[22px]`}>{item?.subTitle}</h4>
            <p className={`text-[22px] bg-clip-text text-transparent ${userType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} my-[30px] max-[1440px]:text-sm`}>{item.description}</p>
            <button className={`${userType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} rounded-[60px] gradient-border !p-[3px]`} type="button">
              <div className={`${userType === 3 ? 'bg-[#142764]' : 'bg-electricWhite'} rounded-[60px] py-4 px-12 max-[1440px]:!px-3`}>
                <div className={`${userType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} font-black pr-1 italic bg-clip-text text-transparent uppercase text-lg flex items-center gap-4 max-[1440px]:text-sm`}>
                  {item.buttonLabel}
                  <img className={`${userType === 3 ? 'brightness-0 invert-[1]' : ''} `} src={Images.ButtonAerrow} alt="" />
                </div>
              </div>
            </button>
          </div>
        </div>
      )
    })
  )
}

export default ListVerticalComponent
