/* eslint-disable max-len */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const CampaignsScreen = () => {
    const navigate = useNavigate()
    const [filter, setFilter] = useState('all')
  const initialApps = [
    {
      id: 1,
      name: 'campaign 1',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 2,
      name: 'campaign 2',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 3,
      name: 'campaign 3',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 4,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 5,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 6,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 7,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 8,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 9,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    }
  ]

  return (
    <div className="dashboard">
      <div className="p-[20px] pt-0">
        <div className="p-0 bg-gray-50 ">
          <p className="text-xs font-medium text-[#0B0A0A]">
            <span
              className="text-sm font-medium cursor-pointer"
              onClick={() => navigate('/dashboard')}
            >
              Home
            </span>
            /
            <span className="text-sm font-bold">Campaigns</span>
          </p>
          <div className="flex justify-between items-center mb-[20px]">
            <div className="text-gradient font-extrabold pr-1 text-[34px] italic uppercase">
              {English.G250}
            </div>
            <div className="flex justify-between items-center">
              <div className="flex space-x-4 mb-6 border border-[#b8b8b8] rounded-full py-2 px-3">
                <button
                  onClick={() => setFilter('all')}
                  className={`px-2 py-[8px] rounded-md ${
                                  filter === 'all'
                                    ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                                    : 'text-gradient text-base italic font-extrabold'
                                }`}
                  type="button"
                >
                  {English.A160}
                </button>
                <button
                  type="button"
                  onClick={() => setFilter('active')}
                  className={`px-4 py-[8px] rounded-md ${
                                  filter === 'active'
                                    ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                                    : 'text-gradient text-base italic font-extrabold'
                                }`}
                >
                  {English.A161}
                </button>
                <button
                  onClick={() => setFilter('inactive')}
                  type="button"
                  className={`px-4 py-[8px] rounded-md ${
                                  filter === 'inactive'
                                    ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                                    : 'text-gradient text-base italic font-extrabold'
                                }`}
                >
                  {English.A162}
                </button>
                <button
                  onClick={() => setFilter('start')}
                  type="button"
                  className={`px-4 py-[8px] rounded-md ${
                                  filter === 'start'
                                    ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                                    : 'text-gradient text-base italic font-extrabold'
                                }`}
                >
                  {English.A163}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mb-3">
          <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
            {English.G250}
          </div>
          <CommonButton
            label={English.A141}
            type="button"
            className="primary-btns w-auto"
            context="primary-btn"
            isPrimary
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {initialApps.map((i) => (
            <div
              key={i.id}
              className={`w-[100%] h-auto p-[12px] rounded-2xl bg-white ${
              i.isSelected ? 'selected' : 'selectable'
            }`}
            >
              <div className="flex flex-col gap-3">
                <div>
                  <div className="flex items-center justify-between w-100">
                    <div className="flex gap-10 items-center w-100">
                      <div className="flex-row items-center gap-2 w-100">
                        <div className="flex gap-1 items-center justify-between">
                          <h3 className="text-base font-semibold mb-0 text-[#303030]">
                            {i.name}
                          </h3>
                          <div className="flex items-center gap-2">
                            <img src={Images.timer_icon} alt="" />
                            <p className="text-[#303030] text-xs font-semibold mb-0">Oct 20 - Nov 20</p>
                          </div>
                        </div>
                        <div className="flex gap-3 items-center">
                          <p className="text-[44px] font-semibold text-[#303030] mb-[12px]">
                            0%
                          </p>
                          <p className="italic text-[13px] font-normal bg-[#FFEFB8] text-[#ECB131] mb-[12px] w-[100px] text-center justify-center p-1 rounded-full">
                            In Progress
                          </p>
                        </div>
                        <p className="mb-2 text-[#9E9E9E] font-medium text-xs">Make a presentation about the marketing strategy for Q4.  </p>
                        <div className="flex items-center gap-3">
                          <CommonButton
                            label="Schedule"
                            type="button"
                            className="primary-btns !w-[100px] !p-2 text-xs font-medium"
                            context="primary-btn"
                            isPrimary
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ))}
        </div>
      </div>
    </div>
  )
}

export default CampaignsScreen
