import React from 'react'
import { useLocation } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const JobDetail = () => {
  const location = useLocation()
  const { state } = location || {}
  const job = state || []
  const JobsStyle = {
    background: 'url("./images/job/a9801f3738d3f4883afffd708f83004f.jpg")',
    backgroundrepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  return (
    <div className="creator-profile-main">
      <div className="job-details-main">
        <div className="job-details-wrapper">
          <div className="find-job">
            <div className="find-job-cover" style={JobsStyle}>
              <div className="find-job-details">
                <h2>{job.name}</h2>
                <ul>
                  <li>
                    <img alt="" src={Images.Phone} />
                    {job.number}
                  </li>
                  <li>
                    <img alt="" src={Images.Mail} />
                    {job.mail}
                  </li>
                  <li>
                    <img alt="" src={Images.LocationLight} />
                    {job.location}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="common-card-body mt-30 d-flex justify-content-between">
            <div className="job-detail-left">
              <h4>
                {job.role}
                {' '}
                (
                {job.type}
                )
              </h4>
              {job?.texts.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
              <ul className="job-details-data">
                <li>
                  <span>Compensation:</span>
                  {job.Compensation}
                </li>
                <li>
                  <span>
                    {English.G256}
                    :
                  </span>
                  {job.type}
                </li>
                <li>
                  <span>Duration:</span>
                  {job.Duration}
                </li>
                <li>
                  <span>Commitment:</span>
                  {job.Commitment}
                </li>
              </ul>
              <h3>
                {English.G112}
                :
              </h3>
              {job?.discription.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
              <h3>Responsibilities:</h3>
              <ul className="job-responsibilities">
                {job?.Responsibilities.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
              <h3>Requirements:</h3>
              <ul className="job-requirements">
                {job?.Requirements.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
              <button type="button" className="primary-btn w-400">Apply for Job</button>
            </div>
            <div className="job-detail-right">
              <div>
                <button type="button" className="primary-btn me-3">Apply Job</button>
                <button type="button" className="light-button">Save Job</button>
              </div>
              <div className="vertical-divider mb-4 mt-5" />
              <div className="map mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
                  width="100%"
                  height="276"
                  allowfullscreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="company Website"
                />
              </div>
              <button type="button" className="light-button">Company Website</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDetail
