/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import './AddProduct.css'
import { useSelector } from 'react-redux'
import {
  urlImageProductPathStore
} from '../../services/Service'
import Loader from '../Loader/Loader'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { emitter, EventType } from '../../helpers/Emitter'
import ProductLibaryModal from './ProductLibaryModal'
import AddEditProductLibaryModal from './AddEditProductLibaryModal'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const AddProduct = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [ShowProductsModel, setShowProductsModel] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)
  const [ShowProduct, setShowProduct] = useState(false)
  const [EditProduct, setEditProduct] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)

  const [trendingProduct, setTrendingProduct] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const AddProducts = () => {
    setShowProductsModel(true)
  }

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }

  const componentRef = useRef()

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setShowLibraryConfirmModel(false)
  }

  const getTrending = async () => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    APICall('post', platFormData, EndPoints.getTrendingProduct, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setTrendingProduct(res?.decryptedData)
        setIsLoading(false)
      } else {
        toast.error(res.data?.message)
        setIsLoading(false)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setIsLoading(false)
    })
  }

  const deleteTrending = (data) => {
    const platFormData = {
      app_id: app_id.id,
      delete_trending_products: [data.id]
    }
    setLoading(true)
    APICall('post', platFormData, EndPoints.deleteTrendingProduct, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setTimeout(() => {
          getTrending()
          setLoading(false)
          setShowConfirmModel(false)
          toast.success(res?.data?.message)
        }, 1500)
      } else {
        toast.error(res.data?.message)
        setLoading(false)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setLoading(false)
    })
  }

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const event = emitter.addListener(EventType.ProductLibraryDelete, ({ product_id, action }) => {
      if (action === 'delete') {
        const temp = [...trendingProduct]
        const newData = temp.filter((item) => item.id !== product_id)
        setTrendingProduct(newData)
      } else {
        getTrending()
      }
    })
    return () => event.remove()
  }, [trendingProduct])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getTrending()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (
      ShowConfirmModel ||
      EditProduct ||
      ShowProductsModel ||
      ShowProduct ||
      ShowLibraryConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowConfirmModel,
    EditProduct,
    ShowProductsModel,
    ShowProduct,
    ShowLibraryConfirmModel
  ])

  const [editData, setEditData] = useState(null)
  const EditLibrary = (data) => {
    setEditProduct(true)
    setShowProduct(true)
    setEditData(data)
    // setSelectedImages([data.image])
    // oneProductDetails(data)
  }

  return (
    <div className="content-manager-awards-wrapper common-card-body mt-40 add-profile-video">
      <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
        <div className="tag_link mb-0">
          {/* <div className="video_button"> */}
          <h2>{English.G136}</h2>

          {trendingProduct?.length < 3 && (
            <div className="add-video-rows">
              <CommonButton
                label={English.G137}
                imgSrc={Images.AddIco}
                onClick={AddProducts}
                isPrimary
                className="add_new_content"
              />
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
      <div className="awards-row">
        {trendingProduct?.length === 0 && !isLoading ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button" onClick={AddProducts}>
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value" />
              <span className="add-value" />
            </div>
          </div>
        ) : isLoading && trendingProduct?.length === 0 ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button">
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value">
                <Skeleton className="add-value" width={202} height={35} />
              </p>
              <span className="add-value">
                <Skeleton className="add-value" width={67} height={35} />
              </span>
            </div>
          </div>
        ) : (
          trendingProduct?.map((data, i) => {
            if (i < 4) {
              return (
                <div key={data.id} className="product-grid-item position-relative">
                  <div className="image-buttons z-1">
                    <label>
                      <button
                        type="button"
                        className="d-none"
                        onClick={() => EditLibrary(data)}
                      />
                    </label>
                    <button
                      type="button"
                      className="p-0"
                      onClick={ShowConfirm}
                    />
                    {ShowConfirmModel && (
                      <div className="add-item-model">
                        <div
                          className="add-item-contents h-auto w-auto"
                          ref={componentRef}
                        >
                          <div className="item-details-input pt-0">
                            <h3 className="mb-0 text-center">
                              {English.G119}
                            </h3>
                            <div className="button-row justify-content-center">
                              <button
                                type="button"
                                className="secondary-btn-modelConfirm"
                                onClick={HandleCancelConfirm}
                              >
                                <span>{English.G116}</span>
                              </button>
                              <button
                                type="button"
                                className="primary-btn-modelConfirm"
                                onClick={() => {
                                  setUpdatingIndex(i)
                                  deleteTrending(data)
                                }}
                              >
                                {English.G120}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {loading && updatingIndex === i && <Loader />}
                  <div
                    className="product-item-cover"
                    style={{
                      backgroundImage: `url(${urlImageProductPathStore + data.image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    <div className="rating">
                      <img src={Images.RatingStar} alt="star" />
                      <span>4.9</span>
                    </div>
                  </div>
                  <div className="product-item-details d-flex  justify-content-between">
                    <span>{data.name}</span>
                    {/* <span>{data.description}</span> */}
                    <div>
                      {data.discount_price > 0 ? (
                        <>
                          <h4>{data.discount_price}</h4>
                          <h4 className="actual-price" id="regular">
                            {data.regular_price}
                          </h4>
                        </>
                      ) : (
                        <h4>{data.regular_price}</h4>
                      )}
                    </div>
                  </div>
                </div>
              )
            }
            return null
          })
        )}
      </div>

      {ShowProductsModel && (
        <ProductLibaryModal
          ShowProductsModel={ShowProductsModel}
          setShowProductsModel={setShowProductsModel}
          generalProduct={trendingProduct}
          productType="trending"
          onSubmited={() => {
            getTrending()
          }}
        />
      )}
      {ShowProduct && (
        <AddEditProductLibaryModal
          isProductEdit={EditProduct}
          setIsProductEdit={setEditProduct}
          setShowProduct={setShowProduct}
          onComplete={() => {
            if (EditProduct) {
              getTrending()
            }
            setEditProduct(false)
            setEditData(null)
          }}
          editData={editData}
        />
      )}
    </div>
  )
}
export default AddProduct
