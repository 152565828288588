/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import '../../App.css'
import { useSelector } from 'react-redux'
import { English } from '../../helpers'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import SideBarPageBusiness from './SideBarPageBusiness'
import { APICall, EndPoints } from '../../services'
import { dataDecrypt, setUserSliceData } from '../../services/http-services'
import { preventNonAlphabeticKeydownWithSingleSpace, preventNonAlphabeticPasteWithSingleSpace } from '../../helpers/Utility'

const BusinessLegalInfoPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user_type = useSelector((state) => state.user?.user_type)
  const [inputFields, setInputFields] = useState({
    company_name: '',
    company_type: '',
    company_managing_director: '',
    company_email: '',
    company_hrm: '',
    company_commercial_register_extract: '',
    company_tax_number: '',
    company_tax_id: ''
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [legalInfo, setLegalInfo] = useState({})
  const [businessInfo, setBusinessInfo] = useState(null)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'company_email') {
      setInputFields({ ...inputFields, [name]: value.replace(/\s/g, '') })
    } else {
      setInputFields({ ...inputFields, [name]: value })
    }
  }

  const validateEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  const validateValues = () => {
    const errors = {}
    if (!inputFields.company_name.match(/^[a-zA-ZÀ-Ÿ-. ]*$/)) {
      errors.company_name = English.G48
    }
    if (!validateEmail(inputFields.company_email)) {
      errors.company_email = English.G1
    }
    if (inputFields.company_tax_number && !/^\d{11}$/.test(inputFields.company_tax_number)) {
      errors.company_tax_number = English.A169
    }

    if (inputFields.company_tax_id && !/^\d{11}$/.test(inputFields.company_tax_id)) {
      errors.company_tax_id = English.A168
    }

    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // setErrors(validateValues(inputFields))
    const validationErrors = validateValues()
    setErrors(validationErrors)
    setSubmitting(true)
    setLegalInfo({
      ...legalInfo,
      company_name: inputFields?.company_name,
      company_type: inputFields?.company_type,
      company_managing_director: inputFields?.company_managing_director,
      company_email: inputFields?.company_email,
      company_hrm: inputFields?.company_hrm,
      company_commercial_register_extract:
        inputFields?.company_commercial_register_extract,
      company_tax_number: inputFields?.company_tax_number,
      company_tax_id: inputFields?.company_tax_id,
      type: user_type
    })
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      // navigate('/business-slide')
      if (submitting) {
        userDataSubmit()
      }
    }
  }, [errors])
  useEffect(() => {
    if (location.state) {
      setBusinessInfo(location.state?.inputFields)
    }
  }, [location.state])

  const userDataSubmit = () => {
    setLoading(true)
    const allData = { ...businessInfo, ...legalInfo }
    APICall('post', allData, EndPoints.userUpdateProfile, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = dataDecrypt(res?.data?.data)
          const data_data = decryptedData?.business_data
          const userData = { ...decryptedData, ...data_data }
          setUserSliceData(userData, 'userInfo')
          // navigate('/create-profile')
          navigate('/business-slide')
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    setIsButtonEnabled(
      inputFields.company_type.length > 0 &&
        inputFields.company_managing_director.length > 0 &&
        inputFields.company_email.length > 0 &&
        inputFields.company_hrm.length > 0
    )
  }, [inputFields])

  return (
    <div className="auth-register business-register">
      <div className="bg-overlay row overlay-height">
        <div className="d-flex col-md-6 py-[20px]">
          <SideBarPageBusiness />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto no-scrollbar">
            <h2 className="signup_text mb-0">{English.A59}</h2>
            <h5 className="bg-[#151515] text-center bg-transparent text-[24px] font-black italic mb-7">
              {English.A60}
            </h5>
            <form
              className="h-full flex flex-1 flex-col"
              onSubmit={handleSubmit}
            >
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text capitalize">{English.A73}</label>
                  <div className="">
                    <CommonInput
                      name="company_name"
                      required
                      placeholder={English.G55}
                      value={inputFields.company_name}
                      onChange={handleChange}
                      onKeyDown={preventNonAlphabeticKeydownWithSingleSpace}
                      onPaste={preventNonAlphabeticPasteWithSingleSpace}
                    />
                  </div>
                  {errors.company_name ? (
                    <p className="error-text">{errors.company_name}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text capitalize">{English.A74}</label>
                  <div className="company_type-input ">
                    <CommonInput
                      type="name"
                      name="company_type"
                      placeholder={English.G55}
                      value={inputFields.company_type}
                      className="custom-input "
                      required
                      onChange={handleChange}
                      onKeyDown={preventNonAlphabeticKeydownWithSingleSpace}
                      onPaste={preventNonAlphabeticPasteWithSingleSpace}
                    />
                  </div>
                  {errors.company_type ? (
                    <p className="error-text">{errors.company_type}</p>
                  ) : null}
                </p>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.A75}</label>
                  <div className="number-input">
                    <CommonInput
                      name="company_managing_director"
                      placeholder={English.G55}
                      required
                      value={inputFields.company_managing_director}
                      onChange={handleChange}
                      onKeyDown={preventNonAlphabeticKeydownWithSingleSpace}
                      onPaste={preventNonAlphabeticPasteWithSingleSpace}
                    />
                  </div>
                </p>
                <p className="w-50">
                  <label className="business_label_text capitalize">{English.A76}</label>
                  <div className="password-input">
                    <CommonInput
                      name="company_email"
                      placeholder={English.G185}
                      required
                      value={inputFields.company_email}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.company_email ? (
                    <p className="error-text">{errors.company_email}</p>
                  ) : null}
                </p>
              </div>
              {/* <p className="bg-[#151515] bg-transparent text-[14px] font-black italic">
                {English.A70}
              </p> */}
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text capitalize">{English.A77}</label>
                  <div className="user-input">
                    <CommonInput
                      name="company_hrm"
                      required
                      placeholder={English.A71}
                      value={inputFields.company_hrm}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.company_hrm ? (
                    <p className="error-text">{errors.company_hrm}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.A78}</label>
                  <div className="company_type-input">
                    <CommonInput
                      type="text"
                      name="company_commercial_register_extract"
                      placeholder={English.A71}
                      value={inputFields.company_commercial_register_extract}
                      onChange={handleChange}
                    />
                  </div>
                </p>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.A79}</label>
                  <div className="number-input">
                    <CommonInput
                      name="company_tax_number"
                      placeholder={English.A71}
                      value={inputFields.company_tax_number}
                      onChange={handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/\D/g, '')
                          .slice(0, 11)
                      }}
                    />
                  </div>
                  {errors.company_tax_number ? (
                    <p className="error-text">{errors.company_tax_number}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.A80}</label>
                  <div className="password-input">
                    <CommonInput
                      name="company_tax_id"
                      placeholder={English.A71}
                      value={inputFields.company_tax_id}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/\D/g, '')
                          .slice(0, 11)
                      }}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.company_tax_id ? (
                    <p className="error-text">{errors.company_tax_id}</p>
                  ) : null}
                </p>
              </div>
              <p className="w-full flex flex-1 flex-col items-end justify-center mb-[30px]">
                <div className="flex flex-1" />
                <CommonButton
                  id="sub_btn"
                  label={English.A69}
                  type="submit"
                  className="primary-button m-auto custom-mt"
                  context="primary"
                  isPrimary
                  loading={loading}
                  disabled={!isButtonEnabled || loading}
                />
              </p>
            </form>
            <footer>
              <div className="text-[10px] text-placeholder text-center items-center justify-center !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
                erat enim mattis nunc, eget volutpat. Aenean sed urna, facilisi
                vitae, volutpat faucibus.enim mattis nunc, eget volutpat. Aenean
                sed urna, facilisi vitae, volutpat faucibus
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BusinessLegalInfoPage
