/* eslint-disable max-len */
import React from 'react'
import { useSelector } from 'react-redux'
import { English } from '../../../helpers'
import { s3ImageProfile } from '../../../services/Service'

const Recommendation = () => {
  const UserData = useSelector((state) => state.user?.userData)

  const initialData = [
    {
      id: 1,
      image: UserData.profile_image,
      name: UserData.full_name,
      user_name: UserData.user_name,
      description: English.A131
    },
    {
      id: 1,
      image: UserData.profile_image,
      name: UserData.full_name,
      user_name: UserData.user_name,
      description: English.A131
    },
    {
      id: 1,
      image: UserData.profile_image,
      name: UserData.full_name,
      user_name: UserData.user_name,
      description: English.A131
    }
  ]
  return (
    <div className="p-0 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center">
        <div className="text-gradient font-extrabold pr-1 !pb-5 text-2xl italic bg-clip-text text-transparent uppercase">
          {English.A129}
        </div>
      </div>
      <div>
        <div>
          <h4 className="font-bold text-lg italic text-[#0B0A0A]">
            {English.A130}
          </h4>

          {initialData.map((i) => (
            <div
              key={i.id}
              className="w-[334px] h-auto p-[16px] rounded-2xl bg-white border border-[#E4E4E4] mb-2"
            >
              <div className="flex flex-1 flex-col">
                <div className="d-flex items-center gap-[10px] !pb-3">
                  <img
                    className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl border-2 border-border"
                    src={s3ImageProfile + i.image}
                    alt="sad"
                  />
                  <div className="flex flex-1 flex-col">
                    <div className="bg-primaryGradient font-extrabold text-xl bg-clip-text text-transparent">
                      {i.name}
                    </div>
                    <div className="text-black font-medium text-lg">{`@${i.user_name}`}</div>
                  </div>
                </div>
                <div className="bg-primaryGradient font-normal text-xs bg-clip-text text-transparent">
                  {i.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="mt-4">
          <h4 className="font-bold text-lg italic text-[#0B0A0A]">
            {English.A132}
          </h4>

          {initialData.map((i) => (
            <div
              key={i.id}
              className="w-[334px] h-auto p-[16px] rounded-2xl bg-white border border-[#E4E4E4] mb-2"
            >
              <div className="flex flex-1 flex-col">
                <div className="d-flex items-center gap-[10px] !pb-3">
                  <img
                    className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl border-2 border-border"
                    src={s3ImageProfile + i.image}
                    alt="sad"
                  />
                  <div className="flex flex-1 flex-col">
                    <div className="bg-primaryGradient font-extrabold text-xl bg-clip-text text-transparent">
                      {i.name}
                    </div>
                    <div className="text-black font-medium text-lg">{`@${i.user_name}`}</div>
                  </div>
                </div>
                <div className="bg-primaryGradient font-normal text-xs bg-clip-text text-transparent">
                  {i.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Recommendation
