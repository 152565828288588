/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatViews, lockScroll, unlockScroll } from '../../helpers/Utility'
import { s3ImageProfile } from '../../services/Service'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import ProfileSkeleton from './ProfileSkeleton'
import { APICall, EndPoints } from '../../services'
import EditProfileModel from './EditProfileModel/EditProfileModel'

const ProfileHeadLocal = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const componentRef = useRef()
  const navigate = useNavigate()
  const [profile, setProfile] = useState([])
  const [profileEditData, setProfileEditData] = useState(null)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const profileGet = async () => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    await APICall('post', platFormData, EndPoints.getLocalProfile, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const data = res?.decryptedData
          setProfile(data)
          // setInputFields({
          //   icon: data[0]?.profile_icon,
          //   description: data[0]?.description,
          //   website_link: data[0]?.website_link
          // })
          // setProfileImg(data[0]?.profile_icon)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const EditProfile = (profileData) => {
    setShowProfileModel(true)
    setProfileEditData(profileData)
    // setInputFields({
    //   ...inputFields,
    //   description: profileData.description,
    //   website_link: profileData.website_link,
    //   icon: profileData.profile_icon
    // })
    // setProfileImg(profileData.profile_icon)
  }

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const HandleCancel = () => {
    setShowProfileModel(false)
    // setIsEdited(false)
    // clearValue()
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const profileInitialized = useRef(false)

  useEffect(() => {
    if (!profileInitialized.current) {
      profileInitialized.current = true
      profileGet()
    }
  }, [])

  useEffect(() => {
    if (ShowProfileModel) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel])

  return (
    <div>
      {isLoading ? (
        <ProfileSkeleton />
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            <div className="profile-builder-header mb-40">
              <button type="button" onClick={handleGoBack}>
                <img alt="" src={Images.LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Images.Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={Images.MenuIcon} />
              </button>
            </div>

            <div className="profile-builder-main">
              <div className="profile-personal-detail">
                <div className="profile-build-image">
                  <img alt="" src={s3ImageProfile + data.profile_icon} />
                </div>
                <div className="about-personal-profile">
                  <h2>{data.user_name}</h2>
                  <h3>FITNESS & GESUNDHEIT</h3>
                  <p>
                    {' '}
                    {data.description.slice(0, 65)}
                    {data.description.length > 65 && '...'}
                  </p>
                  <span>Your Personal Trainer</span>
                </div>
              </div>
              <div className="profile-account-details">
                <ul className="about-account-insignt buttons-group">
                  <li>
                    <h4 className="profile_post_count">
                      {formatViews(data.total_post)}
                    </h4>
                    <p>{English.G104}</p>
                  </li>
                  <li style={{ justifyItems: 'center' }}>
                    <h4 className="profile_follower_count">
                      {formatViews(data.total_followers)}
                    </h4>
                    <p className="Follower_text">{English.G105}</p>
                  </li>
                  <li style={{ justifyItems: 'flex-end' }}>
                    <h4 className="profile_like_count">
                      {formatViews(data.total_likes)}
                    </h4>
                    <p>{English.G106}</p>
                  </li>
                </ul>
                <div className="follow-connenct">
                  <div className="buttons-group">
                    <button
                      type="button"
                      className="success-btn-no-hover disabled"
                    >
                      {English.G107}
                    </button>
                    <button type="button" className="primary-btn-no-hover p-10">
                      {English.G108}
                    </button>
                  </div>
                  <div>
                    <img alt="" src={Images.GbnLogo} />
                  </div>
                </div>
                <div className="follwer-shows">
                  {/* <div className="follower-img-group">
                    {displayedFollowers.map((follower, index) => (
                      <img
                        key={index}
                        alt={`Follower ${index + 1}`}
                        src={s3ImagePath + follower.user_profile_icon}
                      />
                    ))}
                  </div> */}
                  {/* {allFollowerListData.length > 0 && (
                    <div className="follower-img-group">
                      {displayedFollowers.map((follower, index) => (
                        <img
                          key={index}
                          alt={`Follower ${index + 1}`}
                          src={s3ImagePath + follower.user_profile_icon}
                        />
                      ))}
                    </div>
                  )}
                  {allFollowerListData.length > 0 && (
                    <p>
                      <span className="gredient-txt">
                        {displayedNames.map((follower, index) => (
                          <React.Fragment key={index}>
                            <b>{follower.user_name}</b>
                            {index < displayedNames.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                        {' und '}
                        <b>
                          {allFollowerListData.length - 2}
                          {' '}
                          weitere Personen
                        </b>
                        {' '}
                        sind Follower
                      </span>
                    </p>
                  )} */}
                </div>
                {data?.website_link && (
                  <div className="profile-redirect">
                    <a
                      className="gredient-txt"
                      target="_blank"
                      href={getStoreLink(data?.website_link)}
                      rel="noreferrer"
                    >
                      <img alt="" src={Images.Redirect} />
                      {data.website_link}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      )}
      {ShowProfileModel && (
        <EditProfileModel
          type="local"
          title={English.G111}
          linkTitle={English.G113}
          profileEditData={profileEditData}
          onCancel={HandleCancel}
          componentRef={componentRef}
          onSubmite={(data) => {
            APICall('post', data, EndPoints.EditLocalProfile, 'local')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  setTimeout(() => {
                    profileGet()
                    toast.success(res?.data?.message)
                    setShowProfileModel(false)
                    setProfileEditData(null)
                  }, 3000)
                } else {
                  setShowProfileModel(false)
                  setProfileEditData(null)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
                // setIsEdited(false)
              })
          }}
        />
      )}
    </div>
  )
}
export default ProfileHeadLocal
