import React from 'react'
import './Admin.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Categories from './Categories'
import Cities from './Cities'
import PersonalityTest from './PersonalityTest'
import Clients from './Clients'
import English from '../../helpers/English'

const Admin = () => {
  return (
    <div className="creator-profile-main">
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>Categories</Tab>
                <Tab>{English.G432}</Tab>
                <Tab>Personality test</Tab>
                <Tab>{English.G429}</Tab>
                <Tab>Ingredients</Tab>
                <Tab>Subscription Model</Tab>
                <Tab>Notifications</Tab>
                <Tab>Games</Tab>
                <Tab>Offers</Tab>
                <Tab>Security</Tab>
                <Tab>{English.G240}</Tab>
                <Tab>Chat</Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Categories />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Cities />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <PersonalityTest />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Clients />
                </div>
              </TabPanel>
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Admin
