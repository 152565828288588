/* eslint-disable import/no-duplicates */
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useLocation } from 'react-router-dom'
import VideoDetails from './VideoDetail'
import PodcastDetails from './PodcastDetails'
import CoursesDetails from './CoursesDetail'
import RecipiesDetails from './RecipiesDetails'
import MagazinesDetails from './MagazinesDetails'
import CommunityDetails from './CommunityDetails'
import ShotsDetail from './ShotsDetail'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const DetailsComponent = () => {
  const { state } = useLocation()
  return (
    <div className="detail-main content-manager">
      <div className="details-tab-view common-card-body">
        <div className="tabs-for-details-view">
          <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
            <TabList>
              <Tab>
                <img className="component_icon" alt="" src={Images.VideoIco} />
                {English.G225}
              </Tab>
              <Tab>
                <img className="component_icon" alt="" src={Images.PodcastIco} />
                {English.G226}
              </Tab>
              <Tab>
                <img className="component_icon" alt="" src={Images.CommunityIco} />
                {English.G439}
              </Tab>
              <Tab>
                <img className="component_icon" alt="" src={Images.ShotsIco} />
                {English.G164}
              </Tab>
              <Tab disabled>
                <img className="component_icon" alt="" src={Images.CoursesIco} />
                {English.G440}
              </Tab>
              <Tab disabled>
                <img className="component_icon" alt="" src={Images.RecipiesIco} />
                {English.G441}
              </Tab>
              <Tab disabled>
                <img className="component_icon" alt="" src={Images.MegazinesIco} />
                {English.G442}
              </Tab>
            </TabList>

            <TabPanel>
              <VideoDetails />
            </TabPanel>
            <TabPanel>
              <PodcastDetails />
            </TabPanel>
            <TabPanel>
              <CommunityDetails />
            </TabPanel>
            <TabPanel>
              <ShotsDetail />
            </TabPanel>
            <TabPanel>
              <CoursesDetails />
            </TabPanel>
            <TabPanel>
              <RecipiesDetails />
            </TabPanel>
            <TabPanel>
              <MagazinesDetails />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
export default DetailsComponent
