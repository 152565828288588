/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { English } from '../../../helpers'
import AppsItemMember from './AppsItemMember'
import { APICall, EndPoints } from '../../../services'

const AppList = () => {
const [isAppLoading, setIsAppLoading] = useState(false)
  const [apps, setApps] = useState([])
  const [filter, setFilter] = useState('all')

  const getApplicationsData = async () => {
    setIsAppLoading(true)
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setApps(decryptedData)
          setIsAppLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsAppLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsAppLoading(false)
      })
  }

  const toggleActive = (id) => {
    const updatedApps = apps.map((app) => (app.id === id ? { ...app, active: !app.active } : app))
    setApps(updatedApps)
  }

  const filteredApps = apps.filter((app) => {
    if (filter === 'active') return app.active
    if (filter === 'inactive') return !app.active
    return true
  })

  useEffect(() => {
      getApplicationsData()
    }, [])

  return (
    <div className="p-0 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center">
        <div className="bg-primaryGradient font-black pr-1 !pb-5 text-3xl italic bg-clip-text text-transparent uppercase">
          {English.B43}
        </div>

        <div className="flex space-x-4 mb-6 border border-[#b8b8b8] rounded-full py-2 px-3">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-[12px] rounded-md ${
              filter === 'all'
                ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                : 'text-gradient text-base italic font-extrabold'
            }`}
            type="button"
          >
            {English.A124}
          </button>
          <button
            type="button"
            onClick={() => setFilter('active')}
            className={`px-4 py-[12px] rounded-md ${
              filter === 'active'
                ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                : 'text-gradient text-base italic font-extrabold'
            }`}
          >
            {English.A125}
          </button>
          <button
            onClick={() => setFilter('inactive')}
            type="button"
            className={`px-4 py-[12px] rounded-md ${
              filter === 'inactive'
                 ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                : 'text-gradient text-base italic font-extrabold'
            }`}
          >
            {English.A126}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredApps.map((i) => (
          <AppsItemMember
            item={i}
            key={i.id}
            toggleActive={toggleActive}
            loading={isAppLoading}
          />
        ))}
      </div>
    </div>
  )
}

export default AppList
