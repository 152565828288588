import React from 'react'
import { Link } from 'react-router-dom'
import ProgressBar from '@ramonak/react-progress-bar'
import './RecentCommunity.css'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RecentCommunity = () => {
  const itemsGiveaways = [
    {
      CoverImage: './images/megazines-1.png',
      Title: 'Shoes',
      text: 'Lorum ipsum',
      price: '$25.00'
    },
    {
      CoverImage: './images/megazines-2.png',
      Title: 'Shoes',
      text: 'Lorum ipsum',
      price: '$25.00'
    },
    {
      CoverImage: './images/megazines-3.png',
      Title: 'Shoes',
      text: 'Lorum ipsum',
      price: '$25.00'
    },
    {
      CoverImage: './images/megazines-4.png',
      Title: 'Shoes',
      text: 'Lorum ipsum',
      price: '$25.00'
    }
  ]
  const items = [
    {
      CoverImage: './images/award1.png',
      Title: 'Academy Awards',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Nisl, et quam. ',
      profile: './images/podcast.png',
      name: 'Ankit Muller',
      time: '10 days'
    },
    {
      CoverImage: './images/award2.png',
      Title: 'The Nobel Prize',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Nisl, et quam. ',
      profile: './images/podcast.png',
      name: 'Ankit Muller',
      time: '10 days left'
    },
    {
      CoverImage: './images/award3.png',
      Title: 'Academy Awards',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Nisl, et quam. ',
      profile: './images/podcast.png',
      name: 'Ankit Muller',
      time: '10 days left'
    }
  ]
  const itemsDiscussion = [
    {
      CoverImage: './images/profile.png',
      Title: 'New oppertunities in the area of e-health.',
      name: 'Michelangelo',
      subtext: 'started 10 days ago',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis mi mauris pharetra aliquam ultrices.',
      comment: '6',
      groupImg: [
        './images/Goup-disccusion1.png',
        './images/Goup-disccusion2.png',
        './images/Goup-disccusion3.png'
      ]
    },
    {
      CoverImage: './images/profile.png',
      Title: 'New oppertunities in the area of e-health.',
      name: 'Michelangelo',
      subtext: 'started 10 days ago',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis mi mauris pharetra aliquam ultrices.',
      comment: '6',
      groupImg: [
        './images/Goup-disccusion1.png',
        './images/Goup-disccusion2.png',
        './images/Goup-disccusion3.png'
      ]
    },
    {
      CoverImage: './images/profile.png',
      Title: 'New oppertunities in the area of e-health.',
      name: 'Michelangelo',
      subtext: 'started 10 days ago',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis mi mauris pharetra aliquam ultrices.',
      comment: '6',
      groupImg: [
        './images/Goup-disccusion1.png',
        './images/Goup-disccusion2.png',
        './images/Goup-disccusion3.png'
      ]
    }
  ]
  const itemsPolls = [
    {
      CoverImage: './images/profile.png',
      Title: 'New oppertunities in the area of e-health.',
      tag: 'Web Design',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat arcu, nulla ipsum massa volutpat non duis. ',
      comment: '6',
      groupImg: [
        './images/Goup-disccusion1.png',
        './images/Goup-disccusion2.png',
        './images/Goup-disccusion3.png'
      ],
      pollOption: [
        {
          label: 'Poll option one can be placed here',
          percantage: '75',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        },
        {
          label: 'Poll option one can be placed here',
          percantage: '50',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        },
        {
          label: 'Poll option one can be placed here',
          percantage: '40',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        },
        {
          label: 'Poll option one can be placed here',
          percantage: '80',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        }
      ]
    },
    {
      CoverImage: './images/profile.png',
      Title: 'New oppertunities in the area of e-health.',
      tag: 'Web Design',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat arcu, nulla ipsum massa volutpat non duis. ',
      comment: '6',
      groupImg: [
        './images/Goup-disccusion1.png',
        './images/Goup-disccusion2.png',
        './images/Goup-disccusion3.png'
      ],
      pollOption: [
        {
          label: 'Poll option one can be placed here',
          percantage: '75',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        },
        {
          label: 'Poll option one can be placed here',
          percantage: '50',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        },
        {
          label: 'Poll option one can be placed here',
          percantage: '40',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        },
        {
          label: 'Poll option one can be placed here',
          percantage: '80',
          groupImg: [
            './images/Goup-disccusion1.png',
            './images/Goup-disccusion2.png',
            './images/Goup-disccusion3.png'
          ]
        }
      ]
    }
  ]
  return (
    <div className="recent-video-component common-card-body">
      <h3>{English.G439}</h3>
      <div className="content-manager-communitymegazines-wrapper common-card-body">
        <div className="recipies-wrapper-header common-contnt-wrapper">
          <h2>Recent Giveaways</h2>
          <Link>{English.G248}</Link>
        </div>
        <div className="megazines-row">
          {itemsGiveaways.map((item) => (
            <div className="magazines-items" key={item.Title}>
              <div
                className="magazines-cover"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="like">
                  <img alt="" src={Images.UnLikes} />
                </div>
              </div>
              <div className="magazines-detail">
                <h5>{item.Title}</h5>
                <div className="other-detail-megazines">
                  <span>{item.text}</span>
                  <p className="price">{item.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="content-manager-discussions-wrapper common-card-body mt-40">
        <div className="discussions-wrapper-header common-contnt-wrapper">
          <h2>{English.G208}</h2>
          <Link>{English.G248}</Link>
        </div>
        {itemsDiscussion.map((item) => (
          <div className="discussions-item" key={item.Title}>
            <div className="discussion-profile">
              <div
                className="discssion-profile-image"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
              <div className="discssion-detail">
                <h5>{item.Title}</h5>
                <span>
                  <b>{item.name}</b>
                  {' '}
                  {item.subtext}
                </span>
                <p>{item.text}</p>
              </div>
            </div>
            <div className="discussion-group">
              {item?.groupImg.map((img, index) => (
                <img alt="" key={index} src={img} />
              ))}
              <img alt="" src={Images.GroupDiscussion} />
            </div>
            <div className="discussion-comment">
              <img alt="" src={Images.Comments} />
              {item.comment}
            </div>
            <div className="discussion-button">
              <button type="button">
                <img alt="" src={Images.IconsMore} />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="content-manager-Polls-wrapper common-card-body mt-40">
        <div className="polls-wrapper-header common-contnt-wrapper">
          <h2>Recent Polls</h2>
          <Link>{English.G248}</Link>
        </div>
        {itemsPolls.map((item) => (
          <div className="polls-item common-card-body" key={item.tag}>
            <div className="polls-tag">
              <span>{item.tag}</span>
            </div>
            <div className="poll-header">
              <p>{item.text}</p>
              <div className="discussion-group">
                {item?.groupImg.map((img, index) => (
                  <img alt="" key={index} src={img} />
                ))}
                <img alt="" src={Images.GroupDiscussion} />
              </div>
              <div className="discussion-comment">
                <img alt="" src={Images.Comments} />
                {item.comment}
              </div>
              <div className="discussion-button">
                <button type="button">
                  <img alt="" src={Images.IconsMore} />
                </button>
              </div>
            </div>
            {item?.pollOption.map((poll) => (
              <div className="polls-option" key={poll.index}>
                <ProgressBar
                  customLabel={poll.label}
                  className="profress-line"
                  baseBgColor="#fff"
                  height={43}
                  completed={poll.percantage}
                  borderRadius={15}
                  bgColor="linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)"
                  isLabelVisible={false}
                  labelClassName="polls-label"
                />
                <div className="polls-group">
                  <div className="discussion-group">
                    {poll?.groupImg.map((img, index) => (
                      <img alt="" key={index} src={img} />
                    ))}
                    <img alt="" src={Images.GroupDiscussion} />
                  </div>
                  300
                  {' '}
                  {English.G198}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="content-manager-awards-wrapper common-card-body mt-40">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Recent Awards</h2>
          <Link>{English.G248}</Link>
        </div>
        <div className="awards-row">
          {items.map((item) => (
            <div className="awards-item" key={item.Title}>
              <div
                className="awards-cover"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="like">
                  <img alt="" src={Images.Likes} />
                </div>
              </div>
              <div className="awards-details">
                <h4>{item.Title}</h4>
                <p>{item.text}</p>
                <div className="awards-other">
                  <div className="awards-profile">
                    <div
                      className="awards-profile-img"
                      style={{
                        background: `url(${item.profile})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    />
                    {item.name}
                  </div>
                  <span className="time-duration-detail">
                    <img alt="" src={Images.ClockFillIco} />
                    {item.time}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default RecentCommunity
