/* eslint-disable max-len */
import React from 'react'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const MyProfile = () => {
  const initialApps = [
    {
      id: 1,
      image: Images.ProfileBorderImage,
      name: 'Sam Will',
      type: '@samwill',
      starBages: Images.BlueStartBage,
      GreenTickRound: Images.GreenTickRound,
      ThreeBlueIcon: Images.ThreeBlueIcon,
      profileStarIcon: Images.profileStarIcon,
      BlueUpArrow: Images.BlueUpArrow,
      active: false,
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 2,
      image: Images.ProfileBorderImage,
      name: 'Sam Will',
      type: '@samwill',
      ThreeBlueIcon: Images.ThreeBlueIcon,
      profileStarIcon: Images.profileStarIcon,
      BlueUpArrow: Images.BlueUpArrow,
      active: false,
      starBages: Images.BlueStartBage,
      GreenTickRound: Images.GreenTickRound,
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 3,
      image: Images.ProfileBorderImage,
      name: 'Sam Will',
      type: '@samwill',
      starBages: Images.BlueStartBage,
      GreenTickRound: Images.GreenTickRound,
      ThreeBlueIcon: Images.ThreeBlueIcon,
      profileStarIcon: Images.profileStarIcon,
      BlueUpArrow: Images.BlueUpArrow,
      active: false,
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 4,
      image: Images.ProfileBorderImage,
      name: 'Sam Will',
      type: '@samwill',
      active: false,
      starBages: Images.BlueStartBage,
      GreenTickRound: Images.GreenTickRound,
      ThreeBlueIcon: Images.ThreeBlueIcon,
      profileStarIcon: Images.profileStarIcon,
      BlueUpArrow: Images.BlueUpArrow,
      number1: '5.204',
      number2: '2.453'
    }
  ]

  return (
    <div className="p-0 bg-gray-50 ">
      <div className="flex justify-between items-center mb-3">
        <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
          {English.A142}
        </div>
        <CommonButton
          label={English.A141}
          type="button"
          // onClick={createProfileClick}
          className="primary-btns w-auto"
          context="primary-btn"
          isPrimary
        />
      </div>
      <p className="text-[#9E9E9E] font-medium text-base">{English.A143}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        {initialApps.map((i) => (
          <div
            key={i.id}
            className={`w-[100%] h-auto p-[12px] rounded-2xl bg-white ${
              i.isSelected ? 'selected' : 'selectable'
            }`}
          >
            <div className="flex flex-col gap-3">
              <div>
                <div className="flex items-center justify-between">
                  <div className="flex gap-10 items-center">
                    <div className="flex items-center gap-2">
                      <div className="relative">
                        <img className="" src={i.image} alt="" />
                        <img
                          className="absolute bottom-0 right-0"
                          src={i.starBages}
                          alt=""
                        />
                      </div>
                      <div className="flex-row items-center gap-2">
                        <div className="flex gap-1 items-center">
                          <h3 className="text-base font-semibold mb-0 text-[#303030]">
                            {i.name}
                          </h3>
                          <img src={i.GreenTickRound} alt="" />
                        </div>
                        <p className="italic text-[13px] font-normal text-[#8D8D8D] mb-[12px]">
                          {i.type}
                        </p>
                        <div className="flex items-center gap-3">
                          <div className="flex items-center gap-2">
                            <img src={i.ThreeBlueIcon} alt="" />
                            <p className="font-semibold text-lg text-[#303030] mb-0">
                              {i.number1}
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <img src={i.profileStarIcon} alt="" />
                            <p className="font-semibold text-lg text-[#303030] mb-0">
                              {i.number2}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img className="" src={i.BlueUpArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MyProfile
