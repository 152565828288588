import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import Rooms from './Rooms'
import RatingContainer from '../RatingContainer/Rating'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import RecentHotels from './RecentHotels'
import Slider from '../ReviewsAndRating/slider'
import RecentEvent from './RecentEvent'
import SliderLocal from './Slider'
import Hotel from './hotel'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const LocalProfile = () => {
  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.HomeIco} />
                  {English.G222}
                </Tab>
                <Tab>
                  <img alt="" src={Images.LocalIco} />
                  {English.G236}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedbackIco} />
                  {English.G427}
                </Tab>
                <Tab>
                  <img alt="" src={Images.AboutIco} />
                  {English.G428}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="mt-40">
                  <RecentHotels />
                </div>
                <div className="mt-40">
                  <Rooms />
                </div>
                <div className="mt-40">
                  <div className="eating--drinks">
                    <div className="d-flex">
                      <div className="w-50 p-40">
                        <h4>Eating & Drinks</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Aliquet quis pretium condimentum enim nisl.
                          Vitae id velit, diam tincidunt. Suspendisse accumsan
                          consequat enim ipsum. Diam fusce pharetra ante amet,
                          nam amet nulla dolor.
                        </p>
                        <button type="button" className="light-button">
                          <span>Explore</span>
                        </button>
                      </div>
                      <div
                        className="w-50 rounded-15"
                        style={{
                          background: 'url(./images/eatanddrink.jpg)',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-40">
                  <RecentEvent />
                </div>
                <div className="mt-40">
                  <SliderLocal />
                </div>
                <div className="about common-card-body recent-video-component mt-40">
                  <h3>{English.G428}</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                    pellentes nullam sit amet, lorem. Semper vel egestas
                    convallis nisl duis mattis ut molestie sed. Mauris feugiat
                    tincidunt nunc, quam mauris, massa diam faucibus.
                    {' '}
                    <span>See more</span>
                  </p>
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <SliderLocal />
                <div className="services-container common-card-body recent-video-component mt-40 position-relative">
                  <h3>Photo Gallery</h3>
                  <div className="grid-gellery">
                    <div className="gellery-item grid-1">
                      <img alt="" src="./images/gellery/1.jpg" />
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img alt="" src="./images/gellery/2.jpg" />
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img alt="" src="./images/gellery/3.jpg" />
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img alt="" src="./images/gellery/4.jpg" />
                      <div className="overlay-gredient" />
                    </div>
                    <div className="gellery-item">
                      <img alt="" src="./images/gellery/5.jpg" />
                      <div className="overlay-gredient" />
                    </div>
                  </div>
                  <button type="button" className="show-photos">
                    <img alt="" src={Images.ShowIco} />
                    <span>Show all photos</span>
                  </button>
                </div>
                <div className="mt-40">
                  <Hotel />
                </div>
                <div className="services-container common-card-body recent-video-component mt-40 position-relative">
                  <h3>Location</h3>
                  <div className="contact-info-item map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
                      width="100%"
                      height="452"
                      allowfullscreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Location"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LocalProfile
