import React, { useState } from 'react'
import './style.css'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const Offer = () => {
  const items = [
    {
      cover: './images/hotels/3.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    },
    {
      cover: './images/hotels/1.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    },
    {
      cover: './images/hotels/2.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    },
    {
      cover: './images/hotels/3.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    },
    {
      cover: './images/hotels/1.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    },
    {
      cover: './images/hotels/2.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    },
    {
      cover: './images/hotels/3.jpg',
      name: 'Hotel Name',
      offerPrice: '€30',
      location: '3517 W. Gray St. Utica, ',
      actualPrice: '€42',
      rating: '4.9'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(6)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3)
  }
  return (
    <div className="offers--hotel common-card-body">
      <div className="d-flex align-items-center justify-content-between recent-video-component">
        <h3 className="m-0">Recent Reviews</h3>
        <div className="drop-search">
          <input placeholder="Search here..." />
        </div>
      </div>
      <div className="hotel-offer--grid mt-30">
        {items.slice(0, visibleItems).map((item) => (
          <div className="hotel-offer-grid--item" key={item.name}>
            <div
              className="hotel-offer--cover"
              style={{
                background: `url(${item.cover})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>{item.rating}</span>
              </div>
            </div>
            <div className="hotel-offer-grid--detail">
              <div className="d-flex align-items-center justify-content-between">
                <h6>{item.name}</h6>
                <p>
                  {item.offerPrice}
                  <span>
                    {' '}
                    {English.G273}
                  </span>
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="location d-flex align-items-center col-gap-10">
                  <img alt="" src={Images.Location} />
                  <span>{item.location}</span>
                </span>
                <p className="actule-price">
                  {item.actualPrice}
                  <span>
                    {' '}
                    {English.G273}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img alt="" src={Images.DownIco} />
          </button>
        </div>
      )}
    </div>
  )
}
export default Offer
