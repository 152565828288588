import React from 'react'
import HotelComponent from './HotelComponent'

const AddHotel = () => {
  return (
    <div className="content-manager-awards-wrapper common-card-body add-profile-video">
      <HotelComponent showCount={3} clickable={false} />
    </div>
  )
}
  export default AddHotel
