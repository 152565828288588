import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'
// import CommonButton from '../../ButtonComponent/ButtonCommon'
import { setUserSliceData } from '../../../services/http-services'

const MemberSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = useNavigate()

  const slides = [
    {
      Image: Images.Member_slider_Image1,
      title: English.A88,
      description: English.A89
    },
    {
      Image: Images.creator_slider_Image1,
      title: English.A90,
      description: English.A89
    },
    {
      Image: Images.Member_slider_Image2,
      title: English.A91,
      description: English.A89
    }
  ]

  const slideRef = useRef(null)
  const goToSlide = (index) => {
    setCurrentSlide(index)
    if (slideRef.current && slideRef.current.swiper) {
      slideRef.current.swiper.slideTo(index)
    }
  }

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-white">
      <img
        src={Images.Member_slider_bg}
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover"
      />

      <div className="w-[60%] h-1/2 bg-electricWhite rounded-3xl overflow-hidden shadow-lg z-10">
        <Swiper
          ref={slideRef}
          navigation
          modules={[Navigation]}
          pagination={{ clickable: false, type: 'custom' }}
          spaceBetween={50}
          mousewheel
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          className="bg-opacity-90 m-[50px]"
          onSnapIndexChange={({ activeIndex }) => setCurrentSlide(activeIndex)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              className="d-flex items-center justify-center"
              key={index}
            >
              <div className="w-[600px] flex-col items-center justify-center">
                <img
                  src={slide.Image}
                  alt="Slide"
                  className={`mb-4 ${
                    index === 0 || index === 2
                      ? 'w-[300px] mx-auto h-[250px]'
                      : 'w-[600px] h-[250px]'
                  }`}
                />
                <h2 className="text-3xl italic text-center text-gradient font-black business_slide_h2">
                  {slide.title}
                </h2>
                <p className="mt-2 text-[#505050] text-center text-lg font-medium business_slide_p">
                  {slide.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="z-20 mt-[5%] w-[60%] relative">
        <div className="flex space-x-4 items-center justify-center">
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              onClick={() => goToSlide(index)}
              className={`transition ${
                currentSlide === index
                  ? 'bg_member_slide_btn w-6 h-3 rounded-2xl'
                  : 'bg_member_slide_btn opacity-[20%] shadow-md w-3 h-3 rounded-full'
              }`}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>
        {currentSlide === 2 && (
          <p className="z-20 mt-[2%] absolute right-0 top-[50%] -translate-y-[50%]">
            {/* <CommonButton
              label={English.A69}
              loading={false}
              onClick={() => {
                navigate('/login')
                setUserSliceData('true', 'onBoardPage')
              }}
              className="primary-button m-auto login_button"
              context="primary"
              isPrimary
            /> */}
            <img
              className="pb-5 cursor-pointer"
              src={Images.arrow_left_black}
              alt="arrow"
              loading={false}
              onClick={() => {
                navigate('/dashboard')
                setUserSliceData('true', 'onBoardPage')
              }}
            />
          </p>
        )}
      </div>
    </div>
  )
}

export default MemberSlider
