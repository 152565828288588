import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { English, Images } from '../../helpers'
import Loader from '../Loader/Loader'
import CommentPagination from '../Pagination/CommentPagination'

const CommentModal = ({
  title,
  comments,
  setComments,
  HandleCancel,
  isLoadingComments,
  setNewComment,
  newComment,
  handleAddComment,
  commentsList,
  profileData,
  setCommentIndex,
  commentIndex,
  shotCommentDelete,
  editCommentId,
  editedCommentText,
  setEditedCommentText,
  handleSaveComment,
  handleLikeComment,
  handleEditComment,
  toggleReplies,
  showReplies,
  handleDeleteReply,
  editReplyId,
  editedReplyText,
  setEditedReplyText,
  handleSaveReply,
  shotCommentReplyLike,
  handleEditReply,
  setShowReplied,
  handleAddReply,
  isDeleting,
  currentPageComment,
  setCurrentPageComment,
  Replied
}) => {
  // const [currentPageComment, setCurrentPageComment] = useState(1)
  const commentsPerPage = 5
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  const handlePageChanges = (pageNumber) => {
    setCurrentPageComment(pageNumber)
  }

  const handleLikeReply = (commentReplayId, replayId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentReplayId) {
        return {
          ...comment,
          reply: comment.reply.map((reply) => {
            return (
              reply.reply_id === replayId
              ? {
                ...reply,
                total_like: reply.is_like ? reply.total_like - 1 : reply.total_like + 1,
                is_like: !reply.is_like
              }
              : { ...reply }
            )
          })
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentReplyLike(replayId)
  }

  return (
    <div className="add-item-modal mh-100">
      <div className="add-item-contents comment-container">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="mb-0">{title}</h3>
          <button
            type="button"
            className="secondary-btn-modal"
            onClick={HandleCancel}
          >
            <img
              className="close-btns"
              src={Images.CloseIco}
              alt="clock icon"
            />
          </button>
        </div>
        <div className="comment-section">
          {isLoadingComments ? (
            <div>
              <div className="comments-container">
                <ul className="comments-list">
                  <li className="comment-item">
                    <button type="button" className="comment-delete">
                      <Skeleton
                        className="rounded-3"
                        width={20}
                        height={20}
                      />
                    </button>
                    <div className="comment-header mb-0 gap-2">
                      <Skeleton circle width={45} height={45} />
                      <span className="user-name">
                        <Skeleton width={150} height={20} />
                      </span>
                    </div>
                    <div className="comment-btns">
                      <button type="button">
                        <Skeleton width={20} height={20} />
                      </button>
                      <Skeleton width={20} height={20} />
                      <button type="button">
                        <Skeleton width={45} height={20} />
                      </button>
                      <button type="button">
                        <Skeleton width={45} height={20} />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="add-comment">
                <input
                  type="text"
                  placeholder={English.G151}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  name="reply"
                />
                <button
                  type="button"
                  onClick={() => handleAddComment(newComment)}
                >
                  {English.G152}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="comments-container">
                {commentsList?.length === 0 ? (
                  <div className="no-comments-message">
                    {English.G148}
                  </div>
                ) : (
                  <ul className="comments-list">
                    {commentsList.map((comment, index) => (
                      <li key={comment.id} className="comment-item">
                        {isDeleting && (
                          <div className="loader-overlay">
                            {commentIndex === index && <Loader />}
                          </div>
                        )}
                        {comment.user.name ===
                          profileData.user_name && (
                            <button
                              type="button"
                              className="comment-delete"
                              onClick={() => {
                                setCommentIndex(index)
                                shotCommentDelete(comment.reply_id)
                              }}
                            >
                              <img src={Images.Delete} alt="delete icon" />
                            </button>
                          )}
                        <div className="comment-header mb-0">
                          <img
                            src={comment.user.profilePic}
                            alt={`${comment.user.name}'s profile`}
                            className="profile-pic"
                          />
                          <span className="user-name">
                            {comment.user.name}
                          </span>
                        </div>
                        {editCommentId === comment.id ? (
                          <div className="edit-reply">
                            <input
                              type="text"
                              value={editedCommentText}
                              onChange={(e) => setEditedCommentText(e.target.value)}
                            />
                            <button
                              type="button"
                              onClick={() => handleSaveComment(
                                comment.reply_id,
                                editedCommentText
                              )}
                            >
                              {English.G117}
                            </button>
                          </div>
                        ) : (
                          <div className="comment-text">
                            {comment.text}
                          </div>
                        )}
                        <div className="comment-btns">
                          <button
                            type="button"
                            onClick={() => handleLikeComment(comment.reply_id)}
                          >
                            {comment.is_like ? (
                              <img src={Images.Likes} alt="Unlike" />
                            ) : (
                              <img src={Images.UnLikes} alt="Like" />
                            )}
                            {' '}
                            {comment.total_like}
                          </button>
                          {comment.user.name ===
                            profileData.user_name && (
                              <button
                                type="button"
                                onClick={() => handleEditComment(
                                  comment.id,
                                  comment.text
                                )}
                              >
                                {English.G81}
                              </button>
                            )}
                          <button
                            type="button"
                            onClick={() => toggleReplies(comment.id)}
                          >
                            {showReplies[comment.id]
                              ? 'Hide Replies'
                              : `Show Replies (${comment?.reply?.length})`}
                          </button>
                        </div>
                        {showReplies[comment.id] && (
                          <ul className="replies-list">
                            {comment.reply.map((reply) => (
                              <li key={reply.id} className="reply-item">
                                <div className="reply-header mb-0">
                                  <img
                                    src={reply.user.profilePic}
                                    alt={`${reply.user.name}'s profile`}
                                    className="profile-pic"
                                  />
                                  <span className="user-name">
                                    {reply.user.name}
                                  </span>
                                  {reply.user.name ===
                                    profileData.user_name && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleDeleteReply(
                                            comment.reply_id,
                                            reply.reply_id
                                          )
                                          setCommentIndex(index)
                                        }}
                                        className="comment-delete"
                                      >
                                        <img
                                          src={Images.Delete}
                                          alt="delete icon"
                                        />
                                      </button>
                                    )}
                                </div>
                                {editReplyId === reply.id ? (
                                  <div className="edit-reply">
                                    <input
                                      type="text"
                                      value={editedReplyText}
                                      onChange={(e) => setEditedReplyText(
                                        e.target.value
                                      )}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => handleSaveReply(
                                        comment.reply_id,
                                        reply.reply_id
                                      )}
                                    >
                                      {English.G117}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="reply-text">
                                    {reply.text}
                                  </div>
                                )}
                                <div className="comment-btns">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleLikeReply(comment.reply_id, reply.reply_id)
                                    }}
                                  >
                                    {reply.is_like ? (
                                      <img src={Images.Likes} alt="Unlike" />
                                    ) : (
                                      <img src={Images.UnLikes} alt="Like" />
                                    )}
                                    {' '}
                                    {reply.total_like}
                                  </button>
                                  {reply.user.name ===
                                    profileData.user_name && (
                                      <button
                                        type="button"
                                        onClick={() => handleEditReply(
                                          reply.id,
                                          reply.text
                                        )}
                                      >
                                        {English.G81}
                                      </button>
                                    )}
                                </div>
                              </li>
                            ))}
                            <li className="add-reply">
                              <input
                                type="text"
                                placeholder={English.G149}
                                value={Replied}
                                onChange={(e) => setShowReplied(e.target.value)}
                              />
                              <button
                                type="button"
                                onClick={() => handleAddReply(
                                  comment.reply_id,
                                  Replied
                                )}
                              >
                                {English.G150}
                              </button>
                            </li>
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {totalCommentPages > 1 && (
                <CommentPagination
                  currentPage={currentPageComment}
                  totalPages={totalCommentPages}
                  onPageChange={handlePageChanges}
                />
              )}
              <div className="add-comment">
                <input
                  type="text"
                  placeholder={English.G151}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  name="reply"
                />

                <button
                  type="button"
                  className="comment_add_btn"
                  onClick={() => handleAddComment(newComment)}
                >
                  {English.G152}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentModal
