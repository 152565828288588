/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import { setUserSliceData } from '../../services/http-services'
import '../../App.css'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import Utility, {
  preventMultipleAtSignKeydown,
  preventMultipleAtSignPaste,
  preventSpaceInput,
  preventSpacePaste
} from '../../helpers/Utility'
import { English, Images } from '../../helpers'
import EndPoints from '../../services/EndPoints'
import { APICall } from '../../services'

const SignInPage = () => {
  const navigate = useNavigate()
  const [showPassword1, setShowPassword1] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeType, setActiveType] = useState(0)
  const [inputFields, setInputFields] = useState({
    email: '',
    password: '',
    app_id: 2,
    is_gbn: false
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [stayLoggedIn, setStayLoggedIn] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [loginResponse, setLoginResponse] = useState(null)

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }
  const InfoClick = () => {
    setInfoModal(true)
  }
  const ClickOk = () => {
    setInfoModal(false)
  }

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.email.length === 0) {
      errors.email = English.G2
    }
    if (inputValues.password.length === 0) {
      errors.password = English.G3
    }
    return errors
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsButtonEnabled(
      inputFields.email.length > 0 && inputFields.password.length > 0
    )
  }

  const handleSubmit = (event) => {
    if (loginResponse) {
      const userTypeMapping = {
        1: 'member',
        2: 'creator',
        3: 'business'
      }
      const userType = userTypeMapping[activeType] || null
      if (userType) {
        setUserSliceData(userType, 'user_type')
      }
      submitNext(userType)
      return
    }
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const submitNext = (userType) => {
    const res = Utility.deepClone(loginResponse)
    const decryptedData = res?.decryptedData
    const tokenData = res?.data?.token
    setUserSliceData(tokenData, 'token')
    const data_data =
      userType === 'creator'
        ? decryptedData?.creator_data
        : userType === 'business'
          ? decryptedData?.business_data
          : decryptedData?.member_data
    const userData = { ...decryptedData, ...data_data }
    setUserSliceData(userData, 'userInfo')
    setUserSliceData(stayLoggedIn, 'stay_login')
    // const isType = (type) => Array.isArray(decryptedData.type)
    //  && decryptedData.type.includes(type)
    if (decryptedData.send_otp) {
      navigate('/otp-verification')
    } else if (
      decryptedData?.creator_data === null ||
      decryptedData?.business_data === null ||
      decryptedData?.member_data === null
    ) {
      navigate('/user-information')
    } else {
      navigate('/dashboard')
    }
    toast.success(res?.data?.message)
  }

  const finishSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, EndPoints.login, 'auth')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // const decryptedData = res?.decryptedData
          // const tokenData = Utility.deepClone(res?.data?.token)
          // setUserSliceData(tokenData, 'token')
          // setUserSliceData(decryptedData, 'userInfo')
          // setUserSliceData(stayLoggedIn, 'stay_login')
          setLoading(false)
          setLoginResponse(res)
          // if (decryptedData.send_otp) {
          //   navigate('/otp-verification')
          // } else if (decryptedData.business_profile === false) {
          //   navigate('/user-information')
          // } else if (decryptedData.user_type_id === 0) {
          //   navigate('/creator-slide')
          // } else {
          //   navigate('/dashboard')
          // }
          // toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  useEffect(() => {
    setIsButtonEnabled(
      inputFields.email.length > 0 && inputFields.password.length > 0
    )
  }, [inputFields])

  return (
    <div className="auth-register auth-login">
      <div className="bg-overlay row overlay-height">
        <div className="col-md-6 py-[20px]">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto no-scrollbar">
            <h2 className="bg-[#151515] text-center login_sign_text pb-3">
              {English.A18}
            </h2>
            <h6 className="bg-[#303030] text-center mb-12">{English.A19}</h6>
            <form onSubmit={handleSubmit}>
              <p
                className={`${loginResponse ? 'pointer-events-none' : 'pointer-events-auto'}`}
              >
                <div className="user-input password-input input-container mb-4">
                  <img
                    src={Images.input_logo}
                    alt="Logo"
                    className="input-logo !w-[30px] !h-[33px]"
                  />
                  <CommonInput
                    name="email"
                    placeholder={English.A20}
                    value={inputFields.email}
                    onChange={handleChange}
                    autocomplete="on"
                    onKeyDown={(e) => preventMultipleAtSignKeydown(e, inputFields.email)}
                    onPaste={preventMultipleAtSignPaste}
                    className="custom-input"
                  />
                  <button onClick={InfoClick} type="button" className="mr-1">
                    <img alt="" src={Images.Info_icon} />
                  </button>
                </div>
                {errors.email ? (
                  <p className="error-text">{errors.email}</p>
                ) : null}
              </p>

              <p
                className={`${loginResponse ? 'pointer-events-none' : 'pointer-events-auto'}`}
              >
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="password"
                    className="input-logo"
                  />
                  <CommonInput
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    value={inputFields.password}
                    placeholder={English.A21}
                    onChange={handleChange}
                    className="text_password custom-input"
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={Images.eyeOn} />
                    ) : (
                      <img alt="" src={Images.eyeOff} />
                    )}
                  </button>
                </div>
                {errors.password ? (
                  <p className="error-text">{errors.password}</p>
                ) : null}
              </p>
              <p
                className={`${loginResponse ? 'pointer-events-none' : 'pointer-events-auto'} d-flex justify-between items-center mb-4`}
              >
                <div>
                  <p className="stay_login d-flex gap-2 mb-0">
                    <div className="flex items-center">
                      <input
                        id="inline-2-checkbox"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={stayLoggedIn}
                        onChange={(e) => setStayLoggedIn(e.target.checked)}
                      />
                    </div>
                    {English.A26}
                  </p>
                </div>
                <Link to="/forget-password" className="clip-text text-end">
                  {English.A23}
                </Link>
              </p>

              {loginResponse && (
                <div className="login-container mb-8">
                  <h4 className="pt-0 login_as">{English.A43}</h4>
                  <div className="login-options d-flex gap-5 justify-center">
                    <div
                      onClick={() => setActiveType(1)}
                      className={`login-option cursor-pointer ${activeType === 1 && 'login-type-active'}`}
                    >
                      <img
                        src={Images.Member_icon}
                        alt="Member"
                        className="login-image"
                      />
                      <p className="mb-0">{English.A44}</p>
                    </div>
                    <div
                      onClick={() => setActiveType(2)}
                      className={`login-option ${activeType === 2 && 'login-type-active'}`}
                    >
                      <img
                        src={Images.Creator_icon}
                        alt="Creator"
                        className="login-image"
                      />
                      <p className="mb-0">{English.A45}</p>
                    </div>
                    <div
                      onClick={() => setActiveType(3)}
                      className={`login-option ${activeType === 3 && 'login-type-active'}`}
                    >
                      <img
                        src={Images.Business_icon}
                        alt="Business"
                        className="login-image"
                      />
                      <p className="mb-0">{English.A46}</p>
                    </div>
                  </div>
                </div>
              )}

              <p>
                <CommonButton
                  label={loginResponse ? English.B58 : English.A22}
                  loading={loading}
                  type="submit"
                  className="primary-button m-auto login_button"
                  disabled={
                    !isButtonEnabled ||
                    loading ||
                    (loginResponse && activeType === 0)
                  }
                  context="primary"
                  isPrimary
                />
              </p>

              {infoModal && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white rounded-2xl shadow-lg p-8 w-[460px]">
                    <h2 className="text-2xl font-black italic text-center bg-[#0B0A0A] pb-3 mb-3">
                      {English.A7}
                    </h2>
                    <p className="text-[#303030] font-medium text-base text-center mb-6">
                      {English.A47}
                    </p>
                    <CommonButton
                      label={English.G72}
                      type="button"
                      onClick={ClickOk}
                      className="primary-button m-auto login_button"
                      context="primary"
                      isPrimary
                    />
                  </div>
                </div>
              )}
            </form>
            <footer className="flex-1 d-flex flex-col">
              <p className="dont_account mb-2 !font-semibold">{English.A24}</p>
              <p className="sing_up_text flex flex-1 flex-row justify-center !font-extrabold">
                <Link to="/register">{English.A25}</Link>
              </p>
              <div className="text-[10px] text-placeholder text-center items-center justify-center !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
                erat enim mattis nunc, eget volutpat. Aenean sed urna, facilisi
                vitae, volutpat faucibus.enim mattis nunc, eget volutpat. Aenean
                sed urna, facilisi vitae, volutpat faucibus
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignInPage
