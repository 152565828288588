import React, { useEffect, useRef, useState } from 'react'
import '../AddAwards/AddAwards.css'
import ReactDatePicker from 'react-datepicker'
import './style.css'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const AddEvents = () => {
  const [ShowEventModel, setShowEventModel] = useState(false)
  const AddEvent = () => {
    setShowEventModel(true)
  }
  const HandleCancel = () => {
    setShowEventModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowEventModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [startDate, setStartDate] = useState(new Date())
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>{English.G306}</h2>
        </div>
        <div className="awards-row">
          <div className="add-local-events-item">
            <div className="position-relative add-local-event-top">
              <button type="button" className="add-item-btn" onClick={AddEvent}>
                <img alt="" src={Images.Plus} />
              </button>
              <p className="people-block" />
              <p className="date-block" />
              <div className="detail-block">
                <h4 className="add-value">.</h4>
                <h5 className="add-value">.</h5>
                <h6 className="add-value">.</h6>
              </div>
            </div>
            <div className="add-event-detail">
              <p className="add-value" />
              <span className="add-value" />
            </div>
          </div>
        </div>
      </div>
      {ShowEventModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={Images.upload} />
                  </div>
                  <h4>{English.G458}</h4>
                  <p>
                    {English.G156}
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>{English.G216}</span>
                  </button>
                </label>
              </div>
              <div>
                <label>Tagline</label>
                <input placeholder="Tagline" />
              </div>
              <div>
                <label>{English.G162}</label>
                <input placeholder={English.G162} />
              </div>
              <div>
                <label>Sub title</label>
                <input placeholder="Sub title" />
              </div>
              <div>
                <label>{English.G131}</label>
                <input placeholder="Enter Price" />
              </div>
              <div className="celender-input">
                <label>{English.G383}</label>
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                <label>{English.G112}</label>
                <textarea placeholder={English.G83} />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button type="button" className="secondary-btn-model">{English.G152}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddEvents
