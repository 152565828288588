/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  s3ImagePodcast,
  s3ImageProfile,
  urlSystemIcon
} from '../../services/Service'
import { timeDifference } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const Podcasts = () => {
  const navigate = useNavigate()
  const audioRefs = useRef({})
  const userData = useSelector((state) => state.user?.userData)

  const [isLoading, setIsLoading] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [isPlaying, setIsPlaying] = useState({})
  const [podcastData, setPodcastData] = useState([])
  const [expandedIndex, setExpandedIndex] = useState(null)

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 1 } })
  }

  const getPodcastLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getPodcastLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPodcastData(res?.decryptedData.data)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleMouseEnter = (index, attachment) => {
    setHoveredIndex(index)

    if (!audioRefs.current[index]) {
      const newAudio = new Audio(attachment)
      newAudio.onerror = (e) => {
        console.error('Audio playback error:', e)
      }
      audioRefs.current[index] = newAudio
    }

    audioRefs.current[index]
      .play()
      .then(() => {
        setIsPlaying((prev) => ({ ...prev, [index]: true }))
      })
      .catch((error) => {
        console.error('Error playing audio:', error)
      })
  }

  const handleMouseLeave = (index) => {
    setHoveredIndex(null)

    if (audioRefs.current[index]) {
      audioRefs.current[index].pause()
      audioRefs.current[index].currentTime = 0
      setIsPlaying((prev) => ({ ...prev, [index]: false }))
    }
  }

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index]

    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            setIsPlaying((prev) => ({ ...prev, [index]: true }))
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } else {
        audio.pause()
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    }
  }
  const playVolumeIcon = (index) => (
    <img
      src={isPlaying[index] ? Images.PodcastPauseIco : Images.PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={() => togglePlayPause(index)}
      className="volume-icon"
    />
  )

  useEffect(() => {
    getPodcastLibraryData()
  }, [])

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])


  return (
    <div className="content-manager-podcasts-wrapper common-card-body">
      <div className="podcasts-wrapper-header common-contnt-wrapper">
        <h2>Podcasts</h2>
        <div className="" onClick={viewAll}>
          <Link>{English.G248}</Link>
        </div>
      </div>
      <div className="Podcasts-item-details">
        {podcastData?.length === 0 && !isLoading ? (
          <div className="awards-item-video">
            <div className="add-profile-video-cover">
              <button type="button" onClick={viewAll}>
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="add-profile-video-detail">
              <p />
              <div className="d-flex align-items-center justify-content-between">
                <span />
                <span />
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          podcastData?.slice(0, 4)?.map((item, index) => (
            <div className="Podcasts-item-detail" key={item.name}>
              <div
                className="podacast-items"
                key={s3ImagePodcast + item.cover_attachment}
                onMouseEnter={() => handleMouseEnter(index, `${s3ImagePodcast}${item.attachment}`)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div
                  className="podcast-cover"
                  style={{
                    backgroundImage: `url(${
                      hoveredIndex === index
                        ? `${s3ImagePodcast}${item.cover_attachment}`
                        : `${s3ImagePodcast}${item.cover_attachment}`
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  {item.app_data?.length > 0 && (
                    <span
                      className={`platform-badge ${
                        item?.app_data?.length === 2 ? 'platform-badge-alt' : ''
                      }`}
                    >
                      {item?.app_data?.map((data, i) => (
                        <div
                          key={i}
                          className={`badge-cover ${
                            item?.app_data?.length === 2 ? 'badge-cover-alt' : ''
                          }`}
                          style={{
                            backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '90%',
                            backgroundPosition: 'center'
                          }}
                        />
                      ))}
                    </span>
                  )}
                  {playVolumeIcon(index)}
                  {/* <button type="button" className="drop-button">
                  <img className="d-block" src={MoreHorizontal} />
                </button> */}
                </div>
                <div className={`video-details podcast-detail ${expandedIndex === index ? 'expanded' : ''}`}>
                  <div className="d-flex justify-content-between user_images">
                    <h6>
                      <img src={s3ImageProfile + userData.profile_image} alt="" />
                      <span>{userData?.user_name}</span>
                    </h6>
                    <div className="rating width_all">
                      <img className="rating_image_podcast" src={Images.RatingStar} alt="rating" />
                      <span>4.9</span>
                    </div>
                  </div>
                  <p>
                    {expandedIndex === index
                    ? item?.title
                    : `${item?.title?.slice(0, 45)}...`}
                    {item?.title?.length > 45 && (
                    <span
                      className="see-more"
                      onClick={(e) => {
                        e.stopPropagation()
                        setExpandedIndex(
                          expandedIndex === index ? null : index
                        )
                      }}
                    >
                      {expandedIndex === index ? English.G145 : English.G146}
                    </span>
                  )}
                  </p>
                  <div className="other-info">
                    <span>
                      <img className="d-block" src={Images.ClockFillIco} alt="" />
                      {timeDifference(item.created_at)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
export default Podcasts
