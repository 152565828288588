import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import 'swiper/css/pagination'
import 'swiper/css'
import { useSelector } from 'react-redux'
import { Images } from '../../../helpers'
import { setUserSliceData } from '../../../services/http-services'

const CreatorSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = useNavigate()
  const timeoutRef = useRef(null)
  const UserData = useSelector((state) => state.user?.userData)

  const slides = [
    {
      Image: Images.creator_slider_Image1,
      title: 'YOU HAVE SUCCESSFULLY REGISTERED AS AN APP WORLD ONE CREATOR!',
      description:
        'We want to tailor your experience so you’ll feel right at home. Choose your user type. With the World One ID app, up to'
    },
    {
      Image: Images.creator_slider_Image2,
      title:
        'NOW SELECT THE APPS ON WHICH YOU WANT TO CREATE PROFILES AS A CREATOR!',
      description:
        'We want to tailor your experience so you’ll feel right at home. Choose your user type. With the World One ID app, up to'
    },
    ...(UserData.user_type_id !== 0
      ? [
          {
            Image: Images.creator_slider_Image3,
            title: 'THANK YOU! WE WILL CHECK YOUR REQUEST AND VERIFY YOUR PROFILES!',
            description:
              'While your profiles are being verified, you can use the profile builder to upload content that will help us unlock you faster.'
          }
        ]
      : [])
  ]

  const slideRef = useRef(null)
  const goToSlide = (index) => {
    setCurrentSlide(index)
    if (slideRef.current && slideRef.current.swiper) {
      slideRef.current.swiper.slideTo(index)
    }
  }

  useEffect(() => {
    if (currentSlide === 1 && UserData.user_type_id === 0) {
      timeoutRef.current = setTimeout(() => {
        navigate('/creator-create-profile')
      }, 2500)
    } else {
      clearTimeout(timeoutRef.current)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [currentSlide, navigate, UserData.user_type_id])

  return (
    <div className="relative flex flex-col d-flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-white">
      <img
        src={Images.creator_slide_bg}
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      <div className={`w-[60%] h-1/2 bg-electricWhite rounded-3xl overflow-hidden shadow-lg z-10 ${UserData.user_type_id !== 0 && 'pointer-events-none'}`}>
        <Swiper
          ref={slideRef}
          navigation
          initialSlide={UserData.user_type_id !== 0 ? 2 : 0}
          modules={[Navigation]}
          pagination={{ clickable: false, type: 'custom' }}
          spaceBetween={50}
          mousewheel
          noSwiping={UserData.user_type_id !== 0}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          className="bg-opacity-90 m-[50px]"
          onSnapIndexChange={({ activeIndex }) => setCurrentSlide(activeIndex)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide className="d-flex items-center justify-center" key={index}>
              <div className="w-[600px] flex-col items-center justify-center">
                <img
                  src={slide.Image}
                  alt="Slide"
                  className={`mb-4 ${
                    index === 1 || index === 2
                      ? 'w-[450px] mx-auto h-[250px]'
                      : 'w-[600px] h-[250px]'
                  }`}
                />
                <h2 className="text-xl font-bold creator_slider_h2">
                  {slide.title}
                </h2>
                <p className="mt-2 text-[#505050] text-center">
                  {slide.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="z-20 mt-[5%] w-[60%] relative">
        <div className="flex space-x-4 items-center justify-center">
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              onClick={() => {
                if (UserData.user_type_id === 0) {
                  goToSlide(index)
                }
              }}
              className={`transition ${
                currentSlide === index
                  ? 'creator_slider_btn_color w-6 h-3 rounded-2xl'
                  : 'creator_slider_btn_color opacity-[20%] shadow-md w-3 h-3 rounded-full'
              } ${UserData.user_type_id !== 0 && '!cursor-default'}`}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>
        {currentSlide === slides.length - 1 && UserData.user_type_id !== 0 && (
          <p className="z-20 mt-[2%] absolute right-0 top-[50%] -translate-y-[50%]">
            {/* <CommonButton
              label={English.A69}
              loading={false}
              onClick={() => {
                navigate('/dashboard')
                setUserSliceData('true', 'onBoardPage')
              }}
              className="primary-button m-auto login_button"
              context="primary"
              isPrimary
            /> */}
            <img
              className="pb-5 cursor-pointer"
              src={Images.arrow_left_black}
              alt="arrow"
              loading={false}
              onClick={() => {
                navigate('/dashboard')
                setUserSliceData('true', 'onBoardPage')
              }}
            />
          </p>
        )}
      </div>
    </div>
  )
}

export default CreatorSlider
