import React from 'react'
import '../LandingPage.css'

const UserType = ({ title, description, onClick }) => {
  return (
    <div onClick={onClick} className="bg-white !p-4 rounded-xl flex flex-col items-center">
      <div className="w-full aspect-[16/9] rounded-xl bg-primarySky" />
      <div className="bg-primaryGradient text-center font-black pr-1 pt-2 pb-2 text-xl italic bg-clip-text text-transparent">
        { title }
      </div>
      <div className="bg-primaryGradient pr-1 pb-2 text-sm italic bg-clip-text text-transparent text-center">
        { description }
      </div>
    </div>
  )
}

export default UserType
