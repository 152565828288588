/* eslint-disable react/no-unescaped-entities */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import English from '../../../helpers/English'
import CommonInput from '../../InputComponet/CommonInput'
import Dropdown from '../../Dropdown/Dropdown'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'

const ProfileVerification = ({
  selctedItem,
  iconImg,
  handleImageUpload,
  verificationData,
  handleChange,
  socialMediaLinks,
  socialMediaOptions,
  handleInputChange,
  removeInputField,
  errors,
  addInputField,
  certificateUrl,
  handleCertificateUpload,
  renderImg,
  handleImageChange,
  organizationData,
  prevStep,
  nextStep,
  iconObj,
  Images
}) => {
  const [activeType, setActiveType] = useState(0)
  const userType = useSelector((state) => state.user?.user_type)
  const UserData = useSelector((state) => state.user?.userData)
  const [infoModal, setInfoModal] = useState(false)

  const InfoClick = () => {
    setInfoModal(true)
  }
  const ClickOk = () => {
    setInfoModal(false)
  }

  const TypeItem = ({ type = 0 }) => (
    <div
      className={`w-[360px] p-[16px] bg-[#FFFFFF] flex flex-col rounded-2xl ${
      activeType === type ? 'login-type-active' : 'border border-[#E4E4E4]'
    }`}
      onClick={() => setActiveType(type)}
    >
      <div
        className="flex justify-end cursor-pointer"
        onClick={() => setActiveType(type)}
      >
        {activeType === type && (
          <img
            src={Images.checkedRoundDarkBlue}
            className="w-6 h-6"
            alt="Checked"
          />
        )}
        {activeType !== type && (
          <div className="w-6 h-6 border-3 border-[#c8c8c8] rounded-full" />
        )}
      </div>
      <div className="d-blok grid text-center justify-center mb-[20px]">
        <img
          src={
            type === 1
              ? Images.IdCard_image
              : type === 2
                ? Images.Phone_image
                : Images.Social_media_image
          }
          alt="logo"
          className="m-auto"
        />
        <div className="flex flex-col ml-[8px]">
          <span className="italic text-main font-black text-[22px]">
            {type === 1
              ? English.A102
              : type === 2
                ? English.A117
                : English.A118}
          </span>
          <span className="italic text-main font-black text-[12px]">
            {type === 1 ? English.B31 : type === 2 ? English.B32 : English.B33}
          </span>
        </div>
      </div>
      <div className="text-secondary flex flex-1 font-normal text-[14px] text-center">
        {type === 1 ? English.B34 : type === 2 ? English.B34 : English.A119}
      </div>
    </div>
  )
  const TypeItems = ({ type = 0 }) => (
    <div
      className={`w-[360px] p-[16px] bg-[#FFFFFF] flex flex-col rounded-2xl ${
      activeType === type ? 'login-type-active' : 'border border-[#E4E4E4]'
    }`}
      onClick={() => setActiveType(type)}
    >
      <div className="d-blok flex items-center mb-[20px]">
        <img
          src={
            type === 1
              ? Images.memberLogo
              : type === 2
                ? Images.creatorLogo
                : Images.businessLogo
          }
          alt="logo"
        />
        <div className="flex flex-col ml-[8px]">
          <span className="italic text-main font-black text-[22px]">
            {type === 1
              ? English.A102
              : type === 2
                ? English.A103
                : English.A104}
          </span>
          <span className="italic text-main font-black text-[12px]">
            {type === 1 ? English.B31 : type === 2 ? English.B32 : English.B33}
          </span>
        </div>
      </div>
      <div className="text-[#303030] flex flex-1 font-medium text-[14px] mb-[20px] text-center">
        {type === 1 ? English.B34 : type === 2 ? English.B35 : English.B36}
      </div>
      <div className="border-t-2 border-dashed border-inactive w-full mb-[12px]" />
      <div className="mb-[10px]" onClick={() => setActiveType(type)}>
        {activeType === type && (
          <img
            src={Images.checkedRoundDarkBlue}
            className="w-6 h-6"
            alt="Checked"
          />
        )}
        {activeType !== type && (
          <div className="w-6 h-6 border-3 border-[#c8c8c8] rounded-full" />
        )}
      </div>
    </div>
  )

  const ComponentToRender = userType === 'creator' ? TypeItem : TypeItems

  return (
    <div className="rounded-3xl p-[30px] bg-white !pb-[0px]">
      <div className="">
        <div style={{ position: 'static' }} className="sticky-header pt-0">
          <h1 className="font-black text-[22px] italic flex gap-4 justify-center">
            {userType === 'creator' ? English.A120 : English.A98}
            {' '}
            <img src={Images.info} alt="" />
          </h1>
          {userType === 'creator' ? <p className="sub-text-3 text-center">{English.A121}</p> : ''}
        </div>
        <div
          // className={`scroll_bars_create_3 ${userType === 2 || selctedItem?.includes('Creator')
          //  ? 'scroll_bars_create_3 additional-class' : 'scroll_bars_create_3'}`}
          className={`${userType === 'creator' ? 'scroll_bars_create_3' : 'scroll_bars_create_3_business'}`}
          // className="scroll_bars_create_3 !h-[calc(100vh-315px)]"
        >
          <div className="profile-pics mt-3">
            <div className="profile-pic-images">
              <CommonImageUpload
                handleImageChange={handleImageUpload}
                index={0}
                forImage="create-profile"
                className="profile-upload"
                selectedImg={iconImg}
                defaultImg={Images.dummy_avatar}
                defaultImgCamera={Images.SelectImg}
              />
            </div>
          </div>
          {userType === 'creator' ? (
            <div className="item-details-input mt-4">
              <div className="">
                <label>{English.G80}</label>
                <CommonInput
                  value={userType === 'creator' ? 'CREATOR' : selctedItem}
                  className="user-type profile_input"
                  readOnly
                />
              </div>
            </div>
          ) : (
            <div className="item-details-input d-flex col-gap-20 mt-4">
              <div className="w-50">
                <label>{English.G80}</label>
                <CommonInput
                  value={selctedItem}
                  className="user-type"
                  readOnly
                />
              </div>
              <div className="w-50">
                <label>{English.A99}</label>
                <CommonInput
                  value={UserData?.user_name}
                  className="user-type"
                  readOnly
                />
              </div>
            </div>
          )}
          {userType === 'business' && (
            <div className="item-details-input">
              <div>
                <label className="mb-2">{English.G112}</label>
                <CommonInput
                  onChange={handleChange}
                  name="description"
                  placeholder={English.A100}
                  value={verificationData.description}
                  isTextArea
                  className="profile_input"
                  maxLength={150}
                />
              </div>
            </div>)}

          {(userType === 'c1reator' || selctedItem?.includes('Creator')) && (
            <div className="social-media-link-input px-2">
              <label className="create_profile_add_link">{English.G82}</label>
              {socialMediaLinks.map((link, index) => (
                <div
                  key={index}
                  className="item-details-input p-0 socialmedia-select"
                >
                  <Dropdown
                    title=""
                    img={link.img}
                    Data={socialMediaOptions}
                    onChanged={() => {}}
                    customClass="create_profile_link_dropdown"
                  />
                  <div className="input-and-remove">
                    <CommonInput
                      className="mb-2 profile_input"
                      placeholder={English.G84}
                      value={link.link}
                      onChange={(e) => handleInputChange(
                          index,
                          e.target.value,
                          socialMediaOptions[index].img
                        )}
                    />
                    <button
                      type="button"
                      className="remove-button mt-2"
                      onClick={() => removeInputField(index)}
                    >
                      <img src={Images.Remove} alt="" />
                    </button>
                  </div>
                </div>
              ))}
              {errors !== '' && <p className="error-message">{errors}</p>}
              <div
                className={`add-video-rows ${socialMediaLinks.length >= 7 ? 'disabled' : ''}`}
                onClick={socialMediaLinks.length < 7 ? addInputField : null}
              >
                <div className="add_new_content">
                  <img src={Images.plusrounded} alt="" />
                  {English.G85}
                </div>
              </div>
            </div>
          )}

          {userType === 'creator' && (
            <div className="">
              <label>{English.B61}</label>
              <CommonInput
                placeholder={English.A167}
                name="website_link"
                value={socialMediaLinks[0].link}
                className="profile_input px-4 !font-light !rounded-full"
                onChange={(e) => handleInputChange(
                  0,
                  e.target.value,
                  socialMediaOptions[0].img
                )}
              />
              {errors !== '' && <p className="error-message">{errors}</p>}
            </div>
          )}

          {selctedItem == 'Expert' && (
            <div className="item-details-input expert-verification px-2">
              <label>{English.G86}</label>
              <CommonInput
                placeholder={English.G84}
                name="website_link"
                value={verificationData.website_link}
                onChange={handleChange}
                className="mb-2"
              />
              <label>{English.G87}</label>
              <div className="input-with-button mb-2 d-flex">
                <CommonInput value={certificateUrl} placeholder={English.G88} />
                <label htmlFor="upload" className="upload-btn primary-btn">
                  <span>{English.G88}</span>
                  <CommonInput
                    type="file"
                    id="upload"
                    onChange={handleCertificateUpload}
                    className="primary-button d-none"
                  />
                </label>
              </div>
            </div>
          )}

          {selctedItem == 'Local' && (
            <div className="item-details-input expert-verification local-verification px-2">
              <label>{English.G86}</label>
              <CommonInput
                placeholder={English.G84}
                name="website_link"
                value={verificationData.website_link}
                onChange={handleChange}
                className="mb-3"
              />
              {errors !== '' && <p className="error-message">{errors}</p>}
              <label>{English.G90}</label>
              <CommonInput
                placeholder={English.G91}
                name="local_address"
                value={verificationData.local_address}
                onChange={handleChange}
                className="mb-3"
              />
              <div>
                <p className="create_profile_local_add_link">{English.G92}</p>
                <div className="input-image-show justify-content-start">
                  {renderImg}
                  {verificationData.local_address_image.length < 4 && (
                    <div className="input-img-label">
                      <CommonImageUpload
                        handleImageChange={(file) => handleImageChange(file)}
                        buttonText="Add Image"
                        selectedImg=""
                        defaultImg={Images.plusgredient}
                        className="plsimg"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {selctedItem == 'Store' && (
            <div className="item-details-input social-media-link-input store-verification px-2">
              <label>{English.G93}</label>
              <CommonInput
                className="mb-2"
                placeholder={English.G84}
                name="store_link"
                value={verificationData.store_link}
                onChange={handleChange}
              />
              {errors !== '' && <p className="error-message">{errors}</p>}
            </div>
          )}

          {selctedItem == 'Organization' && (
            <div className="item-details-input expert-verification organization-verification px-2">
              <label>{English.G94}</label>
              <div className="custome-select">
                <Dropdown
                  title={
                    verificationData.organization_type === ''
                      ? organizationData[0]?.name
                      : verificationData.organization_type
                  }
                  onChanged={(d) => {
                    handleChange({
                      target: {
                        name: 'organization_type',
                        value: d.title
                      }
                    })
                  }}
                  Data={organizationData.map((d) => ({
                    title: d.name,
                    id: d.id
                  }))}
                />
              </div>
              <label>{English.G86}</label>
              <CommonInput
                placeholder={English.G84}
                name="website_link"
                value={verificationData.website_link}
                onChange={handleChange}
                className="mb-2"
              />
            </div>
          )}

          <div className="item-details-input mt-3">
            <label className="flex text-center gap-2">
              {English.A101}
              {userType === 'creator' && (
                <button onClick={InfoClick} type="button" className="mr-1 w-[22px] h-[22px]">
                  <img alt="" src={Images.info_blue_icon} />
                </button>
              )}
            </label>
            <div className="flex justify-start gap-7 mb-[20px]">
              <ComponentToRender type={1} />
              <ComponentToRender type={2} />
              <ComponentToRender type={3} />
            </div>
          </div>

          {infoModal && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded-2xl shadow-lg p-8 w-[400px]">
                <div className="flex justify-between items-center">
                  <h2 className="text-3xl italic font-black text-gradient pr-1">
                    {English.A122}
                  </h2>
                  <button
                    onClick={ClickOk}
                    type="button"
                    className="text-gray-500 hover:text-gray-800"
                  >
                    <img
                      src={Images.Close_Icon_New}
                      alt="Close Icon"
                      className="w-5 h-5"
                    />
                  </button>
                </div>
                <p className="text-gradient mb-4 mt-2">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="steps-button sticky-footer mt-0 px-3">
          <CommonButton
            label={English.A116}
            type="button"
            onClick={prevStep}
            className="secondary-btns btn-prev-case3 w-auto"
            context="secondary-btn"
          />
          <CommonButton
            label={English.G96}
            type="button"
            onClick={nextStep}
            className="primary-btns btn-next-case3 uppercase"
            disabled={!iconObj}
            context="primary-btn"
            isPrimary
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileVerification
