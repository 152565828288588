import React, { useEffect, useState, useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Loader from '../Loader/Loader'
import { urlImageProductPathStore } from '../../services/Service'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import { compressImage, dataDecrypt } from '../../services/http-services'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import Dropdown from '../Dropdown/Dropdown'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const AddEditProductLibaryModal = ({
  setShowProduct,
  setIsProductEdit,
  isProductEdit,
  editData,
  onComplete
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const modelheaderRef = useRef(null)
  const modelFooterRef = useRef(null)
  const componentRef = useRef()
  const [modalHeight, setModalHeight] = useState(0)
  const [isEdited, setIsEdited] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [newImageLoading, setNewImageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [inputFields, setInputFields] = useState(
    isProductEdit
      ? editData
      : {
          name: '',
          regular_price: '',
          discount_price: '',
          category_id: '',
          sub_category_id: '',
          product_link: '',
          description: '',
          main_image: '',
          image_1: '',
          image_2: '',
          image_3: '',
          search: '',
          id: ''
        }
  )
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const [error, setError] = useState({})

  const ProductDataSubmit = () => {
    setLoading(true)
    inputFields.main_image = selectedImages[0] ?? ''

    inputFields.image_1 = selectedImages[1] ?? ''

    inputFields.image_2 = selectedImages[2] ?? ''

    inputFields.image_3 = selectedImages[3] ?? ''

    if (!inputFields.discount_price) {
      inputFields.discount_price = 0
    }
    APICall('post', inputFields, `${EndPoints.addProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onComplete()
            setIsEdited(false)
            toast.success(res?.data?.message)
            setLoading(false)
            setShowProduct(false)
            setIsProductEdit(false)
          }, 3000)
        } else {
          setIsEdited(false)
          setLoading(false)
          setShowProduct(false)
          setIsProductEdit(false)
          setLoading(false)
           toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const ResetValue = () => {
    setSelectedImages([])
    setInputFields({
      name: '',
      regular_price: '',
      discount_price: '',
      category_id: '',
      sub_category_id: '',
      product_link: '',
      description: '',
      main_image: '',
      image_1: '',
      image_2: '',
      image_3: '',
      search: '',
      id: ''
    })
    setSubCategory([])
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isFormValid) {
      if (isProductEdit) {
        EditProductLib(inputFields)
      } else {
        ProductDataSubmit()
      }
    }
  }

  const ProductAddCancel = () => {
    setShowProduct(false)
    setIsProductEdit(false)
    ResetValue()
  }

  const handleImageChange = async (file, indexToReplace) => {
    if (!file) return

    const updatedImages = selectedImages.slice(0)
    setIsImageLoading({
      index: indexToReplace,
      loading: true
    })

    if (indexToReplace === undefined && updatedImages.length + 1 > 4) {
      toast.error('Please select a maximum of 4 images.')
      return
    }

    if (indexToReplace === undefined) {
      setNewImageLoading(true)
    }

    setIsEdited(true)

    const url = await finishSubmit(file)

    if (indexToReplace !== undefined) {
      updatedImages.splice(indexToReplace, 1, url)
    } else {
      updatedImages.push(url)
    }
    setSelectedImages(updatedImages)
    if (indexToReplace === undefined) {
      setNewImageLoading(false)
    }
  }

  const finishSubmit = async (file) => {
    const imageType = 'product'
    try {
      const compressedImage = await compressImage(file)
      const formData = new FormData()
      formData.append('file', compressedImage)
      formData.append('type', imageType)
      return new Promise((resolve) => {
        APICall('post', formData, EndPoints.uploadImage, 'store', true)
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              resolve(dataDecrypt(res.data.data))
            } else {
              toast.error(res?.data?.message)
              resolve(null)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
            resolve(null)
          })
      })
    } catch (error) {
      toast.error('Image compression failed!')
      return Promise.resolve(null)
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
    setIsEdited(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b

        return copy
      })
      setIsEdited(true)
      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages?.map((image, index) => (
          <div
            className={`image position-relative ${
              index === 0 ? 'first-image' : ''
            }`}
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => {
              event.preventDefault()
              handleDrop(index)
            }}
          >
            {isImageLoading.index === index && isImageLoading.loading && (
              <Loader />
            )}
            <img
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              src={
                typeof image === 'string'
                  ? urlImageProductPathStore + image
                  : URL.createObjectURL(image)
              }
              alt={`image-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              onLoad={() => handleLoad(index)}
              onError={() => {
                setIsImageLoading({
                  index: null,
                  loading: false
                })
              }}
            />
            <button type="button" onClick={() => removeImage(index)}>
              <img src={Images.Remove} alt="remove" />
            </button>
            <button type="button" className="plus-img">
              <CommonImageUpload
                handleImageChange={(file) => {
                  handleImageChange(file, index)
                }}
                selectedImg=""
                defaultImg={Images.plusimg}
                className="upload_label"
              />
            </button>
          </div>
        ))}
        {newImageLoading && (
          <div className="image position-relative">
            <Loader />
          </div>
        )}
      </>
    )
  }, [selectedImages, handleDragStart])

  const MainCategory = () => {
    APICall('post', {}, EndPoints.category, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const SubCategory = (data) => {
    APICall('post', data, EndPoints.subCategory, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setSubCategory(res?.decryptedData[0]?.sub_category_data)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const mainCategoryInitialized = useRef(false)

  useEffect(() => {
    if (!mainCategoryInitialized.current) {
      mainCategoryInitialized.current = true
      MainCategory()
    }
  }, [])

  const getCategoryName = (categoryId) => {
    const categoryObj = category?.find((cat) => cat?.id === categoryId)
    return categoryObj ? categoryObj?.name : English.G134
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory?.find(
      (sc) => sc?.sub_category_id === subCategoryId
    )
    return subCategoryItem ? subCategoryItem?.sub_category_name : English.G135
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'regular_price' || name === 'discount_price') {
      if (/^\d*\.?\d*$/.test(value) && !value.startsWith('.')) {
        setInputFields((prev) => ({
          ...prev,
          [name]: value
        }))
      }

      if (
        name === 'discount_price' &&
        parseFloat(value) > parseFloat(inputFields.regular_price)
      ) {
        setError((prev) => ({
          ...prev,
          discount_price: 'Discount price cannot be higher than regular price'
        }))
      } else {
        setError((prev) => ({
          ...prev,
          discount_price: ''
        }))
      }

      return
    }

    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const PlatformPreview = () => {
    setShowProduct(false)
    setIsProductEdit(false)
    ResetValue()
  }

  const validateForm = () => {
    const {
      name,
      regular_price,
      category_id,
      sub_category_id,
      product_link,
      description
    } = inputFields

    const isValid =
      name &&
      regular_price &&
      category_id &&
      sub_category_id &&
      product_link &&
      description &&
      selectedImages.length > 0 &&
      !error.discount_price

    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm()
  }, [inputFields, selectedImages, error, isEdited])

  const oneProductDetails = (data) => {
    const platFormData = {
      app_id: app_id.id,
      plateform: 'web',
      product_id: data.id
    }
    APICall('post', platFormData, `${EndPoints.productDetail}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const productDetails = dataDecrypt(res.data.data)
          SubCategory({ category_id: productDetails.category_id })
          setInputFields(productDetails)
          const Urls = productDetails?.images?.map((i) => i.image)
          setSelectedImages(Urls)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const EditProductLib = (data) => {
    setLoading(true)
    const platFormData = {
      product_libraries_id: data?.id,
      name: data.name,
      regular_price: data?.regular_price,
      discount_price: data?.discount_price || 0,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,
      product_link: data?.product_link,
      description: data?.description,
      main_image: data?.image,
      image_1: data?.image_1,
      image_2: data?.image_2,
      image_3: data?.image_3
    }
    if (!data?.image) {
      platFormData.main_image = selectedImages[0] ?? ''
    }
    if (!data?.image_1) {
      platFormData.image_1 = selectedImages[1] ?? ''
    }
    if (!data?.image_2) {
      platFormData.image_2 = selectedImages[2] ?? ''
    }
    if (!data?.image_3) {
      platFormData.image_3 = selectedImages[3] ?? ''
    }
    APICall('post', platFormData, `${EndPoints.editProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onComplete()
            emitter.emit(EventType.ProductLibraryDelete, {
              product_id: data.id,
              action: 'update'
            })
            toast.success(res?.data?.message)
            ResetValue()
            setIsEdited(false)
            setIsProductEdit(false)
            setLoading(false)
            setShowProduct(false)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (isProductEdit) {
      oneProductDetails(editData)
    }
  }, [isProductEdit, editData])

  const updateModalHeight = () => {
    if (componentRef.current) {
      setModalHeight(
        componentRef.current.offsetHeight -
          (modelheaderRef.current.offsetHeight +
            modelFooterRef.current.offsetHeight)
      )
    }
  }

  useEffect(() => {
    updateModalHeight()
    window.addEventListener('resize', updateModalHeight)
    return () => {
      window.removeEventListener('resize', updateModalHeight)
    }
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div className="add-item-model add-product-content">
        {loading && <Loader />}
        <div
          ref={componentRef}
          className="add-item-content add-item-content-store store_right_padding"
        >
          <div className="product-add-image">
            <div
              ref={modelheaderRef}
              className="product-label store_header_sticky"
            >
              <h3 className="edit-new-label">
                {isProductEdit ? English.G138 : English.G137}
              </h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={ProductAddCancel}
              >
                <span>
                  <img src={Images.CloseIco} alt="close icon" />
                </span>
              </button>
            </div>
            <div
              style={{ maxHeight: `${modalHeight}px` }}
              className="store_scroll_bar_add"
            >
              <div className="input-image-show align-items-end">
                <div className="input-img-label">
                  <div className="upload_crop">
                    <CommonImageUpload
                      handleImageChange={handleImageChange}
                      buttonText="Upload Image"
                      selectedImg=""
                      defaultImg={Images.upload}
                      className="upload_label"
                    />
                  </div>
                </div>
                {renderImg}
              </div>
              <div className="vertical-divider mt-4" />
              <form className="item-details-input pt-3">
                <div className="name-field">
                  <div>
                    <label>{English.G121}</label>
                    <CommonInput
                      name="name"
                      value={inputFields.name}
                      placeholder={English.G130}
                      onChange={handleChange}
                      style={{ textTransform: 'capitalize' }}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G122}</label>
                    <CommonInput
                      name="regular_price"
                      type="number"
                      value={inputFields.regular_price}
                      placeholder={English.G131}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <div>
                    <label>{English.G123}</label>
                    <CommonInput
                      name="discount_price"
                      type="number"
                      value={inputFields.discount_price || ''}
                      placeholder={English.G132}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                      className={`${error.discount_price ? 'mb-0' : 'mb-2'}`}
                    />
                    {error.discount_price && (
                      <span className="error-message">
                        {error.discount_price}
                      </span>
                    )}
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G124}</label>
                    <Dropdown
                      title={getCategoryName(inputFields.category_id)}
                      onChanged={(d) => {
                        SubCategory({ category_id: d.id })
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }}
                      textClass="selected-dropdown-text"
                      Data={category?.map((d) => ({
                        title: d.name,
                        id: d.id
                      }))}
                      name="category_id"
                      customClass={
                        inputFields.category_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                  <div>
                    <label>{English.G125}</label>
                    <Dropdown
                      title={getSubCategoryNameById(
                        inputFields.sub_category_id
                      )}
                      onChanged={(d) => setInputFields({
                          ...inputFields,
                          sub_category_id: d.id
                        })}
                      Data={
                        subCategory && subCategory?.length > 0
                          ? subCategory.map((d) => ({
                              title: d.sub_category_name,
                              id: d.sub_category_id
                            }))
                          : [{ title: 'No Data Found', id: null }]
                      }
                      textClass="selected-dropdown-text"
                      name="sub_category_id"
                      customClass={
                        inputFields.sub_category_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G126}</label>
                    <CommonInput
                      name="product_link"
                      value={inputFields.product_link}
                      placeholder={English.G133}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label>{English.G112}</label>
                  <CommonInput
                    name="description"
                    placeholder={English.G83}
                    onChange={handleChange}
                    value={inputFields.description}
                    className="description-input"
                    maxLength={150}
                    isTextArea
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            ref={modelFooterRef}
            className="button-row d-flex align-items-center justify-content-between store_sticky_btn pb-20"
          >
            <div>
              <CommonButton
                label={English.G78}
                type="button"
                onClick={PlatformPreview}
                isPrimary={false}
                context={English.G232}
                className="me-4"
              />
              <CommonButton
                label={English.G127}
                type="button"
                isPrimary={false}
                context={English.G232}
                className="common-button"
                disabled
              />
            </div>
            <CommonButton
              label={isProductEdit ? English.G128 : English.G129}
              type="submit"
              isPrimary
              disabled={!isFormValid}
              context={English.G232}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddEditProductLibaryModal
