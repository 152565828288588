import React, { useState } from 'react'
import './Store.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ReactDatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import OrderChart from './OrderChart'
import CustomerChart from './CustomerChart'
import ProductChart from './ProductChart'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Store = () => {
  const [startDate, setStartDate] = useState(new Date())

  const [startDateRange, setStartDateRange] = useState(new Date('2014/02/08'))
  const [endDateRange, setEndDateRange] = useState(new Date('2014/02/10'))
  const items = [
    { text: 'Overview' },
    { text: 'Orders' },
    { text: 'Products' },
    { text: 'Clients' },
    { text: 'Rating' }
  ]
  const orders = [
    {
      productId: '#1234',
      clientName: 'Kathryn Murphy',
      yearMonth: 'May 2021',
      clientImg: './images/profile.png',
      productName: 'Face Lotion',
      date: '12/12/2021',
      price: '$700',
      staus: 'Delivered'
    },
    {
      productId: '#1234',
      clientName: 'Kathryn Murphy',
      yearMonth: 'May 2021',
      clientImg: './images/profile.png',
      productName: 'Face Lotion',
      date: '12/12/2021',
      price: '$700',
      staus: 'Delivered'
    },
    {
      productId: '#1234',
      clientName: 'Kathryn Murphy',
      yearMonth: 'May 2021',
      clientImg: './images/profile.png',
      productName: 'Face Lotion',
      date: '12/12/2021',
      price: '$700',
      staus: 'Delivered'
    }
  ]
  const product = [
    {
      img: './images/products/8.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$25.00',
      id: 'overview'
    },
    {
      img: './images/products/14.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$25.00',
      id: 'overview'
    },
    {
      img: './images/products/15.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$25.00',
      id: 'overview'
    },
    {
      img: './images/products/15.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$25.00',
      id: 'overview'
    },
    {
      img: './images/products/10.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$25.00',
      id: 'trending'
    },
    {
      img: './images/products/16.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$25.00',
      id: 'trending'
    },
    {
      img: './images/products/17.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$25.00',
      id: 'trending'
    },
    {
      img: './images/products/11.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$25.00',
      id: 'trending'
    },
    {
      img: './images/products/18.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$25.00',
      id: 'popular'
    },
    {
      img: './images/products/19.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$25.00',
      id: 'popular'
    },
    {
      img: './images/products/20.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$25.00',
      id: 'popular'
    },
    {
      img: './images/products/11.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$25.00',
      id: 'popular'
    }
  ]
  const client = [
    {
      profile: './images/profile/1.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      review: 'Very nice products and fast delivery!',
      order: '5'
    },
    {
      profile: './images/profile/2.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      review: 'Thank you for a great service!',
      order: '3'
    },
    {
      profile: './images/profile/3.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      review: 'I needed a little help with my order. Service team helped me...',
      order: '1'
    },
    {
      profile: './images/profile/1.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      review: 'These products are amazing. Totally ordering here again!',
      order: '5'
    },
    {
      profile: './images/profile/2.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      review: 'The delivery time is outstanding!',
      order: '12'
    },
    {
      profile: './images/profile/3.png',
      name: 'Jane Cooper',
      designation: 'Ernahrungsberater',
      rating: '5',
      review: 'Good quality and convenient ordering process! ',
      order: '3'
    }
  ]
  const productRating = [
    {
      img: './images/products/13.jpg',
      rating: '4.5',
      totalRating: '1145',
      percentage: ['1', '2', '5', '7', '88']
    },
    {
      img: './images/products/13.jpg',
      rating: '4.5',
      totalRating: '1145',
      percentage: ['1', '2', '5', '7', '88']
    },
    {
      img: './images/products/13.jpg',
      rating: '4.5',
      totalRating: '1145',
      percentage: ['1', '2', '5', '7', '88']
    },
    {
      img: './images/products/13.jpg',
      rating: '4.5',
      totalRating: '1145',
      percentage: ['1', '2', '5', '7', '88']
    }
  ]
  return (
    <div className="content-manager">
      <div className="content-manager-Statistics">
        <div className="content-manager-Statistics-chart">
          <div className="statistic-chart-container position-relative">
            <h4>Sales Summary</h4>
            <div>
              <Tabs>
                <div>
                  <TabList>
                    <Tab>Orders</Tab>
                    <Tab>Products</Tab>
                    <Tab>Customers</Tab>
                  </TabList>
                  <div className="drop-and-date top-40">
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </div>
                </div>

                <TabPanel>
                  <OrderChart />
                </TabPanel>
                <TabPanel>
                  <ProductChart />
                </TabPanel>
                <TabPanel>
                  <CustomerChart />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <div className="content-manager-Statistics-chart common-card-body mt-40">
        <h3>Categories</h3>
        <div className="Statistics-tab">
          {items.map((item) => (
            <button type="button" key={item.text}>{item.text}</button>
          ))}
        </div>
      </div>

      <div className="common-card-body mt-20 recent-video-component">
        <div className="d-flex align-items-center justify-content-between mb-40">
          <h3 className="m-0">Orders </h3>
          <div className="date-range">
            <ReactDatePicker
              selected={startDateRange}
              onChange={(date) => setStartDateRange(date)}
              selectsStart
              startDate={startDateRange}
              endDate={endDateRange}
              dateFormat="dd/MM/yyyy"
            />
            <span>To</span>
            <ReactDatePicker
              selected={endDateRange}
              onChange={(date) => setEndDateRange(date)}
              selectsEnd
              startDate={startDateRange}
              endDate={endDateRange}
              minDate={startDateRange}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="order-table">
          <table className="w-100">
            <tr>
              <th>Product ID</th>
              <th>Client Name</th>
              <th>Product Name</th>
              <th>Date</th>
              <th>Price</th>
              <th>Status</th>
              <th />
            </tr>

            {orders
              .filter((item, index) => index < 3)
              .map((item) => (
                <tr key={item.productId} className="data-row">
                  <td>{item.productId}</td>
                  <td className="d-flex col-gap-20">
                    <div className="client-img">
                      <img alt="" src={item.clientImg} />
                    </div>
                    <div className="client-detail">
                      <p className="m-0">{item.clientName}</p>
                      <span>{item.yearMonth}</span>
                    </div>
                  </td>
                  <td>{item.productName}</td>
                  <td>{item.date}</td>
                  <td>{item.price}</td>
                  <td>
                    <span id={item.staus}>{item.staus}</span>
                  </td>
                  <td>
                    <Link to="/orders" state={orders}>
                      <img alt="" src={Images.IconsMore} />
                    </Link>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>

      <div className="services-container menu-wrapper common-card-body recent-video-component mt-40">
        <h3 className="d-flex align-items-center justify-content-between">
          Product Overview
          {' '}
          <Link to="/products" state={product}>
            {English.G248}
          </Link>
        </h3>
        <div className="store-container mt-30">
          {product
            .filter((item) => item.id === 'overview')
            .filter((item, index) => index < 4)
            .map((item, index) => (
              <div className="product-grid-item" key={index}>
                <div
                  className="product-item-cover"
                  style={{
                    background: `url(${item.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="like">
                    <img alt="" src={Images.UnLikes} />
                  </div>
                  <div className="rating">
                    <img alt="" src={Images.RatingStar} />
                    <span>{item.rating}</span>
                  </div>
                </div>
                <div className="product-item-details d-flex  justify-content-between">
                  <span>{item.name}</span>
                  <div>
                    <h4>{item.price}</h4>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <h3 className="d-flex align-items-center justify-content-between mt-40">
          Trending Accessories
          {' '}
          <Link to="/products" state={product}>
            {English.G248}
          </Link>
        </h3>
        <div className="store-container mt-30">
          {product
            .filter((item) => item.id === 'trending')
            .filter((item, index) => index < 4)
            .map((item, index) => (
              <div className="product-grid-item" key={index}>
                <div
                  className="product-item-cover"
                  style={{
                    background: `url(${item.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="like">
                    <img alt="" src={Images.UnLikes} />
                  </div>
                  <div className="rating">
                    <img alt="" src={Images.RatingStar} />
                    <span>{item.rating}</span>
                  </div>
                </div>
                <div className="product-item-details d-flex  justify-content-between">
                  <span>{item.name}</span>
                  <div>
                    <h4>{item.price}</h4>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="services-container menu-wrapper common-card-body recent-video-component mt-40">
        <h3 className="d-flex align-items-center justify-content-between">
          Related Products
          {' '}
          <Link to="/products" state={product}>
            {English.G248}
          </Link>
        </h3>
        <div className="store-container mt-30">
          {product
            .filter((item) => item.id === 'popular')
            .filter((item, index) => index < 4)
            .map((item, index) => (
              <div className="product-grid-item" key={index}>
                <div
                  className="product-item-cover"
                  style={{
                    background: `url(${item.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="like">
                    <img alt="" src={Images.UnLikes} />
                  </div>
                  <div className="rating">
                    <img alt="" src={Images.RatingStar} />
                    <span>{item.rating}</span>
                  </div>
                </div>
                <div className="product-item-details d-flex  justify-content-between">
                  <span>{item.name}</span>
                  <div>
                    <h4>{item.price}</h4>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="services-container menu-wrapper common-card-body recent-video-component mt-40">
        <h3 className="d-flex align-items-center justify-content-between">
          {English.G429}
          {' '}
          <Link to="/clients" state={client}>
            {English.G248}
          </Link>
        </h3>
        <div className="mt-30 client-container">
          {client
            .filter((item, index) => index < 4)
            .map((item) => (
              <div className="client-detail-card" key={item.name}>
                <img alt="" className="profile-img" src={item.profile} />
                <h6>
                  {item.name}
                  <img alt="" src={Images.GreenTick} />
                </h6>
                <span className="designation">{item.designation}</span>
                <div className="rating-star mb-30 mt-1">
                  <img alt="" src={Images.RatingStars} />
                </div>
                <div className="d-flex col-gap-10 row-gap-2">
                  <button type="button" className="btn-gredient">
                    <img alt="" src={Images.ViewProfile} />
                    {' '}
                    <span>
                      {English.G232}
                    </span>
                  </button>
                  <button type="button" className="btn-light-blue">
                    <img alt="" src={Images.ViewProfile} />
                    {' '}
                    <span>Contact</span>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="services-container menu-wrapper common-card-body recent-video-component mt-40">
        <h3 className="d-flex align-items-center justify-content-between">
          Product Rating
          {' '}
          <Link to="/rating" state={productRating}>
            {English.G248}
          </Link>
        </h3>
        <div className="mt-30 product-rating-container">
          {productRating
            .filter((item, index) => index < 2)
            .map((item, index) => (
              <div className="product-detail-card" key={index}>
                <div className="product-img">
                  <img alt="" src={item.img} />
                </div>
                <div>
                  <h3>Product Quality</h3>
                  <h2>{item.rating}</h2>
                  <div className="rating-star mb-30 mt-1 justify-content-start">
                    <img alt="" src={Images.RatingStars} />
                  </div>
                  <span>
                    {item.totalRating}
                    {' '}
                    ratings
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
export default Store
