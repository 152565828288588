/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import './header.css'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { s3ImageProfile } from '../../services/Service'
import CommonInput from '../InputComponet/CommonInput'
import Images from '../../helpers/Images'
import { English } from '../../helpers'

const Header = () => {
  const navigate = useNavigate()

  const UserData = useSelector((state) => state.user?.userData)
  const user_type = useSelector((state) => state.user?.user_type)
  const [isLoading, setIsLoading] = useState(false)

  const location = useLocation()

  const isProfileCreationPage =
    location.pathname === '/creator-create-profile' ||
    location.pathname === '/create-profile'

  const handleButtonClick = () => {
    navigate('/dashboard/smart-hub')
  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const Header = () => {
    return (
      <>
        <div className="serch-input position-relative ml-5">
          <CommonInput
            disabled
            placeholder="Search..."
            className="max-[1550px]:!w-[182px]"
          />
          <div className="serch-icon">
            <img src={Images.SearchIco} alt="" />
          </div>
        </div>
        <div className="flex gap-[44px] items-center max-[1600px]:gap-[20px] max-[1440px]:hidden">
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.File_Icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A170}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.File_Icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A171}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled={isProfileCreationPage}
            onClick={handleButtonClick}
          >
            <div className="flex gap-2">
              <img src={Images.smart_hub_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A164}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.ServiceCenter} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A2}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.media_business_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A170}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.Recruiting_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A172}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.Crm_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A173}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.profileBuilder_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A2}
              </p>
            </div>
          </button>
        </div>
        <div className="header-right-bar d-flex align-items-center mr-5">
          <div className="relative min-[1440px]:hidden">
            <div
              className="dropdown-toggles relative bg-[#fafafa] rounded-[35px] h-[50px] cursor-pointer"
              onClick={() => setDropdownVisible((prev) => !prev)}
            >
              Menu
            </div>
            <div
              className={`flex flex-col gap-[20px] items-center bg-[#F9F9F9] shadow-md rounded-[16px] absolute w-full top-[calc(100%+20px)] transition-all ${dropdownVisible ? 'h-auto opacity-100' : 'h-0 pointer-events-none overflow-hidden opacity-0'}`}
            >
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.File_Icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A170}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.File_Icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A171}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled={isProfileCreationPage}
                onClick={handleButtonClick}
              >
                <div className="flex gap-2">
                  <img src={Images.smart_hub_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A164}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.ServiceCenter} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A2}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.media_business_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A170}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.Recruiting_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A172}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.Crm_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A173}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.profileBuilder_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A2}
                  </p>
                </div>
              </button>
            </div>
          </div>
          <button type="button" className="right-button" disabled>
            <img src={Images.Message} alt="" />
          </button>
          <button type="button" className="right-button" disabled>
            <img src={Images.Notification} alt="" />
          </button>
          <div className="w-[1px] h-9 bg-[#f1f1f1] rounded-3xl" />
          <div className="right-button w-auto">
            <button
              className="dropdown-toggles"
              id="navbarDropdown"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled
            >
              <img
                className="object-cover object-center"
                src={s3ImageProfile + UserData?.profile_image}
                alt=""
              />
              <div className="flex-col flex items-start px-2 gap-[2px]">
                <span className="text-[12px] bg-primaryGradient bg-clip-text text-transparent leading-none font-medium">
                  {UserData?.full_name}
                </span>
                <span className="text-[10px] text-secondary leading-none">
                  {UserData?.email}
                </span>
              </div>
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="navbar-custom d-flex items-center justify-between">
      {isLoading ? (
        <>
          <div className="serch-input position-relative">
            <Skeleton className="rounded-3" width={329} height={39} />
          </div>
          <div className="header-right-bar d-flex align-items-center">
            <button type="button" className="right-button">
              <Skeleton width={25} height={25} />
            </button>
            <button type="button" className="right-button">
              <Skeleton width={25} height={25} />
            </button>
            <div className="profile-dropdown d-flex gap-2">
              <Skeleton className="rounded-3" width={30} height={30} />
              <Skeleton className="mt-2" width={15} height={15} />
            </div>
          </div>
        </>
      ) : (
        <Header />
      )}
    </div>
  )
}
export default Header
