import React from 'react'
import AddHotel from '../AddHotel/AddHotel'
import AddRestaurant from '../AddRestaurant/AddRestaurant'
import AddEvent from '../AddEvent/AddEvent'
// import StoreBannerComponent from '../UploadComponent/StoreBannerComponent'

const LocalHomePage = () => {
    // if (pageType === 'store') {
    //   return (
    //     <div>
    //       <StoreBannerComponent />
    //     </div>
    //   )
    // }
    return (
      <>
        <div>
          <AddHotel />
        </div>
        <div className="mt-30">
          <AddRestaurant />
        </div>
        <div className="mt-30">
          <AddEvent />
        </div>
      </>
    )
}
export default LocalHomePage
