/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import { preventMultipleAtSignKeydown, preventMultipleAtSignPaste } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const ForgetPasswordPage = () => {
  const navigate = useNavigate()

  const [inputFields, setInputFields] = useState({
    email: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.email.length === 0) {
      errors.email = English.G28
    }
    return errors
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    if (emailRegex.test(e.target.value)) {
      setIsButtonEnabled(true)
    } else {
      setIsButtonEnabled(false)
    }
  }
  const handleLogin = () => {
   navigate('/login')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, EndPoints.forgotPassword, 'auth')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        navigate('/reset-password', { state: { email: inputFields.email } })
        setLoading(false)
        toast.success(res?.data?.message)
      } else {
        setLoading(false)
        toast.error(res.data?.message)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setLoading(false)
    })
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  return (
    <div className=" auth-register auth-login">
      <div className="bg-overlay row overlay-height">
        <div className="col-md-6 py-[20px]">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto no-scrollbar">
            <h2 className="bg-[#151515] text-center login_sign_text pb-3">
              {English.A29}
              <br />
              {English.A30}
            </h2>
            <h6 className="bg-[#303030] text-center mb-12">{English.A31}</h6>
            <div className="w-100">
              <form onSubmit={handleSubmit} className="mb-[25px]">
                <p className="mb-0">
                  <div className="email-input input-container mb-4">
                    <img
                      src={Images.input_logo}
                      alt="Logo"
                      className="input-logo !w-[30px] !h-[33px]"
                    />
                    <CommonInput
                      type="email"
                      name="email"
                      placeholder={English.A27}
                      required
                      onChange={handleChange}
                      onKeyDown={(e) => preventMultipleAtSignKeydown(e, inputFields.email)}
                      onPaste={preventMultipleAtSignPaste}
                    />
                  </div>
                  {errors.email ? (
                    <p className="error-text">{errors.email}</p>
                  ) : null}
                  <CommonButton
                    label={English.A32}
                    type="submit"
                    loading={loading}
                    className="primary-button m-auto mt-1 login_button"
                    disabled={!isButtonEnabled || loading}
                    context="primary"
                    isPrimary
                  />
                </p>
              </form>
            </div>
            <footer className="d-flex flex-1 flex-col gap-3 items-center">
              <div className="d-flex align-items-center col-gap-10 cursor-pointer" onClick={() => handleLogin()}>
                <img
                  src={Images.Black_back}
                  alt="back"
                />
                <p className="text-start mb-0 text-gradient">
                  {English.A28}
                </p>
              </div>
              <div className="flex flex-1" />
              <div className="text-[10px] text-placeholder text-center items-center justify-center !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Dolor erat enim mattis nunc, eget volutpat. Aenean sed urna,
                facilisi vitae, volutpat faucibus.enim mattis nunc,
                eget volutpat. Aenean sed urna, facilisi vitae, volutpat faucibus
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForgetPasswordPage
