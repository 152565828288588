/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './AddDiscussions.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import {
  s3ImageProfile
} from '../../services/Service'
import Loader from '../Loader/Loader'
import PlatformTag from '../ContentManager/PlatformTag'
import {
  capitalizeFirstLetter,
  debounce,
  lockScroll,
  timeDifference,
  unlockScroll
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommentPagination from '../Pagination/CommentPagination'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import TagView from '../CommanComponent/TagView'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const DisscussionComponent = ({ showCount }) => {
  const userData = useSelector((state) => state.user?.userData)
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)
  const navigate = useNavigate()
  const { id } = useParams()
  const componentRef = useRef()
  const [ShowDiscussionModel, setShowDiscussionModel] = useState(false)
  const [ShowPreviewDiscussionModel, setShowPreviewDiscussionModel] =
    useState(false)
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    description: '',
    tags: []
  })
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delDiscussionDataId, setDelDiscussionId] = useState()
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [tags, setTags] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [discussions, setDiscussions] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  // const [particularDiscussionData, setParticularDiscussionData] = useState([])
  const [expandedIndex, setExpandedIndex] = useState(null)

  const HandleDiscussion = () => {
    setShowDiscussionModel(true)
    // navigate('/add-component', { state: { defaultIndex: 5 } })
  }

  const HandleCancel = () => {
    clearValue()
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
    setIsEditing(false)
  }

  const handleCancels = () => {
    setShowConfirmModel(false)
  }

  const HandleDiscussionPereview = () => {
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(true)
  }

  const HandlePublish = () => {
    if (isEditing) {
      editDiscussionDataApi()
    } else {
      addDiscussionDataSubmit()
    }
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
  }

  const ShowConfirm = (discussion_id) => {
    setDelDiscussionId(discussion_id)
    setShowConfirmModel(true)
  }

  const updateInputFieldsTags = (tagsArray) => {
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      tags: tagsArray
    }))
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      topic: '',
      description: '',
      app_id: [],
      tags: []
    })
    setTags([])
    setPlatformTags([])
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const getDiscussion = (search, currentPage) => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getDiscussion}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscussions(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
          // const discussionId = discussions[0]?.discussion_id
          // console.log(discussionId)

          // const discussionIds = decryptedData?.data
          //   ?.map((discussion) => discussion?.discussion_id)
          //   ?.filter(Boolean)

          // if (discussionIds?.length > 0) {
          //   getParticularDiscussion(discussionIds)
          // }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  // const getParticularDiscussion = (id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     discussion_id: id
  //   }
  //   particularDiscussion(platFormData).then((res) => {
  //     if (res?.data?.success) {
  //       setParticularDiscussionData(dataDecrypt(res.data.data))
  //     }
  //   })
  // }

  const updateDiscussionData = (id) => {
    const platFormData = {
      app_id: app_id.id,
      discussion_id: id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.particularDiscussion}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const d = res?.decryptedData
          const oldData = [...discussions]
          const index = oldData.findIndex((item) => item.discussion_id === id)
          oldData[index] = d.find((item) => item.discussion_id === id)
          setDiscussions(oldData)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getDiscussion(inputVal, currentPage), 500),
    []
  )

  const addDiscussionDataSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, `${EndPoints.addDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowPreviewDiscussionModel(false)
          setTimeout(() => {
            getDiscussion(null, currentPage)
          }, 3000)
          setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const deleteDiscussion = () => {
    const data = {
      discussion_id: delDiscussionDataId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getDiscussion(null, currentPage)
          }, 3000)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const editDiscussionDataApi = () => {
    const data = {
      topic: inputFields.topic,
      description: inputFields.description,
      app_id: Platformtags.map((d) => d.id),
      discussion_id: inputFields.discussion_id,
      tags: inputFields.tags
    }
    APICall('post', data, `${EndPoints.editDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            getDiscussion(null, currentPage)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionLikes = (discussion_id) => {
    const platFormData = {
      app_id: app_id.id,
      discussion_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscussions(
            discussions?.map((item) => (item.discussion_id === discussion_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  // Discussion pagination

  const totalVideos = discussions?.length

  const handlePageChange = (pageNumber) => {
    getDiscussion(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  // discussion comment
  const fetchDiscussionComments = (discussion_id, delay = 3000) => {
    setTimeout(() => {
      discussionReply(discussion_id)
    }, delay)
  }

  const discussionReply = (discussion_id) => {
    const platFormData = {
      app_id: app_id.id,
      discussion_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.reply,
            discussion_id: d.discussion_id,
            is_like: d.is_like,
            reply_id: d.reply_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.reply,
              reply_id: subData.reply_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const discussionAddComment = (reply) => {
    const platFormData = {
      discussion_id: selectedId,
      reply,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionAddReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setTimeout(() => {
            updateDiscussionData(selectedId)
          }, 2500)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionAddReply = (reply_id, replyText) => {
    const platFormData = {
      discussion_id: selectedId,
      reply_id,
      reply: replyText,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionAddReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionEditComment = (reply_id, reply) => {
    const platFormData = {
      reply_id,
      reply
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionEditReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionEditReply = (reply_id, replyId) => {
    const platFormData = {
      reply_id,
      reply: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionEditReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteDiscussionComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      reply_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.discussionDeleteReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            updateDiscussionData(selectedId)
          }, 2500)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const deleteDiscussionReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      reply_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.discussionDeleteReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const discussionCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReplyLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.comment_id === reply_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReplyLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        discussion_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name: profileData.user_name,
          profilePic: s3ImageProfile + profileData.profile_icon
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    discussionAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    discussionCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    discussionReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImageProfile + profileData.profile_icon
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    discussionAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deleteDiscussionReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const onClickComment = (discussion_id) => {
    setSelectedId(discussion_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      discussionReply(discussion_id)
      setCommentSection(true)
    }
  }

  const handleCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    discussionEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    discussionEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const onClickEditDiscussion = (videoData) => {
    setShowDiscussionModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      discussion_id: videoData.discussion_id,
      tags: videoData.tags
    })
    setInitialValues({
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      discussion_id: videoData.discussion_id,
      tags: videoData.tags
    })
    setPlatformTags(videoData.app_data)
    setTags(videoData.tags)
  }

  const validateForm = () => {
    const { topic, description, tags } = inputFields
    if (topic && description && Array.isArray(tags) && tags?.length > 0) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  const handlePageChanges = (pageNumber) => {
    setCurrentPageComment(pageNumber)
  }

  useEffect(() => {
    getDiscussion(null, currentPage)
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  useEffect(() => {
    if (
      commentSection ||
      ShowDiscussionModel ||
      ShowPreviewDiscussionModel ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    commentSection,
    ShowDiscussionModel,
    ShowPreviewDiscussionModel,
    ShowConfirmModel
  ])

  return (
    <div className="">
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link">
            {/* <div className="video_button"> */}
            <h2>Discussion</h2>
            <div className="add-video-rows">
              <CommonButton
                label="Add New Discussion"
                imgSrc={Images.AddIco}
                onClick={HandleDiscussion}
                isPrimary
                className="add_new_content"
              />
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
      <div
        className={
          showCount !== 2 &&
          'recent-video-component recent-shots-component common-card-body add-shots-component'
        }
      >
        {showCount !== 2 && (
          <div className="discussions-wrapper-header common-contnt-wrapper mb-40">
            {showCount !== 2 && <h2>{English.G207}</h2>}
            {showCount !== 2 && (
              <div className="items-filter">
                <div className="position-relative input-position">
                  <CommonInput
                    placeholder="Search Discussions...."
                    onChange={(e) => {
                      handleSearch(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {!showCount && (
          <div className="add-video-rows main_btn mt-3">
            <CommonButton
              label="Add New Discussion"
              imgSrc={Images.AddIco}
              onClick={HandleDiscussion}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
        {discussions?.length === 0 && !isLoading ? (
          <div className="add-discussions-container">
            <div className="add-discussions-item">
              {/* <h5 className="giveaways_text">No Discussion Available</h5> */}
              <div className="disscussion-item-profile-detail">
                <div className="disscussion-profile-img add-value" />
                <div className="disscussion-profile-txt">
                  <h2 className="add-value">{English.G162}</h2>
                  <h3 className="add-value">-</h3>
                  <p className="add-value">{English.G112}</p>
                </div>
              </div>
              <div className="disscussion-item-other">
                <div className="disscussion-group-member add-value" />
                <div className="disscussion-chat add-value" />
                <div className="position-relative">
                  <button
                    type="button"
                    className="add-item-btn"
                    onClick={HandleDiscussion}
                  >
                    <img src={Images.Plus} alt="add discussion" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="discussions-item position-relative">
            <div className="discussion-profile">
              <Skeleton className="discssion-profile-image border-0" />
              <div className="discssion-detail">
                <h5>
                  <Skeleton max-width={700} height={20} />
                </h5>
                <span className="mb-3">
                  <Skeleton max-width={200} height={10} />
                </span>
                <p>
                  <Skeleton max-width={700} height={30} />
                </p>
              </div>
            </div>
            <div className="discussion-group">
              <Skeleton circle width={30} height={30} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>
        ) : (
          <div className="content-manager-discussions-wrapper mt-20">
            {discussions?.map(
              (item, index) => index < (showCount || totalVideos) && (
              <div
                className="discussions-item position-relative"
                key={item.Title}
              >
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditDiscussion(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                          setUpdatingIndex(index)
                          ShowConfirm(item.discussion_id)
                        }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deleteDiscussion}
                  onCancel={handleCancels}
                  isLoading={loading}
                />
                {loading && updatingIndex === index && <Loader />}
                <div className="discussion-profile">
                  <div
                    className="discssion-profile-image"
                    style={{
                          background: `url(${s3ImageProfile + userData?.profile_image})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                  />
                  <div className="discssion-detail">
                    <h5>{item.topic}</h5>
                    <span>
                      <b>{userData?.user_name}</b>
                      {' '}
                      {English.G209}
                      {' '}
                      {timeDifference(item.created_at)}
                      {' '}
                      {English.G210}
                    </span>
                    <p className="mt-2">
                      {expandedIndex === index
                            ? item?.description
                            : `${item?.description?.slice(0, 130)}`}
                      {item?.description?.length > 130 && (
                      <span
                        className="see-more"
                        onClick={(e) => {
                                e.stopPropagation()
                                setExpandedIndex(
                                  expandedIndex === index ? null : index
                                )
                              }}
                      >
                        {expandedIndex === index
                                ? English.G145
                                : English.G146}
                      </span>
                          )}
                    </p>
                  </div>
                </div>
                <div className="comment_people">
                  <div className="discussion-group d-flex gap-3">
                    {/* {item?.groupImg.map((img, index) => (
                  <img key={index} src={img} />
                ))} */}
                    <img
                      src={Images.GroupDiscussion}
                      alt="discussion icon"
                    />
                    <div className="poll_like_bg">
                      <button
                        type="button"
                        className="video_like"
                        onClick={() => discussionLikes(item.discussion_id)}
                      >
                        {item.is_like ? (
                          <img
                            src={Images.Likes}
                            alt="Unlike"
                            className="like_giveaway"
                          />
                            ) : (
                              <img
                                src={Images.UnLikes}
                                alt="Like"
                                className="like_giveaway"
                              />
                            )}
                      </button>
                    </div>
                  </div>
                  <div
                    className="discussion-comment"
                    onClick={() => onClickComment(item.discussion_id)}
                  >
                    <img src={Images.Comments} alt="comment icon" />
                    {item.total_replies}
                  </div>
                  <div className="discussion-button">
                    <button type="button">
                      <img src={Images.IconsMore} alt="see more icon" />
                    </button>
                  </div>
                </div>
              </div>
                )
            )}

            {commentSection && (
              <div className="add-item-modal mh-100">
                <div className="add-item-contents comment-container">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mb-0">{English.G211}</h3>
                    <button
                      type="button"
                      className="secondary-btn-modal"
                      onClick={handleCancel}
                    >
                      <img
                        className="close-btns"
                        src={Images.CloseIco}
                        alt="clock icon"
                      />
                    </button>
                  </div>
                  <div className="comment-section">
                    {isLoadingComments ? (
                      <div>
                        <div className="comments-container">
                          <ul className="comments-list">
                            <li className="comment-item">
                              <button type="button" className="comment-delete">
                                <Skeleton
                                  className="rounded-3"
                                  width={20}
                                  height={20}
                                />
                              </button>
                              <div className="comment-header mb-0 gap-2">
                                <Skeleton circle width={45} height={45} />
                                <span className="user-name">
                                  <Skeleton width={150} height={20} />
                                </span>
                              </div>
                              <div className="comment-btns">
                                <button type="button">
                                  <Skeleton width={20} height={20} />
                                </button>
                                <Skeleton width={20} height={20} />
                                <button type="button">
                                  <Skeleton width={45} height={20} />
                                </button>
                                <button type="button">
                                  <Skeleton width={45} height={20} />
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="add-comment">
                          <input
                            type="text"
                            placeholder={English.G151}
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            name="reply"
                          />
                          <button
                            type="button"
                            onClick={() => handleAddComment(newComment)}
                          >
                            {English.G152}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="comments-container">
                          {commentsList?.length === 0 ? (
                            <div className="no-comments-message">
                              {English.G148}
                            </div>
                          ) : (
                            <ul className="comments-list">
                              {commentsList?.map((comment, index) => (
                                <li key={comment.id} className="comment-item">
                                  {isDeleting && (
                                    <div className="loader-overlay">
                                      {commentIndex === index && <Loader />}
                                    </div>
                                  )}
                                  {comment.user.name ===
                                    profileData.user_name && (
                                    <button
                                      type="button"
                                      className="comment-delete"
                                      onClick={() => {
                                        setCommentIndex(index)
                                        deleteDiscussionComment(
                                          comment.reply_id
                                        )
                                      }}
                                    >
                                      <img
                                        src={Images.Delete}
                                        alt="delete icon"
                                      />
                                    </button>
                                  )}
                                  <div className="comment-header mb-0">
                                    <img
                                      src={comment.user.profilePic}
                                      alt={`${comment.user.name}'s profile`}
                                      className="profile-pic"
                                    />
                                    <span className="user-name">
                                      {comment.user.name}
                                    </span>
                                  </div>
                                  {editCommentId === comment.id ? (
                                    <div className="edit-reply">
                                      <input
                                        type="text"
                                        value={editedCommentText}
                                        onChange={(e) => setEditedCommentText(e.target.value)}
                                      />
                                      <button
                                        type="button"
                                        onClick={() => handleSaveComment(
                                            comment.reply_id,
                                            editedCommentText
                                          )}
                                      >
                                        {English.G117}
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="comment-text">
                                      {comment.text}
                                    </div>
                                  )}
                                  <div className="comment-btns">
                                    <button
                                      type="button"
                                      onClick={() => handleLikeComment(comment.reply_id)}
                                    >
                                      {comment.is_like ? (
                                        <img src={Images.Likes} alt="Unlike" />
                                      ) : (
                                        <img src={Images.UnLikes} alt="Like" />
                                      )}
                                      {' '}
                                      {comment.total_like}
                                    </button>
                                    {comment.user.name ===
                                      profileData.user_name && (
                                      <button
                                        type="button"
                                        onClick={() => handleEditComment(
                                            comment.id,
                                            comment.text
                                          )}
                                      >
                                        {English.G81}
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      onClick={() => toggleReplies(comment.id)}
                                    >
                                      {/* {showReplies[comment.id]
                                        ? 'Hide'
                                        : 'Show'}
                                      {' '}
                                      Replies */}
                                      {showReplies[comment.id]
                                        ? 'Hide Replies'
                                        : `Show Replies (${comment?.replies?.length})`}
                                    </button>
                                  </div>
                                  {showReplies[comment.id] && (
                                    <ul className="replies-list">
                                      {comment.replies.map((reply) => (
                                        <li
                                          key={reply.id}
                                          className="reply-item"
                                        >
                                          <div className="reply-header mb-0">
                                            <img
                                              src={reply.user.profilePic}
                                              alt={`${reply.user.name}'s profile`}
                                              className="profile-pic"
                                            />
                                            <span className="user-name">
                                              {reply.user.name}
                                            </span>
                                            {reply.user.name ===
                                              profileData.user_name && (
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setCommentIndex(index)
                                                  handleDeleteReply(
                                                    comment.reply_id,
                                                    reply.reply_id
                                                  )
                                                }}
                                                className="comment-delete"
                                              >
                                                <img
                                                  src={Images.Delete}
                                                  alt="delete icon"
                                                />
                                              </button>
                                            )}
                                          </div>
                                          {editReplyId === reply.id ? (
                                            <div className="edit-reply">
                                              <input
                                                type="text"
                                                value={editedReplyText}
                                                onChange={(e) => setEditedReplyText(
                                                    e.target.value
                                                  )}
                                              />
                                              <button
                                                type="button"
                                                onClick={() => handleSaveReply(
                                                    reply.reply_id,
                                                    editedReplyText
                                                  )}
                                              >
                                                {English.G117}
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="reply-text">
                                              {reply.text}
                                            </div>
                                          )}
                                          <div className="comment-btns">
                                            <button
                                              type="button"
                                              onClick={() => handleLikeReply(reply.reply_id)}
                                            >
                                              {reply.is_like ? (
                                                <img
                                                  src={Images.Likes}
                                                  alt="Unlike"
                                                />
                                              ) : (
                                                <img
                                                  src={Images.UnLikes}
                                                  alt="Like"
                                                />
                                              )}
                                              {' '}
                                              {reply.total_like}
                                            </button>
                                            {reply.user.name ===
                                              profileData.user_name && (
                                              <button
                                                type="button"
                                                onClick={() => handleEditReply(
                                                    reply.id,
                                                    reply.text
                                                  )}
                                              >
                                                {English.G81}
                                              </button>
                                            )}
                                          </div>
                                        </li>
                                      ))}
                                      <li className="add-reply">
                                        <input
                                          type="text"
                                          placeholder={English.G149}
                                          value={Replied}
                                          onChange={(e) => setShowReplied(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleAddReply(
                                              comment.reply_id,
                                              Replied
                                            )}
                                        >
                                          {English.G150}
                                        </button>
                                      </li>
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        {totalCommentPages > 1 && (
                          <CommentPagination
                            currentPage={currentPageComment}
                            totalPages={totalCommentPages}
                            onPageChange={handlePageChanges}
                          />
                        )}
                        <div className="add-comment">
                          <input
                            type="text"
                            placeholder={English.G151}
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            name="reply"
                          />
                          <button
                            type="button"
                            className="comment_add_btn"
                            onClick={() => handleAddComment(newComment)}
                          >
                            {English.G152}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!showCount && totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </div>

      {ShowDiscussionModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>{English.G212}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img alt="" className="close-btns" src={Images.CloseIco} />
                </span>
              </button>
            </div>
            <div className="scroll-bars pb-0">
              <div className="item-details-input pt-0">
                <div>
                  <label>{English.G201}</label>
                  <CommonInput
                    placeholder={English.G83}
                    name="topic"
                    onChange={handleChange}
                    maxLength={70}
                    value={capitalizeFirstLetter(inputFields.topic)}
                    disabled={isEditing}
                  />
                </div>

                <div>
                  <label>{English.G112}</label>
                  <CommonInput
                    placeholder={English.G83}
                    name="description"
                    onChange={handleChange}
                    value={capitalizeFirstLetter(inputFields.description)}
                    maxLength={150}
                    className="disscussion_description"
                    isTextArea
                  />
                </div>
                <TagView
                  setTags={setTags}
                  tags={tags}
                  updateInputFieldsTags={updateInputFieldsTags}
                  key="tags"
                />
              </div>
              <div className="item-details-input mt-3">
                <div className="button-row">
                  <CommonButton
                    label={English.G78}
                    onClick={HandleCancel}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G127}
                    type="button"
                    isPrimary
                    disabled={!isFormComplete}
                    onClick={HandleDiscussionPereview}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowPreviewDiscussionModel && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="item-details-input pt-0">
              <div className="d-flex justify-content-between header">
                <h3>{English.G212}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleCancel}
                >
                  <span>
                    <img alt="" className="close-btns" src={Images.CloseIco} />
                  </span>
                </button>
              </div>
              <div className="scroll-bars pb-5">
                <div className="discussion-preview">
                  <div className="discussion-img">
                    <img alt="" src={s3ImageProfile + userData.profile_image} />
                  </div>
                  <div className="discussion-preview-detail">
                    <h6>{inputFields.topic}</h6>
                    <p>{inputFields.description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-bars pb-0">
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />
              <div className="item-details-input">
                <div className="button-row">
                  <CommonButton
                    label={English.G116}
                    type="button"
                    onClick={HandleCancel}
                    isPrimary={false}
                    disabled={loading}
                    context={English.G232}
                  />
                  <CommonButton
                    label={isAppsSelected ? English.G128 : English.G89}
                    loading={loading}
                    type="button"
                    onClick={HandlePublish}
                    className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                    isPrimary
                    disabled={loading}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {discussions?.length >= 2 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/community-builder/${id}`, { state: { defaultIndex: 4 } })}
        >
          {English.G144}
        </div>
      )}
    </div>
  )
}

export default DisscussionComponent
