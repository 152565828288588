import React from 'react'
import './Ads.css'
import { Link } from 'react-router-dom'
import ProgressBar from '@ramonak/react-progress-bar'
import Dropdown from '../Dropdown/Dropdown'
import Images from '../../helpers/Images'
import English from '../../helpers/English'

const AdvertisementOption = () => {
  const items = [
    {
      id: '#1234',
      ads: 'Find Way to Burn Fates',
      videoAds: './images/podcast-video-cover.jpg',
      status: 'Published',
      reach: '24k',
      engagement: '166',
      dateCreated: '12/12/2021',
      link: ''
    },
    {
      id: '#1234',
      ads: 'Find Way to Burn Fates',
      videoAds: './images/podcast-video-cover.jpg',
      status: 'Published',
      reach: '24k',
      engagement: '166',
      dateCreated: '12/12/2021',
      link: ''
    },
    {
      id: '#1234',
      ads: 'Find Way to Burn Fates',
      videoAds: './images/podcast-video-cover.jpg',
      status: 'Published',
      reach: '24k',
      engagement: '166',
      dateCreated: '12/12/2021',
      link: ''
    },
    {
      id: '#1234',
      ads: 'Find Way to Burn Fates',
      videoAds: './images/podcast-video-cover.jpg',
      status: 'Published',
      reach: '24k',
      engagement: '166',
      dateCreated: '12/12/2021',
      link: ''
    }
  ]
  return (
    <div className="advertisement-option recent-video-component services-container">
      <h3 className="d-flex align-items-center justify-content-between">
        {English.G433}
        <div className="d-flex align-items-center col-gap-20">
          <Dropdown
            title="Filter"
            Data={[{ title: 'Running' }, { title: 'Planned' }]}
          />
          <Link className="border-0">{English.G423}</Link>
        </div>
      </h3>
      <div className="summary-table">
        <table className="w-100">
          <tr>
            <th>ID</th>
            <th>Ads</th>
            <th>Status</th>
            <th>Reach</th>
            <th>Engagement</th>
            <th>Date Created</th>
            <th />
          </tr>
          {items
            .filter((item, index) => index < 4)
            .map((item) => (
              <tr key={item}>
                <td>{item.id}</td>
                <td>
                  <div className="summary-adds">
                    <div className="summary-adds-img">
                      <img alt="" src={item.videoAds} />
                    </div>
                    {item.ads}
                  </div>
                </td>
                <td>
                  <span id={item.status}>{item.status}</span>
                </td>
                <td>
                  {item.reach}
                  <div className="mt-2">
                    <ProgressBar
                      className="profress-line"
                      baseBgColor="#fff"
                      height={9}
                      width={90}
                      completed={50}
                      borderRadius={3}
                      bgColor="linear-gradient(180deg, #FFD739 0%, #F8C600 99.99%, #FFD739 100%)"
                      isLabelVisible={false}
                    />
                  </div>
                </td>
                <td>
                  {item.engagement}
                  <div className="mt-2">
                    <ProgressBar
                      className="profress-line"
                      baseBgColor="#fff"
                      height={9}
                      width={90}
                      completed={50}
                      borderRadius={3}
                      bgColor="#001C93"
                      isLabelVisible={false}
                    />
                  </div>
                </td>
                <td>{item.dateCreated}</td>
                <td>
                  <Link>
                    <img alt="" src={Images.IconsMore} />
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  )
}
export default AdvertisementOption
