import React from 'react'
import Dropdown from '../Dropdown/Dropdown'
import Discussions from './Discussions'
import Polls from './Polls'
import Giveaway from './Giveaways'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const CommunityDetails = () => {
  return (
    <div>
      <div className="add-video-row ">
        <CommonButton
          label={English.G137}
          imgSrc={Images.AddIco}
          // onClick={handleButtonClick}
          isPrimary
          className="add_new_content disabledDropdown"
        />
        <div className="items-filter">
          <div className="position-relative input-position">
            <input placeholder="Search Product" className="disabledDropdown" />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="content-filter disabledDropdown"
          />
        </div>
      </div>
      <div className="community-detail-manager">
        <Discussions />
        <Polls />
        <Giveaway />
        {/* <AwardsDetails /> */}
      </div>
    </div>
  )
}
export default CommunityDetails
