import React, { useRef, useState } from 'react'
import Stories from 'react-insta-stories'
import PropTypes from 'prop-types'
import './REcentShots.css'
import English from '../../helpers/English'

const RecentShots = () => {
  const [pause, setPause] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const storiesRef = useRef()

  const pauseHandel = () => {
    setPause(!pause)
  }

  const VolumeHandel = () => {
    setIsMuted(!isMuted)
  }

  const playPauseIcon = pause ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M8.89402 6.53751C8.84168 6.58958 8.64356 6.81642 8.46036 7.00607C6.892 8.51701 5.08607 9.76303 3.11392 10.695C2.76929 10.8448 2.40584 10.9474 2.03346 11C1.74485 11.0019 1.46007 10.9343 1.20346 10.8029C0.869688 10.617 0.606863 10.3268 0.455712 9.97732C0.361719 9.6542 0.284307 9.32648 0.223866 8.99553C0.0568802 7.84053 -0.0169171 6.67413 0.00324612 5.50742C-0.0087408 4.37758 0.0562202 3.2482 0.197669 2.12709C0.26836 1.71381 0.373439 1.30707 0.511757 0.911065C0.646164 0.640598 0.853148 0.412361 1.10981 0.251575C1.36648 0.0907899 1.66279 0.00373468 1.96609 0H2.01849C2.44039 0.066061 2.85075 0.191263 3.23733 0.371871C5.16678 1.2973 6.93408 2.52528 8.47159 4.00878C8.63059 4.16774 8.77917 4.33662 8.91648 4.51447C9.12318 4.79749 9.23328 5.139 9.23057 5.48881C9.23435 5.86465 9.11485 6.23151 8.89028 6.53379"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
    >
      <path
        d="M8.00011 0C8.55233 0 9 0.447668 9 0.999895V9.99977C9 10.552 8.55233 10.9997 8.00011 10.9997V10.9997C7.44788 10.9997 7.00021 10.552 7.00021 9.99977V0.999895C7.00021 0.447668 7.44788 0 8.00011 0V0Z"
        fill="white"
      />
      <path
        d="M0.999895 0.000337601C1.55212 0.000337601 1.99979 0.352103 1.99979 0.786028V10.2143C1.99979 10.6482 1.55212 11 0.999895 11C0.447668 11 0 10.6482 0 10.2143V0.786028C0 0.352103 0.447668 0.000337601 0.999895 0.000337601Z"
        fill="white"
      />
    </svg>
  )

  const playVolumeIcon = isMuted ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5059 1.43457C12.273 2.62607 12.6843 4.04296 12.6843 5.49468C12.6843 6.94639 12.273 8.36329 11.5059 9.55478"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96289 3.04004C10.36 3.78848 10.569 4.6362 10.569 5.499C10.569 6.36179 10.36 7.20959 9.96289 7.95804"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.5C13.0598 4.08693 13.0359 4.28454 12.4995 4.99965C11.9632 5.71476 11.4397 6.41272 10.9995 6.99965"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.5C11.1087 4.10874 11.299 4.29904 12.0008 5.00078C12.7025 5.70253 13.3913 6.39126 14 7"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  const stories = [
    {
      cover: './images/shots/1.jpg',
      url: 'https://kaprat.com/dev/demo/videos/5.mp4',
      type: 'video',
      header: {
        heading: 'Kathryn Murphy',
        subheading: '7h',
        profileImage: './images/task-profile4.png'
      }
    },
    {
      cover: './images/shots/2.jpg',
      url: 'https://kaprat.com/dev/demo/videos/25.mp4',
      type: 'video',
      header: {
        heading: 'Kathryn Murphy',
        subheading: '7h',
        profileImage: './images/task-profile3.png'
      }
    },
    {
      cover: './images/shots/3.jpg',
      url: 'https://kaprat.com/dev/demo/videos/26.mp4',
      type: 'video',
      header: {
        heading: 'Kathryn Murphy',
        subheading: '7h',
        profileImage: './images/task-profile2.png'
      }
    },
    {
      cover: './images/shots/4.jpg',
      url: 'https://kaprat.com/dev/demo/videos/29.mp4',
      type: 'video',
      header: {
        heading: 'Kathryn Murphy',
        subheading: '7h',
        profileImage: './images/task-profile1.png'
      }
    }
  ]
  const [hoveredItem, setHoveredItem] = useState(null)
  const handleMouseEnter = (itemIndex) => {
    setHoveredItem(itemIndex)
  }

  const handleMouseLeave = () => {
    setHoveredItem(null)
  }
  return (
    <div className="recent-video-component recent-shots-component common-card-body">
      <h3>{English.G164}</h3>
      <div className="shots-grid">
        {stories.map((item, index) => (
          <div
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === index ? (
              <div className="shots-stories-react w-100 m-0">
                <Stories
                  ref={storiesRef}
                  stories={stories}
                  isMuted={isMuted}
                  header={(props) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          width: '140px',
                          columnGap: '10px'
                        }}
                      >
                        <div>
                          <img
                            src={props.profileImage}
                            alt="Avatar"
                            style={{
                              'vertical-align': 'middle',
                              width: '30px',
                              height: '30px',
                              borderRadius: '8px',
                              border: '0.74px solid #FFF'
                            }}
                          />
                        </div>
                        <div
                          style={{
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '12px'
                          }}
                        >
                          <p
                            style={{
                              color: 'white',
                              fontWeight: '600',
                              fontSize: '12px',
                              margin: 0,
                              textAlign: 'start'
                            }}
                          >
                            {props.heading}
                          </p>
                          <p
                            style={{
                              color: '#ffffff99',
                              fontWeight: '400',
                              fontSize: '12px',
                              margin: 0,
                              textAlign: 'start'
                            }}
                          >
                            {`${props.subheading}`}
                          </p>
                        </div>
                      </div>
                    )
                  }}
                  defaultInterval={1500}
                  width="100%"
                  isPaused={pause}
                  height="100%"
                  storyContainerStyles={{
                    background: 'transparent',
                    margin: 'auto'
                  }}
                />
                <div className="shots-control">
                  <div className="shots-play-control" onClick={pauseHandel}>
                    {playPauseIcon}
                  </div>
                  <div className="shots-volume-control" onClick={VolumeHandel}>
                    {playVolumeIcon}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="shots-default-content"
                style={{
                  backgroundImage: `url(${item.cover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="shots-default-profile">
                  <img src={item.header.profileImage} alt="" />
                  <p>{item.header.heading}</p>
                  <span>{item.header.subheading}</span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
RecentShots.propTypes = {
  profileImage: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired
}
export default RecentShots
