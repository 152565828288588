/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'

const WorkSpace = () => {
  const navigate = useNavigate()
  const initialApps = [
    {
      id: 1,
      text: 'TASK NAME'
    },
    {
      id: 2,
      text: 'TASK NAME'
    },
    {
      id: 3,
      text: 'TASK NAME'
    },
    {
      id: 4,
      text: 'TASK NAME'
    }
  ]

  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    const generateWeeklyAppointments = () => {
      const today = new Date()
      const startOfWeek = new Date(today)
      startOfWeek.setDate(today.getDate() - today.getDay())

      const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
      const appointmentsData = Array.from({ length: 7 }).map((_, index) => {
        const currentDay = new Date(startOfWeek)
        currentDay.setDate(startOfWeek.getDate() + index)

        return {
          day: daysOfWeek[index],
          date: currentDay.getDate(),
          isActive: today.getDate() === currentDay.getDate()
        }
      })

      setAppointments(appointmentsData)
    }

    generateWeeklyAppointments()
  }, [])

  const handleButtonClick = () => {
    navigate('/dashboard/workspace')
  }

  return (
    <div className="gbn-business-profile">
      <div className="flex justify-between items-center mb-[20px]">
        <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
          {English.G233}
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <p className="text-[#0B0A0A] italic text-lg font-bold uppercase">
          {English.A151}
        </p>
        <div className="grid grid-cols-7 gap-2 mb-2">
          {appointments.map((day, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center py-2 rounded ${
              day.isActive ? 'bg-[#183379] text-white' : 'text-gray-700'
            }`}
            >
              <span className="text-sm font-medium">{day.day}</span>
              <span className="text-lg font-bold">{day.date}</span>
            </div>
        ))}
        </div>
        <img
          className="w-[343px] h-[184px]"
          src={Images.AddtoCaleder_image}
          alt="sad"
          onClick={handleButtonClick}
        />
      </div>
      <div className="mt-3">
        <h4 className="font-bold text-lg italic text-[#0B0A0A]">
          {English.A152}
        </h4>

        {initialApps.map((i) => (
          <div
            key={i.id}
            className="w-[334px] h-auto p-[16px] rounded-2xl bg-white border border-[#E4E4E4] mb-[12px]"
          >
            <div className="flex flex-1 flex-col">
              <div className="text-gradient font-extrabold text-base italic mb-[12px]">
                {i.text}
              </div>
              <p className="text-[#9E9E9E] text-xs font-medium mb-[12px]">
                Make a presentation about the marketing strategy for Q4. Make a
                presentation about the marketing strategy for Q4.
              </p>
              <div className="flex gap-3">
                <div className="flex gap-1 ">
                  <img
                    className=""
                    src={Images.watch_round_arrow}
                    alt=""
                  />
                  <p className="mb-0 text-xs font-semibold">4 hr, 30 min</p>
                </div>
                <div className="flex gap-1">
                  <img
                    className=""
                    src={Images.timer_icon}
                    alt=""
                  />
                  <p className="mb-0 text-xs font-semibold w-[70px]">Due Today</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WorkSpace
