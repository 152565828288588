import React, { useState } from 'react'
import Dropdown from '../Dropdown/Dropdown'
import Images from '../../helpers/Images'

const ExpertCourses = () => {
  const items = [
    {
      CoverImage: './images/course1.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/scalco-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course3.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course4.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course1.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/scalco-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course3.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course4.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course1.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/scalco-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course3.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course4.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    }
  ]

  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  return (
    <div className="podcast-details-manage expert-courses recent-video-component common-card-body">
      <div className="add-video-row">
        <h3 className="m-0">Courses</h3>
        <div className="items-filter">
          <div className="position-relative input-position">
            <input placeholder="Search Course" />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
      </div>
      <div className="courses-item-row">
        {items.slice(0, visibleItems).map((item) => (
          <div
            className="courses-item-box position-relative"
            key={item.text}
            style={{
              background: `url(${item.CoverImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="Courses-name">
              <p>
                <span>{item.Title}</span>
                <br />
                {item.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img alt="" src={Images.DownIco} />
          </button>
        </div>
      )}
    </div>
  )
}
export default ExpertCourses
