import React from 'react'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'

const ConfirmationModal = ({
  isVisible,
  onConfirm,
  onCancel,
  message = English.G119,
  isLoading = false
}) => {
  if (!isVisible) return null

  return (
    <div className="add-item-model delete_modal">
      <div className="add-item-contents h-auto w-auto">
        <div className="item-details-input pt-0">
          <h3 className="mb-0 text-center">{message}</h3>
          <div className="button-row justify-content-center">
            <CommonButton
              label={English.G116}
              type="button"
              // className="secondary-btn-modelConfirm"
              onClick={onCancel}
              disabled={isLoading}
              isPrimary={false}
              context={English.G232}

            />
            <CommonButton
              label={English.G120}
              type="button"
              onClick={onConfirm}
              // className="primary-btn-modelConfirm"
              disabled={isLoading}
              isPrimary
              context={English.G232}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
