/* eslint-disable import/no-named-as-default-member */
/* eslint-disable eqeqeq */
/* eslint-disable no-multi-assign */
import axios from 'axios'
import { decode as base64_decode, encode as base64_encode } from 'base-64'
import imageCompression from 'browser-image-compression'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { toast } from 'react-toastify'
import Utility from '../helpers/Utility'
import { setUserData } from '../redux/UserSlice/UserSlice'
import { ReduxStore } from '../redux'

export const getData = (url) => {
  const token = Utility.getToken()

  return axios({
    method: 'GET',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export const postData = (url, data) => {
  const formData = new FormData()
  formData.append('data', dataEncrypt(data))
  const token = Utility.getToken()

  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      if (response?.data?.code === 422 || response?.data?.code === 400) {
        toast.error('Something went wrong. Please try again later.')
      } else if (response?.data?.code === 410) {
        toast.error(response?.data?.message)
      } else if (response?.data?.code === 404) {
        toast.error('Page not found.')
      }
      return response
    })
    .catch((error) => {
      if (error?.response) {
        if (error?.response?.status === 422 || error?.response.status === 400) {
          toast.error('Something went wrong. Please try again later')
        } else if (error?.response?.status === 500) {
          toast.error('Server error. Please try again later.')
        } else if (error?.response?.status === 404) {
          toast.error('Page not found.')
        }
      } else {
        toast.error(error?.response?.message)
      }
      return {
        data: {
          success: false
        }
      }
    })
}

export const ImagePostData = (url, data) => {
  const formData = new FormData()
  formData.append('image', data)
  return axios.post(url, formData).then((res) => {
    return res
  })
}

export const ImagePostDataStore = (url, data, type) => {
  const formData = new FormData()
  formData.append('file', data)
  formData.append('type', type)
  const token = Utility.getToken()
  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export const compressImage = async (imageFile) => {
  // const options = {
  //   maxSizeMB: 0.05, // (50 KB)
  //   maxWidthOrHeight: 1024,
  //   initialQuality: 0.9,
  //   useWebWorker: true
  // }
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options)
    return compressedFile
  } catch (error) {
    console.error('Image compression error', error)
  }
}

export const uploadFileData = (url, formData, onUploadProgress) => {
  const token = Utility.getToken()
  return axios
    .post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      onUploadProgress: (progressEvent) => {
        if (typeof onUploadProgress === 'function') {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onUploadProgress(percentCompleted)
        }
      }
    })
    .then((res) => {
      return res
    })
}

export const compressVideo = async (file, onProgress) => {
  const ffmpeg = new FFmpeg({ log: true })

  try {
    await ffmpeg.load()

    ffmpeg.on('progress', (progress) => {
      if (progress) {
        const progressPercentage = Math.floor(progress.progress * 100)
        onProgress(progressPercentage)
      }
    })

    ffmpeg.off()

    await ffmpeg.writeFile('input', await fetchFile(file))

    const isAudio = file.type == 'audio/mpeg'

    const outputFile = isAudio ? 'output.mp3' : 'output.mp4'
    const ffmpegArgs = isAudio
      ? ['-i', 'input', '-b:a', '128k', outputFile]
      : [
          '-i',
          'input',
          '-c:v',
          'libx264',
          '-b:v',
          '800k',
          '-crf',
          '28',
          '-preset',
          'slow',
          '-c:a',
          'aac',
          '-b:a',
          '128k',
          outputFile
        ]

    await ffmpeg.exec(ffmpegArgs)

    const fileData = await ffmpeg.readFile(outputFile)
    const compressedFile = new Blob([fileData.buffer], {
      type: isAudio ? 'audio/mp3' : 'video/mp4'
    })

    const newFile = new File([compressedFile], outputFile, {
      type: isAudio ? 'audio/mp3' : 'video/mp4'
    })

    return newFile
  } catch (err) {
    throw new Error(`Media compression failed: ${err.message}`)
  }
}

const generateRandomString = () => {
  const randomString = Math.random().toString(36).substring(2, 18)
  const remainrandomString = Math.random().toString(36).substring(2, 18)
  return randomString.padEnd(16, remainrandomString).substring(0, 16)
}

export const dataEncrypt = (data) => {
  const string = JSON.stringify(data, null, 2)

  const key = generateRandomString()
  let result = ''
  for (let i = 0; i < string.length; i++) {
    const char = string.charAt(i)
    let index = (i % key.length) - 1
    index = index = ((index % key.length) + key.length) % key.length
    const keychar = key.slice(index, index + 1)
    const encryptedChar = String.fromCharCode(
      char.charCodeAt(0) + keychar.charCodeAt(0)
    )

    result += encryptedChar
  }
  const base64EncodedResult = base64_encode(result)

  return base64EncodedResult + key
}

export const dataDecrypt = (encryptedString) => {
  if (Array.isArray(encryptedString) || String(encryptedString).length <= 16) {
    return encryptedString
  }

  try {
    const key = encryptedString?.slice(-16)
    const encryptedData = encryptedString?.slice(0, -16)

    let result = ''
    const decodedData = base64_decode(encryptedData)

    for (let i = 0; i < decodedData.length; i++) {
      const char = decodedData.charAt(i)
      let index = (i % key.length) - 1
      index = index = ((index % key.length) + key.length) % key.length
      const keychar = key.slice(index, index + 1)
      const encryptedChar = String.fromCharCode(
        char.charCodeAt(0) - keychar.charCodeAt(0)
      )

      result += encryptedChar
    }
    const decryptedJson = JSON?.parse(result) ?? ''
    return decryptedJson
  } catch (e) {
    return encryptedString
  }
}

export const setUserSliceData = (data, type) => {
  ReduxStore.dispatch(setUserData({ data, type }))
}
