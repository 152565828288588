import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHeadStore from '../ProfileBuilder/ProfileHeadStore'
import Feeds from '../Feeds/AddFeeds'
import BussinessProfileRating from '../ReviewsAndRating/BussinessProfileRating'
import BussinessProfileRatingContainer from '../RatingContainer/BussinessProfileRatingContainer'
import AddDeals from './AddDeals'
import AddRooms from './AddRooms'
import AddPhotos from './AddPhotos'
import DealComponent from './DealComponent'
import RoomComponent from './RoomComponent'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const LocalHotels = () => {
  return (
    <div className="creator-profile-main creator-profile-create">
      <ProfileHeadStore />
      <div className=" mt-30">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.HomeIco} />
                  {English.G222}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedIco} />
                  {English.G223}
                </Tab>
                <Tab>
                  <img alt="" src={Images.DealIco} />
                  {English.G453}
                </Tab>
                <Tab>
                  <img alt="" src={Images.RoomIco} />
                  {English.G287}
                </Tab>
                <Tab>
                  <img alt="" src={Images.AwardsIco} />
                  {English.G452}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <AddDeals />
                </div>
                <div className="mt-40">
                  <AddRooms />
                </div>
                <div className="mt-40">
                  <AddPhotos />
                </div>
                <div className="mt-40">
                  <BussinessProfileRating />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Feeds />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <DealComponent />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <RoomComponent />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <BussinessProfileRatingContainer />
                </div>
                <div className="mt-40">
                  <BussinessProfileRating />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LocalHotels
