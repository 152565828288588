import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Images from '../../helpers/Images'
import English from '../../helpers/English'

const PersonalityTest = () => {
  return (
    <div className="categories-admin">
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.local_add_img_All} />
                  {English.G415}
                </Tab>
                <Tab>
                  <img alt="" src={Images.RelaxationIco} />
                  {English.G416}
                </Tab>
                <Tab>
                  <img alt="" src={Images.MovementIco} />
                  {English.G417}
                </Tab>
                <Tab>
                  <img alt="" src={Images.SvustainabilityIco} />
                  {English.G418}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <h3>
                    {English.G419}
                  </h3>
                  <button type="button" className="primary-btn">
                    <span>
                      <img alt="" src={Images.AddIcoAdmin} />
                      {' '}
                      {English.G420}
                    </span>
                  </button>
                </div>
                <div className="common-card-body">
                  <div>
                    <button type="button" className="light-button">
                      <span>
                        <img alt="" src={Images.Delete} />
                        {' '}
                        {English.G392}
                      </span>
                    </button>
                  </div>
                  <div className="personality-questions">
                    <h4>Q #: 1</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cum auctor proin est aliquam massa.
                      {' '}
                    </p>
                    <div className="personality-test">
                      <div>
                        <img alt="" src={Images.personality1} />
                      </div>
                      <h2>{English.G421}</h2>
                      <div>
                        <img alt="" src={Images.personality2} />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PersonalityTest
