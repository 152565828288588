/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Event.css'
import { Button, Image, Input, Table, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { LoadScriptNext } from '@react-google-maps/api'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dropdown from '../../Dropdown/Dropdown'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import {
  locationSuggestion,
  s3ImageOrganizer
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination/Pagination'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import MapComponent from '../../MapComponent/MapComponent'
import AutocompleteInput from '../../MapComponent/AutoComplete'
import CommonDatePicker from '../../CommonDatePicker/CommonDatePicker'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const libraries = ['places']
const Event = ({ isOrganizerId, latitude, longitude }) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const { id } = useParams()
  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    name: '',
    industry_type_id: '',
    performer_type_id: '',
    start_time: '',
    end_time: '',
    min_price: '',
    max_price: '',
    tickets_qty: '',
    date: '',
    description: '',
    images: [],
    website: '',
    latitude: latitude || '',
    longitude: longitude || '',
    event_performer: [],
    contact_name1: '',
    contact_profile_pic: [],
    organizer_id: isOrganizerId,
    event_id: '',
    performer_id: '',
    address: ''
  })
  const [isIndustryType, setIsIndustryType] = useState([])
  const [isPerformerType, setIsPerformerType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [personDetails, setPersonDetails] = useState(false)
  const [eventListData, setEventListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [delEventId, setDelEventId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowConfirmPerformerModel, setShowConfirmPerformerModel] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [performerLoading, setPerformerLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [particularEvent, setParticularEvent] = useState([])
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isEditPerformer, setIsEditPerformer] = useState(false)
  const [error, setError] = useState({
    min_price: '',
    max_price: '',
    start_time: '',
    end_time: ''
  })

  const [initialInputFields, setInitialInputFields] = useState({
    contact_profile_pic: '',
    performer_id: '',
    contact_name1: '',
    performer_type_id: ''
  })
  const [delContactId, setDelContactId] = useState()
  const [contactIndex, setContactIndex] = useState(null)
  const [eventID, setEventID] = useState('')
  const [EditAddPerformer, setEditNewAddPerformer] = useState(false)
  const [AddPerformer, setAddPerformer] = useState(false)
  const [currentEditIndex, setCurrentEditIndex] = useState(null)
  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      industry_type_id: '',
      performer_type_id: '',
      performer_id: '',
      start_time: '',
      end_time: '',
      min_price: '',
      max_price: '',
      tickets_qty: '',
      date: '',
      description: '',
      images: [],
      website: '',
      latitude: '',
      longitude: '',
      event_performer: [],
      contact_name1: '',
      contact_profile_pic: [],
      event_id: '',
      address: ''
    })
    setIsEdit(false)
    setEditNewAddPerformer(false)
  }

  const [isEventLoading, setEventLoading] = useState(false)

  const [markerPosition, setMarkerPosition] = useState({
    lat: latitude || 0,
    lng: longitude || 0
  })

  const clearPerformer = () => {
    setInputFields({
      ...inputFields,
      performer_type_id: '',
      performer_id: '',
      contact_name1: '',
      contact_profile_pic: []
    })
  }

  const addDiscount = () => {
    setIsAddDiscount(true)
  }

  const clickPerFormerAdd = () => {
    setPersonDetails(true)
    setIsRoomFeature(false)
  }

  const cancelContact = () => {
    setPersonDetails(false)
    setIsRoomFeature(true)
    clearPerformer()
  }

  const performerAdd = () => {
    const updatedPerformer = {
      performer_type_id: inputFields.performer_type_id,
      event_performers_name: inputFields.contact_name1,
      performer_profile_pic: inputFields.contact_profile_pic,
      performer_id: inputFields.performer_id
    }

    if (isEditPerformer && currentEditIndex !== null) {
      const updatedPerformers = [...inputFields.event_performer]
      updatedPerformers[currentEditIndex] = updatedPerformer

      setInputFields({
        ...inputFields,
        event_performer: updatedPerformers,
        performer_type_id: '',
        contact_name1: '',
        contact_profile_pic: []
      })
    } else {
      setInputFields({
        ...inputFields,
        event_performer: [...inputFields.event_performer, updatedPerformer],
        performer_type_id: '',
        contact_name1: '',
        contact_profile_pic: []
      })
    }
    setIsEditPerformer(false)
    setCurrentEditIndex(null)
    setPersonDetails(false)
    setIsRoomFeature(true)
  }

  const handleCancels = () => {
    clearValue()
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setIsEdit(false)
  }

  const nextClick = () => {
    if (isEdit) {
      EventAddEditApiCall()
      // setIsAddDiscount(false)
      setIsRoomFeature(false)
    } else {
      setIsAddDiscount(false)
      setEditNewAddPerformer(false)
      setAddPerformer(false)
      setIsRoomFeature(true)
    }
  }

  const showEvent = (record) => {
    setIsAddRoom(true)
    particularEventData(record.event_id)
  }

  const EditEventData = async (record) => {
    // const data = await particularEventData(record.event_id)
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      // const mappedInputFields = data.map((d) => ({
      const mappedInputFields = {
        name: record.name,
        industry_type_id: record.industry_type_id,
        start_time: record.start_time,
        event_id: record.event_id,
        end_time: record.end_time,
        min_price: record.min_price,
        max_price: record.max_price,
        tickets_qty: record.tickets_qty,
        date: record.date,
        description: record.description,
        images: record.images,
        website: record.website,
        latitude: record.latitude,
        longitude: record.longitude,
        organizer_id: isOrganizerId
      }
      // }))

      // const mappedInitialValues = data.map((d) => ({
      const mappedInitialValues = {
        name: record.name,
        industry_type_id: record.industry_type_id,
        start_time: record.start_time,
        event_id: record.event_id,
        end_time: record.end_time,
        min_price: record.min_price,
        max_price: record.max_price,
        tickets_qty: record.tickets_qty,
        date: record.date,
        description: record.description,
        images: record.images,
        website: record.website,
        latitude: record.latitude,
        longitude: record.longitude,
        organizer_id: isOrganizerId
      }
      // }))

      setInputFields(mappedInputFields)
      setInitialValues(mappedInitialValues)
    }

    setIsAddDiscount(true)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const ShowConfirm = (record) => {
    setDelEventId(record.event_id)
    setShowConfirmModel(true)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
    setShowConfirmPerformerModel(false)
  }
  const handleCancelSpeaker = () => {
    setIsRoomFeature(false)
    setIsAddDiscount(true)
  }

  const formatViews = (views) => {
    if (views >= 1_000_000_000) {
      return `${(views / 1_000_000_000).toFixed(1)}B`
    }
    if (views >= 1_000_000) {
      return `${(views / 1_000_000).toFixed(1)}M`
    }
    if (views >= 1_000) {
      return `${(views / 1_000).toFixed(2)}k`
    }
    return views.toString()
  }

  const columns = [
    {
      title: 'EVENT NAME & IMAGE',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={s3ImageOrganizer + record.images[0]}
            alt="img"
            style={{ marginRight: 10 }}
          />
          <span>
            {record.name.length > 15
              ? `${record.name.substring(0, 15)}...`
              : record.name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      title: 'TIMING',
      dataIndex: 'timing',
      key: 'timing',
      sorter: (a, b) => a.start_time.localeCompare(b.start_time),
      render: (text, record) => {
        const formatTime = (time) => time.slice(0, 5)
        return `${formatTime(record.start_time)} - ${formatTime(record.end_time)}`
      }
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry_type',
      key: 'industry_type',
      sorter: (a, b) => a.industry_type.localeCompare(b.industry_type)
    },
    {
      title: 'TICKET QTY',
      dataIndex: 'tickets_qty',
      key: 'tickets_qty',
      sorter: (a, b) => a.tickets_qty - b.tickets_qty,
      render: (text) => formatViews(text)
    },
    {
      title: 'LIKE',
      key: 'like',
      render: (text, record) => (
        <Button
          icon={
            <img
              src={record.is_like ? Images.Likes : Images.UnLikes}
              alt={record.is_like ? 'Like' : 'Unlike'}
            />
          }
          onClick={(e) => {
            e.stopPropagation()
            eventLikeData(record.event_id)
          }}
        />
      )
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Images.Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              EditEventData(record)
            }}
          />
          <Button
            icon={<img src={Images.DeleteDiscount} alt="Delete" />}
            onClick={(e) => {
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      )
    }
  ]

  const filteredData = eventListData?.filter((item) => item.name.toLowerCase().includes(searchText))

  const validatePrices = () => {
    const minPrice = parseFloat(inputFields.min_price)
    const maxPrice = parseFloat(inputFields.max_price)

    if (!isNaN(minPrice) && !isNaN(maxPrice) && maxPrice <= minPrice) {
      setError((prev) => ({
        ...prev,
        max_price: English.G410
      }))
    } else {
      setError((prev) => ({
        ...prev,
        max_price: ''
      }))
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))

    if (name === 'min_price' || name === 'max_price') {
      const minPrice =
        name === 'min_price'
          ? parseFloat(value)
          : parseFloat(inputFields.min_price)
      const maxPrice =
        name === 'max_price'
          ? parseFloat(value)
          : parseFloat(inputFields.max_price)

      if (!isNaN(minPrice) && !isNaN(maxPrice) && maxPrice > minPrice) {
        setError((prev) => ({
          ...prev,
          max_price: ''
        }))
      }
    }
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        if (fieldName === 'contact_profile_pic') {
          coverFormData.append('type', 'organizer_event_perfomer')
        } else {
          coverFormData.append('type', 'organizer_event')
        }

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )
          if (res.status === 200 && res.data?.code === 200) {
            const decryptedImageData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              const prevData = prev[fieldName]
              if (
                (index !== undefined || index !== null) &&
                Array.isArray(prevData)
              ) {
                prevData[index] = decryptedImageData
              }
              return {
                ...prev,
                [fieldName]: prevData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const onChangeOpenTime = (time, timeString) => {
    if (timeString) {
      setInputFields((prevState) => ({
        ...prevState,
        start_time: timeString
      }))
      setError((prev) => ({
        ...prev,
        start_time: ''
      }))
    }
  }

  const onChangeCloseTime = (time, timeString) => {
    if (timeString) {
      if (inputFields?.start_time && timeString < inputFields?.end_time) {
        setError((prev) => ({
          ...prev,
          end_time: English.G325
        }))
      } else {
        setInputFields((prevState) => ({
          ...prevState,
          end_time: timeString
        }))
        setError((prev) => ({
          ...prev,
          end_time: ''
        }))
      }
    }
  }

  const typeofIndustryListData = () => {
    APICall('post', {}, `${EndPoints.industryType}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsIndustryType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const typeofPerformerListData = () => {
    APICall('post', {}, `${EndPoints.performerType}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsPerformerType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getIndustryName = (id) => {
    const categoryObj = isIndustryType.find((cat) => cat.id === id)
    return categoryObj ? categoryObj.industry_type : 'Select industry'
  }

  const getPerformerName = (id) => {
    const categoryObj = isPerformerType.find((cat) => cat.id === id)
    return categoryObj ? categoryObj.performer_type : 'Select performer'
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageOrganizer + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const allEventData = (currentPage) => {
    const isEventPath = window.location.pathname === '/event'
    const payload = {
      organizer_id: isOrganizerId,
      ...(isEventPath && { app_id: 2 })
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getEvent}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setEventListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const EventAddEditApiCall = () => {
    setEventLoading(true)
    if (isEdit) {
      const EditPayload = {
        name: inputFields.name,
        address: inputFields.address,
        event_id: inputFields.event_id,
        industry_type_id: inputFields.industry_type_id,
        start_time: inputFields.start_time.slice(0, 5),
        end_time: inputFields.end_time.slice(0, 5),
        min_price: inputFields.min_price,
        max_price: inputFields.max_price,
        tickets_qty: inputFields.tickets_qty,
        date: inputFields.date,
        description: inputFields.description,
        images: inputFields.images,
        organizer_id: isOrganizerId,
        latitude: inputFields.latitude,
        longitude: inputFields.longitude,
        website: inputFields.website
      }
      APICall('post', EditPayload, `${EndPoints.editEvent}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddDiscount(false)
            setIsRoomFeature(false)
            setPersonDetails(false)
            setIsEdit(false)
            setTimeout(() => {
              allEventData(currentPage)
              toast.success(res?.data?.message)
            }, 4000)
            clearValue()
          } else {
            setIsEdit(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      const payload = {
        name: inputFields.name,
        event_id: inputFields.event_id,
        address: inputFields.address,
        industry_type_id: inputFields.industry_type_id,
        start_time: inputFields.start_time.slice(0, 5),
        end_time: inputFields.end_time.slice(0, 5),
        min_price: inputFields.min_price,
        max_price: inputFields.max_price,
        tickets_qty: inputFields.tickets_qty,
        date: inputFields.date,
        description: inputFields.description,
        images: inputFields.images,
        event_performers_name: inputFields.event_performer.map(
          (d) => d.event_performers_name
        ),
        performer_type_id: inputFields.event_performer.map(
          (d) => d.performer_type_id
        ),
        performer_profile_pic: inputFields.event_performer.map((d) => d.performer_profile_pic.toString()),
        organizer_id: isOrganizerId,
        latitude: inputFields.latitude,
        longitude: inputFields.longitude,
        website: inputFields.website
      }
      APICall('post', payload, `${EndPoints.addEvent}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddDiscount(false)
            setIsRoomFeature(false)
            setPersonDetails(false)
            clearValue()
            setTimeout(() => {
              allEventData(currentPage)
              toast.success(res?.data?.message)
            }, 4000)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const deleteEvent = () => {
    setLoading(true)
    const data = {
      event_id: delEventId
    }
    APICall('post', data, `${EndPoints.deleteEvent}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            allEventData(currentPage)
            toast.success(res?.data?.message)
          }, 2500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const eventLikeData = (event_id) => {
    const isLocalBuilderPath =
      window.location.pathname === `/local-builder/${id}`
    const platFormData = {
      ...(isLocalBuilderPath && {
        app_id: app_id?.id
      }),
      event_id
    }
    APICall('post', platFormData, `${EndPoints.eventLike}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setEventListData(
            eventListData.map((item) => (item.event_id === event_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularEventData = async (event_id) => {
    setEventID(event_id)
    const payload = {
      organizer_id: isOrganizerId,
      event_id
    }
    APICall('post', payload, `${EndPoints.particularEvent}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularEvent(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    allEventData(pageNumber)
    setCurrentPage(pageNumber)
  }

  const onClickPerformerEdit = (data, index) => {
    setIsEditPerformer(true)
    setPersonDetails(true)
    setIsRoomFeature(false)
    setInputFields({
      ...inputFields,
      performer_type_id: data?.performer_type_id,
      contact_name1: data?.event_performers_name,
      contact_profile_pic: [data?.performer_profile_pic],
      performer_id: data?.performer_id
    })
    setCurrentEditIndex(index)
  }

  const ShowContactConfirm = (performer_id) => {
    setDelContactId(performer_id)
    setShowConfirmPerformerModel(true)
  }

  const onClickContactEdit = (event_id, item = {}) => {
    setPersonDetails(true)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setEventID(event_id)
    setEditNewAddPerformer(true)
    if (item && Object.keys(item).length > 0) {
      const updatedInputFields = {
        event_id: eventID,
        performer_type_id: item.performer_type_id || '',
        contact_name1: item.performer_name || '',
        contact_profile_pic: item.performer_profile_pic
          ? [item.performer_profile_pic]
          : [],
        performer_id: item.performer_id || ''
      }
      setInputFields((prevFields) => ({
        ...prevFields,
        ...updatedInputFields
      }))
      setInitialInputFields(updatedInputFields)
    }
  }

  const onClickContactAdd = (event_id) => {
    setPersonDetails(true)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setEventID(event_id)
    setAddPerformer(true)
  }

  const AddEditPerformer = () => {
    const payload = {
      event_id: eventID,
      performer_type_id: inputFields.performer_type_id,
      performer_name: inputFields.contact_name1,
      performer_profile_pic: inputFields.contact_profile_pic.toString(),
      performer_id: inputFields.performer_id
    }

    if (!EditAddPerformer && !AddPerformer) {
      performerAdd()
    } else if (EditAddPerformer) {
      APICall('post', payload, `${EndPoints.eventPerformerEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPersonDetails(false)
            setIsAddRoom(true)
            setEditNewAddPerformer(false)
            setTimeout(() => {
              particularEventData(inputFields.event_id)
              toast.success(res?.data?.message)
            }, 3000)
            clearPerformer()
          } else {
            setEditNewAddPerformer(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setEditNewAddPerformer(false)
          toast.error(error?.data?.message)
        })
    } else if (AddPerformer) {
      APICall('post', payload, `${EndPoints.eventPerformerAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddRoom(true)
            setPersonDetails(false)
            setEditNewAddPerformer(false)
            setTimeout(() => {
              toast.success(res?.data?.message)
              particularEventData(eventID)
            }, 3000)
            clearPerformer()
          } else {
            setEditNewAddPerformer(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setEditNewAddPerformer(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const performerDeleteApi = async () => {
    const data = {
      performer_id: delContactId
    }
    setPerformerLoading(true)
    APICall('post', data, `${EndPoints.eventPerformerDelete}`, 'local')
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              setShowConfirmPerformerModel(false)
              setPerformerLoading(false)
              particularEventData(eventID)
              toast.success(res?.data?.message)
            } else {
              setPerformerLoading(false)
              toast.error(res?.data?.message)
            }
          })
          .catch((error) => {
            setPerformerLoading(false)
            toast.error(error?.data?.message)
          })
  }

  const [isStep4Modified, setIsStep4Modified] = useState(false)

  const isStep4Valid = () => {
    return (
      isStep4Modified &&
      inputFields.contact_profile_pic &&
      inputFields.contact_name1 &&
      inputFields.performer_type_id
    )
  }

  useEffect(() => {
    setIsStep4Modified(
      inputFields.contact_profile_pic !==
        initialInputFields.contact_profile_pic ||
        inputFields.performer_type_id !==
          initialInputFields.performer_type_id ||
        inputFields.contact_name1 !== initialInputFields.contact_name1
    )
  }, [inputFields])

  const handleRemovePerformer = (data, index) => {
    if (isEdit) {
      deletePerformer(data.performer_id, index)
    } else {
      const updatedPerformers = inputFields.event_performer.filter(
        (performer, i) => i !== index
      )
      setInputFields({
        ...inputFields,
        event_performer: updatedPerformers
      })
    }
  }

  const deletePerformer = (id, index) => {
    const data = {
      performer_id: id
    }
    APICall('post', data, `${EndPoints.eventPerformerDelete}`, 'local')
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const updatedPerformers = inputFields.event_performer?.filter(
                (performer, i) => i !== index
              )
              setInputFields({
                ...inputFields,
                event_performer: updatedPerformers
              })
              toast.success(res?.data?.message)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((error) => {
            toast.error(error?.data?.message)
          })
  }

  const validateForm = () => {
    const {
      name,
      industry_type_id,
      start_time,
      end_time,
      min_price,
      max_price,
      tickets_qty,
      date,
      description,
      images,
      website
    } = inputFields

    if (
      name &&
      industry_type_id &&
      start_time &&
      end_time &&
      min_price &&
      max_price &&
      tickets_qty &&
      date &&
      description &&
      images.length > 0 &&
      website
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  useEffect(() => {
    typeofIndustryListData()
    typeofPerformerListData()
    allEventData(currentPage)
  }, [])

  useEffect(() => {
    if (isAddDiscount || isRoomFeature || ShowConfirmModel || isAddRoom || ShowConfirmPerformerModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddDiscount, isRoomFeature, ShowConfirmModel, isAddRoom, ShowConfirmPerformerModel])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        {eventListData?.length === 0 ? (
          <div className="add_room_text">
            <img className="d-block" src={Images.Event_Logo} alt="bedroom" />
            <h3>{English.G377}</h3>
            <p>{English.G378}</p>
            <div className="add-video-rows" onClick={addDiscount}>
              <div className="add_new_content">
                <img src={Images.PlusIco} alt="" />
                {English.G379}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="local-room-main">
              <h3 className="d-flex align-items-center justify-content-between">
                {English.G306}
                <button
                  type="button"
                  className="primary-btn"
                  onClick={addDiscount}
                >
                  <img src={Images.PlusIco} alt="add" />
                  {English.G379}
                </button>
              </h3>
              <div className="discount_list">
                <Input
                  placeholder="Search by Event Name"
                  onChange={handleSearch}
                  style={{ width: 200, marginBottom: '1rem' }}
                />
                <div className="discount_list">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          showEvent(record)
                        }
                      }
                    }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel}
                  onConfirm={deleteEvent}
                  onCancel={handleDeleteCancel}
                  isLoading={loading}
                />
                {loading && <Loader />}
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {isAddDiscount && (
          <div className="add-item-model">
            {isEventLoading && <Loader />}
            <div className="add-item-content local_type_modal contact_padding">
              <div className="type_close mb-2 local_sticky_header">
                <div className="d-flex align-items-center col-gap-10">
                  {isEdit ? 'EDIT EVENT' : 'ADD EVENT'}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0 local_contact_scroll_bars">
                <div className="input-grid">
                  <div>
                    <label>{English.G380}</label>
                    <CommonInput
                      placeholder={English.G381}
                      name="name"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.name)}
                    />
                  </div>
                  <div className="performer_dropdown">
                    <label>{English.G382}</label>
                    <Dropdown
                      title={getIndustryName(inputFields.industry_type_id)}
                      name="industry_type_id"
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          industry_type_id: d.id
                        })
                      }}
                      Data={isIndustryType.map((d) => ({
                        title: d.industry_type,
                        id: d.id
                      }))}
                      customClass={
                        inputFields.industry_type_id
                          ? 'selected_class_local'
                          : 'local-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G383}</label>
                    <div className="calendar">
                      <CommonDatePicker
                        inputFields={inputFields}
                        setInputFields={setInputFields}
                        fieldName="date"
                        placeholder={English.G57}
                        minDate={new Date()}
                        maxDate={
                          new Date(new Date().getFullYear() + 10, 11, 31)
                        }
                        popperPlacement="bottom"
                      />
                    </div>
                  </div>
                  <div>
                    <label>{English.G384}</label>
                    <CommonInput
                      placeholder={English.G385}
                      name="tickets_qty"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 4) {
                          e.preventDefault()
                        }
                      }}
                      type="number"
                      onChange={handleChange}
                      value={inputFields.tickets_qty}
                    />
                  </div>
                </div>
                <div className="input-grid mb-2">
                  <div className="time_timepicker_local">
                    <label>{English.G323}</label>
                    <TimePicker
                      name="start_time"
                      onChange={onChangeOpenTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder={English.G386}
                      value={
                        inputFields?.start_time
                          ? dayjs(inputFields?.start_time, 'HH:mm')
                          : null
                      }
                      format="HH:mm"
                      className="ant-design event_time"
                      style={{ 'margin-top': '0px' }}
                      onSelect={(time) => {
                        const timeString = time ? time.format('HH:mm') : ''
                        setInputFields((prevState) => ({
                          ...prevState,
                          start_time: timeString
                        }))
                        setError((prev) => ({
                          ...prev,
                          end_times: ''
                        }))
                      }}
                    />
                  </div>
                  <div className="time_timepicker_local">
                    <label>{English.G387}</label>
                    <TimePicker
                      name="end_time"
                      onChange={onChangeCloseTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder="Select end time"
                      value={
                        inputFields?.end_time
                          ? dayjs(inputFields?.end_time, 'HH:mm')
                          : null
                      }
                      format="HH:mm"
                      className="ant-design event_time"
                      style={{ 'margin-top': '0px' }}
                      onSelect={(time) => {
                        const timeString = time ? time.format('HH:mm') : ''
                        setInputFields((prevState) => ({
                          ...prevState,
                          end_time: timeString
                        }))
                        if (
                          inputFields?.start_time &&
                          timeString < inputFields?.start_time
                        ) {
                          setError((prev) => ({
                            ...prev,
                            end_time: English.G325
                          }))
                        } else {
                          setError((prev) => ({
                            ...prev,
                            end_time: ''
                          }))
                        }
                      }}
                    />
                    {error.end_time && (
                      <span className="error-message">{error.end_time}</span>
                    )}
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G319}</label>
                    <CommonInput
                      placeholder={English.G320}
                      name="min_price"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 4) {
                          e.preventDefault()
                        }
                      }}
                      onBlur={validatePrices}
                      type="number"
                      onChange={handleChange}
                      value={inputFields.min_price}
                    />
                  </div>
                  <div>
                    <label>{English.G321}</label>
                    <CommonInput
                      placeholder={English.G322}
                      name="max_price"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 4) {
                          e.preventDefault()
                        }
                      }}
                      onBlur={validatePrices}
                      onChange={handleChange}
                      value={inputFields.max_price}
                      className={`${error.max_price ? 'mb-0' : 'mb-2'}`}
                    />
                    {error.max_price && (
                      <span className="error-message">{error.max_price}</span>
                    )}
                  </div>
                </div>
                <div className="">
                  <div>
                    <label>{English.G388}</label>
                    <CommonInput
                      placeholder={English.G389}
                      name="website"
                      onChange={handleChange}
                      value={inputFields.website}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="local_event_description">
                    <label>{English.G112}</label>
                    <CommonInput
                      placeholder={English.G390}
                      name="description"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.description)}
                      maxLength={150}
                      isTextArea
                      className="mb-4"
                    />
                  </div>
                </div>
                <div className="hotel_image_upload_section mb-3">
                  <div className="hotel_upload_div mt-0">
                    <div className="input-image-show m-0 local_images_show">
                      {renderImg}
                      {inputFields.images.length < 4 && (
                        <div className="input-img-label">
                          <CommonImageUpload
                            handleImageChange={(file) => onUploadImage(
                                file,
                                'images',
                                inputFields.images.length
                              )}
                            buttonText="Add Image"
                            selectedImg=""
                            defaultImg={Images.plusGiveaway}
                            className="room_add_img"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <LoadScriptNext
                  googleMapsApiKey={locationSuggestion}
                  libraries={libraries}
                >
                  <div className="form-group">
                    <label>{English.G187}</label>
                    <AutocompleteInput
                      setInputFields={setInputFields}
                      inputFields={inputFields}
                    />
                  </div>
                  <div className="form-group">
                    <label>{English.G316}</label>
                    <MapComponent
                      inputFields={inputFields}
                      setInputFields={setInputFields}
                      onMapPositionChange={handleMapPositionChange}
                      markerPosition={markerPosition}
                    />
                  </div>
                </LoadScriptNext>
              </div>
              <div className="item-details-input pt-0">
                <div className="local_button_row local_sticky_btn">
                  <CommonButton
                    label={English.G116}
                    onClick={handleCancels}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    onClick={nextClick}
                    disabled={!isFormComplete}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            {isEventLoading && <Loader />}
            <div className="add-item-content local_type_modal pt-0">
              <div className="type_close mb-2 local_sticky_header pt-4 pb-2">
                <div className="d-flex align-items-center col-gap-10">
                  {isEdit ? 'EDIT EVENT' : 'ADD EVENT'}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div
                className={`item-details-input pt-0 ${
                  inputFields?.event_performer?.length > 2
                    ? 'local_scroll_bars'
                    : ''
                }`}
              >
                <div className="event_speaker_dropdown mb-4">
                  <div className="event_speaker_drop">
                    <label>{English.G391}</label>
                    <Dropdown
                      title={getPerformerName(inputFields.performer_type_id)}
                      name="performer_type_id"
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          performer_type_id: d.id
                        })
                      }}
                      Data={isPerformerType.map((d) => ({
                        title: d.performer_type,
                        id: d.id
                      }))}
                      customClass={
                        inputFields.performer_type_id
                          ? 'selected_class_local'
                          : 'local-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="speaker_info position-relative pb-1">
                  {inputFields?.event_performer
                    ?.filter(
                      (data) => data &&
                        data.performer_profile_pic &&
                        data.event_performers_name
                    )
                    ?.map((data, index) => (
                      <div
                        className="event_speaker position-relative"
                        key={index}
                      >
                        <div className="speaker_img">
                          <img
                            src={s3ImageOrganizer + data.performer_profile_pic}
                            alt={data.performer_profile_pic}
                          />
                        </div>
                        <div className="event_speaker_name">
                          <h1>
                            {data.event_performers_name.slice(0, 17)}
                            {data.event_performers_name.length > 17 && '...'}
                          </h1>
                          <p>{getPerformerName(data.performer_type_id)}</p>
                        </div>
                        <div className="event_speaker_remove">
                          <CommonButton
                            label={English.G392}
                            type="button"
                            isPrimary={false}
                            context={English.G232}
                            className="event_speaker_btn"
                            onClick={() => handleRemovePerformer(data, index)}
                          />
                        </div>
                        <div className="local_contact_buttons_event z-1">
                          <label>
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation()
                                onClickPerformerEdit(data, index)
                              }}
                              className="d-none"
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  {inputFields.event_performer?.length < 5 ? (
                    <div className="event_speaker">
                      <div className="add-profile-video-cover event_performer_box">
                        <button type="button" onClick={clickPerFormerAdd}>
                          <img src={Images.Plus} alt="add library" />
                        </button>
                      </div>
                      <div className="add-profile-video-detail">
                        <p />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="local_button_row local_sticky_btn mt-0">
                  <CommonButton
                    label={English.G359}
                    onClick={handleCancelSpeaker}
                    isPrimary={false}
                    context={English.G232}
                    className="common-button"
                  />
                  <CommonButton
                    label={English.G117}
                    type="button"
                    isPrimary
                    disabled={inputFields.event_performer?.length === 0}
                    onClick={EventAddEditApiCall}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isAddRoom && (
          <div className="add-item-model">
            {particularEvent.map((data, index) => (
              <div className="add-item-content add_room_modal" key={index}>
                <div className="type_close mb-2 local_sticky_header">
                  <div className="d-flex align-items-center col-gap-10">
                    {data.name}
                  </div>
                  <button
                    className="cancel_icon"
                    type="button"
                    onClick={handleCancels}
                  >
                    <img src={Images.RemoveLocal} alt="" />
                  </button>
                </div>
                <div className="item-details-input pt-0 local_contact_scroll_bars px-3">
                  <div className="room_image menu_image_show">
                    {data.images.map((d, i) => {
                      return (
                        <img
                          className="menu_image"
                          src={s3ImageOrganizer + d}
                          alt="roomImage"
                          key={i}
                        />
                      )
                    })}
                  </div>
                  <div className="edit_description ">
                    <p>{data.description}</p>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label className="performer_text">{English.G393}</label>
                      <div className="mb-3">
                        <div className="d-flex flex-wrap row performer_data">
                          {data.performer_details?.map((item, index) => {
                            const totalItems = data.performer_details.length
                            const columnClass =
                              totalItems === 2
                                ? 'col-6 btn-delete-edit'
                                : 'col-4 btn-delete-edit'

                            return (
                              <div
                                key={index}
                                className={`${columnClass} position-relative`}
                                style={{ marginBottom: '10px' }}
                              >
                                <div
                                  className="podcast-profile-img"
                                  style={{ marginRight: '10px' }}
                                >
                                  <img
                                    src={
                                      s3ImageOrganizer +
                                      item.performer_profile_pic
                                    }
                                    alt=""
                                  />
                                  <div className="podcast-profile-detail">
                                    <span>{item.performer_name}</span>
                                    <p className="designation">
                                      {item.performer_type_name}
                                    </p>
                                  </div>
                                </div>

                                <div className="local_contact_buttons z-1">
                                  <label>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onClickContactEdit(data.event_id, item)
                                      }}
                                      className="d-none"
                                    />
                                  </label>
                                  <button
                                    type="button"
                                    className="p-0"
                                    onClick={() => {
                                      setContactIndex(index)
                                      ShowContactConfirm(item.performer_id)
                                    }}
                                  />
                                </div>
                                <ConfirmationModal
                                  isVisible={
                                    ShowConfirmPerformerModel &&
                                    contactIndex === index
                                  }
                                  onConfirm={performerDeleteApi}
                                  onCancel={handleDeleteCancel}
                                  isLoading={performerLoading}
                                />
                                {performerLoading && contactIndex === index && (
                                  <Loader />
                                )}
                              </div>
                            )
                          })}
                          <div className="col position-relative">
                            <div
                              className="podcast-profile-img width_add_performer"
                              onClick={() => onClickContactAdd(data.event_id)}
                            >
                              <button
                                type="button"
                                className="add_contact_person p-0"
                                onClick={() => onClickContactAdd(data.event_id)}
                              >
                                <img
                                  className="add_image_local"
                                  src={Images.Plus}
                                  alt="add library"
                                />
                              </button>
                              <div className="podcast-profile-detail new_contact_add">
                                <span className="new_contact_add">
                                  {English.G394}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-grid detail-grid">
                    <div>
                      <label>{English.G395}</label>
                      <p>{data.date}</p>
                    </div>
                    <div>
                      <label>{English.G131}</label>
                      <p>
                        {English.G283}
                        {data.min_price}
                      </p>
                    </div>
                  </div>
                  <div className="input-grid detail-grid">
                    <div>
                      <label>{English.G396}</label>
                      <p>
                        {data.start_time.slice(0, 5)}
                        -
                        {data.end_time.slice(0, 5)}
                        {' '}
                        {English.G397}
                      </p>
                    </div>
                    <div>
                      <label>{English.G382}</label>
                      <p>{data.industry_type}</p>
                    </div>
                  </div>
                </div>
                <div className="item-details-input pt-0">
                  <div className="local_button_row local_sticky_btn">
                    <CommonButton
                      label={English.G359}
                      onClick={handleCancels}
                      isPrimary={false}
                      context={English.G232}
                      className="particular_event_cancel"
                    />
                    {/* <CommonButton
                      label="EDIT"
                      type="button"
                      isPrimary
                      onClick={eventEditSave}
                      context={English.G232}
                    /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {personDetails && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  {English.G398}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={cancelContact}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <p />
                <div className="logo_btn mb-5">
                  <div className="image_upload_section">
                    <div className="add_photos mb-4 mt-2">
                      <div className="add_logo_text text-center align-self-center">
                        <CommonImageUpload
                          name="local_upload_logo"
                          handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                          index={0}
                          forImage={English.G290}
                          className="profile-upload"
                          selectedImg={
                            inputFields.contact_profile_pic.length > 0
                              ? `${s3ImageOrganizer}${inputFields.contact_profile_pic}`
                              : ''
                          }
                          defaultImg={Images.local_add_img}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="person_detail_section">
                    <div className="mb-3 performer_dropdown">
                      <label>{English.G399}</label>
                      <Dropdown
                        title={getPerformerName(inputFields.performer_type_id)}
                        name="performer_type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            performer_type_id: d.id
                          })
                        }}
                        Data={isPerformerType.map((d) => ({
                          title: d.performer_type,
                          id: d.id
                        }))}
                        customClass={
                          inputFields.performer_type_id
                            ? 'selected_class_local'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <div>
                      <label>{English.G130}</label>
                      <CommonInput
                        placeholder={English.G130}
                        onChange={handleChange}
                        name="contact_name1"
                        value={capitalizeFirstLetter(inputFields.contact_name1)}
                      />
                    </div>
                  </div>
                </div>
                <p />
                <p />
                <div className="local_button_row">
                  <CommonButton
                    label={English.G116}
                    onClick={cancelContact}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    onClick={() => {
                      AddEditPerformer()
                    }}
                    disabled={!isStep4Valid()}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Event
