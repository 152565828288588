import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RestaurantsMenu = () => {
  const items = [
    {
      img: './images/Restaurant/1.jpg',
      rating: '4.9',
      name: 'Restaurant Name',
      location: '3517 W. Gray St. Utica,'
    },
    {
      img: './images/Restaurant/2.jpg',
      rating: '4.9',
      name: 'Restaurant Name',
      location: '3517 W. Gray St. Utica,'
    },
    {
      img: './images/Restaurant/3.jpg',
      rating: '4.9',
      name: 'Restaurant Name',
      location: '3517 W. Gray St. Utica,'
    }
  ]
  return (
    <div className=" services-container menu-wrapper common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Restaurants
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="menu-grid--item">
        {items.map((item) => (
          <div className="menu-grid-item" key={item.name}>
            <div
              className="menu-item-cover"
              style={{
                background: `url(${item.img})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>{item.rating}</span>
              </div>
            </div>
            <div className="menu-item-details">
              <h4>{item.name}</h4>
              <span>
                <img alt="" src={Images.Location} />
                {item.location}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RestaurantsMenu
