/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadScript, LoadScriptNext } from '@react-google-maps/api'
import Dropdown from '../../Dropdown/Dropdown'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  ForMobileNumber
} from '../../../helpers/Utility'
import {
  GOOGLE_MAP_LIBRARIES,
  locationSuggestion,
  s3ImageHotel,
  s3ImageLocalIcon
} from '../../../services/Service'
import 'leaflet/dist/leaflet.css'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import MapComponent from '../../MapComponent/MapComponent'
import Loader from '../../Loader/Loader'
import AutocompleteInput from '../../MapComponent/AutoComplete'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const hotelPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowHotelList,
  setIsShow,
  // particularHotelApiCall = () => {},
  editHotel,
  isFromHotelComponent,
  platformData
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [inputFields, setInputFields] = useState({
    ...defaultData,
    name: '',
    description: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    amenities: [],
    min_price: '',
    max_price: '',
    logo: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    contact_phone1: '',
    contact_phone2: '',
    contact_profile_pic: [],
    hotel_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: '',
    address: ''
  })

  const [error, setError] = useState({
    min_price: '',
    max_price: ''
  })

  const [selectedAmenities, setSelectedAmenities] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [errors, setErrors] = useState('')
  const [isStep1Modified, setIsStep1Modified] = useState(false)
  const [isStep2Modified, setIsStep2Modified] = useState(false)
  const [isStep3Modified, setIsStep3Modified] = useState(false)
  const [isStep4Modified, setIsStep4Modified] = useState(false)
  const [isStep5Modified, setIsStep5Modified] = useState(false)
  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [isHotelAmenitiesListData, setIsHotelAmenitiesListData] = useState([])
  const [imagesCleared, setImagesCleared] = useState(false)
  const [initialImages, setInitialImages] = useState(defaultData.images || [])

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      description: '',
      address: '',
      city_id: '',
      zip_code: '',
      longitude: '',
      latitude: '',
      website: '',
      amenities: [],
      min_price: '',
      max_price: '',
      logo: '',
      images: [],
      contact_name1: '',
      contact_name2: '',
      contact_designation1: '',
      contact_designation2: '',
      contact_phone1: '',
      contact_phone2: '',
      contact_profile_pic: [],
      hotel_id: '',
      country_id: '',
      province_id: '',
      country: '',
      city_name: '',
      province_name: ''
    })
  }

  const handleNextClick = () => {
    if (currentStep < 5) {
      if (currentStep === 3 && editHotel) {
        setCurrentStep(5)
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
      hotelAddApiCall()
    }
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      if (currentStep === 5 && editHotel) {
        setCurrentStep(3)
      } else {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  const handleStartStep2 = () => {
    setErrors('')
    if (inputFields.website && !urlRegex.test(inputFields.website)) {
      setErrors(English.G73)
      return
    }
    hotelAmenitiesListData()
    setCurrentStep(2)
  }

  const handleCancelStep1 = () => {
    // setLocationDetails(false)
    onCancel()
  }

  const handleCancels = () => {
    setCurrentStep(0)
    onCancel()
    clearValue()
  }

  const validatePrices = () => {
    const minPrice = parseFloat(inputFields.min_price)
    const maxPrice = parseFloat(inputFields.max_price)

    if (!isNaN(minPrice) && !isNaN(maxPrice) && maxPrice <= minPrice) {
      setError((prev) => ({
        ...prev,
        max_price: English.G410
      }))
    } else {
      setError((prev) => ({
        ...prev,
        max_price: ''
      }))
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target

    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))

    if (name === 'website' && urlRegex.test(value)) {
      setErrors('')
    }
    if (name === 'min_price' || name === 'max_price') {
      const minPrice =
        name === 'min_price'
          ? parseFloat(value)
          : parseFloat(inputFields.min_price)
      const maxPrice =
        name === 'max_price'
          ? parseFloat(value)
          : parseFloat(inputFields.max_price)

      if (!isNaN(minPrice) && !isNaN(maxPrice) && maxPrice > minPrice) {
        setError((prev) => ({
          ...prev,
          max_price: ''
        }))
      }
    }
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const imageFile = await compressImage(file)
          const coverFormData = new FormData()
          coverFormData.append('file', imageFile)

          if (fieldName === 'contact_profile_pic') {
            coverFormData.append('type', 'hotel_contact')
          } else if (fieldName === 'logo') {
            coverFormData.append('type', 'hotel_logo')
          } else {
            coverFormData.append('type', 'hotel')
          }

          try {
            const res = await APICall(
              'post',
              coverFormData,
              '',
              'imageUploadLocal',
              true
            )
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedImageData = dataDecrypt(res.data.data)
              setInputFields((prev) => {
                if (fieldName === 'logo') {
                  return {
                    ...prev,
                    [fieldName]: decryptedImageData
                  }
                }
                const prevData = prev[fieldName] || []
                const updatedData = [...prevData]

                if (
                  index !== undefined &&
                  index !== null &&
                  Array.isArray(prevData)
                ) {
                  updatedData[index] = decryptedImageData
                } else {
                  updatedData.push(decryptedImageData)
                }

                return {
                  ...prev,
                  [fieldName]: updatedData
                }
              })
              setIsStep5Modified(true)
            } else {
              toast.error(res?.data?.message || 'Error uploading image')
            }
          } catch (err) {
            toast.error(err?.response?.data?.message || 'Upload failed')
          }
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  // const handleClick = () => {
  //   document.getElementById('upload_button').click()
  // }

  const toggleAmenitySelection = (amenitiesId) => {
    let updatedAmenities

    if (selectedAmenities.includes(amenitiesId)) {
      updatedAmenities = selectedAmenities.filter((id) => id !== amenitiesId)
    } else {
      updatedAmenities = [...selectedAmenities, amenitiesId]
    }

    setSelectedAmenities(updatedAmenities)

    setInputFields({
      ...inputFields,
      amenities: updatedAmenities
    })
  }

  // api call

  const countryListData = () => {
    APICall('get', '', `${EndPoints.countryList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCountryList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const provinceListData = (data) => {
    APICall('post', data, `${EndPoints.provinceList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsProvinceList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cityListData = (data) => {
    APICall('post', data, `${EndPoints.cityList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCityListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const hotelAmenitiesListData = () => {
    APICall('post', {}, `${EndPoints.hotelAmenities}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsHotelAmenitiesListData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const hotelAddApiCall = () => {
    let payload = {
      name: inputFields.name,
      description: inputFields.description,
      address: inputFields.address,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      amenities: inputFields.amenities,
      min_price: inputFields.min_price,
      max_price: inputFields.max_price,
      logo: inputFields.logo,
      images: inputFields.images,
      contact_name: [
        inputFields.contact_name1,
        inputFields.contact_name2
      ].filter((item) => item !== '' && item !== null),
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ].filter((item) => item !== '' && item !== null),
      contact_phone: [
        inputFields.contact_phone1,
        inputFields.contact_phone2
      ].filter((item) => item !== '' && item !== null),
      contact_profile_pic: inputFields.contact_profile_pic,
      hotel_id: inputFields.hotel_id,
      ...(isFromHotelComponent && { app_id: [platformData[0]] })
    }

    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) => !(
            value === '' ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0)
          )
      )
    )

    if (Object.keys(defaultData).length > 0) {
      APICall('post', payload, `${EndPoints.editHotel}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            toast.success(res?.data?.message)
            // particularHotelApiCall(inputFields.hotel_id)
            setTimeout(() => {
              apiSuccess()
            }, 3000)
            setCurrentStep(0)
            setIsShowHotelList(true)
            setIsShow(false)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.addHotel}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              apiSuccess()
              if (isFromHotelComponent) {
                navigate(`/local-builder/${id}`)
              } else {
                navigate('/hotels')
              }
            }, 3000)
            setCurrentStep(0)
            toast.success(res?.data?.message)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
    setIsStep5Modified(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageHotel + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const getCountryName = (country_id) => {
    const categoryObj = isCountryList.find(
      (cat) => cat.country_id === country_id
    )
    return categoryObj ? categoryObj.country_name : English.G408
  }
  const getProvinceName = (province_id) => {
    const categoryObj = isProvinceList.find(
      (cat) => cat.province_id === province_id
    )
    return categoryObj ? categoryObj.province_name : English.G409
  }
  const getCityName = (city_id) => {
    const categoryObj = isCityListData.find((cat) => cat.city_id === city_id)
    return categoryObj ? categoryObj.city_name : English.G272
  }

  useEffect(() => {
    countryListData()
  }, [])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      provinceListData({
        country_id: defaultData.country_id
      })
      cityListData({
        province_id: defaultData.province_id
      })
      setSelectedAmenities(defaultData.amenities)
    }
  }, [defaultData])

  useEffect(() => {
    setIsStep1Modified(
      inputFields.name !== defaultData.name ||
        inputFields.country_id !== defaultData.country_id ||
        inputFields.province_id !== defaultData.province_id ||
        inputFields.city_id !== defaultData.city_id ||
        inputFields.zip_code !== defaultData.zip_code ||
        inputFields.website !== defaultData.website
    )
    setIsStep2Modified(
      inputFields.logo !== defaultData.logo ||
        inputFields.min_price !== defaultData.min_price ||
        inputFields.max_price !== defaultData.max_price ||
        inputFields.description !== defaultData.description
    )
    setIsStep3Modified(inputFields.amenities !== defaultData.amenities)
    setIsStep4Modified(
      inputFields.contact_profile_pic !== defaultData.contact_profile_pic ||
        inputFields.contact_name1 !== defaultData.contact_name1 ||
        inputFields.contact_name2 !== defaultData.contact_name2 ||
        inputFields.contact_designation1 !== defaultData.contact_designation1 ||
        inputFields.contact_designation2 !== defaultData.contact_designation2 ||
        inputFields.contact_phone1 !== defaultData.contact_phone1 ||
        inputFields.contact_phone2 !== defaultData.contact_phone2
    )
    setIsStep5Modified(
      inputFields?.images?.length !== defaultData?.images?.length
    )
  }, [inputFields])

  const isStep1Valid = () => {
    return (
      isStep1Modified &&
      inputFields.name &&
      inputFields.country_id &&
      inputFields.province_id &&
      inputFields.city_id &&
      inputFields?.zip_code?.length > 4 &&
      inputFields.website &&
      markerPosition
    )
  }

  const isStep2Valid = () => {
    return (
      isStep2Modified &&
      inputFields.logo &&
      inputFields.min_price &&
      inputFields.max_price &&
      inputFields.description
    )
  }

  const isStep3Valid = () => {
    return isStep3Modified && inputFields.amenities.length > 0
  }

  const isStep4Valid = () => {
    return (
      isStep4Modified &&
      inputFields.contact_profile_pic.length === 2 &&
      inputFields.contact_name1 &&
      inputFields.contact_name2 &&
      inputFields.contact_designation1 &&
      inputFields.contact_designation2 &&
      inputFields.contact_phone1.length > 10 &&
      inputFields.contact_phone2.length > 10
    )
  }
  // const isStep5Valid = () => {
  //   return isStep5Modified && inputFields.images.length > 0
  // }

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  const clearCurrentStepData = (step) => {
    switch (step) {
      case 2:
        setInputFields({
          ...inputFields,
          logo: '',
          min_price: '',
          max_price: '',
          description: ''
        })
        break
      case 3:
        if (!editHotel) {
          setSelectedAmenities([])
        } else {
          setSelectedAmenities(defaultData.amenities)
        }
        break
      case 4:
        setInputFields({
          ...inputFields,
          contact_name1: '',
          contact_designation1: '',
          contact_phone1: '',
          contact_name2: '',
          contact_designation2: '',
          contact_phone2: '',
          contact_profile_pic: ['', '']
        })
        break
      case 5:
        const hasImages = inputFields.images?.length > 0
        setInputFields({
          ...inputFields,
          images: []
        })
        if (hasImages) {
          setImagesCleared(true)
        } else {
          hotelAddApiCall()
        }
        break
      default:
        break
    }
  }

  const handleSkipClick = () => {
    if (currentStep >= 2 && currentStep <= 5) {
      clearCurrentStepData(currentStep)
      if (currentStep === 3) {
        if (!editHotel) {
          setSelectedAmenities([])
        } else {
          setSelectedAmenities(defaultData.amenities)
        }
      }
      if (currentStep === 3 && editHotel) {
        setCurrentStep(5)
      } else if (currentStep < 5) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  useEffect(() => {
    if (imagesCleared) {
      hotelAddApiCall()
      setImagesCleared(false)
    }
  }, [imagesCleared])

  const isStep5Valid = () => {
    const hasImagesChanged =
      JSON.stringify(inputFields.images) !== JSON.stringify(initialImages)

    return hasImagesChanged && inputFields.images.length > 0
  }
  useEffect(() => {
    setInitialImages(defaultData.images || [])
  }, [])

  return (
    <div className="content-manager">
      {currentStep === 1 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal !max-w-[1200px]">
            <div className="flex items-center justify-between">
              {/* {step === 0 && <div>Select local type</div>} */}
              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                <img onClick={handleBackClick} src={Images.BackAerrow} alt="" />
                Add Location
              </div>
              <button className="cancel_icon border-0 p-0" type="button" onClick={handleCancels}>
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-30">
              <div className="text-main test-base font-bold">
                Location Details
              </div>
              <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
                <span className="font-black text-xl leading-none italic">02</span>
                of 04
              </div>
            </div>
            <div className="item-details-input !pt-5">
              <div className="grid grid-cols-12 !gap-5">
                <div className="col-span-5">
                  <div className="">
                    <label>Location Title</label>
                    <CommonInput
                      placeholder="Type Location Title here"
                      name="name"
                      onChange={onChange}
                      allowTextOnly
                      maxLength={30}
                      value={capitalizeFirstLetter(inputFields.name)}
                    />
                  </div>
                  <div className="!mb-5">
                    <label>{English.G309}</label>
                    <Dropdown
                      title={getCountryName(inputFields.country_id)}
                      name="type_id"
                      onChanged={(d) => {
                        provinceListData({
                          country_id: d.id
                        })
                        setInputFields((prev) => ({
                          ...prev,
                          country_id: d.id,
                          country: d.title
                        }))
                      }}
                      Data={isCountryList.map((d) => ({
                        title: d.country_name,
                        id: d.country_id
                      }))}
                      customClass={
                        inputFields.country_id
                          ? 'selected-class'
                          : 'local-dropdown'
                      }
                    />
                  </div>
                  <LoadScriptNext
                    googleMapsApiKey={locationSuggestion}
                    libraries={GOOGLE_MAP_LIBRARIES}
                  >
                    <div className="form-group">
                      <label>{English.G187}</label>
                      <AutocompleteInput
                        setInputFields={setInputFields}
                        inputFields={inputFields}
                      />
                    </div>
                  </LoadScriptNext>
                  <div className="!mb-5">
                    <div>
                      <label>{English.G311}</label>
                      <Dropdown
                        title={getProvinceName(inputFields.province_id)}
                        name="type_id"
                        onChanged={(d) => {
                          cityListData({
                            province_id: d.id
                          })
                          setInputFields((prev) => ({
                            ...prev,
                            province_id: d.id,
                            province_name: d.title,
                            city: '',
                            city_name: ''
                          }))
                        }}
                        Data={isProvinceList.map((d) => ({
                          title: d.province_name,
                          id: d.province_id
                        }))}
                        customClass={
                          inputFields.province_id
                            ? 'selected-class'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <div>
                      <label>City</label>
                      <Dropdown
                        title={getCityName(inputFields.city_id)}
                        name="type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            city_id: d.id,
                            city_name: d.title
                          })
                        }}
                        Data={isCityListData.map((d) => ({
                          title: d.city_name,
                          id: d.city_id
                        }))}
                        customClass={
                          inputFields.city_id ? 'selected-class' : 'local-dropdown'
                        }
                      />
                    </div>
                  </div>
                  <div className="!mt-5">
                    <label>{English.G313}</label>
                    <CommonInput
                      placeholder={English.G91}
                      name="zip_code"
                      type="number"
                      className="mb-0"
                      onChange={onChange}
                      pattern="^\d{0,5}$"
                      maxLength={5}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault()
                        }
                        if (e.target.value.length === 5) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.zip_code}
                    />
                    <div className="!mt-5">
                      <label>{English.G314}</label>
                      <CommonInput
                        placeholder={English.G315}
                        name="website"
                        onChange={onChange}
                        value={inputFields.website}
                      />
                      {errors && <div className="error-message">{errors}</div>}
                    </div>
                  </div>
                </div>
                <div className="col-span-7">
                  <div className="form-group h-full rounded-[15px] overflow-hidden">
                    <MapComponent
                      inputFields={inputFields}
                      setInputFields={setInputFields}
                      onMapPositionChange={handleMapPositionChange}
                      markerPosition={markerPosition}
                    />
                  </div>
                </div>
              </div>
              <div className="local_button_row local_sticky_btn mb-0 mt-0">
                <CommonButton
                  label={English.G116}
                  onClick={handleCancelStep1}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={handleStartStep2}
                  context={English.G232}
                  disabled={!isStep1Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal !max-w-[1200px]">
            <div className="flex items-center justify-between">
              {/* {step === 0 && <div>Select local type</div>} */}
              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                <img onClick={handleBackClick} src={Images.BackAerrow} alt="" />
                Add Location
              </div>
              <button className="cancel_icon border-0 p-0" type="button" onClick={handleCancels}>
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-30">
              <div className="text-main test-base font-bold">
                Hotel Details
              </div>
              <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
                <span className="font-black text-xl leading-none italic">03</span>
                of 04
              </div>
            </div>
            <div className="item-details-input pt-0 !mt-5">
              <div className="logo_btn mb-2">
                <div className="add_logo">
                  <CommonImageUpload
                    id="upload_button"
                    name="local_upload_logo"
                    handleImageChange={(file) => onUploadImage(file, 'logo')}
                    index={0}
                    forImage="local_logo"
                    className="profile-upload mb-0"
                    selectedImg={
                      inputFields.logo
                        ? `${s3ImageHotel}${inputFields.logo}`
                        : ''
                    }
                    defaultImg={Images.local_add_img}
                  />
                </div>
                <div className="upload_btn_logo">
                  {/* <CommonButton
                    label={English.G331}
                    context="local"
                    className="local-secondary-button mb-2"
                    onClick={handleClick}
                  /> */}
                  <p className="image_validation mb-0">
                    {English.G317}
                    <br />
                    {English.G318}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 !gap-5">
                <div>
                  <div>
                    <label>Hotel Name</label>
                    <CommonInput
                      placeholder="Type Name"
                    />
                  </div>
                  <div>
                    <label>Price Range</label>
                    <CommonInput
                      placeholder="€€ - €€€ / Night"
                    />
                  </div>
                  <div className="">
                    <div>
                      <label>{English.G319}</label>
                      <CommonInput
                        placeholder={English.G320}
                        name="min_price"
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={4}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 4) {
                            e.preventDefault()
                          }
                        }}
                        type="number"
                        onChange={onChange}
                        onBlur={validatePrices}
                        value={inputFields.min_price}
                      />
                    </div>
                    <div>
                      <label>{English.G321}</label>
                      <CommonInput
                        placeholder={English.G322}
                        name="max_price"
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={4}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 4) {
                            e.preventDefault()
                          }
                        }}
                        onChange={onChange}
                        onBlur={validatePrices}
                        value={inputFields.max_price}
                        className={`${error.max_price ? 'mb-0' : 'mb-2'}`}
                      />
                      {error.max_price && (
                        <span className="error-message mt-0">
                          {error.max_price}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Check In</label>
                      <Dropdown
                        title="Select from options"
                        name="type_id"
                        onChanged={() => {}}
                        Data={isProvinceList.map((d) => ({
                          title: d.province_name,
                          id: d.province_id
                        }))}
                        customClass={
                          inputFields.province_id
                            ? 'selected-class'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <div>
                      <label>Check Out</label>
                      <Dropdown
                        title="Select from options"
                        name="type_id"
                        onChanged={() => {}}
                        Data={isProvinceList.map((d) => ({
                          title: d.province_name,
                          id: d.province_id
                        }))}
                        customClass={
                          inputFields.province_id
                            ? 'selected-class'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="input-grid !mb-5">
                    <div>
                      <label>Room Type</label>
                      <Dropdown
                        title="Select from options"
                        name="type_id"
                        onChanged={() => {}}
                        Data={isProvinceList.map((d) => ({
                          title: d.province_name,
                          id: d.province_id
                        }))}
                        customClass={
                          inputFields.province_id
                            ? 'selected-class'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <div>
                      <label>Bed Options</label>
                      <Dropdown
                        title="Select from options"
                        name="type_id"
                        onChanged={() => {}}
                        Data={isProvinceList.map((d) => ({
                          title: d.province_name,
                          id: d.province_id
                        }))}
                        customClass={
                          inputFields.province_id
                            ? 'selected-class'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label>Website / Booking Link</label>
                    <CommonInput
                      placeholder="http://"
                    />
                  </div>
                  <div className="">
                    <div className="local_event_description">
                      <label>{English.G112}</label>
                      <CommonInput
                        placeholder={English.G326}
                        name="description"
                        onChange={onChange}
                        maxLength={350}
                        value={capitalizeFirstLetter(inputFields.description)}
                        isTextArea
                        className="mb-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="local_button_row">
                <CommonButton
                  label={English.G333}
                  // onClick={handleNextClick}
                  onClick={handleSkipClick}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={handleNextClick}
                  context={English.G232}
                  disabled={!isStep2Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal !max-w-[1200px]">
            <div className="flex items-center justify-between">
              {/* {step === 0 && <div>Select local type</div>} */}
              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                <img onClick={handleBackClick} src={Images.BackAerrow} alt="" />
                Add Location
              </div>
              <button className="cancel_icon border-0 p-0" type="button" onClick={handleCancels}>
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-30">
              <div className="text-main test-base font-bold">
                Hotel Details
              </div>
              <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
                <span className="font-black text-xl leading-none italic">04</span>
                of 04
              </div>
            </div>
            <div className="!mt-5 rounded-[15px] border border-BorderLight !p-5">
              <h6 className="font-bold text-base text-Secondary leading-none !mb-5">Amenities</h6>
              <div className="tool-option drop-search-3-0 !mb-5">
                <CommonInput
                  className="w-100 search-input disabledDropdown"
                  placeholder="Search..."
                />
              </div>
              <div className="amenities_name">
                {isHotelAmenitiesListData.map((amenity) => (
                  <div
                    key={amenity.amenities_id}
                    className={`type_amenities ${selectedAmenities.includes(amenity.amenities_id) ? 'selected' : ''}`}
                    onClick={() => toggleAmenitySelection(amenity.amenities_id)}
                  >
                    <img
                      className="hotel_amenities"
                      src={s3ImageLocalIcon + amenity.icon}
                      alt={amenity.name}
                    />
                    <h6 className="text-Main text-sm font-semibold">{amenity.name}</h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="local_button_row">
              <CommonButton
                label={English.G333}
                // onClick={handleNextClick}
                onClick={handleSkipClick}
                isPrimary={false}
                context={English.G232}
              />
              <CommonButton
                label={English.G69}
                type="button"
                isPrimary
                onClick={handleNextClick}
                context={English.G232}
                disabled={!isStep3Valid()}
              />
            </div>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal !max-w-[1200px]">
            <div className="flex items-center justify-between">
              {/* {step === 0 && <div>Select local type</div>} */}
              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                <img src={Images.BackAerrow} alt="" />
                Add Location
              </div>
              <button className="cancel_icon border-0 p-0" type="button" onClick={handleCancels}>
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-30">
              <div className="text-main test-base font-bold">
                Hotel Details
              </div>
              <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
                <span className="font-black text-xl leading-none italic">04</span>
                of 04
              </div>
            </div>
            <div className="item-details-input pt-0 !mt-5">
              <div className="grid grid-cols-2 !gap-5">
                <div>
                  <div className="logo_btn mb-2.5">
                    <div className="image_upload_section">
                      <div className="add_photos">
                        <div className="add_logo_text text-center align-self-center">
                          <CommonImageUpload
                            name="local_upload_logo"
                            handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                            index={0}
                            forImage={English.G290}
                            className="profile-upload"
                            selectedImg={
                              inputFields.contact_profile_pic[0]
                                ? `${s3ImageHotel}${inputFields.contact_profile_pic[0]}`
                                : ''
                            }
                            defaultImg={Images.local_add_img}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="upload_btn_logo">
                      <p className="image_validation mb-0">
                        {English.G317}
                        <br />
                        {English.G318}
                      </p>
                    </div>
                  </div>
                  <div className="person_detail_section">
                    <div className="input-grid">
                      <div>
                        <label>{English.G130}</label>
                        <CommonInput
                          placeholder={English.G130}
                          onChange={onChange}
                          name="contact_name1"
                          maxLength={20}
                          allowTextOnly
                          value={capitalizeFirstLetter(inputFields.contact_name1)}
                        />
                      </div>
                      <div>
                        <label>{English.G291}</label>
                        <CommonInput
                          placeholder={English.G291}
                          onChange={onChange}
                          name="contact_designation1"
                          allowTextOnly
                          maxLength={20}
                          value={capitalizeFirstLetter(
                            inputFields.contact_designation1
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label>{English.G186}</label>
                      <CommonInput
                        placeholder={English.G292}
                        name="contact_phone1"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress={ForMobileNumber}
                        type="text"
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /[^0-9+]/g,
                            ''
                          )
                          const maxLength = sanitizedValue.startsWith('+')
                            ? 13
                            : 12
                          if (sanitizedValue.length <= maxLength) {
                            onChange(e)
                          }
                        }}
                        value={inputFields.contact_phone1}
                      />
                    </div>
                  </div>
                  <div className="logo_btn mb-2.5">
                    <div className="image_upload_section">
                      <div className="add_photos">
                        <div className="add_logo_text text-center align-self-center">
                          <CommonImageUpload
                            name="local_upload_logo"
                            handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 1)}
                            index={1}
                            forImage={English.G290}
                            className="profile-upload"
                            selectedImg={
                              inputFields.contact_profile_pic[1]
                                ? `${s3ImageHotel}${inputFields.contact_profile_pic[1]}`
                                : ''
                            }
                            defaultImg={Images.local_add_img}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="upload_btn_logo">
                      <p className="image_validation mb-0">
                        {English.G317}
                        <br />
                        {English.G318}
                      </p>
                    </div>
                  </div>
                  <div className="person_detail_section">
                    <div className="input-grid">
                      <div>
                        <label>{English.G130}</label>
                        <CommonInput
                          placeholder={English.G130}
                          onChange={onChange}
                          name="contact_name2"
                          allowTextOnly
                          maxLength={20}
                          value={capitalizeFirstLetter(inputFields.contact_name2)}
                        />
                      </div>
                      <div>
                        <label>{English.G291}</label>
                        <CommonInput
                          placeholder={English.G291}
                          onChange={onChange}
                          name="contact_designation2"
                          allowTextOnly
                          maxLength={20}
                          value={capitalizeFirstLetter(
                            inputFields.contact_designation2
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label>{English.G186}</label>
                      <CommonInput
                        placeholder={English.G292}
                        name="contact_phone2"
                        type="text"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress={ForMobileNumber}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /[^0-9+]/g,
                            ''
                          )
                          const maxLength = sanitizedValue.startsWith('+')
                            ? 13
                            : 12
                          if (sanitizedValue.length <= maxLength) {
                            onChange(e)
                          }
                        }}
                        value={inputFields.contact_phone2}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-[#FAFAFA] border-[0.5px] border-[#bab8b8] border-dashed rounded-[15px] flex items-center justify-center">
                  <div className="text-center">
                    <div>
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                        index={0}
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[0]
                          ? `${s3ImageHotel}${inputFields.contact_profile_pic[0]}`
                          : ''
                        }
                        defaultImg={Images.FileUpload}
                      />
                    </div>
                    <h6 className="text-xl font-black italic uppercase">Upload Image</h6>
                    <h5 className="text-[#7A7474] text-sm max-w-[350px] mx-auto mb-8">Recommended: “.JPG”  (1400 x 1400 pixels) Other major formats are supported with aspect ratio of 1:1</h5>
                    <div className="text-center">
                      <button type="button" className="mx-auto text-white flex items-center gap-2.5 text-sm italic font-black !bg-primaryGradient !rounded-[999px]">
                        <img src={Images.plusrounded} alt="" />
                        SELECT Image
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label={English.G333}
                  // onClick={handleNextClick}
                  onClick={handleSkipClick}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={handleNextClick}
                  context={English.G232}
                  disabled={!isStep4Valid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 5 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Images.BackLocal}
                  className="back_btn"
                  alt="back"
                  onClick={handleBackClick}
                />
                {English.G412}
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="hotel_image_upload_section">
              <div className="input-image-show image_local_last_popup">
                {renderImg}
                {inputFields.images.length < 4 && (
                  <div className="input-img-label">
                    <CommonImageUpload
                      handleImageChange={(file) => onUploadImage(file, 'images', inputFields.images.length)}
                      buttonText="Add Image"
                      selectedImg=""
                      defaultImg={Images.plusGiveaway}
                      className="plsimg"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="local_button_row">
              <CommonButton
                label={English.G333}
                // onClick={handleNextClick}
                onClick={handleSkipClick}
                isPrimary={false}
                context={English.G232}
              />
              <CommonButton
                label={English.G69}
                type="button"
                isPrimary
                onClick={handleNextClick}
                context={English.G232}
                disabled={!isStep5Valid()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default hotelPopup
