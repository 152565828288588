import React from 'react'
import './profile.css'
import { Link } from 'react-router-dom'
import Images from '../../helpers/Images'

const ProfileHead = () => {
  const BannerStyle = {
    background: 'url("./images/profilecover.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  const ProfileStyle = {
    background: 'url("./images/main-profile.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '158px',
    height: '158px',
    borderRadius: '60px',
    border: '2px solid  #001C93',
    boxShadow: '0px 0px 8.103px 0px rgba(0, 0, 0, 0.15)',
    marginTop: '-30px'
  }

  return (
    <div className="profile-head">
      <div className="profile-banner" style={BannerStyle} />
      <div className="profile-detail profile-detail-main">
        <div className="profile-head-left">
          <div style={ProfileStyle} />
          <div>
            <ul className="profile-detail">
              <li className="name">Eleanor Pena</li>
              <li className="tag">@eleanor</li>
            </ul>
            <ul className="profile-count">
              <li className="people">
                <img className="me-2" src={Images.FollowUser} alt="" />
                20k
              </li>
              <li className="creator">
                <img className="me-2" src={Images.Creators} alt="" />
                10k
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-button-group">
          <button type="button" className="primary-btn">
            <img className="me-2" src={Images.PlusIco} alt="" />
            Add Story
          </button>
          <Link to="/profiles">
            <button type="button" className="secondary-btn">
              <img className="me-2" src={Images.EditIco} alt="" />
              Edit Profile
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default ProfileHead
