import React from 'react'
import EventComponent from './EventComponent'

const AddEvent = () => {
  return (
    <div className="content-manager-awards-wrapper common-card-body add-profile-video">
      <EventComponent showCount={3} clickable={false} />
    </div>
  )
}
  export default AddEvent
