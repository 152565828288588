/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import SideBarPage from './SidebarPage'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { s3ImageProfile } from '../../services/Service'
import Utility, {
  preventSpaceInput,
  preventSpacePaste
} from '../../helpers/Utility'
import English from '../../helpers/English'
import { dataDecrypt, setUserSliceData } from '../../services/http-services'
import Images from '../../helpers/Images'
import { Logout } from '../../redux/UserSlice/UserSlice'
import { APICall, EndPoints } from '../../services'

const ReLogin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const UserData = useSelector((state) => state.user?.userData)
  const [showPassword1, setShowPassword1] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputFields, setInputFields] = useState({
    email: '',
    password: '',
    app_id: 2,
    is_gbn: false
  })
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [stayLoggedIn, setStayLoggedIn] = useState(false)

  const divStyle = {
    background: `url(${s3ImageProfile + UserData?.profile_image || ''}) lightgray 50% / cover no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsButtonEnabled(inputFields.password.length > 0)
  }

  const ForgotClick = () => {
    dispatch(Logout())
    navigate('/forget-password')
  }

  const loginApi = (e) => {
    e.preventDefault()
    setLoading(true)
    const data = {
      email: UserData?.email,
      password: inputFields.password,
      app_id: 2,
      is_gbn: false
    }
    APICall('post', data, EndPoints.login, 'auth')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const tokenData = Utility.deepClone(res?.data?.token)
          setUserSliceData(tokenData, 'token')
          setUserSliceData(stayLoggedIn, 'stay_login')
          const decryptedData = dataDecrypt(res.data.data)
          if (decryptedData.user_type_id === 0) {
            navigate('/create-profile')
          } else {
            navigate('/dashboard')
          }
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const onClickSwitch = (e) => {
    e.preventDefault()
    dispatch(Logout())
    navigate('/login')
    // toast.success('User logged out successfully')
  }

  useEffect(() => {
    setIsButtonEnabled(inputFields.password.length > 0)
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient-new">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 overflow-y-auto no-scrollbar">
          <div className="d-flex align-items-center col-gap-10 cursor-pointer pt-5 px-5" onClick={(e) => onClickSwitch(e)}>
            <img src={Images.Black_back} alt="back" className="w-5 h-5" />
          </div>
          <div className="inline-padding pt-3">
            <h2 className="bg-[#151515] text-center login_sign_text mb-0">
              {English.A40}
            </h2>
            <h4 className="bg-[#303030] text-center mb-6 bg-transparent text-[24px] font-black italic">
              {English.A41}
            </h4>
            <div className="login-img" style={divStyle} />
            <div className="profile-full-name text-center">
              <p className="m-0 clip-text">
                {UserData?.user_name}
                <span>
                  <img src={Images.Verify_icon} alt="" />
                </span>
              </p>
              {English.A42}
              {' '}
              <Link className="clip-text" onClick={(e) => onClickSwitch(e)}>
                {English.G43}
              </Link>
            </div>
            <form action="/dashboard">
              <p>
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="password"
                    className="input-logo"
                  />
                  <CommonInput
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder={English.A21}
                    required
                    onChange={handleChange}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                    className="custom-input"
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={Images.eyeOn} />
                    ) : (
                      <img alt="" src={Images.eyeOff} />
                    )}
                  </button>
                </div>
              </p>
              <p className="d-flex justify-between items-center">
                <div>
                  <p className="stay_login d-flex gap-2 mb-0">
                    <div className="flex items-center">
                      <input
                        id="inline-2-checkbox"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={stayLoggedIn}
                        onChange={(e) => setStayLoggedIn(e.target.checked)}
                      />
                    </div>
                    {English.A26}
                  </p>
                </div>
                <NavLink
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    ForgotClick()
                  }}
                  className="clip-text text-end"
                >
                  {English.A23}
                </NavLink>
              </p>
              <p className="mt-5">
                <CommonButton
                  label={English.A22}
                  type="button"
                  id="sub_btn"
                  loading={loading}
                  onClick={(e) => loginApi(e)}
                  disabled={!isButtonEnabled || loading}
                  className="primary-button m-auto"
                  context="primary"
                  isPrimary
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ReLogin
