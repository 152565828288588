import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
// import Images from '../../helpers/Images'

const Courses = () => {
  // const items = [
  //   {
  //     CoverImage: './images/courses1.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  //     days: '10 days'
  //   },
  //   {
  //     CoverImage: './images/courses2.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  //     days: '10 days'
  //   },
  //   {
  //     CoverImage: './images/courses3.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  //     days: '10 days'
  //   },
  //   {
  //     CoverImage: './images/courses1.png',
  //     TimeDuration: '10:20',
  //     Title: 'Health',
  //     Rating: '4.9',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  //     days: '10 days'
  //   }
  // ]
  return (
    <div className="content-manager-courses-wrapper common-card-body">
      <div className="courses-wrapper-header common-contnt-wrapper">
        <h2>{English.G440}</h2>
        <Link>{English.G248}</Link>
      </div>
      <div className="courses-item">
        {/* {items.map((item) => (
          <div className="video-item-detail" key={item.name}>
            <div className="video-item">
              <div
                className="video-cover"
                style={{
                  background: `url(${item.CoverImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <button type="button" className="drop-button">
                  <img alt="" className="d-block" src={Images.MoreHorizontal} />
                </button>
                <p className="time-duration">{item.TimeDuration}</p>
              </div>
              <div className="video-details">
                <div className="d-flex justify-content-between">
                  <h6>
                    <img alt="" src={Images.HealthIco} />
                    <span>{item.Title}</span>
                  </h6>
                  <div className="rating">
                    <img alt="" src={Images.RatingStar} />
                    <span>{item.Rating}</span>
                  </div>
                </div>
                <p>{item.text}</p>
                <div className="other-info">
                  <span>
                    <img alt="" src={Images.ClockFillIco} />
                    {item.days}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  )
}
export default Courses
