import React, { useState } from 'react'
import './style.css'
import ReactDatePicker from 'react-datepicker'
import ContactInfo from '../ContactInfo/ContactInfo'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Appointment = () => {
  const [startDate, setStartDate] = useState(new Date())
  return (
    <div className="appointment-container">
      <button type="button" className="d-flex align-items-center col-gap-20 primary-btn w-400 justify-content-center ms-auto">
        <img alt="" src={Images.CelenderIco} />
        Book Now
      </button>
      <div className="appointment-contact mt-20">
        <ContactInfo />
      </div>
      <div className="services-container common-card-body recent-video-component mt-40">
        <h3>Book An Appointment Online</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin iaculis
          morbi tempus volutpat tortor. Ipsum vitae enim sit suscipit in
          ridiculus. Morbi potenti eu facilisis vitae posuere suspendisse.
          Praesent sit lorem amet posuere vel diam scelerisque a. Nisl imperdiet
          egestas dui consequat, diam et, at tempor.
        </p>
        <form className="item-details-input">
          <div className="input-grid">
            <div>
              <label>Your Name</label>
              <input placeholder="Enter name" />
            </div>
            <div>
              <label>Email</label>
              <input placeholder="e.g michelle.rivera@example.com" />
            </div>
          </div>
          <div className="input-grid">
            <div>
              <label>{English.G186}</label>
              <input placeholder="Enter phone number" />
            </div>
            <div>
              <label>{English.G187}</label>
              <input placeholder={English.G62} />
            </div>
          </div>
          <div className="input-grid">
            <div>
              <label>Appointment Date</label>
              <div className="position-relative d-content date-label">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd MMMM yyyy"
                />
              </div>
            </div>
            <div>
              <label>Preferred Time</label>
              <div className="cooking-time-filed position-relative">
                <input placeholder={English.G181} />
              </div>
            </div>
          </div>
          <div>
            <label>Your Message</label>
            <textarea placeholder="Type here..." />
          </div>
          <button type="button" className="d-flex align-items-center col-gap-20 primary-btn w-400 justify-content-center">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
export default Appointment
