/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'
import '../../../App.css'

const WorkSpaceScreen = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState('all')
  const initialApps = [
    {
      id: 1,
      text: 'TASK NAME'
    },
    {
      id: 2,
      text: 'TASK NAME'
    },
    {
      id: 3,
      text: 'TASK NAME'
    }
  ]
  // const [appointments, setAppointments] = useState([])

  // useEffect(() => {
  //   const generateWeeklyAppointments = () => {
  //     const today = new Date()
  //     const startOfWeek = new Date(today)
  //     startOfWeek.setDate(today.getDate() - today.getDay())

  //     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  //     const appointmentsData = Array.from({ length: 7 }).map((_, index) => {
  //       const currentDay = new Date(startOfWeek)
  //       currentDay.setDate(startOfWeek.getDate() + index)

  //       return {
  //         day: daysOfWeek[index],
  //         date: currentDay.getDate(),
  //         isActive: today.getDate() === currentDay.getDate()
  //       }
  //     })

  //     setAppointments(appointmentsData)
  //   }

  //   generateWeeklyAppointments()
  // }, [])

  // const visibleAppointments = appointments.slice(
  //   Math.max(currentIndex - 3, 0),
  //   Math.min(currentIndex + 4, appointments.length)
  // )

  const [visibleDates, setVisibleDates] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)

  const daysToShow = 7

  const formatWeekday = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long' })
  }

  const formatDay = (date) => {
    return date.getDate()
  }

  const updateVisibleDates = (startDate) => {
    const dates = []
    for (let i = 0; i < daysToShow; i++) {
      const newDate = new Date(startDate)
      newDate.setDate(startDate.getDate() + i)
      dates.push(newDate)
    }
    setVisibleDates(dates)
  }

  useEffect(() => {
    updateVisibleDates(new Date())
  }, [])

  const handlePrev = () => {
    const newStartDate = new Date(visibleDates[0])
    newStartDate.setDate(newStartDate.getDate() - 1)
    updateVisibleDates(newStartDate)
  }

  const handleNext = () => {
    const newStartDate = new Date(visibleDates[0])
    newStartDate.setDate(newStartDate.getDate() + 1)
    updateVisibleDates(newStartDate)
  }

  const handleDateSelect = (date) => {
    setSelectedDate(date)
  }

  return (
    <div className="dashboard">
      <div className="p-[20px] pt-0">
        <div className="p-0 bg-gray-50 ">
          <p className="text-xs font-medium text-[#0B0A0A]">
            <span
              className="text-sm font-medium cursor-pointer"
              onClick={() => navigate('/dashboard')}
            >
              Home
            </span>
            /
            <span className="text-sm font-bold">Workspace</span>
          </p>
          <div className="flex justify-between items-center mb-[20px]">
            <div className="text-gradient font-extrabold pr-1 text-[34px] italic uppercase">
              {English.A153}
            </div>
          </div>
        </div>
        <div className="flex w-100 gap-4 mb-5">
          <div className="w-[70%] h-[367px]">
            <div className="flex-row bg-white rounded-4 !p-[20px] shadow-sm">
              <div className="flex justify-between items-center">
                <div className="bg-primaryGradient font-black pr-1 !pb-5 text-3xl italic bg-clip-text text-transparent uppercase flex gap-2">
                  DECEMBER 2024
                  <img src={Images.calendar} alt="" />
                </div>

                <div className="flex space-x-4 mb-6 border border-[#b8b8b8] rounded-full py-2 px-3">
                  <button
                    onClick={() => setFilter('all')}
                    className={`px-2 py-[8px] rounded-md ${
                      filter === 'all'
                        ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                        : 'text-gradient text-base italic font-extrabold'
                    }`}
                    type="button"
                  >
                    {English.A154}
                  </button>
                  <button
                    type="button"
                    onClick={() => setFilter('active')}
                    className={`px-4 py-[8px] rounded-md ${
                      filter === 'active'
                        ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                        : 'text-gradient text-base italic font-extrabold'
                    }`}
                  >
                    {English.A155}
                  </button>
                  <button
                    onClick={() => setFilter('inactive')}
                    type="button"
                    className={`px-4 py-[8px] rounded-md ${
                      filter === 'inactive'
                        ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                        : 'text-gradient text-base italic font-extrabold'
                    }`}
                  >
                    {English.A156}
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center gap-4">
                <div className="flex items-center">
                  <div className="bg-gradients rounded-md">
                    <button
                      onClick={handlePrev}
                      type="button"
                      className="px-1 py-1 text-white rounded hover:bg-blue-700"
                    >
                      ◀
                    </button>
                  </div>
                  <div className="flex gap-2">
                    {visibleDates.map((date, index) => {
                      const isSelected =
                        selectedDate &&
                        selectedDate.toDateString() === date.toDateString()
                      return (
                        <div
                          key={index}
                          className={`flex flex-col items-center w-[91.5px] h-[61px] px-[22px] py-2 border rounded cursor-pointer transition ${
                            isSelected
                              ? 'w-[110px] h-[72px] bg-gradients text-white font-bold'
                              : 'bg-gray-100 hover:bg-gray-200'
                          }`}
                          onClick={() => handleDateSelect(date)}
                        >
                          <div
                            className={`text-[14px] font-semibold ${
                              isSelected ? 'text-white' : 'text-[#777777]'
                            }`}
                          >
                            {formatWeekday(date)}
                          </div>
                          <div
                            className={`font-bold text-lg ${
                              isSelected ? 'text-white' : 'text-gradient'
                            }`}
                          >
                            {formatDay(date)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="bg-gradients rounded-md">
                    <button
                      onClick={handleNext}
                      type="button"
                      className="px-1 py-1 text-white rounded hover:bg-blue-700"
                    >
                      ▶
                    </button>
                  </div>
                </div>
                <div className="flex-row">
                  <div className="flex-row items-center gap-2 w-100 border border-[#5a5a5a] p-[20px] rounded-xl mb-6">
                    <div className="flex gap-1 items-center justify-between">
                      <h3 className="text-base font-semibold mb-0 text-[#303030]">
                        MEETING 1
                      </h3>
                      <div className="flex items-center gap-2">
                        <img src={Images.timer_icon} alt="" />
                        <p className="text-[#303030] text-xs font-semibold mb-0">
                          Oct 20 - Nov 20
                        </p>
                      </div>
                    </div>
                    <p className="mb-2 text-[#9E9E9E] font-medium text-xs">
                      Make a presentation about the marketing strategy for Q4.
                    </p>
                  </div>
                  <div className="flex-row items-center gap-2 w-100 border border-[#5a5a5a] p-[20px] rounded-xl mb-11">
                    <div className="flex gap-1 items-center justify-between">
                      <h3 className="text-base font-semibold mb-0 text-[#303030]">
                        MEETING 1
                      </h3>
                      <div className="flex items-center gap-2">
                        <img src={Images.timer_icon} alt="" />
                        <p className="text-[#303030] text-xs font-semibold mb-0">
                          Oct 20 - Nov 20
                        </p>
                      </div>
                    </div>
                    <p className="mb-2 text-[#9E9E9E] font-medium text-xs">
                      Make a presentation about the marketing strategy for Q4.
                    </p>
                  </div>
                  <div className="flex-row items-center gap-2 w-100 border border-[#5a5a5a] p-[20px] rounded-xl mb-6">
                    <div className="flex gap-1 items-center justify-between">
                      <h3 className="text-base font-semibold mb-0 text-[#303030]">
                        MEETING 1
                      </h3>
                      <div className="flex items-center gap-2">
                        <img src={Images.timer_icon} alt="" />
                        <p className="text-[#303030] text-xs font-semibold mb-0">
                          Oct 20 - Nov 20
                        </p>
                      </div>
                    </div>
                    <p className="mb-2 text-[#9E9E9E] font-medium text-xs">
                      Make a presentation about the marketing strategy for Q4.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[30%] h-[367px]">
            <div className="flex-row bg-white rounded-4 !p-[20px] shadow-sm">
              <div className="mt-3">
                <h4 className="font-extrabold text-2xl italic text-gradient uppercase">
                  {English.A152}
                </h4>

                {initialApps.map((i) => (
                  <div
                    key={i.id}
                    className="w-[334px] h-auto p-[16px] rounded-2xl bg-white border border-[#E4E4E4] mb-[12px]"
                  >
                    <div className="flex flex-1 flex-col">
                      <div className="text-gradient font-extrabold text-base italic mb-[12px]">
                        {i.text}
                      </div>
                      <p className="text-[#9E9E9E] text-xs font-medium mb-[12px]">
                        Make a presentation about the marketing strategy for Q4.
                        Make a presentation about the marketing strategy for Q4.
                      </p>
                      <div className="flex gap-3">
                        <div className="flex gap-1 ">
                          <img
                            className=""
                            src={Images.watch_round_arrow}
                            alt=""
                          />
                          <p className="mb-0 text-xs font-semibold">
                            4 hr, 30 min
                          </p>
                        </div>
                        <div className="flex gap-1">
                          <img className="" src={Images.timer_icon} alt="" />
                          <p className="mb-0 text-xs font-semibold w-[70px]">
                            Due Today
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkSpaceScreen
