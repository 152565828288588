import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'

const Workplaces = () => {
  const items = [
    {
      img: './images/workplace/1.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/2.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    },
    {
      img: './images/workplace/3.jpg',
      heading: 'ETHIOPIA',
      text: 'There are 3.7 million out-of-school children in Afghanistan — 60% are girls. (UNICEF)'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component ">
      <h3 className="d-flex align-items-center justify-content-between">
        Recent Workplaces
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="workplace-grid">
        {items.map((item) => (
          <div key={item.heading} className="workplace-item">
            <img alt="" src={item.img} />
            <div className="hover-content">
              <h4>{item.heading}</h4>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Workplaces
