import React from 'react'
import { Link } from 'react-router-dom'
import ProfileHead from './ProfileHead'
import Dropdown from '../Dropdown/Dropdown'
import PersonalTraining from '../../images/personal-training.png'
import Aerobics from '../../images/aerobics.png'
import Yoga from '../../images/yoga.png'
import Exercice from '../../images/exersice.png'
import Therapie from '../../images/therapir.png'
import Member1 from '../../images/member1.png'
import Member2 from '../../images/member2.png'
import Member3 from '../../images/member3.png'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Profile = () => {
  const VideoStyle = {
    background: 'url("./images/unsplash__pPHgeHz1uk.png")'
  }

  return (
    <div className="profile-wrapper">
      <ProfileHead />
      <div className="custome-select">
        <Dropdown />
      </div>
      <div className="card-body">
        <div className="navigation">
          <ul>
            <li>
              <Link>
                <img src={Images.StoreIco} alt="store icon" />
                {English.G224}
              </Link>
            </li>
            <li>
              <Link>
                <img src={Images.LocalIco} alt="local icon" />
                {English.G236}
              </Link>
            </li>
            <li>
              <Link>
                <img src={Images.ServiceIco} alt="service icon" />
                {English.G449}
              </Link>
            </li>
            <li>
              <Link>
                <img src={Images.EntertainmentIco} alt="entertainment icon" />
                {English.G443}
              </Link>
            </li>
          </ul>
        </div>
        <div className="video-wall" style={VideoStyle}>
          <div className="overlay" />
          <div className="video-button">
            <img src={Images.VideoPlayIco} alt="video play" />
          </div>
        </div>
        <div className="discription-box">
          <h3>{English.G112}</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            iaculis morbi tempus volutpat tortor. Ipsum vitae enim sit suscipit
            in ridiculus. Morbi potenti eu facilisis vitae posuere suspendisse.
            Praesent sit lorem amet posuere vel diam scelerisque a. Nisl
            imperdiet egestas dui consequat, diam et, at tempor.
          </p>
        </div>
        <div className="facilities-gellery">
          <h3>Facilities</h3>
          <div className="grid-gellery">
            <div className="gellery-item grid-1">
              <img src={PersonalTraining} alt="" />
              <h4>Personal Training</h4>
              <div className="overlay-gredient" />
            </div>
            <div className="gellery-item">
              <img src={Aerobics} alt="" />
              <h4>Aerobics</h4>
              <div className="overlay-gredient" />
            </div>
            <div className="gellery-item">
              <img src={Yoga} alt="" />
              <h4>Yoga</h4>
              <div className="overlay-gredient" />
            </div>
            <div className="gellery-item">
              <img src={Exercice} alt="" />
              <h4>Exercise</h4>
              <div className="overlay-gredient" />
            </div>
            <div className="gellery-item">
              <img src={Therapie} alt="" />
              <h4>Therapie</h4>
              <div className="overlay-gredient" />
            </div>
          </div>
        </div>
        <div className="our-team">
          <h3>Our Team</h3>
          <div className="team-member">
            <div className="team-memver-card">
              <div className="member-img">
                <img src={Member1} alt="" />
              </div>
              <div className="member-name">
                <p>
                  Hi, i’m
                  {' '}
                  <span>Jens Peter</span>
                </p>
                <p>Ernahrungsberater</p>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                dictum quam platea turpis sed libero sed ut. Enim faucibus
                volutpat potenti eros, rhoncus libero amet luctus. Habitasse
                enim sagittis, eget at eu augue sit. Et arcu sagittis diam purus
                diam in.
              </p>
            </div>
            <div className="team-memver-card">
              <div className="member-img">
                <img src={Member2} alt="" />
              </div>
              <div className="member-name">
                <p>
                  Hi, i’m
                  {' '}
                  <span>Jens Peter</span>
                </p>
                <p>Ernahrungsberater</p>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                dictum quam platea turpis sed libero sed ut. Enim faucibus
                volutpat potenti eros, rhoncus libero amet luctus. Habitasse
                enim sagittis, eget at eu augue sit. Et arcu sagittis diam purus
                diam in.
              </p>
            </div>
            <div className="team-memver-card">
              <div className="member-img">
                <img src={Member3} alt="" />
              </div>
              <div className="member-name">
                <p>
                  Hi, i’m
                  {' '}
                  <span>Jens Peter</span>
                </p>
                <p>Ernahrungsberater</p>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis
                dictum quam platea turpis sed libero sed ut. Enim faucibus
                volutpat potenti eros, rhoncus libero amet luctus. Habitasse
                enim sagittis, eget at eu augue sit. Et arcu sagittis diam purus
                diam in.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Profile
