/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import './LandingPage.css'
import LandingHeader from './Component/LandingHeader'
import { English, Images } from '../../helpers'
import PrimaryButton from './Component/PrimaryButton'
import UserType from './Component/UserType'
import MemberLandingView from './Component/MemberLandingView'
import CreaterLandingView from './Component/CreaterLandingView'
import BusinessLandingView from './Component/BusinessLandingView'
import TitleComponent from './Component/SubComponent/TitleComponent'
import SecondaryButton from './Component/SecondaryButton'

const LandingPage = () => {
  const [activeType, setActiveType] = useState(0)
  useEffect(() => {
    if (activeType > 0) {
      window.scrollTo({ left: 0, top: 0 })
    }
  }, [activeType])

  return (
    <div className={`${activeType === 3 ? 'bg-primaryGradient' : 'bg-headerbg'} min-h-screen`}>
      <LandingHeader activeType={activeType} />
      <div className="">
        {activeType !== 3 && <div className="bg-white h-28 absolute flex top-20 z-0 pt-10" style={{ width: '100%' }} />}
        <div className={`${activeType === 3 ? 'bg-primaryGradient' : 'bg-electricWhite'} relative rounded-3xl flex gap-10 justify-center items-center mt-10 pt-20 pb-20 pl-5 pr-5 ml-14 mr-14 z-100`}>
          <div className="flex flex-col gap-y-10">
            <div className="flex items-center !gap-5">
              <img className="logos" src={Images.Scaloo} alt="logo" />
              <img className="logos" src={Images.LuxuryEleven} alt="logo" />
              <img className="logos" src={Images.Fyerdates} alt="logo" />
            </div>
            <div className="flex items-center !gap-5">
              <img className="logos" src={Images.Spacure} alt="logo" />
              <img className="logos" src={Images.FamilyTrends} alt="logo" />
              <img className="logos" src={Images.AZFit} alt="logo" />
            </div>
          </div>
          <img className="h-64 w-64 max-[1440px]:w-48 max-[1440px]:h-48" src={Images.LogoIcon} alt="logo" />
          <div className="flex flex-col gap-y-10">
            <div className="flex items-center !gap-5">
              <img className="logos" src={Images.Testiee} alt="logo" />
              <img className="logos" src={Images.CreatorTelents} alt="logo" />
              <img className="logos" src={Images.TheTestClub} alt="logo" />
            </div>
            <div className="flex items-center !gap-5">
              <img className="logos" src={Images.GoAthlete} alt="logo" />
              <img className="logos" src={Images.Filiyo} alt="logo" />
              <img className="logos" src={Images.Petzino} alt="logo" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center p-5 mt-1 ml-14 mr-14">
          {/* <div className={`bg-clip-text text-transparent leading-none font-black italic text-[80px] text-center pe-2 ${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'}`}>{activeType === 0 ? English.A7 : activeType === 3 ? English.B20 : English.B21}</div>
          <div className="bg-clip-text text-transparent bg-electricPink leading-none font-black italic text-[56px] text-center pe-2">
            {activeType === 0 ? English.A8 : activeType === 1 ? English.A15 : activeType === 2 ? English.A16 : English.A17}
          </div> */}
          <TitleComponent
            userType={activeType}
            title={activeType === 0 ? English.A7 : activeType === 3 ? English.B20 : English.B21}
            subTitle={activeType === 0 ? English.A8 : activeType === 1 ? English.A15 : activeType === 2 ? English.A16 : English.A17}
          />
          {activeType === 0 && <div className="bg-clip-text text-transparent bg-primaryGradient text-[24px] text-center mt-7 max-w-[1100px] mx-auto max-[1600px]:text-[20px] max-[1600px]:!mt-5 max-[1440px]:text-[18px]">{English.A9}</div>}
          {activeType === 3 && <div className="bg-clip-text text-transparent bg-electricWhite text-[24px] text-center mt-7 max-w-[1100px] mx-auto max-[1600px]:text-[20px] max-[1600px]:!mt-5 max-[1440px]:text-[18px]">{English.B22}</div>}
          {activeType === 3 && <div className="bg-clip-text text-transparent bg-electricWhite text-[24px] text-center mt-7 max-w-[1100px] mx-auto max-[1600px]:text-[20px] max-[1600px]:!mt-5 max-[1440px]:text-[18px]">{English.B23}</div>}
        </div>
        {/** Second Page */}
        {activeType === 1 && <MemberLandingView />}

        {/** Third Page */}
        {activeType === 2 && <CreaterLandingView />}

        {/** Four Page */}
        {activeType === 3 && <BusinessLandingView />}

        {activeType === 0 &&
          <>
            <div className="grid grid-cols-3 gap-6 mb-10 ml-14 mr-14">
              <UserType
                title={English.A12}
                onClick={() => setActiveType(1)}
                description="Bestmme eiszigartigen Nutzernamen fÜr alies Apps und lasses ihn in deinen Profilen anzeigen. Bestmme eiszigartigen Nutzernamen fÜr alies Apps und lasses ihn in deinen Profilen anzeigen."
              />
              <UserType
                title={English.A13}
                onClick={() => setActiveType(2)}
                description="Bestmme eiszigartigen Nutzernamen fÜr alies Apps und lasses ihn in deinen Profilen anzeigen.Bestmme eiszigartigen Nutzernamen fÜr alies Apps ihn in deinen Profilen anzeigen."
              />
              <UserType
                title={English.A14}
                onClick={() => setActiveType(3)}
                description="Bestmme eiszigartigen Nutzernamen fÜr alies Apps und lasses ihn in deinen Profilen anzeigen. Bestmme eiszigartigen Nutzernamen fÜr alies ihn in deinen Profilen anzeigen."
              />
            </div>

            <div className="flex justify-center items-center gap-3 mb-8">
              <PrimaryButton
                txtClass="uppercase text-lg"
                text={English.A10}
              />
              <SecondaryButton
                text={English.A11}
              />
            </div>
          </>}
      </div>
    </div>
  )
}
export default LandingPage
