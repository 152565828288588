import React from 'react'
import './Feeds.css'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const AddFeeds = () => {
  const items = [
    {
      profile: './images/podcast.png',
      name: 'Bessie Cooper',
      designation: 'Ernahrungsberater',
      video: '',
      cover: './images/feeds/1.jpg',
      feed: 'Fitness Diet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus platea duis ac, lorem. Id viverra egestas.'
    },
    {
      profile: './images/award-second-profile.png',
      name: 'Ronald Richards',
      designation: 'Ernahrungsberater',
      video: '',
      cover: './images/feeds/2.jpg',
      feed: 'Fitness',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus platea duis ac, lorem. Id viverra egestas.'
    }
  ]
  return (
    <div className="feeds-main">
      {items.map((item) => (
        <div className="feeds-item common-card-body mt-30" key={item.feed}>
          <div className="feeds-profile">
            <div className="feeds-profile-main">
              <img src={item.profile} alt="" />
              <ul>
                <li className="name">{item.name}</li>
                <li className="designation">{item.designation}</li>
              </ul>
            </div>
            <Link>
              <img src={Images.DropLink} alt="" />
            </Link>
          </div>
          <div
            className="feeds-video"
            style={{
              background: `url(${item.cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="play">
              <img src={Images.Play} alt="" />
            </div>
          </div>
          <div className="feeds-detail">
            <h6>{item.feed}</h6>
            <p>{item.text}</p>
          </div>
          <div className="feeds-button">
            <div className="feed-btn-group">
              <button type="button">
                <img src={Images.Feed_Like} alt="" />
                {English.G401}
              </button>
              <button type="button">
                <img src={Comment} alt="" />
                {English.G402}
              </button>
              <button type="button">
                <img src={Images.Share} alt="" />
                {English.G403}
              </button>
            </div>
            <div className="save-btn">
              <button type="button">
                <img src={Images.Save} alt="" />
                {English.G117}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default AddFeeds
