import { combineReducers, configureStore, Tuple } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import UserSlice from './UserSlice/UserSlice'

const tuple = new Tuple(thunk)

const persistConfig = {
  key: 'root',
  storage
}

const rootReducer = combineReducers({
  user: UserSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const ReduxStore = configureStore({
  reducer: persistedReducer,
    middleware: () => tuple
})

export const persistor = persistStore(ReduxStore)

