/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { s3ImageProfile } from '../../services/Service'
import Images from '../../helpers/Images'
import English from '../../helpers/English'

const FollowerListModel = ({ allFollowerListData, isFollowerListLoading, onClose = () => {} }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const followerList = allFollowerListData.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = allFollowerListData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  return (
    <div className="add-item-model edit-profile">
      <div className="add-item-content">
        <div className="d-flex justify-content-between header">
          <h3>Follower List</h3>
          <button
            type="button"
            className="border-0 bg-none p-0"
            onClick={() => onClose()}
          >
            <span>
              <img className="close-btns" src={Images.CloseIco} alt="" />
            </span>
          </button>
        </div>

        <div className="scroll-bars feed-scroll">
          <div className="item-details-input pt-0">
            <div className="list_data">
              {isFollowerListLoading ?
                Array(6)
                .fill(0)
                .map((_, index) => (
                  <div
                    className="d-flex justify-content-start list_table"
                    key={index}
                  >
                    <span className="number_index">
                      <Skeleton
                        className="rounded-3"
                        width={20}
                        height={20}
                      />
                    </span>
                    <Skeleton circle width={45} height={45} />
                    <span className="user-name">
                      <Skeleton
                        className="rounded-3"
                        width={200}
                        height={25}
                      />
                    </span>
                  </div>
                ))
               : followerList.length > 0 ? (
                followerList.map((item, index) => {
                  const calculatedIndex =
                    (currentPage - 1) * videosPerPage + index + 1
                  return (
                    <div
                      className="d-flex justify-content-start list_table"
                      key={index}
                    >
                      <span className="number_index">
                        {calculatedIndex}
                      </span>
                      <img
                        src={s3ImageProfile + item.user_profile_icon}
                        className="profile-pic"
                        alt="profile"
                      />
                      <span className="user-name">
                        {item.user_name}
                      </span>
                    </div>
                  )
                })
              ) : (
                <div className="no-data-message">
                  No one follows you
                </div>
              )}
            </div>
          </div>
        </div>
        {totalPages > 1 && (
          <div className="pagination-containers-list">
            <button
              type="button"
              className={`pagination-button ${
                currentPage === 1 ? 'disabled' : ''
              }`}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              {English.G176}
            </button>
            {renderPaginationButtons()}
            <button
              type="button"
              className={`pagination-button ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {English.G69}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FollowerListModel
