/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import './style.css'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { English } from '../../helpers'
import { s3ImageProfile } from '../../services/Service'
import CommonButton from '../ButtonComponent/ButtonCommon'
import AppsItemMember from '../Dashboard/Componant/AppsItemMember'
import { APICall, EndPoints } from '../../services'

const AboutMeMember = () => {
  const UserData = useSelector((state) => state.user?.userData)

  const [isAppLoading, setIsAppLoading] = useState(false)
  const [apps, setApps] = useState([])

  const getApplicationsData = async () => {
    setIsAppLoading(true)
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setApps(decryptedData)
          setIsAppLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsAppLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsAppLoading(false)
      })
  }
  const toggleActive = (id) => {
    const updatedApps = apps.map((app) => (app.id === id ? { ...app, active: !app.active } : app))
    setApps(updatedApps)
  }

   useEffect(() => {
        getApplicationsData()
      }, [])

  return (
    <div className="profiles-section">
      <div>
        <h2 className="text-gradient-black font-extrabold italic text-2xl mb-0">
          {English.A134}
        </h2>
      </div>
      <div className="flex flex-col items-center">
        <div className="my-[30px]">
          <img
            className="border-[6px] w-[220px] h-[220px] border-[#1f2c78] rounded-full"
            src={s3ImageProfile + UserData.profile_image}
            alt=""
          />
        </div>
        <div className="flex gap-3 w-[70%] mb-[30px]">
          <div className="flex justify-between profiles-section-sub-member w-[50%] items-center">
            <div>
              <h4 className="text-gradient-black italic font-extrabold text-2xl pe-1">
                {English.A135}
              </h4>
              <h5>ss</h5>
            </div>
            <CommonButton
              label={English.G276}
              type="button"
              // onClick={prevStep}
              className="secondary-btns w-auto h-fit"
              context="secondary-btn"
            />
          </div>
          <div className="flex justify-between profiles-section-sub-member w-[50%] items-center">
            <div>
              <h4 className="text-gradient-black italic font-extrabold text-2xl pe-1">
                {English.A136}
              </h4>
              <h5>ss</h5>
            </div>
            <CommonButton
              label={English.G276}
              type="button"
              // onClick={prevStep}
              className="secondary-btns w-auto h-fit"
              context="secondary-btn"
            />
          </div>
        </div>
        <div className="profiles-section-sub-member w-[70%] mb-[30px]">
          <div className="flex justify-between items-center mb-2">
            <h4 className="text-gradient-black italic font-extrabold text-2xl pe-1">
              {English.A137}
            </h4>
            <CommonButton
              label={English.G276}
              type="button"
            // onClick={prevStep}
              className="secondary-btns w-auto h-fit"
              context="secondary-btn"
            />
          </div>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled it to make a
            type specimen book. It has survived not only five centuries, but
            also the leap into electronic typesetting, remaining essentially
            unchanged.
          </p>
        </div>
        <div className="profiles-section-sub-member">
          <h4 className="text-gradient-black italic font-extrabold text-2xl">
            {English.A138}
          </h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {apps.map((i) => (
              <AppsItemMember
                item={i}
                key={i.id}
                toggleActive={toggleActive}
                loading={isAppLoading}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutMeMember
