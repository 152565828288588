/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import './style.css'
import { useSelector } from 'react-redux'
import {
  s3ImageProfile
} from '../../services/Service'
import {
  setUserSliceData
} from '../../services/http-services'
import { formatViews, lockScroll, unlockScroll } from '../../helpers/Utility'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import ProfileSkeleton from './ProfileSkeleton'
import EditProfileModel from './EditProfileModel/EditProfileModel'
import { APICall, EndPoints } from '../../services'

const ProfileHeadCreator = () => {
  const navigate = useNavigate()
  const app_id = useSelector((state) => state.user?.app_id)
  const profileData = useSelector((state) => state.user?.profileData)
  const [profile, setProfile] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [allFollowerListData, setAllFollowerListData] = useState([])
  const [showFollowerList, setShowFollowList] = useState(false)
  const [profileEditData, setProfileEditData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isFollowerListLoading, setIsFollowerListLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const componentRef = useRef()

  const profileGet = async () => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    await APICall(
      'post',
      platFormData,
      EndPoints.creatorProfileDetails,
      'creator'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const data = res?.decryptedData
          setProfile(data)
          if (Array.isArray(data) && data.length > 0) {
            setUserSliceData(data[0], 'profile_Data')
            setUserSliceData(data[0], 'creator_Data')
          } else {
            console.error('Data array is empty or undefined.')
          }
          allFollowerList(data[0]?.user_id)
          if (data[0]) {
            // const socialMediaLinks = Array.isArray(data[0]?.social_media_links)
            //   ? data[0].social_media_links
            //   : []

            setProfile(data)
            // setInputFields({
            //   icon: data[0]?.profile_icon,
            //   description: data[0]?.description,
            //   social_media: socialMediaLinks
            // })
            // setSocialMediaLinks(socialMediaLinks)
            // setProfileImg(data[0]?.profile_icon)
          }
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const EditProfile = (profileData) => {
    setShowProfileModel(true)
    setProfileEditData(profileData)
    // setInputFields({
    //   ...inputFields,
    //   description: profileData.description,
    //   store_link: profileData.store_link,
    //   icon: profileData.profile_icon
    // })
    // setProfileImg(profileData.profile_icon)
  }

  const HandleCancel = () => {
    setShowProfileModel(false)
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const allFollowerList = (user_id) => {
    setIsFollowerListLoading(true)
    const platform = {
      app_id: app_id.id,
      creator_user_id: user_id
    }
    APICall('post', platform, EndPoints.followerList, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setAllFollowerListData(res?.decryptedData)
          setIsFollowerListLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsFollowerListLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsFollowerListLoading(false)
      })
  }
  const followCreatorData = (user_id) => {
    const platform = {
      app_id: app_id.id,
      creator_user_id: user_id
    }
    APICall('post', platform, EndPoints.followCreator, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsFollowing(!isFollowing)
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const followOpen = () => {
    setShowFollowList(true)
  }

  const handleCancel = () => {
    setShowFollowList(false)
  }

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const followerList = allFollowerListData.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = allFollowerListData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const displayedFollowers = allFollowerListData.slice(0, 4)
  const displayedNames = allFollowerListData.slice(0, 2)
  const profileInitialized = useRef(false)

  useEffect(() => {
    if (!profileInitialized.current) {
      profileInitialized.current = true
      profileGet()
    }

    const update = emitter.addListener(EventType.ProfileUpdate, () => {
      profileGet()
    })

    return () => {
      update.remove()
    }
  }, [])

  useEffect(() => {
    if (ShowProfileModel || showFollowerList) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel, showFollowerList])

  return (
    <div>
      {isLoading ? (
        <ProfileSkeleton />
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            <div className="profile-builder-header mb-40">
              <button type="button" onClick={handleGoBack}>
                <img alt="" src={Images.LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Images.Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={Images.MenuIcon} />
              </button>
            </div>

            <div className="profile-builder-main">
              <div className="profile-personal-detail">
                <div className="profile-build-image">
                  <img alt="" src={s3ImageProfile + data.profile_icon} />
                </div>
                <div className="about-personal-profile">
                  <h2>{data.user_name}</h2>
                  <h3>FITNESS & GESUNDHEIT</h3>
                  <p>
                    {' '}
                    {data.description.slice(0, 65)}
                    {data.description.length > 65 && '...'}
                  </p>
                  <span>Your Personal Trainer</span>
                </div>
              </div>
              <div className="profile-account-details">
                <ul className="about-account-insignt buttons-group">
                  <li>
                    <h4 className="profile_post_count">
                      {formatViews(data.total_post)}
                    </h4>
                    <p>{English.G104}</p>
                  </li>
                  <li style={{ justifyItems: 'center' }}>
                    <h4 className="profile_follower_count">
                      {formatViews(data.total_followers)}
                    </h4>
                    <p
                      className="Follower_text"
                      onClick={() => followOpen(data.user_id)}
                    >
                      {English.G105}
                    </p>
                  </li>
                  <li style={{ justifyItems: 'flex-end' }}>
                    <h4 className="profile_like_count">
                      {formatViews(data.total_likes)}
                    </h4>
                    <p>{English.G106}</p>
                  </li>
                </ul>
                {showFollowerList && (
                  <div className="add-item-model edit-profile">
                    <div className="add-item-content">
                      <div className="d-flex justify-content-between header">
                        <h3>Follower List</h3>
                        {/* <div className="items-filter">
                        <div className="position-relative input-position">
                          <input
                            placeholder="Search Follower"
                              onChange={(e) => {
                              handleSearch(e.target.value)
                            }}
                          />
                        </div>
                      </div> */}
                        <button
                          type="button"
                          className="border-0 bg-none p-0"
                          onClick={handleCancel}
                        >
                          <span>
                            <img
                              className="close-btns"
                              src={Images.CloseIco}
                              alt=""
                            />
                          </span>
                        </button>
                      </div>

                      <div className="scroll-bars feed-scroll">
                        <div className="item-details-input pt-0">
                          <div className="list_data">
                            {isFollowerListLoading ? (
                              Array(6)
                                .fill(0)
                                .map((_, index) => (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      <Skeleton
                                        className="rounded-3"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                    <Skeleton circle width={45} height={45} />
                                    <span className="user-name">
                                      <Skeleton
                                        className="rounded-3"
                                        width={200}
                                        height={25}
                                      />
                                    </span>
                                  </div>
                                ))
                            ) : followerList.length > 0 ? (
                              followerList.map((item, index) => {
                                const calculatedIndex =
                                  (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImageProfile + item.user_profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {item.user_name}
                                    </span>
                                  </div>
                                )
                              })
                            ) : (
                              <div className="no-data-message">
                                No one follows you
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {totalPages > 1 && (
                        <div className="pagination-containers-list">
                          <button
                            type="button"
                            className={`pagination-button ${
                              currentPage === 1 ? 'disabled' : ''
                            }`}
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                          >
                            {English.G176}
                          </button>
                          {renderPaginationButtons()}
                          <button
                            type="button"
                            className={`pagination-button ${
                              currentPage === totalPages ? 'disabled' : ''
                            }`}
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                          >
                            {English.G69}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="follow-connenct">
                  <div className="buttons-group">
                    <button
                      type="button"
                      className={`success-btn ${
                        profileData?.user_id === data.user_id ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        if (profileData?.user_id === data.user_id) {
                          return
                        }
                        followCreatorData(data.user_id)
                      }}
                    >
                      {English.G107}
                    </button>
                    <button type="button" className="primary-btn-no-hover p-10">
                      {English.G108}
                    </button>
                  </div>
                  <div>
                    <img alt="" src={Images.GbnLogo} />
                  </div>
                </div>
                <div className="follwer-shows">
                  {/* <div className="follower-img-group">
                    {displayedFollowers.map((follower, index) => (
                      <img
                        key={index}
                        alt={`Follower ${index + 1}`}
                        src={s3ImageProfile + follower.user_profile_icon}
                      />
                    ))}
                  </div> */}
                  {allFollowerListData.length > 0 && (
                    <div className="follower-img-group">
                      {displayedFollowers.map((follower, index) => (
                        <img
                          key={index}
                          alt={`Follower ${index + 1}`}
                          src={s3ImageProfile + follower.user_profile_icon}
                        />
                      ))}
                    </div>
                  )}
                  {allFollowerListData.length > 0 && (
                    <p>
                      <span className="gredient-txt">
                        {displayedNames.map((follower, index) => (
                          <React.Fragment key={index}>
                            <b>{follower.user_name}</b>
                            {index < displayedNames.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                        {' und '}
                        <b>
                          {allFollowerListData.length - 2}
                          {' '}
                          weitere Personen
                        </b>
                        {' '}
                        sind Follower
                      </span>
                    </p>
                  )}
                </div>
                {data?.social_media_links?.[0] && (
                  <div className="profile-redirect">
                    <a
                      className="gredient-txt"
                      target="_blank"
                      href={getStoreLink(data?.social_media_links?.[0])}
                      rel="noreferrer"
                    >
                      <img alt="" src={Images.Redirect} />
                      {data.social_media_links?.[0]}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      )}
      {ShowProfileModel && (
        <EditProfileModel
          type="creator"
          title={English.G110}
          profileEditData={profileEditData}
          onCancel={HandleCancel}
          componentRef={componentRef}
          onSubmite={(data) => {
            APICall('post', data, EndPoints.editCreatorProfile, 'creator')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  setTimeout(() => {
                    profileGet()
                    toast.success(res?.data?.message)
                    setShowProfileModel(false)
                    setProfileEditData(null)
                  }, 3000)
                } else {
                  setShowProfileModel(false)
                  setProfileEditData(null)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
                // setIsEdited(false)
              })
          }}
        />
      )}
    </div>
  )
}
export default ProfileHeadCreator
