import React from 'react'
import { English, Images } from '../../helpers'

const SidebarPageCreator = () => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="bg-primary-gredient-new d-flex flex-column h-100 py-[60px] px-[60px] justify-content-between ml-4">
        <div className="w-100">
          <img src={Images.creator_side_image} alt="white bg logo" className="m-auto 2xl:w-[520px] 2xl:h-[400px] xl:w-[450px] xl:h-[375px] lg:w-[320px] lg:h-[300px] md:w-[300px] md:h-[250px]" />
        </div>
        <div className="text-center">
          <p className="text-[#151515] italic text-[30px] bg-transparent font-black">{English.A56}</p>
          <p>
            {English.A83}
          </p>
        </div>
        <footer className="bg-gradient-to-r from-black to-[#041493] text-sm mt-0 d-flex gap-4 items-center justify-center">
          <img src={Images.Withe_bg_logo} alt="white bg logo" className="w-10 h-10" />
          {' '}
          <span className="bg-gradient-to-r from-black text-base to-[#041493] d-flex justify-center items-center gap-1">
            {English.A58}
            {' '}
            <span className="font-bold italic text-base">{English.A57}</span>
          </span>
        </footer>
      </div>
    </div>
  )
}
export default SidebarPageCreator
