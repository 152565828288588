import React from 'react'
import { useLocation } from 'react-router-dom'
import { English, Images } from '../../helpers'
// import Images from '../../helpers/Images'

const SideBarPage = () => {
  const location = useLocation()

  const renderMessage = () => {
    if (location.pathname === '/forget-password') {
      return (
        <>
          <p className="forgot_text text-gradient">{English.A48}</p>
          <p className="forgot_text_under text-gradient">{English.A49}</p>
        </>
      )
    }
    if (location.pathname === '/reset-password') {
      return (
        <>
          <p className="forgot_text text-gradient">{English.A50}</p>
          <p className="forgot_text_under text-gradient">{English.A51}</p>
        </>
      )
    }
    if (location.pathname === '/re-login') {
      return (
        <>
          <p className="forgot_text text-gradient">{English.G4}</p>
          <p className="forgot_text_under text-gradient">
            {English.A52}
          </p>
        </>
      )
    }
    return English.A56
  }

  const renderImage = () => {
    if (location.pathname === '/forget-password' || location.pathname === '/reset-password' || location.pathname === '/re-login' || location.pathname === '/register') {
      return null
    }
    return (
      <div className="w-[126px] h-[126px] flex items-center justify-center bg-yellow-400 rounded-full">
        <span className="text-lg">
          <img src={Images.Waving_hand} alt="waving hand" />
        </span>
      </div>
    )
  }

  const renderDescription = () => {
    if (location.pathname === '/register') {
      return (
        <div className="w-[590px] h-[126px] flex items-center justify-center bg-yellow-400 rounded-full">
          <span className="text-2xl bg-primaryGradient bg-clip-text text-transparent font-black">
            {English.B27}
          </span>
        </div>
      )
    }
    return null
  }

  return (
    <div className="align-items-center justify-content-center h-100">
      <div className="bg-primary-gredient-new d-flex flex-column justify-content-between h-100 py-[60px] ml-4">
        <div className="text-center">
          <h1 className="sidebar_text">{renderMessage()}</h1>
          <div className="flex items-center justify-center gap-2 text-gray-600">
            {renderImage()}
            {renderDescription()}
          </div>
        </div>

        <div className="relative">
          <div className="relative">
            <img className="w-100" src={Images.App_icons} alt="app icons" />
          </div>
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
            <img src={Images.Withe_bg_logo} alt="white bg logo" />
          </div>
        </div>

        <footer className="text-center text-gray-500 text-sm mt-0 text-gradient">
          {English.A54}
          {' '}
          <span className="font-extrabold italic text-gradient">{English.A53}</span>
        </footer>
      </div>
    </div>
  )
}
export default SideBarPage
