import React from 'react'
import '../Dashboard.css'
import 'react-tabs/style/react-tabs.css'
import 'react-datepicker/dist/react-datepicker.css'
import MainCart from '../MainChart'
import MyProfile from './MyProfile'
import Wallet from './Wallet'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import Campaigns from './Campaigns'
import Inspirations from './Inspirations'
import WorkSpace from './WorkSpace'

const CreatorDashboard = () => {
  return (
    <div className="">
      <div className="flex w-100 gap-4 mb-5">
        <div className="w-[70%] h-[367px]">
          <div className="bg-inactive flex-row bg-white rounded-4 !p-[20px] shadow-sm">
            <MyProfile />
          </div>
        </div>
        <div className="w-[30%] h-[367px]">
          <Wallet />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-4">
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.RunningJobs_Icon} alt="visitor" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.A146}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.UpPath} alt="" />
                <span className="positive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.OpenJobs_Icon} alt="impression" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.A147}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.UpPath} alt="" />
                <span className="positive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.NewJobs_Icon} alt="Clicks" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.A148}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.UpPath} alt="" />
                <span className="positive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.CloseJobs_Icon} alt="request" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.A149}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.DownPath} alt="" />
                <span className="negetive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="flex w-100 gap-4">
        <div className="w-[70%] flex flex-col gap-4">
          <div className="chart bg-inactive flex-row bg-white rounded-4 !p-[20px] shadow-sm">
            <div className="flex justify-between items-center mb-3">
              <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
                {English.G252}
              </div>
              <CommonButton
                label={English.A141}
                type="button"
                // onClick={createProfileClick}
                className="primary-btns w-auto"
                context="primary-btn"
                isPrimary
              />
            </div>
            <MainCart />
          </div>
          <div className="bg-inactive flex-row bg-white rounded-4 !p-[20px] shadow-sm">
            <Campaigns />
          </div>
          <div className="bg-inactive flex-row bg-white rounded-4 !p-[20px] shadow-sm">
            <Inspirations />
          </div>
        </div>
        <div className="w-[30%]">
          <WorkSpace />
        </div>
      </div>
    </div>
  )
}
export default CreatorDashboard
