import React from 'react'
import { Link } from 'react-router-dom'
import Images from '../../helpers/Images'

const Jobs = () => {
  const JobsStyle = {
    background: 'url("./images/job/a9801f3738d3f4883afffd708f83004f.jpg")',
    backgroundrepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  const items = [
    {
      image: './images/job/cat1.svg',
      name: 'Design & Creative Artist',
      position: '653'
    },
    {
      image: './images/job/cat2.svg',
      name: 'Content Writer',
      position: '653'
    },
    {
      image: './images/job/cat3.svg',
      name: 'Construction',
      position: '653'
    },
    {
      image: './images/job/cat4.svg',
      name: 'Web Developer',
      position: '653'
    },
    {
      image: './images/job/cat5.svg',
      name: 'Real Estate',
      position: '653'
    },
    {
      image: './images/job/cat6.svg',
      name: 'Android  Developer',
      position: '653'
    },
    {
      image: './images/job/cat7.svg',
      name: 'IT Manager',
      position: '653'
    },
    {
      image: './images/job/cat8.svg',
      name: 'Marketing Manger',
      position: '653'
    }
  ]
  const jobs = [
    {
      logo: './images/job/3.png',
      role: 'User Experience Designer',
      name: 'Uber, Inc',
      location: 'Athens, Greece',
      range: '$3200 - $4000',
      type: 'Fulltime',
      skill: ['UX', 'Figma', 'Azura', 'XD'],
      date: '23-09-2020',
      texts: [
        'Experienced professionals love working with us! Our interesting contract positions help professionals wanting to spend more time with family, travel the world, or start their own side business.',
        'If you are available for project work and excited to increase your earning potential, apply today!'
      ],
      Compensation: '$50 - $55 / hr',
      Commitment: 'Part-time (~25 hours per week)',
      Duration: '~3 months (early February through end of April)',
      discription: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.'
      ],
      Responsibilities: [
        'Work on financial statement audits within an audit engagement team.',
        'Perform substantive procedures and tests of controls.',
        'Maintain a great working relationship with clients and team members.'
      ],
      Requirements: [
        'BA/BS in Business with an emphasis in Accounting',
        'CPA required',
        'Prior experience auditing at a public accounting firm, at the level of Senior Associate or above'
      ],
      number: '(480) 555-0103',
      mail: 'nathan.roberts@example.com'
    },
    {
      logo: './images/job/4.svg',
      role: 'Digital Marketing Managerr',
      name: 'Facebook',
      location: 'Athens, Greece',
      range: '$3200 - $4000',
      type: 'Freelance',
      skill: ['MArketing', 'Marketing Executive', 'Marketing Manager'],
      date: '23-09-2020',
      texts: [
        'Experienced professionals love working with us! Our interesting contract positions help professionals wanting to spend more time with family, travel the world, or start their own side business.',
        'If you are available for project work and excited to increase your earning potential, apply today!'
      ],
      Compensation: '$50 - $55 / hr',
      Commitment: 'Part-time (~25 hours per week)',
      Duration: '~3 months (early February through end of April)',
      discription: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.'
      ],
      Responsibilities: [
        'Work on financial statement audits within an audit engagement team.',
        'Perform substantive procedures and tests of controls.',
        'Maintain a great working relationship with clients and team members.'
      ],
      Requirements: [
        'BA/BS in Business with an emphasis in Accounting',
        'CPA required',
        'Prior experience auditing at a public accounting firm, at the level of Senior Associate or above'
      ],
      number: '(480) 555-0103',
      mail: 'nathan.roberts@example.com'
    },
    {
      logo: './images/job/5.svg',
      role: 'Full Stack Developer',
      name: 'Google',
      location: 'Taxas',
      range: '$3200 - $4000',
      type: 'Fulltime',
      skill: ['UX', 'Figma', 'Azura', 'XD'],
      date: '23-09-2020',
      texts: [
        'Experienced professionals love working with us! Our interesting contract positions help professionals wanting to spend more time with family, travel the world, or start their own side business.',
        'If you are available for project work and excited to increase your earning potential, apply today!'
      ],
      Compensation: '$50 - $55 / hr',
      Commitment: 'Part-time (~25 hours per week)',
      Duration: '~3 months (early February through end of April)',
      discription: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.'
      ],
      Responsibilities: [
        'Work on financial statement audits within an audit engagement team.',
        'Perform substantive procedures and tests of controls.',
        'Maintain a great working relationship with clients and team members.'
      ],
      Requirements: [
        'BA/BS in Business with an emphasis in Accounting',
        'CPA required',
        'Prior experience auditing at a public accounting firm, at the level of Senior Associate or above'
      ],
      number: '(480) 555-0103',
      mail: 'nathan.roberts@example.com'
    },
    {
      logo: './images/job/4.svg',
      role: 'Digital Marketing Manager',
      name: 'Facebook',
      location: 'Athens, Greece',
      range: '$3200 - $4000',
      type: 'Freelance',
      skill: ['MArketing', 'Marketing Executive', 'Marketing Manager'],
      date: '23-09-2020',
      texts: [
        'Experienced professionals love working with us! Our interesting contract positions help professionals wanting to spend more time with family, travel the world, or start their own side business.',
        'If you are available for project work and excited to increase your earning potential, apply today!'
      ],
      Compensation: '$50 - $55 / hr',
      Commitment: 'Part-time (~25 hours per week)',
      Duration: '~3 months (early February through end of April)',
      discription: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, accumsan, erat mauris urna, diam enim elementum tellus. Consequat congue faucibus aliquam, neque cras pellentesque velit auctor. Turpis nec nulla mollis suspendisse quis est.'
      ],
      Responsibilities: [
        'Work on financial statement audits within an audit engagement team.',
        'Perform substantive procedures and tests of controls.',
        'Maintain a great working relationship with clients and team members.'
      ],
      Requirements: [
        'BA/BS in Business with an emphasis in Accounting',
        'CPA required',
        'Prior experience auditing at a public accounting firm, at the level of Senior Associate or above'
      ],
      number: '(480) 555-0103',
      mail: 'nathan.roberts@example.com'
    }
  ]
  return (
    <div className="job-container">
      <div className="job-wrapper">
        <div className="find-job">
          <div className="find-job-cover" style={JobsStyle}>
            <div className="find-job-details">
              <h2>Find your job better and fast</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
                elit, quis ultrices purus a scelerisque commodo scelerisque
                lobortis. Lobortis hendrerit vulputate nunc in tellus.
              </p>
            </div>
            <div className="position-relative z-1 input-serchbg">
              <input placeholder="Type any thing seprated by comma" />
            </div>
          </div>
        </div>

        <div className="job-categories common-card-body recent-video-component mt-40">
          <h3>Categories</h3>
          <div className="job-categories-grid">
            {items.map((item) => (
              <div className="job-categories-item" key={item.name}>
                <img alt="" src={item.image} />
                <p>{item.name}</p>
                <span>
                  (
                  {item.position}
                  )
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="job-for-you common-card-body mt-40">
          <h2>Jobs For you</h2>
          <div className="jobs-item-row">
            {jobs.map((item) => (
              <div key={item.name} className="jobs-items-detsils flex-wrap">
                <div className="d-flex align-items-center job-items-sub">
                  <div className="jobs-items-logo">
                    <img alt="" src={item.logo} />
                  </div>
                  <div className="jobs-items-discription">
                    <h4>{item.role}</h4>
                    <ul className="job-items-company">
                      <li>
                        <img alt="" src={Images.Company} />
                        {item.name}
                      </li>
                      <li>
                        <img alt="" src={Images.Location} />
                        {item.location}
                      </li>
                      <li>
                        <img alt="" src={Images.Card} />
                        {item.range}
                      </li>
                    </ul>
                    <div className="job-items-skill">
                      <h6>
                        <img alt="" src={Images.briefcase} />
                        {item.type}
                      </h6>
                      <span className="divider mx-3" />
                      <ul>
                        {item?.skill.map((skills, index) => (
                          <li key={index}>{skills}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="job-items-apply">
                  <Link
                    className="light-button d-block"
                    to="/job-detail"
                    state={item}
                  >
                    <span>Apply Now</span>
                  </Link>
                  <p>
                    Posted on:
                    {item.date}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="job-newslatter">
            <h5>Don’t miss any job update</h5>
            <div>
              <input placeholder="Your work email address" />
              <button type="button" className="primary-btn">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Jobs
