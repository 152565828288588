import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DiscountSkeleton = () => {
  return (
    <div className="add-item-content add_room_modal px-4">
      <div className="type_close mb-2">
        <div className="d-flex align-items-center col-gap-10">
          <Skeleton count={1} height={25} width={250} />
        </div>
        <button className="cancel_icon" type="button">
          <Skeleton circle height={25} width={25} />
        </button>
      </div>
      <div className="item-details-input ">
        <div className="room_image">
          <Skeleton count={1} height={250} width={750} />
        </div>
      </div>
      <div className="item-details-input pt-0 mt-3">
        <div className="input-grid detail-grid">
          <div className="deal-label">
            <label>
              <Skeleton count={1} height={20} width={150} />
            </label>
            <Skeleton count={1} height={50} width={300} />
          </div>
          <div>
            <label>
              <Skeleton count={1} height={20} width={150} />
            </label>
            <Skeleton count={1} height={50} width={300} />
          </div>
        </div>
        <div className="input-grid detail-grid">
          <div>
            <label>
              <Skeleton count={1} height={20} width={150} />
            </label>
            <Skeleton count={1} height={50} width={300} />
          </div>
          <div>
            <label>
              <Skeleton count={1} height={20} width={150} />
            </label>
            <Skeleton count={1} height={50} width={300} />
          </div>
        </div>
        <div className="local_button_row">
          <Skeleton borderRadius={12.5} count={1} height={40} width={100} />
        </div>
      </div>
    </div>
  )
}
export default DiscountSkeleton
