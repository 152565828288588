import React from 'react'
import { Link } from 'react-router-dom'
import RatingContainer from '../RatingContainer/Rating'
import PopularDishes from './PopularDishes'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RestaurantDetail = () => {
  const itemsReview = [
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    }
  ]
  return (
    <div className="creator-profile-main restaurant-details">
      <div className="common-card-body hotel-resrve">
        <div className="hotel-reserve-detail d-flex justify-content-between">
          <div className="hotel-reserve-detail--header">
            <h4>One Market restaurant</h4>
            <p>
              2nd street, clubs road
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>4.9</span>
              </div>
            </p>
          </div>
          <div className="d-flex align-items-center col-gap-10 common-contnt-wrapper">
            <img alt="" src={Images.Location} />
            <Link>View map</Link>
          </div>
        </div>
        <div className="grid-gellery position-relative">
          <div className="gellery-item grid-1--span-4">
            <img alt="" src="./images/Restaurant/1.jpg" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img alt="" src="./images/Restaurant/2.jpg" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img alt="" src="./images/Restaurant/3.jpg" />
            <div className="overlay-gredient" />
          </div>
          <button type="button" className="show-photos">
            <img alt="" src={Images.ShowIco} />
            <span>Show all photos</span>
          </button>
        </div>
        <div className="mt-30">
          <button type="button" className="price-btn">Preis ab 350€</button>
        </div>
        <div className="discription-box recent-video-component mb-0 mt-40">
          <h3>{English.G112}</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            iaculis morbi tempus volutpat tortor. Ipsum vitae enim sit suscipit
            in ridiculus. Morbi potenti eu facilisis vitae posuere suspendisse.
            Praesent sit lorem amet posuere vel diam scelerisque a. Nisl
            imperdiet egestas dui consequat, diam et, at tempor.
          </p>
        </div>
        <div className="restaurant--sub-detail d-flex align-items-center flex-wrap col-gap-70 mt-40">
          <div>
            <p>{English.G297}</p>
            <span>African, International, Fuision</span>
          </div>
          <div>
            <p>Special Diets</p>
            <span>Vegetarian friendly</span>
          </div>
          <div>
            <p>{English.G296}</p>
            <span>Breakfast, Lunch, Dinner, Brunch</span>
          </div>
          <div>
            <p>Opening Hours</p>
            <span>24/7</span>
          </div>
        </div>
        <div className="free-courses-starter-pack mt-40">
          <div className="details">
            <h5>Reservation</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
              sem tincidunt urna, justo. Urna non nibh aliquam elementum enim
              pulvinar amet. Eros, vulputate proin purus lectus. Lacinia
              tincidunt vitae pharetra.
            </p>
          </div>
          <button type="button" className="light-button">
            <span>Book a Table</span>
          </button>
        </div>
        <div className="mt-40">
          <PopularDishes />
        </div>
        <div className="mt-40">
          <RatingContainer />
        </div>
        <div className="common-card-body mt-40 recent-video-component">
          <h3>Reviews</h3>
          <div className="hotel-reserve--review">
            {itemsReview.map((item) => (
              <div className="hotel-reserve-review--item" key={item.name}>
                <p>{item.review}</p>
                <div className="devider" />
                <div className="hotel-reserve-review--detail d-flex align-items-center col-gap-20">
                  <img alt="" src={item.image} />
                  <div>
                    <p>{item.name}</p>
                    <span>{item.date}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default RestaurantDetail
