import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHeadStore from '../ProfileBuilder/ProfileHeadStore'
import Feeds from '../Feeds/AddFeeds'
import BussinessProfileRating from '../ReviewsAndRating/BussinessProfileRating'
import BussinessProfileRatingContainer from '../RatingContainer/BussinessProfileRatingContainer'
import AddLocalHome from './AddLocalHome'
import AddEvents from './AddEvents'
import AddTickets from './AddTickets'
import Services from './Services'
import TicketComponent from './TicketComponent'
import EventComponent from './EventComponent'
import MapLocal from './MapLocal'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const LocalEvents = () => {
  return (
    <div className="creator-profile-main creator-profile-create">
      <ProfileHeadStore />
      <div className=" mt-30">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.HomeIco} />
                  {English.G222}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedIco} />
                  {English.G223}
                </Tab>
                <Tab>
                  <img alt="" src={Images.EventIco} />
                  {English.G306}
                </Tab>
                <Tab>
                  <img alt="" src={Images.TicketIco} />
                  {English.G450}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedbackIco} />
                  {English.G427}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <AddLocalHome />
                </div>
                <div className="mt-40">
                  <AddEvents />
                </div>
                <div className="mt-40">
                  <AddTickets />
                </div>
                <div className="mt-40">
                  <Services />
                </div>
                <div className="mt-40">
                  <MapLocal />
                </div>
                <div className="mt-40">
                  <BussinessProfileRatingContainer />
                </div>
                <div className="mt-40">
                  <BussinessProfileRating />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Feeds />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <EventComponent />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <TicketComponent />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <BussinessProfileRatingContainer />
                </div>
                <div className="mt-40">
                  <BussinessProfileRating />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LocalEvents
