import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Images from '../../helpers/Images'

const AppIcons = () => {
  const navigate = useNavigate()
  const iconClick = () => {
    navigate('/create-profile')
  }

  return (
    <div className="app-icons">
      <div className="icons-row">
        <div className="icons-table">
          <img alt="" src={Images.Scaloo} />
          <img alt="" src={Images.LuxuryEleven} />
          <img onClick={() => iconClick()} alt="" src={Images.Fyerdates} />
          <img alt="" src={Images.Spacure} />
          <img alt="" src={Images.FamilyTrends} />
          <img alt="" src={Images.AZFit} />
        </div>
        <div className="logo-view">
          <Link to="/create-profile">
            <div className="logo-gredient">
              <img alt="" src={Images.Logo} />
            </div>
          </Link>
        </div>
        <div className="icons-table">
          <img alt="" src={Images.Testiee} />
          <img alt="" src={Images.CreatorTelents} />
          <img alt="" src={Images.TheTestClub} />
          <img alt="" src={Images.GoAthlete} />
          <img alt="" src={Images.Filiyo} />
          <img alt="" src={Images.Petzino} />
        </div>
      </div>
    </div>
  )
}
export default AppIcons
