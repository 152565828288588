/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React, { useRef, useState } from 'react'
import MarqueeComponent from './SubComponent/MarqueeComponent'
import TitleComponent from './SubComponent/TitleComponent'
import FlowComponent from './SubComponent/FlowComponent'
import ListVerticalComponent from './SubComponent/ListVerticalComponent'
import PrimaryButton from './PrimaryButton'
import OtherUserTypeComponent from './SubComponent/UserTypeSubComponent'
import AccordionComponent from './SubComponent/AccordionComponent'
import CompanyDetailComponent from './SubComponent/CompanyDetailComponent'
import { English, Images } from '../../../helpers'
import SecondaryButton from './SecondaryButton'

const CreaterLandingView = () => {
  const videoRef = useRef()
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }
  return (
    <div>
      <div className="flex items-center justify-center relative">
        <div className="bg-electricWhite w-1/2 h-96 text-center items-center rounded-xl overflow-hidden relative">
          <video
            ref={videoRef}
            className="rounded-lg overflow-hidden h-full w-full object-cover"
            src="https://www.w3schools.com/html/mov_bbb.mp4"
            controls={false}
          />
          <button
            type="button"
            onClick={togglePlayPause}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-primaryGradient w-12 h-12 self-center rounded-3xl"
          >
            <span className="text-white text-2xl pl-1">{isPlaying ? '❚❚' : '▶'}</span>
          </button>
        </div>
      </div>
      <div className="py-28">
        <MarqueeComponent />
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          userType={2}
          title={English.B44}
          subTitle={English.B45}
          description={English.B46}
        />
        <FlowComponent />
      </div>
      <div className="pt-16 pb-28 px-16">
        <TitleComponent
          userType={2}
          title={English.B55}
          subTitle={English.B56}
        />
        <div className="grid grid-cols-2 gap-20 mt-10 max-[1440px]:gap-6">
          <div className="flex flex-col gap-20">
            <ListVerticalComponent
              userType={2}
              data={[
                {
                  title: 'ERSTELLE DEINE APP WORLD ONE ID',
                  subTitle: 'TRETE DER COMMUNITY KOSTENLOS BEI',
                  description: 'Verwende deine Member-ID als Zugang für alle Apps von App World One.',
                  buttonLabel: 'APP WORLD ONE ID ERSTELLEN',
                  image: null
                },
                {
                  title: 'NUTZE BIS ZU 16 APPS',
                  subTitle: 'ENTFALTE DAS MAXIMALE POTENTIAL DEINER APPS',
                  description: 'Nutze dauerhaft alle Apps in der kostenlosen Lite Version oder schalte mit einem Upgrade die Pro Funktionen für deine Lieblings-App(s) frei.',
                  buttonLabel: 'APPS ENTDECKEN',
                  image: Images.DeinerApps
                }
              ]}
            />
          </div>
          <div className="flex flex-col gap-20 mt-36">
            <ListVerticalComponent
              userType={2}
              data={[
                {
                  title: 'WÄHLE DEINE NUTZERART',
                  subTitle: 'MEMBER - CREATOR - BUSINESS',
                  description: 'Alle Apps bestehen aus einer Kombination von Social Media und Premium Funktionen mit exklusiven Content.',
                  buttonLabel: 'NUTZERARTEN VERGLEICHEN',
                  image: Images.MemberCreatorBussiness
                },
                {
                  title: 'APP WORLD ONE WALLET',
                  subTitle: 'ALLE AKTIVITÄTEN ABRUFBAR IN JEDER APP',
                  description: 'Die Wallet beinhaltet deine App World One ID mit dem ausgewählten Abo, verfügbare Points, Transaktionen und vieles mehr.',
                  buttonLabel: 'WALLET ERKUNDEN',
                  image: Images.JederApp
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          userType={2}
          title="APP WORLD ONE WALLET ALS MEMBER"
          subTitle="ALLES NOTWENDIGE ABRUFBAR IN JEDER APP"
          description="eine individuelle App World One Wallet beinhaltet deine App World One ID mit deinem ausgewählten Abo, deine verfügbaren Points, Transaktionen und vieles mehr."
        />
        <div className="mt-16">
          <img className="mw-100 mx-auto" src={Images.WalletAs} alt="" />
        </div>
        <div className="flex items-center !gap-5 justify-center mt-16">
          <PrimaryButton
            txtClass="uppercase text-lg"
            text="APP WORLD ONE ID ERSTELLEN"
          />
          <SecondaryButton
            text="MEHR ÜBER DIE APP WORLD ONE Wallet"
          />
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          userType={2}
          title="VERWANDEL DEINEN CONTENT IN CASH"
          subTitle="+ VERDIENE GELD MIT DEINEN COMMUNITIES"
          description="Erstelle mit einem Nutzernamen und Passwort deine App World One ID für die Apps von App World One und profitiere dadurch von einem schnellen Anmeldeprozess."
        />
        <div className="text-center mb-10">
          <PrimaryButton
            txtClass="uppercase text-lg"
            text="PARTNER PROGRAMM ENTDECKEN"
          />
        </div>
        <div className="flex flex-col gap-[70px]">
          <div className="border-[0.5px] border-BorderLight rounded-[30px] flex items-center overflow-hidden">
            <div className="w-3/5 max-[1350px]:w-1/2 h-full">
              <img className="w-full h-full object-cover" src={Images.growthWrapper} alt="" />
            </div>
            <div className="text-center w-2/5 max-[1350px]:w-1/2 !p-5">
              <h3 className="max-[1600px]:text-[42px] max-[1350px]:text-[38px] mb-0 italic font-black leading-1 text-[54px] bg-clip-text text-transparent bg-primaryGradient pe-2">CREATOR CODE</h3>
              <h4 className="max-[1600px]:text-[22px] max-[1350px]:text-lg max-[1350px]:!mb-3 mb-7 italic font-bold leading-normal text-[27px] bg-clip-text text-transparent bg-textDodgerBlue pe-2">VERDIENE AN JEDER TRANSAKTION</h4>
              <p className="max-[1600px]:text-[18px] max-[1350px]:text-base max-[1200px]:text-sm text-Main mb-0 text-[23px]">Durch den Aufbau deiner App World One Community hast du viele Möglichkeiten dauerhaft an den Usern, die sich auf Grund von dir anmelden Geld zu verdienen. Du bekommst nachhaltig für alle Transaktionen der User, die du akquiriert hast eine Beteiligung. Alle Umsätze aus Transaktionen der von dir geworbenen Nutzer werden in deinem Chash-Out-Konto gespeichert und können von dort auf Wunsch auf dein Bankkonto überwiesen werden.</p>
            </div>
          </div>
          <div className="border-[0.5px] border-BorderLight rounded-[30px] flex items-center overflow-hidden">
            <div className="text-center w-2/5 max-[1350px]:w-1/2 !p-5">
              <h3 className="max-[1600px]:text-[42px] max-[1350px]:text-[38px] mb-0 italic font-black leading-1 text-[54px] bg-clip-text text-transparent bg-primaryGradient pe-2">KOOPERATIONEN</h3>
              <h4 className="max-[1600px]:text-[22px] max-[1350px]:text-lg max-[1350px]:!mb-3 mb-7 italic font-bold leading-normal text-[27px] bg-clip-text text-transparent bg-textDodgerBlue pe-2">ARBEITE MIT DEINEN LIEBLINGSMARKEN</h4>
              <p className="max-[1600px]:text-[18px] max-[1350px]:text-base max-[1200px]:text-sm text-Main mb-0 text-[23px]">Stellen deine Dienstleistungen auf unserer Plattform ein und werde von Marken kontaktiert, die zu deinen Vorstellungen passen. Wir arbeiten mit KI, um die am besten geeigneten Kooperationen zu finden. Die Unternehmen können nicht nur auf dich zukommen, sondern Du kannst auch dein bestehendes Profil nutzen, um die Stellenanzeigen der Marken einzusehen und dann mit nur einem Klick eine &quot;Bewerbung&quot; für eine Zusammenarbeit zu versenden.</p>
            </div>
            <div className="w-3/5 max-[1350px]:w-1/2 h-full">
              <img className="w-full h-full object-cover" src={Images.growthWrapper} alt="" />
            </div>
          </div>
          <div className="border-[0.5px] border-BorderLight rounded-[30px] flex items-center overflow-hidden">
            <div className="w-3/5 max-[1350px]:w-1/2 h-full">
              <img className="w-full h-full object-cover" src={Images.growthWrapper} alt="" />
            </div>
            <div className="text-center w-2/5 max-[1350px]:w-1/2 !p-5">
              <h3 className="max-[1600px]:text-[42px] max-[1350px]:text-[38px] mb-0 italic font-black leading-1 text-[54px] bg-clip-text text-transparent bg-primaryGradient pe-2">PRODUKTIONEN</h3>
              <h4 className="max-[1600px]:text-[22px] max-[1350px]:text-lg max-[1350px]:!mb-3 mb-7 italic font-bold leading-normal text-[27px] bg-clip-text text-transparent bg-textDodgerBlue pe-2">VIDEOS - PODCAST - SHOTS - STREAMS</h4>
              <p className="max-[1600px]:text-[18px] max-[1350px]:text-base max-[1200px]:text-sm text-Main mb-0 text-[23px]">Wir bieten verschiedene Challenges im Business Manager an. Du kannst dich einfach bewerben, um einen Job von App World One für bestimmte Inhalte zu bekommen, die oft in der App angezeigt werden. App World One hat mehrere Original-Produktionen geplant. Sie werden überall in den Apps präsentiert und Du kannst dich einfach über Creator Talents oder das Business Manager Dashboard bewerben.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <div className="flex rounded-[32px] overflow-hidden bg-PrimaryBg">
          <div className="flex w-7/12 items-center flex-col justify-center p-14">
            <h3 className="font-black italic text-[60px] leading-none text-white mb-[30px] max-[1440px]:text-[48px] max-[1200px]:text-[36px]">APP WORLD ONE CONTENT PRODUKTIONEN</h3>
            <p className="text-[22px] text-white mb-[30px] max-[1440px]:text-[20px] max-[1200px]:text-[16px]">Führen Sie ein Upgrade in bestimmten Funktionen durch, damit sie nicht direkt ein größeres und teureres Abo-Paket buchen müssen. Die genauen Preise finden Sie, wenn Sie auf eines der Upgrades klicken.</p>
            <div className="text-start w-full">
              <button className="bg-textDodgerBlue rounded-[80px] !py-4 px-[60px]" type="button">
                <span className="bg-clip-text text-transparent bg-electricWhite italic uppercase text-base font-black pe-2">MEHR ERFAHREN</span>
              </button>
            </div>
          </div>
          <div className="w-5/12 relative">
            <img className="w-full h-full object-cover" src={Images.ContentProduction} alt="" />
            <div className="bg-bgOverlay absolute top-0 left-[4%] -translate-x-1/2 h-full w-1/12" />
          </div>
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          userType={2}
          title="WEITERE NUTZERARTEN ENTDECKEN"
          subTitle="CREATOR I BUSINESS"
        />
        <div className="mt-16 grid grid-cols-2 gap-14 max-[1440px]:gap-6">
          <OtherUserTypeComponent
            image={Images.CreatorBlock}
            icon={Images.creator}
            userTypeTitle={English.A45}
            userTypeSubTitle="BUILD YOUR COMMUNITIES"
          />
          <OtherUserTypeComponent
            image={Images.BussinessBlock}
            icon={Images.BussinessIco}
            userTypeTitle={English.A46}
            userTypeSubTitle="PRESENT YOUR COMPANY UNITS"
          />
        </div>
      </div>
      <div className="px-16 pt-[60px] pb-20">
        <TitleComponent
          userType={2}
          title="HÄUFIG GESTELLTE FRAGEN"
          subTitle="FINDE ANTWORTEN UND ERKLÄRUNGEN"
        />
        <div className="lending-faq mt-14">
          <AccordionComponent
            data={[
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              },
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              },
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              },
              {
                title: 'What our wallet offers!',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium massa lacus porttitor tortor. Est massa euismod risus mauris dui urna dolor. Tincidunt praesent ultrices faucibus massa sollicitudin sagittis eget. Fringilla volutpat faucibus nulla nunc ipsum elementum pretium commodo. Condimentum elit aliquam augue enim turpis nibh.'
              }
            ]}
          />
        </div>
      </div>
      <footer className="p-[60px] pb-0 bg-lightGradient">
        <CompanyDetailComponent />
      </footer>
      <div className="w-full h-[10px] bg-BgRainbow" />
    </div>
  )
}

export default CreaterLandingView
