import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import RatingContainer from '../RatingContainer/Rating'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import Slider from '../ReviewsAndRating/slider'
import ServicesProfile from '../Services/Services'
import Jobs from '../EducationSchoolUnivercity/Jobs'
import CurruntJobs from '../EducationSchoolUnivercity/CurruntJobs'
import Portfolio from './Portfolio'
import Services from './Services'
import RecentProject from './RecentProject'
import ProjectPortfolio from './ProjectPortfolio'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Agency = () => {
  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.HomeIco} />
                  {English.G222}
                </Tab>
                <Tab>
                  <img alt="" src={Images.ServiceIco} />
                  {English.G224}
                </Tab>
                <Tab>
                  <img alt="" src={Images.PortfolioIco} />
                  {English.G225}
                </Tab>
                <Tab>
                  <img alt="" src={Images.JobIco} />
                  {English.G226}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedbackIco} />
                  {English.G427}
                </Tab>
                <Tab>
                  <img alt="" src={Images.AboutIco} />
                  {English.G428}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="mt-40">
                  <Services />
                </div>
                <div className="mt-40">
                  <ProjectPortfolio />
                </div>
                <div className="mt-40">
                  <RecentProject />
                </div>
                <div className="mt-40">
                  <CurruntJobs />
                </div>
                <div className="about common-card-body recent-video-component mt-40">
                  <h3>{English.G428}</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                    pellentes nullam sit amet, lorem. Semper vel egestas
                    convallis nisl duis mattis ut molestie sed. Mauris feugiat
                    tincidunt nunc, quam mauris, massa diam faucibus.
                    {' '}
                    <span>See more</span>
                  </p>
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <ServicesProfile />
              </TabPanel>
              <TabPanel>
                <Portfolio />
              </TabPanel>
              <TabPanel>
                <Jobs />
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Agency
