import React from 'react'
import '../AddAwards/AddAwards.css'
import './style.css'
import Images from '../../helpers/Images'

const AddPhotos = () => {
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Photos</h2>
        </div>
        <div className="awards-row">
          <div className="add-photos-item">
            <div className="add-photos-cover add-value position-relative">
              <button type="button" className="add-item-btn">
                <img alt="" src={Images.Plus} />
              </button>
            </div>
          </div>
          <div className="add-photos-item">
            <div className="add-photos-cover add-value position-relative">
              <button type="button" className="add-item-btn">
                <img alt="" src={Images.Plus} />
              </button>
            </div>
          </div>
          <div className="add-photos-item">
            <div className="add-photos-cover add-value position-relative">
              <button type="button" className="add-item-btn">
                <img alt="" src={Images.Plus} />
              </button>
            </div>
          </div>
          <div className="add-photos-item">
            <div className="add-photos-cover add-value position-relative">
              <button type="button" className="add-item-btn">
                <img alt="" src={Images.Plus} />
              </button>
            </div>
          </div>
          <div className="add-photos-item">
            <div className="add-photos-cover add-value position-relative">
              <button type="button" className="add-item-btn">
                <img alt="" src={Images.Plus} />
              </button>
            </div>
          </div>
          <div className="add-photos-item">
            <div className="add-photos-cover add-value position-relative">
              <button type="button" className="add-item-btn">
                <img alt="" src={Images.Plus} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddPhotos
