import React from 'react'
import Skeleton from 'react-loading-skeleton'
import English from '../../../helpers/English'
import CommonButton from '../../ButtonComponent/ButtonCommon'

 const SelectUserType = ({
  isLoading,
  userTypesData,
  selctedItem,
  handleSelection,
  nextStep,
  urlSystemIcon
}) => {
  return (
    <div className="rounded-3xl p-[30px] bg-white h-100">
      <div className="flex justify-between">
        <div>
          <h1 className="business_type_header_text italic text-3xl font-black pr-1 mb-1">{English.A94}</h1>
          <p className="sub-text-1 font-medium text-[15px] text-[#303030]">{English.A95}</p>
        </div>
        <div className="">
          <CommonButton
            label={English.G69}
            type="button"
            onClick={nextStep}
            className="primary-btns btn-next"
            disabled={!selctedItem}
            context="primary-btn"
            isPrimary
          />
        </div>
      </div>
      <div className="step-1">
        {isLoading ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3>
                <Skeleton width={100} />
              </h3>
              <p>
                <Skeleton className="mb-2" width={150} height={20} />
              </p>
              <p>
                <Skeleton width={175} height={20} />
              </p>
            </div>
            <Skeleton width={80} height={80} />
          </div>
        ) : (
          userTypesData.map((i) => (
            <div
              key={i.name}
              className={`d-flex justify-content-between align-items-center hover_create ${
                selctedItem?.includes(i.name) ? 'selected' : 'selectable'
              }`}
              onClick={() => handleSelection(i)}
            >
              <div>
                <h3 className="font-black text-[#0B0A0A] text-2xl capitalize">
                  {' '}
                  {i.name.toUpperCase()}
                </h3>
                <p className="text-[#303030] text-sm font-medium mb-0">{i.description}</p>
              </div>
              <img className="!w-[69px] !h-[80px]" src={urlSystemIcon + i.icon} alt="" />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default SelectUserType
