import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RecentHotels = () => {
  const items = [
    {
      cover: './images/hotels/1.jpg',
      name: 'Four Seasons Hotel Gresham Palaceb Suites',
      price: '$460,000',
      type: '5 star',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    },
    {
      cover: './images/hotels/2.jpg',
      name: 'Four Seasons Hotel Gresham Palaceb Suites',
      price: '$460,000',
      type: '5 star',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    },
    {
      cover: './images/hotels/3.jpg',
      name: 'Four Seasons Hotel Gresham Palaceb Suites',
      price: '$460,000',
      type: '5 star',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    },
    {
      cover: './images/hotels/1.jpg',
      name: 'Four Seasons Hotel Gresham Palaceb Suites',
      price: '$460,000',
      type: '5 star',
      bedrooms: '8',
      bathrooms: '2',
      location: '704 Aberdeen Southlake',
      rating: '4.9'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component ">
      <h3 className="d-flex align-items-center justify-content-between">
        Recent Hotels
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="recent-hotel-grid">
        {items.map((item) => (
          <div key={item.name} className="hotel--grid-item">
            <div
              className="recent-hotel-cover"
              style={{
                background: `url(${item.cover})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>{item.rating}</span>
              </div>
              <div className="like">
                <img alt="" src={Images.UnLikes} />
              </div>
            </div>
            <div className="recent-hotel-detail">
              <div className="price--type">
                <div className="price--detail">{item.price}</div>
                <div className="type--detail">{item.type}</div>
              </div>
              <h5 className="name">{item.name}</h5>
              <ul>
                <li>
                  <img alt="" src={Images.Bedroom1} />
                  <span>
                    {item.bedrooms}
                    {' '}
                    Bedrooms
                  </span>
                </li>
                <li>
                  <img alt="" src={Images.Bathroom} />
                  <span>
                    {item.bathrooms}
                    {' '}
                    Bathrooms
                  </span>
                </li>
                <li>
                  <img alt="" src={Images.Location} />
                  <span>{item.location}</span>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RecentHotels
