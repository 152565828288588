import React, { useEffect, useRef, useState } from 'react'
import '../style.css'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'

const Main = () => {
  const [ShowMainModel, setShowMainModel] = useState(false)
  const AddMain = () => {
    setShowMainModel(true)
  }
  const HandleCancel = () => {
    setShowMainModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowMainModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Main</h2>
        </div>
        <div className="awards-row">
          <div className="deals-item">
            <div className="deals-title-price">
              <h2 className="deals-title add-value">.</h2>
              <h2 className="deals-price add-value">.</h2>
            </div>
            <p className="deals-details add-value" />
            <button type="button" className="add-item-btn-deals" onClick={AddMain}>
              <img alt="" src={Images.Plus} />
            </button>
          </div>
        </div>
      </div>
      {ShowMainModel && (
        <div className="add-item-model">
          <div className="add-item-content h-auto" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div>
                <label>{English.G162}</label>
                <input placeholder={English.G162} />
              </div>

              <div>
                <label>{English.G131}</label>
                <input placeholder="Enter price" />
              </div>

              <div>
                <label>{English.G112}</label>
                <textarea placeholder={English.G83} />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button type="button" className="secondary-btn-model">{English.G152}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Main
