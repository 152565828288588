import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const MagazinesDetails = () => {
  const items = [
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/scalco-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/testiee-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/a-z-fit-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/the-teste-club-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/scalco-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/testiee-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    },
    {
      CoverImage: './images/courses1.png',
      platformImg: './images/spacure-table.svg',
      tag: 'Published',
      Title: 'Health',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      view: '1.2 M',
      rating: '4.9',
      days: '10 days'
    }
  ]
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setMegezinesModel(false)
        setShowMegenizePreview(false)
        setShowMegenizePreviewFinal(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  const [ShowMegezinesModel, setMegezinesModel] = useState(false)
  const [ShowMegenizePreview, setShowMegenizePreview] = useState(false)
  const [ShowMegenizePreviewFinal, setShowMegenizePreviewFinal] =
    useState(false)
  const AddMagezines = () => {
    setMegezinesModel(true)
  }
  const AddMagezinesPreview = () => {
    setShowMegenizePreview(true)
    setMegezinesModel(false)
  }
  const HandleMaganizeFinal = () => {
    setMegezinesModel(false)
    setShowMegenizePreview(false)
    setShowMegenizePreviewFinal(true)
  }
  const HandleCancel = () => {
    setMegezinesModel(false)
    setShowMegenizePreview(false)
    setShowMegenizePreviewFinal(false)
  }

  const [tags, setTags] = useState([])

  const handleAddTag = () => {
    if (tags.length < 4) {
      const newTag = `tag ${tags.length + 1}`
      setTags([...tags, newTag])
    } else {
      alert('Maximum 4 tags allowed.')
    }
  }

  const handleRemoveTag = (tagIndex) => {
    const updatedTags = [...tags]
    updatedTags.splice(tagIndex, 1)
    setTags(updatedTags)
  }

  const handleEditTag = (tagIndex) => {
    const newTagName = prompt('Enter new tag name:', tags[tagIndex])
    if (newTagName !== null) {
      const updatedTags = [...tags]
      updatedTags[tagIndex] = newTagName
      setTags(updatedTags)
    }
  }
  return (
    <div>
      <div className="add-video-row">
        <Link onClick={AddMagezines}>
          <img alt="" src={Images.AddIco} />
          Add New Magazines
        </Link>
        <div className="items-filter">
          <div className="position-relative input-position">
            <input placeholder="Search Magazines" />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
      </div>
      <div className="video-detail-manager">
        {items.slice(0, visibleItems).map((item) => (
          <div
            className="video-detail-item magazines-detail-item"
            key={item.platformImg}
          >
            <div
              className="video-detail-cover"
              style={{
                background: `url(${item.CoverImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            >
              <span className="activity-badge">{item.tag}</span>
              <span className="platform-badge">
                <div
                  className="badge-cover"
                  style={{
                    background: `url(${item.platformImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center'
                  }}
                />
              </span>
            </div>
            <div className="video-content">
              <div className="magezines-title">
                <h6>
                  <img alt="" src={Images.HealthIco} />
                  <span>{item.Title}</span>
                </h6>
                <div className="rating">
                  <img alt="" src={Images.RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <p>{item.text}</p>
              <div className="views-and-time">
                <span>
                  <img alt="" src={Images.ViewIco} />
                  {item.view}
                </span>
                <span>
                  <img alt="" src={Images.ClockFillIco} />
                  {item.days}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img alt="" src={Images.DownIco} />
          </button>
        </div>
      )}
      {ShowMegezinesModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={Images.upload} />
                  </div>
                  <h4>{English.G460}</h4>
                  <p>
                    {English.G156}
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>{English.G459}</span>
                  </button>
                </label>
              </div>
              <div className="input-grid">
                <div>
                  <label>Title of Magazine</label>
                  <input placeholder={English.G162} />
                </div>
                <div>
                  <label>{English.G202}</label>
                  <Dropdown
                    title="Health"
                    Data={[
                      { title: 'Health' },
                      { title: 'Health' },
                      { title: 'Health' },
                      { title: 'Health' },
                      { title: 'Health' }
                    ]}
                  />
                </div>
              </div>
              <div>
                <label>{English.G112}</label>
                <textarea placeholder={English.G83} />
              </div>
              <div className="add-tag">
                <span>{English.G189}</span>
                <div className="d-flex flex-wrap column-gap-20">
                  <ul>
                    {tags.map((tag, index) => (
                      <li key={index}>
                        {tag}
                        <button
                          type="button"
                          className="remove-tag"
                          onClick={() => handleRemoveTag(index)}
                        >
                          <img alt="" src={Images.Remove} />
                        </button>
                        <button
                          type="button"
                          className="edit-tag"
                          onClick={() => handleEditTag(index)}
                        >
                          {English.G81}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <button type="button" className="add-tag-btns" onClick={handleAddTag}>
                    <img alt="" src={Images.AddIcoAdmin} />
                    Add Tag
                  </button>
                </div>
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={AddMagezinesPreview}
                >
                  {English.G127}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowMegenizePreview && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div
              className="position-relative megezine-model-wrapper"
              style={{
                background: 'url(./images/megezinesCover.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            />
            <div className="about-courses-cetegory">
              <h1>Lorem ipsum dolor sit amet, consectetur.</h1>
              <h2>Health</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare
                vitae habitant justo, id cursus. Amet diam mauris neque ultrices
                at lacinia elementum ullamcorper. Diam volutpat iaculis est,
                pulvinar. Aliquet pellentesque orci hendrerit rutrum enim et id.
                At duis ac consectetur lorem ullamcorper adipiscing. Volutpat
                sagittis, nascetur habitant curabitur ipsum. Porttitor dui fusce
                in libero. Nunc sed lectus rhoncus, praesent urna in. Lectus
                urna in adipiscing ullamcorper amet. Egestas odio commodo sed
                est eu, cursus. In sed sed amet parturient volutpat odio
                posuere. Convallis diam eget orci elit vel libero at ullamcorper
                amet.
              </p>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model">
                  <span onClick={HandleCancel}>{English.G78}</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={HandleMaganizeFinal}
                >
                  {English.G128}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowMegenizePreviewFinal && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div
              className="position-relative megezine-model-final-wrapper"
              style={{
                background: 'url(./images/megenizefinal.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            />
            <div className="about-courses-cetegory">
              <h1>Lorem ipsum dolor sit amet, consectetur.</h1>
              <h2 className="megezines-like-share">
                <button type="button">
                  <img alt="" src={Images.LikeBtn} />
                </button>
                <button type="button">
                  <img alt="" src={Images.ShareBtn} />
                </button>
              </h2>
              <div className="megazines-profile">
                <div className="megazines-profile-detail">
                  <div
                    className="megezines-profile-pic"
                    style={{
                      background: 'url(./images/profile.png)',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <div className="megezines-profile-name">
                    <p className="name">Ankit Muller</p>
                    <p className="designation">Fitness Instructor</p>
                  </div>
                </div>
                <button type="button" className="follow-btn">
                  <img alt="" src={Images.Follow} />
                  Follow
                </button>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Dignissim eget sit aliquet diam. At urna lectus tellus imperdiet
                aliquam in laoreet faucibus sit. Enim, malesuada rutrum eu est.
                Eu, nisl ut dolor, eget egestas viverra. Cursus neque aliquam
                diam sem volutpat scelerisque tempor sed. Magnis risus augue
                augue sit dui, tristique.
              </p>
              <p>
                Ut justo, facilisi nisi ultricies. Ut tellus dui, sapien arcu
                magna ultrices elit, tempor velit. Massa, viverra vitae netus ut
                venenatis neque. Nam ut lacinia elit dui porttitor. Vitae
                euismod nulla vivamus et, id.
                {' '}
              </p>
              <p>
                Convallis velit nunc, sed eget ornare egestas. Lacus praesent
                sed facilisis mattis. Vitae, suscipit urna, tellus amet porta
                dui. Laoreet mi, id iaculis donec laoreet tortor enim, amet
                nunc. Integer duis faucibus dolor lacus, amet, commodo
                consectetur felis, molestie. Vitae, pharetra faucibus eget nec
                in nisl. Purus consequat nulla commodo nunc elementum
                pellentesque adipiscing vivamus adipiscing. Cursus et neque cras
                iaculis. Mauris et amet ullamcorper tellus pellentesque
                phasellus maecenas.
              </p>
              <p>
                Pellentesque enim mauris in pellentesque. Convallis odio dui
                arcu felis condimentum laoreet. Fringilla eleifend amet risus
                fusce vestibulum id egestas fermentum. Lectus eget nulla nunc
                gravida elementum ultricies dictum. Leo consequat laoreet ut
                eget bibendum. Congue etiam etiam mauris pretium adipiscing
                neque. Orci aliquam diam eu vel. Nunc nullam eget ac massa sed.
                Nascetur suspendisse scelerisque quis a egestas morbi amet. Quis
                duis sapien id id mattis turpis. Morbi proin nec eu scelerisque
                dignissim. Varius mattis maecenas quisque vitae cursus egestas
                mattis faucibus netus.
              </p>
              <p>
                Nam morbi non fringilla consequat. Metus magnis adipiscing
                volutpat at vitae. Nibh cursus egestas morbi eu, arcu orci risus
                ac. Duis nec urna sed etiam enim, vulputate. Et interdum elit
                nec lacus in ornare.
              </p>
              <p>
                Nullam in metus lectus vitae mi. Ultricies dictum purus volutpat
                auctor ut. Ornare integer ante urna arcu, et ac malesuada ut
                leo. Mi blandit nec suspendisse congue augue semper sem auctor.
                Sit tellus, pellentesque iaculis sagittis. amet luctus nisl
                consectetur maecenas tortor, bibendum. Tristique bibendum
                convallis sed magnis quis suscipit vulputate adipiscing aliquet.
                Consequat ut lorem dapibus ac. .
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default MagazinesDetails
