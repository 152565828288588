/* eslint-disable no-nested-ternary */
import React from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import CommonButton from '../ButtonComponent/ButtonCommon'
import './StoreImageCroper.css'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const CommonImageUpload = ({
  handleImageChange,
  index,
  buttonText,
  forImage,
  forName,
  className,
  accept = '.jpg,.jpeg,.png',
  selectedImg,
  defaultImg,
  defaultImgCamera,
  name,
  ref,
  id
}) => {
  return (
    <label htmlFor={`input-img-${index}`} className={className}>
      {selectedImg ? (
        forImage === 'profile' ? (
          <>
            <img className="Profile_img mt-3" src={selectedImg} alt="Profile" />
            <div className="input-profile-img-label profile_add_icon cursor-pointer">
              <ImgCrop showGrid rotationSlider aspectSlider showReset>
                <Upload
                  id={id}
                  ref={ref}
                  name={name}
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={(file) => {
                    handleImageChange(file, index)
                    return false
                  }}
                >
                  <img src={defaultImgCamera} alt="Upload Icon" />
                </Upload>
              </ImgCrop>
            </div>
          </>
        ) : forImage === 'create-profile' ? (
          <>
            <img className="Profile_img" src={selectedImg} alt="Profile" />
            <ImgCrop showGrid rotationSlider aspectSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                    handleImageChange(file, index)
                    return false
                  }}
              >
                <div className="input-profile-img-label cursor-pointer">
                  <img src={defaultImgCamera} alt="Upload Icon" />
                </div>
              </Upload>
            </ImgCrop>
          </>
        ) : forImage === 'local_logo' ? (
          <>
            <img className="logo_show_img" src={selectedImg} alt="Preview" />
            <ImgCrop showGrid rotationSlider aspectSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                    handleImageChange(file, index)
                    return false
                  }}
              >
                <div className="edit_logo_local z-1">
                  <label htmlFor="upload-file">
                    <button type="button" className="d-none" />
                  </label>
                </div>
              </Upload>
            </ImgCrop>
          </>
        ) : forImage === English.G290 ? (
          <>
            <img className="logo_show_imgs" src={selectedImg} alt="Preview" />
            <ImgCrop showGrid rotationSlider aspectSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                    handleImageChange(file, index)
                    return false
                  }}
              >
                <div className="edit_logo_local z-1">
                  <label htmlFor="upload-file">
                    <button type="button" className="d-none" />
                  </label>
                </div>
              </Upload>
            </ImgCrop>
          </>
        ) : forImage === 'add_photo' ? (
          <div className="text-center image_view">
            <img src={selectedImg} alt="Uploaded" className="mt-0" />
          </div>
        ) : (
          <div className="text-center image_view">
            <img src={selectedImg} alt="Uploaded" />
            <ImgCrop showGrid rotationSlider aspectSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                  handleImageChange(file, index)
                  return false
                }}
              >
                <button
                  type="button"
                  className="edit-button-model content-meneger-edit"
                />
              </Upload>
            </ImgCrop>
          </div>
        )
      ) : forName ? (
        <div className={forImage === 'image' ? '' : 'container-space'}>
          <div className="upload-img">
            <img src={Images.upload} alt="Upload Icon" />
          </div>
          {forImage === 'video' ? (
            <h4>{English.G215}</h4>
          ) : (forImage === 'image' || forImage === 'add_photo') ? (
            <h4>{English.G216}</h4>
          ) : forImage === 'Hotel' ? (
            <h4>
              {English.G217}
            </h4>
          ) : (
            <h4>
              {English.G218}
            </h4>
          )}
          <p className="drag-drop-text">
            {English.G156}
          </p>
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              { buttonText &&
                <CommonButton
                  label={buttonText}
                  htmlFor={`input-img-${index}`}
                  type="button"
                  className="upload_btn_width"
                />}
            </Upload>
          </ImgCrop>
          {/* <button type="button" htmlFor={`input-img-${index}`}>
                <span>{buttonText}</span>
              </button> */}
        </div>
      ) : forImage === 'profile' ? (
        <div className="cropper-user mt-4">
          <img src={defaultImg} alt="uploading" />
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <div className="input-profile-img-label profile_add_icon cursor-pointer">
                <img src={defaultImgCamera} alt="Upload Icon" />
              </div>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === 'create-profile' ? (
        <div className="cropper-user">
          <img className="default" src={defaultImg} alt="uploading" />
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <div className="input-profile-img-label cursor-pointer">
                <img src={defaultImgCamera} alt="" />
              </div>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === 'local_logo' ? (
        <div className="cropper-user">
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <img className="mx-auto" src={defaultImg} alt="" />
              <h6>{English.G219}</h6>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === English.G290 ? (
        <div className="cropper-user">
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <img className="mx-auto" src={defaultImg} alt="" />
              <h6 className="mb-0">{English.G220}</h6>
            </Upload>
          </ImgCrop>
        </div>
      ) : (
        <label htmlFor="input-img" className={className}>
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <img src={defaultImg} alt="uploading" />
            </Upload>
          </ImgCrop>
          <p className="m-0 upload_image_text">{buttonText}</p>
        </label>
      )}
    </label>
  )
}

export default CommonImageUpload
