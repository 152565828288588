import React, { useState } from 'react'
import './style.css'
import Dropdown from '../Dropdown/Dropdown'
import Images from '../../helpers/Images'

const EventOffer = () => {
  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  const items = [
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Oktoberfest, Mu...',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/7.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'DFB Pokal, Berlin',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/8.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/9.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/10.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Oktoberfest, Mu...',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/11.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'DFB Pokal, Berlin',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/12.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/13.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/10.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/13.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/10.jpg'
    }
  ]
  return (
    <div className="reviews-and-retings">
      <div className="recent-reviews common-card-body recent-video-component mt-40 mb-40">
        <div className="drop-row">
          <div className="drop-sub-row">
            <Dropdown
              title="Categories"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
            <Dropdown
              title="Ratings"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
            <Dropdown
              title="Duration"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
          </div>
          <div className="drop-search">
            <input placeholder="Search here..." />
          </div>
        </div>
        <div className="event-offer-grid mt-30">
          {items.slice(0, visibleItems).map((item) => (
            <div className="event-offer-item position-relative" key={item.name}>
              <div
                className="event-offer-cover"
                style={{
                  background: `url(${item.cover})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="event-thumb--date">
                  <h3>{item.date}</h3>
                  <span>{item.month}</span>
                </div>
                <div className="event-host">
                  {item?.host.map((img, index) => (
                    <img alt="" src={img} key={index} />
                  ))}
                </div>
                <div className="event-name-and-text">
                  <h6>{item.type}</h6>
                  <p>{item.name}</p>
                  <h5>
                    Booked Ticket
                    {item.bookedTicket}
                  </h5>
                </div>
              </div>
              <div className="event-ticket-detail">
                <div className="about-event">{item.text}</div>
                <div>
                  <p className="offer-price-event">{item.offerPrice}</p>
                  <p className="actual-price-event">{item.actualPrice}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={Images.DownIco} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default EventOffer
