import React, { useState } from 'react'
import './style.css'
import Slider from './slider'
import RatingContainer from '../RatingContainer/Rating'
import Dropdown from '../Dropdown/Dropdown'
import Images from '../../helpers/Images'

const ReviewsAndReting = () => {
  const [visibleItems, setVisibleItems] = useState(3)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3)
  }
  const items = [
    {
      profile: './images/profile.png',
      name: 'Kathryn Murphy',
      date: 'May 2021',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus faucibus id platea egestas. Eget vitae at aliquam facilisis. Montes, quam porttit sed fringilla dignissim aliquet. Quisque aenean ullamcorper pharetra purus amet et.'
    },
    {
      profile: './images/profile.png',
      name: 'Kathryn Murphy',
      date: 'May 2021',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus faucibus id platea egestas. Eget vitae at aliquam facilisis. Montes, quam porttit sed fringilla dignissim aliquet. Quisque aenean ullamcorper pharetra purus amet et.'
    },
    {
      profile: './images/profile.png',
      name: 'Kathryn Murphy',
      date: 'May 2021',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus faucibus id platea egestas. Eget vitae at aliquam facilisis. Montes, quam porttit sed fringilla dignissim aliquet. Quisque aenean ullamcorper pharetra purus amet et.'
    }
  ]
  return (
    <div className="reviews-and-retings">
      <Slider />
      <div className="recent-reviews common-card-body recent-video-component mt-40 mb-40">
        <div className="d-flex align-items-center justify-content-between">
          <h3>Recent Reviews</h3>
          <div className="drop-search">
            <input placeholder="Search here..." />
          </div>
        </div>
        <div className="drop-row">
          <div className="drop-sub-row">
            <Dropdown
              title="Categories"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
            <Dropdown
              title="Ratings"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
            <Dropdown
              title="Duration"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
          </div>
        </div>
        <div className="review-profile-container">
          {items.slice(0, visibleItems).map((item) => (
            <div
              className="review-profile-item common-card-body mt-30"
              key={item.name}
            >
              <div className="review-profile-information">
                <div className="d-flex col-gap-20 align-items-center">
                  <img alt="" src={item.profile} />
                  <ul className="">
                    <li className="name">{item.name}</li>
                    <li className="review-date">{item.date}</li>
                  </ul>
                </div>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={Images.DownIco} />
            </button>
          </div>
        )}
      </div>
      <RatingContainer />
    </div>
  )
}
export default ReviewsAndReting
