import React from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../../../helpers'

const OtherUserTypeComponent = ({ image, icon, userTypeTitle, usertTyleSubTitle }) => {
  return (
    <div className="bg-lightGradient rounded-[32px] !p-4 !pb-0">
      <div className="aspect-[1.65/1] bg-DarkBlue flex items-center justify-center rounded-[32px]">
        <img className="mw-100" src={image} alt="" />
      </div>
      <div className="w-2/3 border-[0.5px] border-BorderLight rounded-[15px] !py-4 px-10 max-[1440px]:!px-4 max-[1440px]:w-10/12">
        <div className="flex items-center !gap-4 pb-2.5">
          <div>
            <img className="w-16" src={icon} alt="" />
          </div>
          <div>
            <h4 className="text-2xl font-extrabold italic bg-clip-text text-transparent bg-primaryGradient leading-none mb-0 pe-2">{userTypeTitle}</h4>
            <p className="mb-0 font-bold italic text-base bg-clip-text text-transparent bg-primaryGradient leading-none pe-2">{usertTyleSubTitle}</p>
          </div>
        </div>
        <Link className="text-lg italic font-extrabold bg-clip-text text-transparent bg-primaryGradient pe-2 flex items-center justify-between">
          MEHR INFORMATIONEN
          <img className="rotate-45" src={Images.ButtonAerrow} alt="" />
        </Link>
      </div>
    </div>
  )
}

export default OtherUserTypeComponent
