/* eslint-disable max-len */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'

const InspirationsScreen = () => {
  const navigate = useNavigate()

  const initialApps = [
    {
      id: 1,
      image: Images.Inspirations_image_1,
      text: 'FASHION'
    },
    {
      id: 2,
      image: Images.Inspirations_image_2,
      text: 'TIERE'
    },
    {
      id: 3,
      image: Images.Inspirations_image_3,
      text: 'BEAUTY'
    },
    {
      id: 4,
      image: Images.Inspirations_image_3,
      text: 'BEAUTY'
    },
    {
      id: 5,
      image: Images.Inspirations_image_1,
      text: 'FASHION'
    },
    {
      id: 6,
      image: Images.Inspirations_image_2,
      text: 'TIERE'
    },
    {
      id: 7,
      image: Images.Inspirations_image_3,
      text: 'BEAUTY'
    },
    {
      id: 8,
      image: Images.Inspirations_image_3,
      text: 'BEAUTY'
    }

  ]

  const categories = [
    { id: 1, image: Images.Dating_icon, label: 'Dating' },
    { id: 2, image: Images.Sports_icon, label: 'Sports' },
    { id: 3, image: Images.Luxry_icon, label: 'Luxury' },
    { id: 4, image: Images.Relaxing_icon, label: 'Relaxing' },
    { id: 5, image: Images.Fashion_icon, label: 'Fashion' },
    { id: 6, image: Images.Food_icon, label: 'Food' },
    { id: 7, image: Images.Business_iconI, label: 'Business' },
    { id: 8, image: Images.Travel_icon, label: 'Travel' }
  ]

  return (
    <div className="dashboard">
      <div className="p-[20px] pt-0">
        <div className="p-0 bg-gray-50 ">
          <p className="text-xs font-medium text-[#0B0A0A]">
            <span
              className="text-sm font-medium cursor-pointer"
              onClick={() => navigate('/dashboard')}
            >
              Home
            </span>
            /
            <span className="text-sm font-bold">Inspiration</span>
          </p>
          <div className="flex justify-between items-center mb-[20px]">
            <div className="text-gradient font-extrabold pr-1 text-[34px] italic uppercase">
              {English.A150}
            </div>
          </div>
          <div className="w-full overflow-x-auto scrollbar-hide mb-[20px]">
            <div className="flex space-x-4 px-4 py-2">
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-center py-[16px] pl-[16px] pr-[20px]
                        rounded-lg bg-[#F9F9F9] shadow-md hover:scale-105 transition-transform 
                        ${
                          category.label === 'Luxury'
                            ? 'bg-[#1a2f90] text-[#fff]'
                            : ''
                        }`}
                >
                  <div className="flex gap-[16px]">
                    <img className="" src={category.image} alt="" />
                    <span className="text-gradient text-lg font-semibold">
                      {category.label}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {initialApps.map((i) => (
              <div
                key={i.id}
                className={`w-[100%] h-auto p-[12px] rounded-2xl bg-white ${
                  i.isSelected ? 'selected' : 'selectable'
                }`}
              >
                <div className="flex flex-col gap-3">
                  <div>
                    <div className="flex flex-col gap-2">
                      <div className="mx-auto text-center">
                        <img className="" src={i.image} alt="" />
                      </div>
                      <h2 className="italic font-black text-lg text-gradient text-center">
                        {i.text}
                      </h2>
                    </div>
                    <div className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer">
                      <div
                        className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
                        type="button"
                      >
                        LEARN MORE
                      </div>
                      <img
                        className="ml-1 mt-2"
                        src={Images.arrow_left_black}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InspirationsScreen
