import React from 'react'
import { English, Images } from '../../../../helpers'

const FlowComponent = () => {
  return (
    <div className="mt-16">
      <div className="grid grid-cols-4 gap-7 max-[1440px]:!gap-4">
        <div className="border border-BorderLight rounded-[28px] !p-5 bg-white">
          <div className="aspect-[1.17/1] bg-DarkBlue border-[0.5px] border-BorderLight rounded-[28px] flex items-center justify-center">
            <img className="mw-100" src={Images.Username} alt="" />
          </div>
          <div className="!pt-5">
            <h4 className="bg-clip-text text-transparent bg-primaryGradient font-black italic text-[28px] text-center max-[1440px]:text-[20px]">{English.B47}</h4>
            <p className="bg-clip-text text-transparent bg-primaryGradient text-xl text-center mb-0 max-[1440px]:text-sm">{English.B48}</p>
          </div>
        </div>
        <div className="border border-BorderLight rounded-[28px] !p-5 bg-white">
          <div className="aspect-[1.17/1] bg-DarkBlue border-[0.5px] border-BorderLight rounded-[28px] flex items-center justify-center">
            <img className="mw-100" src={Images.Password} alt="" />
          </div>
          <div className="!pt-5">
            <h4 className="bg-clip-text text-transparent bg-primaryGradient font-black italic text-[28px] text-center max-[1440px]:text-[20px]">{English.B49}</h4>
            <p className="bg-clip-text text-transparent bg-primaryGradient text-xl text-center mb-0 max-[1440px]:text-sm">{English.B50}</p>
          </div>
        </div>
        <div className="border border-BorderLight rounded-[28px] !p-5 bg-white">
          <div className="aspect-[1.17/1] bg-DarkBlue border-[0.5px] border-BorderLight rounded-[28px] flex items-center justify-center">
            <img className="mw-100" src={Images.Verifying} alt="" />
          </div>
          <div className="!pt-5">
            <h4 className="bg-clip-text text-transparent bg-primaryGradient font-black italic text-[28px] text-center max-[1440px]:text-[20px]">{English.B51}</h4>
            <p className="bg-clip-text text-transparent bg-primaryGradient text-xl text-center mb-0 max-[1440px]:text-sm">{English.B52}</p>
          </div>
        </div>
        <div className="border border-BorderLight rounded-[28px] !p-5 bg-white">
          <div className="aspect-[1.17/1] bg-DarkBlue border-[0.5px] border-BorderLight rounded-[28px] flex items-center justify-center">
            <img className="mw-100" src={Images.Abo} alt="" />
          </div>
          <div className="!pt-5">
            <h4 className="bg-clip-text text-transparent bg-primaryGradient font-black italic text-[28px] text-center max-[1440px]:text-[20px]">{English.B53}</h4>
            <p className="bg-clip-text text-transparent bg-primaryGradient text-xl text-center mb-0 max-[1440px]:text-sm">{English.B54}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlowComponent
