import React from 'react'
import Marquee from 'react-fast-marquee'
import { Images } from '../../../../helpers'

const MarqueeComponent = () => {
  return (
    <>
      <div className="mb-10">
        <Marquee>
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.CreatorTalentsBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.AZFitBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.FyerdatesBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.FiliyoBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.ScalcoBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.FamilyTrendsBlue} alt="" />
        </Marquee>
      </div>
      <div>
        <Marquee direction="right">
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.GoAthleteBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.TheTasteClub} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.TestieeBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.PetzinoBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.SpacureBlue} alt="" />
          <img className="!mx-5 max-[1600px]:w-48 max-[1600px]:h-48" src={Images.LuxuryElevenBlue} alt="" />
        </Marquee>
      </div>
    </>
  )
}

export default MarqueeComponent
