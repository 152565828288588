/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable dot-notation */
/* eslint-disable import/no-useless-path-segments */
import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { Avatar, Select } from 'antd'
import countries from '../../Counties.json'
import SideBarPage from './SidebarPage'
import '../../App.css'
import { dataDecrypt, setUserSliceData } from '../../services/http-services'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import Utility, {
  preventInvalidKeydown,
  preventInvalidPaste,
  preventMultipleAtSignKeydown,
  preventMultipleAtSignPaste,
  preventSpaceInput,
  preventSpacePaste
} from '../../helpers/Utility'
import NumberValidation from '../../components/NumberValidation/NumberValidation'
import { APICall, EndPoints } from '../../services'
import { English, Images } from '../../helpers'

const SignUpPage = () => {
  const navigate = useNavigate()
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [inputFields, setInputFields] = useState({
    user_name: '',
    email: '',
    mobile_no: '',
    password: '',
    confirm_password: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const { Option } = Select

  const countryList = countries
    .filter((it) => it.idd.suffixes[0])
    .map((country) => ({
      name: country.name.common,
      code: country.cca2, // ISO 3166-1 alpha-2
      dialCode:
        country.idd.root +
        (country.idd.suffixes ? country.idd.suffixes[0] : ''),
      flag: `https://flagcdn.com/w320/${country.cca2.toLowerCase()}.png`
    }))

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2)
  }

  const [selectCode, setSelectedCode] = useState(
    countryList?.find((country) => country?.name === 'Germany')?.dialCode
  )
  const selectRef = useRef(null)

  const handleSelectChange = (value) => {
    setSelectedCode(value)
    selectRef.current?.blur()
    setInputFields({
      ...inputFields,
      mobile_no: ''
    })
  }

  // const isEmailValid = (value) => {
  //   const reg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w+)+$/
  //   return !value.trim() || !reg.test(value.trim())
  // }

  const validateValues = (inputValues) => {
    const errors = {}
    if (!inputValues.user_name.match(/^[A-Za-z0-9._]+$/)) {
      errors.user_name = English.G23
    }
    if (
      !inputValues.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errors.email = English.G1
    }

    const regexMobile = NumberValidation[(countryList.find((item) => selectCode == item.dialCode) || countryList[0]).code]
    // const regexMobile = /^[0-9]+$/
    if (!inputValues.mobile_no.match(regexMobile)) {
      errors.mobile_no = English.G24
    }
    const regexPass =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/
    if (!inputValues.password.match(regexPass)) {
      errors.password = English.G25
    }
    if (inputValues.password !== inputValues.confirm_password) {
      errors.confirm_password = English.G26
    }

    return errors
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputFields((prevFields) => {
      const updatedFields = { ...prevFields, [name]: value }

      const updatedErrors = { ...errors }

      if (name === 'email') {
        if (
          updatedFields.email.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) ||
          submitting
        ) {
          delete updatedErrors.email
        }
      }

      if (name === 'password') {
        if (
          updatedFields.password.match(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/
          )
        ) {
          delete updatedErrors.password
        }
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        }
      }

      if (name === 'confirm_password') {
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        } else {
          // updatedErrors.confirm_password = 'Password miss match'
        }
      }

      setErrors(updatedErrors)
      return updatedFields
    })
  }

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked
    setInputFields((prevFields) => ({
      ...prevFields,
      default_usename: isChecked
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const validationErrors = validateValues(inputFields)
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      setSubmitting(true)
    }
  }

  const loginApi = () => {
    const data = {
      email: inputFields.email,
      password: inputFields.password,
      app_id: 2,
      is_gbn: false
    }
    APICall('post', data, EndPoints.login, 'auth')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          navigate('/otp-verification')
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    if (submitting) {
      setLoading(true)
      inputFields.country_code = selectCode

      APICall('post', inputFields, EndPoints.register, 'auth')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            const decryptedData = dataDecrypt(res?.data?.data)
            const tokenData = Utility.deepClone(res?.data?.token)
            setUserSliceData(tokenData, 'token')
            setUserSliceData(decryptedData, 'userInfo')
            loginApi()
            // navigate('/otp-verification')
            setLoading(false)
          } else {
            toast.error(res.data?.message)
            setLoading(false)
            setSubmitting(false)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
          setLoading(false)
          setSubmitting(false)
        })
    }
  }, [submitting, inputFields, navigate])

  useEffect(() => {
    setIsFormValid(
      inputFields.user_name.length > 0 &&
        inputFields.email.length > 0 &&
        inputFields.mobile_no.length > 0 &&
        inputFields.password.length > 0 &&
        inputFields.confirm_password.length > 0
    )
  }, [inputFields])

  return (
    <div className="auth-register">
      <div className="bg-overlay row overlay-height">
        <div className="col-md-6 rounded-3xl overflow-hidden pt-3 pb-3">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 overflow-y-auto pt-3 pb-3">
          <div className="inline-padding d-flex flex-col bg-white rounded-2xl h-full mr-5">
            <div className="text-center text-4xl italic font-black bg-primaryGradient bg-clip-text text-transparent pt-[20px]">
              {English.B24}
            </div>
            <div className="text-center text-3xl italic font-black bg-primaryGradient bg-clip-text text-transparent pb-[30px]">
              {English.B25}
            </div>
            <form onSubmit={handleSubmit}>
              <p>
                {/* <label className="clip-text">{English.G15}</label> */}
                <div className="user-input input-container mb-3">
                  <img
                    src={Images.input_logo}
                    alt="Logo"
                    className="input-logo !w-[30px] !h-[33px]"
                  />
                  <CommonInput
                    name="user_name"
                    required
                    placeholder={English.G6}
                    value={inputFields.user_name}
                    onChange={handleChange}
                    onKeyDown={preventInvalidKeydown}
                    onPaste={preventInvalidPaste}
                  />
                </div>
                {errors.user_name ? (
                  <p className="error-text">{errors.user_name}</p>
                ) : null}
              </p>
              <p className="d-flex gap-2">
                <div className="flex items-center font-normal text-xs text-inactive ml-4">
                  <input
                    id="inline-2-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    defaultChecked={inputFields.default_usename}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <span className="font-normal text-xs text-inactive ml-4">
                  {English.B26}
                </span>
              </p>
              <p>
                {/* <label className="clip-text">{English.G16}</label> */}
                <div className="email-input input-container mb-4">
                  <img
                    src={Images.emailNew}
                    alt="Logo"
                    className="input-logo"
                  />
                  <CommonInput
                    type="email"
                    name="email"
                    placeholder={English.G17}
                    value={inputFields.email}
                    onChange={handleChange}
                    required
                    onKeyDown={(e) => preventMultipleAtSignKeydown(e, inputFields.email)}
                    onPaste={preventMultipleAtSignPaste}
                  />
                </div>
                {errors.email ? (
                  <p className="error-text">{errors.email}</p>
                ) : null}
              </p>
              <p>
                {/* <label className="clip-text">{English.G18}</label> */}
                <div className="mobile-container d-flex rounded-full mb-4 pl-4 pr-4 h-[60px]">
                  <div className="flex flex-row items-center">
                    <div className="inline-flex items-center gap-3">
                      <Avatar
                        className="!h-[30px] !w-[48px] rounded-3xl object-cover"
                        src={
                          (
                            countryList.find(
                              (item) => selectCode === item.dialCode
                            ) || countryList[0]
                          ).flag
                        }
                        alt={countryList[0].name}
                      />
                      <Select
                        showSearch
                        ref={selectRef}
                        value={selectCode}
                        onChange={handleSelectChange}
                        className="custom-select w-56 "
                        dropdownClassName="custom-dropdown"
                        placeholder="Select a country"
                        optionFilterProp="children"
                        suffixIcon={<span className="custom-down-arrow"><img src={Images.down_arrow_icon} alt="down" /></span>}
                        filterOption={(input, option) => option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())}
                      >
                        {countryList.map((country) => (
                          <Option
                            key={country.code}
                            value={country.dialCode}
                            label={country.dialCode}
                          >
                            {`${country.dialCode}  ${country.name}`}
                          </Option>
                        ))}
                      </Select>
                      <input
                        value={inputFields.mobile_no}
                        placeholder={English.G19}
                        type="number"
                        name="mobile_no"
                        className="mobile-input"
                        required
                        onKeyDown={preventInvalidKeydown}
                        onPaste={preventMultipleAtSignPaste}
                        onChange={(e) => {
                          const codec = (countryList.find((item) => selectCode == item.dialCode) || countryList[0]).code
                          const regexMobile = NumberValidation[codec]
                          if (!inputFields.mobile_no.match(regexMobile)) {
                            // setErrors({
                            //   ...errors,
                            //   mobile_no: English.G24
                            // })
                            setInputFields({
                              ...inputFields,
                              mobile_no: e.target.value
                            })
                          }
                          if (e.target.value.match(regexMobile)) {
                            setErrors({
                              ...errors,
                              mobile_no: ''
                            })
                          }
                          if (inputFields.mobile_no.length > e.target.value.length) {
                            setInputFields({
                              ...inputFields,
                              mobile_no: e.target.value
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {errors.mobile_no ? (
                  <p className="error-text">{errors.mobile_no}</p>
                ) : null}
              </p>
              <p>
                {/* <label className="clip-text">{English.G7}</label> */}
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="Logo"
                    className="input-logo"
                  />
                  <CommonInput
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder={English.G8}
                    required
                    value={inputFields.password}
                    onChange={handleChange}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={Images.eyeOn} />
                    ) : (
                      <img alt="" src={Images.eyeOff} />
                    )}
                  </button>
                </div>
                {errors.password ? (
                  <p className="error-text">{errors.password}</p>
                ) : null}
              </p>
              <p>
                {/* <label className="clip-text">{English.G20}</label> */}
                <div className="password-input input-container mb-4">
                  <img
                    src={Images.lock_icon}
                    alt="Logo"
                    className="input-logo"
                  />
                  <CommonInput
                    type={showPassword2 ? 'text' : 'password'}
                    required
                    name="confirm_password"
                    placeholder={English.G08}
                    value={inputFields.confirm_password}
                    onChange={handleChange}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                  />
                  <button onClick={togglePasswordVisibility2} type="button">
                    {showPassword2 ? (
                      <img alt="" src={Images.eyeOn} />
                    ) : (
                      <img alt="" src={Images.eyeOff} />
                    )}
                  </button>
                </div>
                {errors.confirm_password ? (
                  <p className="error-text">{errors.confirm_password}</p>
                ) : null}
              </p>
              <p>
                {/* <Link to="/user-information"> */}
                <CommonButton
                  id="sub_btn"
                  label={English.G21}
                  loading={loading}
                  type="submit"
                  className="primary-button m-auto custom-mt !max-w-none"
                  disabled={!isFormValid || loading}
                  context="primary"
                  isPrimary
                />
                {/* </Link> */}
              </p>
            </form>
            <footer className="flex-1 d-flex flex-col">
              <p className="text-secondary text-xs mb-3">
                {English.G22}
                {' '}
              </p>
              <div className="flex flex-1 flex-row justify-center text-2xl font-black italic bg-primaryGradient bg-clip-text text-transparent uppercase">
                <Link className="text-transparent" to="/login">
                  {English.G45}
                </Link>
              </div>
              <div className="text-[10px] text-placeholder text-center items-center justify-center py-8 !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
                erat enim mattis nunc, eget volutpat. Aenean sed urna, facilisi
                vitae, volutpat faucibus.enim mattis nunc, eget volutpat. Aenean
                sed urna, facilisi vitae, volutpat faucibus
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignUpPage
