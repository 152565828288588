import React from 'react'
import './ServiceCenter.css'
import { Link } from 'react-router-dom'
import SupportCetegory from './SupportCetegory'
import Faq from './Faq'
import Images from '../../helpers/Images'

const Support = () => {
  return (
    <div className="creator-profile-main">
      <div className="service-center-main-container">
        <img alt="" src={Images.AuthLogo} />
        <h1>How Can We Help You?</h1>
        <div className="position-input">
          <input placeholder="Describe your issue" />
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra
          luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque
          nullam sit amet, lorem.
        </p>
        <Link>Learn more</Link>
      </div>

      <div className="support-cetegory">
        <SupportCetegory />
        <Faq />
      </div>
    </div>
  )
}
export default Support
