import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHeadStore from '../ProfileBuilder/ProfileHeadStore'
import Feeds from '../Feeds/AddFeeds'
import LocalHome from './LocalHome'
import PopularDishes from './PopularDishes'
import Services from './Services'
import MapLocal from './MapLocal'
import BussinessProfileRating from '../ReviewsAndRating/BussinessProfileRating'
import BussinessProfileRatingContainer from '../RatingContainer/BussinessProfileRatingContainer'
import MenuDeals from './MenuDeals'
import Starter from './Menu/Starter'
import Main from './Menu/Main'
import Dessert from './Menu/Dessert'
import Drinks from './Menu/Drinks'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const LocalGastronomy = () => {
  return (
    <div className="creator-profile-main creator-profile-create">
      <ProfileHeadStore />
      <div className=" mt-30">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.HomeIco} />
                  {English.G222}
                </Tab>
                <Tab>
                  <img alt="" src={Images.FeedIco} />
                  {English.G223}
                </Tab>
                <Tab>
                  <img alt="" src={Images.DealIco} />
                  {English.G298}
                </Tab>
                <Tab>
                  <img alt="" src={Images.BookTableIco} />
                  {English.G451}
                </Tab>
                <Tab>
                  <img alt="" src={Images.AwardsIco} />
                  {English.G452}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <LocalHome />
                </div>
                <div className="mt-40">
                  <PopularDishes />
                </div>
                <div className="mt-40">
                  <Services />
                </div>
                <div className="mt-40">
                  <MapLocal />
                </div>
                <div className="mt-40">
                  <BussinessProfileRatingContainer />
                </div>
                <div className="mt-40">
                  <BussinessProfileRating />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Feeds />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <div className="mt-40">
                    <MenuDeals />
                  </div>
                  <div className="mt-40">
                    <Starter />
                  </div>
                  <div className="mt-40">
                    <Main />
                  </div>
                  <div className="mt-40">
                    <Dessert />
                  </div>
                  <div className="mt-40">
                    <Drinks />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="add-item-content">
                  <div className="item-details-input pt-0">
                    <div className="input-grid">
                      <div>
                        <label>Your Name</label>
                        <input placeholder="Enter name" />
                      </div>
                      <div>
                        <label>Your Email</label>
                        <input placeholder={English.G185} />
                      </div>
                    </div>
                    <div className="input-grid">
                      <div>
                        <label>{English.G186}</label>
                        <input placeholder="Enter phone #" />
                      </div>
                      <div>
                        <label>How many people</label>
                        <input placeholder="Enter adults" />
                      </div>
                    </div>

                    <div className="text-center mt-20">
                      <button type="button" className="primary-btn">Reserve a Table</button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <BussinessProfileRatingContainer />
                </div>
                <div className="mt-40">
                  <BussinessProfileRating />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LocalGastronomy
