import React from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../../../helpers'
import PrimaryButton from '../PrimaryButton'

const CompanyDetailComponent = () => {
  return (
    <>
      <div className="grid grid-cols-7 gap-y-14 gap-x-2.5 max-[1200px]:grid-cols-5">
        <div className="col-span-2">
          <img src={Images.AppWorldOne} alt="" />
          <p className="text-base font-extrabold italic bg-clip-text text-transparent bg-primaryGradient mb-0">GBN ONE TECHNOLOGIES GMBH</p>
          <p className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient mb-0">Mittelweg 144,</p>
          <p className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient mb-0">20148 Hamburg</p>
          <p className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient mb-0">Germany</p>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">COMPANY</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">About</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Career</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">News</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Contact</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">BUSINESS</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Create Business ID</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Business Manager</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Wallet</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Pricing</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">CREATOR</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Monetization</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Affiliate System</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Jobs</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Content Production</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">MEMBER</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Create Member ID</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Points</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Pro Apps</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Pricing</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">OFFICE</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Alte Rabenstraße 2</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">20148</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Hamburg</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Germany</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">ADD ONS</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Local</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Store</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Entertainment</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Community</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Guide</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Service</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">UPGRADES</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Locations</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Products</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Storage</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Job Listings</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Pages</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Agency Services</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">USE CASES</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Online Shops</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Restaurants</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Hotels</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Service Provider</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Agencies</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Events</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">AD SYSTEM</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Formats</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Reporting</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Insights</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Publishing Network</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Target Groups</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Native Ads</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">PARTNER</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Premium Partner</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Cross Selling</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Publishing Partner</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Original Content</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Strategic Partner</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Investors</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">SUPPORT</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Q&A</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Help Desk</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Premium  Support</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Support Levels</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Find an Expert</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Cancel Subscription</Link>
        </div>
        <div className="flex flex-col !gap-3">
          <h3 className="italic text-2xl font-extrabold bg-clip-text text-transparent bg-primaryGradient mb-0">COMPANY</h3>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Privacy</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">AGB</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Imprint</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Countries</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Policies</Link>
          <Link className="text-base font-medium bg-clip-text text-transparent bg-primaryGradient">Legal</Link>
        </div>
      </div>
      <div className="my-14 pt-8 pb-14 border-y border-white flex items-center justify-between">
        <div>
          <h2 className="text-[35px] font-extrabold italic leading-none !mb-5 bg-clip-text text-transparent bg-primaryGradient">GET IN TOUCH</h2>
          <div className="flex items-center">
            <input className="w-[400px] max-[1100px]:w-[300px] bg-lightGradient text-lg h-11 rounded-l-[41px] px-10 -me-5" placeholder="Enter your E-Mail address..." />
            <PrimaryButton
              txtClass="uppercase text-lg"
              text="REQUEST CALL"
            />
          </div>
        </div>
        <div className="flex items-center !gap-4">
          <img src={Images.Switzerland} alt="" />
          <img src={Images.Germany} alt="" />
          <img src={Images.Usa} alt="" />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-7 mb-14">
        <div className="rounded-[30px] bg-electricWhite p-14">
          <label className="bg-electricWhite w-[565px] mx-auto italic text-[35px] font-extrabold text-center rounded-[42px] block mb-7 max-[1440px]:px-6 max-[1440px]:w-auto max-[1440px]:text-2xl">
            <span className="bg-clip-text text-transparent bg-primaryGradient leading-none pe-2">APP WORLD ONE</span>
          </label>
          <img src={Images.AppWorldOneApps} alt="" />
        </div>
        <div className="rounded-[30px] bg-electricWhite p-14">
          <label className="bg-electricWhite w-[565px] mx-auto italic text-[35px] font-extrabold text-center rounded-[42px] block mb-7 max-[1440px]:px-6 max-[1440px]:w-auto max-[1440px]:text-2xl">
            <span className="bg-clip-text text-transparent bg-primaryGradient leading-none pe-2">SERVICE NETWORK</span>
          </label>
          <img src={Images.ServiceNetwork} alt="" />
        </div>
      </div>
      <div className="border-t border-white py-10 flex items-center justify-between">
        <div className="font-bold text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1440px]:text-lg">
          <Link className="font-bold text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1440px]:text-lg">Imprint</Link>
          {' '}
          |
          {' '}
          <Link className="font-bold text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1440px]:text-lg">Terms & Conditions</Link>
          {' '}
          |
          {' '}
          <Link className="font-bold text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1440px]:text-lg">Privacy</Link>
        </div>
        <div className="font-bold text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1440px]:text-lg">GBN ONE TECHNOLOGIES GmbH</div>
        <div className="font-bold text-2xl bg-clip-text text-transparent bg-primaryGradient max-[1440px]:text-lg">© 2024 All rights reserved.</div>
      </div>
    </>
  )
}

export default CompanyDetailComponent
