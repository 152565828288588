import React from 'react'
import English from '../../helpers/English'

const Booking = () => {
  return (
    <div
      className="restaurant-booking-form"
      style={{
        background: 'url(./images/82ef92876b890be164257bfcb2f9e8c9.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <form>
        <h3>Book a Table</h3>
        <div className="input-grid-restaurant">
          <div>
            <label>Your Name</label>
            <input placeholder="Enter name" />
          </div>
          <div>
            <label>Email</label>
            <input placeholder="e.g michelle.rivera@example.com" />
          </div>
        </div>
        <div className="input-grid-restaurant">
          <div>
            <label>{English.G186}</label>
            <input placeholder="Enter phone number" />
          </div>
          <div>
            <label>{English.G187}</label>
            <input placeholder={English.G62} />
          </div>
        </div>
        <div className="input-grid-restaurant">
          <div>
            <label>Number of Adults</label>
            <input placeholder="Please enter" />
          </div>
          <div>
            <label>Number of Childrens</label>
            <input placeholder="Please enter" />
          </div>
        </div>
        <button type="button" className="light-button mt-20">
          <span>Reserve a Table</span>
        </button>
      </form>
    </div>
  )
}
export default Booking
