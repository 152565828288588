/* eslint-disable no-undef */
import { postData } from './http-services'
import EndPoints from './EndPoints'

// const urlAuth = process.env.REACT_APP_URL_AUTH
const urlContentManager = process.env.REACT_APP_URL_CONTENT_MANAGER

export const GOOGLE_MAP_LIBRARIES = ['places']
export const s3ImagePath = process.env.REACT_APP_S3_IMAGE_PATH
export const s3ImagePathCreator = process.env.REACT_APP_S3_IMAGE_PATH_CREATOR
export const s3ImagePathLocal = process.env.REACT_APP_S3_IMAGE_PATH_LOCAL
export const locationSuggestion = process.env.REACT_APP_LOCATION_SUGGESTION
export const locationSuggestionLocal = process.env.REACT_APP_LOCATION_SUGGESTION_LOCAL
export const urlImageBannerPathStore = process.env.REACT_APP_GET_BANNER
export const urlImageProductPathStore = process.env.REACT_APP_GET_STORE_PRODUCT
export const urlSystemIcon = process.env.REACT_APP_SYSTEM_ICON
export const s3ImageVideo = process.env.REACT_APP_S3_VIDEO_PATH
export const s3ImagePodcast = process.env.REACT_APP_S3_PODCAST_PATH
export const s3ImageShots = process.env.REACT_APP_S3_SHOTS_PATH
export const s3ImageGiveaway = process.env.REACT_APP_S3_GIVEAWAY_PATH
export const s3ImagePostFeed = process.env.REACT_APP_S3_POST_FEED_PATH
export const s3ImageStory = process.env.REACT_APP_S3_STORY_PATH
export const s3ImageHotel = process.env.REACT_APP_S3_HOTEL_PATH
export const s3ImageRestaurant = process.env.REACT_APP_S3_RESTAURANT_PATH
export const s3ImageOrganizer = process.env.REACT_APP_S3_ORGANIZER_PATH
export const s3ImageLocalIcon = process.env.REACT_APP_S3_SYSTEM_ICON_LOCAL
export const s3ImageProfile = process.env.REACT_APP_S3_PROFILE_IMAGE

export const addAwardData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addAward}`, data)
}
export const getAwardsLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getAwardsLibrary}`, data)
}
export const editAwardData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editAward}`, data)
}
export const deleteAwardData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deleteAward}`, data)
}
export const getAwardsData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getAwards}`, data)
}
export const awardsUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.awardsUnpublish}`, data)
}
