import React from 'react'
import './ProfileServices.css'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'

const ProfileServices = () => {
  const items = [
    {
      cover: './images/service/1.jpg',
      name: 'Personal Training'
    },
    {
      cover: './images/service/2.jpg',
      name: 'Aerobics'
    },
    {
      cover: './images/service/3.jpg',
      name: 'Yoga'
    }
  ]
  return (
    <div className="services-container common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        {English.G224}
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="service-grid">
        {items.map((item) => (
          <div
            className="services-item"
            key={item.name}
            style={{
              background: `url(${item.cover})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <h4>{item.name}</h4>
          </div>
        ))}
      </div>
    </div>
  )
}
export default ProfileServices
