import React, { useState } from 'react'
import './style.css'
import ReactDatePicker from 'react-datepicker'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const Booking = () => {
  const [startDate, setStartDate] = useState(new Date())
  return (
    <div className="">
      <div className="hotel-resrve">
        <div className="hotel-reserve-detail d-flex justify-content-between">
          <div className="hotel-reserve-detail--header">
            <h4>Four Seasons Hotel Gresham Palace Suites</h4>
            <p>
              2nd street, clubs road
              <div className="rating">
                <img src={Images.RatingStar} alt="" />
                <span>4.9</span>
              </div>
            </p>
          </div>
          <div className="">
            <button type="button" className="d-flex align-items-center col-gap-10 primary-btn w-4 justify-content-center">
              <img src={Images.calendar} alt="" />
              Book Now
            </button>
          </div>
        </div>
      </div>

      <div className="services-container  recent-video-component position-relative mt-30">
        <div className="grid-gellery position-relative">
          <div className="gellery-item grid-1--span-4">
            <img src="./images/gellery/1.jpg" alt="" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img src="./images/gellery/2.jpg" alt="" />
            <div className="overlay-gredient" />
          </div>
          <div className="gellery-item">
            <img src="./images/gellery/3.jpg" alt="" />
            <div className="overlay-gredient" />
          </div>
          <button type="button" className="show-photos">
            <img src={Images.ShowIco} alt="" />
            <span>Show all photos</span>
          </button>
        </div>
      </div>

      <div className="mt-30 common-card-body recent-video-component">
        <h3>Booking</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin iaculis
          morbi tempus volutpat tortor. Ipsum vitae enim sit suscipit in
          ridiculus. Morbi potenti eu facilisis vitae posuere suspendisse.
          Praesent sit lorem amet posuere vel diam scelerisque a. Nisl imperdiet
          egestas dui consequat, diam et, at tempor.
        </p>
        <form className="item-details-input">
          <div className="input-grid">
            <div>
              <label>Your Name</label>
              <input placeholder="Enter name" />
            </div>
            <div>
              <label>Email</label>
              <input placeholder="e.g michelle.rivera@example.com" />
            </div>
          </div>
          <div className="input-grid">
            <div>
              <label>{English.G186}</label>
              <input placeholder="Enter phone number" />
            </div>
            <div>
              <label>No of Guests</label>
              <Dropdown
                title="Please select"
                Data={[
                  { title: '1' },
                  { title: '2' },
                  { title: '3' },
                  { title: '4' },
                  { title: '5' }
                ]}
              />
            </div>
          </div>
          <div className="input-grid">
            <div>
              <label>Check In</label>
              <div className="position-relative date-right-icon">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd MMMM yyyy"
                />
              </div>
            </div>
            <div>
              <label>Check Out</label>
              <div className="position-relative date-right-icon">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd MMMM yyyy"
                />
              </div>
            </div>
          </div>
          <div>
            <label>Your Message</label>
            <textarea placeholder={English.G83} />
          </div>
          <button type="button" className="w-4 primary-btn">Submit</button>
        </form>
      </div>
    </div>
  )
}
export default Booking
