import React, { useState } from 'react'
import Images from '../../helpers/Images'

const Offers = () => {
  const items = [
    {
      img: './images/f2b3bf3133d50a6ba4e31b5062372975.jpg',
      name: 'Discount for Burgers and Drinks',
      discount: 'GIFT VOUCHER Upto 30%',
      valid: 'Valid from Oct 20 - Oct 30',
      terms: 'Et facilisi a dictum ipsum ultricies diam.'
    },
    {
      img: './images/f2b3bf3133d50a6ba4e31b5062372975.jpg',
      name: 'Discount for Burgers and Drinks',
      discount: 'GIFT VOUCHER Upto 30%',
      valid: 'Valid from Oct 20 - Oct 30',
      terms: 'Et facilisi a dictum ipsum ultricies diam.'
    },
    {
      img: './images/f2b3bf3133d50a6ba4e31b5062372975.jpg',
      name: 'Discount for Burgers and Drinks',
      discount: 'GIFT VOUCHER Upto 30%',
      valid: 'Valid from Oct 20 - Oct 30',
      terms: 'Et facilisi a dictum ipsum ultricies diam.'
    },
    {
      img: './images/f2b3bf3133d50a6ba4e31b5062372975.jpg',
      name: 'Discount for Burgers and Drinks',
      discount: 'GIFT VOUCHER Upto 30%',
      valid: 'Valid from Oct 20 - Oct 30',
      terms: 'Et facilisi a dictum ipsum ultricies diam.'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(2)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2)
  }
  return (
    <div>
      <div className="voucher-wrapper">
        {items.slice(0, visibleItems).map((item) => (
          <div key={item.name} className="voucher-item mt-30">
            <div className="voucher-detail">
              <h3>{item.name}</h3>
              <h4>{item.discount}</h4>
              <h5>{item.valid}</h5>
              <span>Terms & Conditions:</span>
              <h6>{item.terms}</h6>
            </div>
            <div className="voucher-cover">
              <img alt="" src={item.img} />
            </div>
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img alt="" src={Images.DownIco} />
          </button>
        </div>
      )}
    </div>
  )
}
export default Offers
