import React, { useState } from 'react'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Services = () => {
  const items = [
    {
      image: './images/job/cat1.svg',
      name: 'Design & Creative Artist',
      position: '653'
    },
    {
      image: './images/job/cat2.svg',
      name: 'Content Writer',
      position: '653'
    },
    {
      image: './images/job/cat3.svg',
      name: 'Construction',
      position: '653'
    },
    {
      image: './images/job/cat4.svg',
      name: 'Web Developer',
      position: '653'
    },
    {
      image: './images/job/cat5.svg',
      name: 'Real Estate',
      position: '653'
    },
    {
      image: './images/job/cat6.svg',
      name: 'Android  Developer',
      position: '653'
    },
    {
      image: './images/job/cat7.svg',
      name: 'IT Manager',
      position: '653'
    },
    {
      image: './images/job/cat8.svg',
      name: 'Marketing Manger',
      position: '653'
    },
    {
      image: './images/job/cat1.svg',
      name: 'Design & Creative Artist',
      position: '653'
    },
    {
      image: './images/job/cat2.svg',
      name: 'Content Writer',
      position: '653'
    },
    {
      image: './images/job/cat3.svg',
      name: 'Construction',
      position: '653'
    },
    {
      image: './images/job/cat4.svg',
      name: 'Web Developer',
      position: '653'
    },
    {
      image: './images/job/cat5.svg',
      name: 'Real Estate',
      position: '653'
    },
    {
      image: './images/job/cat6.svg',
      name: 'Android  Developer',
      position: '653'
    },
    {
      image: './images/job/cat7.svg',
      name: 'IT Manager',
      position: '653'
    },
    {
      image: './images/job/cat8.svg',
      name: 'Marketing Manger',
      position: '653'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  return (
    <div className="portfolio-wrapper">
      <div className="common-card-body mt-30 recent-video-component">
        <h3>{English.G224}</h3>
        <div className="job-categorie">
          <div className="job-categories-grid">
            {items.slice(0, visibleItems).map((item) => (
              <div className="job-categories-item" key={item.name}>
                <img alt="" src={item.image} />
                <p>{item.name}</p>
                <span>
                  (
                  {item.position}
                  )
                </span>
              </div>
            ))}
          </div>
          {items.length > visibleItems && (
            <div className="collapse-button text-center mt-3">
              <button type="button" onClick={showMoreItems}>
                <img alt="" src={Images.DownIco} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Services
