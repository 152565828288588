import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const PopularDishes = () => {
  const items = [
    {
      img: './images/breakfast/1.jpg',
      rating: '4.9',
      name: 'Crispy Fried Chicken Sandwich',
      detail: 'Challah bun, lettuce, tomato, special sauce',
      reviews: '10'
    },
    {
      img: './images/breakfast/2.jpg',
      rating: '4.9',
      name: 'Famous Butterscotch Pudding',
      detail: 'Mexican wedding cookie, whipped chantilly cream.',
      reviews: '10'
    },
    {
      img: './images/breakfast/3.jpg',
      rating: '4.9',
      name: 'Brezel',
      detail: 'Baked bread made from dough',
      reviews: '10'
    },
    {
      img: './images/breakfast/4.jpg',
      rating: '4.9',
      name: 'Famous Butterscotch Pudding',
      detail: 'Mexican wedding cookie, whipped chantilly cream.',
      reviews: '10'
    },
    {
      img: './images/breakfast/1.jpg',
      rating: '4.9',
      name: 'Crispy Fried Chicken Sandwich',
      detail: 'Challah bun, lettuce, tomato, special sauce',
      reviews: '10'
    },
    {
      img: './images/breakfast/2.jpg',
      rating: '4.9',
      name: 'Famous Butterscotch Pudding',
      detail: 'Mexican wedding cookie, whipped chantilly cream.',
      reviews: '10'
    },
    {
      img: './images/breakfast/3.jpg',
      rating: '4.9',
      name: 'Brezel',
      detail: 'Baked bread made from dough',
      reviews: '10'
    },
    {
      img: './images/breakfast/4.jpg',
      rating: '4.9',
      name: 'Famous Butterscotch Pudding',
      detail: 'Mexican wedding cookie, whipped chantilly cream.',
      reviews: '10'
    },
    {
      img: './images/breakfast/1.jpg',
      rating: '4.9',
      name: 'Crispy Fried Chicken Sandwich',
      detail: 'Challah bun, lettuce, tomato, special sauce',
      reviews: '10'
    },
    {
      img: './images/breakfast/2.jpg',
      rating: '4.9',
      name: 'Famous Butterscotch Pudding',
      detail: 'Mexican wedding cookie, whipped chantilly cream.',
      reviews: '10'
    },
    {
      img: './images/breakfast/3.jpg',
      rating: '4.9',
      name: 'Brezel',
      detail: 'Baked bread made from dough',
      reviews: '10'
    },
    {
      img: './images/breakfast/4.jpg',
      rating: '4.9',
      name: 'Famous Butterscotch Pudding',
      detail: 'Mexican wedding cookie, whipped chantilly cream.',
      reviews: '10'
    }
  ]
  return (
    <div className=" services-container menu-wrapper common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Popular Dishes
        <Link to="/menu" state={items}>
          {English.G248}
        </Link>
      </h3>
      <div className="breackfast-grid--item">
        {items
          .filter((item, index) => index < 4)
          .map((item, index) => (
            <div className="menu-grid-item" key={index}>
              <div
                className="menu-item-cover"
                style={{
                  background: `url(${item.img})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="rating">
                  <img alt="" src={Images.RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="menu-item-details">
                <h2>{item.name}</h2>
                <h5>{item.detail}</h5>
                <h6>
                  {item.reviews}
                  {' '}
                  reviews
                </h6>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
export default PopularDishes
