import React from 'react'

const PrimaryButton = ({ text, onClick, bgClass, txtClass, txtStyle, bgStyle }) => {
  return (
    <div>
      <button
        onClick={onClick}
        style={bgStyle}
        className={`${bgClass || 'bg-primaryGradient'} rounded-3xl pl-5 pr-5 pt-2 pb-2`}
        type="button"
      >
        <div
          style={txtStyle}
          className={`bg-electricWhite pr-1 font-black italic id-button bg-clip-text text-transparent ${txtClass}`}
        >
          {text}
        </div>
      </button>
    </div>)
}

export default PrimaryButton
