const BaseURL = {
  AuthURL: process.env.REACT_APP_URL_AUTH ?? '',
  ImageUploadURL: process.env.REACT_APP_URL_IMAGE_UPLOAD ?? '',
  ProfileURL: process.env.REACT_APP_URL_PROFILE ?? '',
  StoreURL: process.env.REACT_APP_URL_STORE ?? '',
  CreatorURL: process.env.REACT_APP_URL_CREATOR ?? '',
  ContentManagerURL: process.env.REACT_APP_URL_CONTENT_MANAGER ?? '',
  LocalURL: process.env.REACT_APP_URL_LOCAL ?? '',
  ImageUploadURLLocal: process.env.REACT_APP_URL_IMAGE_UPLOAD_LOCAL ?? ''
}

export default BaseURL
