import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import Images from '../../helpers/Images'

const Orders = () => {
  const location = useLocation()
  const { state } = location || {}
  const orders = state || []

  const [startDateRange, setStartDateRange] = useState(new Date('2014/02/08'))
  const [endDateRange, setEndDateRange] = useState(new Date('2014/02/10'))
  return (
    <div className="creator-profile-main">
      <div className="common-card-body  recent-video-component">
        <div className="d-flex align-items-center justify-content-between mb-40">
          <h3 className="m-0">Orders </h3>
          <div className="date-range">
            <ReactDatePicker
              selected={startDateRange}
              onChange={(date) => setStartDateRange(date)}
              selectsStart
              startDate={startDateRange}
              endDate={endDateRange}
              dateFormat="dd/MM/yyyy"
            />
            <span>To</span>
            <ReactDatePicker
              selected={endDateRange}
              onChange={(date) => setEndDateRange(date)}
              selectsEnd
              startDate={startDateRange}
              endDate={endDateRange}
              minDate={startDateRange}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="order-table">
          <table className="w-100">
            <tr>
              <th>Product ID</th>
              <th>Client Name</th>
              <th>Product Name</th>
              <th>Date</th>
              <th>Price</th>
              <th>Status</th>
              <th />
            </tr>

            {orders.map((item) => (
              <tr key={item.productId} className="data-row">
                <td>{item.productId}</td>
                <td className="d-flex col-gap-20">
                  <div className="client-img">
                    <img alt="" src={item.clientImg} />
                  </div>
                  <div className="client-detail">
                    <p className="m-0">{item.clientName}</p>
                    <span>{item.yearMonth}</span>
                  </div>
                </td>
                <td>{item.productName}</td>
                <td>{item.date}</td>
                <td>{item.price}</td>
                <td>
                  <span id={item.staus}>{item.staus}</span>
                </td>
                <td>
                  <Link>
                    <img alt="" src={Images.IconsMore} />
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  )
}

export default Orders
