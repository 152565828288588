import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import English from '../../../helpers/English'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const AddOns = ({
  isAddonsLoading,
  addOnsData,
  getSelectedAddOns,
  handleCancelClick,
  handleCheckboxChange,
  prevStep,
  createProfileClick,
  urlSystemIcon,
  Images
}) => {
  const userType = useSelector((state) => state.user?.user_type)
  const [infoModal, setInfoModal] = useState(false)

  const InfoClick = () => {
    setInfoModal(true)
  }
  const ClickOk = () => {
    setInfoModal(false)
  }

  return (
    <div className="rounded-3xl p-[30px] bg-white !pb-[0px]">
      <div style={{ position: 'static' }} className="sticky-header !pt-0 !pr-0">
        {userType === 'business' && (
        <div className="justify-end hidden">
          <CommonButton
            label={English.A115}
            onClick={InfoClick}
            type="button"
            className="primary-button !w-auto !py-[12px] !px-[16px]"
            context="primary"
            isPrimary
          />
        </div>
      )}
        <div className="d-flex align-items-center gap-28">
          <div className="">
            <h1 className="text-[#0B0A0A] text-2xl italic font-black">{English.A105}</h1>
            <p className="text-[15px]">{English.A106}</p>
          </div>
          <div className="add-ons-label">
            {getSelectedAddOns().map((addOn) => (
              <p key={addOn.id} className="">
                <span className="italic font-bold text-base">
                  {addOn.name}
                </span>
                <button
                  type="button"
                  onClick={() => handleCancelClick(addOn.id)}
                  disabled={addOn.disabled}
                >
                  <img src={Images.cancel} alt="" />
                </button>
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4E4] mb-4" />
      <div className={`step-4 scroll-bars_create !h-[calc(100vh-${userType === 'creator' ? '315px' : '270px'})]`}>
        {isAddonsLoading ? (
          <div className="add-ons">
            <div className="add-ons-main-ing">
              <Skeleton width={100} height={100} />
            </div>
            <div className="footer-row">
              <div className="images-block">
                <Skeleton width={100} height={100} />
              </div>
              <div className="add-ons-detail">
                <h4>
                  <Skeleton max-width={175} />
                </h4>
                <p>
                  <Skeleton max-width={175} height={50} />
                </p>
              </div>
            </div>
            <div className="custome-checkbox">
              <Skeleton type="checkbox" width={20} height={20} />
            </div>
          </div>
        ) : (
          addOnsData.map((i, index) => (
            <div
              key={i.id}
              className={`w-[359px] h-auto p-3 rounded-2xl bg-white ${
                i.checked ? 'selected' : 'selectable'
              }`}
            >
              <div className="flex flex-col gap-3">
                <div className="bg-[#F9F9F9] px-[10px] !py-[20px] rounded-2xl">
                  <img
                    className="add_ons_first mx-auto"
                    alt=""
                    src={Images.Add_ons_laptop}
                  />
                </div>
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <img className="w-[69px] h-[70px] object-contain" src={urlSystemIcon + i.icon} alt="" />
                      <div className="flex-row items-center gap-2">
                        <h3 className="text-2xl font-extrabold text-gradient italic !pr-1">
                          {i.name}
                        </h3>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex items-center">
                        <input
                          id="inline-2-checkbox"
                          type="checkbox"
                          className="!w-6 !h-6 text-blue-600 bg-gray-100 border-gray-300 !rounded-full dark:bg-gray-700 dark:border-gray-600"
                          value={i.id}
                          checked={i.checked}
                          disabled={i.disabled}
                          onChange={handleCheckboxChange(index)}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-sm text-gray-600">{i.description}</p>
                  <div className="AddOns_li">
                    <ul>
                      <li>{English.A108}</li>
                      <li>{English.A109}</li>
                      <li>{English.A110}</li>
                      <li>{English.A111}</li>
                    </ul>
                  </div>
                </div>
                <div
                  className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer"
                >
                  <div
                    className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
                    type="button"
                  >
                    {English.A107}
                  </div>
                  <img
                    className="ml-1 mt-2"
                    src={Images.arrow_left_black}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
          ))
        )}

        {infoModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-2xl shadow-lg p-8 w-[450px]">
            <div className="flex justify-between items-center">
              <h2 className="text-3xl italic font-black text-gradient ">
                {English.A112}
              </h2>
              <button
                onClick={ClickOk}
                type="button"
                className="text-gray-500 hover:text-gray-800"
              >
                <img
                  src={Images.Close_Icon_New}
                  alt="Close Icon"
                  className="w-5 h-5"
                />
              </button>
            </div>
            <p className="text-base font-normal text-gradient mb-6">
              {English.G14}
            </p>
            <CommonButton
              label={English.A113}
              type="button"
              onClick={ClickOk}
              className="primary-button m-auto login_button"
              context="primary"
              isPrimary
            />
            <CommonButton
              label={English.A114}
              type="button"
              onClick={ClickOk}
              className="secondary-btns m-auto login_button mt-2 w-100"
              context="secondary-btn"
            />
          </div>
        </div>
              )}
      </div>
      <div className="steps-button sticky-footer mt-0 px-3">
        <CommonButton
          label={English.A116}
          type="button"
          onClick={prevStep}
          className="secondary-btns btn-prev-case3 w-auto"
          context="secondary-btn"
        />
        <CommonButton
          label={English.B58}
          type="button"
          onClick={createProfileClick}
          className="primary-btns btn-next-case3 uppercase"
          context="primary-btn"
          isPrimary
        />
      </div>
    </div>
  )
}
export default AddOns
