import React from 'react'
import { Images } from '../../../helpers'

const PrimaryButtonIcon = ({ text, onClick, bgClass, txtClass, txtStyle, bgStyle, icon }) => {
  return (
    <div>
      <button
        onClick={onClick}
        style={bgStyle}
        className={`${bgClass || 'bg-primaryGradient'} rounded-[35px] pl-5 pr-5 pt-2 pb-2 flex items-center justify-center gap-1 flex-row h-[60px] w-[380px]`}
        type="button"
      >
        <div
          style={txtStyle}
          className={`bg-electricWhite pr-1 font-black italic id-button bg-clip-text text-transparent ${txtClass}`}
        >
          {text}
        </div>
        <img className="h-[15px] w-[15px]" alt="next" src={icon || Images.rightArrowWhite} color="#000000" />
      </button>
    </div>)
}

export default PrimaryButtonIcon
