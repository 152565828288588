/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './RecentPodcast.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { Progress } from 'antd'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { s3ImagePodcast, s3ImageProfile } from '../../services/Service'
import {
  compressImage,
  compressVideo,
  dataDecrypt
} from '../../services/http-services'
import PlatformTag from '../ContentManager/PlatformTag'
import Loader from '../Loader/Loader'
import {
  debounce,
  formatDurationPodcast,
  lockScroll,
  timeDifference,
  unlockScroll
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import { useVideoProgress } from '../../providers/VideoProgressProvider'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const PodcastComponent = ({ showCount }) => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const navigate = useNavigate()
  const { id } = useParams()
  const audioRef = useRef(null)
  const audioRefs = useRef({})

  const [podcast, setPodcast] = useState([])
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [ShowPodcastCoverModel, setShowPodcastCoverModel] = useState(false)
  const [ShowPodcastPreviewModel, setShowPodcastPreviewModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    minutes: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    host_id: 0,
    search: ''
  })
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedAudioName, setSelectedAudioName] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [hostName, setHostName] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delPodcastId, setDelPodcastId] = useState()
  const [loading, setLoading] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [previewModel, setPreviewModel] = useState(false)
  const [plays, setPlays] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [initialPlatformTags, setInitialPlatformTags] = useState([])
  const [initialDescription, setInitialDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [isPlaying, setIsPlaying] = useState({})
  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedIndex, setExpandedIndex] = useState(null)

  const { podcastComProgress, updatePodcastComProgress } = useVideoProgress()

  const AddPodcast = () => {
    setShowPodcastModel(true)
    clearValue()
  }
  const AddPodcastCover = () => {
    setShowPodcastCoverModel(true)
    setShowPodcastModel(false)
  }
  const AddPodcastPreview = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(true)
  }

  const HandleClose = () => {
    clearValue()
    setPreviewModel(false)
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
    setIsModalVisible(false)
  }
  const HandleCancelCover = () => {
    setShowPodcastModel(true)
    setShowPodcastCoverModel(false)
  }
  // const HandleCancelPreview = () => {
  //   setShowPodcastPreviewModel(false)
  //   setShowPodcastCoverModel(true)
  // }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }
  const isAppsSelected = inputFields.app_id?.length > 0

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setSelectedAudioName('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      minutes: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      host_id: 0
    })
  }

  const addPodcastApiCall = () => {
    APICall('post', inputFields, `${EndPoints.addPodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowPodcastPreviewModel(false)
          clearValue()
          setTimeout(() => {
            getPodcast(null, currentPage)
          }, 5000)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const delay = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  const handlePublishClick = async () => {
    const videoId = Date.now()

    updatePodcastComProgress(videoId, 0)
    setShowPodcastPreviewModel(false)
    setPreviewModel(false)

    const durationInSeconds = Math.trunc(audioRef.current.duration)
    const formattedDuration = formatDurationPodcast(durationInSeconds)

    inputFields.minutes = formattedDuration

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'podcast')

        APICall('post', coverFormData, EndPoints.uploadImage, 'creator', true)
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedCoverData = res?.decryptedData
              inputFields.cover_attachment = decryptedCoverData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      if (!isEditing || selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          (compressionProgress) => {
            updatePodcastComProgress(videoId, compressionProgress / 2)
          }
        )

        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'podcast')

        APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true,
          (uploadProgress) => {
            const progress = 51 + Math.round(uploadProgress * 0.49)
            updatePodcastComProgress(videoId, progress)
          }
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res?.data?.data)
              inputFields.attachment = decryptedVideoData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      await delay(5000)

      if (isEditing) {
        await editPodcastApiCall()
      } else {
        await addPodcastApiCall()
      }

      setLoading(false)
      updatePodcastComProgress(videoId, 100)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleVideoChange = (event) => {
    const maxFileSizeMB = 500
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    const maxDurationMinutes = 90

    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      if (selectedFile.size > maxFileSizeBytes) {
        toast.error(`Video file size exceeds ${maxFileSizeMB} MB`)
        return
      }

      const video = document.createElement('video')
      video.src = URL.createObjectURL(selectedFile)

      video.onloadedmetadata = () => {
        const durationMinutes = video.duration / 60

        if (durationMinutes > maxDurationMinutes) {
          toast.error(`Video duration exceeds ${maxDurationMinutes} minutes`)
          return
        }

        setSelectedVideoObj(selectedFile)
        setSelectedAudioName(selectedFile.name)
        setSelectedVideo(video.src)
      }
    }
  }

  const podcastHostApiCall = () => {
    APICall('post', {}, `${EndPoints.hostList}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setHostName(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const ShowConfirm = (videoId) => {
    setDelPodcastId(videoId)
    setShowConfirmModel(true)
    setIsModalVisible(true)
    Object.keys(audioRefs.current).forEach((index) => {
      const audio = audioRefs.current[index]
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    })
  }

  const deletePodcast = () => {
    const data = {
      podcast_id: delPodcastId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deletePodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getPodcast(null, currentPage)
            setIsModalVisible(false)
          }, 3000)
          clearValue()
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const editPodcastApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      host_id: inputFields.host_id,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      podcast_id: inputFields.podcast_id
    }
    APICall('post', data, `${EndPoints.editPodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setShowPodcastPreviewModel(false)
          setPreviewModel(false)
          clearValue()
          setTimeout(() => {
            getPodcast(null, currentPage)
          }, 5000)
          setIsEditing(false)
          toast.success(res?.data?.message)
        } else {
          setIsEditing(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsEditing(false)
        toast.error(error?.data?.message)
      })
  }

  const onClickEditPodcast = (videoData) => {
    setShowPodcastModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePodcast + videoData.attachment)
    setSelectedFilePath(s3ImagePodcast + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setInitialValues({
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id,
      search: ''
    })
    setPlatformTags(videoData.app_data)
  }

  const handleItemClick = (videoData) => {
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePodcast + videoData.attachment)
    setSelectedFilePath(s3ImagePodcast + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setPlatformTags(videoData.app_data)
    setInitialPlatformTags(videoData.app_data)
    setInitialDescription(videoData.description)
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = hostName.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.host : 'Select host'
  }

  const validateForm = () => {
    const { title, host_id, description } = inputFields
    if (title && host_id && description && selectedVideo) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const getPodcast = (search, currentPage) => {
    console.log('search', search)
    console.log('currentPage', currentPage)
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getPodcasts}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPodcast(res?.decryptedData?.data)
          setTotalPages(res?.decryptedData?.last_page)
          setCurrentPage(res?.decryptedData?.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getPodcast(inputVal, currentPage), 500),
    []
  )

  const videoLikeData = (podcast_id) => {
    const platFormData = {
      app_id: app_id.id,
      podcast_id
    }
    APICall('post', platFormData, `${EndPoints.podcastLike}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPodcast(
            podcast?.map((item) => (item.podcast_id === podcast_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  // Podcasts pagination

  const totalVideos = podcast?.length

  const handlePageChange = (pageNumber) => {
    getPodcast(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  // on hover play pause

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])

  const handleMouseEnter = (index, attachment) => {
    if (isModalVisible) return
    setHoveredIndex(index)

    if (!audioRefs.current[index]) {
      const newAudio = new Audio(attachment)
      newAudio.onerror = (e) => {
        console.error('Audio playback error:', e)
      }
      audioRefs.current[index] = newAudio
    }

    audioRefs.current[index]
      .play()
      .then(() => {
        setIsPlaying((prev) => ({ ...prev, [index]: true }))
      })
      .catch((error) => {
        console.error('Error playing audio:', error)
      })
  }

  const handleMouseLeave = (index) => {
    setHoveredIndex(null)

    if (audioRefs.current[index]) {
      audioRefs.current[index].pause()
      audioRefs.current[index].currentTime = 0
      setIsPlaying((prev) => ({ ...prev, [index]: false }))
    }
  }

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index]

    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            setIsPlaying((prev) => ({ ...prev, [index]: true }))
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } else {
        audio.pause()
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    }
  }
  const playVolumeIcon = (index) => (
    <img
      src={isPlaying[index] ? Images.PodcastPauseIco : Images.PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={(e) => {
        e.stopPropagation()
        togglePlayPause(index)
      }}
      className="volume-icon"
    />
  )

  const togglePlayPauses = () => {
    if (audioRef?.current) {
      if (audioRef?.current?.paused) {
        audioRef?.current?.play()
        setPlays(true)
      } else {
        audioRef?.current?.pause()
        setPlays(false)
      }
    }
  }

  const podcastApiInitialized = useRef(false)

  useEffect(() => {
    if (!podcastApiInitialized.current) {
      podcastApiInitialized.current = true
      podcastHostApiCall()
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          // setShowPodcastCoverModel(false)
          setShowPodcastModel(false)
          setShowPodcastPreviewModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }
    if (
      !loading &&
      (ShowPodcastModel ||
        // ShowPodcastCoverModel ||
        ShowPodcastPreviewModel ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }
    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPodcast(null, currentPage)
    }
  }, [localStorageValue])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields, selectedVideo])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, selectedVideo])
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPodcastModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (
      previewModel ||
      ShowPodcastModel ||
      ShowPodcastCoverModel ||
      ShowPodcastPreviewModel ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    previewModel,
    ShowPodcastModel,
    ShowPodcastCoverModel,
    ShowPodcastPreviewModel,
    ShowConfirmModel
  ])

  return (
    <>
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link">
            {/* <div className="video_button"> */}
            <h2>{English.G157}</h2>

            <div className="add-video-rows">
              <CommonButton
                label={English.G158}
                imgSrc={Images.AddIco}
                onClick={AddPodcast}
                isPrimary
                className="add_new_content"
              />
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
      <div
        className={
          showCount !== 3 &&
          'recent-video-component recent-podcast common-card-body add-podcaest-video'
        }
      >
        {!showCount &&
          _.map(podcastComProgress, (pro, videoId) => {
            if (pro === 100) {
              return null
            }
            return (
              <div className="custom-progress" key={videoId}>
                <Progress
                  percent={pro}
                  status="active"
                  strokeColor={{
                    '3.03%': '#001597',
                    '47.1%': '#01104d',
                    '80.14%': '#020c1c',
                    '96.45%': '#020b09'
                  }}
                  trailColor="#d9d9d9"
                  strokeWidth={10}
                />
              </div>
            )
          })}

        {showCount !== 3 && (
          <h3>
            Podcasts
            <div className="items-filter">
              <div className="position-relative input-position">
                <CommonInput
                  placeholder="Search Podcast"
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                />
              </div>
            </div>
          </h3>
        )}
        {showCount !== 3 && (
          <div className="drop-row mb-30">
            <div className="drop-sub-row">
              {showCount !== 3 && (
                <Dropdown
                  title="Categories"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
              {showCount !== 3 && (
                <Dropdown
                  title="Ratings"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
              {showCount !== 3 && (
                <Dropdown
                  title="Duration"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
            </div>
          </div>
        )}
        {!showCount && (
          <div className="add-video-rows main_btn">
            <CommonButton
              label={English.G158}
              imgSrc={Images.AddIco}
              onClick={AddPodcast}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
        <div className="Podcasts-item">
          {podcast?.length === 0 && !isLoading ? (
            <div className="Podcasts-item-detail">
              <div className="podacast-item">
                <div className="podcast-cover">
                  <button type="button" onClick={AddPodcast}>
                    <img src={Images.Plus} alt="add library" />
                  </button>
                  <div className="play_time_blank">
                    <div className="play ">
                      <p />
                    </div>
                    <div className="time">
                      <p />
                    </div>
                  </div>
                </div>
                <div className="video-details podcast-detail">
                  <div className="d-flex justify-content-between">
                    <h6>
                      <img src="" alt="" />
                      <span />
                    </h6>
                  </div>
                  <p className="podcast-name" />
                  <span className="podcast-upload-time" />
                  <div className="other-info" />
                </div>
              </div>
            </div>
          ) : isLoading ? (
            <div className="Podcasts-item-detail">
              <div className="podacast-item">
                <div className="podcast-cover">
                  <div className="play play_button_skeleton">
                    <Skeleton circle width={25} height={25} />
                  </div>
                </div>
                <div className="video-details podcast-detail">
                  <div className="d-flex justify-content-between">
                    <h6>
                      <img src="" alt="" />
                      <span />
                    </h6>
                  </div>
                  <p className="podcast-name">
                    <Skeleton className="podcast-name" width={332} />
                  </p>
                  <span className="podcast-upload-time">
                    <Skeleton className="podcast-upload-time" width={89} />
                  </span>
                  <div className="other-info mt-0">
                    <Skeleton className="other-info mt-0" width={332} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            podcast?.map(
              (item, index) => index < (showCount || totalVideos) && (
              <div
                className="awards-item-podcast position-relative"
                key={index}
                onMouseEnter={() => handleMouseEnter(
                        index,
                        `${s3ImagePodcast}${item.attachment}`
                      )}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div
                  className="recent-podcast-item"
                  key={item.name}
                  style={{
                        backgroundImage: `url(${
                          hoveredIndex === index
                            ? `${s3ImagePodcast}${item.cover_attachment}`
                            : `${s3ImagePodcast}${item.cover_attachment}`
                        })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                  onClick={(e) => {
                        e.stopPropagation()
                        handleItemClick(item)
                      }}
                >
                  <div className="video_like_bg">
                    <button
                      type="button"
                      className="video_like"
                      onClick={(e) => {
                            e.stopPropagation()
                            videoLikeData(item.podcast_id)
                          }}
                    >
                      {item.is_like ? (
                        <img src={Images.Likes} alt="Unlike" />
                          ) : (
                            <img src={Images.UnLikes} alt="Like" />
                          )}
                    </button>
                  </div>
                  <span className="minutes-badge">{item.minutes}</span>
                  {playVolumeIcon(index)}
                </div>
                <div className="d-flex justify-content-between user_image">
                  <h6 className="mb-0">
                    <img src={s3ImageProfile + item.profile_icon} alt="" />
                    <span className="ml-2">{item.user_name}</span>
                  </h6>
                  <div className="rating">
                    <img src={Images.RatingStar} alt="" />
                    <span>4.9</span>
                  </div>
                </div>

                <div
                  className={`video-details ${expandedIndex === index ? 'expanded' : ''}`}
                >
                  <h6 className="title_profile_podcast">{item.title}</h6>
                  <span className="timer">
                    {timeDifference(item.created_at)}
                  </span>
                  <p>
                    {expandedIndex === index
                          ? item?.description
                          : `${item?.description?.slice(0, 45)}`}
                    {item?.description?.length > 45 && (
                    <span
                      className="see-more"
                      onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }}
                    >
                      {expandedIndex === index
                              ? English.G145
                              : English.G146}
                    </span>
                        )}
                  </p>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditPodcast(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={(e) => {
                          e.stopPropagation()
                          setUpdatingIndex(index)
                          ShowConfirm(item.podcast_id)
                        }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={(e) => {
                        e.stopPropagation()
                        deletePodcast()
                      }}
                  onCancel={(e) => {
                        e.stopPropagation()
                        handleCancel()
                      }}
                  isLoading={delLoading}
                />
                {delLoading && updatingIndex === index && <Loader />}
              </div>
                )
            )
          )}
          {previewModel && (
            <div className="add-item-model preview-model-podcast">
              <div className="add-item-content" ref={componentRef}>
                <div className="d-flex justify-content-between header">
                  <h3 className="shot_upload_file_text">{English.G143}</h3>
                  <button
                    type="button"
                    className="border-0 bg-none p-0"
                    onClick={HandleClose}
                  >
                    <span>
                      <img
                        className="close-btns"
                        src={Images.CloseIco}
                        alt=""
                      />
                    </span>
                  </button>
                </div>
                <div className="scroll-bar pb-0">
                  <div className="podcast-overview">
                    <div className="podcast-cover-img">
                      <div className="audio-container">
                        <img
                          src={selectedFilePath}
                          alt="Poster"
                          className="audio-poster"
                        />
                      </div>

                      <div className="podcast-profile">
                        <div className="podcast-profile-img-content border-0">
                          <img
                            src={s3ImageProfile + userData.profile_image}
                            alt=""
                          />
                        </div>
                        <div className="podcast-profile-detail">
                          <span className="podcast_profile_user_name">
                            {userData.user_name}
                          </span>
                          <p className="designation designation_color ">
                            {English.G291}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="podcast-detail">
                      <h3>{inputFields.title}</h3>
                      <p>The Demo Podcast</p>
                      <div className="bg_play_pause">
                        <img
                          src={
                            plays
                              ? Images.PodcastPauseIco
                              : Images.PodcastPlayIco
                          }
                          onClick={togglePlayPauses}
                          style={{ cursor: 'pointer' }}
                          alt="Play/Pause"
                          className="play_pause_icon"
                        />
                      </div>
                      <audio
                        ref={audioRef}
                        width="100%"
                        height="200"
                        className="audio-element"
                      >
                        <source src={selectedVideo} type="audio/mp3" />
                      </audio>
                    </div>
                  </div>
                  <div className="podcast-discription">
                    <h5 className="podcast_profile_user_name">
                      {English.G112}
                    </h5>
                    <textarea
                      className="preview_text"
                      onChange={(e) => setInputFields({
                          ...inputFields,
                          description: e.target.value
                        })}
                      value={inputFields.description}
                    >
                      <p>{inputFields.description}</p>
                    </textarea>
                  </div>
                  <PlatformTag
                    setPlatformTags={setPlatformTags}
                    Platformtags={Platformtags}
                    onItemSelect={(value) => getSelectedAppId(value)}
                  />
                </div>
                <div className="item-details-input pt-0">
                  <div className="button-row video_sticky_btn">
                    <CommonButton
                      label={English.G116}
                      type="button"
                      onClick={HandleClose}
                      isPrimary={false}
                      disabled={loading}
                      context={English.G232}
                    />
                    <CommonButton
                      label={isAppsSelected ? English.G128 : English.G89}
                      loading={loading}
                      type="button"
                      onClick={handlePublishClick}
                      className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                      isPrimary
                      disabled={
                        loading ||
                        (_.isEqual(initialPlatformTags, Platformtags) &&
                          initialDescription === inputFields.description)
                      }
                      context={English.G232}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
        {ShowPodcastModel && (
          <div className="add-item-model">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header pb-1">
                <h3 className="shot_upload_file_text">{English.G143}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bar pb-4">
                <div className="item-details-input pt-0">
                  <div className="upload-input upload-input-with-border">
                    {/* <input
                        onChange={handleVideoChange}
                        id="upload-video"
                        type="file"
                        accept="audio/*"
                        className="d-none"
                      /> */}
                    <CommonInput
                      type="file"
                      accept="audio/*"
                      id="upload-video"
                      onChange={handleVideoChange}
                      className="d-none"
                    />
                    {selectedVideo && (
                      <div>
                        <p>{selectedAudioName}</p>
                        <button
                          type="button"
                          className={`edit-button-model content-meneger-edit ${
                            isEditing ? 'hidden' : ''
                          }`}
                          onClick={() => {
                            setIsEditing(true)
                            document.getElementById('upload-video').click()
                          }}
                        />
                      </div>
                    )}
                    {!selectedVideo && (
                      <div className="upload-div mt-3">
                        <div className="upload-img">
                          <img src={Images.upload} alt="" />
                        </div>
                        <h4>{English.G159}</h4>
                        <p className="drag-drop-text">{English.G156}</p>
                        <CommonButton
                          label={English.G160}
                          htmlFor="upload-video"
                          type="button"
                          className="w-auto"
                          onClick={() => document.getElementById('upload-video').click()}
                        />
                      </div>
                    )}
                    {selectedVideo && (
                      <div className="">
                        <audio
                          key={selectedVideo}
                          ref={audioRef}
                          width="100%"
                          height="394"
                          controls
                          controlsList="nodownload noplaybackrate"
                        >
                          <source src={selectedVideo} type="audio/mp3" />
                        </audio>
                      </div>
                    )}
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>{English.G161}</label>
                      <CommonInput
                        name="title"
                        placeholder={English.G162}
                        onChange={handleChange}
                        value={inputFields.title}
                      />
                    </div>
                    <div>
                      <label>{English.G163}</label>
                      <Dropdown
                        title={getCategoryName(inputFields.host_id)}
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            host_id: d.id
                          })
                        }}
                        Data={hostName.map((d) => ({
                          title: d.host,
                          id: d.id
                        }))}
                        name="host_id"
                        customClass={
                          inputFields.host_id
                            ? 'selected-class'
                            : 'giveaway-dropdown'
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label>{English.G112}</label>
                    <CommonInput
                      name="description"
                      placeholder={English.G83}
                      onChange={handleChange}
                      value={inputFields.description}
                      isTextArea
                      maxLength={150}
                    />
                  </div>
                </div>
              </div>
              <div className="item-details-input pt-0">
                <div className="button-row video_sticky_btn">
                  <CommonButton
                    label={English.G78}
                    onClick={HandleClose}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    disabled={!isFormComplete}
                    onClick={AddPodcastCover}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {ShowPodcastCoverModel && (
          <div className="add-item-model">
            <div
              className="add-item-content podcast-modal-height"
              ref={componentRef}
            >
              <div className="d-flex justify-content-between header pb-1">
                <h3 className="shot_upload_file_text">{English.G154}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars pb-0">
                <div>
                  <div className="upload-input upload_height">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      buttonText={English.G154}
                      selectedImg={selectedFilePath}
                      defaultImg=""
                      forImage
                      forName="Podcast"
                    />
                  </div>
                </div>
                <div className="item-details-input">
                  <div className="button-row-shots pt-5">
                    <CommonButton
                      label={English.G78}
                      onClick={HandleCancelCover}
                      isPrimary={false}
                      context={English.G232}
                    />
                    <CommonButton
                      label={English.G127}
                      type="button"
                      isPrimary
                      disabled={!selectedFilePath}
                      onClick={AddPodcastPreview}
                      context={English.G232}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {ShowPodcastPreviewModel && (
          <div className="add-item-model preview-model-podcast">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header pb-1">
                <h3 className="shot_upload_file_text">{English.G143}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  disabled={loading}
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bar pb-0">
                <div className="podcast-overview">
                  <div className="podcast-cover-img">
                    {/* <div
                  className="podcast-img"
                  style={{
                    background: 'url("./images/profile.png")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div> */}
                    <div className="audio-container">
                      <img
                        src={selectedFilePath}
                        alt="Poster"
                        className="audio-poster"
                      />
                    </div>

                    <div className="podcast-profile">
                      <div className="podcast-profile-img-content border-0">
                        <img
                          src={s3ImageProfile + userData.profile_image}
                          alt=""
                        />
                      </div>
                      <div className="podcast-profile-detail">
                        <span className="podcast_profile_user_name">
                          {userData.user_name}
                        </span>
                        <p className="designation designation_color">
                          {English.G291}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="podcast-detail">
                    <h3>{inputFields.title}</h3>
                    <p>The Demo Podcast</p>
                    <div className="bg_play_pause">
                      <img
                        src={
                          plays ? Images.PodcastPauseIco : Images.PodcastPlayIco
                        }
                        onClick={togglePlayPauses}
                        style={{ cursor: 'pointer' }}
                        alt="Play/Pause"
                        className="play_pause_icon"
                      />
                    </div>
                    <audio
                      ref={audioRef}
                      width="100%"
                      height="200"
                      className="audio-element"
                    >
                      <source src={selectedVideo} type="audio/mp3" />
                    </audio>
                  </div>
                </div>
                <div className="podcast-discription">
                  <h5 className="podcast_profile_user_name">{English.G112}</h5>
                  <p>{inputFields.description}</p>
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
              </div>
              <div className="item-details-input pt-0">
                <div className="button-row video_sticky_btn">
                  <CommonButton
                    label={English.G116}
                    type="button"
                    onClick={HandleClose}
                    isPrimary={false}
                    disabled={loading}
                    context={English.G232}
                  />
                  <CommonButton
                    label={isAppsSelected ? English.G128 : English.G89}
                    loading={loading}
                    type="button"
                    onClick={handlePublishClick}
                    className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                    isPrimary
                    disabled={loading}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {podcast?.length >= 2 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/profile-builder/${id}`, {
              state: { defaultIndex: 4 }
            })}
        >
          {English.G144}
        </div>
      )}
    </>
  )
}
export default PodcastComponent
