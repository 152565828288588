/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import './CreatorProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import Stories from '../Stories/Stories'
import Photos from '../Creator-profile-photos/photos'
import RecentVideo from '../RecentVideos/recent-video'
import RecentPodcast from '../RecentPodcast/RecentPodcast'
import RecentShots from '../RecentShots/RecentShots'
import RecentCommunity from '../RecentCommunity/RecentCommunity'
import Feeds from '../Feeds/Feeds'
import PodcastVideo from '../PodcastVideo/PodcastVideo'
import CommunityProfile from '../CommunityProfile/Community'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const CreatorProfile = () => {
  const items = [
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    },
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    },
    {
      cover: './images/challanges.jpg',
      name: 'Marathon',
      date: '20th May',
      time: '16 min'
    }
  ]
  const Badgeitems = [
    {
      badge: './images/badge/1.svg'
    },
    {
      badge: './images/badge/2.svg'
    },
    {
      badge: './images/badge/3.svg'
    },
    {
      badge: './images/badge/4.svg'
    },
    {
      badge: './images/badge/5.svg'
    },
    {
      badge: './images/badge/6.svg'
    },
    {
      badge: './images/badge/7.svg'
    },
    {
      badge: './images/badge/8.svg'
    },
    {
      badge: './images/badge/9.svg'
    }
  ]
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className="creator-profile-main">
      <ProfileHead />
      <div
        className={`feed-container mt-30 ${tabIndex === 1 ? '' : 'd-block'}`}
      >
        <div
          className={`details-tab-view common-card-body ${
            tabIndex === 1 ? 'feed-left' : ''
          }`}
        >
          <div className="tabs-for-details-view">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab>
                  <img src={Images.HomeIco} alt="" />
                  {English.G222}
                </Tab>
                <Tab>
                  <img src={Images.FeedIco} alt="" />
                  {English.G223}
                </Tab>
                <Tab>
                  <img src={Images.VideoIco} alt="" />
                  {English.G443}
                </Tab>
                <Tab>
                  <img src={Images.CommunityIco} alt="" />
                  {English.G439}
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div className="mt-40">
                  <Photos />
                </div>
                <div className="mt-40">
                  <RecentVideo />
                </div>
                <div className="mt-40">
                  <RecentPodcast />
                </div>
                <div className="mt-40">
                  <RecentShots />
                </div>
                <div className="mt-40">
                  <RecentCommunity />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Stories />
                </div>
                <div>
                  <Feeds />
                </div>
                <div className="free-courses-starter-pack mt-30">
                  <div className="details">
                    <h5>Free Courses Starter Pack </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pulvinar consequat urna pharetra.
                    </p>
                  </div>
                  <button type="button" className="light-button">
                    <span>{English.G447}</span>
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-40">
                  <PodcastVideo />
                </div>
                <div className="mt-40">
                  <Photos />
                </div>
                <div className="mt-40">
                  <RecentVideo />
                </div>
                <div className="mt-40">
                  <RecentPodcast />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <CommunityProfile />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {tabIndex === 1 && (
          <div className="common-card-body feed-right p-20">
            <h3>completed challenges</h3>
            <div className="feed-challanges-container">
              {items.map((item) => (
                <div
                  key={item.name}
                  className="feed-challanges-item mt-20"
                  style={{
                    background: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  <div className="about-challanges">
                    <p>{item.name}</p>
                    <div className="d-flex justify-content-between">
                      <span>{item.date}</span>
                      <span>{item.time}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button type="button">{English.G423}</button>
            <h3>Badges</h3>
            <div className="badge-container">
              {Badgeitems.map((item) => (
                <div className="badge-item" key={item.badge}>
                  <img src={item.badge} alt="" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default CreatorProfile
