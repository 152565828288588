/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import {
  s3ImageGiveaway,
  s3ImageProfile,
  urlSystemIcon
} from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import PlatformTag from './PlatformTag'
import Loader from '../Loader/Loader'
import Pagination from '../Pagination/Pagination'
import {
  capitalizeFirstLetter,
  formatTimeLeft,
  lockScroll,
  preventMultipleAtSignKeydown,
  preventMultipleAtSignPaste,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import TagView from '../CommanComponent/TagView'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const Giveaway = () => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)

  const componentRef = useRef()
  const timePickerRef = useRef(null)

  const [selectedImg, setSelectedImg] = useState(0)
  const [ShowCommunityModel, setShowCommunityModel] = useState(false)
  const [ShowCommunityGiveawayCover, setShowCommunityGiveawayCover] =
    useState(false)
  const [ShowGiveawayProduct, setShowGiveawayProduct] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    file: [],
    title: '',
    time_left: '',
    brand_id: 0,
    type_id: 0,
    email: '',
    phone: '',
    address: '',
    tag_friends: [],
    description: ''
  })
  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [brandData, setBrandData] = useState([])
  const [typeData, setTypeData] = useState([])
  const [giveawayProduct, setGiveawayProduct] = useState([])
  const [delGiveawayId, setDelGiveawayId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [giveawayList, setGiveawayList] = useState([])
  const [errors, setErrors] = useState({})
  const [tags, setTags] = useState([])
  // const [editingIndex, setEditingIndex] = useState(null)
  const [showAll, setShowAll] = useState(false)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const [commentSection, setCommentSection] = useState(false)
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const clearValue = () => {
    setSelectedImages([])
    setSelectedImg([])
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      app_id: [],
      file: [],
      title: '',
      time_left: '',
      brand_id: 0,
      type_id: 0,
      email: '',
      phone: '',
      address: '',
      tag_friends: [],
      description: ''
    })
    setTags([])
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'phone') {
      const phoneValue = value.replace(/\D/g, '')
      setInputFields({
        ...inputFields,
        [name]: phoneValue
      })

      if (validatePhone(phoneValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: ''
        }))
      }
    } else {
      setInputFields({
        ...inputFields,
        [name]: value
      })
    }

    if (name === 'email') {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: ''
        }))
      }
    }
  }

  const onChange = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      time_left: timeString
    }))
  }

  const openTimePicker = () => {
    setOpen(true)
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const handleImageChange = async (file, indexToReplace) => {
    const e = {
      target: {
        files: [file]
      }
    }

    const totalImagesAfterReplacement =
      indexToReplace !== undefined
        ? selectedImages?.length
        : selectedImages.length + e.target.files.length

    if (totalImagesAfterReplacement > 5) {
      toast.error('You can only upload up to 5 images.')
      return
    }

    const updatedImages = [...selectedImages]
    const updatedFiles = [...inputFields.file]

    try {
      const uploadPromises = Array.from(e.target.files).map(async (file) => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'giveaway')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            EndPoints.uploadImage,
            'creator',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)

            if (indexToReplace !== undefined && indexToReplace !== null) {
              updatedImages[indexToReplace] = decryptedCoverData
              updatedFiles[indexToReplace] = decryptedCoverData
            } else {
              updatedImages.push(decryptedCoverData)
              updatedFiles.push(decryptedCoverData)
            }
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      })

      await Promise.all(uploadPromises)
      setSelectedImages(updatedImages)
      setInputFields({ ...inputFields, file: updatedFiles })
    } catch (error) {
      console.error('Error uploading images:', error)
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)

    const updatedFiles = [...inputFields.file]
    updatedFiles.splice(index, 1)
    setInputFields({ ...inputFields, file: updatedFiles })
  }

  // const replaceImage = (index) => {
  //   const input = document.createElement('input')
  //   input.type = 'file'

  //   const handleChange = (e) => {
  //     handleImageChange(e, index)
  //     input.removeEventListener('change', handleChange)
  //   }

  //   if (input) {
  //     input.value = ''
  //     input.removeEventListener('change', handleChange)
  //     input.click()
  //     input.addEventListener('change', handleChange, { once: true })
  //     input.remove()
  //   }
  // }

  const ShowCommunityGiveaway = () => {
    setShowCommunityModel(true)
  }

  const HandleGiveawayCover = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(true)
  }

  const handleCancel = () => {
    setShowConfirmModel(false)
  }
  const ShowConfirm = (giveawayId) => {
    setDelGiveawayId(giveawayId)
    setShowConfirmModel(true)
  }

  const handleClose = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
    clearValue()
  }
  const GiveawayCoverCancel = () => {
    setShowCommunityGiveawayCover(false)
    setShowCommunityModel(true)
  }
  // const ProductCancel = () => {
  //   setShowGiveawayProduct(false)
  //   setShowCommunityGiveawayCover(true)
  // }

  const HandleGiveawayProduct = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(true)
  }

  const HandleDiscussion = () => {
    if (isEditing) {
      editGiveawayDataApi()
    } else {
      addGiveawayDataSubmit()
    }
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
  }

  const handleNextClick = () => {
    const emailValid = validateEmail(inputFields.email)
    const phoneValid = validatePhone(inputFields.phone)
    if (!emailValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: English.G168
      }))
    }
    if (!phoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: English.G169
      }))
    }
    if (emailValid && phoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '',
        phone: ''
      }))
      HandleGiveawayCover()
    }
  }

  const updateInputFieldsTags = (tagsArray) => {
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      tag_friends: tagsArray
    }))
  }

  // const handleAddTag = () => {
  //   if (tags.length < 4) {
  //     const newTag = `tag ${tags.length + 1}`
  //     setTags([...tags, newTag])
  //     updateInputFieldsTags([...tags, newTag])
  //   } else {
  //     alert('Maximum 4 tags allowed.')
  //   }
  // }

  // const handleRemoveTag = (tagIndex) => {
  //   const updatedTags = [...tags]
  //   updatedTags.splice(tagIndex, 1)
  //   setTags(updatedTags)
  //   updateInputFieldsTags(updatedTags)
  // }

  // const handleEditTag = (tagIndex) => {
  //   setEditingIndex(tagIndex)
  // }

  // const handleTagChange = (e, tagIndex) => {
  //   const updatedTags = [...tags]
  //   updatedTags[tagIndex] = e.target.value
  //   setTags(updatedTags)
  //   updateInputFieldsTags(updatedTags)
  // }

  // const handleTagBlur = () => {
  //   setEditingIndex(null)
  // }

  const validateForm = () => {
    const {
      title,
      time_left,
      brand_id,
      type_id,
      email,
      phone,
      address,
      tag_friends,
      description
    } = inputFields

    const isTagFriendsValid =
      Array.isArray(tag_friends) && tag_friends.length > 0

    if (
      title &&
      time_left &&
      brand_id &&
      type_id &&
      email &&
      phone &&
      address &&
      isTagFriendsValid &&
      description
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const validateEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  const validatePhone = (phone) => {
    const regexMobile = /^[0-9]{11,12}$/
    return regexMobile.test(phone)
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  const getBrandListApiCall = () => {
    APICall('post', {}, `${EndPoints.getBrand}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setBrandData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getTypeListApiCall = () => {
    APICall('post', {}, `${EndPoints.getType}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTypeData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getGiveawayLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getGiveawayLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveawayProduct(res?.decryptedData.data)
          const { data } = dataDecrypt(res.data.data)
          if (data.length !== 0) {
            // data.forEach((giveaway) => {
            //   giveawayParticipate(giveaway.giveaway_id)
            // })
            giveawayParticipateList(data[0].giveaway_id)
          }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const addGiveawayDataSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, `${EndPoints.addGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowGiveawayProduct(false)
          setTimeout(() => {
            getGiveawayLibraryData()
          }, 3000)
          setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const editGiveawayDataApi = () => {
    const data = {
      title: inputFields.title,
      time_left: inputFields.time_left,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      email: inputFields.email,
      phone: inputFields.phone,
      address: inputFields.address,
      tag_friends: inputFields.tag_friends,
      description: inputFields.description,
      file: inputFields.file,
      app_id: Platformtags.map((d) => d.id),
      giveaway_id: inputFields.giveaway_id
    }
    APICall('post', data, `${EndPoints.editGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            getGiveawayLibraryData()
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteGiveaway = () => {
    const data = {
      giveaway_id: delGiveawayId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            getGiveawayLibraryData()
          }, 3000)
          setShowConfirmModel(false)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const getBrandName = (categoryId) => {
    const categoryObj = brandData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.brand : English.G181
  }
  const getTypeName = (categoryId) => {
    const categoryObj = typeData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.type : English.G181
  }

  const giveawayParticipateList = (giveaway_id) => {
    const platFormData = {
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayParticipateList}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveawayList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const close = () => {
    setShowProfileModel(false)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        inputFields.file = copy
        return copy
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              // style={{
              //   backgroundImage: `url(${
              //     typeof image === 'string'
              //       ? s3ImageGiveaway + image
              //       : URL.createObjectURL(image)
              //   })`,
              //   backgroundSize: 'cover',
              //   backgroundPosition: 'center'
              // }}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageGiveaway + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad()}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Images.Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    handleImageChange(file, index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [selectedImages, handleDragStart])

  const onClickEditGiveaway = (videoData) => {
    setShowCommunityModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setInitialValues({
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setPlatformTags(videoData.app_data)
    setSelectedImages(videoData.images)
    setSelectedImg(0)
    setTags(videoData.tag_friends)
  }

  const showList = (giveaway_id) => {
    giveawayParticipateList(giveaway_id)
    setShowProfileModel(true)
  }

  const handleViewAll = () => {
    setShowAll(!showAll)
  }
  const displayedGiveaways = showAll
    ? giveawayProduct
    : giveawayProduct.slice(0, 3)

  // giveaway participate list pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const giveawayListData = giveawayList.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = giveawayList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // giveaway comment

  const onClickComment = (giveaway_id) => {
    setSelectedId(giveaway_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      giveawayComments(giveaway_id)
      setCommentSection(true)
    }
  }

  const fetchPollComments = (giveaway_id, delay = 3000) => {
    setTimeout(() => {
      giveawayComments(giveaway_id)
    }, delay)
  }

  const giveawayComments = (giveaway_id) => {
    const platFormData = {
      app_id: app_id?.id,
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayComment}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            poll_id: d.poll_id,
            is_like: d.is_like,
            comment_id: d.comment_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const pollAddComment = (comment) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollAddReply = (reply_id, replyText) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment_id: reply_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const pollEditComment = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
          'post',
          platFormData,
          `${EndPoints.giveawayCommentEdit}`,
          'contentManager'
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              fetchPollComments(selectedId)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((error) => {
            toast.error(error?.data?.message)
          })
  }

  const pollEditReply = (reply_id, replyId) => {
    const platFormData = {
      comment_id: reply_id,
      comment: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deletePollComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall(
          'post',
          data,
          `${EndPoints.giveawayCommentDelete}`,
          'contentManager'
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              fetchPollComments(selectedId)
              setIsDeleting(false)
              toast.success(res?.data?.message)
            } else {
              setIsDeleting(false)
              toast.error(res?.data?.message)
            }
          })
          .catch((error) => {
            setIsDeleting(false)
            toast.error(error?.data?.message)
          })
  }
  const deletePollReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const pollCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
     APICall(
          'post',
          platFormData,
          `${EndPoints.giveawayCommentLike}`,
          'contentManager'
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              setComments(
                comments.map((item) => (item.comment_id === reply_id
                    ? { ...item, is_like: !item.is_like }
                    : item))
              )
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((error) => {
            toast.error(error?.data?.message)
          })
  }

  const pollReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        poll_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    pollAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    pollAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deletePollReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    getGiveawayLibraryData()
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    pollEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId
              ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    pollEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const commentPerPage = 5
  const indexOfLastComment = currentCommentPage * commentPerPage
  const indexOfFirstComment = indexOfLastComment - commentPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalComment = comments.length
  const totalCommentPages = Math.ceil(totalComment / commentPerPage)

  const handlePageCommentChange = (pageNumber) => {
    setCurrentCommentPage(pageNumber)
  }

  useEffect(() => {
    if (currentCommentPage > totalCommentPages) {
      setCurrentCommentPage(totalCommentPages > 0 ? totalCommentPages : 1)
    }
  }, [totalCommentPages, currentCommentPage])

  useEffect(() => {
    getGiveawayLibraryData()
    getBrandListApiCall()
    getTypeListApiCall()
    // const handleClickOutside = (event) => {
    //   if (
    //     componentRef.current &&
    //     !componentRef.current.contains(event.target)
    //   ) {
    //     setShowCommunityGiveawayCover(false)
    //     setShowGiveawayProduct(false)
    //   }
    // }
    // document.addEventListener('mousedown', handleClickOutside)
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside)
    // }
  }, [])

  const enterGiveaway = (giveaway_id, index) => {
    // const data = {
    //   giveaway_id
    // }
    const temp = [...giveawayProduct]
    temp[index].is_participate = true
    setGiveawayProduct(temp)
    giveawayParticipateList(giveaway_id).then((res) => {
      if (res.data.code === 200) {
        toast.success(res?.data?.message)
      }
    })
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  useEffect(() => {
    if (
      ShowCommunityModel ||
      ShowCommunityGiveawayCover ||
      ShowGiveawayProduct ||
      ShowProfileModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowCommunityModel,
    ShowCommunityGiveawayCover,
    ShowGiveawayProduct,
    ShowProfileModel
  ])

  const getImageSrc = (image) => {
    if (image instanceof Blob) {
      return URL.createObjectURL(image)
    }
    if (typeof image === 'string') {
      return s3ImageGiveaway + image
    }
    return ''
  }

  return (
    <div className="content-manager-awards-wrapper common-card-body community-margin">
      <div className="poll-wrapper-header common-contnt-wrapper">
        <h2>{English.G230}</h2>
        <Link onClick={ShowCommunityGiveaway}>
          {/* <img src={AddIco} alt="" /> */}
          {English.G231}
        </Link>
      </div>

      {displayedGiveaways.length === 0 && !isLoading ? (
        <div className="add-giveways-item mb-30">
          <div className="add-giveways-cover">
            <button
              type="button"
              className="add-item-btn"
              onClick={ShowCommunityGiveaway}
            >
              <img src={Images.Plus} alt="add giveaway" />
            </button>
          </div>
          <div className="add-giveways-details">
            <h4 className="add-value"> </h4>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li className="add-value">List Item 1</li>
                <li className="add-value">List Item 2</li>
              </ul>
              <span className="add-value">Span Content</span>
            </div>
            <p className="add-value">Paragraph Content</p>
            <h5 className="add-value"> </h5>
          </div>
        </div>
      ) : isLoading ? (
        <div className="add-giveways-item mb-30">
          <div className="add-giveways-cover">
            <button type="button" className="add-item-btn">
              <img src={Images.Plus} alt="add giveaway" />
            </button>
          </div>
          <div className="add-giveways-details">
            <h4 className="add-value">
              <Skeleton className="add-value" width={155} height={29} />
            </h4>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li className="add-value">
                  <Skeleton className="add-value" width={110} height={29} />
                </li>
                <li className="add-value">
                  <Skeleton className="add-value" width={110} height={29} />
                </li>
              </ul>
              <span className="add-value">
                <Skeleton className="add-value" width={121} height={29} />
              </span>
            </div>
            <p className="add-value">
              <Skeleton className="add-value" width={580} height={110} />
            </p>
            <h5 className="add-value">
              <Skeleton className="add-value" width={149} height={48} />
            </h5>
          </div>
        </div>
      ) : (
        <div className="add-giveways-container">
          {displayedGiveaways.map((item, index) => {
            const timeLeftText = formatTimeLeft(item.time_left)
            const isTimeUp = timeLeftText === 'Time is up'
            return (
              <div
                key={index}
                className="added-giveways-item position-relative"
              >
                <div className="giveways-item-cover position-relative">
                  {item.app_data.length > 0 && (
                    <span
                      className={`platform-badge ${
                        item.app_data.length === 2 ? 'platform-badge-alt' : ''
                      }`}
                    >
                      {item.app_data.map((data, i) => (
                        <div
                          key={i}
                          className={`badge-cover ${
                            item.app_data.length === 2 ? 'badge-cover-alt' : ''
                          }`}
                          style={{
                            backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '90%',
                            backgroundPosition: 'center'
                          }}
                        />
                      ))}
                    </span>
                  )}
                  <img src={s3ImageGiveaway + item.images[0]} alt="" />
                </div>
                <div className="giveways-item-details">
                  <h4>{item.title}</h4>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="giveways-profile-detail">
                      <div className="giveways-com-rating">
                        <img
                          className="profile-img-giveway"
                          src={Images.Dyson}
                          alt=""
                        />
                        <span className="giveway-profile-name">
                          {item.name}
                          Jacob Jones
                        </span>
                      </div>
                      <div className="vertical-devider" />
                      <div className="giveways-com-rating">
                        <div className="rating-block-container">
                          <img src={Images.FillStar} alt="" />
                          <img src={Images.FillStar} alt="" />
                          <img src={Images.FillStar} alt="" />
                          <img src={Images.FillStar} alt="" />
                          <img src={Images.EmptyStar} alt="" />
                        </div>
                        <span>
                          {item.rating}
                          {' '}
                          4.5 rating
                        </span>
                      </div>
                    </div>
                    <div className="giveway-time-left">
                      <img src={Images.ClockBlue} alt="" />
                      <span>{formatTimeLeft(item.time_left)}</span>
                    </div>
                  </div>
                  <p>{item.description}</p>
                  <div className="d-flex align-items-center gap-2">
                    <button
                      type="button"
                      className="primary-btn"
                      onClick={() => enterGiveaway(item.giveaway_id, index)}
                      disabled={isTimeUp || item.is_participate}
                    >
                      <span>{English.G174}</span>
                    </button>
                    <div className="discussion-group ml-1">
                      {item?.total_participates > 0 &&
                        giveawayList
                          .slice(0, 3)
                          .map((list, index) => (
                            <img
                              key={index}
                              src={s3ImageProfile + list.icon}
                              alt={list.user_name}
                            />
                          ))}
                      <img
                        src={Images.GroupDiscussion}
                        alt="Group Discussion"
                        onClick={() => {
                          if (item.total_participates > 0) {
                            showList(item?.giveaway_id)
                          }
                        }}
                      />
                    </div>
                    <div
                      className="discussion-comment"
                      onClick={() => onClickComment(item?.giveaway_id)}
                    >
                      <img
                        className="poll_comment_icon"
                        src={Images.Comments}
                        alt=""
                      />
                    </div>
                    {ShowProfileModel && (
                      <div className="add-item-model edit-profile">
                        <div className="add-item-content" ref={componentRef}>
                          <div className="d-flex justify-content-between header">
                            <h3>{English.G175}</h3>
                            <button
                              type="button"
                              className="border-0 bg-none p-0"
                              onClick={close}
                            >
                              <span>
                                <img
                                  className="close-btns"
                                  src={Images.CloseIco}
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>

                          <div className="scroll-bars">
                            <div className="item-details-input pt-0">
                              <div className="list_data">
                                {giveawayListData.map((list, index) => {
                                  const calculatedIndex =
                                    (currentPage - 1) * videosPerPage +
                                    index +
                                    1
                                  return (
                                    <div
                                      className="d-flex justify-content-start list_table"
                                      key={index}
                                    >
                                      <span className="number_index">
                                        {calculatedIndex}
                                      </span>
                                      <img
                                        src={s3ImageProfile + list.icon}
                                        className="profile-pic"
                                        alt="profile"
                                      />
                                      <span className="user-name">
                                        {list.user_name}
                                      </span>
                                      <span className="time-date">
                                        {list.created_at}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                          {totalPages > 1 && (
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditGiveaway(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                      setUpdatingIndex(index)
                      ShowConfirm(item.giveaway_id)
                    }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deleteGiveaway}
                  onCancel={handleCancel}
                  isLoading={loading}
                />
                {loading && updatingIndex === index && <Loader />}
              </div>
            )
          })}
        </div>
      )}

      {commentSection && (
        <div className="add-item-modal mh-100">
          <div className="add-item-contents comment-container">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">{English.G177}</h4>
              <button
                type="button"
                className="secondary-btn-modal"
                onClick={handleCommentCancel}
              >
                <img
                  className="close-btns"
                  src={Images.CloseIco}
                  alt="clock icon"
                />
              </button>
            </div>
            <div className="comment-section">
              {isLoadingComments ? (
                <div>
                  <div className="comments-container">
                    <ul className="comments-list">
                      <li className="comment-item">
                        <button type="button" className="comment-delete">
                          <Skeleton
                            className="rounded-3"
                            width={20}
                            height={20}
                          />
                        </button>
                        <div className="comment-header mb-0 gap-2">
                          <Skeleton circle width={45} height={45} />
                          <span className="user-name">
                            <Skeleton width={150} height={20} />
                          </span>
                        </div>
                        <div className="comment-btns">
                          <button type="button">
                            <Skeleton width={20} height={20} />
                          </button>
                          <Skeleton width={20} height={20} />
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      onClick={() => handleAddComment(newComment)}
                    >
                      {English.G152}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="comments-container">
                    {commentsList?.length === 0 ? (
                      <div className="no-comments-message">{English.G148}</div>
                    ) : (
                      <ul className="comments-list">
                        {commentsList.map((comment, index) => (
                          <li key={comment.id} className="comment-item">
                            {isDeleting && (
                              <div className="loader-overlay">
                                {commentIndex === index && <Loader />}
                              </div>
                            )}
                            {comment.user.name === userData.user_name && (
                              <button
                                type="button"
                                className="comment-delete"
                                onClick={() => {
                                  setCommentIndex(index)
                                  deletePollComment(comment.comment_id)
                                }}
                              >
                                <img src={Images.Delete} alt="delete icon" />
                              </button>
                            )}
                            <div className="comment-header mb-0">
                              <img
                                src={comment.user.profilePic}
                                alt={`${comment.user.name}'s profile`}
                                className="profile-pic"
                              />
                              <span className="user-name">
                                {comment.user.name}
                              </span>
                            </div>
                            {editCommentId === comment.id ? (
                              <div className="edit-reply">
                                <input
                                  type="text"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSaveComment(
                                      comment.comment_id,
                                      editedCommentText
                                    )}
                                >
                                  {English.G117}
                                </button>
                              </div>
                            ) : (
                              <div className="comment-text">{comment.text}</div>
                            )}
                            <div className="comment-btns">
                              <button
                                type="button"
                                onClick={() => handleLikeComment(comment.comment_id)}
                              >
                                {comment.is_like ? (
                                  <img src={Images.Likes} alt="Unlike" />
                                ) : (
                                  <img src={Images.UnLikes} alt="Like" />
                                )}
                                {' '}
                                {comment.total_like}
                              </button>
                              {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  onClick={() => handleEditComment(comment.id, comment.text)}
                                >
                                  {English.G81}
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => toggleReplies(comment.id)}
                              >
                                {showReplies[comment.id]
                                  ? 'Hide Replies'
                                  : `Show Replies (${comment?.replies?.length})`}
                              </button>
                            </div>
                            {showReplies[comment.id] && (
                              <ul className="replies-list">
                                {comment.replies.map((reply) => (
                                  <li key={reply.id} className="reply-item">
                                    <div className="reply-header mb-0">
                                      <img
                                        src={reply.user.profilePic}
                                        alt={`${reply.user.name}'s profile`}
                                        className="profile-pic"
                                      />
                                      <span className="user-name">
                                        {reply.user.name}
                                      </span>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setCommentIndex(index)
                                            handleDeleteReply(
                                              comment.reply_id,
                                              reply.reply_id
                                            )
                                          }}
                                          className="comment-delete"
                                        >
                                          <img
                                            src={Images.Delete}
                                            alt="delete icon"
                                          />
                                        </button>
                                      )}
                                    </div>
                                    {editReplyId === reply.id ? (
                                      <div className="edit-reply">
                                        <input
                                          type="text"
                                          value={editedReplyText}
                                          onChange={(e) => setEditedReplyText(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleSaveReply(
                                              reply.reply_id,
                                              editedReplyText
                                            )}
                                        >
                                          {English.G117}
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="reply-text">
                                        {reply.text}
                                      </div>
                                    )}
                                    <div className="comment-btns">
                                      <button
                                        type="button"
                                        onClick={() => handleLikeReply(reply.reply_id)}
                                      >
                                        {reply.is_like ? (
                                          <img
                                            src={Images.Likes}
                                            alt="Unlike"
                                          />
                                        ) : (
                                          <img
                                            src={Images.UnLikes}
                                            alt="Like"
                                          />
                                        )}
                                        {' '}
                                        {reply.total_like}
                                      </button>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => handleEditReply(
                                              reply.id,
                                              reply.text
                                            )}
                                        >
                                          {English.G81}
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                                <li className="add-reply">
                                  <input
                                    type="text"
                                    placeholder={English.G149}
                                    value={Replied}
                                    onChange={(e) => setShowReplied(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleAddReply(
                                        comment.comment_id,
                                        Replied
                                      )}
                                  >
                                    {English.G150}
                                  </button>
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentCommentPage}
                      totalPages={totalCommentPages}
                      onPageChange={handlePageCommentChange}
                    />
                  )}
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    {commentsList?.length === 0 && (
                      <button
                        type="button"
                        className="comment_add_btn"
                        onClick={() => handleAddComment(newComment, '', '')}
                      >
                        {English.G152}
                      </button>
                    )}
                    {commentsList?.map((comment, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleAddComment(
                            newComment,
                            comment.user.profilePic,
                            comment.user.name
                          )}
                      >
                        {English.G152}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {ShowCommunityModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header pt-4 pb-3">
              <h3>{English.G178}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleClose}
              >
                <span className="giveaway-close">
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0 scroll-giveaway-height">
              <div className="item-details-input pt-0">
                <div className="input-grid">
                  <div>
                    <label>{English.G179}</label>
                    <CommonInput
                      name="title"
                      placeholder={English.G162}
                      onChange={handleChange}
                      maxLength={25}
                      value={capitalizeFirstLetter(inputFields.title)}
                    />
                  </div>
                  <div>
                    <label>{English.G180}</label>
                    <div
                      onClick={openTimePicker}
                      className="cooking-time-filed position-relative"
                    >
                      <TimePicker
                        ref={timePickerRef}
                        // className={`ant-design ${isEditing ? 'time-picker-disabled' : ''}`}
                        className={`ant-design ${
                          inputFields.time_left
                            ? 'has-value'
                            : 'placeholder-style'
                        } ${isEditing ? 'time-picker-disabled' : ''}`}
                        name="time_left"
                        onChange={onChange}
                        open={open}
                        onOpenChange={setOpen}
                        showNow={false}
                        showOk={false}
                        suffixIcon={null}
                        placeholder={English.G181}
                        value={
                          inputFields.time_left
                            ? dayjs(inputFields.time_left, 'HH:mm')
                            : null
                        }
                        format="HH:mm"
                        disabled={isEditing}
                        onSelect={(time) => {
                          const timeString = time ? time.format('HH:mm') : ''
                          setInputFields((prevState) => ({
                            ...prevState,
                            time_left: timeString
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G182}</label>
                    <Dropdown
                      title={getBrandName(inputFields.brand_id)}
                      onChanged={(d) => {
                        getTypeListApiCall()
                        setInputFields({
                          ...inputFields,
                          brand_id: d.id,
                          type_id: null
                        })
                      }}
                      Data={brandData.map((d) => ({
                        id: d.id,
                        title: d.brand
                      }))}
                      name="brand_id"
                      placeholder="Select Brands"
                      customClass={
                        inputFields.brand_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                  <div>
                    <label>{English.G183}</label>
                    <Dropdown
                      title={getTypeName(inputFields.type_id)}
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          type_id: d.id
                        })
                      }}
                      Data={typeData.map((d) => ({
                        id: d.id,
                        title: d.type
                      }))}
                      name="type_id"
                      customClass={
                        inputFields.type_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G184}</label>
                    <CommonInput
                      name="email"
                      placeholder={English.G185}
                      onChange={handleChange}
                      value={inputFields.email}
                      className="mb-0"
                      onKeyDown={(e) => preventMultipleAtSignKeydown(e, inputFields.email)}
                      onPaste={preventMultipleAtSignPaste}
                    />
                    {errors.email && (
                      <span className="error-text-email">{errors.email}</span>
                    )}
                  </div>
                  <div>
                    <label>{English.G186}</label>
                    <CommonInput
                      name="phone"
                      type="text"
                      placeholder={English.G91}
                      onChange={handleChange}
                      maxLength="12"
                      pattern="\d{12}"
                      className="mb-0"
                      value={inputFields.phone}
                    />
                    {errors.phone && (
                      <span className="error-text-email">{errors.phone}</span>
                    )}
                  </div>
                </div>
                <div className=" mt-3">
                  <div>
                    <label>{English.G187}</label>
                    <CommonInput
                      name="address"
                      placeholder={English.G62}
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.address)}
                    />
                  </div>
                </div>

                <label>{English.G188}</label>
                {/* <div className="add-tag mb-3">
                  <span>Add Tags</span>
                  <div className="d-flex flex-wrap column-gap-20">
                    <ul>
                      {tags.map((tag, index) => (
                        <li
                          key={index}
                          className={`tag-item ${
                            editingIndex === index ? 'editing' : ''
                          }`}
                        >
                          {editingIndex === index ? (
                            <div className="tag-edit-container">
                              <CommonInput
                                value={tag}
                                onChange={(e) => handleTagChange(e, index)}
                                onBlur={handleTagBlur}
                                ref={(input) => input && input.focus()}
                                className="edit-input"
                              />
                            </div>
                          ) : (
                            <>
                              {tag}
                              <button
                                type="button"
                                className="remove-tag"
                                onClick={() => handleRemoveTag(index)}
                              >
                                <img src={Remove} alt="Remove" />
                              </button>
                              <button
                                type="button"
                                className="edit-tag"
                                onClick={() => handleEditTag(index)}
                              />
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                    {tags.length < 4 && (
                      <button
                        type="button"
                        className="add-tag-btns"
                        onClick={handleAddTag}
                      >
                        <img src={PlusTag} alt="Add Tag" />
                        Add Tag
                      </button>
                    )}
                  </div>
                </div> */}
                <TagView
                  setTags={setTags}
                  tags={tags}
                  updateInputFieldsTags={updateInputFieldsTags}
                  key="shotsTags"
                />
                <div className="mt-3">
                  <label>{English.G112}</label>
                  <CommonInput
                    placeholder={English.G83}
                    name="description"
                    onChange={handleChange}
                    maxLength={250}
                    value={capitalizeFirstLetter(inputFields.description)}
                    isTextArea
                  />
                </div>
              </div>
            </div>
            <div className="item-details-input pt-2">
              <div className="button-row video_sticky_btn pl-3">
                <CommonButton
                  label={English.G78}
                  type="button"
                  onClick={handleClose}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  context={English.G232}
                  disabled={!isFormComplete}
                  onClick={handleNextClick}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowCommunityGiveawayCover && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex justify-content-between flex-column"
            ref={componentRef}
          >
            <div>
              <div className="d-flex justify-content-between header pt-4">
                <h3>{English.G190}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={handleClose}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars pb-0 scroll-giveaway-height">
                <div className="input-image-show giveaway_image_section">
                  {renderImg}
                  {inputFields.file.length < 5 && (
                    <div className="input-img-label">
                      <CommonImageUpload
                        handleImageChange={handleImageChange}
                        buttonText="Add Image"
                        selectedImg=""
                        defaultImg={Images.plusGiveaway}
                        className="plsimg"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="scroll-bars pb-4">
              <div className="item-details-input">
                <div className="button-row">
                  <CommonButton
                    label={English.G78}
                    type="button"
                    onClick={GiveawayCoverCancel}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    context={English.G232}
                    disabled={selectedImages?.length === 0}
                    onClick={HandleGiveawayProduct}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowGiveawayProduct && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header pt-4">
              <h3>{English.G191}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleClose}
              >
                <span>
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0 scroll-giveaway-height">
              <div className="row">
                <div className="col-md-7">
                  <div className="product-img-preview">
                    <div className="product-img-table">
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedImg(index)
                          }}
                          className={`product-img-item ${
                            selectedImg === index && 'product-img-blur'
                          }`}
                          style={{
                            backgroundImage: `url(${
                              typeof image === 'string'
                                ? s3ImageGiveaway + image
                                : URL.createObjectURL(image)
                            })`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                          }}
                        />
                      ))}
                    </div>
                    <div className="product-img-selected" key="123">
                      <img
                        alt=""
                        src={getImageSrc(selectedImages[0])}
                        className="w-100 h-100"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 product-detail">
                  <h2>{inputFields.title}</h2>
                  <div className="product-seller-detail">
                    <div
                      className="product-seller-logo"
                      style={{
                        background: 'url(./images/seller-logo.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    />
                    Jacob Jones
                  </div>
                  <div className="product-type">
                    <span>
                      {English.G183}
                      :
                    </span>
                    {getTypeName(inputFields.type_id)}
                  </div>
                  <div className="product-specification">
                    <h5>{English.G192}</h5>
                    <p>{inputFields.description}</p>
                  </div>
                </div>
              </div>
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />
            </div>
            <div className="item-details-input">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label={English.G116}
                  type="button"
                  onClick={handleClose}
                  isPrimary={false}
                  disabled={loading}
                  context={English.G232}
                />
                <CommonButton
                  label={isAppsSelected ? English.G128 : English.G89}
                  loading={loading}
                  type="button"
                  onClick={HandleDiscussion}
                  className={`primary-btn-model ${loading ? 'loading' : ''}`}
                  isPrimary
                  context={English.G232}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="link_view">
        <Link onClick={handleViewAll}>
          {showAll ? English.G193 : English.G194}
        </Link>
      </div>
    </div>
  )
}
export default Giveaway
