/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import './AddGiveway.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import dayjs from 'dayjs'
import { TimePicker } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { compressImage, dataDecrypt } from '../../services/http-services'
import {
  s3ImageGiveaway,
  s3ImageProfile
} from '../../services/Service'
import Loader from '../Loader/Loader'
import PlatformTag from '../ContentManager/PlatformTag'
import Pagination from '../Pagination/Pagination'
import {
  capitalizeFirstLetter,
  debounce,
  formatTimeLeft,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import TagView from '../CommanComponent/TagView'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import CommentModal from '../CommanComponent/CommentModal'
import { APICall, EndPoints } from '../../services'

const GivewayComponent = ({ showCount }) => {
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)

  const { id } = useParams()
  const navigate = useNavigate()
  const timePickerRef = useRef(null)
  const componentRef = useRef()
  const [Giveaways, setGiveaways] = useState([])
  const [giveawayList, setGiveawayList] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [selectedImg, setSelectedImg] = useState(0)
  const [ShowCommunityModel, setShowCommunityModel] = useState(false)
  const [ShowCommunityGiveawayCover, setShowCommunityGiveawayCover] =
    useState(false)
  const [ShowGiveawayProduct, setShowGiveawayProduct] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    file: [],
    title: '',
    time_left: '',
    brand_id: 0,
    type_id: 0,
    email: '',
    phone: '',
    address: '',
    tag_friends: [],
    description: ''
  })

  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [brandData, setBrandData] = useState([])
  const [typeData, setTypeData] = useState([])
  const [delGiveawayId, setDelGiveawayId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [errors, setErrors] = useState({})
  const [tags, setTags] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPageList, setCurrentPageList] = useState(1)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [commentSection, setCommentSection] = useState(false)
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const showList = (giveaway_id) => {
    giveawayParticipateList(giveaway_id)
    setShowProfileModel(true)
  }

  const AddGiveway = () => {
    setShowCommunityModel(true)
    // navigate('/add-component', { state: { defaultIndex: 5 } })
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const HandleGiveawayCover = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(true)
  }

  const handleCancel = () => {
    setShowConfirmModel(false)
  }
  const ShowConfirm = (giveawayId) => {
    setDelGiveawayId(giveawayId)
    setShowConfirmModel(true)
  }

  const handleClose = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
    clearValue()
  }
  const GiveawayCoverCancel = () => {
    setShowCommunityGiveawayCover(false)
    setShowCommunityModel(true)
  }
  // const ProductCancel = () => {
  //   setShowGiveawayProduct(false)
  //   setShowCommunityGiveawayCover(true)
  //   setSelectedImg(0)
  // }

  const HandleGiveawayProduct = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(true)
    setSelectedImg(0)
  }

  const clearValue = () => {
    setSelectedImages([])
    setSelectedImg([])
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      app_id: [],
      file: [],
      title: '',
      time_left: '',
      brand_id: 0,
      type_id: 0,
      email: '',
      phone: '',
      address: '',
      tag_friends: [],
      description: ''
    })
    setTags([])
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'phone') {
      const phoneValue = value.replace(/\D/g, '')
      setInputFields({
        ...inputFields,
        [name]: phoneValue
      })

      if (validatePhone(phoneValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: ''
        }))
      }
    } else {
      setInputFields({
        ...inputFields,
        [name]: value
      })
    }

    if (name === 'email') {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: ''
        }))
      }
    }
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const onChange = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      time_left: timeString
    }))
  }

  const openTimePicker = () => {
    setOpen(true)
  }

  const validateEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  const validatePhone = (phone) => {
    const regexMobile = /^[0-9]{11,12}$/
    return regexMobile.test(phone)
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  const getGiveaways = (search, currentPage) => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getGiveaways}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveaways(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
          if (res?.decryptedData?.data?.length !== 0) {
            giveawayParticipateList(res?.decryptedData.data.data[0].giveaway_id)
          }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getGiveaways(inputVal, currentPage), 500),
    []
  )

  // Giveaways pagination

  const totalVideos = Giveaways.length

  const handlePageChange = (pageNumber) => {
    getGiveaways(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  const enterGiveaway = (giveaway_id, index) => {
    const data = {
      giveaway_id
    }
    const temp = [...Giveaways]
    temp[index].is_participate = true
    setGiveaways(temp)
    APICall('post', data, `${EndPoints.giveawayParticipate}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const giveawayParticipateList = (giveaway_id) => {
    const platFormData = {
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayParticipateList}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveawayList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const close = () => {
    setShowProfileModel(false)
  }

  // giveaway list pagination

  const GiveawayList = 5
  const indexOfLastVideoList = currentPageList * GiveawayList
  const indexOfFirstVideoList = indexOfLastVideoList - GiveawayList
  const giveawayListData = giveawayList.slice(
    indexOfFirstVideoList,
    indexOfLastVideoList
  )
  const totalGiveaway = giveawayList.length
  const totalPagesList = Math.ceil(totalGiveaway / GiveawayList)

  const paginateList = (pageNumber) => setCurrentPageList(pageNumber)

  const renderPaginationButtonsList = () => {
    const numButtonsToShow = 3

    let startPage = currentPageList - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPagesList) {
      endPage = totalPagesList
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPageList === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginateList(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPageList = () => {
    setCurrentPageList((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPageList = () => {
    setCurrentPageList((prevPage) => Math.min(prevPage + 1, totalPagesList))
  }

  const handleImageChange = async (file, indexToReplace) => {
    const e = {
      target: {
        files: [file]
      }
    }

    const totalImagesAfterReplacement =
      indexToReplace !== undefined
        ? selectedImages?.length
        : selectedImages.length + e.target.files.length

    if (totalImagesAfterReplacement > 5) {
      toast.error('You can only upload up to 5 images.')
      return
    }

    const updatedImages = [...selectedImages]
    const updatedFiles = [...inputFields.file]

    try {
      const uploadPromises = Array.from(e.target.files).map(async (file) => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'giveaway')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            EndPoints.uploadImage,
            'creator',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)

            if (indexToReplace !== undefined && indexToReplace !== null) {
              updatedImages[indexToReplace] = decryptedCoverData
              updatedFiles[indexToReplace] = decryptedCoverData
            } else {
              updatedImages.push(decryptedCoverData)
              updatedFiles.push(decryptedCoverData)
            }
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      })

      await Promise.all(uploadPromises)
      setSelectedImages(updatedImages)
      setInputFields({ ...inputFields, file: updatedFiles })
    } catch (error) {
      console.error('Error uploading images:', error)
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)

    const updatedFiles = [...inputFields.file]
    updatedFiles.splice(index, 1)
    setInputFields({ ...inputFields, file: updatedFiles })
  }

  // const replaceImage = (index) => {
  //   const input = document.createElement('input')
  //   input.type = 'file'

  //   const handleChange = (e) => {
  //     handleImageChange(e, index)
  //     input.removeEventListener('change', handleChange)
  //   }

  //   if (input) {
  //     input.value = ''
  //     input.removeEventListener('change', handleChange)
  //     input.click()
  //     input.addEventListener('change', handleChange, { once: true })
  //     input.remove()
  //   }
  // }

  const HandleDiscussion = () => {
    if (isEditing) {
      editGiveawayDataApi()
    } else {
      addGiveawayDataSubmit()
    }
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
  }

  const handleNextClick = () => {
    const emailValid = validateEmail(inputFields.email)
    const phoneValid = validatePhone(inputFields.phone)
    if (!emailValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: English.G168
      }))
    }
    if (!phoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: English.G169
      }))
    }
    if (emailValid && phoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '',
        phone: ''
      }))
      HandleGiveawayCover()
    }
  }

  const updateInputFieldsTags = (tagsArray) => {
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      tag_friends: tagsArray
    }))
  }

  const getBrandListApiCall = () => {
    APICall('post', {}, `${EndPoints.getBrand}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setBrandData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getTypeListApiCall = () => {
    APICall('post', {}, `${EndPoints.getType}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTypeData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const addGiveawayDataSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, `${EndPoints.addGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowGiveawayProduct(false)
          setTimeout(() => {
            getGiveaways(null, currentPage)
          }, 3000)
          setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const editGiveawayDataApi = () => {
    const data = {
      title: inputFields.title,
      time_left: inputFields.time_left,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      email: inputFields.email,
      phone: inputFields.phone,
      address: inputFields.address,
      tag_friends: inputFields.tag_friends,
      description: inputFields.description,
      file: inputFields.file,
      app_id: Platformtags.map((d) => d.id),
      giveaway_id: inputFields.giveaway_id
    }

    APICall('post', data, `${EndPoints.editGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            getGiveaways(null, currentPage)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteGiveaway = () => {
    const data = {
      giveaway_id: delGiveawayId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            getGiveaways(null, currentPage)
          }, 3000)
          setShowConfirmModel(false)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const giveawayLikeData = (giveaway_id) => {
    const platFormData = {
      app_id: app_id.id,
      giveaway_id
    }
    APICall('post', platFormData, `${EndPoints.giveawayLike}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveaways(
            Giveaways?.map((item) => (item.giveaway_id === giveaway_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getBrandName = (categoryId) => {
    const categoryObj = brandData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.brand : English.G171
  }
  const getTypeName = (categoryId) => {
    const categoryObj = typeData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.type : English.G170
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        inputFields.file = copy
        return copy
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              // style={{
              //   backgroundImage: `url(${
              //     typeof image === 'string'
              //       ? s3ImageGiveaway + image
              //       : URL.createObjectURL(image)
              //   })`,
              //   backgroundSize: 'cover',
              //   backgroundPosition: 'center'
              // }}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageGiveaway + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Images.Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    handleImageChange(file, index)
                  }}
                  className="plsimg"
                  selectedImg=""
                  defaultImg={Images.plusimg}
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [selectedImages, handleDragStart])

  const validateForm = () => {
    const {
      title,
      time_left,
      brand_id,
      type_id,
      email,
      phone,
      address,
      tag_friends,
      description
    } = inputFields

    const isTagFriendsValid =
      Array.isArray(tag_friends) && tag_friends?.length > 0

    if (
      title &&
      time_left &&
      brand_id &&
      type_id &&
      email &&
      phone &&
      address &&
      isTagFriendsValid &&
      description
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const onClickEditGiveaway = (videoData) => {
    setShowCommunityModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setInitialValues({
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setPlatformTags(videoData.app_data)
    setSelectedImages(videoData.images)
    setSelectedImg(0)
    setTags(videoData.tag_friends)
  }

  // giveaway comment

  const onClickComment = (giveaway_id) => {
    setSelectedId(giveaway_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      giveawayComments(giveaway_id)
      setCommentSection(true)
    }
  }

  const fetchPollComments = (giveaway_id, delay = 3000) => {
    setTimeout(() => {
      giveawayComments(giveaway_id)
    }, delay)
  }

  const giveawayComments = (giveaway_id) => {
    const platFormData = {
      app_id: app_id?.id,
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayComment}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            poll_id: d.poll_id,
            is_like: d.is_like,
            reply_id: d.comment_id,
            total_like: d.total_like,
            reply: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const pollAddComment = (comment) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment,
      app_id: app_id?.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollAddReply = (reply_id, replyText) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment_id: reply_id,
      comment: replyText,
      app_id: app_id?.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditComment = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditReply = (reply_id, replyId) => {
    const platFormData = {
      comment_id: reply_id,
      comment: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deletePollComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const deletePollReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const pollCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setComments(
          //   comments.map((item) => (item.comment_id === reply_id
          //       ? { ...item, is_like: !item.is_like }
          //       : item))
          // )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              reply: comment.reply.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        poll_id: selectedId,
        total_like: 0,
        reply: [],
        user: {
          name: profileData?.user_name,
          profilePic: s3ImageProfile + profileData.profile_icon
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    pollAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return { ...comment, is_like: !comment.is_like, total_like: comment.is_like ? comment.total_like - 1 : comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollCommentLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          reply: [
            {
              id: (comment?.reply?.length || 0) + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImageProfile + profileData.profile_icon
              }
            },
            ...comment.reply
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    pollAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          reply: comment.reply.filter((reply) => reply.reply_id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deletePollReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    // getGiveaways()
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
    setIsLoadingComments(false)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
      ? { ...comment, text: editedCommentText }
      : comment))
    pollEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
      ? {
          ...comment,
          reply: comment.reply.map((reply) => (reply.reply_id === replyId ?
            { ...reply, text: editedReplyText } : reply))
        }
      : comment))
    pollEditReply(replyId, editedReplyText)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const commentPerPage = 5
  const indexOfLastComment = currentCommentPage * commentPerPage
  const indexOfFirstComment = indexOfLastComment - commentPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalComment = comments.length
  const totalCommentPages = Math.ceil(totalComment / commentPerPage)

  useEffect(() => {
    if (currentCommentPage > totalCommentPages) {
      setCurrentCommentPage(totalCommentPages > 0 ? totalCommentPages : 1)
    }
  }, [totalCommentPages, currentCommentPage])

  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      window.scroll(0, 0)
      getGiveaways(null, currentPage)
      getBrandListApiCall()
      getTypeListApiCall()
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  useEffect(() => {
    if (
      ShowCommunityModel ||
      ShowCommunityGiveawayCover ||
      ShowGiveawayProduct ||
      ShowProfileModel ||
      commentSection ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowCommunityModel,
    ShowCommunityGiveawayCover,
    ShowGiveawayProduct,
    ShowProfileModel,
    commentSection,
    ShowConfirmModel
  ])

  const getImageSrc = (image) => {
    if (image instanceof Blob) {
      return URL.createObjectURL(image)
    }
    if (typeof image === 'string') {
      return s3ImageGiveaway + image
    }
    return ''
  }

  return (
    <div className="">
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link">
            {/* <div className="video_button"> */}
            <h2>{English.G172}</h2>

            <div className="add-video-rows">
              <CommonButton
                label={English.G173}
                imgSrc={Images.AddIco}
                onClick={AddGiveway}
                isPrimary
                className="add_new_content"
              />
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
      <div
        className={
          showCount !== 1 &&
          'recent-video-component recent-shots-component common-card-body add-shots-component'
        }
      >
        <div
          className={
            showCount !== 1 &&
            'discussions-wrapper-header common-contnt-wrapper pb-3'
          }
        >
          {showCount !== 1 && <h2>{English.G230}</h2>}
          {showCount !== 1 && (
            <div className="items-filter">
              <div className="position-relative input-position">
                <CommonInput
                  placeholder="Search Giveaways...."
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {showCount !== 1 && (
          <div className="drop-row mb-30">
            <div className="drop-sub-row">
              {showCount !== 1 && (
                <Dropdown
                  title="Categories"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
              {showCount !== 1 && (
                <Dropdown
                  title="Ratings"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
              {showCount !== 1 && (
                <Dropdown
                  title="Duration"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
            </div>
          </div>
        )}

        {!showCount && (
          <div className="add-video-rows main_btn">
            <CommonButton
              label={English.G173}
              imgSrc={Images.AddIco}
              onClick={AddGiveway}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
        {Giveaways?.length === 0 && !isLoading ? (
          <div className="add-giveways-item mb-30">
            <div className="add-giveways-cover">
              <button
                type="button"
                className="add-item-btn"
                onClick={AddGiveway}
              >
                <img src={Images.Plus} alt="add giveaway" />
              </button>
            </div>
            <div className="add-giveways-details">
              <h4 className="add-value"> </h4>
              <div className="d-flex align-items-center justify-content-between">
                <ul className="d-flex align-items-center">
                  <li className="add-value">List Item 1</li>
                  <li className="add-value">List Item 2</li>
                </ul>
                <span className="add-value">Span Content</span>
              </div>
              <p className="add-value">Paragraph Content</p>
              <h5 className="add-value"> </h5>
            </div>
          </div>
        ) : isLoading ? (
          <div className="add-giveways-item mb-30">
            <div className="add-giveways-cover">
              {/* <Skeleton className="add-giveways-cover" width={502} height={390} /> */}
            </div>
            <div className="add-giveways-details">
              <h4 className="add-value">
                <Skeleton className="add-value" width={155} height={29} />
              </h4>
              <div className="d-flex align-items-center justify-content-between">
                <ul className="d-flex align-items-center">
                  <li className="add-value">
                    <Skeleton className="add-value" width={110} height={29} />
                  </li>
                  <li className="add-value">
                    <Skeleton className="add-value" width={110} height={29} />
                  </li>
                </ul>
                <span className="add-value">
                  <Skeleton className="add-value" width={121} height={29} />
                </span>
              </div>
              <p className="add-value">
                <Skeleton className="add-value" width={580} height={110} />
              </p>
              <h5 className="add-value">
                <Skeleton className="add-value" width={149} height={48} />
              </h5>
            </div>
          </div>
        ) : (
          <div className="add-giveways-container">
            {Giveaways?.map((item, index) => {
              if (index < (showCount || totalVideos)) {
                const timeLeftText = formatTimeLeft(item?.time_left)
                const isTimeUp = timeLeftText === 'Time is up'

                return (
                  <div
                    key={index}
                    className="added-giveways-item position-relative"
                  >
                    <div className="image-buttons z-1">
                      <label>
                        <button
                          type="button"
                          onClick={() => onClickEditGiveaway(item)}
                          className="d-none"
                        />
                      </label>
                      <button
                        type="button"
                        className="p-0"
                        onClick={() => {
                          setUpdatingIndex(index)
                          ShowConfirm(item.giveaway_id)
                        }}
                      />
                    </div>
                    <ConfirmationModal
                      isVisible={ShowConfirmModel && updatingIndex === index}
                      onConfirm={deleteGiveaway}
                      onCancel={handleCancel}
                      isLoading={loading}
                    />
                    {loading && updatingIndex === index && <Loader />}
                    <div className="giveways-item-cover">
                      <img
                        src={s3ImageGiveaway + item.images[0]}
                        alt="giveaway"
                      />
                    </div>
                    <div className="giveways-item-details">
                      <h4>{item.title}</h4>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="giveways-profile-detail">
                          <div className="giveways-com-rating">
                            <img
                              className="profile-img-giveway"
                              src={Images.Dyson}
                              alt="brand logo"
                            />
                            <span className="giveway-profile-name">
                              {item.name}
                              Jacob Jones
                            </span>
                          </div>
                          <div className="vertical-devider" />
                          <div className="giveways-com-rating">
                            <div className="rating-block-container">
                              <img src={Images.FillStar} alt="star" />
                              <img src={Images.FillStar} alt="star" />
                              <img src={Images.FillStar} alt="star" />
                              <img src={Images.FillStar} alt="star" />
                              <img src={Images.EmptyStar} alt="star" />
                            </div>
                            <span>
                              {item.rating}
                              {' '}
                              4.5 rating
                            </span>
                          </div>
                        </div>
                        <div className="giveway-time-left">
                          <img src={Images.ClockBlue} alt="clock icon" />
                          <span>{formatTimeLeft(item.time_left)}</span>
                        </div>
                      </div>
                      <p>{item.description}</p>

                      <div className="d-flex align-items-center gap-2">
                        <button
                          type="button"
                          className="primary-btn"
                          onClick={() => enterGiveaway(item.giveaway_id, index)}
                          disabled={isTimeUp || item.is_participate}
                        >
                          <span>{English.G174}</span>
                        </button>
                        <div className="discussion-group giveaway_list ml-1 ">
                          {item?.total_participates > 0 &&
                            giveawayList
                              .slice(0, 3)
                              .map((list, index) => (
                                <img
                                  key={index}
                                  src={s3ImageProfile + list.icon}
                                  alt={list.user_name}
                                />
                              ))}
                          <img
                            src={Images.GroupDiscussion}
                            alt="Group Discussion"
                            onClick={() => {
                              if (item.total_participates > 0) {
                                showList(item?.giveaway_id)
                              }
                            }}
                          />
                          <div className="like_section">
                            <button
                              type="button"
                              className="giveaway_Like"
                              onClick={() => giveawayLikeData(item.giveaway_id)}
                            >
                              {item.is_like ? (
                                <img src={Images.Likes} alt="Unlike" />
                              ) : (
                                <img src={Images.UnLikes} alt="Like" />
                              )}
                            </button>
                            <h5 className="mb-0">{item.total_likes}</h5>
                          </div>
                        </div>
                        <div
                          className="discussion-comment"
                          onClick={() => onClickComment(item?.giveaway_id)}
                        >
                          <img
                            className="poll_comment_icon"
                            src={Images.Comments}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              return null
            })}
          </div>
        )}
        {!showCount && totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      {ShowProfileModel && (
        <div className="add-item-model edit-profile">
          <div
            className="add-item-content"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header">
              <h3>{English.G175}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={close}
              >
                <span>
                  <img
                    className="close-btns"
                    src={Images.CloseIco}
                    alt="clock icon"
                  />
                </span>
              </button>
            </div>

            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                <div className="list_data">
                  {giveawayListData.map((list, index) => {
                    const calculatedIndex =
                      (currentPageList - 1) * GiveawayList +
                      index +
                      1
                    return (
                      <div
                        className="d-flex justify-content-start list_table"
                        key={index}
                      >
                        <span className="number_index">
                          {calculatedIndex}
                        </span>
                        <img
                          src={s3ImageProfile + list.icon}
                          className="profile-pic"
                          alt="profile"
                        />
                        <span className="user-name">
                          {list.user_name}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            {totalPagesList > 1 && (
              <div className="pagination-containers-list">
                <button
                  type="button"
                  className={`pagination-button ${
                    currentPageList === 1 ? 'disabled' : ''
                  }`}
                  onClick={handlePrevPageList}
                  disabled={currentPageList === 1}
                >
                  {English.G176}
                </button>
                {renderPaginationButtonsList()}
                <button
                  type="button"
                  className={`pagination-button ${
                    currentPageList === totalPagesList
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={handleNextPageList}
                  disabled={
                    currentPageList === totalPagesList
                  }
                >
                  {English.G69}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {commentSection && (
        <CommentModal
          title={English.G177}
          comments={comments}
          setComments={setComments}
          HandleCancel={handleCommentCancel}
          commentIndex={commentIndex}
          commentsList={commentsList}
          editCommentId={editCommentId}
          editReplyId={editReplyId}
          editedCommentText={editedCommentText}
          editedReplyText={editedReplyText}
          handleAddComment={handleAddComment}
          handleAddReply={handleAddReply}
          handleDeleteReply={handleDeleteReply}
          handleEditComment={handleEditComment}
          handleEditReply={handleEditReply}
          handleLikeComment={handleLikeComment}
          shotCommentReplyLike={pollReplyLike}
          handleSaveComment={handleSaveComment}
          handleSaveReply={handleSaveReply}
          isLoadingComments={isLoadingComments}
          newComment={newComment}
          profileData={profileData}
          setCommentIndex={setCommentIndex}
          setEditedCommentText={setEditedCommentText}
          setEditedReplyText={setEditedReplyText}
          setNewComment={setNewComment}
          setShowReplied={setShowReplied}
          shotCommentDelete={deletePollComment}
          showReplies={showReplies}
          toggleReplies={toggleReplies}
          isDeleting={isDeleting}
          currentPageComment={currentCommentPage}
          setCurrentPageComment={setCurrentCommentPage}
          Replied={Replied}
        />
      )}

      {ShowCommunityModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header pt-4">
              <h3>{English.G178}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleClose}
              >
                <span>
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0 scroll-giveaway-height">
              <div className="item-details-input pt-0">
                <div className="input-grid">
                  <div>
                    <label>{English.G179}</label>
                    <CommonInput
                      name="title"
                      placeholder={English.G162}
                      onChange={handleChange}
                      maxLength={25}
                      value={capitalizeFirstLetter(inputFields.title)}
                    />
                  </div>
                  <div>
                    <label>{English.G180}</label>
                    <div
                      onClick={openTimePicker}
                      className="cooking-time-filed position-relative"
                    >
                      <TimePicker
                        ref={timePickerRef}
                        className={`ant-design ${
                          inputFields.time_left
                            ? 'has-value'
                            : 'placeholder-style'
                        } ${isEditing ? 'time-picker-disabled' : ''}`}
                        name="time_left"
                        onChange={onChange}
                        open={open}
                        onOpenChange={setOpen}
                        showNow={false}
                        showOk={false}
                        suffixIcon={null}
                        placeholder={English.G181}
                        value={
                          inputFields.time_left
                            ? dayjs(inputFields.time_left, 'HH:mm')
                            : null
                        }
                        format="HH:mm"
                        disabled={isEditing}
                        onSelect={(time) => {
                          const timeString = time ? time.format('HH:mm') : ''
                          setInputFields((prevState) => ({
                            ...prevState,
                            time_left: timeString
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G182}</label>
                    <Dropdown
                      title={getBrandName(inputFields.brand_id)}
                      onChanged={(d) => {
                        getTypeListApiCall()
                        setInputFields({
                          ...inputFields,
                          brand_id: d.id,
                          type_id: null
                        })
                      }}
                      Data={brandData.map((d) => ({
                        id: d.id,
                        title: d.brand
                      }))}
                      name="brand_id"
                      customClass={
                        inputFields.brand_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                      // customClass="store_add_dropdown"
                    />
                  </div>
                  <div>
                    <label>{English.G183}</label>
                    <Dropdown
                      title={getTypeName(inputFields.type_id)}
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          type_id: d.id
                        })
                      }}
                      Data={typeData.map((d) => ({
                        id: d.id,
                        title: d.type
                      }))}
                      name="type_id"
                      customClass={
                        inputFields.type_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G184}</label>
                    <CommonInput
                      name="email"
                      placeholder={English.G185}
                      onChange={handleChange}
                      value={inputFields.email}
                      className="mb-0"
                    />
                    {errors.email && (
                      <span className="error-text-email">{errors.email}</span>
                    )}
                  </div>
                  <div>
                    <label>{English.G186}</label>
                    <CommonInput
                      name="phone"
                      type="text"
                      placeholder={English.G91}
                      onChange={handleChange}
                      maxLength="12"
                      pattern="\d{12}"
                      className="mb-0"
                      value={inputFields.phone}
                    />
                    {errors.phone && (
                      <span className="error-text-email">{errors.phone}</span>
                    )}
                  </div>
                </div>
                <div className=" mt-3">
                  <div>
                    <label>{English.G187}</label>
                    <CommonInput
                      name="address"
                      placeholder={English.G62}
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.address)}
                    />
                  </div>
                </div>

                <label>{English.G188}</label>
                <div className="mb-3">
                  <TagView
                    setTags={setTags}
                    tags={tags}
                    updateInputFieldsTags={updateInputFieldsTags}
                    key="giveways"
                  />
                </div>
                <div>
                  <label>{English.G112}</label>
                  <CommonInput
                    placeholder={English.G83}
                    name="description"
                    onChange={handleChange}
                    maxLength={250}
                    value={capitalizeFirstLetter(inputFields.description)}
                    isTextArea
                  />
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label={English.G78}
                  onClick={handleClose}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  disabled={!isFormComplete}
                  onClick={handleNextClick}
                  context={English.G232}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowCommunityGiveawayCover && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex justify-content-between flex-column"
            ref={componentRef}
          >
            <div>
              <div className="d-flex justify-content-between header pt-4">
                <h3>{English.G190}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={handleClose}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars pb-0 scroll-giveaway-height">
                <div className="input-image-show">
                  {renderImg}
                  {inputFields.file.length < 5 && (
                    <div className="input-img-label">
                      <CommonImageUpload
                        handleImageChange={handleImageChange}
                        buttonText="Add Image"
                        selectedImg=""
                        defaultImg={Images.Plus}
                        className="plsimg"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="scroll-bars button_scroll_bar">
              <div className="item-details-input">
                <div className="button-row">
                  <CommonButton
                    label={English.G78}
                    onClick={GiveawayCoverCancel}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    disabled={selectedImages.length === 0}
                    onClick={HandleGiveawayProduct}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowGiveawayProduct && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header pt-4">
              <h3>{English.G191}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleClose}
              >
                <span>
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0 scroll-giveaway-height">
              <div className="row">
                <div className="col-md-7">
                  <div className="product-img-preview">
                    <div className="product-img-table">
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedImg(index)
                          }}
                          className={`product-img-item ${
                            selectedImg === index && 'product-img-blur'
                          }`}
                          style={{
                            backgroundImage: `url(${
                              typeof image === 'string'
                                ? s3ImageGiveaway + image
                                : URL.createObjectURL(image)
                            })`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                          }}
                        />
                      ))}
                    </div>
                    <div className="product-img-selected" key="123">
                      <img
                        alt=""
                        src={getImageSrc(selectedImages[0])}
                        className="w-100 h-100"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 product-detail">
                  <h2>{inputFields.title}</h2>
                  <div className="product-seller-detail">
                    {/* <div
                      className="product-seller-logo"
                      style={{
                        background: 'url(../../images/dyson.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    /> */}
                    Jacob Jones
                  </div>
                  <div className="product-type">
                    <span>
                      {English.G183}
                      :
                    </span>
                    {getTypeName(inputFields.type_id)}
                  </div>
                  <div className="product-specification">
                    <h5>{English.G192}</h5>
                    <p>{inputFields.description}</p>
                  </div>
                </div>
              </div>
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row video_sticky_btn">
                <CommonButton
                  label={English.G116}
                  type="button"
                  onClick={handleClose}
                  isPrimary={false}
                  disabled={loading}
                  context={English.G232}
                />
                <CommonButton
                  label={isAppsSelected ? English.G128 : English.G89}
                  loading={loading}
                  type="button"
                  onClick={HandleDiscussion}
                  className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                  isPrimary
                  disabled={loading}
                  context={English.G232}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {Giveaways?.length >= 1 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/community-builder/${id}`, { state: { defaultIndex: 2 } })}
        >
          {English.G144}
        </div>
      )}
    </div>
  )
}
export default GivewayComponent
