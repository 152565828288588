import React, { useState } from 'react'
import Dropdown from '../Dropdown/Dropdown'
import { Images } from '../../helpers'

const Hotel = () => {
  const items = [
    {
      images: './images/hotels/2.jpg',
      name: 'Four Seasons Hotel Gresham Palace Suites',
      bathrooms: '2',
      bedrooms: '8',
      location: '704 Aberdeen Way Southlake, TX',
      price: '$460,000',
      type: '5',
      time: 'night'
    },
    {
      images: './images/hotels/2.jpg',
      name: 'Four Seasons Hotel Gresham Palace Suites',
      bathrooms: '2',
      bedrooms: '8',
      location: '704 Aberdeen Way Southlake, TX',
      price: '$460,000',
      type: '5',
      time: 'night'
    },
    {
      images: './images/hotels/2.jpg',
      name: 'Four Seasons Hotel Gresham Palace Suites',
      bathrooms: '2',
      bedrooms: '8',
      location: '704 Aberdeen Way Southlake, TX',
      price: '$460,000',
      type: '5',
      time: 'night'
    },
    {
      images: './images/hotels/2.jpg',
      name: 'Four Seasons Hotel Gresham Palace Suites',
      bathrooms: '2',
      bedrooms: '8',
      location: '704 Aberdeen Way Southlake, TX',
      price: '$460,000',
      type: '5',
      time: 'night'
    },
    {
      images: './images/hotels/2.jpg',
      name: 'Four Seasons Hotel Gresham Palace Suites',
      bathrooms: '2',
      bedrooms: '8',
      location: '704 Aberdeen Way Southlake, TX',
      price: '$460,000',
      type: '5',
      time: 'night'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(3)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3)
  }
  return (
    <div className="Community-profile common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Hotels
        <div className="drop-search">
          <input placeholder="Search here..." />
        </div>
      </h3>
      <div className="drop-row">
        <div className="drop-sub-row">
          <Dropdown
            title="Categories"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
          <Dropdown
            title="Ratings"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
          <Dropdown
            title="Duration"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
      </div>
      <div className="community-profile-container">
        {items.slice(0, visibleItems).map((item) => (
          <div key={item.name} className="hotel--item--list">
            <div className="hotel--list--item justify-content-between align-items-center">
              <div className="d-flex col-gap-20">
                <div
                  className="hotel--image position-relative"
                  style={{
                    background: `url(${item.images})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="hotel--type">
                    {item.type}
                    {' '}
                    Star
                  </div>
                </div>
                <div className="hotel--name">
                  <h4>{item.name}</h4>
                  <span className="hotel--location">
                    <img alt="" src={Images.Location} />
                    {item.location}
                  </span>
                  <ul>
                    <li>
                      <img alt="" src={Images.Bedroom1} />
                      {item.bedrooms}
                      {' '}
                      Bedrooms
                    </li>
                    <li>
                      <span className="divider" />
                    </li>
                    <li>
                      <img alt="" src={Images.Bathroom} />
                      {item.bathrooms}
                      {' '}
                      Bathrooms
                    </li>
                  </ul>
                </div>
              </div>
              <div className="hotel--price">
                <p>
                  <span>{item.price}</span>
                  {' '}
                  /
                  {item.time}
                </p>
              </div>
            </div>
          </div>
        ))}
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={Images.DownIco} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Hotel
