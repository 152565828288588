/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { useSelector } from 'react-redux'
import { English, Images } from '../../helpers'
import { s3ImageProfile } from '../../services/Service'

const Friends = () => {
    const UserData = useSelector((state) => state.user?.userData)
  const initialApps = [
    {
      id: 1,
      image: Images.Friend_image1,
      name: 'Anna Mirla ',
      type: '26  years'
    },
    {
      id: 2,
      image: Images.Friend_image2,
      name: 'Leah Grun...',
      type: '21  years'
    },
    {
      id: 3,
      image: Images.Friend_image1,
      name: 'Leah Grun...',
      type: '21  years'
    },
    {
      id: 4,
      image: Images.Friend_image3,
      name: 'Claudia Pf...',
      type: '26  years'
    },
    {
        id: 1,
        image: Images.Friend_image1,
        name: 'Anna Mirla ',
        type: '26  years'
      },
      {
        id: 2,
        image: Images.Friend_image2,
        name: 'Leah Grun...',
        type: '21  years'
      },
      {
        id: 3,
        image: Images.Friend_image1,
        name: 'Leah Grun...',
        type: '21  years'
      },
      {
        id: 4,
        image: Images.Friend_image3,
        name: 'Claudia Pf...',
        type: '26  years'
      },
      {
        id: 1,
        image: Images.Friend_image1,
        name: 'Anna Mirla ',
        type: '26  years'
      },
      {
        id: 2,
        image: Images.Friend_image2,
        name: 'Leah Grun...',
        type: '21  years'
      },
      {
        id: 3,
        image: Images.Friend_image1,
        name: 'Leah Grun...',
        type: '21  years'
      },
      {
        id: 4,
        image: Images.Friend_image3,
        name: 'Claudia Pf...',
        type: '26  years'
      }
  ]


  const initialData = [
    {
      id: 1,
      image: UserData.profile_image,
      name: UserData.full_name,
      user_name: UserData.user_name,
      description: English.A131
    },
    {
      id: 1,
      image: UserData.profile_image,
      name: UserData.full_name,
      user_name: UserData.user_name,
      description: English.A131
    },
    {
      id: 1,
      image: UserData.profile_image,
      name: UserData.full_name,
      user_name: UserData.user_name,
      description: English.A131
    }
  ]
  return (
    <div className="profiles-section">
      <div className="flex flex-1 h-full flex-row !gap-5">
        <div className="bg-inactive w-[70%] h-full flex-row bg-white rounded-4 !p-5 shadow-sm">
          <h2 className="text-gradient font-extrabold text-2xl italic">{English.A140}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {initialApps.map((i) => (
              <div
                key={i.id}
                className={`w-[150px] h-auto p-[16px] rounded-2xl bg-white ${
                  i.isSelected ? 'selected' : 'selectable'
                }`}
              >
                <div className="flex flex-col gap-3">
                  <div>
                    <div className="flex flex-col items-center justify-center">
                      <div className="flex flex-col items-center gap-2">
                        <img src={i.image} alt="" />
                        <div className="flex-row items-center gap-2 mb-2">
                          <h3 className="text-sm font-semibold text-[#0B0A0A] mb-0 text-center">
                            {i.name}
                          </h3>
                          <p className="text-sm font-normal text-[#4E4949] mb-0 text-center">
                            {i.type}
                          </p>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <img src={Images.FriendProfile} alt="icon" />
                          <span className="text-xs font-semibold text-[#0B0A0A]">
                            View Profile
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-inactive w-[30%] h-full flex-row bg-white rounded-4 !p-5 shadow-sm">
          <div className="p-0 bg-gray-50 min-h-screen">
            <div className="flex justify-between items-center">
              <div className="text-gradient font-extrabold pr-1 !pb-5 text-2xl italic bg-clip-text text-transparent uppercase">
                {English.A129}
              </div>
            </div>
            <div>
              <div>
                <h4 className="font-bold text-lg italic text-[#0B0A0A]">
                  {English.A130}
                </h4>

                {initialData.map((i) => (
                  <div
                    key={i.id}
                    className="w-[334px] h-auto p-[16px] rounded-2xl bg-white border border-[#E4E4E4] mb-2"
                  >
                    <div className="flex flex-1 flex-col">
                      <div className="d-flex items-center gap-[10px] !pb-3">
                        <img
                          className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl border-2 border-border"
                          src={s3ImageProfile + i.image}
                          alt="sad"
                        />
                        <div className="flex flex-1 flex-col">
                          <div className="bg-primaryGradient font-extrabold text-xl bg-clip-text text-transparent">
                            {i.name}
                          </div>
                          <div className="text-black font-medium text-lg">{`@${i.user_name}`}</div>
                        </div>
                      </div>
                      <div className="bg-primaryGradient font-normal text-xs bg-clip-text text-transparent">
                        {i.description}
                      </div>
                    </div>
                  </div>
          ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Friends
