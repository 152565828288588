import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Images from '../../helpers/Images'
// import PropTypes from "prop-types";

const MenuAll = () => {
  const location = useLocation()
  const { state } = location || {}
  const items = state || []
  const [visibleItems, setVisibleItems] = useState(12)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  return (
    <div className="creator-profile-main">
      <div className=" services-container menu-wrapper common-card-body recent-video-component">
        <div className="d-flex align-items-center justify-content-between">
          <h3>Breakfast</h3>
          <div className="drop-search">
            <input placeholder="Search here..." />
          </div>
        </div>
        <div className="breackfast-grid--item mt-30">
          {items.slice(0, visibleItems).map((item) => (
            <div className="menu-grid-item" key={item.name}>
              <div
                className="menu-item-cover"
                style={{
                  background: `url(${item.img})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="rating">
                  <img alt="" src={Images.RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="menu-item-details d-flex align-items-center justify-content-between">
                <span>{item.name}</span>
                <h4>{item.price}</h4>
              </div>
            </div>
          ))}
        </div>
        {items.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={Images.DownIco} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default MenuAll
