/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ProgressBar from '@ramonak/react-progress-bar'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { format, isBefore } from 'date-fns'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import PlatformTag from './PlatformTag'
import { s3ImageProfile, urlSystemIcon } from '../../services/Service'
import Loader from '../Loader/Loader'
import Pagination from '../Pagination/Pagination'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import CommonDatePicker from '../CommonDatePicker/CommonDatePicker'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const Polls = () => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const componentRef = useRef()

  const [ShowPollPreview, setShowPollPreview] = useState(false)
  const [ShowPollModel, setShowPollModel] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [step, setStep] = useState('')
  const [steps, setSteps] = useState([])
  const [editIndex, setEditIndex] = useState(-1)
  const [editValue, setEditValue] = useState('')
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    category_id: 0,
    sub_category_id: 0,
    expire_date: '',
    options: []
  })
  const [delPollId, setDelPollId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [pollsDetail, setPollsDetail] = useState([])
  const [voteList, setVoteList] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [isPublishEnabled, setIsPublishEnabled] = useState(false)
  // const [votedPolls, setVotedPolls] =  useState({})
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [initialValues, setInitialValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(null)

  const [commentSection, setCommentSection] = useState(false)

  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const HandleCancel = () => {
    clearValue()
    setShowPollModel(false)
    setShowPollPreview(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    setShowConfirmModel(false)
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const handleInputChangeStep = (e) => {
    setStep(e.target.value)
  }
  const handleAddStep = () => {
    if (
      step.trim() !== '' &&
      steps.filter((s) => s.trim() !== '')?.length < 4
    ) {
      const updatedSteps = [...steps, step]
      setSteps(updatedSteps)
      const options = [...inputFields.options, { option: step }]
      setInputFields({
        ...inputFields,
        options
      })
      setStep('')
      checkIfPublishEnabled(updatedSteps)
    }
  }

  const handleEditStep = (index) => {
    if (steps[index].trim() !== '') {
      setEditIndex(index)
      setEditValue(steps[index])
    }
  }

  const handleRemoveStep = (indexToRemove) => {
    const updatedSteps = steps.filter((_, index) => index !== indexToRemove)
    const { options } = inputFields
    options[indexToRemove].isDelete = true
    setSteps(updatedSteps)
    const updatedFields = {
      ...inputFields,
      options
    }
    setInputFields(updatedFields)
    checkIfPublishEnabled(updatedSteps)
  }

  const handleSaveEditStep = () => {
    const updatedSteps = [...steps]
    updatedSteps[editIndex] = editValue
    setSteps(updatedSteps)
    setInputFields({
      ...inputFields,
      [`option_${editIndex + 1}`]: editValue
    })
    setEditIndex(-1)
    setEditValue('')
    checkIfPublishEnabled(updatedSteps)
  }

  const checkIfPublishEnabled = (updatedSteps) => {
    const nonEmptySteps = updatedSteps.filter((step) => step.trim() !== '')
    const hasChanges =
      JSON.stringify(initialValues) !== JSON.stringify(inputFields)
    setIsPublishEnabled(nonEmptySteps.length > 0 && hasChanges)
  }

  const nonEmptySteps = steps.filter((step) => step.trim() !== '')

  const HandlePollsPreview = () => {
    setShowPollPreview(true)
  }
  // const HandlePreCancel = () => {
  //   setShowPollModel(true)
  //   setShowPollPreview(false)
  // }
  const publishPoll = () => {
    if (isEditing) {
      editPollDataApi()
    } else {
      addPollDataSubmit()
    }
    setShowPollPreview(false)
    setShowPollModel(false)
    setIsEditing(false)
    clearValue()
  }

  const ShowCommunityGiveaway = () => {
    MainCategory()
    setShowPollModel(true)
  }

  const ShowConfirm = (poll_id) => {
    setDelPollId(poll_id)
    setShowConfirmModel(true)
  }

  const clearValue = () => {
    setPlatformTags([])
    setSteps([])
    setCategory([])
    setSubCategory([])
    setStep('')
    setInputFields({
      ...inputFields,
      app_id: [],
      topic: '',
      category_id: '',
      sub_category_id: '',
      options: [],
      expire_date: ''
    })
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const close = () => {
    setShowProfileModel(false)
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const getPollLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getPollsLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPollsDetail(res?.decryptedData.data)
          // const { data } = dataDecrypt(res?.data?.data)
          // if (data.length !== 0) {
          //   const poll_id = data.map((poll) => poll.poll_id)
          //   voterList(poll_id)
          // }
          // const { data } = dataDecrypt(res.data.data)
          // data.forEach((poll) => {
          //   voterList(poll.poll_id, poll)
          // })
          // if (data.length !== 0) {
          //   voterList(data[0].poll_id)
          // }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const addPollDataSubmit = () => {
    const options = inputFields.options.map((i) => i.option)
    APICall(
      'post',
      { ...inputFields, options },
      `${EndPoints.addPoll}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowPollPreview(false)
          setTimeout(() => {
            getPollLibraryData()
          }, 3000)
          setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const deletePoll = () => {
    const data = {
      poll_id: delPollId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deletePoll}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getPollLibraryData()
          }, 3000)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const MainCategory = () => {
    APICall('post', {}, `${EndPoints.getCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const SubCategory = (data) => {
    APICall('post', data, `${EndPoints.getSubCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setSubCategory(res?.decryptedData[0]?.sub_category)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : English.G181
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory.find(
      (sc) => sc.sub_category_id === subCategoryId
    )
    return subCategoryItem ? subCategoryItem.sub_category_name : English.G181
  }

  const editPollDataApi = () => {
    const options = inputFields.options.map((i) => ({
      id: i?.id ?? null,
      option: i.isDelete ? null : i.option
    }))
    const data = {
      topic: inputFields.topic,
      category_id: inputFields.category_id,
      sub_category_id: inputFields.sub_category_id,
      options,
      app_id: Platformtags.map((d) => d.id),
      poll_id: inputFields.poll_id,
      expire_date: inputFields.expire_date
    }
    APICall('post', data, `${EndPoints.editPoll}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            getPollLibraryData()
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const onClickEditPoll = (videoData) => {
    setShowPollModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      options: videoData.options,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id,
      expire_date: formatDateToISO(videoData.expire_date)
    })
    setInitialValues({
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      options: videoData.options,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id,
      expire_date: formatDateToISO(videoData.expire_date)
    })
    setPlatformTags(videoData.app_data)
    const data = videoData.options.map((i) => i.option)
    setSteps(data)
    MainCategory({ category_id: videoData.category_id })
    SubCategory({ category_id: videoData.category_id })
  }

  const voterList = async (pollIds) => {
    const platFormData = {
      app_id: app_id?.id,
      poll_id: pollIds
    }
    APICall('post', platFormData, `${EndPoints.voterList}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVoteList(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const voteAdd = (poll_id, optionIndex) => {
    const platFormData = {
      poll_id,
      option: optionIndex
    }
    setLoading(true)
    APICall('post', platFormData, `${EndPoints.addVote}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setVotedPolls(res?.decryptedData.data)
          // setPollsDetail(
          //   pollsDetail.map((poll) => (poll.poll_id === poll_id
          //       ? {
          //           ...poll,
          //           options: poll.options.map((option, idx) => (idx === optionIndex
          //               ? {
          //                   ...option,
          //                   is_vote: true
          //                 }
          //               : option))
          //         }
          //       : poll))
          // )
          setTimeout(() => {
            getPollLibraryData()
            setLoading(false)
          }, 2000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  // console.log(votedPolls, 'votedPolls')

  const showList = (poll_id) => {
    voterList(poll_id)
    setShowProfileModel(true)
  }

  const handleViewAll = () => {
    setShowAll(!showAll)
  }
  const displayedPollsDetail = showAll ? pollsDetail : pollsDetail.slice(0, 1)

  const isAppsSelected = inputFields.app_id?.length > 0

  const validateForm = () => {
    const { topic, category_id, sub_category_id, options } = inputFields
    return (
      topic.trim() !== '' &&
      category_id !== 0 &&
      sub_category_id !== 0 &&
      options.length >= 2
    )
  }

  const formatDateToISO = (dateString) => {
    if (!dateString) return null

    const date = new Date(dateString)
    if (isNaN(date)) return null

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  // voter list pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const voteListData = voteList.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = voteList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // poll comment

  const onClickComment = (poll_id) => {
    setSelectedId(poll_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      pollComments(poll_id)
      setCommentSection(true)
    }
  }

  const fetchPollComments = (poll_id, delay = 3000) => {
    setTimeout(() => {
      pollComments(poll_id)
    }, delay)
  }

  const pollComments = (poll_id) => {
    const platFormData = {
      app_id: app_id?.id,
      poll_id
    }
    APICall('post', platFormData, `${EndPoints.pollComment}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            poll_id: d.poll_id,
            is_like: d.is_like,
            comment_id: d.comment_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const pollAddComment = (comment) => {
    const platFormData = {
      poll_id: selectedId,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollAddReply = (reply_id, replyText) => {
    const platFormData = {
      poll_id: selectedId,
      comment_id: reply_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const pollEditComment = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditReply = (reply_id, replyId) => {
    const platFormData = {
      comment_id: reply_id,
      comment: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deletePollComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall('post', data, `${EndPoints.pollCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }
  const deletePollReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: replyId
    }
    APICall('post', data, `${EndPoints.pollCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const pollCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.comment_id === reply_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        poll_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    pollAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    pollAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deletePollReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    getPollLibraryData()
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    pollEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    pollEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const commentPerPage = 5
  const indexOfLastComment = currentCommentPage * commentPerPage
  const indexOfFirstComment = indexOfLastComment - commentPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalComment = comments.length
  const totalCommentPages = Math.ceil(totalComment / commentPerPage)

  const handlePageCommentChange = (pageNumber) => {
    setCurrentCommentPage(pageNumber)
  }

  useEffect(() => {
    if (currentCommentPage > totalCommentPages) {
      setCurrentCommentPage(totalCommentPages > 0 ? totalCommentPages : 1)
    }
  }, [totalCommentPages, currentCommentPage])

  useEffect(() => {
    MainCategory()
    getPollLibraryData()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPollModel(false)
        setShowPollPreview(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    checkIfPublishEnabled(steps)
  }, [inputFields, steps])

  useEffect(() => {
    if (!isEditing) {
      setIsPublishEnabled(validateForm())
    }
  }, [inputFields, steps])

  useEffect(() => {
    if (isEditing) {
      setIsPublishEnabled(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, steps])

  useEffect(() => {
    if (ShowPollModel || ShowPollPreview || ShowProfileModel) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowPollModel, ShowPollPreview, ShowProfileModel])

  return (
    <div className="content-manager-Polls-wrapper common-card-body community-margin">
      <div className="polls-wrapper-header common-contnt-wrapper">
        <h2>{English.G195}</h2>
        <Link onClick={ShowCommunityGiveaway}>
          {/* <img src={AddIco} alt="" /> */}
          {English.G196}
        </Link>
      </div>

      {displayedPollsDetail?.length === 0 && !isLoading ? (
        <div className="add-polls-container mb-3">
          <div className="add-polls-item common-card-body">
            <div className="position-relative polls-item-head">
              <h6 className="add-value font-hidden">Heading 2</h6>
              <button
                type="button"
                className="add-item-btn"
                onClick={ShowCommunityGiveaway}
              >
                <img src={Images.Plus} alt="add poll" />
              </button>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 1</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 1</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 2</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 2</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 3</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 3</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 4</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 4</h5>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="polls-item common-card-body position-relative">
          <div className="">
            <Skeleton width={50} height={25} />
          </div>
          <div className="poll-header">
            <p className="poll_topic">
              <Skeleton width={600} height={30} />
            </p>
            <div className="discussion-group">
              <Skeleton width={25} height={25} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>

          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
        </div>
      ) : (
        displayedPollsDetail?.map((item, index) => {
          const totalVotes = item.options.reduce(
            (acc, option) => acc + option.total_votes,
            0
          )

          const optionPercentages = item.options.map((option) => {
            return totalVotes > 0
              ? ((option.total_votes / totalVotes) * 100).toFixed(2)
              : '0.00'
          })

          const expireDate = new Date(item.expire_date)
          const currentDate = new Date()

          const expirationMessage = isBefore(expireDate, currentDate)
            ? 'This poll has expired.'
            : `Poll will expire by ${format(expireDate, 'dd MMM, yyyy')}`
          return (
            <div
              className="polls-item common-card-body position-relative"
              key={item.tag}
            >
              <div className="polls-tag">
                <span>
                  {/* {item.tag} */}
                  Web Design
                </span>
              </div>
              <div className="poll-header">
                <p className="poll_topic">
                  {expandedIndex === index
                    ? item?.topic
                    : `${item?.topic?.slice(0, 130)}`}
                  {item?.topic?.length > 130 && (
                    <span
                      className="see-more"
                      onClick={(e) => {
                        e.stopPropagation()
                        setExpandedIndex(
                          expandedIndex === index ? null : index
                        )
                      }}
                    >
                      {expandedIndex === index ? English.G145 : English.G146}
                    </span>
                  )}
                </p>
                <div className="comment_people">
                  <div className="discussion-group">
                    {item?.total_votes > 0 &&
                      voteList
                        .slice(0, 3)
                        .map((vote, index) => (
                          <img
                            key={index}
                            src={s3ImageProfile + vote.profile_icon}
                            alt={vote.user_name}
                          />
                        ))}
                    <img
                      src={Images.GroupDiscussion}
                      alt=""
                      onClick={() => {
                        if (item.total_votes > 0) {
                          showList(item?.poll_id)
                        }
                      }}
                    />
                    {ShowProfileModel && (
                      <div className="add-item-model edit-profile">
                        <div className="add-item-content" ref={componentRef}>
                          <div className="d-flex justify-content-between header">
                            <h3>{English.G197}</h3>
                            <button
                              type="button"
                              className="border-0 bg-none p-0"
                              onClick={close}
                            >
                              <span>
                                <img
                                  className="close-btns"
                                  src={Images.CloseIco}
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>

                          <div className="scroll-bars">
                            <div className="item-details-input pt-0">
                              <div className="list_data">
                                {voteListData.map((list, index) => {
                                  const calculatedIndex =
                                    (currentPage - 1) * videosPerPage +
                                    index +
                                    1
                                  return (
                                    <div
                                      className="d-flex justify-content-start list_table"
                                      key={index}
                                    >
                                      <span className="number_index">
                                        {calculatedIndex}
                                      </span>
                                      <img
                                        src={s3ImageProfile + list.profile_icon}
                                        className="profile-pic"
                                        alt="profile"
                                      />
                                      <span className="user-name">
                                        {list.user_name}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                          {totalPages > 1 && (
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="discussion-comment"
                    onClick={() => onClickComment(item?.poll_id)}
                  >
                    <img
                      className="poll_comment_icon"
                      src={Images.Comments}
                      alt=""
                    />
                    {/* {item.total_votes} */}
                  </div>
                  <div className="discussion-button">
                    <button type="button">
                      <img src={Images.IconsMore} alt="" />
                    </button>
                  </div>
                </div>
              </div>

              {item.options
                .filter((option) => option !== '')
                .map((option, optIndex) => (
                  <div
                    className="polls-option"
                    key={optIndex}
                    onClick={() => {
                      const anyOptionVoted = item.options.some(
                        (opt) => opt.is_vote === true
                      )
                      if (anyOptionVoted) {
                        toast.error('You have already voted for this poll.')
                      } else {
                        voteAdd(item.poll_id, option.id)
                      }
                    }}
                  >
                    <ProgressBar
                      customLabel={`${option.option}`}
                      className="profress-line"
                      baseBgColor="linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)"
                      height={43}
                      completed={optionPercentages[optIndex]}
                      borderRadius={15}
                      bgColor={
                        item.total_votes > 0
                          ? 'linear-gradient(134deg, rgba(0, 21, 151, 1) 3.03%, rgba(1, 16, 77, 1) 47.1%, rgba(2, 12, 28, 1) 80.14%, rgba(2, 11, 9, 1) 96.45%)'
                          : 'linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)'
                      }
                      isLabelVisible={false}
                      labelClassName={
                        option.is_vote === true
                          ? 'active_label'
                          : 'polls-label'
                      }
                    />
                    <div className="polls-group">
                      <div className="discussion-group">
                        {item?.total_votes > 0 &&
                          voteList
                            ?.filter((vote) => vote.option === optIndex + 1)
                            ?.slice(0, 3)
                            ?.map((vote, index) => (
                              <img
                                key={index}
                                src={s3ImageProfile + vote.profile_icon}
                                alt={vote.user_name}
                              />
                            ))}
                        <img
                          src={Images.GroupDiscussion}
                          alt="Group Discussion"
                        />
                      </div>
                      {item[`option_${optIndex + 1}_vote`]}
                      {' '}
                      {English.G198}
                    </div>
                  </div>
                ))}
              <p className="expiration-message">{expirationMessage}</p>
              {item?.app_data?.length > 0 && (
                <span
                  className={`platform-badge ${
                    item?.app_data?.length === 2 ? 'platform-badge-alt' : ''
                  }`}
                >
                  {item.app_data.map((data, i) => (
                    <div
                      key={i}
                      className={`badge-cover ${
                        item?.app_data?.length === 2 ? 'badge-cover-alt' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '90%',
                        backgroundPosition: 'center'
                      }}
                    />
                  ))}
                </span>
              )}
              <div className="image-buttons z-1">
                <label>
                  <button
                    type="button"
                    onClick={() => onClickEditPoll(item)}
                    className="d-none"
                  />
                </label>
                <button
                  type="button"
                  className="p-0"
                  onClick={() => {
                    setUpdatingIndex(index)
                    ShowConfirm(item.poll_id)
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel && updatingIndex === index}
                onConfirm={deletePoll}
                onCancel={handleCancel}
                isLoading={loading}
              />
              {loading && updatingIndex === index && <Loader />}
            </div>
          )
        })
      )}

      {commentSection && (
        <div className="add-item-modal mh-100">
          <div className="add-item-contents comment-container">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">{English.G199}</h4>
              <button
                type="button"
                className="secondary-btn-modal"
                onClick={handleCommentCancel}
              >
                <img
                  className="close-btns"
                  src={Images.CloseIco}
                  alt="clock icon"
                />
              </button>
            </div>
            <div className="comment-section">
              {isLoadingComments ? (
                <div>
                  <div className="comments-container">
                    <ul className="comments-list">
                      <li className="comment-item">
                        <button type="button" className="comment-delete">
                          <Skeleton
                            className="rounded-3"
                            width={20}
                            height={20}
                          />
                        </button>
                        <div className="comment-header mb-0 gap-2">
                          <Skeleton circle width={45} height={45} />
                          <span className="user-name">
                            <Skeleton width={150} height={20} />
                          </span>
                        </div>
                        <div className="comment-btns">
                          <button type="button">
                            <Skeleton width={20} height={20} />
                          </button>
                          <Skeleton width={20} height={20} />
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      onClick={() => handleAddComment(newComment)}
                    >
                      {English.G152}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="comments-container">
                    {commentsList?.length === 0 ? (
                      <div className="no-comments-message">{English.G148}</div>
                    ) : (
                      <ul className="comments-list">
                        {commentsList.map((comment, index) => (
                          <li key={comment.id} className="comment-item">
                            {isDeleting && (
                              <div className="loader-overlay">
                                {commentIndex === index && <Loader />}
                              </div>
                            )}
                            {comment.user.name === userData.user_name && (
                              <button
                                type="button"
                                className="comment-delete"
                                onClick={() => {
                                  setCommentIndex(index)
                                  deletePollComment(comment.comment_id)
                                }}
                              >
                                <img src={Images.Delete} alt="delete icon" />
                              </button>
                            )}
                            <div className="comment-header mb-0">
                              <img
                                src={comment.user.profilePic}
                                alt={`${comment.user.name}'s profile`}
                                className="profile-pic"
                              />
                              <span className="user-name">
                                {comment.user.name}
                              </span>
                            </div>
                            {editCommentId === comment.id ? (
                              <div className="edit-reply">
                                <input
                                  type="text"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSaveComment(
                                      comment.comment_id,
                                      editedCommentText
                                    )}
                                >
                                  {English.G117}
                                </button>
                              </div>
                            ) : (
                              <div className="comment-text">{comment.text}</div>
                            )}
                            <div className="comment-btns">
                              <button
                                type="button"
                                onClick={() => handleLikeComment(comment.comment_id)}
                              >
                                {comment.is_like ? (
                                  <img src={Images.Likes} alt="Unlike" />
                                ) : (
                                  <img src={Images.UnLikes} alt="Like" />
                                )}
                                {' '}
                                {comment.total_like}
                              </button>
                              {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  onClick={() => handleEditComment(comment.id, comment.text)}
                                >
                                  {English.G81}
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => toggleReplies(comment.id)}
                              >
                                {showReplies[comment.id]
                                  ? 'Hide Replies'
                                  : `Show Replies (${comment?.replies?.length})`}
                              </button>
                            </div>
                            {showReplies[comment.id] && (
                              <ul className="replies-list">
                                {comment.replies.map((reply) => (
                                  <li key={reply.id} className="reply-item">
                                    <div className="reply-header mb-0">
                                      <img
                                        src={reply.user.profilePic}
                                        alt={`${reply.user.name}'s profile`}
                                        className="profile-pic"
                                      />
                                      <span className="user-name">
                                        {reply.user.name}
                                      </span>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setCommentIndex(index)
                                            handleDeleteReply(
                                              comment.reply_id,
                                              reply.reply_id
                                            )
                                          }}
                                          className="comment-delete"
                                        >
                                          <img
                                            src={Images.Delete}
                                            alt="delete icon"
                                          />
                                        </button>
                                      )}
                                    </div>
                                    {editReplyId === reply.id ? (
                                      <div className="edit-reply">
                                        <input
                                          type="text"
                                          value={editedReplyText}
                                          onChange={(e) => setEditedReplyText(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleSaveReply(
                                              reply.reply_id,
                                              editedReplyText
                                            )}
                                        >
                                          {English.G117}
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="reply-text">
                                        {reply.text}
                                      </div>
                                    )}
                                    <div className="comment-btns">
                                      <button
                                        type="button"
                                        onClick={() => handleLikeReply(reply.reply_id)}
                                      >
                                        {reply.is_like ? (
                                          <img
                                            src={Images.Likes}
                                            alt="Unlike"
                                          />
                                        ) : (
                                          <img
                                            src={Images.UnLikes}
                                            alt="Like"
                                          />
                                        )}
                                        {' '}
                                        {reply.total_like}
                                      </button>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => handleEditReply(
                                              reply.id,
                                              reply.text
                                            )}
                                        >
                                          {English.G81}
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                                <li className="add-reply">
                                  <input
                                    type="text"
                                    placeholder={English.G149}
                                    value={Replied}
                                    onChange={(e) => setShowReplied(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleAddReply(
                                        comment.comment_id,
                                        Replied
                                      )}
                                  >
                                    {English.G150}
                                  </button>
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentCommentPage}
                      totalPages={totalCommentPages}
                      onPageChange={handlePageCommentChange}
                    />
                  )}
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    {commentsList?.length === 0 && (
                      <button
                        type="button"
                        className="comment_add_btn"
                        onClick={() => handleAddComment(newComment, '', '')}
                      >
                        {English.G152}
                      </button>
                    )}
                    {commentsList?.map((comment, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleAddComment(
                            newComment,
                            comment.user.profilePic,
                            comment.user.name
                          )}
                      >
                        {English.G152}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {ShowPollModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header pt-4">
              <h3 className="shot_upload_file_text">{English.G200}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0 scroll-giveaway-height">
              <div className="item-details-input pt-0">
                <div>
                  <label>{English.G201}</label>
                  <CommonInput
                    name="topic"
                    placeholder={English.G83}
                    onChange={handleChange}
                    className="mb-3"
                    maxLength={200}
                    value={capitalizeFirstLetter(inputFields.topic)}
                  />
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G202}</label>
                    <Dropdown
                      title={getCategoryName(inputFields.category_id)}
                      onChanged={(d) => {
                        SubCategory({
                          category_id: d.id
                        })
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }}
                      Data={category.map((d) => ({
                        title: d.category_name,
                        id: d.id
                      }))}
                      name="category_id"
                      customClass={
                        inputFields.category_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                  <div>
                    <label>{English.G203}</label>
                    <Dropdown
                      title={getSubCategoryNameById(
                        inputFields.sub_category_id
                      )}
                      onChanged={(d) => setInputFields({
                          ...inputFields,
                          sub_category_id: d.id
                        })}
                      Data={subCategory.map((d) => ({
                        title: d.sub_category_name,
                        id: d.sub_category_id
                      }))}
                      name="sub_category_id"
                      customClass={
                        inputFields.sub_category_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G204}</label>
                    <div className="calendar">
                      <CommonDatePicker
                        inputFields={inputFields}
                        setInputFields={setInputFields}
                        fieldName="expire_date"
                        placeholder={English.G57}
                        minDate={new Date()}
                        maxDate={
                          new Date(new Date().getFullYear() + 10, 11, 31)
                        }
                        popperPlacement="right"
                      />
                    </div>
                  </div>
                </div>
                <div className={nonEmptySteps?.length === 4 ? 'mb-3' : ''}>
                  {nonEmptySteps?.map((enteredStep, index) => (
                    <div key={index} className="mt-4 d-flex recipies-step">
                      <label className="label-poll">
                        {English.G205}
                        {index + 1}
                        :
                      </label>
                      {editIndex === index ? (
                        <div className="d-flex align-items-center position-relative">
                          <CommonInput
                            onChange={(e) => {
                              setEditValue(e.target.value)
                              const { options } = inputFields
                              options[index].option = e.target.value
                              const updatedFields = {
                                ...inputFields,
                                options
                              }
                              setInputFields(updatedFields)
                            }}
                            maxLength={70}
                            value={editValue}
                          />
                          <div className="image-buttons z-1">
                            <label>
                              <button
                                type="button"
                                onClick={handleSaveEditStep}
                                className="d-none"
                              />
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div className="text-cancel-btn">
                          <span onClick={() => handleEditStep(index)}>
                            {enteredStep}
                          </span>
                          <button
                            type="button"
                            className="remove-tag ml-5"
                            onClick={() => handleRemoveStep(index)}
                          >
                            <img src={Images.Remove} alt="" />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                  {nonEmptySteps?.length < 4 && (
                    <div
                      className={nonEmptySteps?.length === 0 ? 'mt-2' : 'mt-4'}
                    >
                      <label>
                        {English.G205}
                        {nonEmptySteps.length + 1}
                      </label>
                      <CommonInput
                        placeholder={English.G83}
                        className="mt-2"
                        onChange={handleInputChangeStep}
                        maxLength={70}
                        value={step}
                      />
                    </div>
                  )}
                  {nonEmptySteps?.length < 4 && (
                    <button
                      type="button"
                      className="primary-btn-model add_btn"
                      onClick={handleAddStep}
                    >
                      <img src={Images.AddIco} className="me-2" alt="Add" />
                      {English.G206}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div
                className={
                  nonEmptySteps?.length < 1
                    ? 'button-row video_sticky_btn'
                    : 'button-row video_sticky_btn'
                }
              >
                <CommonButton
                  label={English.G78}
                  type="button"
                  onClick={HandleCancel}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G127}
                  type="button"
                  isPrimary
                  context={English.G232}
                  disabled={!isPublishEnabled}
                  onClick={HandlePollsPreview}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPollPreview && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header pb-2">
              <h3>{English.G200}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleCancel}
              >
                <span>
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bar pb-0 mb-2">
              <div className="item-details-input pt-0">
                <div className="polls-publish-detail mt-2">
                  <span className="polls-label-publish">
                    <span>Web Design</span>
                  </span>
                  <p className="polls-text-publish">{inputFields.topic}</p>
                  {steps
                    .filter((poll) => poll !== '')
                    .map((poll) => (
                      <div className="polls-option w-100" key={poll.index}>
                        <CommonInput type="checkbox" className="m-0" />
                        <ProgressBar
                          customLabel={poll}
                          className="profress-line"
                          baseBgColor="#fff"
                          height={43}
                          completed={poll.percantage}
                          borderRadius={15}
                          bgColor="linear-gradient(120deg, rgba(0, 203, 164, 0.1) 0%, rgba(0, 145, 126, 0.1) 90.19%)"
                          isLabelVisible={false}
                          labelClassName="polls-label"
                        />
                      </div>
                    ))}
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
              </div>
            </div>
            <div className="item-details-input pt-1">
              <div className="button-row video_sticky_btn ">
                <CommonButton
                  label={English.G116}
                  type="button"
                  onClick={HandleCancel}
                  isPrimary={false}
                  disabled={loading}
                  context={English.G232}
                />
                <CommonButton
                  label={isAppsSelected ? English.G128 : English.G89}
                  loading={loading}
                  type="button"
                  onClick={publishPoll}
                  className={`primary-btn-model ${loading ? 'loading' : ''}`}
                  isPrimary
                  context={English.G232}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="link_view">
        <Link onClick={handleViewAll}>
          {showAll ? English.G193 : English.G194}
        </Link>
      </div>
    </div>
  )
}

export default Polls
