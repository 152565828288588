/* eslint-disable react/no-unescaped-entities */

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { English, Images } from '../../../helpers'
import { s3ImageProfile } from '../../../services/Service'
import AppDashboard from './Apps'
import Recommendation from './Recommendation'

const MemberType = () => {
  const UserData = useSelector((state) => state.user?.userData)
  const [infoModal, setInfoModal] = useState(false)
  const [memberInfoModal, setMemberInfoModal] = useState(false)

   const InfoClick = () => {
     setInfoModal(true)
   }
   const ClickOk = () => {
     setInfoModal(false)
   }

   const MemberInfoClick = () => {
    setMemberInfoModal(true)
   }
   const MemberClickOk = () => {
    setMemberInfoModal(false)
   }

  return (

    <div className="flex flex-1 h-full flex-col !gap-5">
      <div className="flex flex-1 flex-row !gap-5">
        <div className="flex flex-1 h-[247px] bg-electricWhite !p-5 rounded-2xl relative overflow-hidden">
          <div className="flex flex-col flex-1">
            <div className="bg-primaryGradient font-black pr-1 !pb-5 text-3xl italic bg-clip-text text-transparent uppercase">{English.A4}</div>
            <div className="flex flex-row items-center gap-[10px] !pb-3" onClick={InfoClick}>
              <img className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl" src={Images.walletPoint} alt="sad" />
              <div className="flex flex-col">
                <div className="bg-primaryGradient font-black text-2xl bg-clip-text text-transparent">{English.B21}</div>
                <div className="text-[#333333] font-semibold text-[17px]">{English.B40}</div>
              </div>
            </div>
            <div className="bg-primaryGradient font-black text-[72px] leading-none bg-clip-text text-transparent italic">2.500</div>
          </div>
          <img className="right-0 bottom-0 absolute" src={Images.walletBg} alt="sad" />
        </div>
        <div className="flex flex-1 h-[247px] flex-col bg-electricWhite !p-5 rounded-2xl overflow-hidden">
          <div className="bg-primaryGradient font-black pr-1 !pb-5 text-3xl italic bg-clip-text text-transparent uppercase">{English.B41}</div>
          <div className="d-flex items-center">
            <div className="flex flex-1 flex-col">
              <div className="d-flex items-center gap-[10px] !pb-3" onClick={MemberInfoClick}>
                <img className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl border-2 border-border" src={s3ImageProfile + UserData.profile_image} alt="sad" />
                <div className="flex flex-1 flex-col">
                  <div className="bg-primaryGradient font-black text-2xl bg-clip-text text-transparent">{UserData?.full_name}</div>
                  <div className="text-black font-medium text-lg">{`@${UserData?.user_name}`}</div>
                </div>
              </div>
              <div className="bg-primaryGradient font-normal text-xs bg-clip-text text-transparent">{English.B42}</div>
            </div>
            <img className="flex flex-1 h-[157px] object-contain" src={Images.premiumMemberCard} alt="sad" />
          </div>
        </div>
      </div>
      <div className="flex flex-1 h-full flex-row !gap-5">
        <div className="bg-inactive w-[70%] h-full flex-row bg-white rounded-4 !p-5 shadow-sm">
          <div>
            <AppDashboard />
          </div>
        </div>
        <div className="bg-inactive w-[30%] h-full flex-row bg-white rounded-4 !p-5 shadow-sm">
          <div>
            <Recommendation />
          </div>
        </div>
      </div>
      {infoModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-2xl shadow-lg p-8 w-[605px]">
            <div className="flex justify-between items-center">
              <h2 className="text-3xl italic font-black text-gradient ">
                {English.A4}
              </h2>
              <button
                onClick={ClickOk}
                type="button"
                className="text-gray-500 hover:text-gray-800"
              >
                <img
                  src={Images.Close_Icon_New}
                  alt="Close Icon"
                  className="w-5 h-5"
                />
              </button>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-row items-center gap-[10px] !pb-3" onClick={InfoClick}>
                <img className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl" src={Images.walletPoint} alt="sad" />
                <div className="flex flex-col">
                  <div className="bg-primaryGradient font-black text-2xl bg-clip-text text-transparent">{English.B21}</div>
                  <div className="text-[#333333] font-semibold text-[17px]">{English.B40}</div>
                </div>
              </div>
              <div className="bg-primaryGradient font-extrabold text-[72px] leading-none bg-clip-text text-transparent italic">2.500</div>
            </div>
            <p className="text-gradient mb-4 mt-2">
              Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book. It has survived
              not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
               )}

      {memberInfoModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-2xl shadow-lg p-8 w-[605px]">
            <div className="flex justify-between items-center">
              <h2 className="text-3xl italic font-black text-gradient ">
                {English.B41}
              </h2>
              <button
                onClick={MemberClickOk}
                type="button"
                className="text-gray-500 hover:text-gray-800"
              >
                <img
                  src={Images.Close_Icon_New}
                  alt="Close Icon"
                  className="w-5 h-5"
                />
              </button>
            </div>
            <div className="flex flex-1 h-[247px] flex-col bg-electricWhite !p-5 rounded-2xl overflow-hidden">
              <div className="d-flex items-center">
                <div className="flex flex-1 flex-col">
                  <div className="d-flex items-center gap-[10px] !pb-3" onClick={InfoClick}>
                    <img className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl border-2 border-border" src={s3ImageProfile + UserData.profile_image} alt="sad" />
                    <div className="flex flex-1 flex-col">
                      <div className="bg-primaryGradient font-black text-2xl bg-clip-text text-transparent">{UserData.full_name}</div>
                      <div className="text-black font-medium text-lg">
                        {`@${UserData.user_name.length > 20 ? `${UserData.user_name.slice(0, 20)}...` : UserData.user_name}`}
                      </div>
                    </div>
                  </div>
                  <div className="bg-primaryGradient font-normal text-xs bg-clip-text text-transparent">{English.B42}</div>
                </div>
                <img className="flex flex-1 h-[157px]" src={Images.premiumMemberCard} alt="sad" />
              </div>
            </div>
          </div>
        </div>
               )}
    </div>

  )
}

export default MemberType
