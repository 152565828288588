const phoneValidationPatterns = {

    AW: /^\d{7}$/, // Aruba
    AF: /^[0-9]{9}$/, // Afghanistan
    AO: /^[0-9]{9}$/, // Angola
    AI: /^[0-9]{7}$/, // Anguilla
    AX: /^[0-9]{6,9}$/, // Åland Islands
    AL: /^[0-9]{8}$/, // Albania
    AD: /^[0-9]{6}$/, // Andorra
    AE: /^[0-9]{9}$/, // United Arab Emirates
    AR: /^[0-9]{10}$/, // Argentina
    AM: /^[0-9]{8}$/, // Armenia
    AS: /^[0-9]{7}$/, // American Samoa
    AG: /^[0-9]{7}$/, // Antigua and Barbuda
    AU: /^[0-9]{9}$/, // Australia
    AT: /^[0-9]{10}$/, // Austria
    AZ: /^[0-9]{9}$/, // Azerbaijan

    BI: /^[0-9]{8}$/, // Burundi
    BE: /^[0-9]{9}$/, // Belgium
    BJ: /^[0-9]{8}$/, // Benin
    BF: /^[0-9]{8}$/, // Burkina Faso
    BD: /^[0-9]{10}$/, // Bangladesh
    BG: /^[0-9]{9}$/, // Bulgaria
    BH: /^[0-9]{8}$/, // Bahrain
    BS: /^[0-9]{7}$/, // Bahamas
    BA: /^[0-9]{8}$/, // Bosnia and Herzegovina
    BL: /^[0-9]{9}$/, // Saint Barthélemy
    BY: /^[0-9]{9}$/, // Belarus
    BZ: /^[0-9]{7}$/, // Belize
    BM: /^[0-9]{7}$/, // Bermuda
    BO: /^[0-9]{8}$/, // Bolivia
    BR: /^[0-9]{10,11}$/, // Brazil
    BB: /^[0-9]{7}$/, // Barbados
    BN: /^[0-9]{7}$/, // Brunei
    BT: /^[0-9]{8}$/, // Bhutan
    BW: /^[0-9]{8}$/, // Botswana

    CF: /^[0-9]{8}$/, // Central African Republic
    CA: /^[0-9]{10}$/, // Canada
    CC: /^[0-9]{9}$/, // Cocos Islands
    CH: /^[0-9]{9}$/, // Switzerland
    CL: /^[0-9]{9}$/, // Chile
    CN: /^[0-9]{11}$/, // China
    CI: /^[0-9]{8}$/, // Ivory Coast
    CM: /^[0-9]{9}$/, // Cameroon
    CD: /^[0-9]{9}$/, // DR Congo
    CG: /^[0-9]{9}$/, // Republic of the Congo
    CK: /^[0-9]{5}$/, // Cook Islands
    CO: /^[0-9]{10}$/, // Colombia
    KM: /^[0-9]{7}$/, // Comoros
    CV: /^[0-9]{7}$/, // Cape Verde
    CR: /^[0-9]{8}$/, // Costa Rica
    CU: /^[0-9]{8}$/, // Cuba
    CW: /^[0-9]{7}$/, // Curaçao
    CX: /^[0-9]{9}$/, // Christmas Island
    KY: /^[0-9]{7}$/, // Cayman Islands
    CY: /^[0-9]{8}$/, // Cyprus
    CZ: /^[0-9]{9}$/, // Czechia

    DE: /^[0-9]{11,12}$/, // Germany
    DJ: /^[0-9]{8}$/, // Djibouti
    DM: /^[0-9]{7}$/, // Dominica
    DK: /^[0-9]{8}$/, // Denmark
    DO: /^[0-9]{10}$/, // Dominican Republic
    DZ: /^[0-9]{9}$/, // Algeria

    EC: /^[0-9]{9}$/, // Ecuador
    EG: /^[0-9]{10}$/, // Egypt
    ER: /^[0-9]{7}$/, // Eritrea
    EH: /^[0-9]{9}$/, // Western Sahara
    ES: /^[0-9]{9}$/, // Spain
    EE: /^[0-9]{8}$/, // Estonia
    ET: /^[0-9]{9}$/, // Ethiopia

    FI: /^[0-9]{9}$/, // Finland
    FJ: /^[0-9]{7}$/, // Fiji
    FK: /^[0-9]{5}$/, // Falkland Islands
    FR: /^[0-9]{9}$/, // France
    FO: /^[0-9]{6}$/, // Faroe Islands
    FM: /^[0-9]{7}$/, // Micronesia

    GA: /^[0-9]{7}$/, // Gabon
    GB: /^[0-9]{10}$/, // United Kingdom
    GE: /^[0-9]{9}$/, // Georgia
    GG: /^[0-9]{10}$/, // Guernsey
    GH: /^[0-9]{9}$/, // Ghana
    GI: /^[0-9]{8}$/, // Gibraltar
    GN: /^[0-9]{9}$/, // Guinea
    GP: /^[0-9]{9}$/, // Guadeloupe
    GM: /^[0-9]{7}$/, // Gambia
    GW: /^[0-9]{7}$/, // Guinea-Bissau
    GQ: /^[0-9]{9}$/, // Equatorial Guinea
    GR: /^[0-9]{10}$/, // Greece
    GD: /^[0-9]{7}$/, // Grenada
    GL: /^[0-9]{6}$/, // Greenland
    GT: /^[0-9]{8}$/, // Guatemala
    GF: /^[0-9]{9}$/, // French Guiana
    GU: /^[0-9]{7}$/, // Guam
    GY: /^[0-9]{7}$/, // Guyana

    HK: /^[0-9]{8}$/, // Hong Kong
    HN: /^[0-9]{8}$/, // Honduras
    HR: /^[0-9]{9}$/, // Croatia
    HT: /^[0-9]{8}$/, // Haiti
    HU: /^[0-9]{9}$/, // Hungary

    ID: /^[0-9]{10,12}$/, // Indonesia
    IM: /^[0-9]{10}$/, // Isle of Man
    IN: /^[0-9]{10}$/, // India
    IO: /^[0-9]{7}$/, // British Indian Ocean Territory
    IE: /^[0-9]{9}$/, // Ireland
    IR: /^[0-9]{10}$/, // Iran
    IQ: /^[0-9]{10}$/, // Iraq
    IS: /^[0-9]{7}$/, // Iceland
    IL: /^[0-9]{9}$/, // Israel
    IT: /^[0-9]{10}$/, // Italy

    JM: /^[0-9]{7}$/, // Jamaica
    JE: /^[0-9]{10}$/, // Jersey
    JO: /^[0-9]{9}$/, // Jordan
    JP: /^[0-9]{10}$/, // Japan

    KZ: /^[0-9]{10}$/, // Kazakhstan
    KE: /^[0-9]{9}$/, // Kenya
    KG: /^[0-9]{9}$/, // Kyrgyzstan
    KH: /^[0-9]{9}$/, // Cambodia
    KI: /^[0-9]{5}$/, // Kiribati
    KN: /^[0-9]{7}$/, // Saint Kitts and Nevis
    KR: /^[0-9]{9,10}$/, // South Korea
    XK: /^[0-9]{8}$/, // Kosovo
    KW: /^[0-9]{8}$/, // Kuwait

    LA: /^[0-9]{8}$/, // Laos
    LB: /^[0-9]{8}$/, // Lebanon
    LR: /^[0-9]{8}$/, // Liberia
    LY: /^[0-9]{9}$/, // Libya
    LC: /^[0-9]{7}$/, // Saint Lucia
    LI: /^[0-9]{7}$/, // Liechtenstein
    LK: /^[0-9]{9}$/, // Sri Lanka
    LS: /^[0-9]{8}$/, // Lesotho
    LT: /^[0-9]{8}$/, // Lithuania
    LU: /^[0-9]{9}$/, // Luxembourg
    LV: /^[0-9]{8}$/, // Latvia

    MO: /^[0-9]{8}$/, // Macau
    MF: /^[0-9]{9}$/, // Saint Martin
    MA: /^[0-9]{9}$/, // Morocco
    MC: /^[0-9]{8}$/, // Monaco
    MD: /^[0-9]{8}$/, // Moldova
    MG: /^[0-9]{9}$/, // Madagascar
    MV: /^[0-9]{7}$/, // Maldives
    MX: /^[0-9]{10}$/, // Mexico
    MH: /^[0-9]{7}$/, // Marshall Islands
    MK: /^[0-9]{8}$/, // North Macedonia
    ML: /^[0-9]{8}$/, // Mali
    MT: /^[0-9]{8}$/, // Malta
    MM: /^[0-9]{8,9}$/, // Myanmar
    ME: /^[0-9]{8}$/, // Montenegro
    MN: /^[0-9]{8}$/, // Mongolia
    MP: /^[0-9]{7}$/, // Northern Mariana Islands
    MZ: /^[0-9]{9}$/, // Mozambique
    MR: /^[0-9]{8}$/, // Mauritania
    MS: /^[0-9]{7}$/, // Montserrat
    MQ: /^[0-9]{9}$/, // Martinique
    MU: /^[0-9]{8}$/, // Mauritius
    MW: /^[0-9]{9}$/, // Malawi
    MY: /^[0-9]{9,10}$/, // Malaysia

    NA: /^[0-9]{9}$/, // Namibia
    NC: /^[0-9]{6}$/, // New Caledonia
    NE: /^[0-9]{8}$/, // Niger
    NF: /^[0-9]{6}$/, // Norfolk Island
    NG: /^[0-9]{10}$/, // Nigeria
    NI: /^[0-9]{8}$/, // Nicaragua
    NU: /^[0-9]{4}$/, // Niue
    NL: /^[0-9]{9}$/, // Netherlands
    NO: /^[0-9]{8}$/, // Norway
    NP: /^[0-9]{10}$/, // Nepal
    NR: /^[0-9]{7}$/, // Nauru
    NZ: /^[0-9]{9,10}$/, // New Zealand

    OM: /^[0-9]{8}$/, // Oman

    PK: /^[0-9]{10}$/, // Pakistan
    PA: /^[0-9]{8}$/, // Panama
    PN: /^[0-9]{9}$/, // Pitcairn Islands
    PE: /^[0-9]{9}$/, // Peru
    PH: /^[0-9]{10}$/, // Philippines
    PW: /^[0-9]{7}$/, // Palau
    PG: /^[0-9]{8}$/, // Papua New Guinea
    PL: /^[0-9]{9}$/, // Poland
    PR: /^[0-9]{10}$/, // Puerto Rico
    KP: /^[0-9]{10}$/, // North Korea
    PT: /^[0-9]{9}$/, // Portugal
    PY: /^[0-9]{9}$/, // Paraguay
    PS: /^[0-9]{9}$/, // Palestine
    PF: /^[0-9]{6}$/, // French Polynesia

    QA: /^[0-9]{8}$/, // Qatar

    RE: /^[0-9]{9}$/, // Réunion
    RO: /^[0-9]{9}$/, // Romania
    RU: /^[0-9]{10}$/, // Russia
    RW: /^[0-9]{9}$/, // Rwanda

    SA: /^[0-9]{9}$/, // Saudi Arabia
    SD: /^[0-9]{9}$/, // Sudan
    SN: /^[0-9]{9}$/, // Senegal
    SG: /^[0-9]{8}$/, // Singapore
    SH: /^[0-9]{4}$/, // Saint Helena
    SJ: /^[0-9]{8}$/, // Svalbard and Jan Mayen
    SB: /^[0-9]{7}$/, // Solomon Islands
    SL: /^[0-9]{8}$/, // Sierra Leone
    SV: /^[0-9]{8}$/, // El Salvador
    SM: /^[0-9]{6}$/, // San Marino
    SO: /^[0-9]{8}$/, // Somalia

    PM: /^[0-9]{6}$/, // Saint Pierre and Miquelon
    RS: /^[0-9]{9,10}$/, // Serbia
    SS: /^[0-9]{9}$/, // South Sudan
    ST: /^[0-9]{7}$/, // São Tomé and Príncipe
    SR: /^[0-9]{7}$/, // Suriname
    SK: /^[0-9]{9}$/, // Slovakia
    SI: /^[0-9]{9}$/, // Slovenia
    SE: /^[0-9]{7,10}$/, // Sweden
    SZ: /^[0-9]{9}$/, // Eswatini
    SX: /^[0-9]{7}$/, // Sint Maarten
    SC: /^[0-9]{7}$/, // Seychelles
    SY: /^[0-9]{9}$/, // Syria
    TC: /^[0-9]{7}$/, // Turks and Caicos Islands
    TD: /^[0-9]{8}$/, // Chad
    TG: /^[0-9]{8}$/, // Togo
    TH: /^[0-9]{9}$/, // Thailand
    TJ: /^[0-9]{9}$/, // Tajikistan
    TK: /^[0-9]{4}$/, // Tokelau
    TM: /^[0-9]{8}$/, // Turkmenistan
    TL: /^[0-9]{7}$/, // Timor-Leste
    TO: /^[0-9]{5}$/, // Tonga
    TT: /^[0-9]{10}$/, // Trinidad and Tobago
    TN: /^[0-9]{8}$/, // Tunisia
    TR: /^[0-9]{10}$/, // Turkey
    TV: /^[0-9]{5}$/, // Tuvalu
    TW: /^[0-9]{9,10}$/, // Taiwan
    TZ: /^[0-9]{9}$/, // Tanzania
    UG: /^[0-9]{9}$/, // Uganda
    UA: /^[0-9]{9}$/, // Ukraine
    UM: /^[0-9]{9}$/, // United States Minor Outlying Islands
    UY: /^[0-9]{7,8}$/, // Uruguay
    US: /^[0-9]{10}$/, // United States
    UZ: /^[0-9]{9}$/, // Uzbekistan
    VA: /^[0-9]{6}$/, // Vatican City
    VC: /^[0-9]{10}$/, // Saint Vincent and the Grenadines
    VE: /^[0-9]{7}$/, // Venezuela
    VG: /^[0-9]{10}$/, // British Virgin Islands
    VI: /^[0-9]{10}$/, // United States Virgin Islands
    VN: /^[0-9]{9,10}$/, // Vietnam
    VU: /^[0-9]{7}$/, // Vanuatu
    WF: /^[0-9]{6}$/, // Wallis and Futuna
    WS: /^[0-9]{5}$/, // Samoa
    YE: /^[0-9]{9}$/, // Yemen
    ZA: /^[0-9]{9}$/, // South Africa
    ZM: /^[0-9]{9}$/, // Zambia
    ZW: /^[0-9]{9}$/ // Zimbabwe
}

export default phoneValidationPatterns
