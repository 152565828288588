/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import '../HotelComponent/RoomAdd.css'
import './Menu.css'
import { Table, Button, Image } from 'antd'
import Input from 'antd/es/input/Input'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Dropdown from '../../Dropdown/Dropdown'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import { s3ImageRestaurant } from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination/Pagination'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const Menu = ({ isRestaurantId }) => {
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    name: '',
    cuisine_id: '',
    meal_type_id: '',
    price: '',
    description: '',
    images: [],
    restaurant_id: isRestaurantId,
    menu_id: ''
  })

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      cuisine_id: '',
      meal_type_id: '',
      description: '',
      price: '',
      images: []
    })
    setIsEdit(false)
  }

  const [isMealType, setIsMealType] = useState([])
  const [isCuisinesType, setIsCuisinesType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [delMenuId, setDelMenuId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [menuListData, setMenuListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [particularMenu, setParticularMenu] = useState([])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleCancels = () => {
    setIsAddRoom(false)
    // setIsRoomFeature(false)
    clearValue()
  }

  const handleCancel = () => {
    setIsRoomFeature(false)
  }

  const ShowConfirm = (record) => {
    setDelMenuId(record.menu_id)
    setShowConfirmModel(true)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const showMenu = (record) => {
    setIsRoomFeature(true)
    particularMenuData(record.menu_id)
  }

  const menuEdits = (record) => {
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      setInputFields({
        menu_id: record.menu_id,
        restaurant_id: record.restaurant_id,
        name: record.name,
        price: record.price,
        cuisine_id: record.cuisine_id,
        meal_type_id: record.meal_type_id,
        description: record.description,
        images: record.images
      })
    }
    setInitialValues({
      menu_id: record.menu_id,
      restaurant_id: record.restaurant_id,
      name: record.name,
      price: record.price,
      cuisine_id: record.cuisine_id,
      meal_type_id: record.meal_type_id,
      description: record.description,
      images: record.images
    })
    setIsAddRoom(true)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const columns = useMemo(
    () => [
      {
        title: 'ITEM NAME & IMAGE',
        dataIndex: 'Name',
        key: 'Name',
        render: (text, record) => (
          <div className="image_name">
            <Image
              width={100}
              src={s3ImageRestaurant + record.images[0]}
              alt="img"
              style={{ marginRight: 10 }}
            />
            <span>
              {record.name.length > 15
                ? `${record.name.substring(0, 15)}...`
                : record.name}
            </span>
          </div>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        className: 'custom-column'
      },
      {
        title: 'MEAL',
        dataIndex: 'meal_type',
        key: 'meal_type',
        sorter: (a, b) => a.meal_type.localeCompare(b.meal_type)
      },
      {
        title: 'CUISINE',
        dataIndex: 'cuisine',
        key: 'cuisine',
        sorter: (a, b) => a.cuisine.localeCompare(b.cuisine)
      },
      {
        title: 'PRICE',
        dataIndex: 'price',
        key: 'price',
        sorter: (a, b) => parseFloat(a.price.slice(1)) - parseFloat(b.price.slice(1))
      },
      {
        title: 'ACTION',
        key: 'action',
        render: (text, record) => (
          <div className="image-buttons-discounts">
            <Button
              icon={<img src={Images.Edit} alt="Edit" />}
              onClick={(e) => {
                e.stopPropagation()
                menuEdits(record)
              }}
            />
            <Button
              icon={<img src={Images.DeleteDiscount} alt="Delete" />}
              onClick={(e) => {
                e.stopPropagation()
                ShowConfirm(record)
              }}
            />
          </div>
        )
      }
    ],
    []
  )

  const renderTable = useMemo(() => {
    const filteredData = menuListData.filter((item) => item.name.toLowerCase().includes(searchText))

    return (
      <div className="discount_list">
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => {
                showMenu(record)
              }
            }
          }}
        />
      </div>
    )
  }, [menuListData, searchText])

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'restaurant_menu')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedImageData = dataDecrypt(res.data.data)

            setInputFields((prev) => {
              const prevData = prev[fieldName]
              if (
                (index !== undefined || index !== null) &&
                Array.isArray(prevData)
              ) {
                prevData[index] = decryptedImageData
              }
              return {
                ...prev,
                [fieldName]: prevData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const openAddRoom = () => {
    setIsAddRoom(true)
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageRestaurant + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const typeofMealListData = () => {
    APICall('post', {}, `${EndPoints.typeofMeal}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsMealType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cuisinesListData = () => {
    APICall('post', {}, `${EndPoints.menuCuisines}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCuisinesType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const RestaurantMenuGetData = (currentPage) => {
    const payload = {
      restaurant_id: isRestaurantId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getMenu}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setMenuListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const menuAddApiCall = () => {
    const payload = {
      menu_id: inputFields.menu_id,
      name: inputFields.name,
      cuisine_id: inputFields.cuisine_id,
      meal_type_id: inputFields.meal_type_id,
      images: inputFields.images,
      price: inputFields.price,
      description: inputFields.description,
      restaurant_id: isRestaurantId
    }
    if (isEdit) {
      APICall('post', payload, `${EndPoints.editMenu}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddRoom(false)
            setIsEdit(false)
            setTimeout(() => {
              RestaurantMenuGetData(currentPage)
              toast.success(res?.data?.message)
            }, 3000)
          } else {
            setIsEdit(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.addMenu}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddRoom(false)
            clearValue()
            setTimeout(() => {
              RestaurantMenuGetData(currentPage)
              toast.success(res?.data?.message)
            }, 3000)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const deleteMenu = () => {
    const data = {
      menu_id: delMenuId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteMenu}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsAddRoom(false)
          clearValue()
          setTimeout(() => {
            RestaurantMenuGetData(currentPage)
            toast.success(res?.data?.message)
          }, 3000)
          setLoading(false)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const particularMenuData = (menu_id) => {
    const payload = {
      restaurant_id: isRestaurantId,
      menu_id
    }
    APICall('post', payload, `${EndPoints.particularMenu}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularMenu(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    RestaurantMenuGetData(pageNumber)
    setCurrentPage(pageNumber)
  }

  const validateForm = () => {
    const { name, cuisine_id, meal_type_id, price, images, description } =
      inputFields
    if (name && cuisine_id && price && images && meal_type_id && description) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const getMealName = (meal_type_id) => {
    const categoryObj = isMealType.find(
      (cat) => cat.meal_type_id === meal_type_id
    )
    return categoryObj ? categoryObj.type : 'Select meal'
  }
  const getCuisineName = (cuisine_id) => {
    const categoryObj = isCuisinesType.find(
      (cat) => cat.cuisine_id === cuisine_id
    )
    return categoryObj ? categoryObj.name : 'Select cuisine'
  }

  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      typeofMealListData()
      cuisinesListData()
      RestaurantMenuGetData(currentPage)
    }
  }, [])

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  useEffect(() => {
    if (isAddRoom || isRoomFeature || ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddRoom, isRoomFeature, ShowConfirmModel])

  return (
    <div className="content-manager-Statistics">
      <div className="statistic-chart-container position-relative">
        {menuListData.length === 0 ? (
          <div className="add_room_text">
            <img className="d-block" src={Images.MenuBook} alt="bedroom" />
            <h3>{English.G360}</h3>
            <p>{English.G361}</p>
            <div className="add-video-rows" onClick={openAddRoom}>
              <div className="add_new_content">
                <img src={Images.PlusIco} alt="" />
                {English.G362}
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <h3 className="d-flex align-items-center justify-content-between">
              {English.G363}
              <button
                type="button"
                className="primary-btn"
                onClick={openAddRoom}
              >
                <img src={Images.PlusIco} alt="add" />
                {English.G362}
              </button>
            </h3>
            <Input
              placeholder="Search by Item Name"
              onChange={handleSearch}
              style={{ width: 200, marginBottom: '1rem' }}
            />
            {renderTable}
            <ConfirmationModal
              isVisible={ShowConfirmModel}
              onConfirm={deleteMenu}
              onCancel={handleDeleteCancel}
              isLoading={loading}
            />
            {loading && <Loader />}
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </div>

      {isAddRoom && (
        <div className="add-item-model">
          <div className="add-item-content add_room_modal local_type_modal pr-0">
            <div className="type_close mb-2 local_sticky_header">
              <div className="d-flex align-items-center col-gap-10">
                {isEdit ? 'Edit Item' : 'Add Item'}
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0 local_contact_scroll_bars">
              <div className="input-grid">
                <div>
                  <label>{English.G364}</label>
                  <CommonInput
                    placeholder={English.G365}
                    name="name"
                    onChange={handleChange}
                    value={capitalizeFirstLetter(inputFields.name)}
                  />
                </div>
                <div>
                  <label>{English.G366}</label>
                  <Dropdown
                    title={getMealName(inputFields.meal_type_id)}
                    name="meal_type_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        meal_type_id: d.id
                      })
                    }}
                    Data={isMealType.map((d) => ({
                      title: d.type,
                      id: d.meal_type_id
                    }))}
                    // customClass="local_menu_dropdown"
                    customClass={
                      inputFields.meal_type_id
                        ? 'selected-class'
                        : 'local-dropdown'
                    }
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G367}</label>
                  <Dropdown
                    // customClass="local_menu_dropdown"
                    title={getCuisineName(inputFields.cuisine_id)}
                    name="cuisine_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        cuisine_id: d.id
                      })
                    }}
                    Data={isCuisinesType.map((d) => ({
                      title: d.name,
                      id: d.cuisine_id
                    }))}
                    customClass={
                      inputFields.cuisine_id
                        ? 'selected-class'
                        : 'local-dropdown'
                    }
                  />
                </div>
                <div>
                  <label>{English.G131}</label>
                  <CommonInput
                    placeholder={English.G368}
                    name="price"
                    type="number"
                    onChange={handleChange}
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={4}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 4) {
                        e.preventDefault()
                      }
                    }}
                    value={inputFields.price}
                  />
                </div>
              </div>
              <div className="">
                <div className="local_event_description">
                  <label>{English.G112}</label>
                  <CommonInput
                    placeholder={English.G369}
                    name="description"
                    onChange={handleChange}
                    isTextArea
                    maxLength={250}
                    value={capitalizeFirstLetter(inputFields.description)}
                    className="mb-4"
                  />
                </div>
              </div>
              <div className="hotel_image_upload_section">
                <div className="hotel_upload_div mt-0">
                  <div className="input-image-show m-0 local_images_show">
                    {renderImg}
                    {inputFields.images.length < 4 && (
                      <div className="input-img-label">
                        <CommonImageUpload
                          handleImageChange={(file) => onUploadImage(
                              file,
                              'images',
                              inputFields.images.length
                            )}
                          buttonText="Add Image"
                          selectedImg=""
                          defaultImg={Images.plusGiveaway}
                          className="room_add_img"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label={English.G116}
                  onClick={handleCancels}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G117}
                  type="button"
                  isPrimary
                  onClick={menuAddApiCall}
                  context={English.G232}
                  disabled={!isFormComplete}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isRoomFeature && (
        <div className="add-item-model">
          {particularMenu.map((data, index) => (
            <div className="add-item-content add_room_modal px-3" key={index}>
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  {data.name}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancel}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                {/* <div className="room_image menu_image_show">
                  {data.images.map((d, i) => {
                    return (
                      <img
                        className="menu_image"
                        src={s3ImagePathLocal + d}
                        alt="roomImage"
                        key={i}
                      />
                    )
                  })}
                </div> */}
                <div
                  className={`room_image menu_image_show image-count-${data.images.length}`}
                >
                  {data.images.map((d, i) => {
                    return (
                      <img
                        className={`menu_image ${data.images.length === 1 ? 'large' : data.images.length === 2 ? 'medium' : ''}`}
                        src={s3ImageRestaurant + d}
                        alt="roomImage"
                        key={i}
                      />
                    )
                  })}
                </div>
                <div className="edit_description ">
                  <p>{data.description}</p>
                </div>
                <div className="mb-3">
                  <div className="deal-label">
                    <label>{English.G370}</label>
                    <p>Flat 20% OFF</p>
                  </div>
                </div>
                <div className="input-grid detail-grid">
                  <div>
                    <label>{English.G366}</label>
                    <p>{data.meal_type}</p>
                  </div>
                  <div>
                    <label>{English.G367}</label>
                    <p>{data.cuisine}</p>
                  </div>
                </div>
                <div className="input-grid detail-grid">
                  <div>
                    <label>{English.G131}</label>
                    <p>
                      {English.G283}
                      &nbsp;
                      {data.price}
                    </p>
                  </div>
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label={English.G359}
                    onClick={handleCancel}
                    isPrimary={false}
                    context={English.G232}
                    className="common-button"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default Menu
