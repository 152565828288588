import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import '../../App.css'
import { dataDecrypt, setUserSliceData } from '../../services/http-services'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Utility from '../../helpers/Utility'
import { Logout } from '../../redux/UserSlice/UserSlice'
import { APICall, EndPoints } from '../../services'
import SidebarPageCreator from './SidebarPageCreator'
import { Images } from '../../helpers'

const OtpVerification = () => {
  const UserData = useSelector((state) => state.user?.userData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputFields, setInputFields] = useState({
    email: UserData?.email,
    otp: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [countdown, setCountdown] = useState(59)
  const [canResend, setCanResend] = useState(false)
  const [firstTime, setFirstTime] = useState(true)
  const [loading, setLoading] = useState(false)

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.otp.length !== 6) {
      errors.otp = English.G33
    }
    return errors
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    const payload = {
      otp: inputFields.otp,
      email: inputFields.email,
      app_id: 2
    }
    APICall('post', payload, EndPoints.otpVerification, 'auth')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          navigate('/user-type-select')
          const decryptedData = dataDecrypt(res?.data?.data)
          const tokenData = Utility.deepClone(res?.data?.token)
          setUserSliceData(tokenData, 'token')
          setUserSliceData(decryptedData, 'userInfo')
          toast.success(res?.data?.message)
          setLoading(false)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const ResendOtpApiCall = () => {
    const payload = {
      email: inputFields.email,
      app_id: 2
    }
    APICall('post', payload, EndPoints.resendOtp, 'auth')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        toast.success(English.G34)
        setCountdown(59)
        setCanResend(false)
      } else {
        toast.error(res.data?.message)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
    })
  }

  const redirectLogin = () => {
    navigate('/login')
    dispatch(Logout())
  }

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
    } else {
      if (canResend) {
        setCountdown(59)
      }
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer)
            setCanResend(true)
          }
          return Math.max(prevCountdown - 1, 0)
        })
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [firstTime, canResend])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  useEffect(() => {
    setIsButtonEnabled(inputFields.otp.length > 5)
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 py-[20px]">
          <SidebarPageCreator />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto no-scrollbar">
            <h2 className="bg-[#151515] text-center login_sign_text py-4">
              {English.G44}
            </h2>
            <form onSubmit={handleSubmit}>
              <p>
                <label className="business_label_text">{English.G39}</label>
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="password"
                    className="input-logo"
                  />
                  <CommonInput
                    name="otp"
                    placeholder={English.G37}
                    onChange={handleChange}
                    required
                    maxLength={6}
                    pattern="\d{6}"
                    inputMode="numeric"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 6)
                    }}
                  />
                </div>
                {errors.otp ? <p className="error-text">{errors.otp}</p> : null}
              </p>
              <div className="reset_password_btn">
                <p>
                  <CommonButton
                    label={
                      canResend
                        ? `${English.G40}`
                        : `${English.G40} in ${countdown}s`
                    }
                    type="button"
                    id="resend_btn"
                    onClick={ResendOtpApiCall}
                    className="primary-button m-auto !w-[261px]"
                    disabled={!canResend}
                    context="primary"
                    isPrimary
                  />
                </p>
                <p>
                  <CommonButton
                    label={English.G32}
                    type="submit"
                    id="submit_btn"
                    loading={loading}
                    className="primary-button m-auto !w-[261px]"
                    disabled={!isButtonEnabled || loading}
                    context="primary"
                    isPrimary
                  />
                </p>
              </div>
              <div className="otp_redirect_login">
                <span onClick={() => redirectLogin()}>{English.G45}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OtpVerification
