/* eslint-disable max-len */
import React, { useState } from 'react'
import { English, Images } from '../../../helpers'
import AppsItemMember from './AppsItemMember'

const AppList = () => {
  const initialApps = [
    {
      id: 1,
      image: Images.AtoZ_Icon,
      name: 'A-Z FIT',
      type: 'LITE APP',
      active: false
    },
    {
      id: 2,
      image: Images.scaloo_icon,
      name: 'SCALOO',
      type: 'LITE APP',
      active: false
    },
    {
      id: 3,
      image: Images.Spacure_icon,
      name: 'SPACURE',
      type: 'PRO APP',
      active: false
    },
    {
      id: 4,
      image: Images.AtoZ_Icon,
      name: 'GO ATHLETE',
      type: 'PRO APP',
      active: false
    },
    {
      id: 5,
      image: Images.scaloo_icon,
      name: 'A-Z FIT',
      type: 'LITE APP',
      active: false
    },
    {
      id: 6,
      image: Images.Spacure_icon,
      name: 'SCALOO',
      type: 'LITE APP',
      active: false
    },
    {
      id: 7,
      image: Images.AtoZ_Icon,
      name: 'SPACURE',
      type: 'PRO APP',
      active: false
    },
    {
      id: 8,
      image: Images.scaloo_icon,
      name: 'GO ATHLETE',
      type: 'PRO APP',
      active: false
    },
    {
      id: 9,
      image: Images.Spacure_icon,
      name: 'A-Z FIT',
      type: 'LITE APP',
      active: false
    },
    {
      id: 10,
      image: Images.AtoZ_Icon,
      name: 'SCALOO',
      type: 'LITE APP',
      active: false
    },
    {
      id: 11,
      image: Images.scaloo_icon,
      name: 'SPACURE',
      type: 'PRO APP',
      active: false
    },
    {
      id: 12,
      image: Images.Spacure_icon,
      name: 'GO ATHLETE',
      type: 'PRO APP',
      active: false
    }
  ]

  const [apps, setApps] = useState(initialApps)
  const [filter, setFilter] = useState('all')

  const toggleActive = (id) => {
    const updatedApps = apps.map((app) => (app.id === id ? { ...app, active: !app.active } : app))
    setApps(updatedApps)
  }

  const filteredApps = apps.filter((app) => {
    if (filter === 'active') return app.active
    if (filter === 'inactive') return !app.active
    return true
  })

  return (
    <div className="p-0 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center">
        <div className="bg-primaryGradient font-black pr-1 !pb-5 text-3xl italic bg-clip-text text-transparent uppercase">
          {English.B43}
        </div>

        <div className="flex space-x-4 mb-6 border border-[#b8b8b8] rounded-full py-2 px-3">
          <button
            onClick={() => setFilter('all')}
            className={`px-4 py-[12px] rounded-md ${
              filter === 'all'
                ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                : 'text-gradient text-base italic font-extrabold'
            }`}
            type="button"
          >
            {English.A124}
          </button>
          <button
            type="button"
            onClick={() => setFilter('active')}
            className={`px-4 py-[12px] rounded-md ${
              filter === 'active'
                ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                : 'text-gradient text-base italic font-extrabold'
            }`}
          >
            {English.A125}
          </button>
          <button
            onClick={() => setFilter('inactive')}
            type="button"
            className={`px-4 py-[12px] rounded-md ${
              filter === 'inactive'
                 ? 'gradient_bg text-[#ffff] text-base italic font-extrabold !rounded-[25px]'
                : 'text-gradient text-base italic font-extrabold'
            }`}
          >
            {English.A126}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredApps.map((i) => (
          <AppsItemMember
            item={i}
            key={i.id}
            toggleActive={toggleActive}
          />
        ))}
      </div>
    </div>
  )
}

export default AppList
