import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const StoreDetail = () => {
  const items = [
    {
      img: './images/products/5.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$280.00'
    },
    {
      img: './images/products/6.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$250.00'
    },
    {
      img: './images/products/7.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$180.00'
    },
    {
      img: './images/products/8.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$120.00'
    }
  ]
  const generateStars = (totalRating) => {
    const totalStars = 5 // Total number of stars to display
    const count = Math.min(Math.floor(parseFloat(totalRating)), totalStars)
    const remainder = parseFloat(totalRating) - count // Decimal part to represent partial star
    const stars = []
    for (let i = 0; i < count; i++) {
      stars.push(<img alt="" key={i} src="./images/star.svg" />)
    }
    if (remainder > 0) {
      stars.push(<img alt="" key={count} src="./images/star.svg" />)
    }
    for (let i = stars.length; i < totalStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
    }

    return stars
  }
  const selectedImages = [
    './images/products/13.jpg',
    './images/products/13.jpg',
    './images/products/13.jpg',
    './images/products/13.jpg'
  ]
  const [count, setCount] = useState(1)
  function incrementCount() {
    setCount(count + 1)
  }
  function decrementCount() {
    if (count > 1) {
      setCount(count - 1)
    }
  }
  const [selectedImg, setSelectedImg] = useState(0)
  return (
    <div className="creator-profile-main restaurant-details">
      <div className="common-card-body hotel-resrve">
        <div className="product-preview store-product-preview">
          <div className="d-flex col-gap-20">
            <div className="">
              <div className="product-img-preview">
                <div className="product-img-table">
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedImg(index)
                      }}
                      className={`product-img-item ${
                        selectedImg === index && 'product-img-blur'
                      }`}
                      style={{
                        background: `url(${image})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    />
                  ))}
                </div>
                <div className="product-img-selected" key="123">
                  <img
                    alt=""
                    src={`${selectedImages[selectedImg]}`}
                    className="w-100 h-100"
                  />
                </div>
              </div>
            </div>
            <div className=" product-detail">
              <h2>
                SheaMoisture 100% coconut oil daily hydration body lotion
                moisturizer
              </h2>
              <div className="ticket-overview--profile d-flex align-items-center col-gap-10 mt-0 mb-2">
                <img alt="" src="./images/seller-logo.png" />
                Jacob Jones
                <span className="divider mx-3" />
                <div>{generateStars(4.5)}</div>
                4.5 rating
              </div>
              <div className="vertical-divider" />
              <div className="product-detail--price mb-4 mt-4">
                <h4 className="linier-text m-0">$700</h4>
                <h4 className="actual-price">$800</h4>
              </div>
              <div className="vertical-divider" />
              <div className="product-detail--warranty mt-3 mb-3">
                <span>Return & Warranty</span>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p>7 days Returns</p>
                  <p>Warranty not available</p>
                </div>
                <h6>Change of mind is not applicable</h6>
              </div>
              <div className="vertical-divider" />
              <div className="price-ticket-counter--wrapper mb-3 mt-3">
                <span>Quantity</span>
                <div className="price--ticket-counter">
                  <button type="button" onClick={decrementCount}>-</button>
                  <div>{count}</div>
                  <button type="button" onClick={incrementCount}>+</button>
                </div>
              </div>
              <div className="vertical-divider" />
              <div className="product-detail--charges mt-3">
                Delivery charges: $100
              </div>
              <div className="d-flex col-gap-20">
                <button type="button" className="primary-btn">Buy Now</button>
                <button type="button" className="light-button">
                  <span>Add to Cart</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="product--detail mt-30">
          <h3 className="linier-text">Product Detail</h3>
          <div className="recent-video-component common-card-body">
            <h3>Specifications</h3>
            <div className="product--detail-item">
              <ul>
                <li>
                  <span>Product Name</span>
                  <p>Product Name</p>
                </li>
                <li>
                  <span>{English.G182}</span>
                  <p>Brand Name</p>
                </li>
                <li>
                  <span>Skin Type</span>
                  <p>All</p>
                </li>
                <li>
                  <span>Scent</span>
                  <p>Coconut</p>
                </li>
                <li>
                  <span>No of Handles</span>
                  <p>Package</p>
                </li>
                <li>
                  <span>Style</span>
                  <p>Package</p>
                </li>
              </ul>
              <ul>
                <li>
                  <span>Product Benefits</span>
                  <p>Soothe,Bright,Hydrate</p>
                </li>
                <li>
                  <span>Item Form </span>
                  <p>Lotion</p>
                </li>
                <li>
                  <span>Shape</span>
                  <p>Brand Name</p>
                </li>
                <li>
                  <span>Brand Name:</span>
                  <p>Brand Name</p>
                </li>
                <li>
                  <span>Brand Name:</span>
                  <p>Brand Name</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="discription-box recent-video-component mb-0 mt-30">
          <h3>{English.G112}</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            quis lectus integer dictumst accumsan posuere in imperdiet enim.
            Varius sed in blandit pharetra amet, morbi risus. Velit aliquet
            neque purus est urna. Pellentesque facilisi nunc quis pretium
            viverra tempor urna, ornare aliquet. In tristique tincidunt arcu
            purus. Mattis risus nulla lectus mi suspendisse lobortis tempus
            enim. Phasellus amet, sollicitudin nibh facilisis pellentesque
            bibendum varius massa.
          </p>
          <p>
            Risus in nunc eget eget. Ipsum, tristique mauris aliquam odio. Nibh
            volutpat, leo, morbi elementum. Tempus vel molestie massa tortor id
            eros. Magna ac est ornare lorem. Lectus turpis nisl at massa ac nec
            nascetur. Enim leo nec non tristique. Neque, duis aliquet a feugiat
            et vitae. Lobortis sed vel eros, diam in neque aenean velit, porta.
            Pellentesque a viverra pretium condimentum urna, urna, volutpat
            urna, lectus. Malesuada dolor aenean tempus morbi eget est sit massa
            sed. Ut pharetra libero, lorem mi. Pharetra, nunc, interdum maecenas
            mauris quis nisl adipiscing sagittis facilisi. Adipiscing
            pellentesque urna et ut nec nunc, semper mi.
          </p>
        </div>
        <div className=" services-container menu-wrapper common-card-body recent-video-component mt-40">
          <h3 className="d-flex align-items-center justify-content-between">
            Related Products
            {' '}
            <Link>{English.G248}</Link>
          </h3>
          <div className="store-container mt-30">
            {items.map((item) => (
              <div className="product-grid-item" key={item}>
                <div
                  className="product-item-cover"
                  style={{
                    background: `url(${item.img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="like">
                    <img alt="" src={Images.Likes} />
                  </div>
                  <div className="rating">
                    <img alt="" src={Images.RatingStar} />
                    <span>{item.rating}</span>
                  </div>
                </div>
                <div className="product-item-details d-flex  justify-content-between">
                  <span>{item.name}</span>
                  <div>
                    <h4>{item.price}</h4>
                    <h4 className="actual-price">{item.price}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default StoreDetail
