import React, { useState } from 'react'
import './Workspace.css'
import 'react-tabs/style/react-tabs.css'
import DatePicker from 'react-datepicker'
import ProgressBar from '@ramonak/react-progress-bar'
import { Link } from 'react-router-dom'
import DonutChart from './DonutChart'
import 'react-datepicker/dist/react-datepicker.css'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Workspace = () => {
  const [startDate, setStartDate] = useState(new Date())

  const service = [
    {
      icon: './images/serviceico/operation.svg',
      name: 'Operation Department'
    },
    {
      icon: './images/serviceico/design.svg',
      name: 'Design Department'
    },
    {
      icon: './images/serviceico/development.svg',
      name: 'Development Department'
    },
    {
      icon: './images/serviceico/training.svg',
      name: 'Training Department'
    },
    {
      icon: './images/serviceico/testing.svg',
      name: 'Testing Department'
    },
    {
      icon: './images/serviceico/research.svg',
      name: 'Research Department'
    }
  ]

  const Projects = [
    {
      ProjectCover: './images/project.png',
      ProjectName: 'Project Name',
      Progress: '0',
      staus: 'Completed',
      Assignee: [
        './images/assignee/task-profile1.png',
        './images/assignee/task-profile2.png',
        './images/assignee/task-profile3.png',
        './images/assignee/task-profile4.png',
        './images/assignee/task-profile4.png'
      ]
    },
    {
      ProjectCover: './images/project.png',
      ProjectName: 'Project Name',
      Progress: '0',
      staus: 'Active',
      Assignee: [
        './images/assignee/task-profile1.png',
        './images/assignee/task-profile2.png',
        './images/assignee/task-profile3.png',
        './images/assignee/task-profile4.png'
      ]
    }
  ]

  const CompletedProject = [
    {
      Project: 'App Design',
      ProfileImg: './images/task-profile1.png',
      name: 'Jacob Jones'
    },
    {
      Project: 'Web Development',
      ProfileImg: './images/task-profile2.png',
      name: 'John Doe'
    },
    {
      Project: 'App Design',
      ProfileImg: './images/task-profile3.png',
      name: 'Jacob Jones'
    },
    {
      Project: 'User Testing',
      ProfileImg: './images/task-profile4.png',
      name: 'Bessie Cooper'
    },
    {
      Project: 'App Design',
      ProfileImg: './images/task-profile1.png',
      name: 'Jacob Jones'
    }
  ]

  const TeamMember = [
    {
      Image: './images/task-profile1.png',
      name: 'Jacob Jones',
      Position: 'UX Researcher'
    },
    {
      Image: './images/task-profile2.png',
      name: 'Bessie Cooper',
      Position: 'Design Lead'
    },
    {
      Image: './images/task-profile3.png',
      name: 'Albert Flores',
      Position: 'Developer'
    },
    {
      Image: './images/task-profile4.png',
      name: 'Albert Flores',
      Position: 'Developer'
    }
  ]

  return (
    <div className="dashboard">
      <div className="dashboard-wrapper">
        <div className="dashboard-main">
          <div className="dashboard-left">
            <div className="dashbord-left-wrapper">
              <div className="common-card-body">
                <div className="d-flex justify-content-between">
                  <div className="status-and-overview">
                    <div className="common-card-body">
                      <div className="team-project-status recent-video-component">
                        <h3>Projects Status</h3>
                        <DonutChart />
                      </div>
                    </div>
                    <div className="common-card-body recent-video-component mt-20">
                      <h3 className="d-flex align-items-center justify-content-between">
                        Project Overview
                        <Dropdown
                          title="Week"
                          Data={[
                            { title: 'Week' },
                            { title: 'Month' },
                            { title: 'Year' }
                          ]}
                        />
                      </h3>
                      <p>6 Projects completed out of 10</p>
                      <ProgressBar
                        className="profress-line"
                        baseBgColor="#edeef3"
                        height={14}
                        completed={30}
                        bgColor="linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%)"
                        isLabelVisible={false}
                      />
                    </div>
                  </div>
                  <div className="common-card-body p-20 recent-video-component available-service-main">
                    <h3>Available Services</h3>
                    <ul className="available-service">
                      {service.map((item) => (
                        <li key={item}>
                          <div className="available-service-icon">
                            <img alt="" src={item.icon} />
                          </div>
                          <span>{item.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <h3 className="strong-head gredient-txt mt-40 mb-20">
                  Recent Projects
                </h3>
                <div className="common-card-body pt-2">
                  <div className="order-table recent-project-tab">
                    <table className="w-100">
                      <tr>
                        <th>Project Name</th>
                        <th>Progress</th>
                        <th>Assignee&apos;s</th>
                        <th>Status</th>
                        <th />
                      </tr>

                      {Projects.map((item) => (
                        <tr key={item.productId} className="data-row">
                          <td className="d-flex align-items-center col-gap-20">
                            <div className="client-img">
                              <img alt="" src={item.ProjectCover} />
                            </div>
                            <div className="client-detail">
                              <p className="m-0">{item.ProjectName}</p>
                            </div>
                          </td>
                          <td className="progress-tab">
                            <img alt="" src={Images.ProgressIco} />
                            {item.Progress}
                            %
                          </td>
                          <td className="assignee-user">
                            <div className="d-flex">
                              <div className="d-flex align-items-center">
                                {item?.Assignee.map((img, index) => (
                                  <img alt="" key={index} src={img} />
                                ))}
                              </div>
                              {item?.Assignee.length > 4 && (
                                <div className="total-assignee">
                                  <span>
                                    +
                                    {/* {item?.Assignee.length - 4} */}
                                    {item?.Assignee ? item.Assignee.length - 4 : 0}
                                  </span>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <span id={item.staus}>{item.staus}</span>
                          </td>
                          <td>
                            <Link>
                              <img alt="" src={Images.IconsMore} />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-right">
            <div className="tasks">
              <div className="tasks-head">
                <h3>Calander</h3>
              </div>
              <div className="tasks-celender text-center pb-0">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  inline
                />
              </div>
              <div className="devider" />
              <div className="tasks-head mb-20">
                <h3>Completed Projects</h3>
              </div>
              <div className="completed-project">
                {CompletedProject.map((item) => (
                  <div className="completed-project-item" key={item}>
                    <div>
                      <h6>{item.Project}</h6>
                      <div className="d-flex align-items-center column-gap-1">
                        <img alt="" src={item.ProfileImg} />
                        <span>{item.name}</span>
                      </div>
                    </div>
                    <img alt="" className="border-0" src={Images.CompletedIco} />
                  </div>
                ))}
              </div>
              <div className="devider mt-20" />
              <div className="tasks-head mb-20">
                <h3>Team Members</h3>
              </div>
              <div className="team-member-workspace">
                {TeamMember.map((item) => (
                  <div className="team-member-item" key={item}>
                    <div className="member-detail">
                      <div className="member-pic">
                        <img alt="" src={item.Image} />
                      </div>
                      <ul>
                        <li>{item.name}</li>
                        <li>{item.Position}</li>
                      </ul>
                    </div>
                    <div>
                      <button type="button" className="primary-btn d-flex align-items-center">
                        <img alt="" src={Images.FollowIco} />
                        Follow
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="team-member-all text-center mt-3">
                <button type="button">{English.G248}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Workspace
