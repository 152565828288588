import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const CoursesDetails = () => {
  const items = [
    {
      CoverImage: './images/course1.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/scalco-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course3.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course4.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course1.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/scalco-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course3.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course4.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course1.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/scalco-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course2.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course3.png',
      image: '../../images/testiee-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    },
    {
      CoverImage: './images/course4.png',
      image: '../../images/spacure-table.svg',
      Title: 'Stay Fit with',
      Rating: '4.9',
      text: 'Julie Marie Urte'
    }
  ]
  const EpisodeItems = [
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    },
    {
      CoverImage: './images/episode.png',
      Title: 'Gesundheit// Sub category ',
      Rating: '4.9',
      text: 'Video title for a video which will be here ',
      view: '1.2 M',
      day: '10 days',
      time: '10:20'
    }
  ]
  const [visibleItems, setVisibleItems] = useState(10)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5)
  }
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [ShowPodcastCoverModel, setShowPodcastCoverModel] = useState(false)
  const [ShowPodcastPreviewModel, setShowPodcastPreviewModel] = useState(false)
  const [ShowPodcastPublishModel, setShowPodcastPublishModel] = useState(false)
  const AddPodcast = () => {
    setShowPodcastModel(true)
  }
  const AddPodcastCover = () => {
    setShowPodcastCoverModel(true)
    setShowPodcastModel(false)
  }
  const AddPodcastPreview = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(true)
  }
  const HandleCancel = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setShowPodcastPublishModel(false)
  }
  const HandlePublish = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setShowPodcastPublishModel(true)
  }

  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPodcastCoverModel(false)
        setShowPodcastModel(false)
        setShowPodcastPreviewModel(false)
        setShowPodcastPublishModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const videoRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    const video = videoRef?.current

    // Update the duration when metadata is loaded
    const handleLoadedMetadata = () => {
      setDuration(video.duration)
    }

    // Update the current time during playback
    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime)
    }

    video?.addEventListener('loadedmetadata', handleLoadedMetadata)
    video?.addEventListener('timeupdate', handleTimeUpdate)

    return () => {
      video?.removeEventListener('loadedmetadata', handleLoadedMetadata)
      video?.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [])
  useEffect(() => {
    const video = videoRef.current

    const updateProgress = () => {
      setCurrentTime(video.currentTime)
    }

    const setVideoDuration = () => {
      setDuration(video.duration)
    }

    video?.addEventListener('timeupdate', updateProgress)
    video?.addEventListener('loadedmetadata', setVideoDuration)

    return () => {
      video?.removeEventListener('timeupdate', updateProgress)
      video?.removeEventListener('loadedmetadata', setVideoDuration)
    }
  }, [])

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying)
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
    }
  }

  const toggleMuteUnmute = () => {
    setIsMuted(!isMuted)
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
    }
  }

  const handleProgressChange = (e) => {
    const video = videoRef.current
    const { value } = e.target
    const newTime = (value / 100) * duration
    video.currentTime = newTime
    setCurrentTime(newTime)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0',
    )}`
  }

  return (
    <div className="podcast-details-manage">
      <div className="add-video-row">
        <Link onClick={AddPodcast}>
          <img alt="" src={Images.AddIco} />
          Add New Course
        </Link>
        <div className="items-filter">
          <div className="position-relative input-position">
            <input placeholder="Search Course" />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
          />
        </div>
      </div>
      <div className="courses-item-row">
        {items.slice(0, visibleItems).map((item) => (
          <div
            className="courses-item-box position-relative"
            key={item.text}
            style={{
              background: `url(${item.CoverImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <span className="platform-badge">
              <div
                className="badge-cover"
                style={{
                  background: `url(${item.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
            </span>
            <div className="rating">
              <img alt="" src={Images.RatingStar} />
              <span>{item.Rating}</span>
            </div>
            <div className="Courses-name">
              <p>
                <span>{item.Title}</span>
                <br />
                {item.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      {items.length > visibleItems && (
        <div className="collapse-button text-center mt-3">
          <button type="button" onClick={showMoreItems}>
            <img alt="" src={Images.DownIco} />
          </button>
        </div>
      )}
      {ShowPodcastModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={Images.upload} />
                  </div>
                  <h4>Upload a Cover</h4>
                  <p>
                    {English.G156}
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>{English.G154}</span>
                  </button>
                </label>
              </div>
              <div className="input-grid">
                <div>
                  <label>Title of Course</label>
                  <input placeholder="Title" />
                </div>
                <div>
                  <label>{English.G202}</label>
                  <Dropdown
                    title="Health"
                    Data={[
                      { title: 'Health' },
                      { title: 'Health' },
                      { title: 'Health' },
                      { title: 'Health' },
                      { title: 'Health' }
                    ]}
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Skill Level</label>
                  <Dropdown
                    title="All"
                    Data={[
                      { title: 'All' },
                      { title: 'All' },
                      { title: 'All' },
                      { title: 'All' },
                      { title: 'All' }
                    ]}
                  />
                </div>
                <div>
                  <label>Total Episodes</label>
                  <Dropdown
                    title="6 Episodes"
                    Data={[
                      { title: '2 Episodes' },
                      { title: '3 Episodes' },
                      { title: '4 Episodes' },
                      { title: '5 Episodes' },
                      { title: '6 Episodes' }
                    ]}
                  />
                </div>
              </div>
              <div>
                <label>{English.G112}</label>
                <textarea placeholder={English.G83} />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={AddPodcastCover}
                >
                  {English.G69}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastCoverModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={Images.upload} />
                  </div>
                  <h4>Upload a video</h4>
                  <p>
                    {English.G156}
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>{English.G153}</span>
                  </button>
                </label>
              </div>
              <div>
                <label>Title of Course</label>
                <input placeholder={English.G162} />
              </div>
              <div>
                <label>{English.G112}</label>
                <textarea placeholder={English.G83} />
              </div>
              <button type="button" className="secondary-btn-model">
                <img alt="" src={Images.plusBtn} className="me-2" />
                Add Second Episode
              </button>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button
                  type="button"
                  className="secondary-btn-model"
                  onClick={AddPodcastPreview}
                >
                  {English.G127}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastPreviewModel && (
        <div className="add-item-model preview-model-cources">
          <div className="add-item-content d-block" ref={componentRef}>
            <div
              className="courses-banner"
              style={{
                background: 'url(../../images/courses-cover.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="like">
                <img alt="" src={Images.CourseLike} />
              </div>
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>4.9</span>
              </div>
              <div className="title-text">
                <span>Stay Fit with</span>
                <br />
                Julie Marie Urte
              </div>
              <div className="other-detail">
                <ul>
                  <li>
                    <img alt="" src={Images.Celender} />
                    14 Tags
                  </li>
                  <li>
                    <img alt="" src={Images.Episode} />
                    6 Episodes
                  </li>
                  <li>
                    <img alt="" src={Images.chart} />
                    Schwer
                  </li>
                  <li>
                    <img alt="" src={Images.Schwer} />
                    Schwer
                  </li>
                  <li>
                    <img alt="" src={Images.OhneGerate} />
                    Ohne Gerate
                  </li>
                </ul>
                <button type="button">
                  <img alt="" src={Images.play} />
                  {' '}
                  START
                </button>
              </div>
            </div>
            <div className="courses-description">
              <h3>{English.G112}</h3>
              <p className="text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                lacus vivamus accumsan rhoncus. Enim augue augue lectus vel quis
                diam quisque id. Mauris vitae id lectus maecenas in. Rhoncus
                viverra ut auctor tincidunt et placerat nunc. Sed pretium sed
                velit, sem amet tellus. Commodo diam enim, velit pulvinar
                tincidunt.
              </p>
              <div className="text-center">
                <button type="button">
                  <img alt="" src={Images.collapse} />
                </button>
              </div>
            </div>
            <div className="episode-box common-card-body">
              <h3>Episodes</h3>
              <div className="episode-row">
                {EpisodeItems.map((item) => (
                  <div className="episode-item" key={item.Title}>
                    <div
                      className="episode-cover position-relative"
                      style={{
                        background: `url(${item.CoverImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    >
                      <div className="play-button">
                        <img alt="" src={Images.Videoicon} />
                      </div>
                      <div className="time-duration">{item.time}</div>
                    </div>
                    <div className="episode-detail">
                      <div className="episode-title">
                        <div className="episode-title-text">
                          <img alt="" src={Images.TitleVector} />
                          {item.Title}
                        </div>
                        <div className="rating">
                          <img alt="" src={Images.RatingStar} />
                          <span>{item.Rating}</span>
                        </div>
                      </div>
                      <div className="episode-text">{item.text}</div>
                      <div className="other-detail">
                        <span>
                          <img alt="" src={Images.Eye} />
                          {item.view}
                        </span>
                        <span>
                          <img alt="" src={Images.Clock} />
                          {item.time}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button type="button" className="secondary-btn-model" onClick={HandlePublish}>
                  {English.G128}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastPublishModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="position-relative video-model-wrapper">
              <video
                width="100%"
                height="394"
                poster="./images/course-cover.jpg"
                ref={videoRef}
              >
                <source src="" type="video/mp4" />
              </video>
              <input
                type="range"
                value={(currentTime / duration) * 100 || 0}
                onChange={handleProgressChange}
                className="custom-progress-range"
              />
              <div className="video-controls">
                <button type="button" onClick={togglePlayPause}>
                  {isPlaying ? (
                    <img alt="" src={Images.VideoPause} />
                  ) : (
                    <img alt="" src={Images.play} />
                  )}
                </button>
                <button type="button" onClick={toggleMuteUnmute}>
                  {isMuted ? (
                    <img alt="" src={Images.Videomute} />
                  ) : (
                    <img alt="" src={Images.VideoVolume} />
                  )}
                </button>
                <div className="duration-progress">
                  {formatTime(currentTime)}
                  {' '}
                  /
                  {formatTime(duration)}
                </div>
              </div>
            </div>
            <div className="about-courses-cetegory">
              <h2>Health</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Hendrerit suspendisse enim placerat nisl facilisis. Pellentesque
                quam in sed varius arcu est ipsum, ac. In volutpat odio nulla
                lacus sagittis nunc sed nibh. Elit ullamcorper arcu aliquam eget
                vitae malesuada hendrerit convallis. Scelerisque cursus in mi
                platea. Consequat rhoncus at posuere justo tincidunt pharetra
                diam molestie mattis.
              </p>
              <ul>
                <li>Skill Level: All Levels</li>
                <li>Languages: English</li>
              </ul>
            </div>
            <div className="item-details-input pt-0">
              <div className="button-row">
                <button type="button" className="secondary-btn-model">
                  <span onClick={HandleCancel}>{English.G78}</span>
                </button>
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  {English.G128}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default CoursesDetails
