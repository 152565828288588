import React from 'react'
import { Link } from 'react-router-dom'
import './ServiceCenter.css'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Tutorial = () => {
  const items = [
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    },
    {
      CoverImage: './images/recipies.png',
      TimeDuration: '10:20',
      Title: 'Recipes // Sub category',
      Rating: '4.9',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      days: '10 days',
      other: 'by Jens Peter'
    }
  ]

  return (
    <div className="service-tutorial">
      <div className="content-manager-recipies-wrapper common-card-body">
        <div className="recipies-wrapper-header common-contnt-wrapper">
          <h2>{English.G441}</h2>
          <div className="items-filter">
            <div className="position-relative input-position">
              <input placeholder="Search Project" />
            </div>
            <Dropdown
              title="Filter"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
          </div>
        </div>
        <div className="recipies-wrapper-header common-contnt-wrapper justify-content-end">
          <Link>{English.G248}</Link>
        </div>
        <div className="recipies-item">
          {items.map((item) => (
            <div className="recipies-item-detail" key={item.name}>
              <div className="video-item">
                <div
                  className="video-cover"
                  style={{
                    background: `url(${item.CoverImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  <button type="button" className="drop-button">
                    <img alt="" src={Images.MoreHorizontal} />
                  </button>
                  <div className="like">
                    <img alt="" src={Images.Likes} />
                  </div>
                  <p className="time-duration">10:20</p>
                </div>
                <div className="video-details">
                  <div className="d-flex justify-content-between">
                    <h6>
                      <img alt="" src={Images.Chief} />
                      <span>{item.Title}</span>
                    </h6>
                    <div className="rating">
                      <img alt="" src={Images.RatingStar} />
                      <span>{item.Rating}</span>
                    </div>
                  </div>
                  <p>{item.text}</p>
                  <div className="other-info">
                    <span>
                      <img alt="" src={Images.ClockFillIco} />
                      {item.days}
                    </span>
                    <span>{item.other}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Tutorial
