import React, { useState } from 'react'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const StoreSettings = () => {
  const [isHidden, setIsHidden] = useState(true)

  const handleButtonClick = () => {
    setIsHidden(false)
  }
  const handleButtonClickHide = () => {
    setIsHidden(true)
  }

  return (
    <div className="settings-overview">
      <div className="item-wrapper">
        <h2>Content Manager Settings</h2>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Sales Summary Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all orders</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleSummary">
                <input id="titleSummary" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all products</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesSummary">
                <input id="categroiesSummary" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all customes</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionSummary">
                <input id="descriptionSummary" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Client Order Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show product Id</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleproduct">
                <input id="titleproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show client name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesproduct">
                <input id="categroiesproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show product name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionproduct">
                <input id="descriptionproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show date of order</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredproduct">
                <input id="featuredproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show price of product</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="Priceproduct">
                <input id="Priceproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show status of order</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="Statusproduct">
                <input id="Statusproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Upload product Setting</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Upload picture</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleUploadUploadproduct">
                <input id="titleUploadUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Product name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesUploadproduct">
                <input id="categroiesUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Product brand</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionUploadproduct">
                <input id="descriptionUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Product price</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredUploadproduct">
                <input id="featuredUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Return & waranty</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="PriceUploadproduct">
                <input id="PriceUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Product quantity</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="quantityUploadproduct">
                <input id="quantityUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Product sizes</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="sizesUploadproduct">
                <input id="sizesUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>{English.G112}</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="DescriptionUploadproduct">
                <input id="DescriptionUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show product overall rating</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="overallUploadproduct">
                <input id="overallUploadproduct" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Categories Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="common-select-option">
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Health</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Medicines</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Drinks</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Family</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Supplements</label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Pricing Options</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.CloseIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="common-select-option">
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Option 1</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Option 2</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Option 3</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Option 4</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Option 5</label>
            </div>
          </div>
        </div>
      </div>
      {!isHidden && (
        <div className="add-more-settings-model">
          <div className="model-content">
            <div className="model-header">
              <h4>Add more video setting</h4>
              <button type="button" onClick={handleButtonClickHide}>
                <img className="close-btns" alt="" src={Images.CloseIco} />
              </button>
            </div>
            <div className="model-filled">
              <label>Option name</label>
              <input placeholder="Name here" />
              <label>{English.G112}</label>
              <textarea placeholder="Type here..." />
            </div>
            <div className="model-footer">
              <button type="button" className="save-button">Add option</button>
              <button type="button" className="cancel-button" onClick={handleButtonClickHide}>
                <span>{English.G116}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default StoreSettings
