/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { s3ImageShots, urlSystemIcon } from '../../services/Service'
import { timeDifference } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const Shots = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [shotData, setShotData] = useState([])

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 3 } })
  }

  const getShotLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getShotLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShotData(res?.decryptedData.data)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const shotLikeData = (shot_id) => {
    const platFormData = {
      shot_id
    }
    APICall('post', platFormData, `${EndPoints.shotLike}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShotData(
            shotData.map((item) => (item.shot_id === shot_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    getShotLibraryData()
  }, [])

  return (
    <div className="content-manager-shots-wrapper common-card-body">
      <div className="shots-wrapper-header common-contnt-wrapper">
        <h2>{English.G164}</h2>
        <div className="" onClick={viewAll}>
          <Link>{English.G248}</Link>
        </div>
      </div>
      <div className="video-items">
        {shotData?.length === 0 && !isLoading ? (
          <div className="awards-item-video">
            <div className="add-profile-video-cover" onClick={viewAll}>
              <button type="button">
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="add-profile-video-detail">
              <p />
              <div className="d-flex align-items-center justify-content-between">
                <span />
                <span />
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          shotData?.slice(0, 4)?.map((item) => (
            <div className="video-item-detail" key={item.name}>
              <div
                className="video-cover"
                style={{
                  background: `url(${s3ImageShots + item.cover_attachment})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="video_like_bg">
                  <button
                    type="button"
                    className="video_like"
                    onClick={(e) => {
                      e.stopPropagation()
                      shotLikeData(item.shot_id)
                    }}
                  >
                    {item.is_like ? (
                      <img src={Images.Likes} alt="Unlike" />
                    ) : (
                      <img src={Images.UnLikes} alt="Like" />
                    )}
                  </button>
                </div>
                {item?.app_data?.length > 0 && (
                  <span
                    className={`platform-badge ${
                      item.app_data.length === 2 ? 'platform-badge-alt' : ''
                    }`}
                  >
                    {item?.app_data?.map((data, i) => (
                      <div
                        key={i}
                        className={`badge-cover ${
                          item?.app_data?.length === 2 ? 'badge-cover-alt' : ''
                        }`}
                        style={{
                          backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '90%',
                          backgroundPosition: 'center'
                        }}
                      />
                    ))}
                  </span>
                )}
              </div>
              <div className="video-details video-1">
                <div className="d-flex justify-content-between">
                  <h6>
                    <img
                      className="health_icon"
                      src={Images.HealthIco}
                      alt=""
                    />
                    <span className="content-video-title">{item.title}</span>
                  </h6>
                  <div className="rating">
                    <img src={Images.RatingStar} alt="" />
                    <span>4.9</span>
                  </div>
                </div>
                <p className="shot_home_page_tag">{JSON.parse(item.tags).toString()}</p>
                <div className="other-info">
                  <span>
                    <img src={Images.ClockIco} alt="" />
                    {timeDifference(item.created_at)}
                  </span>
                  <span>{item.other}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
export default Shots
