/* eslint-disable no-nested-ternary */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'
import '../LandingPage.css'
import PrimaryButton from './PrimaryButton'

const LandingHeader = ({ activeType = 0 }) => {
  const navigate = useNavigate()
  return (
    <div className="sticky top-0 flex justify-between items-center pt-3 pb-3 pl-14 pr-14 z-50 bg-headerbg">
      <div className="flex gap-3 items-center">
        <img
          src={activeType === 3 ? Images.GbnLogoWhite : Images.GbnLogo}
          alt="Alt"
        />
        <div className="flex-col flex items-center">
          <div
            className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} inline-block md:text-sm lg:text-lg font-bold pr-1 italic bg-clip-text text-transparent uppercase`}
          >
            {English.A1}
          </div>
          {activeType !== 0 && (
            <div
              className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} inline-block text-sm pr-1 font-normal -mt-2 italic bg-clip-text text-transparent`}
            >
              {activeType === 1
                ? English.A12
                : activeType === 2
                  ? English.A13
                  : English.A14}
            </div>
          )}
        </div>
      </div>

      <div className={`${activeType === 3 ? 'bg-primaryGradient' : 'bg-electricWhite'} flex justify-between items-center gap-10 max-[1100px]:!gap-4 rounded-3xl pt-2 pb-2 pl-10 pr-10`}>
        <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} text-xs md:text-xs lg:text-sm bg-clip-text flex text-transparent`}>
          {English.A2}
          {/* {activeType === 3 && <div className="font-black pl-2 text-sm"></div>} */}
        </div>
        <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} flex text-xs md:text-xs lg:text-sm bg-clip-text text-transparent`}>
          {English.A3}
          {/* {activeType === 3 && <div className="font-black pl-2 text-sm"></div>} */}
        </div>
        <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} flex text-xs md:text-xs lg:text-sm bg-clip-text text-transparent`}>
          {English.A4}
          {/* {activeType === 3 && <div className="font-black pl-2 text-sm"></div>} */}
        </div>
        <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} text-xs md:text-xs lg:text-sm bg-clip-text text-transparent`}>{English.A5}</div>
        <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} text-xs md:text-xs lg:text-sm bg-clip-text text-transparent`}>{English.A6}</div>
        {activeType === 3 && <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} text-xs md:text-xs lg:text-sm bg-clip-text text-transparent`}>{English.B18}</div>}
        {activeType === 3 && <div className={`${activeType === 3 ? 'bg-electricWhite' : 'bg-primaryGradient'} text-xs md:text-xs lg:text-sm bg-clip-text text-transparent`}>{English.B19}</div>}
      </div>

      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate('/register')
          }}
          className="bg-electricWhite rounded-3xl pl-5 pr-5 pt-2 pb-2"
          type="button"
        >
          <div className="bg-primaryGradient font-black italic id-button pr-1 bg-clip-text text-transparent">
            {English.G21}
          </div>
        </button>
        <PrimaryButton
          txtStyle={{ textTransform: 'uppercase' }}
          bgClass={activeType === 3 ? 'bg-electricPurple' : null}
          text={English.G45}
          onClick={() => navigate('/login')}
        />
      </div>
    </div>
  )
}

export default LandingHeader
