import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

const initialState = {
  userData: null,
  token: null,
  app_id: null,
  profileData: null,
  creatorData: null,
  stay_loginData: null,
  user_type: null,
  onBoardPage: null,
  businessInfo: null
}

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { type } = action.payload
      const { data } = action.payload
      switch (type) {
        case 'token':
          state.token = cloneDeep(data)
          break

        case 'userInfo':
          state.userData = cloneDeep({ ...(state.userData ?? {}), ...data })
          break

        case 'appId':
          state.app_id = cloneDeep({ ...(state.app_id ?? {}), ...data })
          break

        case 'profile_Data':
          state.profileData = cloneDeep({ ...(state.profileData ?? {}), ...data })
          break

        case 'creator_Data':
          state.creatorData = cloneDeep({ ...(state.creatorData ?? {}), ...data })
          break

        case 'stay_login':
          state.stay_loginData = cloneDeep(data)
          break

        case 'user_type':
            state.user_type = cloneDeep(data)
            break

        case 'onBoardPage':
            state.onBoardPage = cloneDeep(data)
            break

        case 'businessInfo':
            state.businessInfo = cloneDeep(data)
            break

        default:
          break
      }
    },
    removeToken: (state) => {
      state.token = null
      state.app_id = null
      state.profileData = null
      state.creatorData = null
      state.stay_loginData = null
      state.onBoardPage = null
      state.businessInfo = null
    },
    Logout: (state) => {
      state.userData = null
      state.token = null
      state.app_id = null
      state.profileData = null
      state.creatorData = null
      state.stay_loginData = null
      state.user_type = null
      state.onBoardPage = null
      state.businessInfo = null
    }
  }
})

export const { setUserData, setToken, removeToken, Logout } = UserSlice.actions

export default UserSlice.reducer
