import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ProfileSkeleton = () => {
  return (
    <div className="profile-head-builder">
      <div className="profile-builder-header mb-40">
        <button type="button">
          <Skeleton width={25} height={25} />
        </button>
        <div className="profile-heading">
          <Skeleton width={35} height={35} />
          <span>
            <Skeleton width={150} height={25} />
          </span>
        </div>
        <button type="button">
          <Skeleton width={25} height={25} />
        </button>
      </div>
      <div className="profile-builder-main">
        <div className="profile-personal-detail">
          <Skeleton className="profile_ske_icon" />
          <div className="about-personal-profile">
            <h2>
              <Skeleton width={150} height={20} />
            </h2>
            <h3>
              <Skeleton width={180} height={20} />
            </h3>
            <p>
              <Skeleton width={150} height={20} />
            </p>
            <span>
              <Skeleton width={130} height={20} />
            </span>
          </div>
        </div>
        <div className="profile-account-details">
          <ul className="about-account-insignt">
            <li>
              <h4>
                <Skeleton width={20} />
              </h4>
              <p>
                <Skeleton width={50} height={20} />
              </p>
            </li>
            <li>
              <h4>
                <Skeleton width={20} />
              </h4>
              <p className="Follower_text">
                <Skeleton width={50} height={20} />
              </p>
            </li>
            <li>
              <h4>
                <Skeleton width={20} />
              </h4>
              <p>
                <Skeleton width={50} height={20} />
              </p>
            </li>
          </ul>
          <div className="follow-connenct">
            <div className="buttons-group">
              <Skeleton
                className="rounded-5 profile-skeleton-btn"
                height={40}
              />
              <Skeleton
                className="rounded-5 profile-skeleton-btn"
                height={40}
              />
            </div>
            <div>
              <Skeleton className="rounded-5" width={60} height={60} />
            </div>
          </div>
          <div className="follwer-shows">
            <div className="follower-img-group d-flex">
              <Skeleton
                className="rounded-5 skeleton-img-group"
                width={30}
                height={30}
              />
              <Skeleton
                className="rounded-5 skeleton-img-group"
                width={30}
                height={30}
              />
              <Skeleton
                className="rounded-5 skeleton-img-group"
                width={30}
                height={30}
              />
              <Skeleton
                className="rounded-5 skeleton-img-group"
                width={30}
                height={30}
              />
            </div>
            <p>
              <span className="gredient-txt">
                <Skeleton className="follow-text" height={20} />
              </span>
            </p>
          </div>
          <div className="profile-redirect">
            <Skeleton width={25} height={25} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfileSkeleton
