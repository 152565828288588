/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Images } from '../../../helpers'
import TitleComponent from './SubComponent/TitleComponent'
import ListVerticalComponent from './SubComponent/ListVerticalComponent'
import CompanyDetailComponent from './SubComponent/CompanyDetailComponent'

const BusinessLandingView = () => {
  return (
    <div>
      <ul className="mb-0 px-14 flex gap-5 items-center justify-center flex-wrap">
        <li className="group uppercase italic text-lg font-black border border-[#dee2e64d] px-[30px] py-[15px] rounded-[80px] transition-all hover:bg-electricWhite cursor-pointer">
          <span className="bg-clip-text text-transparent bg-electricWhite pe-1 group-hover:bg-primaryGradient transition-all">
            HOME DASHBOARD
          </span>
        </li>
        <li className="group uppercase italic text-lg font-black border border-[#dee2e64d] px-[30px] py-[15px] rounded-[80px] transition-all hover:bg-electricWhite cursor-pointer">
          <span className="bg-clip-text text-transparent bg-electricWhite pe-1 group-hover:bg-primaryGradient transition-all">
            PROFILE BUILDER
          </span>
        </li>
        <li className="group uppercase italic text-lg font-black border border-[#dee2e64d] px-[30px] py-[15px] rounded-[80px] transition-all hover:bg-electricWhite cursor-pointer">
          <span className="bg-clip-text text-transparent bg-electricWhite pe-1 group-hover:bg-primaryGradient transition-all">
            CONTENT UPLOAD
          </span>
        </li>
        <li className="group uppercase italic text-lg font-black border border-[#dee2e64d] px-[30px] py-[15px] rounded-[80px] transition-all hover:bg-electricWhite cursor-pointer">
          <span className="bg-clip-text text-transparent bg-electricWhite pe-1 group-hover:bg-primaryGradient transition-all">
            LOCATIONS
          </span>
        </li>
        <li className="group uppercase italic text-lg font-black border border-[#dee2e64d] px-[30px] py-[15px] rounded-[80px] transition-all hover:bg-electricWhite cursor-pointer">
          <span className="bg-clip-text text-transparent bg-electricWhite pe-1 group-hover:bg-primaryGradient transition-all">
            MARKETPLACE
          </span>
        </li>
        <li className="group uppercase italic text-lg font-black border border-[#dee2e64d] px-[30px] py-[15px] rounded-[80px] transition-all hover:bg-electricWhite cursor-pointer">
          <span className="bg-clip-text text-transparent bg-electricWhite pe-1 group-hover:bg-primaryGradient transition-all">
            ADS
          </span>
        </li>
      </ul>
      <div className="py-14">
        <div className="pb-14">
          <Swiper
            slidesPerView={1.5}
            spaceBetween={30}
            centeredSlides
            className="ScreenSwiper"
            initialSlide={1}
          >
            <SwiperSlide>
              <div className="slide-block h-[calc(100vh-292px)] rounded-[32px] bg-[#f9f9f91f] p-4">
                <img className="w-full h-full object-cover rounded-[32px]" src={Images.SlideScreen1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-block h-[calc(100vh-292px)] rounded-[32px] bg-[#f9f9f91f] p-4">
                <img className="w-full h-full object-cover rounded-[32px]" src={Images.SlideScreen2} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-block h-[calc(100vh-292px)] rounded-[32px] bg-[#f9f9f91f] p-4">
                <img className="w-full h-full object-cover rounded-[32px]" src={Images.SlideScreen3} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="text-center">
          <button className="bg-primaryGradient rounded-[60px] gradient-border !p-[3px]" type="button">
            <div className="bg-electricWhite rounded-[60px] py-2 px-12 max-[1440px]:!px-3">
              <div className="bg-primaryGradient font-black pr-1 italic bg-clip-text text-transparent uppercase text-lg flex items-center gap-4 max-[1440px]:text-sm">
                APP WORLD ONE ID ERSTELLEN
                <img src={Images.ButtonAerrow} alt="" />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="py-14 px-14">
        <TitleComponent
          userType={3}
          title="SO FUNKTIONIERT ES"
          subTitle="PRÄSENTIEREN SIE IHR UNTERNEHMEN"
        />
        <div className="grid grid-cols-2 gap-20 mt-10 max-[1440px]:gap-6">
          <div className="flex flex-col gap-20">
            <ListVerticalComponent
              userType={3}
              data={[
                {
                  title: 'APP WORLD ONE ID ERSTELLEN',
                  subTitle: 'REGISTRIEREN SIE IHR BUSINESS',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'APP WORLD ONE ID ERSTELLEN',
                  image: Images.ImageVerticalBlock
                },
                {
                  title: 'WÄHLEN SIE IHRE APPS',
                  subTitle: 'ERREICHEN SIE IHRE ZIELGRUPPE IN BIS ZU 16 APPS',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'MEHR ÜBER DIE APPS',
                  image: Images.ImageVerticalBlock
                },
                {
                  title: 'PROFILE BUILDER & ADD ONS',
                  subTitle: 'GESTALTEN SIE IHRE PROFILE INDIVIDUELL',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'PROFILE BUILDER ENTDECKEN',
                  image: Images.ImageVerticalBlock
                },
                {
                  title: 'ABO UND UPGRADE OPTIONEN',
                  subTitle: 'PERSONALISIEREN SIE IHR ABO EFFIZIENT',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'ABO PLÄNE & TARIFE',
                  image: Images.ImageVerticalBlock
                }
              ]}
            />
          </div>
          <div className="flex flex-col gap-20 mt-36">
            <ListVerticalComponent
              userType={3}
              data={[
                {
                  title: 'UNTERNEHMENS ART AUSWÄHLEN',
                  subTitle: 'EXPERTE - LOCAL - STORE - PUBLISHER',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'KLASSIFIZIERUNGEN VERGLEICHEN',
                  image: Images.ImageVerticalBlock
                },
                {
                  title: 'IHRE VERIFIZIERUNG',
                  subTitle: 'LASSEN SIE DEN BUSINESS ZUGANG FREISCHALTEN',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'NOTWENDIGE ANFORDERUNGEN',
                  image: Images.ImageVerticalBlock
                },
                {
                  title: 'OFFIZIELLE INTEGRATIONEN',
                  subTitle: 'LISTEN SIE LOCATIONS UND PRODUKTE',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'INTEGRATIONEN ANSEHEN',
                  image: Images.ImageVerticalBlock
                },
                {
                  title: 'STEIGERN SIE IHRE REICHWEITE',
                  subTitle: 'ERREICHEN SIE IHRE ZIELGRUPPE MIT DEM AD SYSTEM',
                  description: 'This add-on offers a variety of features, allowing you to engage with your community in multiple ways. It provides flexibility and enhances interaction, giving you more options for connection.',
                  buttonLabel: 'AD SYSTEM ERKUNDEN',
                  image: Images.ImageVerticalBlock
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div className="py-14">
        <div className="pb-14">
          <Swiper
            slidesPerView={1.5}
            spaceBetween={30}
            centeredSlides
            className="ScreenSwiper"
            initialSlide={1}
          >
            <SwiperSlide>
              <div className="slide-block h-[calc(100vh-292px)] rounded-[32px] bg-[#f9f9f91f] p-4">
                <img className="w-full h-full object-cover rounded-[32px]" src={Images.SlideScreen1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-block h-[calc(100vh-292px)] rounded-[32px] bg-[#f9f9f91f] p-4">
                <img className="w-full h-full object-cover rounded-[32px]" src={Images.SlideScreen2} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-block h-[calc(100vh-292px)] rounded-[32px] bg-[#f9f9f91f] p-4">
                <img className="w-full h-full object-cover rounded-[32px]" src={Images.SlideScreen3} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="text-center">
          <button className="bg-primaryGradient rounded-[60px] gradient-border !p-[3px]" type="button">
            <div className="bg-electricWhite rounded-[60px] py-2 px-12 max-[1440px]:!px-3">
              <div className="bg-primaryGradient font-black pr-1 italic bg-clip-text text-transparent uppercase text-lg flex items-center gap-4 max-[1440px]:text-sm">
                APP WORLD ONE ID ERSTELLEN
                <img src={Images.ButtonAerrow} alt="" />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="py-14 px-14">
        <TitleComponent
          userType={3}
          title="PROFILE BUILDER"
          subTitle="BIS ZU 16 PROFILE ZENTRAL STEUERN"
        />
        <div className="grid grid-cols-2 items-center">
          <div className="p-10">
            <h4 className="bg-electricWhite bg-clip-text text-transparent italic font-black leading-none text-[54px]">SO ERSTELLEN SIE PROFILE</h4>
            <h5 className="bg-electricWhite bg-clip-text text-transparent italic font-bold text-[27px]">DIE DIGITALE UNTERNEHMENSPRÄSENZ VON MORGEN</h5>
            <p className="bg-electricWhite bg-clip-text text-transparent leading-none text-[22px] my-10">Mit dem Profile Builder haben Sie die Möglichkeit Profile für die Apps von App World One zu erstellen und zu bearbeiten. Laden Sie den Content zentral über Ihr B hoch und füllen Sie die Profile passend zu der jeweiligen Zielgruppe aus. Nutzen Sie AddOns für Erweiterungen Ihres Profils.</p>
            <button className="bg-primaryGradient rounded-[60px] gradient-border !p-[3px]" type="button">
              <div className="bg-electricWhite rounded-[60px] py-2.5 px-12 max-[1440px]:!px-3">
                <div className="bg-primaryGradient font-black pr-1 italic bg-clip-text text-transparent uppercase text-lg flex items-center gap-4 max-[1440px]:text-sm">
                  MEHR ZUM PROFILE BUILDER
                  <img src={Images.ButtonAerrow} alt="" />
                </div>
              </div>
            </button>
          </div>
          <div className="relative">
            <img className="mw-100 relative z-1" src={Images.ProfileBuilder} alt="" />
            <div className="light-pink-ellips absolute left-0 bottom-0" />
            <div className="multi-color-ellips absolute top-0 right-0" />
            <div />
          </div>
        </div>
      </div>
      <footer className="p-[60px] pb-0 bg-lightGradient">
        <CompanyDetailComponent />
      </footer>
      <div className="w-full h-[10px] bg-BgRainbow" />
    </div>
  )
}

export default BusinessLandingView
