import React from 'react'
import { Link } from 'react-router-dom'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const RecentProduct = () => {
  const items = [
    {
      img: './images/products/1.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$280.00'
    },
    {
      img: './images/products/2.png',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$250.00'
    },
    {
      img: './images/products/3.png',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$180.00'
    },
    {
      img: './images/products/4.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$120.00'
    }
  ]
  return (
    <div className=" services-container menu-wrapper common-card-body recent-video-component">
      <h3 className="d-flex align-items-center justify-content-between">
        Dinner
        {' '}
        <Link>{English.G248}</Link>
      </h3>
      <div className="product-grid--item">
        {items.map((item) => (
          <div className="product-grid-item" key={item}>
            <div
              className="product-item-cover"
              style={{
                background: `url(${item.img})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="rating">
                <img alt="" src={Images.RatingStar} />
                <span>{item.rating}</span>
              </div>
            </div>
            <div className="product-item-details d-flex align-items-center justify-content-between">
              <span>{item.name}</span>
              <h4>{item.price}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RecentProduct
