import React from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Images from '../../helpers/Images'

const Slider = () => {
  const items = [
    {
      heading: 'Your Own Fitness Expert',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim morbi eu elementum pellentesque arcu. Suscipit id arcu scelerisque non egestas leo risus viverra. Pretium, dictum eget nulla volutpat.',
      img: './images/traning/1.jpg'
    },
    {
      heading: 'Your Own Fitness Expert',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim morbi eu elementum pellentesque arcu. Suscipit id arcu scelerisque non egestas leo risus viverra. Pretium, dictum eget nulla volutpat.',
      img: './images/traning/1.jpg'
    }
  ]
  return (
    <div className="slider-for-reviews position-relative">
      <Swiper
        slidesPerView={1}
        navigation
        modules={[Navigation]}
        className="mySwiper-story review-slider position-static"
      >
        {items.map((item) => (
          <SwiperSlide key={item.heading}>
            <div className="review-slider-container">
              <div className="review-text">
                <img alt="" className="w-auto h-auto" src={Images.VectorIco} />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Condimentum eu dolor, etiam nunc porttitor at quam euismod.
                  Fermentum duis ornare leo tortor aliquet tincidunt nullam
                  habitasse donec.
                </p>
                <span>Ankit Muller , Designation</span>
              </div>
              <div className="">
                <div
                  className="review-cover-img"
                  style={{
                    background: 'url(./images/ratingreview/1.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default Slider
