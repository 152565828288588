import _ from 'lodash'
import { ReduxStore } from '../redux'

/* eslint-disable no-control-regex */
export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true
  }
  return false
}

const deepClone = (val) => {
  return _.cloneDeep(val)
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  const cleanedString = string.replace(/^\s+/, '').replace(/\s+/g, ' ')
  return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1)
}

export const timeDifference = (createdAt) => {
  const createdDate = new Date(createdAt)
  const now = new Date()
  let difference = now - createdDate

  if (difference < 0) {
    difference = 0
  }

  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (seconds < 60) {
    return `${seconds} seconds`
  }
  if (minutes < 60) {
    return `${minutes} minutes`
  }
  if (hours < 24) {
    return `${hours} hours`
  }
  return `${days} days`
}

export const formatViews = (views) => {
  if (views >= 1_000_000_000) {
    return `${(views / 1_000_000_000).toFixed(1)}B`
  }
  if (views >= 1_000_000) {
    return `${(views / 1_000_000).toFixed(1)}M`
  }
  if (views >= 1_000) {
    return `${(views / 1_000).toFixed(1)}k`
  }
  return views.toString()
}

export const debounce = (func, wait) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

export const formatTimeLeft = (timeLeft) => {
  const [hours, minutes] = timeLeft.split(':').map(Number)

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} left`
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} left`
  }
  return 'Time is up'
}

export const formatDuration = (seconds) => {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)
  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
}

export const formatDurationPodcast = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins} M : ${secs.toString().padStart(2, '0')} S`
}

export const lockScroll = () => {
  document.body.style.overflow = 'hidden'
}

export const unlockScroll = () => {
  document.body.style.overflow = 'auto'
}

export const preventSpaceInput = (e) => {
  if (e.key === ' ') {
    e.preventDefault()
  }
}

export const preventSpacePaste = (e) => {
  if (e.clipboardData.getData('Text').includes(' ')) {
    e.preventDefault()
  }
}

export const preventMultipleAtSign = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  if ((pastedText.match(/@/g) || []).length > 1) {
    e.preventDefault()
  }
}

export const preventMultipleAtSignKeydown = (e, emailValue) => {
  if (e.key === '@' && (emailValue.match(/@/g) || []).length >= 1) {
    e.preventDefault()
  }

  const invalidChars = /[^a-zA-Z0-9@._]/
  if (invalidChars.test(e.key)) {
    e.preventDefault()
  }
}

export const preventMultipleAtSignPaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  if ((pastedText.match(/@/g) || []).length > 1) {
    e.preventDefault()
  }

  const invalidChars = /[^a-zA-Z0-9@._]/
  if (invalidChars.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticKeydown = (e) => {
  const regex = /^[A-Za-z]+$/
  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z]+$/
  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventInvalidKeydown = (e) => {
  const regex = /^[A-Za-z0-9._]+$/
  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Tab'
  ) {
    e.preventDefault()
  }
}

export const preventInvalidPaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z0-9._]+$/
  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

// space allow
export const preventNonAlphabeticKeydownWithSpace = (e) => {
  const regex = /^[A-Za-z ]+$/
  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPasteWithSpace = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z ]+$/
  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticKeydownWithSingleSpace = (e) => {
  const regex = /^[A-Za-z ]$/
  const inputElement = e.target
  const cursorPosition = inputElement.selectionStart

  if (
    e.key === ' ' &&
    (cursorPosition === 0 || inputElement.value[cursorPosition - 1] === ' ')
  ) {
    e.preventDefault()
  } else if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPasteWithSingleSpace = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z ]+$/

  if (!regex.test(pastedText) || /\s{2,}/.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventNonAlphanumericKeydownWithSingleSpace = (e) => {
  const regex = /^[A-Za-z0-9 ]$/
  const inputElement = e.target
  const cursorPosition = inputElement.selectionStart

  if (
    e.key === ' ' &&
    (cursorPosition === 0 || inputElement.value[cursorPosition - 1] === ' ')
  ) {
    e.preventDefault()
  } else if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphanumericPasteWithSingleSpace = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z0-9 ]+$/

  if (!regex.test(pastedText) || /\s{2,}/.test(pastedText)) {
    e.preventDefault()
  }
}

export const normalizeAddress = (address) => {
  let normalized = address.normalize('NFKD')

  normalized = normalized
    .replace(/é/g, 'e')
    .replace(/ñ/g, 'n')
    .replace(/ë/g, 'e')
    .replace(/ç/g, 'c')
    .replace(/ü/g, 'u')
    .replace(/ß/g, 'ss')

  normalized = normalized.replace(/[^\u0000-\u00FF]/g, '')

  return normalized
}

export const ForMobileNumber = (e) => {
  const { key } = e
  const inputValue = e.target.value
  if (!/[0-9]/.test(key) && !(key === '+' && inputValue.length === 0)) {
    e.preventDefault()
    return
  }
  if (key === '+' && inputValue.includes('+')) {
    e.preventDefault()
    return
  }
  const maxLength = inputValue.startsWith('+') ? 13 : 12
  if (inputValue.length >= maxLength && key !== 'Backspace') {
    e.preventDefault()
  }
}

export const getToken = () => {
  const token = ReduxStore.getState().user?.token
  return token
}

export const preventNonAlphabeticKeydownNoSpaces = (e) => {
  const regex = /^[A-Za-z]$/

  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPasteNoSpaces = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z]+$/

  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

export const allowAlphaNumericDashSingleSpaceKeydown = (e) => {
  const regex = /^[A-Za-z0-9-]$/
  const inputElement = e.target
  const cursorPosition = inputElement.selectionStart

  if (
    e.key === ' ' &&
    (cursorPosition === 0 || inputElement.value[cursorPosition - 1] === ' ')
  ) {
    e.preventDefault()
  } else if (
    !regex.test(e.key) &&
    e.key !== ' ' &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const allowAlphaNumericDashSingleSpacePaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z0-9 -]+$/
  const invalidSpaces = /\s{2,}/

  if (!regex.test(pastedText) || invalidSpaces.test(pastedText)) {
    e.preventDefault()
  }
}
export const isValidUserName = (value) => {
  return !value.match(/^[A-Za-z0-9_]*$/)
}

const Utility = {
  deepClone,
  isEmpty,
  capitalizeFirstLetter,
  timeDifference,
  formatViews,
  debounce,
  formatTimeLeft,
  formatDuration,
  formatDurationPodcast,
  lockScroll,
  unlockScroll,
  preventSpaceInput,
  preventSpacePaste,
  preventMultipleAtSign,
  preventMultipleAtSignKeydown,
  preventMultipleAtSignPaste,
  preventNonAlphabeticKeydown,
  preventNonAlphabeticPaste,
  preventNonAlphabeticPasteWithSingleSpace,
  preventNonAlphabeticKeydownWithSingleSpace,
  preventInvalidKeydown,
  preventInvalidPaste,
  preventNonAlphabeticKeydownWithSpace,
  preventNonAlphabeticPasteWithSpace,
  preventNonAlphanumericKeydownWithSingleSpace,
  preventNonAlphanumericPasteWithSingleSpace,
  normalizeAddress,
  ForMobileNumber,
  getToken,
  preventNonAlphabeticKeydownNoSpaces,
  preventNonAlphabeticPasteNoSpaces,
  allowAlphaNumericDashSingleSpaceKeydown,
  allowAlphaNumericDashSingleSpacePaste,
  isValidUserName
}

export default Utility
