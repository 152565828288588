/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import Utility from '../helpers/Utility'
import { Logout } from '../redux/UserSlice/UserSlice'

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logOut = () => {
    dispatch(Logout())
    navigate('/login', { replace: true })
    toast.dismiss()
    toast.error('Session Expired, Please Login Again')
  }

  const token = Utility.getToken()
  useEffect(() => {
    if (!token) {
      navigate('/login')
    } else if (token) {
      const { exp } = jwtDecode(token)
      const tokenExp = exp - 60
      const currentTime = Date.now() / 1000
      if (tokenExp < currentTime) {
        logOut()
      }
    }
    setStatus(true)
  }, [component])

  return status ? component : null
}

export default AuthGuard
