/* eslint-disable max-len */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const Inspirations = () => {
  const navigate = useNavigate()
  const initialApps = [
    {
      id: 1,
      image: Images.Inspirations_image_1,
      text: 'FASHION'
    },
    {
        id: 2,
        image: Images.Inspirations_image_2,
        text: 'TIERE'
    },
    {
        id: 3,
        image: Images.Inspirations_image_3,
        text: 'BEAUTY'
    }
  ]

  const handleButtonClick = () => {
    navigate('/dashboard/inspirations')
  }

  return (
    <div className="p-0 bg-gray-50 ">
      <div className="flex justify-between items-center mb-3">
        <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
          {English.A150}
        </div>
        <CommonButton
          label={English.A141}
          type="button"
          className="primary-btns w-auto"
          context="primary-btn"
          isPrimary
          onClick={handleButtonClick}
        />
      </div>
      <p className="text-[#9E9E9E] font-medium text-base">{English.A143}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {initialApps.map((i) => (
          <div
            key={i.id}
            className={`w-[100%] h-auto p-[12px] rounded-2xl bg-white ${
              i.isSelected ? 'selected' : 'selectable'
            }`}
          >
            <div className="flex flex-col gap-3">
              <div>
                <div className="flex flex-col gap-2">
                  <div className="mb-2 text-center">
                    <img className="" src={i.image} alt="" />
                  </div>
                  <h2 className="italic font-black text-lg text-gradient">{i.text}</h2>
                </div>
                <div
                  className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer"
                >
                  <div
                    className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
                    type="button"
                  >
                    LEARN MORE
                  </div>
                  <img
                    className="ml-1 mt-2"
                    src={Images.arrow_left_black}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Inspirations
