import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import Dropdown from '../Dropdown/Dropdown'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const LocalHome = () => {
  const [ShowTableModel, setShowTableModel] = useState(false)
  const AddTable = () => {
    setShowTableModel(true)
  }
  const HandleCancel = () => {
    setShowTableModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowTableModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className="common-card-body">
      <div className="local-home-main">
        <div className="local-main-cover position-relative">
          <button type="button" className="add-item-btn" onClick={AddTable}>
            <img alt="" src={Images.Plus} />
          </button>
        </div>
        <div className="d-flex align-items-center block-bottom-row">
          <div className="block-bottom add-value" />
          <div className="block-bottom add-value" />
          <div className="block-bottom add-value" />
          <div className="block-bottom add-value" />
        </div>
        <p className="m-0 add-value" />
        <div className="input-block-local">
          <div className="input-block add-value" />
          <div className="input-block add-value" />
          <div className="input-block add-value" />
        </div>
        <div className="add-value local-home-btn" />
      </div>
      {ShowTableModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <h3>Book a Table</h3>
            <div className="item-details-input pt-0">
              <div className="input-grid">
                <div>
                  <label>Preisspanne</label>
                  <input placeholder={English.G283} />
                </div>
                <div>
                  <label>Nobistor 10</label>
                  <input placeholder="Km" />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Hashtag</label>
                  <input placeholder="Add hashtags" />
                </div>
                <div>
                  <label>Input Fields</label>
                  <Dropdown
                    title="Add Fields"
                    Data={[
                      { title: 'Please select' },
                      { title: 'Please select' },
                      { title: 'Please select' },
                      { title: 'Please select' },
                      { title: 'Please select' }
                    ]}
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Button Label</label>
                  <Dropdown
                    title="Add Label"
                    Data={[
                      { title: 'Please select' },
                      { title: 'Please select' },
                      { title: 'Please select' },
                      { title: 'Please select' },
                      { title: 'Please select' }
                    ]}
                  />
                </div>
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button type="button" className="secondary-btn-model">Add</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default LocalHome
