import React from 'react'
import './style.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHead from '../profile/ProfileHead'
import RatingContainer from '../RatingContainer/Rating'
import ReviewsAndReting from '../ReviewsAndRating/ReviewsAndRating'
import About from '../About/About'
import Slider from '../ReviewsAndRating/slider'
import HomeAbout from '../HomeAbout/HomeAbout'
import EventHome from './EventHome'
import EventCetegory from './EventCetegory'
import EventOffer from './EventOffer'
import Tickets from './Tickets'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Events = () => {
  const itemsReview = [
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ac praesent eget ornare mattis. ',
      image: './images/review-profie.jpg',
      name: 'Kathryn Murphy',
      date: 'May 2021'
    }
  ]
  return (
    <div className="creator-profile-main">
      <ProfileHead />

      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={Images.HomeIco} alt="" />
                  {English.G222}
                </Tab>
                <Tab>
                  <img src={Images.FeedIco} alt="" />
                  {English.G223}
                </Tab>
                <Tab>
                  <img src={Images.TicketIco} alt="" />
                  Tickets/Booking
                </Tab>
                <Tab>
                  <img src={Images.FeedbackIco} alt="" />
                  {English.G427}
                </Tab>
                <Tab>
                  <img src={Images.AboutIco} alt="" />
                  {English.G428}
                </Tab>
              </TabList>

              <TabPanel>
                <div className="mt-40">
                  <EventHome />
                </div>
                <div className="mt-40">
                  <EventCetegory />
                </div>
                <div className="mt-40">
                  <EventOffer />
                </div>
                <div className="mt-40">
                  <HomeAbout />
                </div>
                <div className="mt-40">
                  <RatingContainer />
                </div>
                <div className="mt-40">
                  <Slider />
                </div>
              </TabPanel>
              <TabPanel>
                <h3 className="room-tab--heading">Room Photos</h3>
                <div className="grid-gellery position-relative">
                  <div className="gellery-item grid-1--span-4">
                    <img src="./images/gellery/1.jpg" alt="" />
                    <div className="overlay-gredient" />
                  </div>
                  <div className="gellery-item">
                    <img src="./images/gellery/2.jpg" alt="" />
                    <div className="overlay-gredient" />
                  </div>
                  <div className="gellery-item">
                    <img src="./images/gellery/3.jpg" alt="" />
                    <div className="overlay-gredient" />
                  </div>
                  <button type="button" className="show-photos">
                    <img src={Images.ShowIco} alt="" />
                    <span>Show all photos</span>
                  </button>
                </div>

                <div className="discription-box recent-video-component mb-0 mt-40">
                  <h3>{English.G112}</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin iaculis morbi tempus volutpat tortor. Ipsum vitae enim
                    sit suscipit in ridiculus. Morbi potenti eu facilisis vitae
                    posuere suspendisse. Praesent sit lorem amet posuere vel
                    diam scelerisque a. Nisl imperdiet egestas dui consequat,
                    diam et, at tempor.
                  </p>
                </div>

                <div className="common-card-body recent-video-component place-offer mt-40">
                  <h3>What this place offers</h3>
                  <ul>
                    <li>
                      <img src={Images.shover} alt="" />
                      Parking
                    </li>
                    <li>
                      <img src={Images.Wifi} alt="" />
                      Free Wifi
                    </li>
                    <li>
                      <img src={Images.Cigarette} alt="" />
                      Cigarette
                    </li>
                    <li>
                      <img src={Images.RoomService} alt="" />
                      Room Service
                    </li>
                    <li>
                      <img src={Images.Iron} alt="" />
                      Iron
                    </li>
                    <li>
                      <img src={Images.Library} alt="" />
                      Library
                    </li>
                  </ul>
                </div>

                <div className="common-card-body mt-40 recent-video-component">
                  <h3>Reviews</h3>
                  <div className="hotel-reserve--review">
                    {itemsReview.map((item) => (
                      <div
                        className="hotel-reserve-review--item"
                        key={item.name}
                      >
                        <p>{item.review}</p>
                        <div className="devider" />
                        <div className="hotel-reserve-review--detail d-flex align-items-center col-gap-20">
                          <img src={item.image} alt="" />
                          <div>
                            <p>{item.name}</p>
                            <span>{item.date}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-center mt-30">
                  <button type="button" className="primary-btn w-4">Select Room</button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-40">
                  <Tickets />
                </div>
              </TabPanel>
              <TabPanel>
                <ReviewsAndReting />
              </TabPanel>
              <TabPanel>
                <About />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Events
