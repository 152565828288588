const Images = {
  AuthLogo: require('../images/auth-logo.svg').default,
  eyeOff: require('../images/eye-off.svg').default,
  eyeOn: require('../images/eye-on.svg').default,
  Back: require('../images/back_btn_white.svg').default,
  calendar: require('../images/calendar.svg').default,
  AddImageIco: require('../images/AddImageIco.svg').default,
  useInfo: require('../images/useInfoImage.svg').default,
  StarIco: require('../images/StarIco.svg').default,
  Scaloo: require('../images/scaloo.svg').default,
  LuxuryEleven: require('../images/luxury-eleven.svg').default,
  Fyerdates: require('../images/fyerdates.svg').default,
  Spacure: require('../images/spacure.svg').default,
  FamilyTrends: require('../images/family-trends.svg').default,
  AZFit: require('../images/a-z-feet.svg').default,
  Testiee: require('../images/testiee.svg').default,
  GoAthlete: require('../images/go-athlete.svg').default,
  CreatorTelents: require('../images/creator-talents.svg').default,
  TheTestClub: require('../images/the-teste-club.svg').default,
  Filiyo: require('../images/filiyo.svg').default,
  Petzino: require('../images/petzino.svg').default,
  Logo: require('../images/logoicons.svg').default,
  info: require('../images/info.svg').default,
  cancel: require('../images/ic_baseline-cancel.svg').default,
  SelectImg: require('../images/selectimg.svg').default,
  plusgredient: require('../images/plus-gredient.svg').default,
  Remove: require('../images/remove.svg').default,
  useInfoDummy: require('../images/createprofile_dummy.svg').default,
  plusimg: require('../images/plusimg.svg').default,
  plusrounded: require('../images/plus-white.svg').default,
  GbnLogoBusiness: require('../images/GbnLogo.png'),
  Home: require('../images/home.svg').default,
  Profile: require('../images/profile.svg').default,
  Workspace: require('../images/workspace.svg').default,
  ContentManager: require('../images/contentmanager.svg').default,
  MyStore: require('../images/mystore.svg').default,
  Local: require('../images/local.svg').default,
  CloudStore: require('../images/cloudstore.svg').default,
  ADS: require('../images/ads.svg').default,
  ServiceCenter: require('../images/servicecenter.svg').default,
  Settings: require('../images/settings.svg').default,
  Logout: require('../images/logout.svg').default,
  Affiliate: require('../images/Affiliate.svg').default,
  RightIco: require('../images/RightIco.svg').default,
  GbnLogo: require('../images/gbn-one.png'),
  GbnLogoWhite: require('../images/gbn-one-white.png'),
  RightAerrow: require('../images/Vector.svg').default,
  rightArrowWhite: require('../images/right-arrow-white.svg').default,
  BlueCard: require('../images/blue-card.png'),
  CircleLogo: require('../images/gbn-one.svg').default,
  VisitorIco: require('../images/VisitorIco.svg').default,
  OrderIco: require('../images/OrderIco.svg').default,
  RateIco: require('../images/RateIco.svg').default,
  RevenueIco: require('../images/RevenueIco.svg').default,
  VisitorIndicatorIco: require('../images/VisitorIndicatorIco.svg').default,
  OrderIndicatorIco: require('../images/OrderIndicatorIco.svg').default,
  RateIndicatorIco: require('../images/RateIndicatorIco.svg').default,
  RevenueIndicatorIco: require('../images/RevenueIndicatorIco.svg').default,
  InsightIco: require('../images/InsightIco.svg').default,
  ContentIco: require('../images/ContentIco.svg').default,
  CampaignIco: require('../images/CampaignIco.svg').default,
  ActivityIco: require('../images/ActivityIco.svg').default,
  FiveStarIco: require('../images/FiveStarIco.svg').default,
  FollowUser: require('../images/3User.svg').default,
  Creators: require('../images/Creators.svg').default,
  Message: require('../images/Message.svg').default,
  Notification: require('../images/Notification.svg').default,
  SearchIco: require('../images/SearchIco.svg').default,
  DownIco: require('../images/DownIco.svg').default,
  PlusIco: require('../images/PlusIco.svg').default,
  EditIco: require('../images/EditIco.svg').default,
  AddIco: require('../images/AddIco.svg').default,
  Plus: require('../images/plus-rounded.svg').default,
  RatingStar: require('../images/rating-start.svg').default,
  CloseIco: require('../images/CloseIco.svg').default,
  upload: require('../images/upload.svg').default,
  Building: require('../images/building.svg').default,
  Redirect: require('../images/redirect.svg').default,
  Desk: require('../images/desk.svg').default,
  Tab: require('../images/tab.svg').default,
  Mob: require('../images/mob.svg').default,
  View: require('../images/view.svg').default,
  Image: require('../images/image.svg').default,
  LeftIco: require('../images/LeftIco.svg').default,
  MenuIcon: require('../images/MenuIcon.svg').default,
  ViewIco: require('../images/ViewIco.svg').default,
  ClockFillIco: require('../images/ClockFillIco.svg').default,
  Likes: require('../images/like-fill.svg').default,
  UnLikes: require('../images/like-empty.svg').default,
  Delete: require('../images/delete.svg').default,
  PodcastPauseIco: require('../images/PodcastPauseIco.svg').default,
  PodcastPlayIco: require('../images/PodcastPlayIco.svg').default,
  UploadShot: require('../images/shots-upload.svg').default,
  Save: require('../images/group-save.svg').default,
  Saved: require('../images/group-saved.svg').default,
  View_Icon: require('../images/View_icon.svg').default,
  DropLink: require('../images/three-dot.svg').default,
  Feed_Like: require('../images/group-like.svg').default,
  Feed_UnLike: require('../images/unlike.svg').default,
  Comment: require('../images/group-comment.svg').default,
  Share: require('../images/group-share.svg').default,
  FillStar: require('../images/fill-star.svg').default,
  EmptyStar: require('../images/EmptyStar.svg').default,
  ClockBlue: require('../images/clock-blue.svg').default,
  Dyson: require('../images/dyson.png'),
  GroupDiscussion: require('../images/Group-discussion.svg').default,
  Comments: require('../images/ls_comments.svg').default,
  IconsMore: require('../images/akar-icons_more-horizontal-fill.svg').default,
  ClockIco: require('../images/ClockIco.svg').default,
  HealthIco: require('../images/HealthIco.svg').default,
  MoreHorizontal: require('../images/MoreHorizontal.svg').default,
  Play: require('../images/play.svg').default,
  plusGiveaway: require('../images/plus-giveaway.svg').default,
  Location: require('../images/Locations.svg').default,
  RemoveLocal: require('../images/close-black.svg').default,
  local_add_img: require('../images/add_a_photo.svg').default,
  UpPath: require('../images/up-path.svg').default,
  DownPath: require('../images/down-trend.svg').default,
  impression: require('../images/visitoeeye.svg').default,
  Clicks: require('../images/clicktarget.svg').default,
  Requests: require('../images/reverseicon.svg').default,
  StatusImg: require('../images/layericon.svg').default,
  local_add_img_All: require('../images/local_add_img.svg').default,
  Add: require('../images/Add.svg').default,
  Visitor: require('../images/visitor.svg').default,
  impressionLocal: require('../images/impression.svg').default,
  ClicksLocal: require('../images/click.svg').default,
  RequestsLocal: require('../images/Requests.svg').default,
  StatusImgLocal: require('../images/status.svg').default,
  Excursions: require('../images/excursions.svg').default,
  ServiceProvider: require('../images/serviceprovider.svg').default,
  Stores: require('../images/stores.svg').default,
  Events: require('../images/events.svg').default,
  Restaurant: require('../images/restaurant.svg').default,
  Hotel: require('../images/hotel.svg').default,
  BackLocal: require('../images/back-btn.svg').default,
  bedroom: require('../images/bedroom_parent.svg').default,
  BathRoom: require('../images/bathtub.svg').default,
  RoomTV: require('../images/tv-room.svg').default,
  Sofa: require('../images/chair.svg').default,
  tagsDiscount: require('../images/local_offer.svg').default,
  Edit: require('../images/edit_icon.svg').default,
  DeleteDiscount: require('../images/delete_icon.svg').default,
  MenuBook: require('../images/menu_book.svg').default,
  Event_Logo: require('../images/local_event_logo.svg').default,
  call: require('../images/call.svg').default,
  messenger: require('../images/messanger.svg').default,
  email: require('../images/email.svg').default,
  emailNew: require('../images/email_new.svg').default,
  usergroup: require('../images/usergroup.svg').default,
  gym: require('../images/gym.svg').default,
  LogoAtoZ: require('../images/azfitlogo.png'),
  AddIcoAdmin: require('../images/Plus-tag.svg').default,
  personality1: require('../images/personality1.png'),
  personality2: require('../images/personality2.png'),
  SustainabilityIco: require('../images/SustainabilityIco.svg').default,
  MovementIco: require('../images/MovementIco.svg').default,
  RelaxationIco: require('../images/RelaxationIco.svg').default,
  Que: require('../images/que.svg').default,
  AerrowUpIco: require('../images/AerrowUpIco.svg').default,
  AboutIco: require('../images/AboutIco.svg').default,
  HomeIco: require('../images/HomeIco.svg').default,
  FeedbackIco: require('../images/FeedbackIco.svg').default,
  ServiceIco: require('../images/ServiceIco.svg').default,
  PortfolioIco: require('../images/PortfolioIco.svg').default,
  JobIco: require('../images/JobIco.svg').default,
  CelenderIco: require('../images/CelenderIco.svg').default,
  LikeGreen: require('../images/like-fill-green.svg').default,
  Video_play: require('../images/video_play.svg').default,
  FeedIco: require('../images/FeedIco.svg').default,
  GiveawayIco: require('../images/GivewayIco.svg').default,
  PollsIco: require('../images/PollsIco.svg').default,
  DiscussionIco: require('../images/DiscussionIco.svg').default,
  AwardsIco: require('../images/AwardsIco.svg').default,
  VideoIco: require('../images/VideoIco.svg').default,
  ShotsIco: require('../images/ShotsIco.svg').default,
  PodcastIco: require('../images/PodcastIco.svg').default,
  StoreIco: require('../images/StoreIco.svg').default,
  CoursesIco: require('../images/CalendarIco.svg').default,
  RecipiesIco: require('../images/RecipiesIco.svg').default,
  MegazinesIco: require('../images/MegazinesIco.svg').default,
  CommunityIco: require('../images/CommunityIco.svg').default,
  ScalooIcon: require('../images/scalco-table.svg').default,
  SpacureIcon: require('../images/spacure-table.svg').default,
  PETZINOIcon: require('../images/petzino-table.svg').default,
  AtoZIcon: require('../images/a-z-fit-table.svg').default,
  TESTIEEIcon: require('../images/testiee-table.svg').default,
  LUXURY11Icon: require('../images/luxury-eleven-table.svg').default,
  GOATHLETEIcon: require('../images/go-athelete-table.svg').default,
  THETASTECLUBIcon: require('../images/the-teste-club-table.svg').default,
  FYERDATESIcon: require('../images/fyredates-table.svg').default,
  CREATORTALENTIcon: require('../images/creator-telents-table.svg').default,
  plusBtn: require('../images/plus.svg').default,
  CourseLike: require('../images/courseslike.svg').default,
  play: require('../images/videoplay.svg').default,
  Celender: require('../images/celender.svg').default,
  Episode: require('../images/episode.svg').default,
  chart: require('../images/bar-graph.svg').default,
  Schwer: require('../images/schwer.svg').default,
  OhneGerate: require('../images/other-create.svg').default,
  collapse: require('../images/discription-collapse.svg').default,
  Eye: require('../images/eye.svg').default,
  Clock: require('../images/clock.svg').default,
  TitleVector: require('../images/tilteVector.svg').default,
  Videoicon: require('../images/Video icon.svg').default,
  VideoPause: require('../images/videopause.svg').default,
  Videomute: require('../images/videomute.svg').default,
  VideoVolume: require('../images/videovolume.svg').default,
  LikeBtn: require('../images/like-btns.svg').default,
  ShareBtn: require('../images/share-btn.svg').default,
  Follow: require('../images/Follow.svg').default,
  PlusTag: require('../images/Plus-tag-blue.svg').default,
  Chief: require('../images/Chef.svg').default,
  CookingTime: require('../images/cooking-time.svg').default,
  Servings: require('../images/serving.svg').default,
  Healthy: require('../images/healthy.svg').default,
  Calories: require('../images/calories.svg').default,
  Vegitarian: require('../images/vegitarien.svg').default,
  MailIco: require('../images/notification-activity.svg').default,
  Belle: require('../images/belle.png'),
  FamilyTrendsIco: require('../images/family-trends-table.svg').default,
  FiliyoIco: require('../images/filiyo-table.svg').default,
  Portfolioimg: require('../images/portfolio1.png'),
  HandIco: require('../images/HandIco.svg').default,
  Book: require('../images/book.svg').default,
  LocationLight: require('../images/BoldLocation.svg').default,
  Mail: require('../images/Mail.svg').default,
  Phone: require('../images/Phone.svg').default,
  Company: require('../images/company.svg').default,
  Card: require('../images/card.svg').default,
  briefcase: require('../images/briefcase.svg').default,
  GuestListIco: require('../images/GuestListIco.svg').default,
  VipTicketIco: require('../images/VipTicketIco.svg').default,
  PremiumTicketIco: require('../images/PremiumTicketIco.svg').default,
  StandardTicketIco: require('../images/StandardTicketIco.svg').default,
  LightTicketIco: require('../images/LightTicketIco.svg').default,
  Tickets: require('../images/tickets.svg').default,
  TicketIco: require('../images/TicketIco.svg').default,
  ShowIco: require('../images/ShowIco.svg').default,
  shover: require('../images/shower-linier.svg').default,
  Wifi: require('../images/wifi-linier.svg').default,
  Cigarette: require('../images/air-conditioner.svg').default,
  RoomService: require('../images/room-service-linear.svg').default,
  Iron: require('../images/iron-linier.svg').default,
  Library: require('../images/locker-room.svg').default,
  BookingIco: require('../images/BookingIco.svg').default,
  CoursnsIcon: require('../images/CoursesIco.svg').default,
  Health: require('../images/health.svg').default,
  ReadMore: require('../images/Read more.svg').default,
  time: require('../images/time-primary.svg').default,
  service: require('../images/service-primary.svg').default,
  vegitarian: require('../images/vegiterian-primary.svg').default,
  VideoPlayIco: require('../images/VideoPlayIco.svg').default,
  DonationIco: require('../images/DonationIco.svg').default,
  RoomIco: require('../images/RoomIco.svg').default,
  OfferIco: require('../images/OfferIco.svg').default,
  Parking: require('../images/parking.svg').default,
  Tv: require('../images/television.svg').default,
  LibraryIco: require('../images/library.svg').default,
  Breakfast: require('../images/breakfast.svg').default,
  Bedroom1: require('../images/bedroom.svg').default,
  Bathroom: require('../images/bathroom.svg').default,
  LocalIco: require('../images/LocalIco.svg').default,
  FreeWifi: require('../images/wifi.svg').default,
  Shower: require('../images/shower.svg').default,
  Towel: require('../images/towel.svg').default,
  Iron1: require('../images/iron.svg').default,
  Tv1: require('../images/tv.svg').default,
  GreenTick: require('../images/green-tick.svg').default,
  ViewProfile: require('../images/3Friends.svg').default,
  EntertainmentIco: require('../images/EntertainmentIco.svg').default,
  AddTicketImg: require('../images/ticketcard.png'),
  EventIco: require('../images/EventIco.svg').default,
  DealIco: require('../images/DealIco.svg').default,
  BookTableIco: require('../images/BookTableIco.svg').default,
  MenuIco: require('../images/MenuIco.svg').default,
  VectorIco: require('../images/VectorIco.svg').default,
  ProfileIco: require('../images/ProfileIco.svg').default,
  ProgressIco: require('../images/progressico.svg').default,
  CompletedIco: require('../images/CompletedIco.svg').default,
  FollowIco: require('../images/FollowIco.svg').default,
  LogoIcon: require('../images/logo_icon.png'),

  Waving_hand: require('../images/Waving_hand.png'),
  App_icons: require('../images/App_icons.svg').default,
  Withe_bg_logo: require('../images/Withe_bg_logo.png'),
  blue_bg_logo: require('../images/blue_bg_logo.svg').default,
  lock_icon: require('../images/lock_icon_new.svg').default,
  input_logo: require('../images/input_logo.svg').default,
  Member_icon: require('../images/Member_icon.svg').default,
  Creator_icon: require('../images/Creator_icon.svg').default,
  Business_icon: require('../images/Business_icon.svg').default,
  play_pink: require('../images/play-pink.svg').default,
  play_blue: require('../images/play-blue.svg').default,
  play_dark_blue: require('../images/play-dark-blue.svg').default,
  memberLogo: require('../images/member-logo.svg').default,
  creatorLogo: require('../images/creator-logo.svg').default,
  businessLogo: require('../images/business-logo.svg').default,
  checkedRoundDarkBlue: require('../images/checked-round-dark-blue.svg').default,
  arrowRight: require('../images/arrow-right.svg').default,
  typeSelectoreBg: require('../images/type_selectore_bg.svg').default,

  Black_back: require('../images/black_back.svg').default,
  Verify_icon: require('../images/Verifyed_icon.svg').default,
  Info_icon: require('../images/Info_icon.svg').default,
  dummy_avatar: require('../images/dummy_avtar.svg').default,
  left_white_aero: require('../images/left_white_aero.svg').default,
  creator_side_image: require('../images/creator_side_image.png'),
  creator_slider_Image1: require('../images/creator_slider_Image1.png'),
  creator_slider_Image2: require('../images/creator_slider_Image2.png'),
  creator_slider_Image3: require('../images/creator_side_image.png'),
  creator_slide_bg: require('../images/creator_slide_bg.svg').default,
  Business_slider_bg: require('../images/Business_slider_bg.svg').default,
  Business_slider_Image1: require('../images/Business_slider_Image1.png'),
  Member_slider_bg: require('../images/Member_slider_bg.svg').default,
  Member_slider_Image1: require('../images/Member_slider_Image1.png'),
  Member_slider_Image2: require('../images/Member_slider_Image2.png'),

  LuxuryElevenBlue: require('../images/LuxuryElevenBlue.svg').default,
  SpacureBlue: require('../images/SpacureBlue.svg').default,
  PetzinoBlue: require('../images/PetzinoBlue.svg').default,
  TestieeBlue: require('../images/TestieeBlue.svg').default,
  TheTasteClub: require('../images/TheTasteClub.svg').default,
  GoAthleteBlue: require('../images/GoAthleteBlue.svg').default,
  FamilyTrendsBlue: require('../images/FamilyTrendsBlue.svg').default,
  ScalcoBlue: require('../images/ScalcoBlue.svg').default,
  FiliyoBlue: require('../images/FiliyoBlue.svg').default,
  FyerdatesBlue: require('../images/FyerdatesBlue.svg').default,
  AZFitBlue: require('../images/AZFitBlue.svg').default,
  CreatorTalentsBlue: require('../images/CreatorTalentsBlue.svg').default,

  ButtonAerrow: require('../images/ButtonAerrow.svg').default,

  MemberCreatorBussiness: require('../images/MemberCreatorBussiness.svg').default,
  DeinerApps: require('../images/DeinerApps.svg').default,
  JederApp: require('../images/JederApp.png'),

  Username: require('../images/Username.png'),
  Password: require('../images/Password.png'),
  Verifying: require('../images/Verifying.png'),
  Abo: require('../images/Abo.svg').default,

  PointsLayout: require('../images/PointsLayout.png'),

  LiteMember: require('../images/LiteMember.png'),
  BlueMember: require('../images/BlueMember.png'),
  PremiumMember: require('../images/PremiumMember.png'),
  PlatinumMember: require('../images/PlatinumMember.png'),

  WalletAs: require('../images/WalletAs.png'),

  CreatorBlock: require('../images/CreatorBlock.svg').default,
  BussinessBlock: require('../images/BussinessBlock.svg').default,

  creator: require('../images/creator.svg').default,
  BussinessIco: require('../images/BussinessIco.svg').default,

  AppWorldOne: require('../images/AppWorldOne.svg').default,

  Switzerland: require('../images/Switzerland.svg').default,
  Germany: require('../images/Germany.svg').default,
  Usa: require('../images/Usa.svg').default,

  AppWorldOneApps: require('../images/AppWorldOneApps.svg').default,
  ServiceNetwork: require('../images/ServiceNetwork.svg').default,

  walletBg: require('../images/wallet-bg.png'),
  premiumMemberCard: require('../images/PremiumMember.png'),
  walletPoint: require('../images/wallet-point.png'),
  arrow_left_black: require('../images/arrow_left_black.svg').default,
  Play_Blue_Icon: require('../images/Play_Blue_Icon.svg').default,
  Close_Icon_New: require('../images/Close_Icon_New.svg').default,
  Add_ons_laptop: require('../images/add_ons_laptop.png'),
  IdCard_image: require('../images/IdCard_image.svg').default,
  Phone_image: require('../images/Phone_image.svg').default,
  Social_media_image: require('../images/Social_media_image.svg').default,
  AtoZ_Icon: require('../images/AtoZ_Icon.svg').default,
  scaloo_icon: require('../images/scaloo_icon.svg').default,
  Spacure_icon: require('../images/Spacure_icon.svg').default,

  growthWrapper: require('../images/growthWrapper.png'),

  ContentProduction: require('../images/ContentProduction.png'),
  Friends_Icon: require('../images/Friends_Icon.svg').default,
  Friend_image1: require('../images/Friend_image1.png'),
  Friend_image2: require('../images/Friend_image2.png'),
  Friend_image3: require('../images/Friend_image3.png'),
  FriendProfile: require('../images/FriendProfile.svg').default,
  ProfileBorderImage: require('../images/ProfileBorderImage.svg').default,
  GreenTickRound: require('../images/GreenTickRound.svg').default,
  BlueStartBage: require('../images/BlueStartBage.svg').default,
  ThreeBlueIcon: require('../images/ThreeBlueIcon.svg').default,
  profileStarIcon: require('../images/profileStarIcon.svg').default,
  BlueUpArrow: require('../images/BlueUpArrow.svg').default,
  LiteCreatorCard: require('../images/LiteCreatorCard.png'),
  RunningJobs_Icon: require('../images/RunningJobs_Icon.svg').default,
  OpenJobs_Icon: require('../images/OpenJobs_Icon.svg').default,
  NewJobs_Icon: require('../images/NewJobs_Icon.svg').default,
  CloseJobs_Icon: require('../images/CloseJobs_Icon.svg').default,
  timer_icon: require('../images/timer_icon.svg').default,
  Inspirations_image_1: require('../images/Inspirations_image_1.png'),
  Inspirations_image_2: require('../images/Inspirations_image_2.png'),
  Inspirations_image_3: require('../images/Inspirations_image_3.png'),
  AddtoCaleder_image: require('../images/AddtoCaleder_image.svg').default,
  watch_round_arrow: require('../images/watch_round_arrow.svg').default,
  Dating_icon: require('../images/Dating_icon.svg').default,
  Sports_icon: require('../images/Sports_icon.svg').default,
  Luxry_icon: require('../images/Luxry_icon.svg').default,
  Relaxing_icon: require('../images/Relaxing_icon.svg').default,
  Fashion_icon: require('../images/Fashion_icon.svg').default,
  Food_icon: require('../images/Food_icon.svg').default,
  Business_iconI: require('../images/Business_iconI.svg').default,
  Travel_icon: require('../images/Travel_icon.svg').default,

  SlideScreen2: require('../images/SlideScreen2.svg').default,
  SlideScreen1: require('../images/SlideScreen1.svg').default,
  SlideScreen3: require('../images/SlideScreen3.svg').default,

  ImageVerticalBlock: require('../images/ImageVerticalBlock.svg').default,
  ProfileBuilder: require('../images/ProfileBuilder.svg').default,
  smart_hub_icon: require('../images/smart_hub_icon.svg').default,
  SmartHub_image1: require('../images/SmartHub_image1.png'),
  SmartHub_image2: require('../images/SmartHub_image2.png'),
  SmartHub_image3: require('../images/SmartHub_image3.png'),
  ContentManagerNew: require('../images/ContentManagerNew.svg').default,
  MyStoreNew: require('../images/mystorenew.svg').default,
  localNew: require('../images/localnew.svg').default,
  ProfileNew: require('../images/profileNew.svg').default,
  HomeNew: require('../images/homeNew.svg').default,
  File_Icon: require('../images/file_icon.svg').default,
  media_business_icon: require('../images/media_business_icon.svg').default,
  Recruiting_icon: require('../images/Recruiting_icon.svg').default,
  Crm_icon: require('../images/Crm_icon.svg').default,
  profileBuilder_icon: require('../images/profileBuilder_icon.svg').default,
  arrow_left_white: require('../images/arrow_left_white.svg').default,
  info_blue_icon: require('../images/info_blue_icon.svg').default,
  down_arrow_icon: require('../images/down_arrow_icon.svg').default
}
export default Images
