import React, { useState } from 'react'
import Dropdown from '../Dropdown/Dropdown'
import Images from '../../helpers/Images'

const Store = () => {
  const [visibleItems, setVisibleItems] = useState(8)

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4)
  }
  const items = [
    {
      img: './images/products/10.jpg',
      rating: '4.9',
      name: 'Lorem ipsum dolor sit amet, consectetur ',
      price: '$25.00'
    },
    {
      img: './images/products/11.jpg',
      rating: '4.9',
      name: 'Lorem ipsum dolor sit amet, consectetur ',
      price: '$25.00'
    },
    {
      img: './images/products/12.jpg',
      rating: '4.9',
      name: 'Lorem ipsum dolor sit amet, consectetur ',
      price: '$25.00'
    },
    {
      img: './images/products/10.jpg',
      rating: '4.9',
      name: 'Lorem ipsum dolor sit amet, consectetur ',
      price: '$25.00'
    }
  ]
  const itemsStore = [
    {
      img: './images/products/5.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$280.00'
    },
    {
      img: './images/products/6.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$250.00'
    },
    {
      img: './images/products/7.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$180.00'
    },
    {
      img: './images/products/8.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$120.00'
    },
    {
      img: './images/products/9.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$280.00'
    },
    {
      img: './images/products/5.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$250.00'
    },
    {
      img: './images/products/6.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$180.00'
    },
    {
      img: './images/products/7.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$120.00'
    },
    {
      img: './images/products/8.jpg',
      rating: '4.9',
      name: 'A Series by Samsung',
      price: '$280.00'
    },
    {
      img: './images/products/9.jpg',
      rating: '4.9',
      name: 'Samsung S21 FE 5G',
      price: '$250.00'
    },
    {
      img: './images/products/5.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Pro 360 Notebook',
      price: '$180.00'
    },
    {
      img: './images/products/6.jpg',
      rating: '4.9',
      name: 'Samsung Galaxy Tab S6 with a S Pen',
      price: '$120.00'
    }
  ]
  return (
    <>
      <div className="recent-reviews common-card-body recent-video-component mt-40 mb-40">
        <div className="d-flex align-items-center justify-content-between">
          <h3>Recent Reviews</h3>
          <div className="drop-search">
            <input placeholder="Search here..." />
          </div>
        </div>
        <div className="drop-row">
          <div className="drop-sub-row">
            <Dropdown
              title="Categories"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
            <Dropdown
              title="Ratings"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
            <Dropdown
              title="Duration"
              Data={[
                { title: 'Scaloo' },
                { title: 'Spacure' },
                { title: 'PETZINO' },
                { title: 'A-Z FIT' },
                { title: 'TESTIEE' }
              ]}
            />
          </div>
        </div>
        <div className="store-container mt-30">
          {itemsStore.slice(0, visibleItems).map((item) => (
            <div className="product-grid-item" key={item}>
              <div
                className="product-item-cover"
                style={{
                  background: `url(${item.img})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="like">
                  <img alt="" src={Images.Likes} />
                </div>
                <div className="rating">
                  <img alt="" src={Images.RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="product-item-details d-flex  justify-content-between">
                <span>{item.name}</span>
                <div>
                  <h4>{item.price}</h4>
                  <h4 className="actual-price">{item.price}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
        {itemsStore.length > visibleItems && (
          <div className="collapse-button text-center mt-3">
            <button type="button" onClick={showMoreItems}>
              <img alt="" src={Images.DownIco} />
            </button>
          </div>
        )}
      </div>
      <div className=" services-container menu-wrapper common-card-body recent-video-component">
        <h3 className="d-flex align-items-center justify-content-between">
          Best Ratings
        </h3>
        <div className="product-grid--item">
          {items.map((item) => (
            <div className="product-grid-item" key={item}>
              <div
                className="product-item-cover"
                style={{
                  background: `url(${item.img})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="rating">
                  <img alt="" src={Images.RatingStar} />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="product-item-details d-flex  justify-content-between">
                <span>{item.name}</span>
                <h4>{item.price}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default Store
