import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import English from '../../helpers/English'

const CommonDatePicker = ({
    inputFields,
    setInputFields,
    fieldName,
    placeholder = English.G57,
    minDate = new Date(),
    maxDate = null,
    months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    popperPlacement = null
  }) => {
    const datePickerRef = useRef(null)

    const handleDateChange = (date) => {
      const today = new Date()
      const minAllowedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())

      if (date >= minAllowedDate) {
        setInputFields({
          ...inputFields,
          [fieldName]: moment(date).format('YYYY-MM-DD')
        })
      } else {
        setInputFields({
          ...inputFields,
          [fieldName]: null
        })

        if (datePickerRef.current) {
          datePickerRef.current.setOpen(true)
        }
      }
    }

    const today = new Date()
    const minYear = today.getFullYear()
    const maxYear = maxDate ? maxDate.getFullYear() : minYear + 10

    const years = []
    for (let year = minYear; year <= maxYear; year++) {
      years.push(year)
    }

    return (
      <div>
        <div className="calendar">
          <DatePicker
            ref={datePickerRef}
            selected={inputFields[fieldName] ? new Date(inputFields[fieldName]) : null}
            required
            showMonthDropdown
            autoComplete="off"
            showYearDropdown
            popperPlacement={popperPlacement || 'auto'}
            onChange={handleDateChange}
            name={fieldName}
            dateFormat="yyyy-MM-dd"
            placeholderText={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            onKeyDown={(e) => e.preventDefault()}
            className="custom-datepicker"
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <div className="datepicker-header">
                <button
                  type="button"
                  className="datepicker-button"
                  onClick={(e) => {
                    e.preventDefault()
                    decreaseMonth()
                  }}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  className="datepicker-select"
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(Number(value))}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  className="datepicker-select"
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) => {
                    changeMonth(months.indexOf(value))
                  }}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="datepicker-button"
                  onClick={(e) => {
                    e.preventDefault()
                    increaseMonth()
                  }}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
          />
        </div>
      </div>
    )
  }

  export default CommonDatePicker
