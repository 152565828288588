import React, { useState } from 'react'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const LocalSettings = () => {
  const [isHidden, setIsHidden] = useState(true)

  const handleButtonClick = () => {
    setIsHidden(false)
  }
  const handleButtonClickHide = () => {
    setIsHidden(true)
  }

  return (
    <div className="settings-overview">
      <div className="item-wrapper">
        <h2>Content Manager Settings</h2>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Find a Location Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all orders by week, month and Year</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleLocation">
                <input id="titleLocation" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show all Clients by Week,month and year </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesLocation">
                <input id="categroiesLocation" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Restaurants Menu Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Recipie name </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titleRestaurants">
                <input id="titleRestaurants" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Price </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroiesRestaurants">
                <input id="categroiesRestaurants" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show takein</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionRestaurants">
                <input id="descriptionRestaurants" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show takeout</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredRestaurants">
                <input id="featuredRestaurants" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Hotel Settings</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show hotel name</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="titlehotel">
                <input id="titlehotel" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Hotel rooms pictures</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="categroieshotel">
                <input id="categroieshotel" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Ratings </p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="descriptionhotel">
                <input id="descriptionhotel" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Room Prices</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="featuredhotel">
                <input id="featuredhotel" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show aminities of room</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="Pricehotel">
                <input id="Pricehotel" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
          <div className="checkbox-item">
            <div className="checkbox-data">
              <p>Show Description</p>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                {' '}
              </span>
            </div>
            <div className="custum-toggle">
              <label className="switch" htmlFor="quantityhotel">
                <input id="quantityhotel" type="checkbox" />
                <div className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="content-manager-item">
          <div className="add-more">
            <h3>Aminities</h3>
            <button type="button" className="primary-btn" onClick={handleButtonClick}>
              <img alt="" src={Images.PlusIco} />
              Add more
            </button>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
            scelerisque sed facilisis orci turpis urna et nisl consequat. Leo
            pulvinar diam fringilla id dictumst cursus. In tincidunt faucibus
            congue amet, ut volutpat eu.
            {' '}
          </p>
          <div className="common-select-option">
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Shower</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Free Wifi</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Luggage</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>24/7 Room Service</label>
            </div>
            <div className="d-flex align-items-center check-box-option">
              <input type="checkbox" />
              <label>Safe</label>
            </div>
          </div>
        </div>
      </div>
      {!isHidden && (
        <div className="add-more-settings-model">
          <div className="model-content">
            <div className="model-header">
              <h4>Add more video setting</h4>
              <button type="button" onClick={handleButtonClickHide}>
                <img className="close-btns" alt="" src={Images.CloseIco} />
              </button>
            </div>
            <div className="model-filled">
              <label>Option name</label>
              <input placeholder="Name here" />
              <label>{English.G112}</label>
              <textarea placeholder="Type here..." />
            </div>
            <div className="model-footer">
              <button type="button" className="save-button">Add option</button>
              <button type="button" className="cancel-button" onClick={handleButtonClickHide}>
                <span>{English.G116}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default LocalSettings
