import React, { useEffect, useRef, useState } from 'react'
import '../AddAwards/AddAwards.css'
import './style.css'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const AddDeals = () => {
  const [ShowDealModel, setShowDealModel] = useState(false)
  const AddDeal = () => {
    setShowDealModel(true)
  }
  const HandleCancel = () => {
    setShowDealModel(false)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowDealModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
          <h2>Deals</h2>
        </div>
        <div className="awards-row">
          <div className="add-awards-item add-deal-item">
            <div className="add-awards-cover add-value">
              <button type="button" className="add-item-btn" onClick={AddDeal}>
                <img alt="" src={Images.Plus} />
              </button>
            </div>
            <div className="add-awards-detail d-flex align-items-center justify-content-between">
              <div className="w-50 d-flex flex-column row-gap-2">
                <h4 className="add-value m-0 w-50">.</h4>
                <p className="m-0 add-value w-100" />
              </div>
              <div className="add-awars-other-detail d-flex flex-column row-gap-2 w-50 align-items-end">
                <span className="add-value w-80" />
                <span className="add-value w-80" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {ShowDealModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="item-details-input pt-0">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    type="file"
                    accept="video/*"
                    className="d-none"
                  />
                  <div className="upload-img">
                    <img alt="" src={Images.upload} />
                  </div>
                  <h4>{English.G458}</h4>
                  <p>
                    {English.G156}
                  </p>
                  <button
                    type="button"
                    htmlFor="upload-file"
                    onClick={() => document.getElementById('upload-file').click()}
                  >
                    <span>{English.G216}</span>
                  </button>
                </label>
              </div>
              <div>
                <label>Hotel Name</label>
                <input placeholder="Enter hotel name" />
              </div>
              <div>
                <label>{English.G131}</label>
                <input placeholder="$" />
              </div>
              <div>
                <label>Discounted Price</label>
                <input placeholder="$" />
              </div>
              <div className="location-input">
                <label>{English.G255}</label>
                <input placeholder="Enter location" />
              </div>
              <div className="button-row">
                <button type="button" className="secondary-btn-model" onClick={HandleCancel}>
                  <span>{English.G116}</span>
                </button>
                <button type="button" className="secondary-btn-model">{English.G152}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddDeals
