/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ProgressBar from '@ramonak/react-progress-bar'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  s3ImageProfile,
  urlSystemIcon
} from '../../services/Service'
import Pagination from '../Pagination/Pagination'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const PollsDetails = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const navigate = useNavigate()
  const componentRef = useRef()
  const [voteList, setVoteList] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pollsDetail, setPollsDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(null)

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 2 } })
  }

  const getPollLibraryData = () => {
    setIsLoading(true)
    APICall(
      'post',
      {},
      `${EndPoints.getPollsLibrary}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPollsDetail(res?.decryptedData.data)
          // const { data } = dataDecrypt(res?.data?.data)
          // if (data.length !== 0) {
          //   const poll_id = data.map((poll) => poll.poll_id)
          //   voterList(poll_id)
          // }
          // data.forEach((poll) => {
          //   voterList(poll.poll_id, poll)
          // })
          // if (data?.length !== 0) {
          //   voterList(data[0].poll_id)
          // }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const voterList = async (pollIds) => {
    const platFormData = {
      app_id: app_id?.id,
      poll_ids: pollIds
    }
    APICall('post', platFormData, `${EndPoints.voterList}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVoteList(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const voteListData = voteList.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = voteList?.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const showList = (poll_id) => {
    voterList(poll_id)
    setShowProfileModel(true)
  }
  const close = () => {
    setShowProfileModel(false)
  }

  useEffect(() => {
    getPollLibraryData()
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowProfileModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (ShowProfileModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel])

  return (
    <div className="content-manager-Polls-wrapper common-card-body community-margin">
      <div className="polls-wrapper-header common-contnt-wrapper">
        <h2>{English.G195}</h2>
        <div className="" onClick={viewAll}>
          <Link>{English.G248}</Link>
        </div>
      </div>
      {pollsDetail?.length === 0 && !isLoading ? (
        <div className="add-polls-container mb-3">
          <div className="add-polls-item common-card-body">
            <div className="position-relative polls-item-head">
              <h6 className="add-value font-hidden">Heading 2</h6>
              <button type="button" className="add-item-btn" onClick={viewAll}>
                <img src={Images.Plus} alt="add poll" />
              </button>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 1</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 1</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 2</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 2</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 3</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 3</h5>
            </div>
            <div className="polls-item d-flex align-items-center justify-content-between">
              <div className="polls-item-txt add-value">
                <p className="font-hidden">Poll Item Text 4</p>
              </div>
              <h5 className="add-value font-hidden">Poll Item 4</h5>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="polls-item common-card-body position-relative">
          <div className="">
            <Skeleton width={50} height={25} />
          </div>
          <div className="poll-header">
            <p className="poll_topic">
              <Skeleton width={600} height={30} />
            </p>
            <div className="discussion-group">
              <Skeleton width={25} height={25} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>

          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
        </div>
      ) : (
        pollsDetail?.slice(0, 1)?.map((item, index) => {
          const totalVotes = item.options.reduce(
            (acc, option) => acc + option.total_votes,
            0
          )

          const optionPercentages = item.options.map((option) => {
            return totalVotes > 0
              ? ((option.total_votes / totalVotes) * 100).toFixed(2)
              : '0.00'
          })
          return (
            <div
              className="polls-item common-card-body position-relative"
              key={item.tag}
            >
              <div className="polls-tag">
                <span>
                  {/* {item.tag} */}
                  Web Design
                </span>
              </div>
              <div className="poll-header">
                <p className="poll_topic">
                  {expandedIndex === index
                    ? item?.topic
                    : `${item?.topic?.slice(0, 130)}...`}
                  {item?.topic?.length > 130 && (
                    <span
                      className="see-more"
                      onClick={(e) => {
                        e.stopPropagation()
                        setExpandedIndex(
                          expandedIndex === index ? null : index
                        )
                      }}
                    >
                      {expandedIndex === index ? English.G145 : English.G146}
                    </span>
                  )}
                </p>
                <div className="discussion-group">
                  {item?.total_votes > 0 &&
                    voteList
                      ?.slice(0, 3)
                      ?.map((vote, index) => (
                        <img
                          key={index}
                          src={s3ImageProfile + vote.profile_icon}
                          alt={vote.user_name}
                        />
                      ))}
                  <img
                    src={Images.GroupDiscussion}
                    alt=""
                    onClick={() => {
                      if (item.total_votes > 0) {
                        showList(item?.poll_id)
                      }
                    }}
                  />
                  {ShowProfileModel && (
                    <div className="add-item-model edit-profile">
                      <div className="add-item-content" ref={componentRef}>
                        <div className="d-flex justify-content-between header">
                          <h3>{English.G197}</h3>
                          <button
                            type="button"
                            className="border-0 bg-none p-0"
                            onClick={close}
                          >
                            <span>
                              <img
                                className="close-btns"
                                src={Images.CloseIco}
                                alt=""
                              />
                            </span>
                          </button>
                        </div>

                        <div className="scroll-bars">
                          <div className="item-details-input pt-0">
                            <div className="list_data">
                              {voteListData?.map((list, index) => {
                                const calculatedIndex =
                                  (currentPage - 1) * videosPerPage + index + 1
                                return (
                                  <div
                                    className="d-flex justify-content-start list_table"
                                    key={index}
                                  >
                                    <span className="number_index">
                                      {calculatedIndex}
                                    </span>
                                    <img
                                      src={s3ImageProfile + list.profile_icon}
                                      className="profile-pic"
                                      alt="profile"
                                    />
                                    <span className="user-name">
                                      {list.user_name}
                                    </span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        {totalPages > 1 && (
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="discussion-comment">
                  <img src={Images.Comments} alt="" />
                  {item.total_votes}
                </div>
                <div className="discussion-button">
                  <button type="button">
                    <img src={Images.IconsMore} alt="" />
                  </button>
                </div>
              </div>

              {item.options
                ?.filter((option) => option !== '')
                ?.map((option, optIndex) => (
                  <div className="polls-option" key={optIndex}>
                    <ProgressBar
                      customLabel={`${option.option}`}
                      className="profress-line"
                      baseBgColor="linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)"
                      height={43}
                      completed={optionPercentages[optIndex]}
                      borderRadius={15}
                      bgColor={
                        item.total_votes > 0
                          ? 'linear-gradient(134deg, rgba(0, 21, 151, 1) 3.03%, rgba(1, 16, 77, 1) 47.1%, rgba(2, 12, 28, 1) 80.14%, rgba(2, 11, 9, 1) 96.45%)'
                          : 'linear-gradient(134deg, rgba(0, 21, 151, 0.1) 3.03%, rgba(1, 16, 77, 0.1) 47.1%, rgba(2, 12, 28, 0.1) 80.14%, rgba(2, 11, 9, 0.1) 96.45%)'
                      }
                      isLabelVisible={false}
                      labelClassName={
                        option.is_vote === true
                          ? 'active_label'
                          : 'polls-label'
                      }
                    />

                    <div className="polls-group">
                      <div className="discussion-group">
                        {item?.total_votes > 0 &&
                          voteList
                            ?.filter((vote) => vote.option === optIndex + 1)
                            ?.slice(0, 3)
                            ?.map((vote, index) => (
                              <img
                                key={index}
                                src={s3ImageProfile + vote.profile_icon}
                                alt={vote.user_name}
                              />
                            ))}
                        <img
                          src={Images.GroupDiscussion}
                          alt="Group Discussion"
                        />
                      </div>
                      {item[`option_${optIndex + 1}_vote`]}
                      {' '}
                      {English.G198}
                    </div>
                  </div>
                ))}
              {item?.app_data?.length > 0 && (
                <span
                  className={`platform-badge ${
                    item?.app_data?.length === 2 ? 'platform-badge-alt' : ''
                  }`}
                >
                  {item?.app_data?.map((data, i) => (
                    <div
                      key={i}
                      className={`badge-cover ${
                        item?.app_data?.length === 2 ? 'badge-cover-alt' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '90%',
                        backgroundPosition: 'center'
                      }}
                    />
                  ))}
                </span>
              )}
            </div>
          )
        })
      )}
    </div>
  )
}

export default PollsDetails
