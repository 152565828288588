import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { useSelector } from 'react-redux'
import {
  locationSuggestion,
  s3ImagePostFeed
} from '../../services/Service'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { compressImage } from '../../services/http-services'
import English from '../../helpers/English'
import { Images } from '../../helpers'
import { APICall, EndPoints } from '../../services'

const AddPhotoModal = ({
  ShowVideoModel,
  setShowVideoModel,
  onCompletd,
  setImages,
  images,
  editDate
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [isFormModified, setIsFormModified] = useState(false)
  const [inputFields, setInputFields] = useState(
    ShowVideoModel.isEditing
      ? editDate
      : {
          attachment: '',
          description: '',
          app_id: app_id.id,
          location: ''
        }
  )
  const [imageObj, setImageObj] = useState()
  const [loader, setLoader] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current = inputFields
  }, [inputFields])

  const HandleCancel = () => {
    setShowVideoModel({ show: false, isEditing: false })
    ResetValue()
  }

  const ResetValue = () => {
    setInputFields({
      attachment: '',
      description: '',
      location: '',
      app_id: app_id.id
    })
    setImages('')
    setIsFormModified(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (isSaveButtonDisabled) return

    setLoader(true)
    const formData = new FormData()
    formData.append('file', imageObj)
    formData.append('type', 'post')

    if (inputFields.post_id) {
      EditPostData(inputFields, inputFields.post_id)
    } else {
      const compressedImage = await compressImage(imageObj)
      formData.set('file', compressedImage)
      APICall('post', formData, `${EndPoints.uploadImage}`, 'creator', true)
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            inputFields.attachment = res?.decryptedData
            APICall('post', inputFields, `${EndPoints.addPost}`, 'creator')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  onCompletd()
                  toast.success(res?.data?.message)
                  setShowVideoModel({ show: false, isEditing: false })
                  setLoader(false)
                  ResetValue()
                } else {
                  toast.error(res?.data?.message)
                  setLoader(false)
                }
              })
              .catch((error) => {
                setLoader(false)
                toast.error(error?.data?.message)
              })
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    }
  }

  const EditPostData = (image, postId) => {
    setLoader(true)
    APICall('post', image, `${EndPoints.editPost}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setImages(s3ImagePostFeed + image.attachment)
          setInputFields({
            attachment: s3ImagePostFeed + image.attachment,
            description: image.description,
            app_id: app_id.id,
            location: image.location,
            post_id: postId
          })
          toast.success(res?.data?.message)
          setShowVideoModel({ show: false, isEditing: false })
          setLoader(false)
          ResetValue()
          onCompletd()
        } else {
          setLoader(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoader(false)
        toast.error(error?.data?.message)
      })
  }

  const handleImageChange = (file) => {
    const maxFileSize = 8 * 1024 * 1024

    if (file) {
      if (file.size > maxFileSize) {
        toast.error('Image size should not exceed 8 MB')
        return
      }

      const imageUrl = URL.createObjectURL(file)
      setImages(imageUrl)
      setImageObj(file)
      setIsFormModified(true)
      validateFields({ ...inputFields, attachment: file })
    }
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsFormModified(true)
    validateFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const validateFields = (fields) => {
    const { description, location } = fields
    setIsSaveButtonDisabled(!(description && location))
  }

  const [modalHeight, setModalHeight] = useState(0)

  const componentRef = useRef(null)
  const modelheaderRef = useRef(null)
  const modelFooterRef = useRef(null)
  const updateModalHeight = () => {
    if (componentRef.current) {
      setModalHeight(
        componentRef.current.offsetHeight -
          (modelheaderRef.current.offsetHeight +
            modelFooterRef.current.offsetHeight +
            24)
      )
    }
  }

  useEffect(() => {
    updateModalHeight()
    window.addEventListener('resize', updateModalHeight)
    return () => {
      window.removeEventListener('resize', updateModalHeight)
    }
  }, [ShowVideoModel])

  return (
    <div className="add-item-model">
      <div ref={componentRef} className="add-item-content">
        <div
          ref={modelheaderRef}
          className="d-flex justify-content-between model-header header pt-4 mb-4"
        >
          <h3 className="Gallery_header">
            {ShowVideoModel.isEditing ? 'Edit Image' : 'Add Image'}
          </h3>
          <button type="button" onClick={HandleCancel} disabled={loader}>
            <img className="close-btns" src={Images.CloseIco} alt="" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{ maxHeight: `${modalHeight}px` }}
            className="scroll-bar pb-0"
          >
            <div className="item-details-input">
              <div className="upload-input upload-input-with-border">
                <label htmlFor="upload-file" className="mb-0 pb-0">
                  <CommonImageUpload
                    handleImageChange={handleImageChange}
                    index={0}
                    buttonText={English.G154}
                    selectedImg={images}
                    defaultImg=""
                    className="mb-0 pb-0"
                    forImage="add_photo"
                    forName="video"
                  />
                </label>
              </div>

              <div>
                <label>{English.G256}</label>
                <div>
                  <ReactGoogleAutocomplete
                    placeholder={English.G257}
                    required
                    onChange={handleChange}
                    name="location"
                    value={inputFields.location}
                    apiKey={locationSuggestion}
                    onPlaceSelected={(place) => {
                      setInputFields({
                        ...inputRef.current,
                        location: place.formatted_address
                      })
                      setIsFormModified(true)
                    }}
                  />
                </div>
              </div>

              <div>
                <label>{English.G112}</label>
                <CommonInput
                  name="description"
                  required
                  placeholder={English.G258}
                  onChange={handleChange}
                  value={inputFields.description}
                />
              </div>
            </div>
          </div>
          <div ref={modelFooterRef} className="item-details-input">
            <div className="button-row video_sticky_btn add_photo">
              <CommonButton
                label={English.G116}
                type="button"
                onClick={HandleCancel}
                isPrimary={false}
                disabled={loader}
                context={English.G232}
              />
              <CommonButton
                label={English.G117}
                loading={loader}
                type="submit"
                isPrimary
                disabled={isSaveButtonDisabled || !isFormModified || loader}
                context={English.G232}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddPhotoModal
