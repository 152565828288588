/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { s3ImageVideo } from '../../services/Service'
import { timeDifference } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const Video = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [videoData, setVideoData] = useState([])
  const [expandedIndex, setExpandedIndex] = useState(null)

  const viewAll = () => {
    navigate('/add-component', { state: { defaultIndex: 0 } })
  }

  const getVideosLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getVideoLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVideoData(res?.decryptedData.data)
          setIsLoading(false)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const videoLikeData = (video_id) => {
    const platFormData = {
      video_id
    }
    APICall('post', platFormData, `${EndPoints.videoLike}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVideoData(
            videoData.map((item) => (item.video_id === video_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const formatDuration = (duration) => {
    const [hours, minutes, seconds] = duration.split(':').map(Number)

    const totalMinutes = hours * 60 + minutes

    return `${totalMinutes < 10 ? '0' : ''}${totalMinutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  useEffect(() => {
    getVideosLibraryData()
  }, [])

  return (
    <div className="content-manager-video-wrapper common-card-body">
      <div className="video-wrapper-header common-contnt-wrapper">
        <h2>{English.G225}</h2>
        <div className="" onClick={viewAll}>
          <Link>{English.G248}</Link>
        </div>
      </div>
      <div className="video-items">
        {videoData?.length === 0 && !isLoading ? (
          <div className="awards-item-video">
            <div className="add-profile-video-cover">
              <button type="button" onClick={viewAll}>
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="add-profile-video-detail">
              <p />
              <div className="d-flex align-items-center justify-content-between">
                <span />
                <span />
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          videoData?.slice(0, 4)?.map((item, index) => (
            <div className="video-item-detail" key={item.name}>
              <div
                className="video-cover"
                style={{
                  background: `url(${s3ImageVideo + item.cover_attachment})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="video_like_bg">
                  <button
                    type="button"
                    className="video_like"
                    onClick={(e) => {
                      e.stopPropagation()
                      videoLikeData(item.video_id)
                    }}
                  >
                    {item.is_like ? (
                      <img src={Images.Likes} alt="Unlike" />
                    ) : (
                      <img src={Images.UnLikes} alt="Like" />
                    )}
                  </button>
                </div>
                <p className="time-duration">{formatDuration(item.duration)}</p>
              </div>
              <div
                className={`video-details video-1 ${expandedIndex === index ? 'expanded' : ''}`}
              >
                <div className="d-flex justify-content-between">
                  <h6>
                    <img
                      className="health_icon"
                      src={Images.HealthIco}
                      alt=""
                    />
                    <span className="content-video-title">{item.title}</span>
                  </h6>
                  <div className="rating">
                    <img src={Images.RatingStar} alt="" />
                    <span>4.9</span>
                  </div>
                </div>
                <p>
                  {expandedIndex === index
                    ? item?.description
                    : `${item?.description?.slice(0, 45)}`}
                  {item?.description?.length > 45 && (
                    <span
                      className="see-more"
                      onClick={(e) => {
                        e.stopPropagation()
                        setExpandedIndex(
                          expandedIndex === index ? null : index
                        )
                      }}
                    >
                      {expandedIndex === index ? English.G145 : English.G146}
                    </span>
                  )}
                </p>
                <div className="other-info">
                  <span>
                    <img src={Images.ClockIco} alt="" />
                    {timeDifference(item.created_at)}
                  </span>
                  <span>{item.other}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
export default Video
