/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import './AddProduct.css'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { urlImageProductPathStore } from '../../services/Service'
import Loader from '../Loader/Loader'
import { debounce, lockScroll, unlockScroll } from '../../helpers/Utility'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import { emitter, EventType } from '../../helpers/Emitter'
import AddEditProductLibaryModal from './AddEditProductLibaryModal'
import ProductLibaryModal from './ProductLibaryModal'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const AddProductFilter = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [ShowProductsModel, setShowProductsModel] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)
  const [ShowProduct, setShowProduct] = useState(false)
  const [category, setCategory] = useState([])
  const [EditProduct, setEditProduct] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [inputFields, setInputFields] = useState({
    name: '',
    regular_price: '',
    discount_price: 0,
    category_id: '',
    sub_category_id: '',
    product_link: '',
    description: '',
    main_image: '',
    image_1: '',
    image_2: '',
    image_3: '',
    search: '',
    id: ''
  })
  const [generalProduct, setGeneralProduct] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const AddProducts = () => {
    setShowProductsModel(true)
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setShowLibraryConfirmModel(false)
  }
  const componentRef = useRef()

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }

  const MainCategory = () => {
    APICall('post', {}, EndPoints.category, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setCategory(res?.decryptedData)
      } else {
        toast.error(res.data?.message)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
    })
  }

  const [editData, setEditData] = useState(null)
  const EditLibrary = (data) => {
    setEditProduct(true)
    setShowProduct(true)
    setEditData(data)
    // setSelectedImages([data.image])
    // oneProductDetails(data)
  }

  const getGeneral = async (search) => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search: search || ''
    }
    setLoading(true)
    APICall(
      'post',
      platFormData,
      `${EndPoints.getGeneralProduct}?search=${search}`,
      'store'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setLoading(false)
          setGeneralProduct(res?.decryptedData)
          setIsLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsLoading(false)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
        setLoading(false)
      })
  }

  const getFilterData = async (category_id) => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      category_id
    }
    APICall('post', platFormData, EndPoints.getGeneralProduct, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setGeneralProduct(res?.decryptedData)
        setIsLoading(false)
      } else {
        toast.error(res.data?.message)
        setIsLoading(false)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setIsLoading(false)
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getGeneral(inputVal), 500),
    []
  )

  const deleteGeneral = (data) => {
    const platFormData = {
      app_id: app_id.id,
      delete_general_products: [data.id]
    }
    setLoading(true)
    APICall('post', platFormData, EndPoints.deleteGeneralProduct, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setLoading(false)
            getGeneral()
            emitter.emit(EventType.StoreUpdate)
            setShowConfirmModel(false)
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const event = emitter.addListener(
      EventType.ProductLibraryDelete,
      ({ product_id, action }) => {
        if (action === 'delete') {
          const temp = [...generalProduct]
          const newData = temp.filter((item) => item.id !== product_id)
          setGeneralProduct(newData)
        } else {
          getGeneral()
        }
      }
    )
    return () => event.remove()
  }, [generalProduct])

  const mainCategoryInitialized = useRef(false)

  useEffect(() => {
    if (!mainCategoryInitialized.current) {
      mainCategoryInitialized.current = true
      MainCategory()
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getGeneral()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (
      ShowConfirmModel ||
      EditProduct ||
      ShowProductsModel ||
      ShowProduct ||
      ShowLibraryConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowConfirmModel,
    EditProduct,
    ShowProductsModel,
    ShowProduct,
    ShowLibraryConfirmModel
  ])

  return (
    <div className="content-manager-awards-wrapper common-card-body mt-40 add-profile-video">
      <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
        <h2>{English.G139}</h2>
        <div className="items-filter">
          <div className="position-relative input-position">
            <CommonInput
              placeholder="Search Products"
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="drop-row mb-30">
        <div className="drop-sub-row">
          <Dropdown
            title={
              category?.find((it) => it.id === inputFields.category_id)?.name ||
              'Categories'
            }
            onChanged={(d) => {
              if (d.id === 'all') {
                getGeneral()
                setInputFields({
                  ...inputFields,
                  category_id: null
                })
              } else {
                getFilterData(d.id)
                setInputFields({
                  ...inputFields,
                  category_id: d.id
                })
              }
            }}
            Data={[
              ...(inputFields.category_id
                ? [{ title: 'All Product', id: 'all' }]
                : []),
              ...(category || []).map((d) => ({
                title: d.name,
                id: d.id
              }))
            ]}
            customClass="store_dropdown"
          />
          <Dropdown
            title="Ratings"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="disabledDropdown"
          />
          <Dropdown
            title="Duration"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="disabledDropdown"
          />
        </div>
        <div className="add-video-rows">
          <CommonButton
            label={English.G137}
            imgSrc={Images.AddIco}
            onClick={AddProducts}
            isPrimary
            className="add_new_content"
          />
        </div>
      </div>
      <div className="awards-row">
        {generalProduct?.length === 0 && !isLoading ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button" onClick={AddProducts}>
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value" />
              <span className="add-value" />
            </div>
          </div>
        ) : generalProduct?.length === 0 && isLoading ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button">
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value">
                <Skeleton className="add-value" width={202} height={35} />
              </p>
              <span className="add-value">
                <Skeleton className="add-value" width={67} height={35} />
              </span>
            </div>
          </div>
        ) : (
          generalProduct?.map((data, i) => {
            return (
              <div key={i} className="product-grid-item position-relative">
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      className="d-none"
                      onClick={() => EditLibrary(data)}
                    />
                  </label>
                  <button type="button" className="p-0" onClick={ShowConfirm} />
                  {ShowConfirmModel && (
                    <div className="add-item-model">
                      <div
                        className="add-item-contents h-auto w-auto"
                        ref={componentRef}
                      >
                        <div className="item-details-input pt-0">
                          <h3 className="mb-0 text-center">{English.G119}</h3>
                          <div className="button-row justify-content-center">
                            <button
                              type="button"
                              className="secondary-btn-modelConfirm"
                              onClick={HandleCancelConfirm}
                            >
                              <span>{English.G116}</span>
                            </button>
                            <button
                              type="button"
                              className="primary-btn-modelConfirm"
                              onClick={() => {
                                setUpdatingIndex(i)
                                deleteGeneral(data)
                              }}
                            >
                              {English.G120}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {loading && updatingIndex === i && <Loader />}
                <div
                  className="product-item-cover"
                  style={{
                    backgroundImage: `url(${urlImageProductPathStore + data.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="rating">
                    <img src={Images.RatingStar} alt="star" />
                    <span>4.9</span>
                  </div>
                </div>
                <div className="product-item-details d-flex  justify-content-between">
                  <span>{data.name}</span>
                  {/* <span>{data.description}</span> */}
                  <div>
                    {data.discount_price > 0 ? (
                      <>
                        <h4>{data.discount_price}</h4>
                        <h4 className="actual-price">{data.regular_price}</h4>
                      </>
                    ) : (
                      <h4>{data.regular_price}</h4>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
      {ShowProductsModel && (
        <ProductLibaryModal
          ShowProductsModel={ShowProductsModel}
          setShowProductsModel={setShowProductsModel}
          generalProduct={generalProduct}
          productType="general"
          onSubmited={() => {
            getGeneral()
          }}
        />
      )}
      {ShowProduct && (
        <AddEditProductLibaryModal
          isProductEdit={EditProduct}
          setIsProductEdit={setEditProduct}
          setShowProduct={setShowProduct}
          onComplete={() => {
            if (EditProduct) {
              getGeneral()
            }
            setEditProduct(false)
            setEditData(null)
          }}
          editData={editData}
        />
      )}
    </div>
  )
}
export default AddProductFilter
