import React, { useCallback, useEffect, useRef, useState } from 'react'
import './AddAwards.css'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  awardsUnpublishData,
  getAwardsData,
  s3ImagePath,
  s3ImagePathCreator
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const AwardsComponent = () => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const navigate = useNavigate()
  const debounce = (func, wait) => {
    let timeout

    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }

  const [ShowAwardsModel, setShowAwardsModel] = useState(false)
  const [EditAwardsModel, setEditAwardsModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    image: '',
    award_title: '',
    description: ''
  })
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)

  const AddAwards = () => {
    setShowAwardsModel(true)
    navigate('/add-component', { state: { defaultIndex: 5 } })
  }
  const HandleCancel = () => {
    setShowAwardsModel(false)
  }

  const handleAdd = () => {
    setShowAwardsModel(false)
  }
  const EditCancel = () => {
    setEditAwardsModel(false)
  }

  const EditAdd = () => {
    setEditAwardsModel(false)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const EditAward = () => {
    setEditAwardsModel(true)
  }
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowAwardsModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [Awards, setAwards] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const getAwards = (search) => {
    const platFormData = {
      app_id: app_id?.id,
      search
    }
    getAwardsData(platFormData).then((res) => {
      if (res.data.success) {
        setAwards(dataDecrypt(res.data.data).data)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => {
      setSearchTerm(inputVal)
      getAwards(inputVal)
    }, 500),
    [],
  )

  const awardsUnpublishDataApi = (award_id) => {
    setLoading(true)
    const platFormData = {
      app_id: app_id?.id,
      award_id
    }
    awardsUnpublishData(platFormData).then((res) => {
      if (res.data.success) {
        setShowLibraryConfirmModel(false)
        toast.success(res?.data?.message)
        setLoading(false)
        getAwards()
      }
    })
  }

  useEffect(() => {
    getAwards()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const videosPerPage = 10
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const AwardsData = Awards.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = Awards.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const timeDifference = (createdAt) => {
    const createdDate = new Date(createdAt)
    const now = new Date()
    const difference = now - createdDate

    const seconds = Math.floor(difference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (seconds < 60) {
      return `${seconds} seconds ago`
    } if (minutes < 60) {
      return `${minutes} minutes ago`
    } if (hours < 24) {
      return `${hours} hours ago`
    }
      return `${days} days ago`
  }
  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)

  const HandleCancelConfirm = () => {
    setShowLibraryConfirmModel(false)
  }

  const deleteLibraryConfirm = () => {
    setShowLibraryConfirmModel(true)
  }

  return (
    <div className="">
      <div className="recent-video-component recent-shots-component common-card-body add-shots-component">
        <div className="discussions-wrapper-header common-contnt-wrapper mb-40">
          <h2>Awards</h2>
          <div className="items-filter">
            <div className="position-relative input-position">
              <input
                placeholder="Search Award"
                onChange={(e) => {
                  handleSearch(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="content-manager-awards-wrapper">
          <div className="awards-row ">
            {currentPage === 1 && searchTerm === '' && (
              <div className="add-awards-item">
                <div className="add-awards-cover add-value">
                  <button type="button" className="add-item-btn" onClick={AddAwards}>
                    <img src={Images.Plus} alt="add award" />
                  </button>
                </div>
                <div className="add-awards-detail">
                  <h4 className="add-value">{English.G162}</h4>
                  <p className="add-value">{English.G112}</p>
                  <div className="add-awars-other-detail d-flex align-items-center justify-content-between">
                    <span className="add-value">Detail 1</span>
                    <span className="add-value">Detail 2</span>
                  </div>
                </div>
              </div>
            )}
            {AwardsData.map((item, index) => (
              <div
                className="awards-item-awards position-relative"
                key={item.Title}
              >
                <div
                  className="awards-cover"
                  style={{
                    backgroundImage: `url(${
                      s3ImagePathCreator + item.attachment
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="like">
                    <img src={Images.LikeFill} alt="like icon" />
                  </div>
                </div>
                <div className="awards-details">
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                  <div className="awards-other">
                    <div className="awards-profile">
                      <div
                        className="awards-profile-img"
                        style={{
                          background: `url(${
                            s3ImagePath + userData.profile_image
                          })`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                      />
                      {userData.user_name}
                    </div>
                    <span className="time-duration-detail">
                      <img src={Images.ClockIco} alt="clock icon" />
                      {timeDifference(item.created_at)}
                    </span>
                  </div>
                </div>
                <div className="image-buttons-cancel z-1">
                  <label htmlFor="editButton">
                    <button
                      id="editButton"
                      type="button"
                      className="d-none"
                      onClick={EditAward}
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0 close_icon"
                    onClick={deleteLibraryConfirm}
                  />
                  {ShowLibraryConfirmModel && (
                    <div className="add-item-model">
                      <div className="add-item-contents h-auto w-auto">
                        <div className="item-details-input pt-0">
                          <h3 className="mb-0 text-center">
                            Do you want to unpublish this?
                          </h3>
                          <div className="button-row justify-content-center">
                            <button
                              type="button"
                              className="secondary-btn-modelConfirm"
                              onClick={HandleCancelConfirm}
                            >
                              <span>{English.G116}</span>
                            </button>
                            <button
                              type="button"
                              className="primary-btn-modelConfirm"
                              onClick={() => {
                                setUpdatingIndex(index)
                                awardsUnpublishDataApi(item.award_id)
                              }}
                            >
                              {English.G120}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {loading && updatingIndex === index && <Loader />}
                {EditAwardsModel && (
                  <div className="add-item-model">
                    <div className="add-item-content" ref={componentRef}>
                      <form onSubmit={handleSubmit}>
                        <div className="item-details-input pt-0">
                          <div className="upload-input upload-input-with-border">
                            <label htmlFor="upload-file">
                              <input
                                id="upload-file"
                                type="file"
                                accept="image/*"
                                className="d-none"
                                name="image"
                              />
                              <div className="upload-img">
                                <img src={Images.upload} alt="uploding icon" />
                              </div>
                              <h4>{English.G458}</h4>
                              <p>
                                {English.G156}
                              </p>
                              <button
                                type="button"
                                htmlFor="upload-file"
                                onClick={() => document.getElementById('upload-file').click()}
                              >
                                <span>{English.G459}</span>
                              </button>
                            </label>
                          </div>

                          <div>
                            <label>{English.G461}</label>
                            <input
                              placeholder={English.G162}
                              name="award_title"
                              onChange={handleChange}
                            />
                          </div>

                          <div>
                            <label>{English.G112}</label>
                            <textarea
                              placeholder={English.G83}
                              name="description"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="button-row">
                            <button
                              type="button"
                              className="secondary-btn-model"
                              onClick={EditCancel}
                            >
                              <span>{English.G116}</span>
                            </button>
                            <button
                              type="button"
                              className="secondary-btn-model"
                              onClick={EditAdd}
                            >
                              {English.G152}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

              </div>
            ))}
          </div>
        </div>
        {totalPages > 1 && (
          <div className="pagination-container">
            <button
              type="button"
              className={`pagination-button ${
                currentPage === 1 ? 'disabled' : ''
              }`}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              {English.G176}
            </button>
            {renderPaginationButtons()}
            <button
              type="button"
              className={`pagination-button ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              {English.G69}
            </button>
          </div>
        )}
      </div>
      {ShowAwardsModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <form onSubmit={handleSubmit}>
              <div className="item-details-input pt-0">
                <div className="upload-input upload-input-with-border">
                  <label htmlFor="upload-file">
                    <input
                      id="upload-file"
                      type="file"
                      accept="image/*"
                      className="d-none"
                      name="image"
                    />
                    <div className="upload-img">
                      <img src={Images.upload} alt="uploding icon" />
                    </div>
                    <h4>{English.G458}</h4>
                    <p>
                      {English.G156}
                    </p>
                    <button
                      type="button"
                      htmlFor="upload-file"
                      onClick={() => document.getElementById('upload-file').click()}
                    >
                      <span>{English.G459}</span>
                    </button>
                  </label>
                </div>

                <div>
                  <label htmlFor="award_title">{English.G461}</label>
                  <input
                    id="award_title"
                    placeholder={English.G162}
                    name="award_title"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="description">{English.G112}</label>
                  <textarea
                    id="description"
                    placeholder={English.G83}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                <div className="button-row">
                  <button
                    type="button"
                    className="secondary-btn-model"
                    onClick={HandleCancel}
                  >
                    <span>{English.G116}</span>
                  </button>
                  <button type="button" className="secondary-btn-model" onClick={handleAdd}>
                    {English.G152}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
export default AwardsComponent
