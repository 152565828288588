/* eslint-disable max-len */
import React from 'react'
import { useSelector } from 'react-redux'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { s3ImageProfile } from '../../../services/Service'

const Wallet = () => {
    const UserData = useSelector((state) => state.user?.userData)
  return (
    <div className="gbn-business-profile">
      <div className="flex justify-between items-center mb-[20px]">
        <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
          {English.A144}
        </div>
        <CommonButton
          label={English.A145}
          type="button"
          // onClick={createProfileClick}
          className="primary-btns w-auto !py-[9px] !px-3"
          context="primary-btn"
          isPrimary
        />
      </div>
      <div className="flex flex-1 flex-col">
        <div className="d-flex items-center gap-[10px] !pb-[20px]">
          <img className="w-[60px] h-[60px] bg-electricWhite rounded-full shadow-xl border-2 border-border" src={s3ImageProfile + UserData.profile_image} alt="sad" />
          <div className="flex flex-1 flex-col">
            <div className="bg-primaryGradient font-semibold text-[17.52px] bg-clip-text text-transparent">{UserData.full_name}</div>
            <div className="text-black font-medium text-lg flex items-center gap-2">
              {`@${UserData.user_name}`}
              <img className="" src={Images.GreenTickRound} alt="sad" />
            </div>
          </div>
        </div>

        <img className="w-[343px] h-[184px]" src={Images.LiteCreatorCard} alt="sad" />
      </div>
    </div>
  )
}

export default Wallet
